import { createLoginPreferences, updateLoginPreferences } from 'graphql/mutations'
import { API } from 'aws-amplify'
import { getLoginPreferences } from 'graphql/queries'
import axios from 'axios'
import { urlBase } from 'Apis/rest'
export const LOAD_STORE = 'loadStore'
export const LOAD_STORE_LOGIN = 'loadStoreLogin'
export const SAVE_CENTER_ZOOM = 'saveCenterZoom'

export const fetchLogin = (payload) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  try {
    const { data: tenantData } = await axios.get(urlBase + '/tenants', {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({ type: 'FETCH_TENANTS', payload: tenantData })
    const { empresa: tenant, email: login } = payload
    dispatch(
      fetchLoginPreferences({
        tenant,
        login,
        tenantInfo: tenantData.current.info,
        tenantFeatures: tenantData.current.features,
      })
    )
    dispatch(loadLogin(payload))
  } catch (err) {
    console.error(err)
  }
}

export const loadLogin = (payload) => ({
  type: 'LOAD_LOGIN',
  payload,
})

export const saveRefMap = (refMap) => ({
  type: 'saveCenterZoom',
  payload: { refMap: refMap },
})

export const saveLanguage = (language, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"language": "${language}"}'` },
  where: { email: email },
})

export const saveLanguageNow = (language) => ({
  type: 'saveLanguages',
  payload: { language: language },
})

export const saveFavoriteNow = (favorites) => ({
  type: 'saveFavorites',
  payload: { favorites: favorites },
})

export const saveFollowMeStore = (seguidos) => ({
  type: 'saveLoginInfo',
  payload: { followMe: seguidos },
})

export const saveFollowMe = (id) => {
  return {
    type: 'saveFollowMe',
    payload: { id },
  }
}

export const saveFollowMeNow = (seguidos, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"followMe": [${seguidos.toString()}]}'` },
  where: { email: email },
})

export const removeFollowMe = () => {
  return {
    type: 'removeFollowMe',
  }
}

export const setLoginConnection = (payload) => {
  return {
    type: 'SET_LOGIN_CONNECTION',
    payload,
  }
}

export const fetchLoginPreferences =
  ({ tenant, login, tenantInfo, tenantFeatures }) =>
  async (dispatch) => {
    let formatFeatures = tenantFeatures.reduce((acc, current) => {
      if (current === 'location_hist') return [...acc, 'locationHist']
      if (current === 'firefighters_view') return [...acc, 'firefighter']
      if (current === 'data_collection')
        // return [...acc, 'labelingTool', 'manualYamazumi', 'automaticYamazumi', 'cycleTimeMeasurement']
        //labelingTool em branco e sem uso, retirado até que necessite
        return [
          ...acc,
          'manualYamazumi',
          'automaticYamazumi',
          'cycleTimeMeasurement',
          'labelingTool',
          'activities',
          'dataLabelings',
          'mensurations',
        ]
      if (current === 'app') return [...acc, 'logouts']
      if (current === 'simulations') return [...acc, 'simulations']
      return [...acc, current]
    }, [])
    try {
      dispatch({ type: 'FETCH_LOGIN_PREFERENCES_START', tenant, login, tenantInfo, tenantFeatures })
      const response = await API.graphql({
        query: getLoginPreferences,
        variables: { tenant, login: { eq: login } },
      })
      const { items } = response.data.getLoginPreferences

      let payload = JSON.parse(JSON.stringify(items[0] || {}))

      if (!items[0] && tenantInfo?.lat) {
        payload = {
          center: { lat: tenantInfo?.lat, lng: tenantInfo?.lng },
          zoom: 18,
        }
      }

      const filteredPayload = {
        ...payload,
        fences: payload.fences?.map((fence) => JSON.parse(fence)),
        indoor: payload.indoor?.map((eachIndoor) => JSON.parse(eachIndoor)),
        refs: payload.refs?.map((ref) => JSON.parse(ref)),
        routes: payload.routes?.map((route) => JSON.parse(route)),
        routesPanels: payload?.routesPanels?.reduce((filteredPanels, e) => {
          const item = JSON.parse(e)
          if (item.id !== 'motion' && item.id !== 'logouts') {
            filteredPanels.push(item)
          }
          return filteredPanels
        }, []),
      }

      dispatch({
        type: 'FETCH_LOGIN_PREFERENCES_SUCCESS',
        payload: filteredPayload,
        features: formatFeatures,
      })
    } catch (err) {
      dispatch({
        type: 'FETCH_LOGIN_PREFERENCES_FAILURE',
        features: formatFeatures,
      })
      console.error('error fetching login preferences', err)
    }
  }

export const saveLoginPreferences = () => async (dispatch, getState) => {
  const { empresa, email, refMap, preferences } = getState().login
  const {
    center: prefCenter,
    zoom: prefZoom,
    mapLayers,
    indoor,
    fences,
    refs,
    routes,
    routesPanels: allRoutesPanels,
  } = preferences

  const [center, zoom] = refMap ? [refMap.getCenter(), refMap.getZoom()] : [prefCenter, prefZoom]
  const routesPanels = allRoutesPanels?.allIds?.map((each) => JSON.stringify(allRoutesPanels.byId[each]))

  let input = {
    id: `${empresa}#${email}`,
    tenant: empresa,
    login: email,
    center,
    zoom: Math.round(zoom),
    mapLayers: mapLayers || [],
    indoor: Object.values(indoor).map((eachIndoor) => JSON.stringify(eachIndoor)),
    fences: Object.values(fences).map((fence) => JSON.stringify(fence)),
    refs: Object.values(refs).map((ref) => JSON.stringify(ref)),
    routes: Object.values(routes).map((route) => JSON.stringify(route)),
    routesPanels,
  }

  const omitTypename = (key, value) => (key === '__typename' ? undefined : value)
  input = JSON.parse(JSON.stringify(input), omitTypename)

  dispatch({ type: 'SAVE_LOGIN_START', input })
  try {
    await API.graphql({
      query: updateLoginPreferences,
      variables: { input },
    })
    dispatch({ type: 'UPDATE_TAB_ACTION_SUCCESS' })
  } catch (err) {
    await API.graphql({
      query: createLoginPreferences,
      variables: { input },
    })
      .then(() => dispatch({ type: 'UPDATE_TAB_ACTION_SUCCESS' }))
      .catch((err) => {
        dispatch({ type: 'UPDATE_TAB_ACTION_FAILURE', payload: err })
        console.error('error saveLoginPreferences:', { err, input })
      })
  }
}

export const saveLoginMapLayer = (mapLayer, add) => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  const login = getState().login.email
  const loginMapLayers = getState().login.preferences.mapLayers

  let input = {
    id: `${tenant}#${login}`,
    tenant,
    login,
    mapLayers: add
      ? loginMapLayers.includes(mapLayer)
        ? loginMapLayers
        : loginMapLayers.concat(mapLayer)
      : loginMapLayers.includes(mapLayer)
      ? loginMapLayers.filter((e) => e !== mapLayer)
      : loginMapLayers,
  }
  dispatch({ type: 'SAVE_LOGIN_MAP_LAYERS', payload: input.mapLayers })
  try {
    await API.graphql({
      query: updateLoginPreferences,
      variables: {
        input,
      },
    })
  } catch (err) {
    try {
      await API.graphql({
        query: createLoginPreferences,
        variables: {
          input,
        },
      })
    } catch (err) {
      console.error('error create:', err)
    }
  }
}

export const saveColumnsPreference = (tableName, columnsPreference, groupedValuesColumns) => async (dispatch) => {
  // get columns toggled by user
  // save that to their login preferences
  if (columnsPreference) {
    dispatch({
      type: 'UPDATE_COLUMNS_DISPLAY',
      payload: { tableName, columnsPreference },
    })
  }
  if (groupedValuesColumns) {
    dispatch({
      type: 'UPDATE_GROUPED_ROWS',
      payload: { tableName, groupedValuesColumns },
    })
  }
}

export const saveReportsColumnsPreference =
  (tableName, columnsPreference, groupedValuesColumns) => async (dispatch) => {
    // get columns toggled by user
    // save that to their login preferences
    if (columnsPreference) {
      dispatch({
        type: 'UPDATE_REPORTS_COLUMNS_DISPLAY',
        payload: { tableName, columnsPreference },
      })
    }
    if (groupedValuesColumns) {
      dispatch({
        type: 'UPDATE_REPORTS_GROUPED_ROWS',
        payload: { tableName, groupedValuesColumns },
      })
    }
  }

export const changeLoginPreferences = (payload) => ({
  type: 'CHANGE_LOGIN_PREFERENCES',
  payload,
})

export const setLoginProp = (prop, value) => ({
  type: 'SET_LOGIN_PROP',
  prop,
  value,
})

export const setNewRoutePanel = (value) => ({
  type: 'SET_NEW_ROUTE_PANEL',
  value,
})

export const deleteRoutePanel = (payload) => ({
  type: 'DELETE_ROUTE_PANEL',
  payload,
})

export const changeDashboardPanelPreferences = (payload) => ({
  type: 'CHANGE_DASHBOARD_PANEL_PREFERENCES',
  payload,
})

export const changeDashboardLayout = (payload) => ({
  type: 'CHANGE_DASHBOARD_LAYOUT',
  payload,
})

export const changeDashboardToDefault = (payload) => ({
  type: 'CHANGE_DASHBOARD_TO_DEFAULT',
  payload,
})

export const toggleDashboardStaticMode = (payload) => ({
  type: 'TOGGLE_DASHBOARD_STATIC_MODE',
  payload,
})
