// BreadcrumbReports.tsx
import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import { Link, useHistory } from 'react-router-dom'
import TableSpan from 'Components/SidebarMenu/TableSpan'
import { useDispatch } from 'react-redux'

interface BreadcrumbReportsProps {
  home?: string
  current: string
  code?: string
  reportType: string
  lowercase?: boolean
}

/**
 * Component that renders a breadcrumb navigation for the reports section.
 *
 * @param BreadcrumbReportsProps - Props for configuring the breadcrumb links.
 */
const BreadcrumbReports: React.FC<BreadcrumbReportsProps> = ({ home, current, code, reportType, lowercase }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  // Styles applied when `lowercase` prop is true
  const lowerCaseStyle = { textTransform: 'lowercase' }

  return (
    <Breadcrumb size="big">
      {home && (
        <Breadcrumb.Section>
          <Link to="/app/reports">{home}</Link>
        </Breadcrumb.Section>
      )}
      {home && <Breadcrumb.Divider icon="right chevron" />}
      <Breadcrumb.Section active style={lowercase ? lowerCaseStyle : undefined}>
        <TableSpan
          value={current}
          width="33vw"
          isLink={!!code}
          onClick={() => {
            if (code) {
              current && history.push(`/app/report/${reportType}`)
              dispatch({
                type: 'FETCH_REPORT_CLEAR_STATE',
              })
            }
          }}
          padding="0px"
        />
      </Breadcrumb.Section>

      {!!code && (
        <>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active style={lowercase ? lowerCaseStyle : undefined}>
            <TableSpan value={code ? code.substring(0, 8) : ''} width="10vw" padding="0px" />
          </Breadcrumb.Section>
        </>
      )}
    </Breadcrumb>
  )
}

export default BreadcrumbReports
