import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'
import { API } from 'aws-amplify'
import { createMensuration, deleteMensuration, updateMensuration } from 'graphql/mutations'
import { listMensurations } from 'graphql/queries'

export const setMensurationsProp = (prop, value) => ({
  type: 'SET_MENSURATIONS_PROP',
  prop,
  value,
})

export const setMensurationsProps = (payload, source) => {
  return {
    type: 'SET_MENSURATIONS_PROPS',
    payload,
    source,
  }
}

export const mutateMensurationPhase = (payload) => ({
  type: 'MUTATE_MENSURATION_PHASE',
  payload,
})

export const removeMensurationPhase = (payload) => ({
  type: 'REMOVE_MENSURATION_PHASE',
  payload,
})

export const fetchMensurations = (nextToken) => async (dispatch, getState) => {
  const cleanObjects = (arr) => {
    return arr.map((obj) => {
      const filteredEntries = Object.entries(obj).filter(([key, value]) => value !== null && value !== undefined)
      return Object.fromEntries(filteredEntries)
    })
  }

  const tenant = getState().login.empresa
  try {
    dispatch({ type: 'FETCH_MENSURATIONS_START', tenant })
    const response = await API.graphql({
      query: listMensurations,
      variables: { tenant },
    })
    let payload = { ...response.data.listMensurations }
    payload.items = cleanObjects(payload.items)
    dispatch({
      type: 'FETCH_MENSURATIONS_SUCCESS',
      payload,
    })
  } catch (err) {
    console.error('error fetching mensurations', err)
  }
}

export const mutateMensuration = () => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  const { activity, updatedAt, ...fields } = getState().mensurations.wip
  const mutation = fields.id ? 'update' : 'create'
  let input = {
    tenant,
    ...fields,
    [mutation === 'create' ? 'createdBy' : 'updatedBy']: getState().login.email,
  }

  dispatch({ type: 'MUTATE_MENSURATION_START', input, mutation })
  try {
    const response = await API.graphql({
      query: mutation === 'update' ? updateMensuration : createMensuration,
      variables: {
        input,
      },
    })
    dispatch({
      type: 'MUTATE_MENSURATION_SUCCESS',
      payload: response?.data?.[mutation === 'update' ? 'updateMensuration' : 'createMensuration'],
    })
    notifySuccess()
  } catch (err) {
    console.error('error:', err)
    dispatch({ type: 'MUTATE_MENSURATION_FAILURE', input, mutation })
    notifyError()
  }
}

export const setMensurationWIP = (id) => {
  return { type: 'SET_MENSURATION_WIP', id }
}

export const deleteMensurations =
  ({ ids }) =>
  async (dispatch) => {
    for (const id of ids) {
      try {
        dispatch({ type: 'DELETE_MENSURATIONS_START', id })
        await API.graphql({
          query: deleteMensuration,
          variables: {
            input: {
              id,
            },
          },
        })
        dispatch({ type: 'DELETE_MENSURATIONS_SUCCESS', id })
        notifySuccess()
      } catch (err) {
        dispatch({ type: 'DELETE_MENSURATIONS_FAILURE', id })
        console.error('error:', err)
        notifyError()
      }
    }
  }

export const editMensurationStart = (id) => {
  return { type: 'EDIT_MENSURATION_START', id }
}

export const clearMensurationForm = () => {
  return { type: 'CLEAR_MENSURATION_FORM' }
}
