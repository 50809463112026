import React, { /*useState,*/ useEffect } from 'react'
import { Icon } from 'semantic-ui-react'

const PlaySoundsButton = ({ name, stop }) => {
  const audio = new Audio(`/` + name + `.mp3`)
  useEffect(() => {
    audio.play()
    setTimeout(() => stop(), ['alarm', 'success'].includes(name) ? 3000 : 1000)
  }, [audio, stop, name])
  const handleClick = () => {
    audio.play()
  }
  return <Icon name={'play'} onClick={handleClick} />
}
export default PlaySoundsButton
