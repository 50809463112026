import React, { useMemo } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import useFetchData from 'Utils/hooks/useFetchData'

export interface EntityDropdownProps {
  entity: 'assets' | 'activities' | 'rules' | 'fences' | 'reports' | 'profiles' | 'devices'
  value?: string | number | boolean | (string | number | boolean)[] | undefined
  placeholder?: string
  onChange: Function
  multiple?: boolean
  module?: boolean
  key?: string
  clearable?: boolean
  filter?: (item: any) => boolean
  prefix?: string
}

const EntityDropdown = ({
  entity,
  value,
  placeholder,
  onChange,
  module = false,
  multiple = false,
  clearable = true,
  filter,
  prefix = 'code',
}: EntityDropdownProps) => {
  useFetchData({
    independentFetches: [
      {
        fetchAction: entity === 'fences' ? 'geoModules' : entity,
      },
    ],
  })

  const allEntityIds = useObjSelector((state) => {
    if (module && entity === 'fences') {
      return state.modules[entity].allIds
    }
    return entity !== 'fences' && state[entity].allIds
  })

  const entityById = useObjSelector((state: RootStore) => {
    if (module && entity === 'fences') {
      return state.modules[entity].byId
    }
    return entity !== 'fences' && state[entity].byId
  })

  const loadingEntity = useSelector((state: RootStore) => {
    if (module && entity === 'fences') {
      return state.modules[entity]?.loading || state.modules?.loading
    }
    return entity !== 'fences' && state[entity].loading
  })

  const options = useMemo(() => {
    return allEntityIds
      .reduce((acc: any, entityId: string) => {
        const entity = entityById[entityId]
        if (filter && !filter(entity)) return acc
        const getCodeLabel = (code: string | undefined, label: string | undefined) => {
          return `${code ? code + ' - ' : ''}${label || ''}`
        }

        const text = module
          ? getCodeLabel(entity.info?.code || entity.code, entity.info?.label || entity.name || entity.label)
          : getCodeLabel(entity?.[prefix], entity?.name || entity?.label)

        acc.push({
          key: entityId,
          value: entityId,
          text,
        })

        return acc
      }, [])
      .sort((a: any, b: any) => a.text.localeCompare(b.text))
  }, [allEntityIds, entityById, module, prefix, filter])

  return (
    <Dropdown
      selection
      placeholder={loadingEntity ? 'Carregando opções, aguarde...' : placeholder}
      onChange={(_, { value }) => onChange(value)}
      value={value}
      options={options}
      noResultsMessage={
        loadingEntity ? 'Carregando opções, aguarde...' : 'Nenhum resultado encontrado no filtro selecionado.'
      }
      loading={loadingEntity}
      search
      fluid
      multiple={multiple}
      clearable={clearable}
    />
  )
}

export default EntityDropdown
