import { RootStore } from 'Store/initialStore'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ReferenceLine,
  LabelList,
  //   LabelList,
} from 'recharts'
import { Label } from 'semantic-ui-react'
// import { lightColors } from 'Utils/colorsFormat'

interface PredictBarChartProps {
  data: any[]
  showPhases: boolean
}

const valueAccessor =
  (attribute: string) =>
  ({ payload }: { payload: { attribute: string } }) => {
    return payload[attribute] || ''
  }

const PredictBarChart: React.FC<PredictBarChartProps> = ({ data, showPhases }) => {
  const { t } = useTranslation()

  const selectedChart = useSelector((state: RootStore) => state.dashboardStellantis.charts.selected)

  //console.log('Os dados: ', data)
  let newData = [
    {
      cycle: '25-Out',
      minStartTime: '2023-07-06T22:35:48.324Z',
      maxEndTime: '2023-07-06T22:36:31.321Z',
      duration: 43,
      vehicle: 'Fiorino/Partner',
      'phase#1': 13,
      'phase#2': 30,
      'phase#3': 23,
      'phase2#0': 12,
      'phase2#1': 13,
      'phase2#2': 30,
      'phase2#3': 23,
    },
    {
      cycle: '26-Out',
      minStartTime: '2023-07-06T22:35:48.324Z',
      maxEndTime: '2023-07-06T22:36:31.321Z',
      duration: 43,
      vehicle: 'Fiorino/Partner',
      'phase#0': 11,
      'phase#1': 15,
      'phase#2': 25,
      'phase#3': 15,
      'phase2#0': 11,
      'phase2#1': 15,
      'phase2#2': 25,
    },
  ]

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={newData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="cycle" />
        <YAxis unit="s" />
        <Tooltip />
        <Legend
          content={() => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '3px',
                marginLeft: '55px',
              }}
            >
              <Label>{t('shift1&2')}</Label>
            </div>
          )}
        />

        {selectedChart === 'ciclo' ? (
          <>
            <Bar dataKey="phase#0" stackId={'1'} fill="#B0DCF2">
              <LabelList valueAccessor={valueAccessor('phase#0')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#1" stackId={'1'} fill="#8DD994">
              <LabelList valueAccessor={valueAccessor('phase#1')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#2" stackId={'1'} fill="#F4B9B9">
              <LabelList valueAccessor={valueAccessor('phase#2')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#3" stackId={'1'} fill="#FFC18F">
              <LabelList valueAccessor={valueAccessor('phase#3')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#4" stackId={'1'} fill="#FFDA8F">
              <LabelList valueAccessor={valueAccessor('phase#4')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase2#0" stackId={'2'} fill="#B0DCF2">
              <LabelList valueAccessor={valueAccessor('phase#0')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase2#1" stackId={'2'} fill="#8DD994">
              <LabelList valueAccessor={valueAccessor('phase#1')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase2#2" stackId={'2'} fill="#F4B9B9">
              <LabelList valueAccessor={valueAccessor('phase#2')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase2#3" stackId={'2'} fill="#FFC18F">
              <LabelList valueAccessor={valueAccessor('phase#3')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase2#4" stackId={'2'} fill="#FFDA8F">
              <LabelList valueAccessor={valueAccessor('phase#4')} style={{ backgroundColor: 'white' }} />
            </Bar>
          </>
        ) : (
          <Bar dataKey="duration" stackId={'cycle'} fill="rgb(102, 175, 233)">
            <LabelList valueAccessor={valueAccessor('duration')} style={{ backgroundColor: 'white' }} />
          </Bar>
        )}

        <ReferenceLine y={77} label="TT" stroke="red" />
        <ReferenceLine y={65} label="TCT" stroke="blue" strokeDasharray="3 3" />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default PredictBarChart
