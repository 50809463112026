import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

export const GraphLine = ({ data = [], distinct = [], maxValue = 'auto' }) => {
  const maxDataValue =
    !distinct.lenght &&
    data.reduce((prev, curr) => {
      return parseFloat(curr.value) > prev ? parseFloat(curr.value) : prev
    }, 5)
  return (
    <ResponsiveContainer>
      <LineChart width={700} height={800} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
        <YAxis domain={[0, maxDataValue || maxValue]} unit={' min'} />
        <Tooltip
          formatter={(value) => {
            return value + ' min'
          }}
        />
        <Legend />
        {distinct?.length === 0 ? (
          <Line type="monotone" dataKey="value" stroke="#82ca9d" />
        ) : (
          distinct.map((dis) => <Line type="monotone" dataKey={dis.name} key={`cell-${dis.name}`} stroke={dis.color} />)
        )}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default GraphLine
