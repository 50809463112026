import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { /* Checkbox,*/ Icon, Label } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
//import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { deleteNotificationProfiles } from 'Store/actions/notificationProfiles-action'
import { colorFormat } from 'Utils/colorsFormat'
import { RootStore } from 'Store/initialStore'
import { saveColumnsPreference } from 'Store/actions/login-action'
import useFetchData from 'Utils/hooks/useFetchData'
import { GenericColumns } from 'Utils/reactTable/components/GenericColumns'

export const CriticalityContent = () => {
  const [loading, setLoading] = useState(true)

  useFetchData({
    independentFetches: [{ fetchAction: 'notificationProfiles' }],
    finishAction: () => setLoading(false),
  })

  const dispatch = useDispatch()

  const { t } = useTranslation()
  const tableName: string = 'notificationProfiles'
  const hiddenColumns: string[] = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns
  )

  const groupedValuesColumns = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  //if(table?.allIds === []) dispatch(fetchRules());
  const [theseColumns, setTheseColumns] = React.useState([
    'nome',
    'info.destino',
    'info.responsavel',
    'info.recorrencia',
  ])
  const formatColumns = React.useMemo(
    () => [
      //{ Header: t('Ícone'), accessor: 'icon', aggregate: 'uniqueCount', Aggregated: ({ value }) => `${value} ${t('icons')}`, Cell: ({ value }) => <Icon name={value} /> },
      {
        Header: t('uniqueID'),
        accessor: 'id',
        aggregate: 'uniqueCount',
        Aggregated: ({ value = '' }) => `${value} ${'ids'}`,
      },
      {
        Header: t('Name'),
        accessor: 'name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value = '' }) => `${value} ${'perfis'}`,
      },
      {
        Header: t('Description'),
        accessor: 'description',
        aggregate: 'uniqueCount',
        Aggregated: ({ value = '' }) => `${value} ${'descrições'}`,
      },
      {
        Header: t('Level'),
        accessor: 'level',
        aggregate: 'uniqueCount',
        Aggregated: ({ value = '' }) => `${value} ${t('levels')}`,
      },
      {
        Header: t('bgColor'),
        accessor: 'bgColor',
        aggregate: 'uniqueCount',
        Aggregated: ({ value = '' }) => `${value} ${t('colors')}`,
        Cell: ({ value = '' }) => {
          return (
            <Label circular key={value} style={{ backgroundColor: colorFormat(value) }}>
              {t(value?.toLowerCase())}
            </Label>
          )
        },
      },
      {
        Header: t('txtColor'),
        accessor: 'txtColor',
        aggregate: 'uniqueCount',
        Aggregated: ({ value = '' }) => `${value} ${t('colors')}`,
        Cell: ({ value = '' }) => {
          const color: string = colorFormat(value)
          return (
            <Label
              circular
              key={value}
              style={{ backgroundColor: color, color: color === '#FFFFFF' ? 'black' : 'aliceblue' }}
            >
              {t(value)}
            </Label>
          )
        },
      },
      {
        Header: t('screenTime'),
        accessor: 'screenTime',
        aggregate: 'uniqueCount',
        Aggregated: ({ value = '' }) => `${value} ${t('screenTime')}`,
        Cell: ({ value = '' }) => {
          return <span>{t(value)}</span>
        },
      },
      {
        Header: t('Icon'),
        accessor: 'icon',
        aggregate: 'uniqueCount',
        Aggregated: ({ value = '' }) => `${value} ${t('icons')}`,
        Cell: ({ value }: any) => {
          return <Icon name={value?.toLowerCase()?.replace('_', ' ')} circular />
        },
      },
      {
        Header: t('Sound'),
        accessor: 'sound',
        aggregate: 'uniqueCount',
        Aggregated: ({ value = '' }) => `${value} ${t('sound')}`,
        Cell: ({ value = '' }) => {
          return <span>{t(value)}</span>
        },
      },
    ],
    [t]
  )

  const columns = [...formatColumns, ...GenericColumns({})]
  // const rows = table?.allIds?.sort((prev, next) => table?.byId[next]?.time?.localeCompare(table?.byId[prev]?.time))
  const notificationProfiles = useSelector((state: RootStore) => state.notificationProfiles)
  const data = React.useMemo(() => {
    return notificationProfiles.allIds.map((id: string) => notificationProfiles.byId[id])
    // return [
    //   {
    //     level: 1,
    //     name: 'Crítico',
    //     bgColor: 'white',
    //     txColor: 'black',
    //     icon: 'warning sign',
    //     iconcolor: 'red',
    //     sound: true,
    //     vibration: true,
    //     status: true,
    //     createdat: '2021-02-10 13:21:03',
    //   },
    //   {
    //     level: 2,
    //     name: 'Atenção',
    //     bgColor: 'white',
    //     txColor: 'black',
    //     icon: 'warning sign',
    //     iconcolor: 'yellow',
    //     sound: true,
    //     vibration: false,
    //     status: true,
    //     createdat: '2021-02-10 13:21:03',
    //   },
    //   {
    //     level: 3,
    //     name: 'Informação',
    //     bgColor: 'white',
    //     txColor: 'black',
    //     icon: 'warning',
    //     iconcolor: 'blue',
    //     sound: false,
    //     vibration: false,
    //     status: true,
    //     createdat: '2021-02-10 13:21:03',
    //   },
    // ]

    /*(rows?.map((id) => {
              let row = table?.byId[id]
              return {
                     user:'Qualquer Pessoa',
                     interact: 'Qualquer Outra Pessoa', 
                     time:row.time,
                     type:row.type,
                     criticity:'Máximo',
                     distance:row.distance,
                     scope:row.scope
              }
            })),[table,rows])*/
  }, [notificationProfiles])
  /*const insertData = [
                      {placeholder:t('Name'),id:'name'}
                      ,{placeholder:t('Model'),id:'modelId',
                        options:table2.map((row)=>{return {label:row.name,id:row.id}})
                      }
                    ]*/

  return (
    <ReactTable
      table={tableName}
      hiddenColumns={hiddenColumns}
      columns={columns}
      data={data}
      theseColumns={theseColumns}
      setTheseColumns={setTheseColumns}
      showPlaceholders={loading}
      deleteAction={(ids: any) => dispatch(deleteNotificationProfiles(ids))}
      initialSortBy={{ desc: true, id: 'createdAt' }}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
    />
  )
}
