import initialStore from 'Store/initialStore'
import set from 'immutable-set'
import produce from 'immer'
import { omit } from 'lodash'
import { setStoreProps } from './reducerUtils'

export default function mensurationsReducer(state = initialStore.mensurations, { type, payload, prop, value, id }) {
  switch (type) {
    case 'FETCH_MENSURATIONS_START':
      return { ...state, loading: true }
    case 'FETCH_MENSURATIONS_SUCCESS':
      return produce(state, (newState) => {
        if (!payload?.items) return state
        const { items } = payload
        newState.allIds = items.map((item) => item.id)
        newState.byId = items.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
        newState.loading = false
      })
    case 'FETCH_MENSURATIONS_FAILURE':
      return { ...state, loading: false }

    case 'SET_MENSURATIONS_PROP':
      return set(state, prop, value)

    case 'SET_MENSURATIONS_PROPS':
      return setStoreProps(state, payload)

    case 'MUTATE_MENSURATION_PHASE':
      return produce(state, (newState) => {
        const { index, field, value } = payload
        const phases = newState.wip?.phases
        if (phases) {
          const phase = phases[index] ?? {}
          newState.wip.phases[index] = { ...phase, [field]: value }
        } else {
          newState.wip = { ...newState.wip, phases: [{ [field]: value }] }
        }
      })

    case 'REMOVE_MENSURATION_PHASE':
      return produce(state, (newState) => {
        const { index } = payload
        newState.wip.phases.splice(index, 1)
      })

    case 'MUTATE_MENSURATION_START':
      return produce(state, (newState) => {
        newState.form.loading = true
      })

    case 'MUTATE_MENSURATION_SUCCESS':
      return produce(state, (newState) => {
        if (!payload?.id) return state
        if (!newState.allIds.includes(payload.id)) newState.allIds.push(payload.id)
        newState.byId[payload.id] = payload
        newState.form.loading = false
        newState.form.open = false
      })

    case 'MUTATE_MENSURATION_FAILURE':
      return produce(state, (newState) => {
        newState.form.loading = false
      })

    case 'SET_MENSURATION_WIP':
      return produce(state, (newState) => {
        newState.wip = newState.byId[id]
      })

    case 'DELETE_MENSURATIONS_START':
      return { ...state, loading: true }

    case 'DELETE_MENSURATIONS_SUCCESS':
      if (!id) return state
      return produce(state, (newState) => {
        newState.allIds = newState.allIds.filter((each) => each !== id)
        newState.byId = omit(newState.byId, [id])
        newState.loading = false
      })

    case 'DELETE_MENSURATIONS_FAILURE':
      return { ...state, loading: false }

    case 'EDIT_MENSURATION_START':
      return produce(state, (newState) => {
        newState.wip = newState.byId[id]
        newState.current = newState.byId[id]
        newState.form.open = true
        newState.form.steps.current = 0
        newState.form.steps.items = newState.form.steps.items.map((step) => {
          return { ...step, completed: false, value: step.description, disabled: false }
        })
      })

    case 'CLEAR_MENSURATION_FORM':
      return produce(state, (newState) => {
        newState.form.steps.items = newState.form.steps.items.map((item, index) => {
          return { ...item, disabled: !(index === 0), completed: false, showFeedback: false }
        })
        newState.form.steps.current = 0
      })

    default:
      return state
  }
}
