import React, { useCallback, useState } from 'react'
import { Modal, /*Icon,*/ List, Header, Button, Image, Grid, Placeholder, Menu, Message, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { logError } from 'Utils/analytics.js'
import { createImageFromInitials } from 'Utils/components/ImageInitials'
import { ItemDetailsField } from './ItemDetailsField.jsx'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

const fieldOrder = [
  { field: 'name', label: 'Nome', type: 'string', desc: 'Nome e Sobrenome da pessoa', canBeProvided: true },
  {
    field: 'badgeNumber',
    label: 'Número do crachá',
    type: 'string',
    desc: 'Valor do NFC em decimal (escrito atrás do crachá plástico)',
    canBeProvided: true,
  },
  {
    field: 'registration',
    label: 'Matrícula',
    type: 'number | null',
    desc: 'Número da matrícula do funcionário, se disponível',
  },
  {
    field: 'nfcId',
    label: 'NFC (Suricato)',
    type: 'string',
    desc: 'Informação de identificação do cartão no suricato',
  },
  { field: 'company', label: 'Empresa', type: 'string', desc: 'Empresa da pessoa, se disponível', canBeProvided: true },
  { field: 'email', label: 'E-mail', type: 'string', desc: 'Email da pessoa, se disponível', canBeProvided: true },
  { field: 'position', label: 'Cargo', type: 'string | null', desc: 'Cargo da pessoa, se disponível' },
  {
    field: 'cellPhoneNumber',
    label: 'Celular',
    type: 'string | null',
    desc: 'Celular da pessoa, se disponível',
    canBeProvided: true,
  },
  {
    field: 'assignment',
    label: 'Assignment',
    type: 'enum | null',
    desc: 'Dentre as Atribuições possíveis da tabela de atribuições',
  },
  {
    field: 'lastRegister',
    label: 'Ultima atualização',
    type: 'timestamp',
    desc: 'Relativo ao tempo que esses dados foram salvos',
  },
  {
    field: 'onBoarding',
    label: 'Ambientação',
    type: 'string',
    desc: 'Se recebeu treinamento de ambientação',
  },
  /* {
      field: 'certifications',
      label: 'Treinamentos',
      type: 'Array | null',
      desc: 'Lista de treinamentos da pessoa, se disponível',
    },*/
  //{ field: 'lastIn', label: 'Último Horário de Entrada', type: 'string', desc: 'Horário de Entrada segundo API' },
  //{ field: 'lastOut', label: 'Último Horário de Saída', type: 'string', desc: 'Horário de Saída segundo API' },
]
const DivInfo = styled.div`
  margin-left: 40px;
  width: 800px;
`

const ItemDetailsModal = ({
  id,
  assetId,
  tryPreviousDate,
  dateRef,
  value,
  setOpen,
  error,
  loadingButton,
  neverFound,
  unreliable,
}) => {
  const assetInfo = useObjSelector((state) => state.assets.byId[assetId])
  const [activeItem, setActiveItem] = useState('integrated')
  const profileName = useObjSelector((state) => state.profiles.byId[assetInfo?.info?.type]?.name)
  const formattedAssetInfo = {
    assignment: profileName,
    badgeNumber: assetInfo?.info?.cardId,
    cellPhoneNumber: assetInfo?.info?.cellNumber,
    company: assetInfo?.info?.company,
    email: assetInfo?.info?.email,
    lastRegister: assetInfo?.info?.updatedAt,
    name: assetInfo?.name,
    nfcId: assetInfo?.info?.company,
  }

  const data = activeItem === 'integrated' ? value?.data : formattedAssetInfo
  const getPhotoUrl = useCallback((value) => {
    if (value.data?.photo?.content) {
      return `data:image/${value.data.photo.type};base64,${value.data.photo.content}`
    } else {
      return createImageFromInitials(500, value.data.name || 'NA', '#4b72b2')
    }
  }, [])
  if (value?.status === 204)
    return (
      <>
        <Modal.Header>{id} não foi encontrado na base do suricato</Modal.Header>
        <Modal.Content>
          <p>
            Código nfc {id} não foi encontrado na base do suricato
            {!neverFound ? (
              <>
                {' '}
                no dia <BoldedDate>{dateRef}</BoldedDate>{' '}
              </>
            ) : (
              ''
            )}
            .
          </p>
          <Button onClick={tryPreviousDate} loading={loadingButton} disabled={loadingButton || neverFound}>
            Verifique em uma data anterior
          </Button>
        </Modal.Content>
      </>
    )
  if (error?.response?.status === 500 || error?.response?.status === 502 || error?.response?.status === 504) {
    logError(error)
    return (
      <>
        <Modal.Header>
          {error?.response?.status === 504
            ? 'O servidor do Suricato não respondeu.'
            : 'Ops! Algo deu errado na API do suricato.'}
        </Modal.Header>
        <Modal.Content>
          <p>Tente novamente mais tarde</p>
          <p>Caso o erro persista, consulte o suporte técnico informando o erro.</p>
        </Modal.Content>
      </>
    )
  }

  return (
    <>
      <Modal.Header>
        Detalhes do {id} - hora de referência: {dateRef}
      </Modal.Header>
      {unreliable && (
        <Menu tabular>
          <Menu.Item
            name="Dados Integrados"
            active={activeItem === 'integrated'}
            onClick={() => setActiveItem('integrated')}
          />
          <Menu.Item
            name="Dados Informados"
            active={activeItem === 'provided'}
            onClick={() => setActiveItem('provided')}
          />
        </Menu>
      )}
      <Modal.Content image>
        {value ? (
          <Image size="medium" src={getPhotoUrl(value)} wrapped style={{ borderRadius: '10px' }} />
        ) : (
          <>
            <Placeholder style={{ height: 209.45, width: 209.45 }}>
              <Placeholder.Image />
            </Placeholder>
          </>
        )}
        <DivInfo>
          <Header style={{ marginBottom: '34px' }}>Informações Pessoais</Header>
          <Grid columns={'equal'}>
            <Grid.Column width={6}>
              <List>
                {fieldOrder
                  .filter((li) => li.canBeProvided || activeItem === 'integrated')
                  .map(
                    (li, i) =>
                      i % 2 === 0 && (
                        <ItemDetailsField
                          key={`detailItem${li.field}`}
                          li={li}
                          value={data?.[li.field]}
                          tenant={'Tiplam'}
                          unreliableAlert={unreliable && value?.data?.[li.field] !== formattedAssetInfo?.[li.field]}
                          loading={value?.status !== 200}
                        />
                      )
                  )}
              </List>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={6}>
              <List>
                {fieldOrder
                  .filter((li) => li.canBeProvided || activeItem === 'integrated')
                  .map(
                    (li, i) =>
                      i % 2 === 1 && (
                        <ItemDetailsField
                          status={value?.status}
                          key={`detailItem${li.field}`}
                          li={li}
                          value={data?.[li.field]}
                          tenant={'Tiplam'}
                          unreliableAlert={unreliable && value?.data?.[li.field] !== formattedAssetInfo?.[li.field]}
                          loading={value?.status !== 200}
                        />
                      )
                  )}
              </List>
            </Grid.Column>
          </Grid>
        </DivInfo>
      </Modal.Content>
      {unreliable && (
        <div style={{ padding: '21px' }}>
          <Message warning style={{ backgroundColor: '#fff5e0', color: 'black', border: '1px #FED307 solid' }}>
            <Message.Header style={{ backgroundColor: '#fff5e0', color: 'black', borderColor: '#FED307' }}>
              Os dados apresentados pelo sistema Suricato divergem dos informados pelo usuário.
            </Message.Header>
          </Message>
        </div>
      )}
      <Modal.Actions>
        <Button icon labelPosition="right" onClick={() => setOpen(false)} positive>
          <Icon name="checkmark" />
          OK
        </Button>
      </Modal.Actions>
    </>
  )
}

export default ItemDetailsModal

const BoldedDate = styled.span`
  font-weight: bold;
`
