import { deleteFile } from 'react-s3'
import { configs3 } from 'Utils/constantes'
import { handleDeleteFile } from 'Utils/database'
import innerMeasures from 'Utils/innerMeasures'
import produce from 'immer'

export default function indoorReducer(state = {}, { type, id, payload, company }) {
  switch (type) {
    case 'indoorInitialStore':
      return produce(state, (newState) => {
        if (payload === null || !Array.isArray(payload) || !Array.isArray(payload[0]?.indoor)) {
          return
        }

        payload[0].indoor.forEach((newItem) => {
          const existingItemIndex = newState.items?.findIndex((item) => item.id === newItem.id)
          if (existingItemIndex !== -1 && existingItemIndex !== undefined) {
            newState.items[existingItemIndex] = {
              ...newState.items[existingItemIndex],
              ...newItem,
            }
          } else {
            newState.items.push(newItem)
          }
        })
      })

    case 'indoorNew':
      let x = state.items.length
      state.items[x] = payload
      return { ...state }

    case 'updateindoorNew':
      return produce(state, (newState) => {
        Object.entries(state).forEach((data) => {
          if (payload.id === data[1].id) {
            newState[data[0]] = payload
          }
        })
      })

    case 'deleta imagem': {
      const items = state.items.filter(({ id }) => id !== payload.id)
      const filename = payload.url.split('/')[5]
      deleteFile(filename, configs3)
        .then((response) => handleDeleteFile(payload.id))
        .catch((err) => console.error(err))
      return { ...state, items }
    }

    case 'seleciona imagem': {
      const selectedItem = state.items.find(({ id }) => id === payload.id)
      return { ...state, selectedItem }
    }

    case 'atualizaIndoor':
      return produce(state, (newState) => {
        Object.entries(state).forEach((data) => {
          if (id === data[1].id) {
            switch (payload.campo) {
              case 'Nome':
                newState[data[0]].name = payload.valor
                break
              case 'Campos':
                newState[data[0]].info.corners = payload.valor
                break
              default:
            }
          }
        })
      })
    case 'SET_CURRENT_INDOOR':
      if (!id) return { ...state, current: { inner: state.current.inner } }
      return produce(state, (newState) => {
        let index = state.items.findIndex((el) => el.id === id)
        let item = state.items[index]
        newState.current = { ...item } // Create a new object
        if (!item.inner) {
          let measures = innerMeasures(item.info.corners, company, item)
          newState.items[index].inner = measures
          newState.current.inner = measures
        }
      })

    default:
      return state
  }
}
