import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './dateRange.css'
import { DateRangePicker } from 'react-date-range'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import moment from 'moment'
import TimePicker from '../timePicker'
import { withTranslation } from 'react-i18next'
import languages from './languages.js'
import { Button, Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { fetchHeatMap } from 'Store/actions/heatmap-action'
import { fetchHistory, fetchHolesHist, fetchHolesLinesHist } from 'Store/actions/history-action'
//import { setFalse } from 'Store/actions/toggle-action';

// Máximo de dias que pode voltar no histórico
//const MAX_PICKABLE_DAYS = 365;

class DateRange extends React.Component {
  state = {
    language: this.props.i18n.language,
  }

  static getDerivedStateFromProps(p, s) {
    if (p.i18n && p.i18n.language !== s.language) return { language: p.i18n.language }
    else return null
  }

  static propTypes = {
    /**
     * Valor da data inicial
     * Aceita JS Date ou objeto moment
     *
     * React não suporta `instanceOf` com valores nulos
     */
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /**
     * Valor da data final
     * Aceita JS Date ou objeto moment
     *
     * React não suporta `instanceOf` com valores nulos
     */
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /**
     * Aparece o negocinho do tempo ou não
     */
    showTimePicker: PropTypes.bool,

    /**
     * Máximo de dias no passado que você pode voltar
     */
    maxPickableDays: PropTypes.number,

    /**
     * Usa UTC
     */
    utc: PropTypes.bool,

    /**
     * trata a mudança do checkbox do UTC
     */
    onChangeUtc: PropTypes.func,

    /**
     * Callbackde quando o valor muda
     */
    onChange: PropTypes.func,
  }

  static defaultProps = {
    showAbsolute: true,
    showRelative: false,
    //maxPickableDays: MAX_PICKABLE_DAYS,
  }

  static contextTypes = {
    router: PropTypes.object,
  }

  static getTimeStringFromDate = (date) => {
    return moment(date).local().format('HH:mm')
  }

  static setDateToTime(dateObj, timeStr, { local } = {}) {
    const [hours, minutes, seconds] = timeStr.split(':')

    const date = new Date(+dateObj)

    if (local) {
      date.setHours(hours, minutes)
    } else {
      date.setUTCHours(hours, minutes)
    }

    if (typeof seconds !== 'undefined') {
      date.setSeconds(seconds)
    }

    return date
  }
  handleSelectDateRange = ({ selection }) => {
    const { onChange } = this.props
    const { startDate, endDate } = selection
    //Se o dia for hoje, setar a hora de agora, se não 23:59
    const end = moment(endDate).isSame(new Date(), 'day') ? moment() : moment(endDate).set({ h: 23, m: 59 })
    onChange({
      start: moment(startDate),
      end,
    })
  }

  handleChangeStart = (e) => {
    const { start, end, onChange, t } = this.props
    const startTime = e.target.value
    if (!startTime) {
      return
    }
    let chosenTime = moment(DateRange.setDateToTime(start, startTime, { local: true }))
    if (chosenTime >= moment()) {
      alert(t('HistoryCalendarNoFutureTime'))
      return
    }

    onChange({
      start: chosenTime,
      end,
    })
  }

  handleChangeEnd = (e) => {
    const { start, end, onChange, t } = this.props
    const endTime = e.target.value

    if (!endTime) {
      return
    }
    let chosenTime = moment(DateRange.setDateToTime(end, endTime, { local: true }))
    if (chosenTime >= moment()) {
      alert(t('HistoryCalendarNoFutureTime'))
      return
    }
    onChange({
      start,
      end: chosenTime,
    })
  }

  handleSubmit = () => {
    const { start, end, onSubmit, t } = this.props
    if (start > end) {
      alert(t('HistoryCalendarNoStartAfterEnd'))
      return
    }
    onSubmit()
  }

  render() {
    const {
      className,
      start,
      end,
      /*showTimePicker,*/
      minDate,
      maxDate,
      //closeHistory
    } = this.props

    const startTime = start < maxDate ? DateRange.getTimeStringFromDate(new Date(start)) : '00:00'
    const endTime =
      end < maxDate
        ? DateRange.getTimeStringFromDate(new Date(end))
        : DateRange.getTimeStringFromDate(new Date(maxDate))
    //const newStart = start ? moment(start).local() : moment(maxDate).local().toDate();

    // Restrições no intervalo de tempo que você pode selecionar
    // Não tem como selecionar dados futuros porque não somos videntes (ainda)
    // Queremos `maxPickableDays` - 1 (Se hoje é 5 de Jan, e o max é 3 dias, então o minDate tem que ser  3 de Jan)
    // Subtrai mais um dia para forçar que a data final fique inclusiva

    //const minDate = moment().local().subtract(maxPickableDays, 'days').toDate();
    //const minDate = moment('2019-03-07').toDate();
    //const maxDate = new Date();

    const selectionRange = {
      startDate: moment(start).local().toDate(),
      endDate: moment(end).local().toDate(),
      key: 'selection',
    }
    return (
      <Fragment>
        <div className={className} id="date-range">
          <DateRangePicker
            ranges={[selectionRange]}
            minDate={minDate}
            maxDate={maxDate}
            onChange={this.handleSelectDateRange}
            className="rdr-DateRangePicker"
            locale={languages[this.state.language]}
          />
          <Grid centered columns={3}>
            <TimePicker
              start={startTime}
              end={endTime}
              onChangeStart={this.handleChangeStart}
              onChangeEnd={this.handleChangeEnd}
              className="rdr-TimePicker"
            />
            <Grid.Column width={3}>
              <Button
                primary
                style={{ marginBottom: '20px' }}
                loading={this.props.loadingButton}
                disabled={this.props.loadingButton}
                size="tiny"
                onClick={() => {
                  console.log('Cheguei aqui inicio do onclick ', this.props.type)

                  this.handleSubmit()
                  if (this.props.type === 'hole') {
                    this.props.fetchHolesHist()
                    return
                  }
                  if (this.props.type === 'heatmap') {
                    this.props.fetchHeatMap()
                    return
                  }
                  if (this.props.type === 'holesLines') {
                    console.log('Cheguei aqui tb, pedindo fetch das rotas')
                    this.props.fetchHolesLinesHist()
                    return
                  }
                  this.props.fetchHistory({
                    assetsIds: this.props.filter.map((e) => e.id),
                  })
                }}
              >
                {this.props.t('DrawFinish')}
              </Button>
            </Grid.Column>
          </Grid>
        </div>
      </Fragment>
    )
  }
}

//DateRange.whyDidYouRender = true

const mapDispatchToProps = {
  fetchHeatMap,
  fetchHistory,
  fetchHolesHist,
  fetchHolesLinesHist,
}

const mapStateToProps = (state) => {
  return {
    filter: state.filters.result,
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DateRange))
