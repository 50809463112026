import React from 'react'
import { Feed } from 'semantic-ui-react'
import m from 'moment'
import { setTrue } from 'Store/actions/toggle-action'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TableSpan from 'Components/SidebarMenu/TableSpan'

export default function ToastItem({
  profile: { icon, txtColor },
  payload: { title, message, time, confirmedBy, type },
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  let content = message
  let fallbackMessage
  // review this please
  if (!message && icon) {
    switch (icon.replace('_', ' ')) {
      case 'close':
      case 'x':
        fallbackMessage = t('toast.error')
        break
      case 'check':
        fallbackMessage = t('toast.success')
        break
      case 'warning':
        fallbackMessage = t('toast.warning')
        break
      case 'info':
        fallbackMessage = t('toast.info')
        break
      default:
        fallbackMessage = t('toast.default')
    }
    content = fallbackMessage
  }

  const openNotificationDropdown = () => {
    dispatch(setTrue('showDropdownNotification'))
  }

  const momentTime = m(time)
  return (
    <Feed size="large" style={{ width: '100%' }} onClick={() => openNotificationDropdown()}>
      <Feed.Event>
        <Feed.Label
          style={{ paddingTop: '8px' }}
          icon={{ name: icon?.replace('_', ' ') || 'exclamation', circular: true, style: { color: txtColor } }}
        />
        <Feed.Content>
          <Feed.Summary style={{ color: txtColor || 'black' }}>
            {title}
            <Feed.Date style={{ float: 'right', marginRight: '15px' }}>
              {momentTime.format('DD/MM à HH:mm').replace('à', 'às')}
            </Feed.Date>
          </Feed.Summary>
          <Feed.Extra text style={{ color: txtColor || 'black' }}>
            <TableSpan
              value={confirmedBy && type === 'help' ? `Envio de ajuda confirmado por ${confirmedBy}` : content}
              width="95%"
              padding="0px"
            ></TableSpan>
          </Feed.Extra>
        </Feed.Content>
      </Feed.Event>
    </Feed>
  )
}
