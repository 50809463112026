import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useSignOut } from 'Utils/hooks/useLogout'
import { useTranslation } from 'react-i18next'

export const SignOut = () => {
  const handleLogout = useSignOut()
  const { t } = useTranslation()
  return <Dropdown.Item icon="sign out" text={t('sign-out')} key="sign-out" onClick={handleLogout} />
}
