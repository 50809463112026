import axios from 'axios'
import { URL_BASE } from './rest'

export const fetchNews = async () => {
  const token = localStorage.getItem('id_token')
  const news = await axios.get(URL_BASE + '/news', {
    headers: { Authorization: `Bearer ${token}` },
  })
  const byId = news.data.reduce((acc, news) => {
    news.id = news.datetime
    acc[news.id] = news
    return acc
  }, {})
  return { byId, allIds: news.data.map((news) => news.id) }
}

export const deleteNews = async (newsId) => {
  const token = localStorage.getItem('id_token')
  const news = await axios.delete(URL_BASE + '/news/' + newsId, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return news
}

export const createNews = async (news) => {
  const token = localStorage.getItem('id_token')
  const response = await axios.post(URL_BASE + '/news', news, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return response.data
}

export const updateNews = async (news) => {
  const token = localStorage.getItem('id_token')
  const response = await axios.put(URL_BASE + '/news/' + news.id, news, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return response.data
}
