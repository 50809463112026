import { DispatchWithoutAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { fetchAssets, fetchAssociations } from 'Store/actions/assets-action'
import { useDispatch } from 'react-redux'
import { fetchGeoModules, fetchGeoModulesByIds, fetchModules, subscribeGeoModule } from 'Store/actions/modules-action'
import { fetchNotifications, fetchEmergency, subscribeNotificationChange } from 'Store/actions/notification-action'
import { union } from 'lodash'
import { fetchRules, subscribeRuleChange, subscribeRuleDelete } from 'Store/actions/rules-action'
import { fetchAssetsLocation, subscribeAssetLocationChange } from 'Store/actions/measures-action'
import { fetchDevices } from 'Store/actions/devices-action'
import { fetchMessages } from 'Store/actions/messages-action'
import {
  fetchNotificationProfiles,
  subscribeNotificationProfileChange,
  subscribeNotificationProfileDelete,
} from 'Store/actions/notificationProfiles-action'
import { fetchDataLabelings, fetchMotionData } from 'Store/actions/dataLabelings-actions'
import { fetchActivities } from 'Store/actions/activities-action'
import { fetchMensurations } from 'Store/actions/mensurations-action'
import { fetchReports, subscribeReport } from 'Store/actions/reports-action'
import { fetchProfiles, subscribeProfile } from 'Store/actions/profiles-action'
import { fetchBetaDashboards } from 'Store/actions/betaDashboards-action'

/**
 * A union type of available fetch actions. Each action corresponds to a predefined data fetch function
 * and, optionally, a subscription to data changes.
 */
type FetchAction =
  | 'activities'
  | 'assets'
  | 'associations'
  | 'dataLabelings'
  | 'geoModules'
  | 'measures'
  | 'mensurations'
  | 'messages'
  | 'notifications'
  | 'notificationProfiles'
  | 'reports'
  | 'reportsBeta'
  | 'rules'
  | 'devices'
  | 'units'
  | 'fetchEmergency'
  | 'simulations'
  | 'dataLabelingMotionData'
  | 'geoModulesByIds'
  | 'profiles'
  | 'dashboards'

/**
 * Configuration for an individual fetch operation.
 */
type FetchesProps = {
  /**
   * The fetch action to execute. Must be one of the predefined fetch actions.
   */
  fetchAction: FetchAction
  /**
   * Optional parameters to pass to the fetch action.
   */
  fetchParams?: object
}

/**
 * A mapping of fetch actions to their corresponding fetch and subscription functions.
 */
type Fetches = {
  [key in FetchAction]: {
    fetch: any | null
    subscription?: any | null
  }
}

/**
 * Props for configuring the useFetchData hook.
 */
interface Props {
  /**
   * An array of fetch configurations to be executed in parallel.
   */
  independentFetches?: FetchesProps[]
  /**
   * An array of fetch configurations to be executed in series.
   */
  dependentFetches?: FetchesProps[]
  /**
   * An optional function to be called before starting the fetch operations.
   */
  startAction?: DispatchWithoutAction
  /**
   * An optional function to be called after all fetch operations have completed.
   */
  finishAction?: DispatchWithoutAction
  /**
   * An optional function to handle any errors that occur during the fetch operations.
   */
  errorAction?: (err: any) => void
}

let subs: any[] = []

/**
 * @example
 * ```tsx
 *   useFetchData({
 *     independentFetches: [
 *       { fetchAction: 'assets' },
 *       { fetchAction: 'devices' },
 *     ],
 *     dependentFetches: [
 *       { fetchAction: 'rules' },
 *       { fetchAction: 'notifications' },
 *     ],
 *     startAction: () => dispatch(startLoading()),
 *     finishAction: () => dispatch(finishLoading()),
 *     errorAction: (err) => {
 *       console.error('Error fetching data:', err)
 *       dispatch(showErrorNotification(err.message))
 *     },
 *   })
 * ``` 

* `useFetchData` is a custom React hook that manages data fetching and subscriptions in a standardized way.
 *
 * This hook allows you to specify data fetch actions to be executed either in parallel (through `independentFetches`) or in series (through `dependentFetches`),
 * and automatically handles the setup and cleanup of any associated subscriptions. It also allows you to specify actions to be dispatched at the start
 * and completion of the fetch process, as well as handle any errors that may occur.
 *
 * @param {Props} props - Configuration object for the hook.
 * @param {FetchesProps[]} [props.independentFetches] - An array of fetch configurations to be executed in parallel. Each configuration specifies a `fetchAction` and optional `fetchParams`.
 * @param {FetchesProps[]} [props.dependentFetches] - An array of fetch configurations to be executed in series (i.e., the next fetch starts after the previous one completes). Each configuration specifies a `fetchAction` and optional `fetchParams`.
 * @param {DispatchWithoutAction} [props.startAction] - An optional function to be called before starting the fetch operations.
 * @param {DispatchWithoutAction} [props.finishAction] - An optional function to be called after all fetch operations have completed.
 * @param {(err: any) => void} [props.errorAction] - An optional function to handle any errors that occur during the fetch operations.
 *
 */
const useFetchData = ({
  independentFetches = [],
  dependentFetches = [],
  startAction,
  finishAction,
  errorAction,
}: Props) => {
  const dispatch = useDispatch()

  const handleFetchError = useCallback(
    (err: any) => {
      console.error(err)
      if (errorAction) errorAction(err)
    },
    [errorAction]
  )

  const [independentFetchesFinish, setIndependentFetchesFinish] = useState(
    independentFetches?.length > 0 ? false : true
  )
  const [dependentFetchesFinish, setDependentFetchesFinish] = useState(dependentFetches?.length > 0 ? false : true)

  const allFetches: Fetches = useMemo(() => {
    const fetches: Fetches = {
      activities: {
        fetch: () => dispatch(fetchActivities()),
      },
      assets: {
        fetch: () => dispatch(fetchAssets()),
      },
      associations: {
        fetch: () => dispatch(fetchAssociations()),
      },
      dataLabelings: {
        fetch: () => dispatch(fetchDataLabelings()),
      },
      geoModules: {
        fetch: () => dispatch(fetchGeoModules()),
        subscription: () => ({
          change: dispatch(subscribeGeoModule('change')),
          delete: dispatch(subscribeGeoModule('delete')),
        }),
      },
      geoModulesByIds: {
        fetch: (params: any) => {
          return dispatch(fetchGeoModulesByIds(params || {}))
        },
        subscription: () => ({
          change: dispatch(subscribeGeoModule('change')),
          delete: dispatch(subscribeGeoModule('delete')),
        }),
      },
      measures: {
        fetch: () => dispatch(fetchAssetsLocation()),
        subscription: () => ({
          change: dispatch(subscribeAssetLocationChange()),
        }),
      },
      mensurations: {
        fetch: () => dispatch(fetchMensurations()),
      },
      messages: {
        fetch: () => dispatch(fetchMessages()),
      },
      notifications: {
        fetch: () => dispatch(fetchNotifications()),
        subscription: () => ({
          change: dispatch(subscribeNotificationChange('change')),
        }),
      },
      fetchEmergency: {
        fetch: () => dispatch(fetchEmergency()),
      },
      notificationProfiles: {
        fetch: () => dispatch(fetchNotificationProfiles()),
        subscription: () => ({
          change: dispatch(subscribeNotificationProfileChange()),
          delete: dispatch(subscribeNotificationProfileDelete()),
        }),
      },
      reports: {
        fetch: () => dispatch(fetchModules('reports')),
      },
      reportsBeta: {
        fetch: () => dispatch(fetchReports()),
        subscription: () => ({
          change: dispatch(subscribeReport('change')),
          delete: dispatch(subscribeReport('delete')),
        }),
      },
      rules: {
        fetch: () => dispatch(fetchRules()),
        subscription: () => ({
          change: dispatch(subscribeRuleChange()),
          delete: dispatch(subscribeRuleDelete()),
        }),
      },
      simulations: {
        fetch: () => dispatch(fetchModules('simulations')),
      },
      devices: {
        fetch: () => dispatch(fetchDevices()),
      },
      units: {
        fetch: () => dispatch(fetchModules('units')),
      },
      dataLabelingMotionData: {
        fetch: () => dispatch(fetchMotionData()),
      },
      profiles: {
        fetch: () => dispatch(fetchProfiles()),
        subscription: () => ({
          change: dispatch(subscribeProfile('change')),
          delete: dispatch(subscribeProfile('delete')),
        }),
      },
      dashboards: {
        fetch: () => dispatch(fetchBetaDashboards()),
        subscription: () => ({
          change: dispatch(subscribeProfile('change')),
          delete: dispatch(subscribeProfile('delete')),
        }),
      },
    }

    return fetches
  }, [dispatch])

  const independentFetchesStr = JSON.stringify(independentFetches)

  const isMounted = useRef(true)

  // Action to start before fetches
  useEffect(() => {
    if (startAction) startAction()
  }, [startAction])

  // Parallel fetches
  useEffect(() => {
    const independentFetchesParse: FetchesProps[] = JSON.parse(independentFetchesStr)
    const promises = independentFetchesParse.map(({ fetchAction, fetchParams }: FetchesProps) =>
      allFetches[fetchAction]?.fetch(fetchParams)
    )
    Promise.all(promises)
      .catch(handleFetchError)
      .finally(() => isMounted.current && setIndependentFetchesFinish(true))

    return () => {
      isMounted.current = false
    }
  }, [allFetches, independentFetchesStr, handleFetchError])

  const dependentFetchesStr = JSON.stringify(dependentFetches)

  // Serial fetches
  useEffect(() => {
    const dependentFetchesParse: FetchesProps[] = JSON.parse(dependentFetchesStr)
    dependentFetchesParse
      .reduce(async (previousPromise: any, { fetchAction, fetchParams }: FetchesProps) => {
        await previousPromise
        return allFetches[fetchAction]?.fetch?.(fetchParams)
      }, Promise.resolve())
      .catch(handleFetchError)
      .finally(() => isMounted.current && setDependentFetchesFinish(true))

    return () => {
      isMounted.current = false
    }
  }, [allFetches, dependentFetchesStr, handleFetchError])

  // Subscriptions
  useEffect(() => {
    const unionFetches = union(JSON.parse(dependentFetchesStr), JSON.parse(independentFetchesStr))

    unionFetches.forEach((fetch: any) => {
      const fetchAction = allFetches[fetch.fetchAction as FetchAction]
      if (fetchAction?.subscription) {
        const subscription = fetchAction.subscription()
        if (subscription) {
          subs.push(subscription)
        }
      }
    })

    // Cleanup subscriptions when the component unmounts or dependencies change
    return () => {
      subs.forEach((sub) => {
        sub.change?.unsubscribe()
        sub.delete?.unsubscribe()
      })
    }
  }, [dependentFetchesStr, independentFetchesStr, allFetches])

  // Final action after fetches
  useEffect(() => {
    if (dependentFetchesFinish && independentFetchesFinish) {
      if (finishAction) {
        finishAction()
      }
      setDependentFetchesFinish(false)
      setIndependentFetchesFinish(false)
    }
  }, [dependentFetchesFinish, independentFetchesFinish, finishAction])
}

export default useFetchData
