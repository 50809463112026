import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { StatsBarFormGeoArea } from './StatsBarFormGeoArea'
import { StatsBarFormGeoLength } from './StatsBarFormGeoLength'
import useEntitySelector from 'Utils/hooks/useEntitySelector'

const FormStatsBarGeo = () => {
  const { t } = useTranslation()

  const geometry = useEntitySelector('modules', 'geometry')
  const type = useEntitySelector('modules', 'type')

  return type === 'refs' ? (
    <Form>
      <Form.Field
        id="form-lat"
        control={Input}
        label={t('lat')}
        placeholder={t('lat')}
        value={geometry?.coordinates[1] || 'missing Lat'}
      />
      <Form.Field
        id="form-lng"
        control={Input}
        label={t('lng')}
        placeholder={t('lng')}
        value={geometry?.coordinates[0] || 'missing Lng'}
      />
    </Form>
  ) : type === 'routes' ? (
    <StatsBarFormGeoLength geometry={geometry} />
  ) : (
    <StatsBarFormGeoArea geometry={geometry} />
  )
}
export default FormStatsBarGeo
