import React from 'react'
import SpanEditable from 'Utils/components/SpanEditable'
/*  Author: Bruno Melo / Luís Mourão
    Type: Dumb
	Description: Title with asset's name,
				 Input to change the asset's name				 
	To do list: -
				
*/
const PopupTitle = (props) => {
  return <SpanEditable assetId={props.assetId} assetName={props.assetName} chars={22} />
}

export default PopupTitle
