import React from 'react'
import { TableColumn } from '../types'
import { TFunction } from 'i18next'
import TableSpan from 'Components/SidebarMenu/TableSpan'
// import { Checkbox } from 'semantic-ui-react'
import { DateTime } from 'Utils/reactTable/components/DateTime'
//import { threeColumns } from 'Utils/threeColumns'

export const MessageColumns = (t: TFunction) => {
  return [
    {
      Header: 'ID',
      accessor: 'id',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} ids`,
      Cell: (c) => c.value.split('#')[1],
    },
    {
      Header: 'Nome',
      accessor: 'label',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
    // {
    //   Header: 'Status',
    //   accessor: 'status',
    //   aggregate: 'uniqueCount',
    //   Aggregated: ({ value }) => `${value} adicionais`,
    //   Cell: ({ value }) => <Checkbox toggle /*title={value.toString()}*/ checked={value ? true : false} />,
    // },
    {
      Header: 'Tipo',
      accessor: 'type',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'tipos'}`,
    },
    {
      Header: 'Título',
      accessor: 'title',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'títulos'}`,
    },
    {
      Header: 'Corpo',
      accessor: 'body',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'regras'}`,
      Cell: ({ value, column }) => <TableSpan value={value} width={column.width + 'px'} capitalize={false} />,
    },
    /*{
      Header: 'Adicional',
      accessor: 'extra',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} adicionais`,
    },*/
    {
      Header: 'Atualizado em',
      accessor: 'updatedAt',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} hour`,
      Cell: ({ value }) => <DateTime value={value} />,
    },
    {
      Header: 'Atualizado por',
      accessor: 'updatedBy',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} login`,
      Cell: ({ value }) => <span>{value ? value : 'ADM'}</span>,
    },
    {
      Header: 'Atualizado de',
      accessor: 'updatedFrom',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} fontes`,
      Cell: ({ value }) => <span>{value ? value : 'Plataforma'}</span>,
    },
    {
      Header: 'Criado em',
      accessor: 'createdAt',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} hour`,
      Cell: ({ value }) => <DateTime value={value} />,
    },
    {
      Header: 'Criado por',
      accessor: 'createdBy',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} login`,
      Cell: ({ value }) => <span>{value ? value : 'ADM'}</span>,
    },
    {
      Header: 'Criado de',
      accessor: 'createdFrom',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} fontes`,
      Cell: ({ value }) => <span>{value ? value : 'Plataforma'}</span>,
    },
    //@ts-ignore
    // ...threeColumns(t),
  ] as TableColumn[]
}
