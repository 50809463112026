import React, { useMemo, useState } from 'react'
import ModalHeader from 'Utils/modals/ModalHeader'
import { Modal, Divider, Grid, Dropdown, Popup, Button } from 'semantic-ui-react'
import {
  LineChart as LineGraph,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from 'recharts'
import { semanticColors } from 'Utils/colorsFormat'
import colorOptions from 'Utils/theme/colors'
import styled from 'styled-components'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import m from 'moment'
import { round } from 'lodash'
import ErrorBoundary from 'Utils/ErrorBoundary/ErrorBoundary'
import { setTableProp } from 'Store/actions/tables-action'
import { useDispatch } from 'react-redux'

const style = {
  borderRadius: 0,
  opacity: 0.7,
  padding: '2em',
}

const GridHeader = styled(Grid)`
  margin-top: 3vh !important;
  margin-left: 5.5vw !important;
  margin-right: 2vw !important;
`

const getDistinctAssetsIds = (data) => {
  return data.reduce((prev, current) => {
    if (prev.includes(current.assetName)) {
      return prev
    } else return [...prev, current.assetName]
  }, [])
}

function LineChartView({ tableName, fetchAction }) {
  const dispatch = useDispatch()
  const selectedPreferenceMeasures = useObjSelector((state) => {
    return state.login.preferences.routesPanels.byId[tableName].filters.find((e) => e.id === 'measure').value
  })
  const selectedPanelMeasures = useObjSelector((state) => state.tables.byId[tableName]?.selectedMeasures)
  const selectedMeasures = selectedPanelMeasures?.length > 0 ? selectedPanelMeasures : selectedPreferenceMeasures

  const measuresHistData = useObjSelector((state) => state.tables.byId.measuresHist.data)

  const distinctAssetsIds = useMemo(() => getDistinctAssetsIds(measuresHistData), [measuresHistData])
  const [selectedChartsAssets, setSelectedChartsAssets] = useState(distinctAssetsIds[0])

  const [selectedChartsMeasures, setSelectedChartsMeasures] = useState(selectedMeasures)

  const chartData = useMemo(
    () =>
      measuresHistData.reduce((prev, current) => {
        if (selectedChartsAssets === current.assetName) {
          return [...prev, { ...current, time: new Date(current.time).getTime() }]
        } else return prev
      }, []),
    [measuresHistData, selectedChartsAssets]
  )
  return (
    <ErrorBoundary onError={() => dispatch(setTableProp(`byId.${tableName}.view`, 'table'))}>
      <div style={{ marginBottom: '10vh' }}>
        <ModalHeader
          name={tableName}
          menuButtons={[
            'histFilter',
            'refresh',
            'viewOptions',
            // 'export',
          ]}
          noFilter
          fetchAction={fetchAction}
          style={{ padding: '0', height: '10vh' }}
          showButtonBar={false}
        />
        <Divider />
        <Modal.Content
          style={{
            overflowY: 'auto',
            height: '70vh',
            border: 'none',
            boxShadow: 'none',
            marginLeft: '2vw',
          }}
        >
          <div style={{ width: '85vw', height: '50vh' }}>
            <GridHeader columns={3}>
              <Grid.Column floated="left" width={4}>
                <Dropdown
                  placeholder="Ativos/Pessoas"
                  selection
                  options={distinctAssetsIds.map((e) => ({ key: e, text: e, value: e })) || []}
                  onChange={(c, { value }) => setSelectedChartsAssets(value)}
                  value={selectedChartsAssets || distinctAssetsIds[0]}
                />
              </Grid.Column>
              <Grid.Column floated="left" width={11}>
                <Dropdown
                  placeholder="Medidas"
                  multiple
                  selection
                  options={selectedMeasures.map((e) => ({ key: e, text: e, value: e }))}
                  onChange={(c, { value }) => setSelectedChartsMeasures(value)}
                  value={selectedChartsMeasures}
                />
              </Grid.Column>
              <Grid.Column floated="right" width={1}>
                <Popup
                  trigger={
                    <Button
                      icon={{ name: 'info circle', style: { paddingLeft: '0px' } }}
                      style={{ float: 'right' }}
                      size="tiny"
                    />
                  }
                  content="Essa gráfico pode conter apenas medidas numéricas. 
              As medidas não numéricas são visualizadas apenas no tooltip dos respectivos pontos."
                  // "Múltipla seleção de ativos/pessoas só é possível quando apenas uma medida for selecionada.
                  // Da mesma forma, múltipla seleção de medidas só é possível quando apenas um ativo/pessoa é selecionado"
                  style={style}
                  inverted
                  position="left center"
                />
              </Grid.Column>
            </GridHeader>
            <ResponsiveContainer width="100%" height="100%">
              <LineGraph
                width={500}
                height={300}
                data={chartData}
                margin={{
                  top: 45,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  tickFormatter={(time) => m(time).format('DD/MM HH:mm')}
                  scale="time"
                  type="number"
                  domain={['auto', 'auto']}
                />
                <YAxis
                  domain={[
                    (dataMin) => {
                      return dataMin ? (round(dataMin / 5) - 1) * 5 : 0
                    },
                    (dataMax) => (dataMax ? (round(dataMax / 5) + 1) * 5 : 1),
                  ]}
                  type="number"
                  allowDecimals={false}
                />
                <Tooltip labelFormatter={(label) => m(label).format('DD/MM HH:mm')} />
                <Legend wrapperStyle={{ top: '98%' }} />
                <Brush
                  data={chartData}
                  dataKey="level"
                  height={30}
                  stroke="#4b72b2"
                  startIndex={chartData?.length > 200 ? chartData?.length - 200 : 0}
                />
                {selectedChartsMeasures.map((each, i) => {
                  const lineColor = semanticColors(colorOptions[i]?.value)
                  return <Line key={i} type="monotone" dataKey={each} stroke={lineColor} fill={lineColor} />
                })}
              </LineGraph>
            </ResponsiveContainer>
          </div>
        </Modal.Content>
      </div>
    </ErrorBoundary>
  )
}

export default LineChartView
