import React from 'react'
import styled from 'styled-components'
import { HistoryInfo } from './HistoryInfo.jsx'
import { Grid, Icon } from 'semantic-ui-react'
import { HistoryIcons } from './HistoryIcons.jsx'
import TimeSlider from './TimeSlider.js'
import HistCalendar from './HistCalendar'
import { useDispatch, useSelector } from 'react-redux'
import { emptyHistory } from 'Store/actions/history-action'
import { setFalse } from 'Store/actions/toggle-action'

/*  Author: Bruno Melo
    Type: Dumb
	Description:  Controls the second bar design. 
	TODO: - 
*/

const HistoryBarContainer = styled.div`
  /*border: 1px red solid;*/
  padding-left: 0px !important;
  height: 46px;
  width: 100vw;
  background-color: white;
  border-top: 0.5px solid gray;
  text-align: center;
`
const HistBarGridContainer = styled(Grid)`
  height: 46px;
`
const HistBarGridInfo = styled(Grid.Row)`
  height: 26px;
  padding: 0px !important;
`
const HistBarGridSlider = styled(Grid.Row)`
  height: 20px;
  padding: 0px !important;
`
const HistBarX = styled(Icon)`
  top: 0px;
  right: 0px;
  position: absolute;
  float: right;
  cursor: pointer;
  z-index: 10;
`

export const HistoryBarGrid = ({ t, histInfoProps, histIconProps, sliderProps, calendarProps, showIcons }) => {
  const dispatch = useDispatch()

  const showHistoryCalendar = useSelector((state) => state.toggle.showHistoryCalendar)
  const histType = useSelector((state) => state.history.type)

  function cancelHistory() {
    switch (histType) {
      case 'heatmap':
        dispatch(setFalse(['showHeatMap']))
        dispatch(emptyHistory())
        break
      default:
        dispatch(emptyHistory())
        break
    }
  }

  return (
    <HistoryBarContainer style={{ display: histType === 'holesLines' ? 'none' : 'block' }}>
      <HistBarGridContainer columns={2}>
        <HistBarGridInfo centered>
          <HistBarX onClick={() => cancelHistory()} color="blue" name="close" title={t('HistoryCancel')} />
          <HistoryInfo {...histInfoProps} />
          {showIcons && <HistoryIcons {...histIconProps} />}
        </HistBarGridInfo>
        {['discrete', 'antpath'].includes(histType) && (
          <HistBarGridSlider centered columns={1}>
            <TimeSlider {...sliderProps} />
          </HistBarGridSlider>
        )}
        {showHistoryCalendar && <HistCalendar {...calendarProps} />}
      </HistBarGridContainer>
    </HistoryBarContainer>
  )
}
export default HistoryBarGrid
