import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useObjSelector } from '../../../../Utils/hooks/useObjSelector'
import { RootStore } from '../../../../Store/initialStore'
import { fetchCronologyData, setDataLabelingWIP, setDataLabelingsProp } from 'Store/actions/dataLabelings-actions'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, Menu, Breadcrumb, Header } from 'semantic-ui-react'
import styled from 'styled-components'
import { DataLabelingsResumeTable } from 'Components/SidebarMenu/Contents/DataLabelings/DataLabelingResumeTable'
import { DataLabelingMensurationTable } from 'Components/SidebarMenu/Contents/DataLabelings/DataLabelingMensurationTable'
import GridLayout from 'react-grid-layout'
import ErrorBoundary from 'Utils/ErrorBoundary/ErrorBoundary'
import AppFallback from 'Utils/ErrorBoundary/Fallbacks/AppFallback'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { notifySuccess, notifyError } from 'Utils/components/SystemToasts'
import { upgradeSwk } from './swk'
import DataLabelingStepTable from './DataLabelingStepTable'
import { Link, useParams } from 'react-router-dom'
import useFetchData from 'Utils/hooks/useFetchData'
import DataLabelingYamazumi from './DataLabelingYamazumi'

const DivGrid = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px #ccc solid;
  border-radius: 15px;
  padding: 10px;
`

type Payload = {
  attribute: string
}

interface CronologyProps {}

const BreadcrumbDataLabeling = (props: any) => {
  var timeLabel = new Date(props.time)
  const { t } = props
  return (
    <Breadcrumb size={'big'}>
      <Breadcrumb.Section>
        <Link to={'/app/dataLabelings'}>{props.entityLabel}</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      <Breadcrumb.Section>
        <span title={t('resume')}>{t('resume')}</span>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      <Breadcrumb.Section
        active
        style={{
          maxWidth: '50vw',
        }}
        title={'Um dos Postos de Trabalho'}
      >
        <span
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%' }}
          title={props.ids}
        >
          {props.ids}
        </span>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      <Breadcrumb.Section
        active
        style={{
          maxWidth: '50vw',
        }}
      >
        <span
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%' }}
          title={props.time}
        >
          {timeLabel.toLocaleDateString()}
        </span>
      </Breadcrumb.Section>
    </Breadcrumb>
  )
}

const DataLabelingCronology: React.FC<CronologyProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  useFetchData({
    independentFetches: [{ fetchAction: 'dataLabelings' }],
    finishAction: () => setLoading(false),
  })

  const { ids } = useParams()

  const selectedDataLabelingsIds = useMemo(() => {
    return ids?.split(',') || []
  }, [ids])

  const windowWidth = 2000

  const dataLabelingsById = useObjSelector((state: RootStore) => state.dataLabelings.byId)
  const openSWKModal = useSelector((state: RootStore) => state.dataLabelings.openSWKModal)
  const [loadingButton, setLoadingButton] = useState(false)
  const [currentShift, setCurrentShift] = useState('')
  const [currentCar, setCurrentCar] = useState('')

  const [layout, setLayout] = React.useState([
    { i: 'a', x: 0, y: 0, w: 5, h: 9, minW: 2, minH: 2 },
    { i: 'b', x: 5, y: 0, w: 6, h: 9, minW: 2, minH: 2 },
    { i: 'c', x: 0, y: 9, w: 5, h: 11, minW: 2, minH: 2 },
    { i: 'd', x: 5, y: 9, w: 5, h: 11, minW: 2, minH: 2 },
    { i: 'e', x: 10, y: 9, w: 1, h: 11, minW: 1, minH: 2 },
  ])

  const email = useSelector((state: RootStore) => state.login.email)
  const entityLabel = useSelector((state: RootStore) => state.login.preferences.routesPanels.byId.dataLabelings.label)

  const dataAPI = useObjSelector((state) => state.dataLabelings.cronology)

  let dataAPIStr = ''
  if (dataAPI) {
    dataAPIStr = JSON.stringify(dataAPI)
  }

  const lastDataLabelingId = useMemo(() => {
    let lastDataLabelingUpdate = null
    if (dataAPIStr) {
      const dataAPIObj = JSON.parse(dataAPIStr)
      const { items } = dataAPIObj
      if (Array.isArray(items) && items.length > 0) {
        lastDataLabelingUpdate = items.reduce(
          (max: any, obj: any) => (max.updatedAt > obj.updatedAt ? max : obj),
          items[0]
        )
      }
    }
    return lastDataLabelingUpdate?.id
  }, [dataAPIStr])

  useEffect(() => {
    dispatch(setDataLabelingWIP(lastDataLabelingId))
  }, [dispatch, lastDataLabelingId])

  const lastDataLabeling = useObjSelector((state) => state.dataLabelings.wip)

  const thisData: any = dataAPI.rechartsYamazumi
  const { uniquePhases, uniqueShifts, uniqueVehicles, uniqueCycles, mensurationData, vehicleParam, shiftParam } =
    dataAPI

  const vaNva = useMemo(() => {
    let byCar = {}
    if (lastDataLabeling?.phases) {
      byCar = lastDataLabeling?.phases.reduce((acc: any, phase) => {
        const { vehicle: vehicles, va, nva } = phase
        if (vehicles) {
          vehicles.forEach((vehicle) => {
            if (vehicle.name && uniqueVehicles.includes(vehicle.name)) {
              if (acc[vehicle.name]) {
                acc[vehicle.name].va += va
                acc[vehicle.name].nva += nva
              } else {
                acc[vehicle.name] = {
                  name: vehicle.name,
                  va,
                  nva,
                }
              }
            }
          })
        }
        return acc
      }, {})
    }
    return Object.values(byCar)
  }, [lastDataLabeling, uniqueVehicles])

  const valueAccessor =
    (attribute: string) =>
    ({ payload }: { payload: Payload }) => {
      return payload[attribute] || ''
    }

  useEffect(() => {
    if (selectedDataLabelingsIds.length > 0 && !loading) {
      dispatch(fetchCronologyData(selectedDataLabelingsIds, currentCar, currentShift, t))
    }
  }, [dispatch, selectedDataLabelingsIds, currentCar, currentShift, t, loading])

  return (
    <ErrorBoundary FallbackComponent={AppFallback}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '2px',
          marginTop: '0',
          marginBottom: '15px',
          justifyContent: 'space-between',
          marginRight: '120px',
        }}
      >
        <BreadcrumbDataLabeling
          ids={dataLabelingsById[selectedDataLabelingsIds[0]]?.activity?.name}
          time={lastDataLabeling?.startTime}
          t={t}
          entityLabel={entityLabel}
        />
        <Menu icon="labeled" size="mini" style={{ height: '44px', width: `${90}px`, marginTop: '0px' }}>
          <Menu.Item key={'exportPDF'} name={'save2'} style={{ padding: '4px', minWidth: '4em', width: '90px' }}>
            <Icon name={'download'} style={{ marginBottom: '2px !important' }} />
            <span style={{ fontSize: 'x-small' }}>{t('ExportToPDF')}</span>
          </Menu.Item>
          <Menu.Item
            key={'modelAI'}
            name={'save'}
            onClick={() => dispatch(setDataLabelingsProp('openSWKModal', true))}
            style={{ padding: '4px', minWidth: '4em', width: '90px' }}
          >
            <Icon name={'save'} style={{ marginBottom: '2px !important' }} />
            <span style={{ fontSize: 'x-small' }}>{t('UpdateSW&K')}</span>
          </Menu.Item>
        </Menu>
        <ModalTemplate
          open={!!openSWKModal}
          onClose={() => dispatch(setDataLabelingsProp('openSWKModal', false))}
          header={<span>{t('NewAIModel')}</span>}
          saveContent={t('Confirm')}
          onSave={async () => {
            setLoadingButton(true)
            try {
              await upgradeSwk({
                email,
                labelings: selectedDataLabelingsIds,
                vehicle: currentCar,
                shift: currentShift,
              })
              notifySuccess()
            } catch (err) {
              console.error(err)
              notifyError()
            } finally {
              setLoadingButton(false)
              dispatch(setDataLabelingsProp('openSWKModal', false))
            }
          }}
          cancelContent={t('Cancel')}
          onCancel={() => dispatch(setDataLabelingsProp('openSWKModal', false))}
          children={
            <>
              <p>
                Ao confirmar, um novo modelo de <em>Inteligência Artificial</em> será criado.
              </p>
              <ul>
                <li>Você verá a precisão e demais informações do modelo de Inteligência Artificial</li>
                <li style={{ marginTop: '5px' }}>
                  Assim que o processo estiver concluído, enviaremos uma notificação por e-mail
                </li>
              </ul>
            </>
          }
          loading={loadingButton}
        />
      </div>
      <GridLayout
        className="layout"
        layout={layout}
        cols={12}
        rowHeight={30}
        width={windowWidth}
        onLayoutChange={(layout: any) => setLayout(layout)}
        style={{ minHeight: '81vh', overflowY: 'auto' }}
      >
        <DivGrid key="a">
          <div className="row" style={{ display: 'flex' }}>
            <div className="column" style={{ flex: 13 }}>
              <Header as="h3">{t('resume')}</Header>
            </div>
          </div>

          <DataLabelingsResumeTable
            cars={uniqueVehicles
              ?.sort((a: string, b: string) => a?.toLowerCase()?.localeCompare(b.toLowerCase()))
              ?.map((car) => ({
                carName: car?.toString() || '',
                phases: lastDataLabeling?.labelings
                  ?.filter((lb: any) => lb.vehicle === car)
                  .map((lbm: any) => lbm.index),
              }))}
            phases={lastDataLabeling?.phases}
            lastDataLabelingId={lastDataLabeling?.id}
          />
        </DivGrid>
        <DivGrid key="b">
          <DataLabelingYamazumi
            thisData={thisData}
            vaNva={vaNva}
            uniqueShifts={uniqueShifts}
            uniquePhases={uniquePhases}
            uniqueVehicles={uniqueVehicles}
            setCurrentCar={setCurrentCar}
            setCurrentShift={setCurrentShift}
            vehicleParam={vehicleParam}
            shiftParam={shiftParam}
            lastDataLabeling={lastDataLabeling}
            t={t}
            valueAccessor={valueAccessor}
          />
        </DivGrid>
        <DivGrid key="c">
          {shiftParam ? (
            <h3>
              {t('Mensuration')}: {vehicleParam} - {t('shift')}: {shiftParam}
            </h3>
          ) : (
            <h3>Clique em uma das barras do Yamazumi</h3>
          )}
          {shiftParam && (
            <DataLabelingMensurationTable dataMensurations={mensurationData} uniqueCycles={uniqueCycles} />
          )}
        </DivGrid>
        <DivGrid key="d">
          <div>
            <h3>{t('spaghettiChart')}</h3>
            <img
              src="https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/spaguetti.gif"
              alt="spaghetti"
              style={{ width: 763, height: 370 }}
            />
          </div>
        </DivGrid>
        <DivGrid key="e">
          <div>
            <h3>{t('Steps')}</h3>
            <DataLabelingStepTable dataMensurations={mensurationData} />
          </div>
        </DivGrid>
      </GridLayout>
    </ErrorBoundary>
  )
}

export default DataLabelingCronology
