import moment from 'moment'

export enum RelativeDate {
  'TODAY' = '1d',
  'LAST_WEEK' = '7d',
  'LAST_MONTH' = '30d',
  'LAST_QUARTER' = '90d',
}

export const convertRelativeDateToAbsolute = (relativeDate: RelativeDate) => {
  const end = moment().toISOString()
  const relativeDateInDays = relativeDate.replace('d', '')
  const start = moment().subtract(relativeDateInDays, 'days').toISOString()
  return {
    start,
    end,
  }
}
