/*  
  Author: Bruno Melo
  Type: Component  
  Description: Dashboard com regras de negócio e kpi's
  To do: 
*/
import './react-grid-layout.css'
import './react-resizable.css'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DashboardPanel from './DashboardPanel'
import GridLayout from 'react-grid-layout'
import { changeDashboardLayout, saveLoginPreferences } from 'Store/actions/login-action'
import { debounce } from 'lodash'
import Loading from 'Utils/components/Loading'
import useFetchData from 'Utils/hooks/useFetchData'

const DashboardPanels = ({ windowWidth = 2000 }) => {
  const [staticMode, setStaticMode] = useState(false)
  const [loadingReports, setLoadingReports] = useState(true)
  const dashboardPrefs = useSelector((state) => state.login.preferences.routesPanels.byId['dashboard'])
  const dashboardStaticMode = useSelector((state) => state.login.preferences.routesPanels.byId['dashboard'].staticMode)
  const loading = useSelector((state) => state.login.loading)
  useFetchData({
    independentFetches: [{ fetchAction: 'reports' }],
    finishAction: () => setLoadingReports(false),
  })
  const dispatch = useDispatch()

  const debouncedChangeHandler = useMemo(() => debounce((layout) => dispatch(saveLoginPreferences()), 3000), [dispatch])

  let staticLayout = dashboardPrefs.layout.map((l) => ({ ...l, static: dashboardStaticMode || staticMode }))
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  const handleLayoutChange = (layout) => {
    if (layout !== dashboardPrefs.layout && !staticMode) {
      dispatch(changeDashboardLayout(layout))
      debouncedChangeHandler()
    }
  }

  const changeHandleMode = (mode) => {
    setStaticMode(mode)
  }

  const NewReturn =
    loadingReports || loading ? (
      <Loading />
    ) : (
      <GridLayout
        className="layout"
        layout={staticLayout}
        cols={12}
        rowHeight={30}
        width={windowWidth}
        onLayoutChange={(layout) => handleLayoutChange(layout)}
      >
        {dashboardPrefs?.panelsIds
          // ?.filter((e) => e === 'panel03')
          ?.map((panel, i) => (
            <div key={i}>
              <DashboardPanel
                layoutStats={staticLayout[i]}
                key={panel}
                panelId={panel}
                setStaticMode={(mode) => changeHandleMode(mode)}
              />
            </div>
          ))}
      </GridLayout>
    )

  return NewReturn
}

export default DashboardPanels
