import React, { useEffect } from 'react'
import { Label, Dropdown, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { setAutoUpdate, setShowInput } from 'Store/actions/tables-action'
import { useDispatch } from 'react-redux'
import { useCanAccess } from 'Utils/rbac'

export const InputTemplate = ({
  value,
  options,
  action,
  field,
  id,
  table,
  type = 'text',
  limitSize = null,
  setCurrent = () => null,
}) => {
  const dispatch = useDispatch()
  const canAccess = useCanAccess('WRITE')
  const [currentValue, setCurrentValue] = React.useState(value)
  const [errorState] = React.useState('')

  const { t } = useTranslation()

  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleItemChange = (val) => {
    setCurrentValue(val)
    closeEdit()
    action(id, field, val)
  }

  const handleInputChange = () => {
    closeEdit()
    action(id, field, currentValue)
  }

  function handleChangeValue(e, { value }) {
    if (limitSize && typeof limitSize === 'number') setCurrentValue(value?.slice(0, limitSize))
    else setCurrentValue(value)
  }

  const handleEnter = (ev) => {
    if (ev.charCode === 13) {
      handleInputChange()
    }
  }

  const getText = (id) => {
    let res
    const isEmpty = options && Object.keys(options).length === 0 && Object.getPrototypeOf(options) === Object.prototype
    if (!isEmpty) {
      res = options.filter((o) => o.value === id)
      return res[0]?.text || 'Sem valor'
    } else {
      return 'Sem valor'
    }
  }

  const openInput = () => {
    dispatch(setAutoUpdate(table, false))
    dispatch(setShowInput(table, true))
    setCurrent()
    setOpen(true)
  }

  function closeEdit() {
    dispatch(setShowInput(table, false))
    setOpen(false)
  }

  return !canAccess ? (
    <span>{options ? getText(currentValue) : currentValue}</span>
  ) : open ? (
    options ? (
      <Dropdown
        placeholder={field.endsWith('color') ? t(currentValue) : getText(currentValue)}
        search
        selection
        additionLabel="Adicionar"
        noResultsMessage="Nenhum resultado..."
        options={options}
        value={currentValue}
        onChange={(e, { value }) => handleItemChange(value)}
        onClose={() => closeEdit()}
      />
    ) : (
      <Input
        focus
        autoFocus
        fluid
        size="mini"
        placeholder={'Digite...'}
        required
        value={currentValue}
        onChange={handleChangeValue}
        onKeyPress={handleEnter}
        onBlur={() => closeEdit()}
        label={{ active: false, color: errorState ? 'red' : undefined, content: errorState }}
        labelPosition="right"
        type={type || 'text'}
      />
    )
  ) : field.endsWith('color') ? (
    <Label
      circular
      color={currentValue || 'blue'}
      onClick={() => openInput()}
      style={{ cursor: 'pointer' }}
      title={t('clickToEdit')}
    >
      {t(currentValue || 'blue')}
    </Label>
  ) : (
    <span onClick={() => openInput()} style={{ cursor: 'pointer' }} title={t('clickToEdit')}>
      {options ? getText(currentValue) : currentValue !== '' ? currentValue : '-'}
    </span>
  )
}

export default InputTemplate
