import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { showConfirm, hideConfirm } from 'Store/actions/system-action'
import { deleteGeoModules } from 'Store/actions/modules-action'
import { setFalse } from 'Store/actions/toggle-action'

interface Props {
  id: string
  moduleType: string
  label?: string | null
}

const SideFormDeleteAction: FC<Props> = ({ id, moduleType, label }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClick = () => {
    const data = {
      content: (
        <div style={{ height: 'auto', fontSize: '1.5em', padding: '10px' }}>
          <span>{t('areYouSureYouWishToContinue')}</span>
        </div>
      ),
      onConfirm: () => {
        dispatch(deleteGeoModules({ type: moduleType, ids: [id] }))
        dispatch(hideConfirm())
        dispatch(setFalse('showStatsBar'))
      },
      onCancel: () => dispatch(hideConfirm()),
      confirmHeader: `${t('lbldelete')} ${t(moduleType.slice(0, -1))} "${label}"`,
    }
    dispatch(showConfirm(data))
  }

  return (
    <Button
      circular
      icon="trash alternate outline"
      title={t('lbldelete')}
      style={{
        cursor: 'pointer',
      }}
      onClick={handleClick}
    />
  )
}
export default SideFormDeleteAction
