import React, { useState } from 'react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Label } from 'semantic-ui-react'
import { setShowModal, setTableProp } from 'Store/actions/tables-action'
import { setNewRoutePanel } from 'Store/actions/login-action'
import MeasuresHistFilterHeader from 'Utils/reactTable/modals/measuresHist/MeasuresHistFilterHeader'
import { saveLoginPreferences } from 'Store/actions/login-action'

function ConfirmPanelSave() {
  const dispatch = useDispatch()
  const currentTableId = useSelector((state) => state.tables.current?.id ?? 'measuresHist')
  const showModal = useSelector((state) => state.tables.byId.measuresHist.showSaveModal)
  const selectedRelativeTime = useSelector((state) => state.tables.byId[currentTableId].selectedRelativeTime)
  const selectedAsset = useSelector((state) => state.tables.byId[currentTableId].selectedAsset)
  const selectedCategory = useSelector((state) => state.tables.byId[currentTableId].selectedCategory)
  const selectedMeasures = useSelector((state) => state.tables.byId[currentTableId].selectedMeasures)
  const routesPanelsStr = useSelector((state) => JSON.stringify(state.login.preferences.routesPanels))
  const routesPanels = JSON.parse(routesPanelsStr)

  const [nameInput, setNameInput] = useState(currentTableId !== 'measuresHist' ? currentTableId : null)
  const [errorMsg, setErrorMsg] = useState(false)

  const saveNewTable = () => {
    const nameExist =
      currentTableId === 'measuresHist' &&
      routesPanels.allIds.find((id) => routesPanels.byId[id].label === nameInput || id === nameInput)
    if (nameInput && !nameExist) {
      dispatch(
        setNewRoutePanel({
          id: nameInput,
          type: 'created',
          label: nameInput,
          icon: 'newspaper',
          resource: 'measuresHist',
          filters: [
            {
              id: 'relativeTime',
              icon: 'calendar',
              title: 'Intervalo',
              description: 'Selecione o Intervalo de Tempo',
              value: selectedRelativeTime,
            },
            {
              id: 'asset',
              icon: 'user',
              title: 'Pessoa/Ativo',
              description: 'Selecione o Ativo',
              value: selectedAsset,
            },
            {
              id: 'category',
              icon: 'sitemap',
              title: 'Categoria',
              description: 'Selecione a Categoria',
              value: selectedCategory,
            },
            {
              id: 'measure',
              icon: 'info circle',
              title: 'Medida',
              description: 'Selecione a Medida',
              value: selectedMeasures,
            },
          ],
          route: '/' + nameInput,
        })
      )
      dispatch(setTableProp('byId.measuresHist.showSaveModal', false))
      dispatch(saveLoginPreferences())
      dispatch(setShowModal('measuresHist', false))
    } else {
      nameExist ? setErrorMsg('nameExist') : setErrorMsg('emptyInput')
    }
  }

  return (
    showModal && (
      <ModalTemplate
        size="large"
        open
        header={
          <>
            <label>
              {currentTableId !== 'measuresHist'
                ? 'Deseja salvar esse novo filtro para essa tabela?'
                : 'Deseja salvar uma nova tabela com esse filtro?'}
            </label>
            <MeasuresHistFilterHeader disabled />
          </>
        }
        onClose={() => {
          dispatch(setTableProp('byId.measuresHist.showSaveModal', false))
          dispatch(setShowModal('measuresHist', false))
        }}
        onCancel={() => {
          dispatch(setTableProp('byId.measuresHist.showSaveModal', false))
          dispatch(setShowModal('measuresHist', false))
        }}
        onSave={saveNewTable}
      >
        {currentTableId === 'measuresHist' && (
          <Form style={{ marginTop: '25px', marginBottom: '25px' }}>
            <Form.Field inline required error={errorMsg}>
              <label>Informe o nome da nova tabela</label>
              <Input
                name="name"
                fluid
                placeholder="Nome..."
                value={nameInput ?? ''}
                onChange={(e, { value }) => setNameInput(value)}
              />
              {errorMsg === 'nameExist' ? (
                <Label color="red" pointing>
                  Já existe uma tabela identificada com esse nome
                </Label>
              ) : (
                ''
              )}
            </Form.Field>
          </Form>
        )}
      </ModalTemplate>
    )
  )
}

export default ConfirmPanelSave
