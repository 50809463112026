import axios, { AxiosError } from 'axios'
import { ACCESS_CONTROL_BASE_URL } from './utils'
import React, { Fragment, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Button,
  Grid,
  Header,
  Message,
  Loader,
  Segment,
  Select,
  DropdownProps,
  Input,
  Dropdown,
  DropdownItemProps,
  TextArea,
} from 'semantic-ui-react'
import moment from 'moment'

type TParams = { hash: string }
interface IData {
  name: string
  email: string
  hash: string
  beginning: number
  expiration: number
  tenant: string
  shipName?: string
  title?: string
  form: 'tool' | 'vehicle' | 'guest'
  approvedTitle?: string
  approvedMessage: string
  deniedMessage: string
}

export const OnboardingAdmit = ({ match }: RouteComponentProps<TParams>) => {
  const [success, setSuccess] = useState<boolean>()
  const [isLoading, setLoading] = useState(false)
  const [isLoadingReject, setRejectedLoading] = useState(false)
  const [approved, setApproved] = useState<boolean>()
  const [data, setData] = useState<IData>()
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const token = new URLSearchParams(window.location.search).get('t') ?? ''
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${ACCESS_CONTROL_BASE_URL}/onboarding/guest/verification/${match.params.hash}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
          }
        )
        setData(result.data)
      } catch (err) {
        setError(true)
      }
    }
    fetchData()
  }, [match.params.hash, token])
  const handleApprove = async ({ nfcId, device, piers, mask, hasApproved }: SubmitData) => {
    try {
      hasApproved ? setLoading(true) : setRejectedLoading(true)
      setApproved(hasApproved)
      await axios.post(
        ACCESS_CONTROL_BASE_URL + `/onboarding/guest/admission/${match.params.hash}`,
        {
          nfcId,
          device,
          piers,
          mask,
          hasApproved,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: new URLSearchParams(window.location.search).get('t') ?? '',
          },
        }
      )
      setSuccess(true)
    } catch (err) {
      const errors = err as Error | AxiosError<any>
      setSuccess(false)
      setLoading(false)
      if (!axios.isAxiosError(errors)) {
        setErrorMsg(errors.message)
        return
      }
      //@ts-ignore
      setErrorMsg(errors?.response?.data || 'Erro desconhecido')
    }
  }
  if (!match.params.hash) return null
  return (
    <Grid textAlign="center" style={{ height: '100vh', background: '#f7f7f7' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 600 }}>
        <Content
          data={data}
          handleClick={handleApprove}
          success={success}
          error={error}
          isLoading={isLoading}
          isLoadingReject={isLoadingReject}
          approved={approved}
          errorMsg={errorMsg}
        />
      </Grid.Column>
    </Grid>
  )
}

type SubmitData = {
  nfcId: string
  device: Device
  piers: string[]
  mask?: string
  justification?: string
  hasApproved: boolean
}

interface IContent {
  handleClick: (submitData: SubmitData) => void
  success: boolean | undefined
  error: boolean
  isLoading: boolean
  isLoadingReject: boolean
  approved?: boolean
  data: IData | undefined
  errorMsg: string
}

type Device = boolean | number | string | undefined | (boolean | number | string)[]

const marginStyle = {
  marginBottom: 10,
}

const SuccessBox = ({
  approvedMessage,
  deniedMessage,
  hasApproved,
  titleMessage,
}: {
  approvedMessage: string
  deniedMessage: string
  titleMessage?: string
  hasApproved?: boolean
}) => {
  if (hasApproved) {
    return <Message positive header={titleMessage ?? 'Acesso Autorizado'} content={approvedMessage} />
  } else {
    return <Message warning header="Acesso Recusado" content={deniedMessage} />
  }
}

const Content = ({ handleClick, success, data, error, isLoading, errorMsg, isLoadingReject, approved }: IContent) => {
  const [device, setDevice] = useState<Device>()
  const [nfcId, setNfcId] = useState('')
  const [mask, setMask] = useState('')
  const [piers, setPiers] = useState<string[]>([])
  const [rejected, setRejected] = useState(false)
  const [justification, setJustification] = useState('')
  const [piersOptions, setPiersOptions] = useState<DropdownItemProps[]>([])
  const isGuestForm = data?.form === 'guest'
  const isDisabled = isLoading || (isGuestForm && (!device || !nfcId.length))

  useEffect(() => {
    const fetchData = async (tenant: string) => {
      const piers = await getPierOptions(tenant)
      setPiersOptions(piers)
    }
    if (data) {
      fetchData(data.tenant)
    }
  }, [data])

  const handleChangeDevice = (e: any, data: DropdownProps) => {
    setDevice(data.value)
  }
  const handleApprove = (hasApproved: boolean, justification?: string) => {
    handleClick({ nfcId, device, piers, mask, hasApproved, justification })
  }
  const handleChangePier = (_e: unknown, data: DropdownProps) => {
    setPiers(data.value as string[])
  }
  if (error) return <ErrorBox />
  if (!data) return <Loader active />
  if (success)
    return (
      <SuccessBox
        approvedMessage={data.approvedMessage}
        titleMessage={data.approvedTitle}
        deniedMessage={data.deniedMessage}
        hasApproved={approved}
      />
    )
  if (rejected) {
    return (
      <Segment>
        <Header as="h3" color="red" content="Justificativa" />
        <TextArea
          style={{ width: '100%', height: 100 }}
          placeholder="Justifique o motivo da recusa (opcional)"
          onChange={(_, { value }) => {
            if (value) {
              setJustification(value as string)
            }
          }}
        />
        <Button
          onClick={() => {
            setRejected(false)
          }}
        >
          Cancelar
        </Button>
        <Button
          color="red"
          onClick={handleApprove.bind(null, false, justification)}
          loading={isLoadingReject}
          disabled={isLoadingReject}
        >
          Rejeitar
        </Button>
      </Segment>
    )
  }
  return (
    <Fragment>
      <Header as="h2" color="blue" textAlign="center" content={data.title ?? 'Autorização de acesso'} />
      <Segment>
        <div style={marginStyle}>
          <Header sub>Nome do Solicitante</Header>
          <span>{data.name}</span>
        </div>
        <div style={marginStyle}>
          <Header sub>Email do Solicitante</Header>
          <span>{data.email}</span>
        </div>
        <div style={marginStyle}>
          <Header sub>Data da início</Header>
          <span>{moment(Number(data.beginning)).format('ll')}</span>
        </div>
        <div style={marginStyle}>
          <Header sub>Data da fim</Header>
          <span>{moment(Number(data.expiration)).format('ll')}</span>
        </div>
        {isGuestForm && (
          <>
            <div style={marginStyle}>
              <Header sub>Dispositivo</Header>
              <Select placeholder="Selecione o dispositivo" options={options} onChange={handleChangeDevice} />
            </div>
            {
              <div style={marginStyle}>
                <Header sub>Número Crachá (NFC)</Header>
                <Input
                  placeholder="ID do cartão"
                  value={nfcId}
                  onChange={(_e, data) => {
                    setNfcId(data.value)
                  }}
                />
              </div>
            }
            <div style={marginStyle}>
              <Header sub>Número da Máscara</Header>
              <Input
                placeholder="123456"
                value={mask}
                onChange={(_e, data) => {
                  setMask(data.value)
                }}
              />
            </div>
          </>
        )}
        {data.shipName && (
          <div style={marginStyle}>
            <Header sub>Berço</Header>
            <Dropdown
              placeholder="Selecione o berço"
              multiple
              selection
              options={piersOptions}
              onChange={handleChangePier}
            />
          </div>
        )}
        <Button
          basic
          color="green"
          onClick={handleApprove.bind(null, true, undefined)}
          loading={isLoading}
          disabled={isDisabled}
        >
          Autorizar
        </Button>
        <Button
          basic
          color="red"
          onClick={() => {
            setRejected(true)
          }}
          loading={isLoading}
          disabled={isDisabled}
        >
          Rejeitar
        </Button>
      </Segment>
      {errorMsg.length > 0 && <Message negative header="Erro" content={errorMsg} />}
    </Fragment>
  )
}

const ErrorBox = () => (
  <Message
    negative
    header="Link inválido ou já utilizado"
    content="Verifique se a aprovação/rejeição já foi realizada, e se o link está correto."
  />
)

const options = [
  { key: 'app', value: 'app', text: 'Aplicativo' },
  { key: 'smartbadge', value: 'smartbadge', text: 'Smartbadge' },
]

const getPiers = async (tenant: string): Promise<PierData[]> => {
  const result = await axios.get(
    `https://uiefu6b723cgfwaxhj6gojdehu0ntwsg.lambda-url.us-east-2.on.aws/?tenant=${tenant}`
  )
  return result.data
}

async function getPierOptions(tenant: string): Promise<DropdownItemProps[]> {
  const piers = await getPiers(tenant)
  if (!piers.length) return []
  return piers
    .sort((a, b) => a.groupName.localeCompare(b.groupName))
    .map((pier) => ({ key: pier.ids, value: pier.ids, text: pier.groupName }))
}

interface PierData {
  groupName: string
  ids: string
}
