import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Divider, Header, Form } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import DropdownField from 'Components/forms/generic/DropdownField'
import InputField from 'Components/forms/generic/InputField'

/*
Casos de uso:
 - personalizado:
   - início relativo / fim agora
   - início absoluto / fim agora
   - início relativo / fim relativo
   - início absoluto / fim relativo
   - início relativo / fim absoluto
   - início absoluto / fim absoluto
*/

interface CustomTimeFieldsProps {
  setProp: Function
  prefix: 'start' | 'end'
  panelId: string
}

const timeOptions = [
  { key: 'm', value: 'm', text: 'Minuto(s)' },
  { key: 'h', value: 'h', text: 'Hora(s)' },
  { key: 'd', value: 'd', text: 'Dia(s)' },
]

const CustomTimeFields: React.FC<CustomTimeFieldsProps> = ({ prefix, setProp, panelId }) => {
  let customOptions = [
    { key: 'absolute', value: 'absolute', text: 'Absoluto' },
    { key: 'relative', value: 'relative', text: 'Relativo' },
  ]

  prefix === 'end' && customOptions.push({ key: 'now', value: 'now', text: 'Agora' })

  const errors = useObjSelector((state) => state.betaDashboards.form.errors?.[2])
  const showFeedback = useSelector((state: RootStore) => state.betaDashboards.form?.steps.items[2].showFeedback)
  const isEditing = useSelector((state: RootStore) => state.betaDashboards.form.isEditing)

  const time = useSelector(
    (state: RootStore) =>
      state.betaDashboards.wip?.panels?.[panelId]?.[`${prefix}Time`] ??
      state.betaDashboards.current?.panels?.[panelId]?.[`${prefix}Time`] ??
      ''
  )

  const [timeType, setTimeType] = useState(
    time
      ? time.includes('ago')
        ? 'relative'
        : time.includes('now')
        ? 'now'
        : 'absolute'
      : prefix === 'end'
      ? 'now'
      : 'relative'
  )

  const extractNumberUnit = useCallback((input: string): { number: number; unit: string } => {
    const match = input.match(/ago\((\d+)([hdm])\)/)
    if (match && match.length === 3) {
      // match[1] will contain the number and match[2] will contain h, m, or d
      return { number: parseInt(match[1], 10), unit: match[2] }
    }
    return { number: 1, unit: 'h' }
  }, [])

  const numberUnit = useMemo(() => extractNumberUnit(time), [time, extractNumberUnit])
  const [number, setNumber] = useState<number>(numberUnit.number)
  const [timeUnit, setTimeUnit] = useState(numberUnit.unit)

  const handleTimeNumberChange = (prefix: string, numberValue: number, unitValue: string) => {
    setProp(`${prefix}Time`, `ago(${numberValue}${unitValue})`)
    setNumber(numberValue)
    setTimeUnit(unitValue)
  }

  const handleTimeTypeChange = (value: string) => {
    if (value === 'relative') {
      setProp(`${prefix}Time`, `ago(1h)`)
    }
    if (value === 'now') setProp(`endTime`, `now()`)
    setTimeType(value)
  }

  useEffect(() => {
    if (!isEditing && !time) {
      setProp(`${prefix}Time`, prefix === 'start' ? `ago(1h)` : `now()`)
    }
  }, [isEditing, setProp, prefix, panelId, time])

  return (
    <>
      <Divider horizontal>
        <Header as="h4">{prefix === 'start' ? 'Início' : 'Fim'}</Header>
      </Divider>
      <br />
      <DropdownField
        value={timeType}
        label="Tipo"
        options={customOptions}
        onChange={(_, { value }) => handleTimeTypeChange(value)}
        sort={false}
        clearable={false}
        search={false}
      />
      {timeType === 'relative' && (
        <Form.Group widths={16}>
          <InputField
            width={4}
            label="Há"
            placeholder="Digite um número"
            value={number}
            onChange={(e, { value }) => handleTimeNumberChange(prefix, value, timeUnit)}
            inputType={'number'}
            min={1}
          />
          <DropdownField
            value={timeUnit}
            width={12}
            label="Unidade de Tempo"
            options={timeOptions}
            placeholder="Escolha uma unidade"
            onChange={(_, { value }) => handleTimeNumberChange(prefix, number, value)}
            sort={false}
            clearable={false}
          />
        </Form.Group>
      )}
      {timeType === 'absolute' && (
        <InputField
          label="Tempo Absoluto"
          name={`${prefix}Time`}
          value={time.includes('ago') || time.includes('now') ? '' : time}
          onChange={(_, { name, value }) => setProp(`${name}`, value)}
          inputType="datetime-local"
          required={true}
          error={errors?.[`${prefix}Time`]}
          showFeedback={showFeedback}
        />
      )}
    </>
  )
}

export default CustomTimeFields
