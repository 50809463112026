/*  
  Author: Luís Mourão
  Type: Action
  
  Description: 
    - AçõesTables Store
  
  TODO: 
    
*/

import { REPORT_URL, urlBase } from 'Apis/rest'
import { API } from 'aws-amplify'
import { listNotifications } from 'graphql/queries'
import axios from 'axios'
import jwtDecode from 'Utils/jwtDecode'
import { notifyError } from 'Utils/components/SystemToasts'

export const setAutoUpdate = (id, payload) => ({
  type: 'SET_TABLE_AUTO_UPDATE',
  payload,
  id,
})

export const setShowInput = (id, payload) => ({
  type: 'SET_TABLE_SHOW_INPUT',
  payload,
  id,
})

export const setShowModal = (id, payload) => ({
  type: 'SET_TABLE_SHOW_MODAL',
  payload,
  id,
})

export const setTableProp = (prop, value) => ({
  type: 'SET_TABLE_PROP',
  prop,
  value,
})

export const toggleTableBoolean = (id, prop) => ({
  type: 'TOGGLE_TABLE_BOOLEAN',
  id,
  prop,
})

export const fetchTableCategories =
  ({ assetId, relativeTime }) =>
  async (dispatch) => {
    const jwt = localStorage.getItem('id_token')
    let res
    try {
      dispatch({
        type: 'FETCH_TABLE_CATEGORIES_START',
        payload: {},
        params: { assetId, type: 'categories', relativeTime },
      })
      res = await axios
        .get(`${urlBase}/assets/measures?type=categories&assetId=${assetId}&timeAgo=${relativeTime}`, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((resp) => resp.data.result)
      dispatch({ type: 'FETCH_TABLE_CATEGORIES_SUCCESS', payload: res })
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_TABLE_CATEGORIES_FAILURE', payload: err })
    }
  }

export const fetchCategoriesByAbsoluteTime = (assetId, startTime, endTime) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({
      type: 'FETCH_TABLE_CATEGORIES_START',
    })
    res = await axios
      .get(`${urlBase}/assets/measures?type=categories&assetId=${assetId}&startTime=${startTime}&endTime=${endTime}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_TABLE_CATEGORIES_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_TABLE_CATEGORIES_FAILURE', payload: err })
  }
}

export const fetchCategoryMeasuresByTimeAgo = (assetId, category, timeAgo) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({ type: 'FETCH_TABLE_MEASURES_START', assetId, category })
    res = await axios
      .get(`${urlBase}/assets/measures?timeAgo=${timeAgo}&assetId=${assetId}&category=${category}&type=measuresNames`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_TABLE_MEASURES_SUCCESS', assetId, payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_TABLE_MEASURES_FAILURE', payload: err })
  }
}

export const fetchCategoryMeasuresByAbsoluteTime = (assetId, category, startTime, endTime) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({ type: 'FETCH_TABLE_MEASURES_START', assetId, category })
    res = await axios
      .get(
        `${urlBase}/assets/measures?startTime=${startTime}&endTime=${endTime}&assetId=${assetId}&category=${category}&type=measuresNames`,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        }
      )
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_TABLE_MEASURES_SUCCESS', assetId, payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_TABLE_MEASURES_FAILURE', payload: err })
  }
}

export const fetchMeasuresHistory =
  (filters, table = 'measuresHist') =>
  async (dispatch, getState) => {
    const jwt = localStorage.getItem('id_token')
    let selectedRelativeTime,
      selectedAsset,
      selectedCategory,
      selectedMeasures,
      selectedAbsoluteStartTime,
      selectedAbsoluteEndTime
    if (filters) {
      selectedRelativeTime = filters.find((e) => e.id === 'relativeTime').value[0]
      selectedAsset = filters.find((e) => e.id === 'asset').value
      selectedCategory = filters.find((e) => e.id === 'category').value[0]
      selectedMeasures = filters.find((e) => e.id === 'measure').value
    } else {
      const states = getState().tables.byId[table]
      selectedRelativeTime = states.selectedRelativeTime
      selectedAsset = states.selectedAsset
      selectedCategory = states.selectedCategory
      selectedMeasures = states.selectedMeasures
      selectedAbsoluteStartTime = states.selectedAbsoluteStartTime
        ? new Date(states.selectedAbsoluteStartTime).toISOString()
        : undefined
      selectedAbsoluteEndTime = states.selectedAbsoluteEndTime
        ? new Date(states.selectedAbsoluteEndTime).toISOString()
        : undefined
    }

    if (selectedRelativeTime && selectedAsset.length > 0 && selectedCategory && selectedMeasures.length > 0) {
      let res
      try {
        const endpoint = `${urlBase}/assets/measures?type=historyByTime&${
          selectedRelativeTime[0] !== 'absoluteTime'
            ? 'timeAgo=' + selectedRelativeTime
            : 'startTime=' + selectedAbsoluteStartTime + '&endTime=' + selectedAbsoluteEndTime
        }&assetId=${selectedAsset}&category=${selectedCategory}&measure_name=${selectedMeasures}`
        dispatch({
          type: 'FETCH_TABLE_MEASURES_HISTORY_START',
          payload: { selectedRelativeTime, selectedAsset, selectedCategory, selectedMeasures },
          id: table,
        })
        res = await axios
          .get(endpoint, {
            headers: {
              Authorization: 'Bearer ' + jwt,
            },
          })
          .then((resp) => {
            return resp.data
          })
        if (res?.errorType?.includes('ResponseSizeTooLarge')) {
          const error = 'ResponseSizeTooLarge'
          throw error
        } else {
          dispatch({
            type: 'FETCH_TABLE_MEASURES_HISTORY_SUCCESS',
            payload: res?.result,
            id: table,
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'FETCH_TABLE_MEASURES_HISTORY_FAILURE',
          payload: err,
          id: table,
        })
      }
    }
  }

export const fetchDevicesByTime = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${REPORT_URL}/devicesByTime?relativeTime=${args.relativeTime}&grouping=${args.grouping}`
  } else {
    requestURL = `${REPORT_URL}/devicesByTime?startTime=${args.startTime}&endTime=${args.endTime}&grouping=${args.grouping}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchUsersByFences = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${REPORT_URL}/usersByFences?relativeTime=${args.relativeTime}`
  } else {
    requestURL = `${REPORT_URL}/usersByFences?startTime=${args.startTime}&endTime=${args.endTime}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchLogoutsByTime = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${REPORT_URL}/logoutsByTime?relativeTime=${args.relativeTime}&grouping=${args.grouping}`
  } else {
    requestURL = `${REPORT_URL}/logoutsByTime?startTime=${args.startTime}&endTime=${args.endTime}&grouping=${args.grouping}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchFenceRulesByTime = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${REPORT_URL}/fenceRulesByTime?relativeTime=${args.relativeTime}&grouping=${args.grouping}`
  } else {
    requestURL = `${REPORT_URL}/fenceRulesByTime?startTime=${args.startTime}&endTime=${args.endTime}&grouping=${args.grouping}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchFirefightersByTime = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${REPORT_URL}/firefightersByTime?relativeTime=${args.relativeTime}`
  } else {
    requestURL = `${REPORT_URL}/firefightersByTime?startTime=${args.startTime}&endTime=${args.endTime}`
  }

  if (args.assetId) {
    requestURL += `&assetId=${args.assetId}`
  }

  if (args.profileId) {
    requestURL += `&profileId=${args.profileId}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchPeopleTypeByTime = (args, relative, typeID) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${REPORT_URL}/peopleTypeByTime?relativeTime=${args.relativeTime}&typeID=${typeID}`
  } else {
    requestURL = `${REPORT_URL}/peopleTypeByTime?startTime=${args.startTime}&endTime=${args.endTime}&typeID=${typeID}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchRulesTypeByTime = (args, relative, typeID) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${REPORT_URL}/rulesTypeByTime?relativeTime=${args.relativeTime}&typeID=${typeID}`
  } else {
    requestURL = `${REPORT_URL}/rulesTypeByTime?startTime=${args.startTime}&endTime=${args.endTime}&typeID=${typeID}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchReportDataTypeByTime = (args, reportId, relative, typeID) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${urlBase}/reports/${reportId}?relativeTime=${args.relativeTime}&typeID=${typeID}`
  } else {
    requestURL = `${urlBase}/reports/${reportId}?startTime=${args.startTime}&endTime=${args.endTime}&typeID=${typeID}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchReportData = (params) => async (dispatch) => {
  const requestURL = `${urlBase}/reportdata`
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
        params,
      })
      .then((resp) => {
        return resp.data.result
      })
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchReportsData =
  (filters, reportId = 'firefightersbytime', args, relative) =>
  async (dispatch) => {
    let requestURL

    if (relative) {
      requestURL = `${urlBase}/reports/${reportId.toLowerCase}?relativeTime=${args.relativeTime}&grouping=${args.grouping}`
    } else {
      requestURL = `${urlBase}/reports/${reportId.toLowerCase}?startTime=${args.startTime}&endTime=${args.endTime}&grouping=${args.grouping}`
    }

    const jwt = localStorage.getItem('id_token')
    let res
    try {
      res = await axios
        .get(requestURL, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((resp) => resp.data.result)
      dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_REPORT_FAILURE' })
    }
  }

export const fetchPresenceByFences = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${REPORT_URL}/presenceByFences?relativeTime=${args.relativeTime}`
  } else {
    requestURL = `${REPORT_URL}/presenceByFences?startTime=${args.startTime}&endTime=${args.endTime}`
  }

  if (args.assetId) {
    requestURL += `&assetId=${args.assetId}`
  }

  if (args.fenceId) {
    requestURL += `&fenceId=${args.fenceId}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchNearMissesByPerimeter = (args, relative, severityLevel, perimeterID) => async (dispatch) => {
  // we're gonna build the request URL string
  let requestURL = `${REPORT_URL}/nearMissesByPerimeter`

  if (relative) {
    requestURL += `?relativeTime=${args.relativeTime}`
  } else {
    requestURL += `?startTime=${args.startTime}&endTime=${args.endTime}`
  }

  requestURL += `&perimeterID=${perimeterID}`

  if (severityLevel !== '0') {
    requestURL += `&severityLevel=${severityLevel}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchReports = (args, relative, reportType, dataTypeID) => async (dispatch) => {
  // we're gonna build the request URL string
  let requestURL = `${urlBase}/reports/${reportType}`

  // check if relative time or dates
  if (relative) {
    requestURL += `?relativeTime=${args.relativeTime}`
  } else {
    requestURL += `?startTime=${args.startTime}&endTime=${args.endTime}`
  }

  if (args.assetId) {
    requestURL += `&assetId=${args.assetId}`
  }

  if (args.fenceId) {
    requestURL += `&fenceId=${args.fenceId}`
  }

  if (args.refTypeId) {
    requestURL += `&refTypeId=${args.refTypeId}`
  }

  // used for the back-end filter
  if (dataTypeID) {
    requestURL += `&typeID=${dataTypeID}`
    if (reportType === 'peopleTypeByTime') {
      requestURL += `&grouping=${args.grouping}`
    }
  } else {
    requestURL += `&grouping=${args.grouping}`
  }
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await axios
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

const _fetchNotificationByDateRange = async (tenant, startTime, endTime, nextToken) => {
  const response = await API.graphql({
    query: listNotifications,
    variables: { tenant, sortDirection: 'DESC', nextToken, time: { between: [startTime, endTime] } },
  })
  if (response.data.listNotifications.nextToken) {
    const nextResponse = await _fetchNotificationByDateRange(
      tenant,
      startTime,
      endTime,
      response.data.listNotifications.nextToken
    )
    return [...response.data.listNotifications.items, ...nextResponse]
  }
  return response.data.listNotifications.items
}

export const fetchNotificationByDateRange = (startTime, endTime) => async (dispatch, getState) => {
  const jwt = localStorage.getItem('id_token')
  const tenant = jwtDecode(jwt)['http://plataforma.phygitall.com.br/empresa']
  try {
    dispatch({ type: 'FETCH_TABLE_NOTIFICATIONS_START', id: 'notifications' })
    const payload = await _fetchNotificationByDateRange(tenant, startTime, endTime)
    dispatch({
      type: 'FETCH_TABLE_NOTIFICATIONS_SUCCESS',
      payload,
      id: 'notifications',
    })
  } catch (err) {
    console.error('error fetching notifications', err)
  }
}

export const fetchAppRuleTriggers = (args) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')

  const rules = ['RG-008']

  let startTime
  let endTime

  if (args.relativeTime) {
    startTime = `ago(${args.relativeTime})`
    endTime = 'now()'
  } else {
    startTime = new Date(args.startTime).toISOString()
    endTime = new Date(args.endTime).toISOString()
  }

  const payload = {
    rules,
    startTime,
    endTime,
  }

  try {
    const response = await axios.post(`${REPORT_URL}/appRuleTriggers`, payload, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })

    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: response?.data?.result })

    if (response.data?.errorType?.includes('ResponseSizeTooLarge')) {
      throw new Error('ResponseSizeTooLarge')
    }
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
    if (err?.response?.data?.message === 'Endpoint request timed out')
      notifyError({ message: 'Tempo limite de consulta excedido. Tente outro intervalo de tempo' })
    else notifyError()
  }
}

export const fetchEmergencies = (args) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')

  let startTime
  let endTime

  if (args.relativeTime) {
    startTime = `ago(${args.relativeTime})`
    endTime = 'now()'
  } else {
    startTime = new Date(args.startTime).toISOString()
    endTime = new Date(args.endTime).toISOString()
  }

  const payload = {
    types: ['help'],
    startTime,
    endTime,
  }

  try {
    const response = await axios.post(`${REPORT_URL}/notificationsByType`, payload, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })

    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: response?.data?.result })

    if (response.data?.errorType?.includes('ResponseSizeTooLarge')) {
      throw new Error('ResponseSizeTooLarge')
    }
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
    if (err?.response?.data?.message === 'Endpoint request timed out')
      notifyError({ message: 'Tempo limite de consulta excedido. Tente outro intervalo de tempo' })
    else notifyError()
  }
}

export const fetchReportByEndpoint =
  ({ endpoint, payload }) =>
  async (dispatch) => {
    const jwt = localStorage.getItem('id_token')

    dispatch({ type: 'FETCH_REPORT_START', payload })

    try {
      let response
      let queryId
      let isFirstIteration = true
      do {
        response = await axios.post(
          `${REPORT_URL}/${endpoint}`,
          { ...payload, queryId },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
        queryId = response.data?.queryId
        if (queryId) {
          if (isFirstIteration) {
            dispatch({ type: 'SET_TRUE', payload: 'loadingScreen' })
            dispatch({ type: 'SELECT_DATE_REPORTS_CLOSE' })
          }
          dispatch({ type: 'SET_LOADING_PROGRESS', payload: response.data.progress || 0.01 })
        }
      } while (queryId)

      dispatch({ type: 'SET_FALSE', payload: 'loadingScreen' })
      dispatch({ type: 'SET_LOADING_PROGRESS', payload: 0 })
      dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: response?.data?.result || [] })

      if (response.data?.errorType?.includes('ResponseSizeTooLarge')) {
        throw new Error('ResponseSizeTooLarge')
      }
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_REPORT_FAILURE' })
      if (err?.response?.data?.message === 'Endpoint request timed out')
        notifyError({ message: 'Tempo limite de consulta excedido. Tente outro intervalo de tempo' })
      else notifyError()
    }
  }

export const fetchAreaAbandonmentReport =
  ({ endpoint, payload }) =>
  async (dispatch) => {
    const jwt = localStorage.getItem('id_token')

    dispatch({ type: 'FETCH_AREA_ABANDONMENT_REPORT_START', payload })

    try {
      const response = await axios.post(`${REPORT_URL}/${endpoint}`, payload, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })

      dispatch({ type: 'FETCH_AREA_ABANDONMENT_REPORT_SUCCESS', payload: response?.data, id: payload.id })

      if (response.data?.errorType?.includes('ResponseSizeTooLarge')) {
        throw new Error('ResponseSizeTooLarge')
      }
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_REPORT_FAILURE' })
      if (err?.response?.data?.message === 'Endpoint request timed out')
        notifyError({ message: 'Tempo limite de consulta excedido. Tente outro intervalo de tempo' })
      else notifyError()
    }
  }
