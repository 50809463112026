import React from 'react'
import { Text, View } from '@react-pdf/renderer'

/* const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#B2BEB5',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 20,
    fontStyle: 'bold',
  },
}) */
function breakLongWords(text, maxLength) {
  return text
    .split(' ')
    .map((word) => {
      if (word.length > maxLength) {
        // Divide a palavra longa em pedaços de tamanho especificado com espaço
        return word.match(new RegExp('.{1,' + maxLength + '}', 'g')).join(' ')
      }
      return word
    })
    .join(' ')
}

const ReportTableRow = ({ items, rowStyles }) => {
  return (
    <>
      {items.map((item, i) => (
        <View key={item.key.toString()} style={[rowStyles.row, i % 2 !== 0 && { backgroundColor: '#E5E4E2' }]}>
          {Object.entries(item)
            .filter(([key]) => key !== 'key')
            .map(([key, value]) => {
              const formatValue = value ? (typeof value === 'object' ? JSON.stringify(value) : value.toString()) : ''
              return (
                <View key={key} style={rowStyles[key]}>
                  <Text>{breakLongWords(formatValue, 20)}</Text>
                </View>
              )
            })}
        </View>
      ))}
    </>
  )
}

export default ReportTableRow
