import React from 'react'
import styled from 'styled-components'
import PopupHeader from './PopupHeader/PopupHeader'
import PopupBottom from './PopupBottom'
import { Grid } from 'semantic-ui-react'
import PopupInfoHeader from './PopupInfo/PopupInfoHeader'
import { Popup } from 'react-leaflet'
import PopupInfoFields from 'Components/Map/PopupMarker/PopupInfo/PopupInfoFields'

/*  Author: Bruno Melo / Luís Mourão
    Type: Dumb
	Description: Define the Grid format;
				 Separate the props of each part of the grid;				 
	To do list: -				
*/

const PopupGridContainer = styled(Grid)`
  width: 255px;
`
const PopupGridRow = styled(Grid.Row)`
  width: 255px;
  padding: 2px !important;
`
const PopupGridRowBottom = styled(Grid.Row)`
  margin-left: 3px;
  width: 255px;
  padding: 2px !important;
  height: 25px;
`

const PopupGrid = ({ assetId, signals, mode }) => {
  return (
    <Popup closeButton minWidth="244" className="popups" key={'popupmarker' + assetId} keepInView={false}>
      <PopupGridContainer id={'PopupGridContainer'} columns={1} padded>
        <PopupHeader assetId={assetId} signals={signals} mode={mode} />
        <PopupGridRow>
          <Grid.Column>
            <PopupInfoHeader assetId={assetId} mode={mode} />
            <PopupInfoFields assetId={assetId} mode={mode} />
          </Grid.Column>
        </PopupGridRow>
        <PopupGridRowBottom>
          <Grid.Column>
            <PopupBottom assetId={assetId} mode={mode} />
          </Grid.Column>
        </PopupGridRowBottom>
      </PopupGridContainer>
    </Popup>
  )
}
export default PopupGrid
