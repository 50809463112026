import React from 'react'
import { useSelector } from 'react-redux'

const DashboardTitle = () => {
  const empresa = useSelector((state) => state.login.empresa)
  const tab = useSelector((state) => state.tables.current.tab)

  return (
    <>
      {['firefighter', 'dashboard'].includes(tab) && (
        <span style={{ fontSize: '18px', fontWeight: '700', color: 'rgb(75, 114, 178)' }}>
          {tab === 'firefighter'
            ? 'Quadro de Brigadistas'
            : tab === 'dashboard' && ['Tiplam', 'PhygitallDevs'].includes(empresa)
            ? 'Gestão & Segurança'
            : 'Dashboard01'}
        </span>
      )}
    </>
  )
}
export default DashboardTitle
