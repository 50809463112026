import React from 'react'
import { Icon, Card, Statistic, Popup } from 'semantic-ui-react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
//import { PieGraphSmall } from 'Components/SidebarMenu/Contents/Dashboard/PieGraphSmall.jsx'
import { PieChartActive } from 'Components/SidebarMenu/Contents/Dashboard/PieChartActive.js'
import { setChart } from 'Store/actions/dashboardStellantis-action'
import moment from 'moment'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import Cards from 'Utils/reactTable/components/Cards.js'

const CardFiat = styled(Card)`
  width: 400px !important;
  height: 350px !important;
`

const CardPhases = styled(Card)`
  width: 1100px !important;
  height: 400px !important;
  padding: 15px !important;
  margin-bottom: 30px !important;
`

// const StatisticCard = styled(Card)`
//   width: 130px !important;
// `

const StatisticLabel = styled(Statistic.Label)`
  text-transform: lowercase !important;
`

const MainCards = ({ search, phaseCards, dashboard, hidePhaseCards }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const charts = useSelector((state) => state.dashboardStellantis.charts.data, shallowEqual)
  const cards = useObjSelector((state) => state.dataLabelings.cards)

  const selectedActivity = useObjSelector((state) => state.dataLabelings.selectedActivity)

  if (!cards?.['card#0']) return null

  function setChartState(chart) {
    dispatch(setChart(chart))
  }

  const maxCycle = cards?.['card#0']?.body?.find((e) => e.metric === 'max')
  const maxCycleTime = moment(maxCycle.time).format('HH:mm')

  const minCycle = cards?.['card#0']?.body?.find((e) => e.metric === 'min')
  const minCycleTime = moment(minCycle.time).format('HH:mm')

  let subText = selectedActivity.text

  if (subText?.length > 29) {
    subText = `${subText.substring(0, 29)}`
  }

  const presentCards = [
    {
      color: 'red',
      image: 'target',
      header: t('WorkStation'),
      // meta: <TableSpan value={selectedActivity.text} width="100px" />,
      meta: subText,
      time: 'xxxxx',
      duration: cards?.['card#0']?.mainInfo,
      // labelValue: t('insights_MainCards_efficiency'),
      statisticsCards: [
        {
          title: 'TCT',
          mainValue: '65',
          label: t('insights_MainCards_cycle_seconds'),
          color: '#c40233',
        },
        {
          title: t('insights_MainCards_max_cycle'),
          mainValue: maxCycle?.value,
          time: maxCycleTime,
          label: t('insights_MainCards_cycle_seconds'),
        },
        {
          // title: t('insights_MainCards_theoretical_cycle'),
          title: 'TT',
          mainValue: cards?.['card#0']?.body?.find((e) => e.metric === 'teórico')?.value || 77,
          label: t('insights_MainCards_cycle_seconds'),
        },
        {
          title: t('insights_MainCards_min_cycle'),
          mainValue: minCycle?.value || '',
          time: minCycleTime,
          label: t('insights_MainCards_cycle_seconds'),
        },
      ],
      barChart: 'ciclo',
    },
    {
      source: 'ciclo',
      color: 'blue',
      image: 'target',
      header: t('insights_presentCards_1_header'),
      meta: t('insights_presentCards_1_meta'),
      // description: cards?.['card#0']?.body?.filter(each => each.source === "ciclo").map((line) => line?.name + ': ' + line?.value) || [],
      // duration: cards?.['card#0']?.mainInfo || '',
      // labelValue: cards?.['card#0']?.mainInfoLabel || '',
      pieChart: true,
      barChart: 'saturação',
    },
    // card va/nva
    // {
    //   color: 'yellow',
    //   image: 'list alternate outline',
    //   header: t('insights_statisticsCards_header'),
    //   meta: t('insights_statisticsCards_meta'),
    //   duration: '-5%',
    //   //labelValue: "Eficiência",
    //   statisticsCards: [
    //     {
    //       title: t('insights_statisticsCards_title_0'),
    //       // mainValue: cards?.['card#0']?.body?.find((e) => e.metric === 'nva')?.value,
    //       mainValue: '9%',
    //     },
    //     {
    //       title: t('insights_statisticsCards_title_1'),
    //       mainValue: '8.56%',
    //     },
    //   ],
    //   barChart: 'shift',
    // },
  ]

  let pieChart = {}
  if (charts?.length > 0) {
    charts.forEach((e) => {
      Object.values(e.label)
        .filter((element) => element.name === 'Dessaturação' || element.name === 'Saturação')
        .forEach((eachLabel) => {
          if (!pieChart[eachLabel.name]) {
            pieChart[eachLabel.name] = {
              name: 'insights_pieChart_' + eachLabel.name,
              value: eachLabel.duration,
              color: eachLabel.name === 'Dessaturação' ? '#c40233' : '#66afe9',
            }
          } else {
            pieChart[eachLabel.name].value = pieChart[eachLabel.name].value + eachLabel.duration
          }
        })
    })
  }
  pieChart = Object.values(pieChart)
  let filteredCards = search
    ? presentCards.filter(
        (card) => card.time.toLowerCase().includes(search.toLowerCase()) || card.meta.includes(search)
      )
    : presentCards

  var pieDessatMean = parseInt(
    cards?.['card#0']?.body?.find((e) => e.metric === 'média' && e.source === 'dessaturação')?.value
  )
  var pieSatMean = parseInt(
    cards?.['card#0']?.body?.find((e) => e.metric === 'média' && e.source === 'saturação')?.value
  )
  var pieDessatPercent = Math.round((pieDessatMean * 100) / (pieDessatMean + pieSatMean))
  var pieSatPercent = Math.round((pieSatMean * 100) / (pieDessatMean + pieSatMean))

  return (
    <Card.Group centered>
      {filteredCards.map((card, i) => {
        return (
          <CardFiat color={card.color} key={'cardFake' + card.color} onClick={() => setChartState(card.barChart)}>
            <Card.Content>
              {card.duration && (
                <Popup
                  content={'card.time'}
                  on="hover"
                  trigger={
                    <Statistic size="tiny" floated="right" color={card.color}>
                      <Statistic.Value>
                        <Icon name={card.image} /> {card.duration}
                      </Statistic.Value>
                      <Statistic.Label>{card.labelValue}</Statistic.Label>
                    </Statistic>
                  }
                />
              )}
              <Card.Header>{card.header}</Card.Header>
              <Card.Meta>{card.meta}</Card.Meta>
              {/* {card.pieChart && <PieGraphSmall data={pieChart} colors={['#c40233', '#66afe9']} />} */}
              {card.description?.map((eachDesc, descIndex) => (
                <Card.Description key={descIndex}>{eachDesc || '\u00A0'}</Card.Description>
              ))}
              {card.statisticsCards && (
                <>
                  {card.header === 'NVA/VA' && (
                    <>
                      <br />
                      <br />
                      <br />
                    </>
                  )}
                  <br />
                  <br />
                  <Card.Group itemsPerRow="2" centered style={{ width: '400px' }}>
                    <Card centered key={card.statisticsCards[0].title} style={{ width: '130px' }}>
                      <Card.Meta textAlign="center" content={card.statisticsCards[0].title} />
                      <Card.Content textAlign="center">
                        {card.statisticsCards[0].title === 'Ciclo Máximo' ||
                        card.statisticsCards[0].title === 'Ciclo Mínimo' ? (
                          <Statistic.Group size="mini">
                            <Statistic size="mini" color={card.statisticsCards[0].color ? 'red' : null}>
                              <Statistic.Value>{card.statisticsCards[0].mainValue}</Statistic.Value>
                              {card.statisticsCards[0].label && (
                                <StatisticLabel>{card.statisticsCards[0].label}</StatisticLabel>
                              )}
                            </Statistic>
                            <Statistic size="mini" color={card.statisticsCards[0].color ? 'red' : null}>
                              <Statistic.Value>{card.statisticsCards[0].mainValue}</Statistic.Value>
                              {card.statisticsCards[0].label && (
                                <StatisticLabel>{card.statisticsCards[0].label}</StatisticLabel>
                              )}
                            </Statistic>
                          </Statistic.Group>
                        ) : (
                          <Statistic size="small" color={card.statisticsCards[0].color ? 'red' : null}>
                            <Statistic.Value>{card.statisticsCards[0].mainValue}</Statistic.Value>
                            {card.statisticsCards[0].label && (
                              <StatisticLabel>{card.statisticsCards[0].label}</StatisticLabel>
                            )}
                          </Statistic>
                        )}
                      </Card.Content>
                    </Card>
                    <Card centered key={card.statisticsCards[1].title} style={{ width: '180px' }}>
                      <Card.Meta textAlign="center" content={card.statisticsCards[1].title} />
                      <Card.Content textAlign="center">
                        {card.statisticsCards[1].title === 'Ciclo Máximo' ||
                        card.statisticsCards[1].title === 'Ciclo Mínimo' ? (
                          <Statistic.Group size="mini">
                            <Statistic size="mini" color={card.statisticsCards[1].color ? 'red' : null}>
                              <Statistic.Value>{card.statisticsCards[1].mainValue}</Statistic.Value>
                              {card.statisticsCards[1].label && (
                                <StatisticLabel>{card.statisticsCards[1].label}</StatisticLabel>
                              )}
                            </Statistic>
                            <Statistic size="mini" color={card.statisticsCards[1].color ? 'red' : null}>
                              <Statistic.Value>{card.statisticsCards[1].time}</Statistic.Value>
                              {card.statisticsCards[1].label && <StatisticLabel>{'horas'}</StatisticLabel>}
                            </Statistic>
                          </Statistic.Group>
                        ) : (
                          <Statistic size="tiny" color={card.statisticsCards[1].color ? 'red' : null}>
                            <Statistic.Value>{card.statisticsCards[1].mainValue}</Statistic.Value>
                            {card.statisticsCards[1].label && (
                              <StatisticLabel>{card.statisticsCards[1].label}</StatisticLabel>
                            )}
                          </Statistic>
                        )}
                      </Card.Content>
                    </Card>
                    {card.statisticsCards[2] && (
                      <>
                        <Card centered key={card.statisticsCards[2].title || 'card'} style={{ width: '130px' }}>
                          <Card.Meta textAlign="center" content={card.statisticsCards[2].title || ''} />
                          <Card.Content textAlign="center">
                            {card.statisticsCards[2].title === 'Ciclo Máximo' ||
                            card.statisticsCards[2].title === 'Ciclo Mínimo' ? (
                              <Statistic.Group size="mini">
                                <Statistic size="mini" color={card.statisticsCards[2].color ? 'red' : null}>
                                  <Statistic.Value>{card.statisticsCards[2].mainValue}</Statistic.Value>
                                  {card.statisticsCards[2].label && (
                                    <StatisticLabel>{card.statisticsCards[2].label}</StatisticLabel>
                                  )}
                                </Statistic>
                                <Statistic size="mini" color={card.statisticsCards[2].color ? 'red' : null}>
                                  <Statistic.Value>{card.statisticsCards[2].mainValue}</Statistic.Value>
                                  {card.statisticsCards[2].label && (
                                    <StatisticLabel>{card.statisticsCards[2].label}</StatisticLabel>
                                  )}
                                </Statistic>
                              </Statistic.Group>
                            ) : (
                              <Statistic size="tiny" color={card.statisticsCards[2].color ? 'red' : null}>
                                <Statistic.Value>{card.statisticsCards[2].mainValue}</Statistic.Value>
                                {card.statisticsCards[2].label && (
                                  <StatisticLabel>{card.statisticsCards[2].label}</StatisticLabel>
                                )}
                              </Statistic>
                            )}
                          </Card.Content>
                        </Card>
                        <Card centered key={card.statisticsCards[3].title} style={{ width: '180px' }}>
                          <Card.Meta textAlign="center" content={card.statisticsCards[3].title} />
                          <Card.Content textAlign="center">
                            {(card.statisticsCards[3].title === 'Ciclo Máximo' ||
                              card.statisticsCards[3].title === 'Ciclo Mínimo') && (
                              <Statistic.Group size="mini">
                                <Statistic size="mini" color={card.statisticsCards[3].color ? 'red' : null}>
                                  <Statistic.Value>{card.statisticsCards[3]?.mainValue || ''}</Statistic.Value>
                                  {card.statisticsCards[3].label && (
                                    <StatisticLabel>{card.statisticsCards[3].label}</StatisticLabel>
                                  )}
                                </Statistic>
                                <Statistic size="mini" color={card.statisticsCards[3].color ? 'red' : null}>
                                  <Statistic.Value>{card.statisticsCards[3].time}</Statistic.Value>
                                  {card.statisticsCards[3].label && <StatisticLabel>{'horas'}</StatisticLabel>}
                                </Statistic>
                              </Statistic.Group>
                            )}
                            {card.statisticsCards[3].title !== 'Ciclo Máximo' &&
                              card.statisticsCards[3].title !== 'Ciclo Mínimo' && (
                                <Statistic size="tiny" color={card.statisticsCards[3].color ? 'red' : null}>
                                  <Statistic.Value>{card.statisticsCards[3].mainValue}</Statistic.Value>
                                  {card.statisticsCards[3].label && (
                                    <StatisticLabel>{card.statisticsCards[3].label}</StatisticLabel>
                                  )}
                                </Statistic>
                              )}
                          </Card.Content>
                        </Card>
                      </>
                    )}
                  </Card.Group>
                </>
              )}

              {card.pieChart && (
                <div style={{ width: '342px', height: '250px' }}>
                  {card.source === 'ciclo' && (
                    <PieChartActive
                      data={[
                        {
                          name: t('insights_desaturation'),
                          fill: '#c40233',
                          value: pieDessatPercent,
                          mean: pieDessatMean,
                        },
                        {
                          name: t('insights_saturation'),
                          fill: '#66afe9',
                          value: pieSatPercent,
                          mean: pieSatMean,
                        },
                      ]}
                    />
                  )}
                </div>
              )}
            </Card.Content>
          </CardFiat>
        )
      })}
      {!hidePhaseCards && (
        <CardPhases color="green">
          <Cards
            cardTime={t('insights_seconds_ago')}
            key="RTCards"
            fakeCards={phaseCards}
            dashboard={dashboard}
            // setCurrentCard={setCurrentCard}
          />
        </CardPhases>
      )}
    </Card.Group>
  )
}

export default MainCards
