/*  Author: Luís Mourão
    Type: reducer
    Description: reducer from rules store
    To do list:
*/

import { omit } from 'lodash'
import initialStore from 'Store/initialStore'
import produce from 'immer'

const initialState = initialStore.rules

export default function rulesReducer(state = initialState, { type, id, payload }) {
  switch (type) {
    case 'FETCH_RULES_START':
      return { ...state, loading: true }

    case 'FETCH_RULES_SUCCESS':
      return produce(state, (newState) => {
        newState.loading = false
        payload.forEach((each) => {
          each.id = each.id.split('#')?.[1]
          if (!state.allIds.includes(each.id)) newState.allIds.push(each.id)
          newState.byId[each.id] = each
        })
      })

    case 'FETCH_RULES_FAILURE':
      return produce(state, (newState) => {
        let time = new Date()
        newState.fetchTime = time.toISOString()
      })

    case 'SAVE_RULE':
      return produce(state, (newState) => {
        payload.id = payload.id?.split('#')?.[1]
        if (!newState.byId[payload.id]) newState.allIds.push(payload.id)
        newState.byId[payload.id] = payload
      })

    case 'DELETE_RULE':
      return produce(state, (newState) => {
        const localId = id?.split('#')?.[1]
        newState.allIds = newState.allIds.filter((i) => i !== localId)
        newState.byId = omit(newState.byId, [localId])
      })

    case 'CLEAR_RULES':
      return initialState

    case 'SET_RULE_WIP':
      return { ...state, wip: payload }

    case 'SET_CURRENT_ID':
      return { ...state, currentId: id }

    case 'SET_RULES_ERRORS':
      return { ...state, errors: payload }

    case 'SET_CURRENT_RULE':
      return produce(state, (newState) => {
        newState.current = payload ?? newState.byId[id] ?? {}
      })

    default:
      return state
  }
}
