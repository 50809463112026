import React from 'react'
import styled from 'styled-components'
import PanelHeader from './PanelHeader'
import { Segment } from 'semantic-ui-react'
import PanelBody from './body/PanelBody'
import useStoreSelector from 'Utils/hooks/useStoreSelector'

const SegmentPanel = styled(Segment)`
  height: 100%;
  margin: 0 4px 4px 0 !important;
  padding: 8px !important;
`

const DivPanelIndicator = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
  font-size: 14px;
  background-color: white;
  border-radius: 8px;
  --border-left: 10px ${(props) => props.barColor} solid;
`

const PanelContent = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 90%;
  --border: 1px green solid;
`

type DashboardPanelProps = {
  panelId: string
}

const DashboardPanel: React.FC<DashboardPanelProps> = ({ panelId }) => {
  const loading = useStoreSelector((state) => state.betaDashboards.panelsInfo?.byId?.[panelId]?.loading)
  const borderColor = useStoreSelector(
    (state) => state.betaDashboards.panelsInfo?.byId?.[panelId]?.borderColor ?? 'grey'
  )

  return (
    <SegmentPanel raised color={borderColor} loading={loading}>
      <DivPanelIndicator barColor={borderColor}>
        <PanelHeader panelId={panelId} />
        <PanelContent>
          <PanelBody panelId={panelId} />
        </PanelContent>
      </DivPanelIndicator>
    </SegmentPanel>
  )
}

export default DashboardPanel
