import { urlBase } from './rest'
import axios from 'axios'

export const getAssetIdDetails = async (assetId: string) => {
  try {
    // const body = { assetId: assetId } // maybe i can just *not* send the body?
    const jwt = localStorage.getItem('id_token')
    const headers = {
      headers: { Authorization: 'Bearer ' + jwt },
    }
    const result = await axios.get(urlBase + '/assets/details/' + assetId, headers)
    return result.data
  } catch (err) {
    console.error(err)
    return null
  }
}
