import { isNil } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from 'semantic-ui-react'
import { setBetaDashboardProps } from 'Store/actions/betaDashboards-action'
import useStoreSelector from 'Utils/hooks/useStoreSelector'

interface Props {
  panelId: string
}

const PanelBodyKPI: React.FC<Props> = ({ panelId }) => {
  const dispatch = useDispatch()

  // Esses podem estar associados com mudanças de usuários, então também são gerenciados no WIP
  const h = useStoreSelector(
    (state) =>
      state.betaDashboards.wip?.panels?.[panelId]?.layout?.h ??
      state.betaDashboards.current?.panels?.[panelId]?.layout?.h ??
      3
  )
  const goal = useStoreSelector(
    (state) =>
      state.betaDashboards.wip?.panels?.[panelId]?.filters?.goal ??
      state.betaDashboards.current?.panels?.[panelId]?.filters?.goal
  )
  const benchmark = useStoreSelector(
    (state) =>
      state.betaDashboards.wip?.panels?.[panelId]?.filters?.benchmark ??
      state.betaDashboards.current?.panels?.[panelId]?.filters?.benchmark
  )

  // Esses valores não são associados com mudanças de usuário, então estão gerenciados no current
  const result = useStoreSelector((state) => state.betaDashboards.panelsInfo?.byId?.[panelId]?.result)
  const borderColor = useStoreSelector((state) => state.betaDashboards.panelsInfo?.byId?.[panelId]?.borderColor)

  useEffect(() => {
    let newBorderColor = ''
    if (isNil(result)) {
      newBorderColor = 'grey'
    } else {
      switch (benchmark) {
        case '=':
          newBorderColor = result === goal ? 'green' : 'red'
          break
        case '<>':
          newBorderColor = result !== goal ? 'green' : 'red'
          break
        case '>=':
          newBorderColor = result >= goal ? 'green' : 'red'
          break
        case '>':
          newBorderColor = result > goal ? 'green' : 'red'
          break
        case '<':
          newBorderColor = result < goal ? 'green' : 'red'
          break
        case '<=':
          newBorderColor = result <= goal ? 'green' : 'red'
          break
        default:
          newBorderColor = 'grey'
          break
      }
    }

    dispatch(setBetaDashboardProps([[`panelsInfo.byId.${panelId}.borderColor`, newBorderColor]]))
  }, [benchmark, result, goal, panelId, dispatch])

  return (
    <Grid style={{ height: '100%' }}>
      <Grid.Row
        stretched
        centered
        verticalAlign="middle"
        style={{
          height: '80%',
          fontWeight: '700',
          fontSize: `${30 + (h - 2) * 10}px`,
          paddingTop: `${goal ? 30 + (h - 2) * 10 : 45 + (h - 2) * 10}px`,
        }}
      >
        <span
          style={{
            color: borderColor,
          }}
        >
          {result}
        </span>
      </Grid.Row>
      {!isNil(goal) && (
        <Grid.Row
          style={{ height: '20%', paddingLeft: '9px', paddingTop: '2px', minHeight: '20px', color: 'rgba(0,0,0,.6)' }}
        >
          <span style={{ fontWeight: '400', marginRight: '5px' }}>Meta ({benchmark}):</span>
          {parseFloat(goal)}
        </Grid.Row>
      )}
    </Grid>
  )
}

export default PanelBodyKPI
