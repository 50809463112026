/*  Author: Felipe Pegado
    Type: Const Store
    Description: Get every action dispatched to the store;
    To do list: - Conferir sobre o salvar dos dados no local storage
*/
const logger = (store) => (next) => (action) => {
  //console.group(action.type)
  //console.info('dispatching', action)
  let result = next(action)
  //console.log('next state', store.getState())
  /*try {

    let serializedState = JSON.stringify(store.getState());
    localStorage.setItem('store', serializedState);    
  } catch (err) {
    console.log('Error occurred while persisting state to Local Storage: ',err);
  }*/
  //console.groupEnd()
  return result
}

export default logger
