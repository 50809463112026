import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, Form, Input, Checkbox } from 'semantic-ui-react'
import { fetchDevices } from 'Store/actions/devices-action'
import { RootStore } from 'Store/initialStore'
import { ModalTemplate } from '../components/ModalTemplate'
import { Profile } from 'interfaces/profiles'

export interface FieldsAssetThingState {
  id: string
  name: string
  code: string
  sensor: string
  type: string
  idType: number
}

interface AssetThingFormProps {
  cudLoading: boolean
  noType: string
  handleSave: (fields: FieldsAssetThingState) => void
  typesAllIds: string[]
  typesById: Record<string, Profile>
  handleCancel: () => void
  addOpen: boolean
  table: string
  confirmAction: string
  row: any
}

interface FieldValue {
  key?: string
  text?: string
  value?: string
}

const DeviceDropdown = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const devicesAllIds = useSelector((state: RootStore) => state.devices.allIds)
  const devicesById = useSelector((state: RootStore) => state.devices.byId)
  const loading = useSelector((state: RootStore) => state.devices.loading)
  const { handleChange, fields, fetchDevices, showAllDevices } = props
  useEffect(() => {
    dispatch(fetchDevices())
  }, [fetchDevices, dispatch])
  const options = [
    { key: 'none', text: '<<Sem dispositivo>>', value: '' },
    ...devicesAllIds
      .filter((id: string) => showAllDevices || !devicesById[id]?.assets || fields.sensor === id)
      .filter((id: string) => !['Smartphone', 'Smartbadge c/ GPS'].includes(devicesById[id]?.model || ''))
      .map((e: string) => ({
        key: devicesById[e]?.id,
        text: showAllDevices
          ? `${!devicesById[e]?.serial_number ? t('NoSerialNumber') : devicesById[e]?.serial_number} (${
              devicesById[e]?.name
            }) ${devicesById[e]?.assets ? `- ${devicesById[e]?.assets}` : ''}`
          : `${!devicesById[e]?.serial_number ? t('NoSerialNumber') : devicesById[e]?.serial_number} (${
              devicesById[e]?.name
            })`,
        value: devicesById[e]?.id,
      }))
      .sort((a: FieldValue, b: FieldValue) =>
        a.text && b.text ? a.text.toLowerCase().localeCompare(b.text.toLowerCase()) : 1
      ),
  ]
  return (
    <>
      <label>{'Dispositivo Associado'}</label>
      <Dropdown
        value={fields.sensor}
        placeholder={'<<Sem dispositivo>>'}
        search
        selection
        loading={loading}
        options={options}
        onChange={(_e, { value }) => handleChange(fields, 'sensor', value as string)}
      />
    </>
  )
}

const AssetThingForm = (props: AssetThingFormProps) => {
  const { t } = useTranslation()
  const { addOpen, row, confirmAction, cudLoading, handleSave, handleCancel, table, noType, typesAllIds, typesById } =
    props

  const [showAllDevices, setShowAllDevices] = React.useState(false)

  const [fields, setFields] = useState<FieldsAssetThingState>({
    id: row?.id || '',
    name: row?.name || '',
    code: row?.info?.code || '',
    sensor: row?.sensor || row?.deviceId || '',
    type: row?.info?.type || '',
    idType: row?.info?.idType || 1,
  })

  const [errors, setErrors] = useState<Partial<FieldsAssetThingState>>({
    name: '',
  })

  const handleChange = (fields: FieldsAssetThingState, name: string, value: string | boolean) => {
    setFields({ ...fields, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  //validate form fields
  const validate = (fields: FieldsAssetThingState) => {
    let hasError = false
    if (!fields.name) {
      setErrors({ ...errors, name: 'Nome é obrigatório' })
      hasError = true
    }
    return hasError
  }
  const handleSaveForm = (fields: FieldsAssetThingState) => {
    if (!validate(fields)) {
      handleSave(fields)
    }
  }
  return (
    <ModalTemplate
      onClose={handleCancel}
      open={addOpen}
      loading={cudLoading}
      size="small"
      header={
        confirmAction === 'insert' ? <span>{`Adicionar ${t(table)}`} </span> : <span>{`Editar ${t(table)}`}</span>
      }
      onSave={() => {
        handleSaveForm(fields)
      }}
      onCancel={handleCancel}
    >
      <Form>
        <Form.Group>
          <Form.Field required={false} width={3}>
            <label>{t('code')}</label>
            <Input
              name={'code'}
              fluid
              value={fields.code}
              min="0"
              onChange={(_e, { name, value }) => handleChange(fields, name, value.slice(0, 8))}
            />
          </Form.Field>
          <Form.Field width="8" required={true} error={!!errors.name}>
            <label>{'Nome'}</label>
            <Input
              placeholder="Novo"
              name={'name'}
              fluid
              value={fields.name}
              onChange={(_e, { name, value }) => handleChange(fields, name, value)}
            />
          </Form.Field>
          <Form.Field required={false} width={5}>
            <label>{t('Assignment')}</label>
            <Dropdown
              defaultValue={fields.type}
              placeholder={noType}
              search
              selection
              options={[
                {
                  key: 'noType',
                  text: noType,
                  value: '',
                },
                ...typesAllIds
                  .filter((tp) => typesById[tp]?.type === 'assets')
                  .map((e) => ({
                    key: typesById[e]?.id,
                    text: typesById[e]?.name,
                    value: typesById[e]?.id,
                  }))
                  .sort((a, b) => (a.text && b.text ? a.text.toLowerCase().localeCompare(b.text.toLowerCase()) : 1)),
              ]}
              onChange={(e, { value }) => handleChange(fields, 'type', value as string)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field required={false} width={16}>
            <DeviceDropdown
              {...props}
              handleChange={handleChange}
              fields={fields}
              fetchDevices={fetchDevices}
              showAllDevices={showAllDevices}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field
            name={'showAllDevices'}
            control={Checkbox}
            checked={showAllDevices}
            label="Visualizar todos os dispositivos"
            required={false}
            width={16}
            onChange={() => setShowAllDevices(!showAllDevices)}
          ></Form.Field>
        </Form.Group>
      </Form>
    </ModalTemplate>
  )
}
export default React.memo(AssetThingForm)
