import { Storage } from 'aws-amplify'
import { omit } from 'lodash'

interface CustomField {
  [key: string]: any
  type: string
  id: string
}

interface ImageValue {
  path: string
  file: File
}

interface ChangeImagesPathInput {
  tenant: string
  moduleId: string
  customFieldsStruct: CustomField[]
  properties?: any
}

/**
 * Extrai o ID da imagem a partir do caminho.
 * Retorna undefined se não encontrar o ID.
 *
 * @param path Caminho ou URL da imagem.
 */
function extractImageId(path: string): string | undefined {
  try {
    const parsedUrl = new URL(path)
    return parsedUrl.pathname.split('/').pop() || undefined
  } catch (_) {
    // Se path não for uma URL válida, tenta extrair do próprio valor
    const parts = path.split('/')
    return parts[parts.length - 1] || undefined
  }
}

/**
 * Realiza o upload de um arquivo usando o caminho fornecido.
 *
 * @param file Arquivo a ser enviado.
 * @param newPath Novo caminho para armazenar o arquivo.
 * @param contentType Tipo de conteúdo do arquivo.
 * @returns A chave de armazenamento após o upload.
 */
async function uploadFile(file: File, newPath: string, contentType: string): Promise<string> {
  // Remove prefixo se necessário para o Storage
  const pathForStorage = newPath.startsWith('public/') ? newPath.replace('public/', '') : newPath
  const result = await Storage.put(pathForStorage, file, { contentType })
  return result.key
}

/**
 * Processa e atualiza o caminho de uma imagem.
 *
 * @param image Objeto que contém path e file.
 * @param newPathTemplate Template para o novo caminho contendo placeholders {tenant}, {moduleId}, {customFieldId} e {imageId}.
 * @param placeholders Objeto com os valores para os placeholders.
 * @returns Objeto com o novo caminho ou null em caso de falha.
 */
async function processImage(
  image: ImageValue,
  newPathTemplate: string,
  placeholders: { tenant: string; moduleId: string; customFieldId?: string }
): Promise<{ path: string } | null> {
  try {
    const imageId = extractImageId(image.path)
    if (!imageId) {
      throw new Error('Image ID not found in the path.')
    }
    // Substitui os placeholders do template.
    let newPath = newPathTemplate
      .replace('{tenant}', placeholders.tenant)
      .replace('{moduleId}', placeholders.moduleId)
      .replace('{imageId}', imageId)
    if (placeholders.customFieldId) {
      newPath = newPath.replace('{customFieldId}', placeholders.customFieldId)
    }
    // Realiza o upload do arquivo e obtém a chave do Storage.
    await uploadFile(image.file, newPath, image.file.type)
    return { path: newPath }
  } catch (error) {
    console.error(`Failed to process image with path ${image.path}:`, error)
    return null
  }
}

/**
 * Altera os caminhos das imagens nos campos customizados e na propriedade URL.
 * Evita duplicação de código utilizando funções auxiliares.
 *
 * @param tenant Identificador do tenant.
 * @param moduleId Identificador do módulo.
 * @param customFieldsStruct Estrutura dos campos customizados.
 * @param properties Propriedades que podem conter imagens e URL.
 * @returns Novas propriedades com caminhos atualizados.
 */
export async function changeImagesPath({
  tenant,
  moduleId,
  customFieldsStruct,
  properties,
}: ChangeImagesPathInput): Promise<any> {
  let newProperties = { ...properties }

  // Processa campos de imagens customizados
  const imageFieldIds = customFieldsStruct.filter((field) => field.type === 'images').map((field) => field.id)

  for (const fieldId of imageFieldIds) {
    const images: ImageValue[] = properties.customFields?.[fieldId]
    if (images && Array.isArray(images)) {
      // Template para o novo caminho para imagens do campo customizado
      const newPathTemplate = `public/${tenant}/geoModules/${moduleId}/customFields/{customFieldId}/images/{imageId}`
      const updatedImages = await Promise.all(
        images.map((image) => processImage(image, newPathTemplate, { tenant, moduleId, customFieldId: fieldId }))
      )
      // Filtra imagens que foram processadas com sucesso
      newProperties.customFields[fieldId] = updatedImages.filter((img) => img !== null)
    }
  }

  // Processa a imagem principal se existir
  if (properties?.url && properties?.imageFile) {
    const imageId = extractImageId(properties.url)
    if (!imageId) {
      throw new Error('Image ID not found in the path.')
    }
    // Template para o novo caminho da imagem principal
    const newPath = `${tenant}/geoModules/${moduleId}/images/${imageId}`
    const key = await uploadFile(properties.imageFile, newPath, properties.imageFile.type)
    newProperties.url = key
    // Remove o campo imageFile após o upload
    newProperties.customFields = newProperties.customFields || {}
    newProperties = omit(newProperties, 'imageFile')
  }

  return newProperties
}
