import initialStore from 'Store/initialStore'

const initialState = initialStore.tenants
export default function tenants(state = initialState, { type, payload }) {
  switch (type) {
    case 'FETCH_TENANTS':
      return {
        ...state,
        ...payload,
      }
    case 'PICK_TENANT':
      return {
        ...state,
        current: payload,
      }
    default:
      return state
  }
}
