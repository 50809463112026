import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'semantic-ui-react'
import TimeFields from 'Utils/components/TimeFields'
import { setPanelProp } from 'Store/actions/betaDashboards-action'

type Props = {
  panelId: string
}

function PanelStepThreeForm({ panelId }: Props) {
  const dispatch = useDispatch()

  const handleSetProp = useCallback(
    (field: string, value: unknown) => {
      dispatch(setPanelProp({ panelId, field, value }))
    },
    [dispatch, panelId]
  )

  return (
    <Form>
      <TimeFields entity="betaDashboards" setProp={handleSetProp} panelId={panelId} />
    </Form>
  )
}

export default PanelStepThreeForm
