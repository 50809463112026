import React from 'react'
import { Modal, Button, SemanticCOLORS, Icon, Header } from 'semantic-ui-react'
import styled from 'styled-components'
import Loading from 'Utils/components/Loading'
import { ModalCloseIcon } from 'Utils/reactTable/components/ModalCloseIcon.jsx'

interface ModalTemplateProps {
  /** Function called when the modal is closed */
  onClose?: () => void
  /** If true, the modal is open */
  open: boolean
  /** Header content of the modal, can be JSX or a string */
  header?: JSX.Element | string
  /** The content inside the modal */
  children: React.ReactNode
  /** Size of the modal */
  size?: 'tiny' | 'small' | 'large' | 'fullscreen'
  /** If true, the save button shows a loading spinner */
  loading?: boolean
  /** Content for the save button */
  saveContent?: string
  /** Color of the save button */
  saveColor?: SemanticCOLORS
  /** Function called when the save button is clicked */
  onSave?: () => void
  /** Icon name for the save button */
  saveIcon?: string
  /** Content for the cancel button */
  cancelContent?: string
  /** Color of the cancel button */
  cancelColor?: SemanticCOLORS
  /** Function called when the cancel button is clicked */
  onCancel?: () => void
  /** Icon name for the cancel button */
  cancelIcon?: string
  /** Margin for the content inside the modal (in pixels) */
  contentPXMargin?: number
  /** If true, a save button is displayed */
  saveButton?: boolean
  /** Fixed height for the modal content */
  fixedHeight?: string
  /** If true, enables scrolling for the modal */
  scrolling?: boolean
  /** Padding for the modal content */
  padding?: string
  /** Padding bottom for the modal content */
  paddingBottom?: string
  dimmer?: true | 'blurring' | 'inverted'
  cancelButton?: boolean
  closeIcon?: boolean
  bottom?: string
  centeredHeader?: boolean
  loadingContent?: boolean
  noData?: boolean
}

/**
 * A reusable modal component with header, content, and action buttons.
 *
 * @param {ModalTemplateProps} props The properties that define the modal
 * @returns {JSX.Element} The ModalTemplate component
 */
export const ModalTemplate: React.FC<ModalTemplateProps> = ({
  onClose,
  open,
  header,
  children,
  size = 'large',
  loading = false,
  saveContent = 'Salvar',
  saveColor = 'blue',
  onSave,
  saveIcon = 'check',
  cancelContent = 'Cancelar',
  cancelColor,
  onCancel,
  cancelIcon = 'delete',
  contentPXMargin = 15,
  saveButton = true,
  fixedHeight = '',
  scrolling = false,
  padding = '0 1.6em',
  paddingBottom,
  dimmer,
  cancelButton = true,
  closeIcon = true,
  bottom,
  centeredHeader,
  loadingContent,
  noData,
}) => {
  const [localOpen, setLocalOpen] = React.useState(false)
  const isOpen = open !== undefined ? open : localOpen

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose()
    } else {
      setLocalOpen(false)
    }
  }

  const handleSave = () => {
    if (typeof onSave === 'function') {
      onSave()
    } else {
      setLocalOpen(false)
    }
  }

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    } else {
      handleClose()
    }
  }

  return (
    <Modal
      onClose={handleClose}
      open={isOpen}
      size={size}
      closeIcon={closeIcon && <ModalCloseIcon action={handleClose} />}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      dimmer={dimmer}
      style={{ ...(fixedHeight && { height: fixedHeight, bottom }), ...(bottom && { bottom }) }}
    >
      {header ? (
        centeredHeader ? (
          <CenteredHeader>{header}</CenteredHeader>
        ) : (
          <Modal.Header>{header}</Modal.Header>
        )
      ) : null}
      <Modal.Content
        style={{
          ...(fixedHeight && { height: '85%' }),
          ...(fixedHeight && { maxHeight: '85%' }),
          ...(!fixedHeight && {
            padding: padding,
            margin: `${contentPXMargin}px 0 ${contentPXMargin + 15}px 0`,
          }),
          ...(paddingBottom && { paddingBottom: '0px' }),
        }}
        scrolling={scrolling}
      >
        {loadingContent ? (
          <LoadingDiv>
            <Loading />
          </LoadingDiv>
        ) : noData ? (
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              height: ' 100%',
              paddingTop: '15%',
            }}
          >
            <Icon name="clipboard list" size="huge" />
            <Header as="h2">Sem registros de logs</Header>
            <p>Apenas novas alterações nesse item serão registradas</p>
          </div>
        ) : (
          children
        )}
      </Modal.Content>
      <Modal.Actions style={{ height: '65px' }}>
        {saveButton && (
          <Button
            icon={saveIcon}
            style={{
              display: cancelButton ? undefined : 'block',
              margin: cancelButton ? undefined : '0 auto',
              minWidth: '120px',
            }}
            loading={loading}
            disabled={loading}
            content={saveContent}
            color={saveColor}
            onClick={handleSave}
          />
        )}
        {cancelButton && (
          <Button
            style={{ float: 'left', minWidth: '120px' }}
            icon={{ name: cancelIcon, style: { zIndex: '1' } }}
            content={cancelContent}
            color={cancelColor}
            onClick={handleCancel}
          />
        )}
      </Modal.Actions>
    </Modal>
  )
}

const CenteredHeader = styled(Modal.Header)`
  display: flex;
  justify-content: center;
`

const LoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
