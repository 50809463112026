import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Icon, Segment /*, Dropdown*/ } from 'semantic-ui-react'
import styled from 'styled-components'
//import { useTranslation } from 'react-i18next'
import DashboardPanelContent from './DashboardPanelContent' //import { set } from 'lodash'
//import ReportsList from './ReportsList'
import { debounce } from 'lodash'
import { saveLoginPreferences, changeDashboardPanelPreferences } from 'Store/actions/login-action'
import DashboardPanelConfig from './DashboardPanelConfig'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'

//import ConfigList from './ConfigList'

const SegmentPanel = styled(Segment)`
  --border: 1px red solid !important;
  border: none !important;
  height: 100%;
  box-shadow: 0px 0px !important;
  background-color: #f5f5f5 !important;
  margin: 0 !important;
  padding: 8px !important;
  overflow: hidden;
`

const DivPanel = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
  font-size: 14px;
  background-color: white;
  border-radius: 8px;
`

const DivPanelIndicator = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
  font-size: 14px;
  background-color: white;
  border-radius: 8px;
  border-left: 10px ${(props) => props.barColor} solid;
`

const PanelHeader = styled.div`
  width: 100%;
  height: 10%;
  color: #666;
  --border: 1px blue solid;
`

const PanelTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 93%;
  display: inline-block;
  padding: 5px;
  --border: 1px teal solid;
  font-weight: 700;
  font-size: 14px;
`

const PanelConfig = styled.div`
  margin-top: 5px;
  margin-right: 4px;
  float: right;
  z-index: 4;
  color: #666;
  --border: 1px purple solid;
  width: 5%;
  text-align: right;
`

const PanelContent = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 90%;
  --border: 1px green solid;
`

const DashboardPanel = ({ panelId, setStaticMode, layoutStats }) => {
  // const { t } = useTranslation()
  const dispatch = useDispatch()
  const panelPrefs = useSelector(
    (state) => state.login.preferences.routesPanels.byId['dashboard'].panelsById[panelId] || {}
  )
  const debouncedChangeHandler = useMemo(() => debounce((layout) => dispatch(saveLoginPreferences()), 3000), [dispatch])
  const [titleText, setTitleText] = useState(panelPrefs.label)
  const [editReportMode, setEditReportMode] = useState(false)
  const [changes, setChanges] = useState(panelPrefs)
  const [open, setOpen] = useState(false)
  const [good, setGood] = useState(null)

  //const [editConfigMode, setConfigMode] = useState(false)

  useEffect(() => {
    if (panelPrefs.label !== titleText) setTitleText(panelPrefs.label)
  }, [panelPrefs.label, titleText])

  const handleSave = () => {
    dispatch(changeDashboardPanelPreferences({ id: panelPrefs.id, preferences: changes }))
    debouncedChangeHandler()
    setStaticMode(false)
    setOpen(false)
  }
  const handleClose = () => {
    setStaticMode(false)
    setOpen(false)
  }

  const panelHeader = () => (
    <PanelHeader>
      {
        <PanelTitle onClick={() => setEditReportMode(!editReportMode)} title={panelPrefs.description || titleText}>
          {titleText}
        </PanelTitle>
      }
      {
        <PanelConfig
          style={{ cursor: 'pointer', zIndex: 2 }}
          onClick={() => {
            setStaticMode(true)
            setOpen(true)
          }}
        >
          <Icon
            name="cog"
            title="Clique para editar o painel"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setStaticMode(true)
              setOpen(true)
            }}
          />
        </PanelConfig>
      }
    </PanelHeader>
  )

  const panelContent = () => (
    <PanelContent>
      <DashboardPanelContent panelId={panelId} panelPrefs={panelPrefs} layoutStats={layoutStats} setGood={setGood} />
    </PanelContent>
  )
  return (
    <>
      {open && (
        <ModalTemplate
          onClose={handleClose}
          open={open}
          size="large"
          header={'Configuração do ' + panelPrefs.label}
          saveContent={'Salvar'}
          onSave={handleSave}
          onCancel={handleClose}
          scrolling
          contentPXMargin={0}
          padding={'0px'}
          fixedHeight={'100vh'}
          paddingBottom={'0px'}
        >
          <DashboardPanelConfig changes={changes} setChanges={setChanges} />
        </ModalTemplate>
      )}
      <SegmentPanel>
        {panelPrefs.panelType === 'kpi' ? (
          <DivPanelIndicator barColor={good === true ? '#07bc0c' : good === false ? '#ff2d55' : '#cecece'}>
            {panelHeader()}
            {panelContent()}
          </DivPanelIndicator>
        ) : (
          <DivPanel>
            {panelHeader()}
            {panelContent()}
          </DivPanel>
        )}
      </SegmentPanel>
    </>
  )
}

export default DashboardPanel
