import _ from 'lodash'

/**
 * Merges two objects. For properties that are arrays, the arrays in the second object will replace the arrays in the first object.
 * If a property in obj2 is null, that value will be used. If it's undefined, the value from obj1 will be retained.
 * @param obj1 - The first object.
 * @param obj2 - The second object.
 * @returns A new object that is the result of merging obj1 and obj2.
 */
export default function mergeObjects<TObject, TSource>(obj1: TObject, obj2: TSource): TObject & TSource {
  return _.mergeWith({}, obj1, obj2, (objValue, srcValue) => {
    if (srcValue === null) {
      return null
    }
    if (srcValue === undefined) {
      return objValue
    }
    if (Array.isArray(objValue)) {
      return srcValue
    }
  })
}
