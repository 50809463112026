import React from 'react'
import {
  useIsAuthenticated,
  // useMsal
} from '@azure/msal-react'
import { Redirect } from 'react-router-dom'
//import Callback from 'Callback/Callback'

function AuthWrapper({ children, auth }) {
  const isAuthenticatedMsal = useIsAuthenticated()
  // const { inProgress } = useMsal()
  const isAuthenticatedAuth0 = auth.isAuthenticated()
  if (isAuthenticatedAuth0 === isAuthenticatedMsal && isAuthenticatedMsal === false) {
    sessionStorage.setItem('originalUrl', window.location.pathname)
    return <Redirect to="/login" />
  }

  return <>{children}</>
}
export default AuthWrapper
