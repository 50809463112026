import React from 'react'
import { Grid, Message, Button, ButtonGroup, ButtonOr } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { setProfilesProps } from 'Store/actions/profiles-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { RootStore } from 'Store/initialStore'
import { useTranslation } from 'react-i18next'
import { CustomField } from 'API'
import { v4 } from 'uuid'
import ProfileCustomGrids from './ProfileCustomGrids'

const ProfilesStepTwoForm: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const customFields = useObjSelector(
    (state) =>
      state['profiles'].wip?.['customFields'] ||
      state['profiles'].current?.['customFields'] ||
      ([
        {
          id: v4(),
          label: '',
          type: '',
        },
      ] as unknown as CustomField[])
  )

  const showFeedback = useSelector((state: RootStore) => state.profiles.form?.steps.items[1].showFeedback)

  const errors = useObjSelector((state) => state.profiles.form?.errors?.[1]?.customFields)

  const handleAddClick = (type: 'field' | 'section') => {
    dispatch(
      setProfilesProps([
        [
          'wip.customFields',
          [
            ...(customFields ? customFields : []),
            {
              id: v4(),
              label: type === 'field' ? '' : 'Nova Seção',
              type: type === 'field' ? '' : 'section',
            },
          ],
        ],
      ])
    )
  }

  return (
    <div>
      <ProfileCustomGrids />
      <br />
      <br />
      {/* display a '+' button at the bottom of the form to add new custom fields */}
      <Grid centered>
        <ButtonGroup size="large">
          <Button title={t('add_field')} onClick={() => handleAddClick('field')} style={{ width: '100px' }}>
            Campo
          </Button>
          <ButtonOr text={'+'} />
          <Button title={t('addSection')} onClick={() => handleAddClick('section')} style={{ width: '100px' }}>
            Seção
          </Button>
        </ButtonGroup>
      </Grid>
      {errors && showFeedback && (
        <Message negative style={{ marginTop: '30px' }}>
          <p>Preencha todos os campos obrigatórios.</p>
        </Message>
      )}
    </div>
  )
}

export default ProfilesStepTwoForm
