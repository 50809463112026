import React, { useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Icon, Card, Statistic, Menu } from 'semantic-ui-react'
import { saveLoginMapLayer } from 'Store/actions/login-action'
import LegendMenuItem from 'Components/Map/legends/LegendMenuItem'

export const CardRefsTotal = () => {
  const dispatch = useDispatch()

  // cards preferences
  const pref = useSelector((state) => state.login.preferences.mapLayers || [], shallowEqual)
  const [details, setDetails] = useState(pref?.includes('refsCountDetails'))
  const [empties, setEmpties] = useState(pref?.includes('refsCountEmpties'))

  const ids = useSelector((state) => state.modules?.refs?.allIds || [], shallowEqual)
  const info = useSelector((state) => state.modules?.refs?.byId || {}, shallowEqual)
  const typesIds = useSelector((state) => state.profiles.allIds || [], shallowEqual)
  const types = useSelector((state) => state.profiles.byId || [])
  const countedTypes = (typeId) => ids.filter((a) => info[a]?.profile === typeId).length
  const noTypes = () => ids.filter((a) => !info[a]?.profile).length
  const xNoType = noTypes()
  const typesObj = typesIds
    .filter((tp) => types[tp].type === 'refs')
    .map((t) => ({
      ...types[t],
      x: countedTypes(t),
      smallTitle:
        types[t]?.properties?.uniqueId?.slice(0, 5) || types[t]?.name?.slice(0, 5) || types[t]?.code?.slice(0, 5),
    }))
    .filter((ft) => empties || ft.x > 0)
    .sort((a, b) =>
      a.smallTitle && b.smallTitle ? a.smallTitle.toLowerCase().localeCompare(b.smallTitle.toLowerCase()) : 1
    )

  /*Margin calculations*/
  const histType = useSelector((state) => state.history.type)
  const total = typesObj.reduce((tot, { x }) => tot + x, xNoType)

  const menuWidths = typesObj.length + (xNoType ? 1 : 0)

  return (
    <Card
      color="blue"
      style={{
        width: `${(typesObj.length + (xNoType !== 0 ? 1 : 0)) * 5 + 1}vw`,
        maxWidth: '38.2vw',
        minWidth: '250px',
        marginTop: histType ? '112px' : '62px',
        zIndex: '900',
        backgroundColor: 'rgb(255,255,255,0.6)',
      }}
    >
      <Card.Content>
        <Statistic size="mini" floated="right" color={'black'}>
          <Statistic.Value>
            <Icon name={'marker'} /> {total?.toString()?.padStart(4, '0')}
          </Statistic.Value>
        </Statistic>

        <Card.Header>
          {details && (
            <Icon
              title={empties ? 'Clique para ocultar os perfis sem contagem.' : 'Clique para visualizar todos os perfis'}
              name={empties ? 'eye' : 'eye slash'}
              onClick={() => {
                dispatch(saveLoginMapLayer('refsCountEmpties', !empties))
                setEmpties(!empties)
              }}
              style={{ cursor: 'pointer' }}
            />
          )}
          Total de Referências
          {typesObj?.length > 0 && (
            <Icon
              title={details ? 'Clique para ocultar detalhes' : 'Clique para mostrar mais detalhes'}
              onClick={() => {
                dispatch(saveLoginMapLayer('refsCountDetails', !details))
                setDetails(!details)
              }}
              style={{ cursor: 'pointer' }}
              name={details ? 'caret down' : 'caret right'}
              color="black"
            />
          )}
        </Card.Header>
        {details && typesObj?.length > 0 && (
          <Card.Description style={{ overflowX: 'auto', width: '100%' }}>
            <Menu
              compact
              icon="labeled"
              size="mini"
              style={{ width: `${menuWidths * 5}vw`, backgroundColor: 'rgb(255,255,255,0.5)' }}
            >
              {typesObj.map((t) => (
                <LegendMenuItem
                  key={t.id}
                  name={t.name}
                  abbreviation={t.smallTitle}
                  count={t.x}
                  color={t.color || 'gray'}
                  icon={t.icon || 'FaCircle'}
                />
              ))}
              {xNoType !== 0 && (
                <LegendMenuItem
                  key={'noProfile'}
                  name={'Sem atribuição'}
                  abbreviation={'Indef'}
                  count={xNoType}
                  color={'gray'}
                />
              )}
            </Menu>
          </Card.Description>
        )}
      </Card.Content>
    </Card>
  )
}
export default CardRefsTotal
