import React, { useState, useMemo, ChangeEvent } from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import StatsBarHeader from './StatsBarHeader'
import StatsBarButtons from './StatsBarButtons'
import StatsBarFooter from './StatsBarFooter'
import StatsBarFormGeo from './StatsBarFormGeo'
import StatsBarFormHistory from './StatsBarFormHistory'
import StatsBarFormMain from './StatsBarFormMain'
import SideFormActions from './actions/SideFormActions'
import StatsBarFormTags from './StatsBarFormTags'
import useEntitySelector from 'Utils/hooks/useEntitySelector'
import { GeoModuleTypes } from 'API'

const SideFormContent: React.FC = () => {
  const id = useEntitySelector('modules', 'id')
  const isUpdate = !!id
  const type = useEntitySelector('modules', 'type') as GeoModuleTypes

  const [currentForm, setCurrentForm] = useState('main')

  const handleChange = (_e: ChangeEvent<HTMLInputElement>, { name }: { name: string }) => {
    setCurrentForm(name)
  }

  const statusBarButtons = useMemo(() => {
    let baseButtons = [
      { name: 'main', icon: 'home' },
      { name: 'geo', icon: 'compass outline' },
      { name: 'tags', icon: 'tag' },
    ]
    if (isUpdate) baseButtons.push({ name: 'history', icon: 'history' })
    return baseButtons
  }, [isUpdate])

  return (
    <>
      <Grid container stretched>
        <Grid.Row centered>
          <StatsBarHeader />
        </Grid.Row>
        <Grid.Row centered>
          <StatsBarButtons handleChange={handleChange} currentForm={currentForm} buttons={statusBarButtons} />
        </Grid.Row>
        <Grid.Row stretched>
          <Segment style={{ border: 'none', boxShadow: 'none', width: '100%', paddingRight: '0px' }}>
            {currentForm === 'main' && <StatsBarFormMain />}
            {currentForm === 'geo' && <StatsBarFormGeo />}
            {currentForm === 'history' && <StatsBarFormHistory moduleType={type} id={id} />}
            {currentForm === 'tags' && <StatsBarFormTags />}
          </Segment>
        </Grid.Row>
      </Grid>
      {id && <SideFormActions id={id} moduleType={type} />}
      <StatsBarFooter />
    </>
  )
}
export default SideFormContent
