import { ChangeEvent, RefObject, useEffect, useMemo, useState } from 'react'
import { throttle } from 'throttle-debounce'

export type VideoPlayerState = {
  isPlaying: boolean
  progress: number
  speed: number
  isMuted: boolean
  currentVideoTime: number
}

type UseVideoPlayerReturn = {
  playerState: VideoPlayerState
  setVideoCurrentTime: (time: number) => void
  togglePlay: () => void
  handleOnTimeUpdate: () => void
  handleVideoProgress: (event: ChangeEvent<any>) => void
  handleVideoSpeed: (event: ChangeEvent<any>) => void
  toggleMute: () => void
  setVideoProgress: () => void
}

export const useVideoPlayer = (videoElement: RefObject<HTMLVideoElement>): UseVideoPlayerReturn => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [currentVideoTime, setCurrentTime] = useState<number>(0)
  const [speed, setSpeed] = useState<number>(1)
  const [isMuted, setIsMuted] = useState<boolean>(false)
  const togglePlay = () => {
    setIsPlaying((prev) => !prev)
  }

  useEffect(() => {
    if (isPlaying) {
      videoElement?.current?.play()
    } else videoElement?.current?.pause()
  }, [isPlaying, videoElement])

  const handleOnTimeUpdate = () => {
    const progress = (videoElement!.current!.currentTime / videoElement!.current!.duration) * 100
    setProgress(progress)
    setCurrentTime(videoElement!.current!.currentTime)
  }

  // add throttle to avoid too many updates
  const setVideoCurrentTime = useMemo(
    () =>
      throttle(500, (time: number) => {
        if (videoElement?.current) {
          videoElement.current.currentTime = time
        }
        setCurrentTime(time)
      }),
    [videoElement]
  )

  const handleVideoProgress = (event: ChangeEvent<any>) => {
    const manualChange = Number(event.target.value)
    if (videoElement?.current) {
      videoElement.current.currentTime = (videoElement?.current?.duration / 100) * manualChange
    }
    videoElement?.current?.currentTime && setCurrentTime(videoElement?.current?.currentTime)
    setProgress(manualChange)
  }

  const handleVideoSpeed = (event: ChangeEvent<any>) => {
    const speed = Number(event.target.value)
    if (videoElement?.current) {
      videoElement.current.playbackRate = speed
    }
    setSpeed(speed)
  }

  const toggleMute = () => {
    setIsMuted((prev) => !prev)
  }

  const setVideoProgress = () => {
    if (videoElement?.current) {
      videoElement.current.currentTime = (videoElement?.current?.duration / 100) * progress
    }
  }

  useEffect(() => {
    if (videoElement?.current) {
      isMuted ? (videoElement.current.muted = true) : (videoElement.current.muted = false)
    }
  }, [isMuted, videoElement])

  return {
    playerState: {
      isPlaying,
      progress,
      speed,
      isMuted,
      currentVideoTime,
    },
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    setVideoProgress,
    setVideoCurrentTime,
  }
}
