import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, FormGroup, FormRadio } from 'semantic-ui-react'
import { RootStore } from 'Store/initialStore'
import { setPanelProp } from 'Store/actions/betaDashboards-action'
import DropdownField from 'Components/forms/generic/DropdownField'
import useStoreSelector from 'Utils/hooks/useStoreSelector'
import useFetchData from 'Utils/hooks/useFetchData'

type Props = {
  panelId: string
}

function PanelStepFourDonut({ panelId }: Props) {
  useFetchData({ independentFetches: [{ fetchAction: 'profiles' }] })

  const dispatch = useDispatch()

  const scope = useSelector(
    (state: RootStore) =>
      state.betaDashboards?.wip?.panels?.[panelId]?.scope ?? state.betaDashboards.current?.panels[panelId]?.scope ?? ''
  )
  const profileId: any = useSelector(
    (state: RootStore) =>
      state.betaDashboards.wip?.panels?.[panelId]?.filters?.profileId ||
      state.betaDashboards.current?.panels?.[panelId]?.filters?.profileId
  )

  const groupBy: any = useSelector(
    (state: RootStore) =>
      state.betaDashboards.wip?.panels?.[panelId]?.filters?.groupBy ||
      state.betaDashboards.current?.panels?.[panelId]?.filters?.groupBy
  )

  const profilesById = useStoreSelector((state) => state.profiles.byId)

  const profile = profilesById[profileId]

  const groupOptions = useMemo(() => {
    let scopeOptions: { key: string; text: string; value: string }[] = []

    if (scope === 'references') {
      if (!profile || !profile.customFields) {
        return []
      }

      scopeOptions = profile.customFields.reduce((acc: any, cf) => {
        const excludedTypes = ['dateTime', 'date', 'images', 'section', 'checkList']

        if (!excludedTypes.includes(cf.type)) {
          acc.push({
            key: cf.id,
            text: cf.label,
            value: cf.label,
          })
        }

        return acc
      }, [])

      scopeOptions.unshift({
        key: 'createdBy',
        text: 'Criado Por',
        value: 'createdBy',
      })
    }

    if (scope === 'rules') {
      scopeOptions = [
        {
          key: 'ruleId',
          text: 'Código da Regra',
          value: 'ruleId',
        },
        {
          key: 'name',
          text: 'Nome da Regra',
          value: 'name',
        },
        {
          key: 'assetProfileId',
          text: 'Atribuição',
          value: 'assetProfileId',
        },
        {
          key: 'fenceId',
          text: 'Perímetro',
          value: 'fenceId',
        },
      ]
    }

    return [
      {
        key: 'date',
        text: 'Data',
        value: 'date',
      },
      {
        key: 'month',
        text: 'Mês',
        value: 'month',
      },
      // To do: divider
      ...scopeOptions,
    ]
  }, [scope, profile])

  return (
    <Form>
      <DropdownField
        label="Agrupar por"
        options={groupOptions}
        placeholder="Selecione o agrupamento"
        onChange={(_: any, { value }: any) =>
          dispatch(setPanelProp({ panelId, field: 'filters.groupBy', value: value }))
        }
        value={groupBy}
      />
      <br />
      <FormGroup inline>
        <label>Agregação</label>
        <FormRadio name="aggregation" label="Contagem" value="count" checked={true} />
      </FormGroup>

      <br />
    </Form>
  )
}

export default PanelStepFourDonut
