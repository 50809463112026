import React from 'react'
//import { CSVLink } from 'Libs/toCSV/index';
//import { ReactComponent as ExportCSV } from 'Resources/Icons/csv-export.svg'
import DropdownFilter from 'Utils/DropdownFilter'
import DropdownHist from 'Utils/DropdownHist'
//import { headers } from './csvHistory';
import HeatMapButtons from './HeatMapButtons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

/*  Author: Luis Mourão/ Felipe Pegado(Refactor)
    Type: Dumb
	Description: Container for the history bar's icons
    TODO: - Criar componente dumb grid com o conteúdo do return(render)
          - 
*/

const HistoryIconsContainer = styled.div`
  width: auto;
  margin-left: 10px !important;
`

export const HistoryIcons = ({
  perfil,
  calendarIcon,
  exportHist,
  onChangeHistData,
  csvClick,
  csvBool,
  raw,
  dispatch,
  routeByProximity,
  flag,
  showHeatMap,
}) => {
  const { t } = useTranslation()

  return (
    !flag && (
      <HistoryIconsContainer>
        {showHeatMap && <HeatMapButtons />}
        {!showHeatMap && perfil === 'DEV' && (
          <DropdownFilter
            onChangeHistData={onChangeHistData}
            raw={raw}
            dispatch={dispatch}
            options={[t('HistorySliderRoute'), t('HistorySliderNoFilter')]}
            routeByProximity={routeByProximity}
          />
        )}
        {!showHeatMap && perfil === 'DEV' && (
          <DropdownHist
            onChangeHistData={onChangeHistData}
            raw={raw}
            dispatch={dispatch}
            options={[t('HistorySliderNoPath'), t('HistorySliderDiscrete')]}
          />
        )}
        {/* !showHeatMap &&
                <CSVLink onClick={csvClick} separator=";" title={t("HistorySliderExp")} data={exportHist}
                    headers={csvBool ? headers : [[]]}><ExportCSV /></CSVLink> */}
      </HistoryIconsContainer>
    )
  )
}
