import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'Utils/reactTable'
import { saveGeoModule, saveGroup, setCurrentModule } from 'Store/actions/modules-action'
import { deleteGeoModules } from 'Store/actions/modules-action'
import { InputTemplate } from 'Utils/components/InputTemplate.js'
import { saveColumnsPreference } from 'Store/actions/login-action'
import { SvgIcon } from 'Utils/components/SvgIcon.jsx'
import useFetchData from 'Utils/hooks/useFetchData'
// import TableSpan from 'Components/SidebarMenu/TableSpan'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { GenericColumns } from 'Utils/reactTable/components/GenericColumns'
import ModuleDetailsContainer from 'Components/modals/ModuleDetailsContainer'
import ChangeLogs from 'Components/modals/ChangeLogs'
import moment from 'moment'
import { setTrue } from 'Store/actions/toggle-action'
import { setChangeLogsProps } from 'Store/actions/changeLogs-action'
import ClickableLink from 'Utils/components/ClickableLink'

//import risks from 'Utils/theme/risks'

export const RefsContent = () => {
  const { t } = useTranslation()
  const tableName = 'refs'

  const [currentSort, setCurrentSort] = useState('label')
  const [loading, setLoading] = useState(true)
  useFetchData({
    independentFetches: [{ fetchAction: 'geoModules' }, { fetchAction: 'profiles' }],
    finishAction: () => setLoading(false),
  })

  const tableLabel = 'Referências'
  const refsStr = useSelector((state) => JSON.stringify(state.modules.refs))

  const dispatch = useDispatch()
  const groupAction = (groupName, selected, action) => dispatch(saveGroup('refs', groupName, selected, action))
  const deleteAction = (ids) => dispatch(deleteGeoModules({ type: 'refs', ...ids }))

  const [theseColumns, setTheseColumns] = useState([])
  const hiddenColumns = useSelector((state) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns)

  const groupedValuesColumns = useSelector(
    (state) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const assetTypes = useObjSelector((state) => state.profiles)
  const showModuleDetails = useSelector((state) => state.toggle.showModuleDetails)
  const showChangeLogs = useSelector((state) => state.toggle.showChangeLogs)

  const current = useObjSelector((state) => state.modules.current)

  const types = React.useMemo(
    () =>
      assetTypes.allIds
        .filter((tp) => assetTypes.byId[tp]?.type === 'refs')
        .map((t) => ({
          key: t,
          value: t,
          text: assetTypes.byId[t]?.name,
          color: assetTypes.byId[t]?.color,
          iconsvg: assetTypes.byId[t].icon,
        })),
    [assetTypes]
  )

  const [currentGroup, setCurrentGroup] = useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const noType = '<<' + t('noAssignment') + '>>'

  const formatColumns = React.useMemo(() => {
    const typeColor = (id /*:string*/) => types.filter((f) => f.value === id)[0]?.color
    const typeIcon = (id /*:string*/) => types.filter((f) => f.value === id)[0]?.iconsvg

    return [
      {
        Header: t('Code'),
        accessor: 'code',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} códigos`,
      },
      {
        Header: t('label'),
        accessor: 'label',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} referências`,
        Cell: (props) => {
          const rowId = props?.cell?.row?.original?.id
          return (
            <InputTemplate
              value={props.value || '<<sem nome>>'}
              id={rowId}
              action={(id, field, value) =>
                dispatch(saveGeoModule({ mutation: 'update', fields: { id, [field]: value }, type: 'refs' }))
              }
              field={'label'}
              table={tableName}
              setCurrent={() => dispatch(setCurrentModule(rowId, 'refs'))}
            />
          )
        },
      },
      // {
      //   Header: t('description'),
      //   accessor: 'description',
      //   aggregate: 'count',
      //   Aggregated: ({ value }) => `${value} descrições`,
      //   Cell: (props) => {
      //     return <TableSpan value={props.value || '  '} width="400px" />
      //   },
      // },
      {
        Header: t('Assignment'),
        accessor: 'profile',
        align: 'center',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${value === '1' ? t('Assignment') : t('Assignments')}`,
        Cell: (props) => {
          const rowId = props?.cell?.row?.original?.id
          return (
            <>
              <SvgIcon
                title={'Clique no nome para editá-lo'}
                color={typeColor(props.value)}
                name={typeIcon(props.value)}
                compact
              />
              <br />
              <InputTemplate
                options={types}
                value={props.value || noType}
                id={rowId}
                action={(id /*:string*/, field /*:string*/, value /*:string*/) => {
                  dispatch(saveGeoModule({ mutation: 'update', fields: { id, [field]: value }, type: 'refs' }))
                }}
                field="profile"
                table={tableName}
                setCurrent={() => dispatch(setCurrentModule(rowId, 'refs'))}
              />
            </>
          )
        },
      },
    ]
  }, [t, dispatch, noType, types])

  const columns = [
    ...formatColumns,
    ...GenericColumns({
      columns: [
        {
          accessor: 'updatedAt',
          Header: t('updatedAt'),
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => {
            return `${value} Data(s)`
          },
          Cell: ({
            value,
            row: {
              original: { id },
            },
          }) => {
            return (
              <ClickableLink
                title={moment(value).format('lll')}
                onClick={() => {
                  dispatch(setCurrentModule(id, 'refs'))
                  dispatch(setChangeLogsProps([['entityId', id]]))
                  dispatch(setTrue('showChangeLogs'))
                }}
              >
                {moment(value).fromNow()}
              </ClickableLink>
            )
          },
        },
      ],
    }),
  ]

  const data = React.useMemo(() => {
    const refs = JSON.parse(refsStr)

    return refs?.allIds?.map((id) => {
      let ref = refs?.byId[id]
      let typeColor = types?.filter((f) => f.key === ref?.info?.properties?.type)?.[0]?.color
      let typeIcon = types?.filter((f) => f.key === ref?.info?.properties?.type)?.[0]?.iconsvg

      return {
        ...ref,
        id: id,
        info: {
          ...ref?.info,

          label: ref?.info?.properties?.label || ref.name,
        },
        groupsText: ref?.groups
          ?.filter((g) => !g.startsWith('R0'))
          ?.sort()
          ?.join(', '),
        rulesText: ref?.groups
          ?.filter((g) => g.startsWith('R0'))
          ?.sort()
          ?.join(', '),
        type: ref?.info?.properties?.type,
        typeIcon: typeIcon,
        typeColor: typeColor,
      }
    })
  }, [refsStr, types])

  return (
    <>
      <ReactTable
        table={tableName}
        tableLabel={tableLabel}
        hiddenColumns={hiddenColumns}
        columns={columns}
        data={data}
        theseColumns={theseColumns}
        setTheseColumns={setTheseColumns}
        groupAction={groupAction}
        deleteAction={deleteAction}
        currentGroup={currentGroup}
        setCurrentGroup={setCurrentGroup}
        initialSortBy={{ desc: true, id: 'updatedAt' }}
        setCurrentSort={setCurrentSort}
        currentSort={currentSort}
        showPlaceholders={loading}
      />
      {showModuleDetails && <ModuleDetailsContainer type={tableName} />}
      {showChangeLogs && <ChangeLogs entity="geoModule" currentItem={current} />}
    </>
  )
}
