import produce from 'immer'
import _ from 'lodash'

export default function devicesReducer(state = [], { type, id, payload }) {
  let errorTypes = ['sensorNew', 'updatesensorNew', 'updatedeletesensorNew']

  let byId = { ...state.byId }
  let allIds = state.allIds ? [...state.allIds] : []
  let current = { ...state.current }

  if (errorTypes.includes(type)) {
    if (payload === null) {
      console.error(type + ' null: ', id, payload)
      return state
    }
    if (!id && !payload.id) {
      console.error(type + ' without ID: ', id, payload)
      return state
    }
    if (!payload.info) {
      console.error(type + ' without info', id, payload)
      return state
    }
  }

  const splitType = type.split('#')[0]

  switch (splitType) {
    case 'FETCH_DEVICES_START':
      return { ...state, loading: true }

    case 'FETCH_DEVICES_SUCCESS':
      return produce(state, (newState) => {
        // Preenche allIds com todos os IDs únicos
        newState.allIds = payload.map((device) => device.id)

        // Preenche byId com o objeto completo indexado pelo ID
        newState.byId = _.keyBy(payload, 'id')
        newState.current = {}
        newState.loading = false
      })

    case 'FETCH_DEVICES_FAILURE':
      return { ...state, loading: false }

    case 'sensorNew':
      byId[id] = payload
      allIds.push(id)
      return { ...state, byId, allIds }

    case 'updatesensorNew':
      byId[id] = {
        ...state.byId[id],
        ...payload,
      }
      return { ...state, byId }

    case 'updatedeletesensorNew':
      allIds = allIds.filter((e) => e !== id)
      return { ...state, allIds }

    case 'SET_CURRENT_ASSET':
      current = byId[id]
      return { ...state, current }
    case 'UPDATE_SENSOR_START':
      return { ...state, cudLoading: true }
    case 'UPDATE_SENSOR_SUCCESS':
      byId[id] = {
        ...state.byId[id],
        ...payload,
      }
      return { ...state, byId, cudLoading: false }
    case 'UNPROVISION_DEVICES_START':
    case 'PROVISION_DEVICE_START':
      return { ...state, cudLoading: true, errors: undefined, success: undefined }
    case 'UNPROVISION_DEVICES_SUCCESS':
      return { ...state, cudLoading: false, errors: undefined }
    case 'PROVISION_DEVICE_SUCCESS':
      //allIds = [...state.allIds, payload.sensorId]
      return { ...state, /*allIds, */ cudLoading: false, errors: undefined, success: true }
    case 'UNPROVISION_DEVICES_ERROR':
    case 'PROVISION_DEVICE_ERROR':
      return {
        ...state,
        cudLoading: false,
        errors: payload.message,
        success: undefined,
      }
    case 'SENSOR_CLEAR_STATES':
      return { ...state, errors: undefined, success: undefined, loading: false }

    case 'CLEAR_DEVICES':
      return {
        ...state,
        byId: {},
        allIds: [],
        current: {},
        loading: false,
        success: undefined,
        errors: undefined,
      }

    case 'NEW_ASSET_LOCATION':
      if (state.byId[payload.deviceId]) {
        return produce(state, (newState) => {
          if (newState.byId[payload.deviceId]) newState.byId[payload.deviceId].lastSeen = payload.time
        })
      } else return state

    default:
      return state
  }
}
