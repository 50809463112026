interface LabelType {
  phaseIndex: number
  startTime: string
  endTime: string
}

interface StepType {
  assetId: string
  time: string
  steps: number
}

function mergeStepsWithLabels(labels: LabelType[], steps: StepType[]) {
  return labels.map((label) => {
    const stepsInLabel = steps.filter((step) => {
      return step.time >= label.startTime && step.time < label.endTime
    })

    const groupedByAsset = stepsInLabel.reduce((acc, step) => {
      if (!acc[step.assetId]) {
        acc[step.assetId] = { min: step.steps, max: step.steps }
      } else {
        acc[step.assetId].min = Math.min(acc[step.assetId].min, step.steps)
        acc[step.assetId].max = Math.max(acc[step.assetId].max, step.steps)
      }
      return acc
    }, {} as { [key: string]: { min: number; max: number } })

    const averageSteps =
      Object.values(groupedByAsset).reduce((sum, asset) => sum + (asset.max - asset.min), 0) /
      (Object.keys(groupedByAsset).length || 1)

    return {
      ...label,
      steps: averageSteps,
    }
  })
}

export default mergeStepsWithLabels
