import { useDispatch } from 'react-redux'
import Auth from 'Libs/Auth'
import { changeTenant } from 'Apis/auth0'
import { setTrue } from 'Store/actions/toggle-action'
import { logError } from 'Utils/analytics'

const auth = new Auth()

export const useLoadData = () => {
  const dispatch = useDispatch()
  const loadData = async (tenant) => {
    //(process.env.NODE_ENV !== 'development') &&
    dispatch(setTrue('loadingScreen'))
    if (tenant) {
      try {
        await changeTenant(tenant)
        await auth.renewTokenPromise()
      } catch (err) {
        logError(err)
        return
      }
    }
    window.location.reload()
  }
  return loadData
}
