import moment from 'moment'
import React, { ForwardedRef, forwardRef, Fragment } from 'react'
import { SegmentGroup, Segment, Label, Image, Header } from 'semantic-ui-react'
import styled from 'styled-components'
import { chunk } from 'lodash'
import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import AmpImage from 'Components/images/AmpImage'

const BoldSpan = styled.span`
  font-weight: bold;
`

const StyledHeader = styled(Header)`
  margin: 0px !important;
`

interface PrintModelViewProps {
  email: string
  name: string
  code: string
  createdByLabel?: string
  createdBy?: string
  createdAtLabel?: string
  createdAt: string
  fieldsBySection: Record<
    string,
    {
      label: string // section label
      segments: Array<any>
      groupedSegments: Array<any>
    }
  >
  printMode?: boolean
}

const ModuleDetailsContent = forwardRef(
  (
    {
      email,
      name,
      code,
      createdBy = '',
      createdAt,
      createdByLabel = 'Criado por: ',
      createdAtLabel = 'Criado às: ',
      fieldsBySection,
      printMode,
    }: PrintModelViewProps,
    ref: ForwardedRef<HTMLDivElement | null>
  ) => {
    const tenant = useSelector((state: RootStore) => state.login.empresa)

    const formalLabel = (label: string) => {
      const labelSufix = label.includes('?') ? '' : ':'
      return `${label}${labelSufix} `
    }

    return (
      <div ref={ref} style={{ margin: printMode ? 15 : 0 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '99%' }}>
          <span style={{ fontSize: '12px' }}>{`Emissão: ${moment().format('DD/MM/YYYY HH:mm')}`}</span>
          <span style={{ fontSize: '12px' }}>{`Por: ${email}`}</span>
        </div>

        <SegmentGroup>
          <Segment
            size="large"
            textAlign="center"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Image
              src={`https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/${tenant}/miniLogo.png`}
              size="tiny"
              onError={(e: any) => {
                e.currentTarget.src = `https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/navBarLogo/${tenant}.png`
              }}
            />
            <StyledHeader size="tiny">{`${name}`}</StyledHeader>
            <Label circular color={'yellow'} size="large">
              {code}
            </Label>
          </Segment>

          <SegmentGroup horizontal>
            <Segment style={{ flex: 1 }}>
              <BoldSpan>{createdByLabel}</BoldSpan>
              {createdBy}
            </Segment>
            <Segment style={{ flex: 1 }}>
              <BoldSpan>{createdAtLabel}</BoldSpan>
              {moment(createdAt).format('DD/MM/YYYY HH:mm')}
            </Segment>
          </SegmentGroup>

          {Object.entries(fieldsBySection).map(([sectionId, sectionFields]) => {
            const groupedSegmentPairs = chunk(sectionFields.groupedSegments, 2)
            return (
              <Fragment key={sectionId}>
                {sectionId !== 'noSection' && (
                  <Segment secondary textAlign="center">
                    <BoldSpan>{sectionFields.label}</BoldSpan>
                  </Segment>
                )}
                <>
                  {groupedSegmentPairs.map((segmentPair, pairIndex) => (
                    <SegmentGroup key={pairIndex} horizontal style={{ flex: 1 }}>
                      {segmentPair.map(({ id, label, value }) => (
                        <Segment key={id} style={{ flex: 1 }}>
                          <BoldSpan>{formalLabel(label)}</BoldSpan>
                          {value === true ? 'Sim' : value === false ? 'Não' : value.toString()}
                        </Segment>
                      ))}
                    </SegmentGroup>
                  ))}
                  {sectionFields.segments.map((field: any) => {
                    if (field.type === 'images') {
                      return (
                        <Fragment key={field.id}>
                          <Segment
                            textAlign="left"
                            style={{
                              borderBottom: '1px solid rgba(34,36,38,.15)',
                              borderTop: '1px solid rgba(34,36,38,.15)',
                            }}
                          >
                            <BoldSpan>{formalLabel(field.label)}</BoldSpan>
                          </Segment>
                          {field.value ? (
                            <Image.Group
                              size="small"
                              style={{ display: 'flex', justifyContent: 'space-around', marginTop: '8px' }}
                            >
                              {field.value.map((img: any, index: number) => {
                                return <AmpImage path={img.path} key={img.path} />
                              })}
                            </Image.Group>
                          ) : (
                            <Segment placeholder></Segment>
                          )}
                        </Fragment>
                      )
                    }
                    return (
                      <Fragment key={field.id}>
                        <Segment key={field.id} style={{ flex: 1 }}>
                          <BoldSpan>{formalLabel(field.label)}</BoldSpan>
                        </Segment>
                        <Segment>{field.value || 'Nenhum valor cadastrado'} </Segment>
                      </Fragment>
                    )
                  })}
                </>
              </Fragment>
            )
          })}
        </SegmentGroup>
      </div>
    )
  }
)

export default ModuleDetailsContent
