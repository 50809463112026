import { URL_BASE } from './rest'
import axios from 'axios'

export const sendTokenEmail = async (assetId: string) => {
  const jwt = localStorage.getItem('id_token')
  const headers = {
    headers: { Authorization: 'Bearer ' + jwt },
  }
  const result = await axios.post(URL_BASE + '/app-user/token', { assetId }, headers)
  return result.data
}
