import initialStore from 'Store/initialStore'
import set from 'immutable-set'
import produce from 'immer'
import moment from 'moment'
import { omit } from 'lodash'
import { notifyInfo } from 'Utils/components/SystemToasts'
import _ from 'lodash'
import calcDataLabelsCycles from 'Utils/calcDataLabelsCycles'
import seededRandom from 'Utils/seedRandom'
import mergeStepsWithLabels from 'Utils/mergeStepsWithLabels'
import aggregateStepsByPhaseIndex from 'Utils/aggregateStepsByPhaseIndex'
import { setStoreProps } from './reducerUtils'

export default function dataLabelingsReducer(state = initialStore.dataLabelings, { type, payload, prop, value, id }) {
  switch (type) {
    case 'SET_DATA_LABELINGS_PROP':
      return set(state, prop, value)

    case 'SET_DATA_LABELINGS_PROPS':
      return setStoreProps(state, payload)

    case 'SET_DATA_LABELINGS_WIP_ASSETS':
      return produce(state, (newState) => {
        if (!state.wip.assets) newState.wip.assets = []
        newState.wip.assets[payload.index] = payload.id
      })

    case 'FETCH_DATA_LABELINGS_START':
      return { ...state, loading: true }

    case 'FETCH_DATA_LABELINGS_SUCCESS':
      return produce(state, (newState) => {
        newState.byId = payload.reduce((acc, current) => {
          acc[current.id] = current
          return acc
        }, {})
        newState.allIds = Object.keys(newState.byId)
        let selected = true
        newState.activitiesOptions = newState.allIds.reduce((acc, id) => {
          const dataLabeling = newState.byId[id]
          if (!dataLabeling.labelings || dataLabeling.labelings.length === 0) return acc
          const accFindKey = acc.find((e) => e.key === dataLabeling.activityId)?.key

          if (!accFindKey) {
            const item = {
              key: dataLabeling.activityId,
              text: dataLabeling.activity?.code + ' - ' + dataLabeling.activity?.name,
              selected,
            }
            acc.push(item)
            if (selected) {
              newState.selectedActivity = item
              newState.lastActivityDataLabeling = newState.allIds.reduce((acc, id) => {
                const current = newState.byId[id]
                if (current.activityId === item.key) {
                  if (acc.updatedAt > current.updatedAt) return acc
                  return current
                }
                return acc
              }, {})
            }
            selected = false
          }
          return acc
        }, [])

        newState.lastDataLabelingByActivity = newState.allIds.reduce((acc, id) => {
          const current = newState.byId[id]
          if (acc[current.activityId]?.updatedAt > current.updatedAt) return acc
          return { ...acc, [current.activityId]: current }
        }, {})

        newState.loading = false
      })

    case 'FETCH_MOTION_DATA_SUCCESS':
      return produce(state, (newState) => {
        const payloadReduce = (payload || []).reduce(
          (acc, curr) => {
            const { assetName, aYMean, aXMean, aZMean, aXSpread, aYSpread, aZSpread, time } = curr
            const timestamp = Date.parse(time)
            acc.min = acc.min ? Math.min(acc.min, timestamp) : timestamp
            acc.max = acc.max ? Math.max(acc.max, timestamp) : timestamp
            if (!acc.data[assetName]) {
              acc.data[assetName] = []
            }
            acc.data[assetName].push({
              aYMean,
              aXMean,
              aZMean,
              aXSpread,
              aYSpread,
              aZSpread,
              timestamp,
            })
            return acc
          },
          { data: {} }
        )
        newState.motionData = payloadReduce.data
        newState.motionDataDomainMin = payloadReduce.min
        newState.motionDataDomainMax = payloadReduce.max
        const labelings = newState.wip?.labelings
        if (labelings) {
          newState.currentLabelingsIndex = labelings.length
        } else {
          newState.wip.labelings = []
          newState.currentLabelingsIndex = 0
        }
      })

    /* @deprecated Use SET_START_PHASE_LABEL or SET_END_PHASE_LABEL instead */
    case 'SET_NEW_PHASE_LABEL':
      return produce(state, (newState) => {
        const currentLabeling = state.wip.labelings?.[state.currentLabelingsIndex]
        if (currentLabeling?.startTime && !currentLabeling.endTime) {
          const formatPayload = moment(payload).toISOString()
          if (currentLabeling?.startTime > formatPayload) {
            notifyInfo({ msg: 'endTimeInvalid' })
          } else {
            newState.wip.labelings[state.currentLabelingsIndex] = {
              ...currentLabeling,
              endTime: moment(payload).toISOString(),
            }
            newState.currentLabelingsIndex += 1
          }
        } else {
          const lastLabeling = state.wip.labelings?.[state.currentLabelingsIndex - 1]
          let currentPhaseIndex
          if (lastLabeling) {
            const phasesLen = state.wip.phases?.length
            currentPhaseIndex = (lastLabeling?.phaseIndex + 1) % (phasesLen || 1)
          } else currentPhaseIndex = 0
          newState.wip.labelings.push({
            phaseIndex: currentPhaseIndex,
            startTime: moment(payload).toISOString(),
          })
        }
      })

    case 'SET_START_PHASE_LABEL':
      return produce(state, (newState) => {
        const lastLabeling = state.wip.labelings?.[state.currentLabelingsIndex - 1]
        const phasesLen = state.wip.phases?.length
        const formatPayload = moment(payload).toISOString()
        const currentPhaseIndex = lastLabeling ? (lastLabeling?.phaseIndex + 1) % (phasesLen || 1) : 0
        newState.wip.labelings[state.currentLabelingsIndex] = {
          phaseIndex: currentPhaseIndex,
          startTime: formatPayload,
          vehicle: currentPhaseIndex < lastLabeling?.phaseIndex ? null : lastLabeling?.vehicle,
        }
      })

    case 'SET_END_PHASE_LABEL':
      return produce(state, (newState) => {
        const { wip, currentLabelingsIndex } = newState
        const currentLabeling = wip.labelings?.[currentLabelingsIndex]

        if (currentLabeling?.startTime && !currentLabeling.endTime) {
          const formatPayload = moment(payload).toISOString()

          if (currentLabeling.startTime > formatPayload) {
            notifyInfo({ msg: 'endTimeInvalid' })
          } else {
            newState.wip.labelings[currentLabelingsIndex] = {
              ...currentLabeling,
              endTime: formatPayload,
            }
            newState.currentLabelingsIndex += 1
          }
        }
      })

    case 'REMOVE_DATA_LABELING':
      return produce(state, (newState) => {
        const { index } = payload
        newState.wip.labelings.splice(index, 1)

        const currentLabeling = state.wip.labelings?.[state.currentLabelingsIndex]
        if (state.currentLabelingsIndex > 0 && !currentLabeling)
          newState.currentLabelingsIndex = state.currentLabelingsIndex - 1
      })

    case 'MUTATE_DATA_LABELING_PHASE':
      return produce(state, (newState) => {
        const { index, field, value } = payload
        const phases = newState.wip?.phases
        if (phases) {
          const phase = phases[index] ?? {}
          newState.wip.phases[index] = { ...phase, index, [field]: value }
        } else {
          newState.wip = { ...newState.wip, phases: [{ [field]: value, index }] }
        }
      })

    case 'REMOVE_DATA_LABELING_PHASE':
      return produce(state, (newState) => {
        newState.wip.phases.splice(payload, 1)
      })

    case 'SET_DATA_LABELING_WIP':
      return produce(state, (newState) => {
        newState.wip = newState.byId[id] ?? {}
      })

    case 'SET_IDS':
      return produce(state, (newState) => {
        newState.selectedDataLabelingsIds = payload
      })

    case 'DELETE_DATA_LABELINGS_START':
      return { ...state, loading: true }

    case 'DELETE_DATA_LABELINGS_SUCCESS':
      if (!id) return state
      return produce(state, (newState) => {
        newState.allIds = newState.allIds.filter((each) => each !== id)
        newState.byId = omit(newState.byId, [id])
        newState.loading = false
      })

    case 'DELETE_DATA_LABELINGS_FAILURE':
      return { ...state, loading: false }

    case 'SAVE_DATA_LABELINGS_START':
      return produce(state, (newState) => {
        newState.form.loading = true
      })

    case 'SAVE_DATA_LABELING_FAILURE':
      return produce(state, (newState) => {
        newState.form.loading = false
      })

    case 'SAVE_DATA_LABELINGS_SUCCESS':
      return produce(state, (newState) => {
        if (!payload?.id) return state
        if (!newState.allIds.includes(payload.id)) newState.allIds.push(payload.id)
        newState.byId[payload.id] = payload
        newState.form.loading = false
        newState.form.open = false
      })

    case 'CLEAR_DATA_LABELING_FORM':
      return produce(state, (newState) => {
        newState.form.steps.items = newState.form.steps.items.map((item, index) => {
          return { ...item, disabled: !(index === 0), completed: false, showFeedback: false }
        })
        newState.form.steps.current = 0
      })

    //case 'CALC_PREDICT_LABELINGS_BY_DAY':

    case 'CALC_DATA_LABELINGS_METRICS':
      return produce(state, (newState) => {
        const labelsByActivityId = state.allIds.reduce((acc, id) => {
          const dataLabeling = state.byId[id]
          acc[dataLabeling.activityId] = [...(acc[dataLabeling.activityId] || []), ...(dataLabeling.labelings || [])]
          return acc
        }, {})

        const labelsByPDT = Object.entries(labelsByActivityId).reduce((acc, [PDT, labels]) => {
          const sortedLabels = [...labels].sort((a, b) => a.startTime.localeCompare(b.startTime))
          acc[PDT] = calcDataLabelsCycles(sortedLabels)
          return acc
        }, {})

        const aggByGroupedCycle = Object.entries(labelsByPDT).reduce((aggObject, [PDT, labels]) => {
          const groupByCycle = _.groupBy(labels, 'cycle')
          const groupedAgg = _.map(groupByCycle, (items, cycle) => {
            const arbitraryVehicle = _.minBy(items, 'vehicle')?.vehicle
            const minStartTime = _.minBy(items, 'startTime').startTime
            const maxEndTime = _.maxBy(items, 'endTime').endTime

            const phase_0 = items.find((e) => e.phaseIndex === 0)
            const phase_1 = items.find((e) => e.phaseIndex === 1)
            const phase_2 = items.find((e) => e.phaseIndex === 2)
            const phase_3 = items.find((e) => e.phaseIndex === 3)
            const phase_4 = items.find((e) => e.phaseIndex === 4)

            let response = {
              cycle,
              minStartTime,
              maxEndTime,
              //duration: Math.round((new Date(maxEndTime) - new Date(minStartTime)) / 1000),
              vehicle: arbitraryVehicle,
            }

            if (phase_0) {
              response['phase#1'] = Math.round((new Date(phase_0.endTime) - new Date(phase_0.startTime)) / 1000)
            }
            if (phase_1) {
              response['phase#2'] = Math.round((new Date(phase_1.endTime) - new Date(phase_1.startTime)) / 1000)
            }
            if (phase_2) {
              response['phase#3'] = Math.round((new Date(phase_2.endTime) - new Date(phase_2.startTime)) / 1000)
            }
            if (phase_3) {
              response['phase#4'] = Math.round((new Date(phase_3.endTime) - new Date(phase_3.startTime)) / 1000)
            }
            if (phase_4) {
              response['phase#5'] = Math.round((new Date(phase_4.endTime) - new Date(phase_4.startTime)) / 1000)
            }

            response.duration = 0
            for (let i = 1; i <= 5; i++) {
              if (response[`phase#${i}`]) {
                response.duration += response[`phase#${i}`]
              }
            }
            response.dessaturation = Math.max(
              0,
              state.lastDataLabelingByActivity[PDT]?.targetCycleTime - response.duration || 0
            )

            return response
          })

          // Set the groupedAgg to its corresponding PDT key in the aggregate object.
          aggObject[PDT] = groupedAgg.filter((e) => {
            return e.vehicle === state.selectedVehicle || state.selectedVehicle === 'all'
          })

          return aggObject // Return the aggregate object for the next iteration.
        }, {}) // Initialize the aggregate object as empty object.

        newState.aggByGroupedCycle = aggByGroupedCycle
      })

    case 'CALC_DATA_LABELINGS_CARDS':
      return produce(state, (newState) => {
        const aggData = state.aggByGroupedCycle[state.selectedActivity.key]
        const minData = _.minBy(aggData, 'duration') || {}
        const maxData = _.maxBy(aggData, 'duration') || {}
        const meanDuration = Math.round(_.meanBy(aggData, 'duration'))
        const meanDessaturation = Math.round(_.meanBy(aggData, 'dessaturation'))
        const TCT = state.lastDataLabelingByActivity?.[state.selectedActivity.key]?.targetCycleTime || 65

        const ef = _.round(((TCT - meanDuration) / TCT) * 100, 1)

        newState.cards = {
          'card#0': {
            mainInfo: (ef >= 0 ? _.round(ef / 100 + 100, 1) : ef) + '%',
            body: [
              {
                source: 'saturação',
                metric: 'min',
                name: 'Tempo Mínimo',
                value: minData.duration,
                time: minData.minStartTime,
              },
              { source: 'saturação', metric: 'média', name: 'Tempo Médio', value: meanDuration },
              {
                source: 'saturação',
                metric: 'max',
                name: 'Tempo Máximo',
                value: maxData.duration,
                time: maxData.minStartTime,
              },
              {
                source: 'dessaturação',
                metric: 'média',
                name: 'Tempo Médio',
                value: meanDessaturation,
              },
              {
                source: 'dessaturação',
                metric: 'max',
                name: 'Tempo Máximo',
                value: TCT - minData.duration,
                time: minData.minStartTime,
              },
            ],
          },
        }

        newState.driver = Object.entries(state.aggByGroupedCycle).reduce((acc, [PDT, cycles]) => {
          if (cycles.length > 0) {
            const meanDuration = Math.round(_.meanBy(cycles, 'duration'))
            const meanDessaturation = Math.round(_.meanBy(cycles, 'dessaturation'))
            const dataLabeling = state.lastDataLabelingByActivity[PDT]
            const TCT = dataLabeling?.targetCycleTime || 65
            const codeNLabel = `${dataLabeling?.activity?.code} - ${dataLabeling?.activity?.name}`
            return [
              ...acc,
              {
                PDT: codeNLabel,
                TCT,
                meanDuration,
                meanDessaturation,
                steps: seededRandom(codeNLabel, 8, 16),
              },
            ]
          }
          return acc
        }, [])

        const createPhaseItem = (phaseNumber, aggData) => {
          const phaseKey = `phase#${phaseNumber}`,
            colors = ['blue', 'green', 'red', 'orange', 'yellow'],
            color = colors[phaseNumber - 1] || 'grey'

          const phaseMeanDuration = _.round(
            _.meanBy(aggData, function (o) {
              return o?.[phaseKey] ?? 0
            })
          )

          const currentActivityPhase = state.lastActivityDataLabeling?.phases?.[phaseNumber - 1]

          return {
            color,
            header: `Fase ${phaseNumber}`,
            meta: currentActivityPhase?.description,
            description: [
              `Tempo Mínimo(s): ${_.minBy(aggData, phaseKey)?.[phaseKey] ?? '-'}`,
              `Tempo Médio(s): ${phaseMeanDuration ?? '-'}`,
              `Tempo Máximo(s): ${_.maxBy(aggData, phaseKey)?.[phaseKey] ?? '-'}`,
            ],
            duration: `${_.round((phaseMeanDuration * 100) / meanDuration, 1) || 0}%`,
            labelValue: '',
          }
        }

        newState.phaseCards = Array.from({ length: 5 }, (_, i) => createPhaseItem(i + 1, aggData))
      })

    case 'SET_SELECTED_ACTIVITY':
      return produce(state, (newState) => {
        newState.lastActivityDataLabeling = state.allIds.reduce((acc, id) => {
          const current = state.byId[id]
          if (current.activityId === payload) {
            if (acc.updatedAt > current.updatedAt) return acc
            return current
          }
          return acc
        }, {})

        newState.activitiesOptions = state.activitiesOptions.map((e) => {
          return { ...e, selected: e.key === payload }
        })
        newState.selectedActivity = newState.activitiesOptions.find((e) => e.selected)
      })

    case 'SET_SELECTED_VEHICLE':
      return produce(state, (newState) => {
        newState.selectedVehicle = payload
      })

    case 'CALC_WIP_CYCLES':
      if (state.wip?.labelings)
        return produce(state, (newState) => {
          newState.wip.labelings = calcDataLabelsCycles(state.wip.labelings)
        })
      return state

    case 'INIT_LABELS_TABLE_SET_SORT_BY':
      return produce(state, (newState) => {
        newState.labelsTableSetSortBy = payload
      })

    case 'FETCH_STEPS_SUCCESS':
      const merge = mergeStepsWithLabels(payload.labels, payload.result)
      const grouped = aggregateStepsByPhaseIndex(merge)
      return {
        ...state,
        stepsByPhases: grouped,
      }

    case 'FETCH_CRONOLOGY_DATA_START':
      return {
        ...state,
        cronology: { ...initialStore.dataLabelings.cronology, loading: true },
      }

    case 'FETCH_CRONOLOGY_DATA_SUCCESS':
      return { ...state, cronology: { ...payload, loading: false } }

    default:
      return state
  }
}
