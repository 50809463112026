import React from 'react'
import { Feed } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { broadcastMessage } from 'Store/actions/notification-action'
import m from 'moment'

export default function DropdownNotificationItemExtra({
  closedAt,
  type,
  fenceId,
  contrastColor,
  notificationId,
  loadingState,
}) {
  const dispatch = useDispatch()

  const assetIds = useObjSelector((state) => state.assets.allActivePeopleIds || [])
  const fenceLabel = useSelector((state) => state.modules.fences.byId[fenceId]?.label)
  const fenceName = useSelector((state) => state.modules.fences.byId[fenceId]?.name)
  const login = useSelector((state) => state.login.email)

  return (
    <Feed.Extra>
      {!closedAt ? (
        <div style={{ display: 'flex' }}>
          <div style={{ marginLeft: '3px' }}>
            {!loadingState ? (
              <span
                style={{ color: contrastColor, fontStyle: 'italic', cursor: 'pointer' }}
                onClick={() => {
                  dispatch(
                    broadcastMessage(
                      {
                        type,
                        context: 'Stop ' + (fenceId ? 'Local' : 'General'),
                        assetIds,
                        fence: { label: fenceLabel, name: fenceName },
                      },
                      {
                        id: notificationId,
                        closedAt: new Date().toISOString(),
                        closedBy: login,
                      },
                      'update'
                    )
                  )
                }}
              >
                {'Concluir'}
              </span>
            ) : (
              'Carregando...'
            )}
          </div>
        </div>
      ) : (
        <span>Emergência Concluída em {m(closedAt).format('DD/MM à HH:mm').replace('à', 'às')}</span>
      )}
    </Feed.Extra>
  )
}
