import * as z from 'zod'

// Função que retorna um schema com validações que requerem acesso à lista de valores existentes
const createStepOneSchema = (uniqueCodes: string[], uniqueNames: string[], currentId: boolean) => {
  return z.object({
    type: z.string().min(1, 'form.error.min'),
    name: z
      .string()
      .min(1, 'form.error.min')
      .max(60, 'form.error.max')
      .refine((name) => currentId || !uniqueNames.includes(name), {
        message: 'Esse nome já está sendo usado em outra atribuição',
      }),
    code: z
      .string()
      .min(1, 'form.error.min')
      .max(8, 'form.error.max')
      .refine((code) => currentId || !uniqueCodes.includes(code), {
        message: 'Esse código já está sendo usado em outra atribuição',
      }),
    color: z.string().min(1, 'form.error.min'),
    icon: z.string().min(1, 'form.error.min'),
    description: z.string().optional().nullable(),
  })
}

export default createStepOneSchema
