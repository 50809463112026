import React from 'react'
import { Segment, Icon, Header, Grid, Modal, Button, Container, Confirm, SemanticSIZES } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import Loading from 'Utils/components/Loading'
import { Storage } from 'aws-amplify'
import useImageUrl from 'Utils/hooks/useImageUrl'
import { notifyError } from 'Utils/components/SystemToasts'
import ModalCloseIcon from 'Utils/reactTable/components/ModalCloseIcon'
import ImageWithLoader from './images/ImageWithLoader'

const desktopStyles = {
  modal: { width: '55vw', height: '79vh', paddingBottom: '8vh' },
  image: { marginBottom: 128 },
}

const mobileStyles = {
  modal: { width: '95vw', height: '95vh' },
  image: { marginBottom: 128 },
}

type ImageUploadPropsBase = {
  onUpload?: (key: string, file?: File | Blob) => Promise<void>
  onDelete?: () => Promise<void>
  onCloseModal?: () => void
  trigger?: React.ReactNode
  triggerSize?: SemanticSIZES
  validateObjectExistence?: boolean
  // A propriedade preview é usada para mostrar a imagem localmente sem a necessidade de fazer o upload.
  // Esse comportamento é usado no processo de criação onde o módulo ainda não foi salvo (sem id).
  preview?: boolean
}

type ImageUploadProps =
  | (ImageUploadPropsBase & { imagePath: string; uploadPath?: string })
  | (ImageUploadPropsBase & { uploadPath: string; imagePath?: string })

/**
 * A React component that handles image uploading and deletion.
 * Utilizes AWS Amplify Storage for handling image files.
 *
 * @param {ImageUploadProps} props - The props for the component.
 * @param {function} [props.onUpload] - Optional callback after upload.
 * @param {function} [props.onDelete] - Optional callback after deletion.
 * @param {function} [props.onCloseModal] - Optional callback after modal close.
 * @param {string} props.imagePath - The path template for the image. This path can indicate where the image is currently located or where it will be stored.
 * @param {React.ReactNode} [props.trigger] - Modal Trigger
 * @param {string} [props.triggerSize] - Modal Trigger size
 * @param {boolean} [props.preview] - Preview image locally without uploading
 * @param {React.ReactNode} [props.validateObjectExistence] - Validate if object exists
 *
 * @example
 * ```tsx
 * const UserProfile: React.FC = () => {
 *   const handleUpload = async (key: string) => {
 *     // Perform additional actions, such as updating user profile
 *   }
 *
 *   const handleDelete = async () => {
 *     // Perform additional actions, such as removing image reference from user profile
 *   }
 *
 *   return (
 *     <div>
 *       <h1>User Profile</h1>
 *       <ImageUpload
 *         imagePath="avatar/${id}.png"
 *         onUpload={handleUpload}
 *         onDelete={handleDelete}
 *       />
 *     </div>
 *   )
 * }
 *
 * export default UserProfile
 * ```
 *
 * @example
 * ```tsx
 * <ImageUpload
 *   imagePath="logos/${id}.png"
 *   onUpload={async (key) => {
 *     // Upload logic here
 *   }}
 * />
 * ```
 *
 * @returns {JSX.Element} The rendered ImageUpload component.
 */
const ImageUpload: React.FC<ImageUploadProps> = ({
  onUpload,
  onDelete,
  onCloseModal,
  imagePath,
  uploadPath,
  trigger,
  triggerSize,
  validateObjectExistence,
  preview,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)

  const fileRef = React.useRef<HTMLInputElement>(null)

  // Não carrega imagem caso o triggerIcon esteja presente e modal fechado
  const hookImagePath = open || !trigger ? imagePath : undefined

  const { signedURL, loadingImage, setImageUrl } = useImageUrl(hookImagePath, {
    validateObjectExistence,
    preview,
  })

  const handleSelect = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const file = target?.files?.[0]
    if (!file) return
    if (!/^image\//.test(file.type)) {
      alert('Imagem inválida')
      return
    }
    setLoading(true)

    try {
      const url = URL.createObjectURL(file)
      setImageUrl(url)
      if (preview) {
        if (onUpload) await onUpload(url, file)
      } else {
        const path = imagePath || uploadPath
        const pathFormat = path?.replace('public/', '')
        const result = (await Storage.put(pathFormat as string, file, {
          contentType: file.type,
        })) as { key: string }
        if (result?.key && onUpload) await onUpload(path as string)
      }
    } catch (error) {
      console.error('Error uploading file: ', error)
      notifyError()
    } finally {
      setLoading(false)
    }
  }

  const handleEdit = () => {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  const handleDeleteAction = async () => {
    if (!imagePath) return
    try {
      setLoading(true)
      await Storage.remove(imagePath?.replace('public/', ''))
      if (onDelete) await onDelete()
      setImageUrl(null)
    } catch (error) {
      console.error('Error deleting file: ', error)
      notifyError()
    } finally {
      setLoading(false)
    }
  }

  let modalStyle
  // check if mobile mode
  if (window.innerWidth < 710) {
    modalStyle = mobileStyles.modal
  } else {
    modalStyle = desktopStyles.modal
  }

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false)
        onCloseModal && onCloseModal()
      }}
      size="large"
      style={modalStyle}
      trigger={trigger || <ImageWithLoader avatar src={signedURL} size={triggerSize} loadingUrl={loadingImage} />}
      closeIcon={
        <ModalCloseIcon
          action={() => {
            setOpen(false)
            onCloseModal && onCloseModal()
          }}
        />
      }
    >
      <Segment style={{ border: 'none', height: '10%' }}>
        <Grid columns={16}>
          {/* these widths need to be refactored */}
          <Grid.Column style={{ width: '50vw' }}>
            <Header as="h2">Gerenciar Imagem</Header>
          </Grid.Column>

          {/* if this is greater than 1vw, then it goes "under" the Header */}
        </Grid>
      </Segment>

      <Modal.Content image style={{ height: '85%' }}>
        {loading ? (
          <Container style={{ marginTop: '20%' }}>
            <Loading />
          </Container>
        ) : (
          <ImageWithLoader
            avatar
            src={signedURL}
            imageStyle={{
              width: '80%',
              height: '80%',
              objectFit: 'contain',
            }}
            centered
            loadingUrl={loadingImage}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          style={{ float: 'left' }}
          icon
          negative
          onClick={() => {
            setOpenConfirm(true)
          }}
          labelPosition="left"
          disabled={loadingImage || !imagePath}
        >
          <Icon title={t('delete_image')} name="trash" size="large" />
          Excluir
        </Button>
        <input type="file" onChange={handleSelect} accept="image/*" style={{ display: 'none' }} ref={fileRef} />
        <Button primary icon onClick={handleEdit} labelPosition="left">
          <Icon
            style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}
            title={t('upload_image')}
            name="photo"
            size="large"
          />
          Alterar
        </Button>
      </Modal.Actions>
      <Confirm
        header="Confirmar Exclusão"
        content="Tem certeza que deseja excluir a imagem? Essa ação não pode ser desfeita."
        cancelButton="Cancelar"
        confirmButton={
          <Button
            icon
            negative
            onClick={() => {
              setOpenConfirm(false)
              setOpen(false)
            }}
            labelPosition="left"
          >
            <Icon title={t('delete_image')} name="trash" size="large" />
            Excluir
          </Button>
        }
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        onConfirm={handleDeleteAction}
      />
    </Modal>
  )
}

export default ImageUpload
