import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { Form, Header } from 'semantic-ui-react'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
//import schema from 'graphql/schema.json'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { MessageItem, RootStore } from 'Store/initialStore'
import { GenericFormProps, Validation } from './types/GenericFormProps'
import { createMessage, updateMessage } from 'Store/actions/messages-action'
import styled from 'styled-components'

type Validations<T extends {}> = Partial<Record<keyof T, Validation>>
type ErrorRecord<T> = Partial<Record<keyof T, string>>

const options = [
  { key: 'p', text: 'Plataforma', value: 'web' },
  { key: 'a', text: 'App/Smartbadge', value: 'app' },
  { key: 'e', text: 'E-mail', value: 'email' },
]

const validations: Validations<MessageItem> = {
  id: {
    required: {
      value: true,
      message: 'O id é obrigatório',
    },
    pattern: {
      value: '^[a-zA-Z0-9-]*$',
      message: 'Este id possui um caracter inválido',
    },
  },
  extra: {
    pattern: {
      value: /^(\d+)|{{[\w.í]+}},?$/,
      message: 'Código inválido',
    },
  },
  label: {
    required: {
      value: true,
      message: 'Você precisa dar um nome para a mensagem',
    },
  },
  title: {
    required: {
      value: true,
      message: 'A mensagem precisa ter um título',
    },
    custom: {
      isValid: (value) => value.length < 50,
      message: 'Título só pode conter até 50 caracteres',
    },
  },
  body: {
    required: {
      value: true,
      message: 'Esse campo é obrigatório',
    },
  },
}

const MessagesForm: React.FC<GenericFormProps> = ({ handleCancel, addOpen, confirmAction, editId, setAddOpen }) => {
  const { byId, allIds, loading, success } = useSelector((state: RootStore) => state.messages, shallowEqual)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [fields, setFields] = useState<MessageItem>({
    id: '',
    label: '',
    title: '',
    type: 'web',
    body: '',
    extra: '',
  })
  const [errors, setErrors] = useState<ErrorRecord<MessageItem>>({})

  useEffect(() => {
    if (confirmAction === 'update') {
      setFields({
        id: byId[editId]?.id?.split('#')?.[1] ?? '',
        label: byId[editId]?.label ?? '',
        title: byId[editId]?.title ?? '',
        type: byId[editId]?.type ?? 'web',
        body: byId[editId]?.body ?? '',
        extra: byId[editId]?.extra ?? '',
      })
    }
  }, [confirmAction, byId, editId])

  useEffect(() => {
    if (success) {
      dispatch({ type: 'CLEAR_MESSAGE_STATE' })
      setAddOpen(false)
    }
  }, [success, dispatch, setAddOpen])

  const handleChange = useCallback(
    (_ev, data) => {
      setFields({ ...fields, [data.name]: data.value })
    },
    [fields]
  )

  const checkForm = (fields: MessageItem) => () => {
    let valid = true
    const newErrors: ErrorRecord<MessageItem> = {}
    if (allIds.filter((i) => i.split('#')[1] === fields.id).length && confirmAction === 'insert') {
      newErrors['id'] = 'Esse id já existe'
    }
    for (const key in validations) {
      const value = fields[key]
      const validation = validations[key]
      if (validation?.required?.value && !value) {
        valid = false
        newErrors[key] = validation?.required?.message
      }

      const pattern = validation?.pattern
      if (value && pattern?.value && !RegExp(pattern.value).test(value)) {
        valid = false
        newErrors[key] = pattern.message
      }

      const custom = validation?.custom
      if (custom?.isValid && !custom.isValid(value)) {
        valid = false
        newErrors[key] = custom.message
      }
    }

    if (!valid) {
      setErrors(newErrors)
      console.error('Form error', newErrors)
      setErrors(newErrors)
      return
    }
    if (confirmAction === 'update') {
      dispatch(updateMessage(editId, fields))
    } else {
      dispatch(createMessage(fields))
    }
  }
  return (
    <ModalTemplate
      onClose={handleCancel}
      open={addOpen}
      loading={loading}
      size="small"
      header={
        confirmAction === 'insert' ? (
          <span>{`Adicionar ${t('Mensagem')}`}</span>
        ) : (
          <span>{`Editar ${t('Mensagem')}`}</span>
        )
      }
      onSave={checkForm(fields)}
      onCancel={handleCancel}
    >
      <Form error>
        <Form.Group widths="equal">
          <Form.Input
            required={true}
            name="id"
            fluid
            placeholder="M-0001"
            label="ID"
            disabled={confirmAction === 'update'}
            maxLength={8}
            error={errors.id}
            value={fields.id}
            onChange={handleChange}
          />
          <Form.Input
            required={true}
            name="label"
            fluid
            label="Nome"
            error={errors.label}
            value={fields.label}
            onChange={handleChange}
          />
          <Form.Select
            fluid
            label="Tipo"
            name="type"
            required
            options={options}
            value={fields.type}
            onChange={handleChange}
          />
        </Form.Group>
        {fields.type === 'app' && (
          <Form.Input
            name="extra"
            fluid
            label="Codificação do Smartbadge (Hexadecimal)"
            error={errors.extra}
            value={fields.extra}
            onChange={handleChange}
          />
        )}
        <Form.Input
          required={true}
          name="title"
          fluid
          label="Título"
          error={errors.title}
          value={fields.title}
          onChange={handleChange}
        />
        <Form.TextArea
          value={fields.body || ''}
          error={errors.body}
          name="body"
          fluid
          label="Corpo"
          required={true}
          onChange={handleChange}
        />
        <p>
          Você pode incluir{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.phygitall.com.br/definitions/common-variables.html"
          >
            variáveis comuns
          </a>{' '}
          como <Variable>{'{{perímetro.nome}}'}</Variable> e<Variable>{'{{perímetro.risco}}'}</Variable> para compor a
          mensagem dinamicamente.
        </p>
        <Header as="h4">Preview</Header>
        <PreviewMessage>
          <MessageTitle>{fields.title}</MessageTitle>
          <MessageBody>
            <p>{fields.body?.split(':')[0]}</p>
            {fields.body?.split(':')?.[1] && (
              <ul>
                {fields.body
                  ?.split(':')?.[1]
                  .split(';')
                  .map((sentence, index) => (
                    <li key={index}>{sentence}</li>
                  ))}
              </ul>
            )}
          </MessageBody>
        </PreviewMessage>
      </Form>
    </ModalTemplate>
  )
}

const Variable = styled.code`
   {
    display: inline;
    padding: 2px 6px;
    border-radius: 4px;
    background-color: #eff0f2;
  }
`

const PreviewMessage = styled.div`
   {
    margin-top: 20px;
    padding: 20px;
    border-radius: 4px;
    background-color: #eff0f2;
  }
`

const MessageTitle = styled.div`
   {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
`

const MessageBody = styled.div`
   {
    font-size: 14px;
    padding-left: 8px;
  }
`

export default MessagesForm
