export const urlServer = () => {
  let url = window.location.origin.replace(':3000', '')
  url = url.replace(':5000', '')
  let ip = url
  switch (url) {
    // case 'https://beta.phygitall.com.br':
    //   ip = 'https://apibeta.phygitall.com'
    //   break
    case 'https://ygi.phygitall.com.br':
      ip = 'https://serverless.phygitall.com'
      break
    // case 'http://localhost':
    //   ip = 'http://127.0.0.1:4001'
    //   break
    case 'https://alpha.phygitall.com.br':
      ip = 'https://apialpha.phygitall.com'
      break
    default:
      ip = 'https://serverless.phygitall.com'
      break
  }
  return ip
}
