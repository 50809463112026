import L from 'leaflet'
import { iconesSvg } from 'Resources/Icons/iconesSvg/iconesSVG'
import { semanticColors, lightColors } from 'Utils/colorsFormat.js'

// default
/*const defaultMarker = new L.icon({
  iconUrl: './images/marker-icon.svg',
  iconSize: [25, 40],  iconAnchor: [12.5, 40], popupAnchor:[0,-28]});*/

const fillColor = (icon, color) => {
  icon = icon
    .replace(/<path/g, '<path fill ="' + color + '" ')
    .replace(/<circle/g, '<circle fill ="' + color + '" ')
    .replace(/<rectangule/g, '<rectangule fill ="' + color + '" ')
    .replace(/.cls-1, .cls-2 {/g, '.cls-1, .cls-2 { fill ="' + color + '" ')
  return encodeURI('data:image/svg+xml,' + icon).replace('#', '%23')
}

export function icones({ color, icon, tenant }) {
  const checkColor = (color) => ([].includes(tenant) ? lightColors(color) : semanticColors(color))
  let svgString =
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 259.761 259.761" style="enable-background:new 0 0 259.761 259.761;" xml:space="preserve" width="512px" height="512px"><metadata id="metadata1">image/svg+xml</metadata>'
  /*Pin do marcador*/
  svgString =
    svgString +
    '<g><path fill="' +
    checkColor(color) +
    '" d="M129.879,0C77.562,0,35,42.563,35,94.882c0,31.605,29.071,81.486,41.569,101.465c10.282,16.436,21.32,32.062,31.079,44   c15.871,19.414,20.328,19.414,22.232,19.414c1.827,0,6.105,0,21.921-19.42c9.784-12.014,20.831-27.637,31.106-43.988   c12.584-20.027,41.854-70.006,41.854-101.471C224.761,42.563,182.198,0,129.879,0z M129.88,171.525   c-41.389,0-75.062-33.673-75.062-75.062c0-41.389,33.673-75.062,75.062-75.062c41.389,0,75.062,33.673,75.062,75.062   C204.942,137.852,171.269,171.525,129.88,171.525z" />'
  /*Círculo interno do pin (Branco) */
  svgString = svgString + '<circle r="76" fill="white" cx="130" cy="96"/>'
  /*Simbolo do Tipo de Ativo*/
  let iconeSvg = iconesSvg[icon] ? fillColor(iconesSvg[icon], color) : undefined

  svgString = iconeSvg
    ? svgString + '<image x="70" y="30" width="120" height="120" xlink:href="' + iconeSvg + '" />'
    : svgString
  svgString = svgString + '</g></svg>' /*fim do SVG*/

  let iconUrl = encodeURI('data:image/svg+xml,' + svgString).replace('#', '%23')
  return new L.icon({ iconUrl: iconUrl, iconSize: [35, 40], iconAnchor: [18, 40], popupAnchor: [-1, -35] })
}
