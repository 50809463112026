import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { Input, Form, Dropdown, Divider, Header } from 'semantic-ui-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import schema from 'graphql/schema.json'
import { saveNotificationProfile, deleteNotificationProfiles } from 'Store/actions/notificationProfiles-action'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import PlaySoundsButton from 'Utils/components/PlaySoundsButton'

const NotificationProfilesForm = ({ handleCancel, addOpen, table, confirmAction, setAddOpen, editId }) => {
  const notificationProfilesById = useSelector((state) => state.notificationProfiles.byId)
  const tenant = useSelector((state) => state.login.empresa)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const notificationProfile = confirmAction === 'update' ? notificationProfilesById[editId] : {}
  const [fields, setFields] = useState({
    name: notificationProfile?.name,
    level: notificationProfile?.level,
    bgColor: notificationProfile?.bgColor,
    txtColor: notificationProfile?.txtColor,
    theme: notificationProfile?.theme,
    icon: notificationProfile?.icon,
    status: notificationProfile?.status || 1,
    screenTime: notificationProfile?.screenTime,
    sound: notificationProfile?.sound,
    code: notificationProfile?.code,
  })
  const [formObj, setFormObj] = useState({})
  const [errorObj, setErrorObj] = useState({})
  const [playing, setPlaying] = useState('')

  useEffect(() => {
    let schemaTypes = schema.data?.__schema?.types
    let notificationProfilesSchema = schemaTypes
      ? schemaTypes.find((e) => e.name === 'NotificationProfile')?.fields
      : null
    const appVar = ['tenant', 'id', 'status', 'createdBy', 'createdAt', 'updatedAt']

    let formObj = {}
    notificationProfilesSchema
      .filter((each) => !appVar.includes(each.name))
      .forEach((e) => {
        const type = e.type.ofType?.name || e.type.name
        const kind = e.type.ofType?.kind || e.type.kind
        const required = e.type.kind
        formObj[e.name] = {
          title: t(e.name),
          value: e.name,
          type,
          options: kind === 'ENUM' ? schemaTypes.find((eachType) => eachType.name === type).enumValues : null,
          required: required === 'NON_NULL',
        }
      })
    setFormObj(formObj)
  }, [setFormObj, t])

  function checkForm({ name, level }) {
    let error = {}
    Object.entries(formObj).forEach((entrie) => {
      if (entrie[1]?.required) {
        if (!fields?.[entrie[0]]) error[entrie[0]] = true
      }
    })
    if (!name || name.length === 0 || (notificationProfilesById[name] && name !== editId)) error.name = true
    if (level && !parseInt(level)) error.level = true
    if (isEmpty(error)) {
      setErrorObj({})
      const idName = `${tenant}#${name}`
      if (idName !== editId && confirmAction === 'update') {
        dispatch(saveNotificationProfile({ fields, type: 'create' }))
        dispatch(deleteNotificationProfiles({ ids: [editId] }))
      } else dispatch(saveNotificationProfile({ fields, type: confirmAction === 'insert' ? 'create' : 'update' }))
      setAddOpen(false)
    } else setErrorObj({ ...error })
  }

  if (!formObj?.name) return null

  return (
    <ModalTemplate
      onClose={handleCancel}
      open={addOpen}
      size="small"
      header={confirmAction === 'insert' ? `Adicionar ${t(table)}` : <span>{`Editar ${t(table)}`}</span>}
      onSave={() => checkForm(fields)}
      onCancel={handleCancel}
    >
      <Form>
        <Form.Group>
          <Form.Field width="10" required={formObj.name.required} error={errorObj.name}>
            <label>{formObj.name.title}</label>
            <Input
              placeholder="Nome único do Perfil"
              name={formObj.name.value}
              fluid
              value={fields.name}
              onChange={(e, { name, value }) => setFields({ ...fields, [name]: value })}
            />
          </Form.Field>
          <Form.Field width="6" required={formObj.level.required} error={errorObj.level}>
            <label>{formObj.level.title}</label>
            <Input
              placeholder="Insira um Número"
              name={formObj.level.value}
              fluid
              max="999999999"
              value={fields.level}
              onChange={(e, { name, value }) => setFields({ ...fields, [name]: value })}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field required={formObj.description.required} width={16}>
          <label>{formObj.description.title}</label>
          <Input
            placeholder="Descrição do Perfil de Notificação"
            name={formObj.description.value}
            fluid
            value={fields.description}
            onChange={(e, { name, value }) => setFields({ ...fields, [name]: value })}
          />
        </Form.Field>
        <br />
        <Divider horizontal>
          <Header as="h4">Cores</Header>
        </Divider>
        <br />
        <br />
        <Form.Group>
          <Form.Field required={formObj.bgColor.required} width={5} error={errorObj.bgColor}>
            <label>{formObj.bgColor.title}</label>
            <Dropdown
              defaultValue={fields.bgColor}
              placeholder="Selecione uma Cor"
              //placeholder={t(fields.bgColor || formObj.bgColor.options[0].name)}
              fluid
              search
              selection
              options={formObj.bgColor.options.map((e) => ({
                key: e.name,
                text: t(e.name),
                value: e.name,
                label: { color: e.name, empty: true, circular: true },
              }))}
              onChange={(e, { value }) => setFields({ ...fields, [formObj.bgColor.value]: value })}
            />
          </Form.Field>
          <Form.Field required={formObj.txtColor.required} width={5}>
            <label>{t(formObj.txtColor.title)}</label>
            <Dropdown
              defaultValue={fields.txtColor}
              placeholder="Selecione uma Cor"
              fluid
              search
              selection
              options={formObj.txtColor.options.map((e) => ({
                key: e.name,
                text: t(e.name),
                value: e.name,
                label: { color: e.name, empty: true, circular: true },
              }))}
              onChange={(e, { value }) => setFields({ ...fields, [formObj.txtColor.value]: value })}
            />
          </Form.Field>
          <Form.Field required={formObj.theme.required} width={6}>
            <label>{t(formObj.theme.title)}</label>
            <Dropdown
              defaultValue={fields.theme}
              placeholder="Selecione um Tema"
              fluid
              search
              selection
              options={formObj.theme.options.map((e) => ({
                key: e.name,
                text: t(e.name),
                value: e.name,
                disabled: e.name === 'dark' ? true : false,
              }))}
              onChange={(e, { value }) => setFields({ ...fields, [formObj.theme.value]: value })}
            />
          </Form.Field>
        </Form.Group>
        <br />
        <Divider horizontal>
          <Header as="h4">Ícones e Som</Header>
        </Divider>
        <br />
        <br />
        <Form.Group>
          <Form.Field required={formObj.screenTime.required} width={5} error={errorObj.screenTime}>
            <label>{t(formObj.screenTime.title)}</label>
            <Dropdown
              defaultValue={fields.screenTime}
              placeholder="Selecione o Tempo"
              fluid
              search
              selection
              options={formObj.screenTime.options.map((e) => ({
                key: e.name,
                text: t(e.name),
                value: e.name,
              }))}
              onChange={(e, { value }) => setFields({ ...fields, [formObj.screenTime.value]: value })}
            />
          </Form.Field>
          <Form.Field required={formObj.icon.required} width={6} error={errorObj.icon}>
            <label>{t(formObj.icon.title)}</label>
            <Dropdown
              defaultValue={fields.icon}
              placeholder="Selecionar Ícone"
              fluid
              search
              selection
              options={formObj.icon.options.map((e) => ({
                key: e.name,
                text: t(e.name),
                value: e.name,
                icon: {
                  name: e.name.replace('_', ' '),
                  circular: true,
                },
              }))}
              onChange={(e, { value }) => setFields({ ...fields, [formObj.icon.value]: value })}
            />
          </Form.Field>
          <Form.Field required={formObj.sound?.required} width={5}>
            <label>
              {t(formObj.sound?.title)}
              {'  '}
              {playing && <PlaySoundsButton name={playing} stop={() => setPlaying('')} />}
            </label>
            <Dropdown
              placeholder="Selecionar Som"
              defaultValue={fields.sound}
              fluid
              search
              selection
              options={formObj.sound?.options.map((e) => ({
                key: e.name,
                text: t(e.name),
                value: e.name,
              }))}
              onChange={(e, { value }) => {
                value === 'none' ? setPlaying('') : setPlaying(value)
                setFields({ ...fields, [formObj.sound?.value]: value })
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </ModalTemplate>
  )
}

export default NotificationProfilesForm
