import React, { useState, useEffect, useMemo } from 'react'
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Sector } from 'recharts'
import { useSelector } from 'react-redux'
import { colors, lightColors, semanticColors } from 'Utils/colorsFormat'

const RADIAN = Math.PI / 180

/**
 * @typedef {Object} DataItem
 * @property {string | number} id - O id do item.
 * @property {number} value - O valor do item.
 */

/**
 * @typedef {Object} GraphDonutProps
 * @property {DataItem[]} [data] - Os dados para o gráfico (opcional).
 */

/**
 * Componente GraphDonut.
 * @param {GraphDonutProps} props
 */

const GraphDonut = ({ data = [] }) => {
  const [currentData, setCurrentData] = useState([])
  const tenant = useSelector((state) => state.login.empresa)

  useEffect(() => {
    const checkColor = (color) =>
      ['Tiplam', 'PhygitallDevs'].includes(tenant) ? lightColors(color) : semanticColors(color)

    let formattedData = [...data]
      .sort((a, b) => {
        const aName = a.name?.displayValue ?? a.name
        const bName = b.name?.displayValue ?? b.name
        return b.value - a.value || aName.toLowerCase().localeCompare(bName.toLowerCase())
      })
      .map((rd, i) => ({
        name: rd.name?.displayValue ?? rd.name,
        value: parseInt(rd.value),
        fill: checkColor(colors[i % 7]),
      }))

    if (formattedData.length > 8) {
      const firstHalf = formattedData.slice(0, 7)
      const othersTotal = formattedData.slice(7).reduce((total, { value }) => parseInt(value) + total, 0)
      firstHalf.push({ name: 'Outros', value: othersTotal, fill: checkColor('gray') })
      formattedData = firstHalf
    }

    setCurrentData(formattedData)
  }, [data, tenant])

  const total = useMemo(() => {
    return currentData.reduce((total, { value }) => value + total, 0)
  }, [currentData])

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill={'#555'}
        fontWeight={600}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: '18px' }}
      >
        {`${value}`}
      </text>
    )
  }

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

    return (
      <g>
        <text x={cx} y={cy} dy={15} textAnchor="middle" fill={'#555'} style={{ fontSize: '40px' }}>
          {total?.toLocaleString('pt-br')}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    )
  }

  const activeIndex = 0

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          labelLine={false}
          label={renderCustomizedLabel}
          data={currentData}
          cx="50%"
          cy="50%"
          innerRadius={'50%'}
          outerRadius={'100%'}
          dataKey="value"
          startAngle={90}
          endAngle={450}
          minAngle={15}
        />
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.fill} />
        ))}
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default GraphDonut
