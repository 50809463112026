import React, { Fragment, useRef, useState } from 'react'
import {
  Form,
  Input,
  Select,
  Divider,
  TextArea,
  Checkbox,
  Segment,
  Container,
  Header,
  Icon,
  ImageGroup,
} from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useCanAccess } from 'Utils/rbac'
import { RootStore } from 'Store/initialStore'
import DateTimeLocalInput from 'Components/Inputs/TimeInput'
import useEntitySelector from 'Utils/hooks/useEntitySelector'
import { saveGeoModuleCFImage, setModulesProps } from 'Store/actions/modules-action'
import ImageUpload from 'Components/ImageUpload'
import { v4 } from 'uuid'
import { pullAt } from 'lodash'

const StatsBarFormCustomFields = () => {
  const dispatch = useDispatch()
  const selectRef = useRef(null)

  const profileId = useEntitySelector('modules', 'profile')
  const state = useEntitySelector('modules', 'state')
  const isClosed = state === 'closed'

  const customFields = useSelector(
    (state: RootStore) =>
      state.modules.wip.properties?.customFields ?? state.modules.current?.properties?.customFields ?? {}
  )

  const customFieldsStruct = useSelector((state: RootStore) =>
    profileId ? state.profiles.byId[profileId]?.customFields || [] : []
  )

  const moduleId = useSelector((state: RootStore) => state.modules.current?.id)
  const tenant = useSelector((state: RootStore) => state.login.empresa)

  const canAccess = useCanAccess('WRITE')

  const handleChange = (_e: unknown, { value, id }: { value: unknown; id: string }) => {
    let newCustomFields = JSON.parse(JSON.stringify(customFields))

    if (customFieldsStruct?.find((cfs) => cfs.id === id)?.type === 'boolean') {
      newCustomFields[id] = !customFields[id]
    } else {
      newCustomFields[id] = value
    }

    dispatch(setModulesProps([['wip.properties.customFields', newCustomFields]]))
  }

  const mapOptions = (values: string[]) => {
    return values.map((value) => ({
      key: value,
      text: value,
      value: value,
    }))
  }

  const readonly = !canAccess || isClosed

  const handleDropdownOpen = () => {
    const dropdown: any = selectRef.current
    if (dropdown) {
      const mainSideForm = dropdown.closest('#mainSideForm')
      if (mainSideForm) {
        const scrollTop = mainSideForm.scrollTop
        const scrollHeight = mainSideForm.scrollHeight
        const clientHeight = mainSideForm.clientHeight
        const maxScrollTop = scrollHeight - clientHeight

        // Avalia se a diferença entre a altura total do scroll e a altura atual é pequena
        if (maxScrollTop - scrollTop < 50) {
          // Se for, desloca a posição atual de um número grande para garantir um dropdown com muitas opções
          setTimeout(() => {
            mainSideForm.scrollTop += 1000
          }, 50)
        }
      }
    }
  }

  const [newImagePreviewId, setNewImagePreviewId] = useState(v4())

  return (
    <>
      {customFieldsStruct?.length > 0 && <Divider style={{ margin: '3vh 0' }} />}
      {customFieldsStruct
        // .filter((f) => f.type !== 'images')
        .map((field) => {
          return (
            <Fragment key={field.id}>
              {field.type === 'section' ? (
                <Divider horizontal style={{ marginTop: '30px' }}>
                  <Header as="h5">{field.label}</Header>
                </Divider>
              ) : field.type === 'list' || field.type === 'multiple' ? (
                <div ref={selectRef}>
                  <Form.Field
                    key={field.id}
                    disabled={readonly}
                    multiple={field.type === 'multiple'}
                    required={false}
                    readOnly={readonly}
                    id={field.id}
                    control={Select}
                    label={field.label}
                    placeholder={field.label}
                    onChange={handleChange}
                    value={customFields?.[field.id] ?? ''}
                    search
                    clearable={!readonly}
                    width="16"
                    options={mapOptions(field.options || [])}
                    onOpen={handleDropdownOpen}
                  />
                </div>
              ) : field.type === 'boolean' ? (
                <Segment
                  color={customFields?.[field.id] ? 'green' : 'red'}
                  key={field.id}
                  onClick={() => {
                    handleChange(null, { id: field.id, value: !customFields?.[field.id] })
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Container
                      style={{
                        flex: 1,
                      }}
                      textAlign="justified"
                    >
                      <p>{field.label}</p>
                    </Container>
                    <div style={{ width: '25px' }}></div> {/* Espaço entre as colunas */}
                    <div style={{ textAlign: 'center', flexShrink: 0, marginTop: '5px' }}>
                      <Checkbox
                        id={field.id}
                        onChange={(_, { id, checked }) => handleChange(_, { id: id as string, value: checked })}
                        checked={customFields?.[field.id]}
                      />
                    </div>
                  </div>
                </Segment>
              ) : field.type === 'textArea' ? (
                <Form.Field
                  key={field.id}
                  required={false}
                  readOnly={readonly}
                  id={field.id}
                  control={TextArea}
                  label={field.label}
                  placeholder={field.label}
                  onChange={handleChange}
                  value={customFields?.[field.id] ?? ''}
                  style={{ maxHeight: '20vh', minHeight: '7vh' }}
                  width="16"
                />
              ) : field.type === 'dateTime' ? (
                <Form.Field
                  key={field.id}
                  required={false}
                  readOnly={readonly}
                  id={field.id}
                  type={field.type}
                  control={DateTimeLocalInput}
                  label={field.label}
                  placeholder={field.label}
                  onChange={handleChange}
                  value={customFields?.[field.id] ?? ''}
                  width="16"
                  fluid
                />
              ) : field.type === 'images' ? (
                <Form.Field>
                  <label>{field.label}</label>
                  <Segment color={'grey'}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <ImageGroup>
                        {customFields?.[field.id]?.map((image: any, index: number) => {
                          // Imagens salvas que podem ser alteradas
                          return (
                            <ImageUpload
                              key={image.path ?? index}
                              imagePath={image.path}
                              onUpload={async (key) => {
                                let newValue = [...customFields?.[field.id]]
                                newValue[index] = { path: key }
                                dispatch(saveGeoModuleCFImage({ fieldId: field.id, newValue }))
                              }}
                              onDelete={async () => {
                                let newValue = [...customFields?.[field.id]]
                                // Remove o elemento no índice específico
                                pullAt(newValue, index)
                                if (moduleId) {
                                  dispatch(saveGeoModuleCFImage({ fieldId: field.id, newValue }))
                                } else {
                                  dispatch(setModulesProps([[`wip.properties.customFields.${field.id}`, newValue]]))
                                }
                              }}
                              preview={!moduleId}
                            />
                          )
                        })}
                      </ImageGroup>
                      {/* Ícone + para adicionar uma nova imagem */}
                      <ImageUpload
                        key={newImagePreviewId}
                        uploadPath={`public/${tenant}/geoModules/${moduleId}/customFields/${field.id}/images/${newImagePreviewId}`}
                        onUpload={async (path, file) => {
                          let newValue = [...(customFields?.[field.id] ?? [])]

                          if (moduleId) {
                            // Adiciona a nova imagem ao array de imagens [atualização]
                            newValue.push({ path })
                            dispatch(saveGeoModuleCFImage({ fieldId: field.id, newValue }))
                          } else {
                            // Adiciona a nova imagem ao array de imagens [criação]
                            newValue.push({ path, file })
                            dispatch(setModulesProps([[`wip.properties.customFields.${field.id}`, newValue]]))
                          }
                        }}
                        onCloseModal={() => setNewImagePreviewId(v4())}
                        trigger={
                          <Icon name="plus" color="black" link style={{ marginTop: '10px', cursor: 'pointer' }} />
                        }
                        validateObjectExistence
                        preview={!moduleId}
                      />
                    </div>
                  </Segment>
                </Form.Field>
              ) : (
                <Form.Field
                  key={field.id}
                  required={false}
                  readOnly={readonly}
                  id={field.id}
                  control={Input}
                  type={field.type}
                  label={field.label}
                  placeholder={field.label}
                  onChange={handleChange}
                  value={customFields?.[field.id] ?? ''}
                  width="16"
                />
              )}
              <Divider hidden style={{ margin: '1.5vh 0' }} />
            </Fragment>
          )
        })}
      {customFieldsStruct?.length > 0 && <Divider />}
    </>
  )
}
export default StatsBarFormCustomFields
