import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, Icon } from 'semantic-ui-react'
import { deleteDashboardPanel, openPanelForm } from 'Store/actions/betaDashboards-action'
import { RootStore } from 'Store/initialStore'
import styled from 'styled-components'
import PanelForm from './form/PanelForm'

const PanelHeaderContainer = styled.div`
  width: 100%;
  height: 10%;
  color: #666;
`

const PanelTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 93%;
  display: inline-block;
  padding: 5px;
  --border: 1px teal solid;
  font-weight: 700;
  font-size: 14px;
`

const PanelConfig = styled.div`
  margin-top: 5px;
  margin-right: 4px;
  float: right;
  z-index: 4;
  color: #666;
  --border: 1px purple solid;
  width: 5%;
  text-align: right;
`

const StyledDropdown = styled(Dropdown)`
  z-index: 1000;
  cursor: pointer;
`

type PanelHeaderProps = {
  panelId: string
}

const PanelHeader: React.FC<PanelHeaderProps> = ({ panelId }) => {
  const dispatch = useDispatch()

  const formType = useSelector((state: RootStore) => state.betaDashboards.form.type)
  const label = useSelector(
    (state: RootStore) =>
      state.betaDashboards.wip?.panels?.[panelId]?.label ?? state.betaDashboards.current?.panels[panelId]?.label
  )
  const panelIdForm = useSelector((state: RootStore) => state.betaDashboards.panelIdForm)

  const options = [
    {
      key: 'edit',
      text: 'Editar',
      icon: 'edit',
      onClick: () => {
        dispatch(openPanelForm(panelId))
      },
    },
    {
      key: 'delete',
      text: 'Deletar',
      icon: 'delete',
      onClick: () => {
        dispatch(deleteDashboardPanel(panelId))
      },
    },
  ]

  return (
    <>
      <PanelHeaderContainer>
        <PanelTitle title={'PanelDesc'}>{label}</PanelTitle>

        <PanelConfig style={{ cursor: 'pointer', zIndex: 2 }}>
          <StyledDropdown
            trigger={
              <Icon name="ellipsis vertical" title="Clique para gerenciar o painel" style={{ cursor: 'pointer' }} />
            }
            options={options}
            pointing="top right"
            icon={null}
          />
        </PanelConfig>
      </PanelHeaderContainer>
      {formType === 'filter' && panelId === panelIdForm && <PanelForm panelId={panelId} />}
    </>
  )
}

export default PanelHeader
