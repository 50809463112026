import styled from 'styled-components'
import { ReactComponent as CrossIcon } from 'Resources/Icons/cross.svg'

export const NotificationsContainer = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 9999;
`

export const StyledCrossIcon = styled(CrossIcon)`
  transition: 0.3s ease color;
  font-size: 1rem;
  margin-left: 0.5rem;
  verticalalign: middle;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.75);
  &:hover {
    color: white;
  }
`
