import React, { useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { fetchAssets, fetchActiveAssets, fetchAssetDetails } from 'Store/actions/assets-action'
import { fetchModules } from 'Store/actions/modules-action'
import ModalHeaderFirefighter from 'Utils/modals/ModalHeaderFirefighter'
import Loading from 'Utils/components/Loading'
import 'Store/actions/dashboards-action'
import styled from 'styled-components'
import { CardHeader } from './PeopleBoardContent'
import { PeopleBoard } from './PeopleBoard'
import { Card, Segment, Modal } from 'semantic-ui-react'

const HeaderFix = styled('div')`
  padding-bottom: 0 0 0rem -1.5rem;
`

export const PeopleBoardFullScreen = () => {
  const dispatch = useDispatch()
  const dashboardLabel = 'Quadro de Brigadistas'
  const dashboard = 'firefighter'
  const hiddenButtons = ['new', 'edit', 'groups', 'rules', 'columns', 'delete', 'import', 'fullscreen', 'calendar']

  // get the 'brigadista' id
  const firefighterTypeId = useSelector(
    (state) => state.profiles.allIds.filter((id) => state.profiles.byId[id].name === 'Brigadista')[0]
  )

  // get everybody's id's
  const allPeopleIds = useSelector((state) => state.assets.allIds, shallowEqual)

  // get all info
  const allPeople = useSelector((state) => state.assets.byId, shallowEqual)

  // get every id which is 'online'
  const presentPeopleIds = useSelector((state) => state.assets.allActivePeopleIds, shallowEqual)

  // get data from the global state
  useEffect(() => {
    dispatch(fetchAssets())
    dispatch(fetchModules())
    dispatch(fetchActiveAssets())

    const id = setInterval(() => {
      dispatch(fetchActiveAssets())
      dispatch(fetchAssetDetails(presentPeopleIds))
    }, 10000)

    return () => clearInterval(id)
  }, [dispatch, presentPeopleIds])

  const loading = useSelector((state) => state.assets.loading)

  // filter the firefighters and only get those who are online
  const firefighters = allPeopleIds
    .filter((idf) => allPeople[idf]?.info?.type === firefighterTypeId && presentPeopleIds?.find((pid) => pid === idf))
    .map((id) => {
      return {
        id,
        name: allPeople[id]?.name,
        cardId: allPeople[id]?.info?.cardId,
      }
    })

  return (
    <>
      <HeaderFix>
        <ModalHeaderFirefighter
          dashboard={dashboard}
          dashboardLabel={dashboardLabel}
          hiddenButtons={hiddenButtons}
          firefighters={firefighters}
        />
      </HeaderFix>

      <br />
      <Modal.Content style={{ padding: '0 1em' }}>
        {loading ? (
          <div style={{ marginTop: '35vh' }}>
            <Loading />
          </div>
        ) : (
          <Segment style={{ border: 'none', boxShadow: 'none', padding: '0 1em' }}>
            <Card.Group centered textAlign="center">
              {firefighters.length > 0 ? (
                firefighters.map((eachFireFighter) => (
                  <PeopleBoard
                    key={eachFireFighter.id}
                    assetId={eachFireFighter.id}
                    name={eachFireFighter.name}
                    cardId={eachFireFighter.cardId}
                  />
                ))
              ) : (
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <CardHeader>
                    <Card.Header> {'Nenhum brigadista presente.'} </Card.Header>
                  </CardHeader>
                </div>
              )}
            </Card.Group>
          </Segment>
        )}
      </Modal.Content>
    </>
  )
}
