import { URL_BASE, urlBase } from 'Apis/rest'
import arrayToQueryStringParameter from 'Utils/arrayToQueryStringParameter'
import axios from 'axios'
export const FILTER_HISTORY = 'filterHistory'

export const filterHistory = (filters) => ({
  type: FILTER_HISTORY,
  id: '',
  payload: filters,
})

export const sliderChange = (payload) => ({
  type: 'SLIDER_CHANGE',
  payload,
})

export const saveHistory = (data) => ({
  type: 'saveHistory',
  payload: data,
})

export const saveSelectedTime = (data) => ({
  type: 'saveSelectedTime',
  payload: data,
})

export const fetchHistory =
  ({ startTime, endTime, assetsIds = '', category = 'antpath' }) =>
  async (dispatch, getState) => {
    const jwt = localStorage.getItem('id_token')
    let res
    let start = startTime || getState().filters.timeStart
    let end = endTime || getState().filters.timeEnd
    try {
      dispatch({ type: 'FETCH_HISTORY_START', payload: { startTime: start, endTime: end, assetsIds, category } })
      dispatch({ type: 'SET_TRUE', payload: 'loadingScreen' })
      res = await axios
        .get(`${urlBase}/assets/measures?startTime=${start}&endTime=${end}&assetId=${assetsIds}&category=${category}`, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((resp) => resp.data.result)
      dispatch({
        type: 'FETCH_HISTORY_SUCCESS',
        payload: res,
      })
    } catch (err) {
      dispatch({ type: 'FETCH_HISTORY_FAILURE' })
      console.error(err)
    }
    dispatch({ type: 'SET_FALSE', payload: ['loadingScreen', 'showHistoryCalendar'] })
  }

export const emptyHistory = () => ({
  type: 'EMPTY_HISTORY',
})

export const changeHistType = (payload) => ({
  type: 'CHANGE_HIST_TYPE',
  payload,
})

export const fetchHistCategories = (assetId) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({ type: 'FETCH_HIST_CATEGORIES_START', payload: {}, params: { type: 'categories' }, assetId })
    res = await axios
      .get(`${urlBase}/assets/measures?type=categories&assetId=${assetId}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_HIST_CATEGORIES_SUCCESS', assetId, payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_HIST_CATEGORIES_FAILURE', payload: err })
  }
}

export const fetchHistCategory = (assetId, category, time, load) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  time = new Date(time).toISOString().replace('T', ' ')
  let res
  try {
    dispatch({ type: 'FETCH_HIST_CATEGORY_START', assetId, category, time, payload: { load } })
    if (category !== 'coords' && load) {
      res = await axios
        .get(`${urlBase}/assets/measures?time=${time}&assetId=${assetId}&category=${category}`, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((resp) => resp.data.result)
      dispatch({ type: 'FETCH_HIST_CATEGORY_SUCCESS', assetId, payload: res, category })
    }
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_HIST_CATEGORY_FAILURE', payload: err })
  }
}

export const changeHistFlag = (flag, payload) => {
  return {
    type: 'CHANGE_HIST_FLAG',
    flag,
    payload,
  }
}

export const fetchHolesHist = () => async (dispatch, getState) => {
  try {
    let start = getState().filters.timeStart
    let end = getState().filters.timeEnd
    const jwt = localStorage.getItem('id_token')
    dispatch({ type: 'FETCH_HOLES_HIST_START' })
    dispatch({ type: 'SET_TRUE', payload: 'loadingScreen' })
    const res = await axios.get(`${urlBase}/holes`, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
      params: {
        start,
        end,
      },
    })
    dispatch({ type: 'FETCH_HOLES_HIST_SUCCESS', payload: res?.data })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_HOLES_HIST_FAILURE', payload: err })
  }
  setTimeout(() => {
    dispatch({ type: 'SET_FALSE', payload: 'loadingScreen' })
  }, 1000)
  dispatch({ type: 'SET_FALSE', payload: 'showHistoryCalendar' })
}

export const clearHolesHist = () => ({
  type: 'CLEAR_HOLES_HIST',
})

export const setCurrentHole = (payload) => ({
  type: 'SET_CURRENT_HOLE',
  payload,
})

export const fetchMotionHistory = (assetId) => async (dispatch, getState) => {
  const jwt = localStorage.getItem('id_token')

  let res

  try {
    dispatch({
      type: 'FETCH_MOTION_HISTORY_START',
      payload: { assetId },
    })
    res = await axios.get(`${urlBase}/assets/measures`, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
      params: {
        type: 'historyByTime',
        timeAgo: '2m',
        assetId,
        category: 'motion',
        measure_name: ['aXMean', 'aXSpread', 'aYMean', 'aYSpread', 'aZMean', 'aZSpread', 'gXSpread'],
      },
    })

    if (res?.errorType?.includes('ResponseSizeTooLarge')) {
      const error = 'ResponseSizeTooLarge'
      throw error
    } else {
      dispatch({
        type: 'FETCH_MOTION_HISTORY_SUCCESS',
        payload: res?.data?.result,
        assetId,
      })
    }
  } catch (err) {
    console.error(err)
    dispatch({
      type: 'FETCH_MOTION_HISTORY_FAILURE',
      payload: err,
    })
  }
}

export const fetchHolesLinesHist = () => async (dispatch, getState) => {
  try {
    const jwt = localStorage.getItem('id_token')
    dispatch({ type: 'SET_TRUE', payload: 'loadingScreen' })
    dispatch({ type: 'SET_FALSE', payload: ['showHistoryCalendar', 'showHistorySteps'] })
    dispatch({ type: 'FETCH_HOLES_LINES_HIST_START' })

    const relativeTime = getState().tables.byId.historySteps.selectedRelativeTime?.[0]
    const assets = getState().tables.byId.historySteps.selectedAsset || []
    const movs = getState().tables.byId.historySteps.selectedCategory[0]
    const segSize = getState().tables.byId.historySteps.segSize

    //to-do: use this new from webAppApi
    const res = await axios.get(`${URL_BASE}/holeslines`, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
      params: {
        relativeTime,
        assets: arrayToQueryStringParameter(assets),
        movs,
        segSize,
      },
    })
    // const res = await axios.get(`https://uk3ya6vxdq4pm55w5pyq2iquqe0mtzqy.lambda-url.us-east-2.on.aws/ `, {
    //   params: {
    //     company: getState().login.empresa,
    //     /* start:  getState().filters.timeStart,
    //     end: getState().filters.timeEnd,*/
    //   },
    // })

    dispatch({ type: 'FETCH_HOLES_LINES_HIST_SUCCESS', payload: res?.data })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_HOLES_LINES_HIST_FAILURE', payload: err })
  }
  dispatch({ type: 'SET_FALSE', payload: ['loadingScreen'] })
  /*setTimeout(() => {
    
  }, 1000)*/
}
