import React, { Component, Fragment } from 'react'
//import { Polygon} from 'react-leaflet';
import ReactDistortableImageOverlay from 'Libs/ImageOverlay/index'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import L from 'leaflet'
import { point, featureCollection } from '@turf/helpers'
import transformRotate from '@turf/transform-rotate'
import PropTypes from 'prop-types'
import getSafe from 'Utils/getSafe.js'

class InnerMap extends Component {
  onSelect = (id) => {
    //this.props.selectIndoor(id)
  }

  render() {
    const { indoor, indoorPreferences, bearing, center, role } = this.props

    let turfPoints = indoor.map((e) => {
      let turfPoint = [
        point([e.info.corners[0].lng, e.info.corners[0].lat]),
        point([e.info.corners[1].lng, e.info.corners[1].lat]),
        point([e.info.corners[3].lng, e.info.corners[3].lat]),
        point([e.info.corners[2].lng, e.info.corners[2].lat]),
      ]
      let rotate = transformRotate(featureCollection(turfPoint), bearing * -1, { pivot: center }).features
      return {
        id: e.id,
        coordinates: [
          getSafe(() => new L.latLng([rotate[0].geometry.coordinates[1], rotate[0].geometry.coordinates[0]]), null),
          getSafe(() => new L.latLng([rotate[1].geometry.coordinates[1], rotate[1].geometry.coordinates[0]]), null),
          getSafe(() => new L.latLng([rotate[3].geometry.coordinates[1], rotate[3].geometry.coordinates[0]]), null),
          getSafe(() => new L.latLng([rotate[2].geometry.coordinates[1], rotate[2].geometry.coordinates[0]]), null),
        ],
      }
    })

    const canRender = indoor.length > 0 && !turfPoints.every((e) => e.coordinates.includes(null))
    return (
      <Fragment>
        {canRender &&
          indoor
            .filter((e) => !indoorPreferences[e.id]?.invisible)
            .map((element) => {
              return (
                <ReactDistortableImageOverlay
                  key={element.id}
                  id={element.id}
                  opacity={1}
                  keymapper={false}
                  onSelection={this.onSelect}
                  suppressToolbar={!(role === 'ADM' || role === 'DEV')}
                  mode="lock"
                  url={element.info.url}
                  corners={turfPoints.find((e) => e.id === element.id).coordinates}
                />
              )
            })}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  zoom: state.login.preferences.zoom,
  refMap: state.login.refMap,
  indoorPreferences: state.login.preferences.refs,
  updateIndoor: state.toggle.updateIndoor,
  role: state.login.perfil,
})

InnerMap.propTypes = {
  indoor: PropTypes.array.isRequired,
  center: PropTypes.array.isRequired,
}

export default withTranslation()(connect(mapStateToProps)(InnerMap))
