import React from 'react'
import { MapFences } from '../MapModules/MapFences'
import { MapRefs } from '../MapModules/MapRefs'
import { MapRoutes } from '../MapModules/MapRoutes'
import { ModulesStore } from 'interfaces/modules'

interface RenderGeoModules {
  modules: ModulesStore
  geoEvent: (data: any, id: string) => void
}

const RenderGeoModules = ({ modules, geoEvent }: RenderGeoModules) => {
  const { fences, refs, routes } = modules

  return (
    <>
      <MapFences ids={fences.allIds} click={geoEvent} />
      <MapRefs ids={refs.allIds} />
      <MapRoutes ids={routes.allIds} click={geoEvent} />
    </>
  )
}

export default RenderGeoModules
