import React, { useEffect } from 'react'
import { SignInWithAzure } from './SignInWithAzure'
import { SignInWithAuth0 } from './SignInWithAuth0'
import { Button, Segment, Header, Image } from 'semantic-ui-react'
import history from 'Utils/history'
import styled from 'styled-components'
import { useIsAuthenticated } from '@azure/msal-react'

export const Login = ({ auth }) => {
  const isAuthenticatedMsal = useIsAuthenticated()

  useEffect(() => {
    if (isAuthenticatedMsal) {
      const orgUrl = sessionStorage.getItem('originalUrl')
      history.replace(orgUrl || '/')
      sessionStorage.removeItem('originalUrl')
    }
  }, [isAuthenticatedMsal])

  return (
    <LoginWrapper>
      <LoginMain>
        <LoginSection>
          <StyledSegment placeholder>
            <LogoImage
              src="https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/IM-000-3000-500-PH-002_0002_0.png"
              size="medium"
              centered
            />
            <HeaderTop as="h2" textAlign="center" icon>
              Entrar na Plataforma PHYGITALL
            </HeaderTop>
            <ButtonGroup>
              {window.location.hostname === 'vli.phygitall.com.br' && <SignInWithAzure />}
              {window.location.hostname === 'vli.phygitall.com.br' && <StyledOr />}
              <SignInWithAuth0 auth={auth} />
            </ButtonGroup>
          </StyledSegment>
        </LoginSection>
      </LoginMain>
    </LoginWrapper>
  )
}

const HeaderTop = styled(Header)`
  content: '';
  text-align: center;
  margin-bottom: 30px;
`

const LoginSection = styled.section`
  display: flex;
  flex-flow: column;
  align-items: center;
`

const LoginMain = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

const LoginWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: 100vh;
  background-color: #fafafa;
  background-size: cover;
  background-position: center;
  background-image: url('https://i.imgur.com/XwCvoI3.jpg');
`

const StyledSegment = styled(Segment)`
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
`

const LogoImage = styled(Image)`
  margin-bottom: 20px;
`

const ButtonGroup = styled(Button.Group)`
  margin-top: 20px;
`

const StyledOr = styled(Button.Or)``
