import React from 'react'
import { Menu, Sidebar, Icon } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import TableSpan from 'Components/SidebarMenu/TableSpan'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

//Se alguem conseguir transformar isso em styled-component seria otimo.
const sideBarStyle = {
  zIndex: 5000,
  background: 'rgba(255,255,255,.15)',
  textColor: 'white',
  height: '86vh',
  width: '8vw',
  display: 'flex',
  alignItems: 'center',
}
const sideBarMenuItemStyle = {
  color: '#4b72b2',
  paddingTop: '5px',
  paddingRight: '0px',
  paddingLeft: '0px',
  paddingBottom: '0px',
  minHeight: '55px',
  width: '8vw',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}

//Se alguem conseguir transformar isso em styled-component seria otimo by TH
const sideBarStyleMobile = {
  zIndex: 5000,
  background: 'rgba(255,255,255,.15)',
  textColor: 'white',
  height: '86vh',
  width: '25vw',
  display: 'flex',
  alignItems: 'center',
}
const sideBarMenuItemStyleMobile = {
  color: '#4b72b2',
  paddingTop: '5px',
  paddingRight: '0px',
  paddingLeft: '0px',
  paddingBottom: '0px',
  minHeight: '55px',
  width: '20vw',
  display: 'flex',
  alignItems: 'center',
}

const SideBarLeft = ({ isMobile }) => {
  // this should probably be refactored
  const currentTab = useSelector((state) => state.tables.current.tab)
  const visible = useSelector((state) => state.toggle.sideBarLeft)
  const loginProfile = useSelector((state) => state.login.perfil)

  let history = useHistory()
  const match = useRouteMatch()

  const { t } = useTranslation()
  const loginRoutesPanels = useObjSelector((state) => state.login.preferences.routesPanels)

  const handleMenuItemClick = (id, event) => {
    const route = loginRoutesPanels.byId[id].route

    //Botão da esquerda do mouse
    if (event.button === 0) {
      if (loginRoutesPanels.byId[id].blank) {
        // Abrir em outra aba
        window.open(`${match.url}${route}`, '_blank')
      } else {
        // Abrir na mesma aba
        history.push(`${match.url}${route}`)
      }
    }

    //Botão do meio do mouse
    if (event.button === 1) {
      event.preventDefault()
      window.open(`${match.url}${route}`, '_blank')
    }
  }

  return (
    <Sidebar
      as={Menu}
      animation="push"
      direction="left"
      icon="labeled"
      compact
      vertical
      size={'mini'}
      style={isMobile ? sideBarStyleMobile : sideBarStyle}
      visible={visible}
      width="thin"
    >
      {visible && (
        <React.Fragment>
          <Menu.Item
            active={currentTab === 'map'}
            color="green"
            key="map"
            style={sideBarMenuItemStyle}
            onMouseDown={(event) => {
              //Botão da esquerda do mouse
              if (event.button === 0) {
                // Abrir na mesma aba
                history.push(`/`)
              }

              //Botão do meio do mouse
              if (event.button === 1) {
                event.preventDefault()
                window.open(`/`, '_blank')
              }
            }}
            title={t('Map')}
          >
            <Icon name="map outline" />
            <TableSpan value={t('Map')} width="100px" padding="5px" minWidth="100px" noTitle />
          </Menu.Item>
          {loginRoutesPanels.allIds
            .filter(
              (eachId) =>
                loginRoutesPanels.byId[eachId] &&
                !loginRoutesPanels.byId[eachId]?.invisible &&
                ![
                  'dashboard',
                  'manageandsecurity',
                  'mensurations',
                  //'motionChart',
                  'manualYamazumi',
                  'automaticYamazumi',
                  'cycleTimeMeasurement',
                  'driver',
                ].includes(eachId) &&
                (loginProfile === 'DEV' || !['betaReports'].includes(eachId))
            )
            .map((id) => {
              // to change the color
              const selectedTab = id === currentTab
              return (
                <Menu.Item
                  active={selectedTab}
                  color="green"
                  key={id}
                  style={{ ...(isMobile ? sideBarMenuItemStyleMobile : sideBarMenuItemStyle) }}
                  title={loginRoutesPanels.byId[id].label?.length > 16 ? loginRoutesPanels.byId[id].label : ''}
                  onMouseDown={(event) => handleMenuItemClick(id, event)}
                  onClick={() => {}}
                >
                  <Icon name={loginRoutesPanels.byId[id].icon} />
                  <TableSpan value={t(loginRoutesPanels.byId[id].label)} padding="5px" noTitle width="95%" />
                </Menu.Item>
              )
            })}
        </React.Fragment>
      )}
    </Sidebar>
  )
}

export default SideBarLeft
