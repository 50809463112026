/*  
  Author: Bruno Melo/Luís Mourão
  Type: Reducer
  
  Description: 
    - Reducer da ToggleStore
  
  TODO: 
    - Retirar toggleMarkerNew após finalização do refactor redux?
*/

export default function toggleReducer(
  state = {
    showConfig: false,
    mapDragging: true,
    showPolyline: true,
  },
  { type, payload }
) {
  let payloadObj = {}
  switch (type) {
    case 'TOGGLE':
      return { ...state, [payload]: !state[payload] }
    case 'SET_TRUE':
      typeof payload === 'string' ? (payloadObj[payload] = true) : payload.forEach((e) => (payloadObj[e] = true))
      return { ...state, ...payloadObj }
    case 'SET_FALSE':
      typeof payload === 'string' ? (payloadObj[payload] = false) : payload.forEach((e) => (payloadObj[e] = false))
      return { ...state, ...payloadObj }
    case 'markerNew':
      return { ...state, ...{ markerNew: !state.markerNew } }
    case 'filterMarkers':
      return { ...state, ...{ markerNew: !state.markerNew } }
    case 'FETCH_ASSET_COORDS_SUCCESS':
      return { ...state, showFitBounds: payload.lat ? true : false }
    case 'EMPTY_HISTORY':
      return { ...state, showHistoryCalendar: false }
    default:
      return state
  }
}
