import * as z from 'zod'

const stepTwoSchema = z.object({
  activity: z.object({ name: z.string().optional() }).optional(),
  activityId: z.string(),
  vehicle: z.string(),
  targetCycleTime: z.number(),
  operator: z.string(),
})

export type StepTwo = z.infer<typeof stepTwoSchema>

export default stepTwoSchema
