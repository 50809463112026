import axios from 'axios'
import { REPORT_URL, urlBase } from 'Apis/rest'

interface Params {
  [key: string]: any
}

interface PanelPrefs {
  perimeters: string[]
}

interface FetchReportDataArgs {
  scope: string
  params: Params
  panelPrefs: PanelPrefs
}

const fetchDashboardPanelContent = async ({ scope, params, panelPrefs }: FetchReportDataArgs): Promise<any> => {
  try {
    const jwt = localStorage.getItem('id_token')

    const headers = {
      Authorization: `Bearer ${jwt}`,
    }

    const axiosRequest: Record<string, any> = {
      peopleOnSite: () => {
        if (params.filterType === 'peopleType' && params.time === 'rtm') {
          return {
            method: 'get',
            url: 'https://ucx40j7bv0.execute-api.us-east-2.amazonaws.com/beta/onlineAssetsCountByProfile',
          }
        } else {
          return {
            method: 'get',
            url: `${urlBase}/reportdata`,
            params,
          }
        }
      },
      avgTimeReport: () => ({
        method: 'post',
        url: `${REPORT_URL}/avgTimeReport`,
        data: { ...params, originType: 'simulated', perimeters: panelPrefs.perimeters },
      }),
      default: {
        method: 'get',
        url: `${urlBase}/reportdata`,
        params,
      },
    }

    const { method, url, data, params: queryParams } = axiosRequest[scope]?.() || axiosRequest.default
    const res = await axios({ method, url, data, params: queryParams, headers })

    if (scope === 'avgTimeReport' && res.data?.errorType?.includes('ResponseSizeTooLarge')) {
      throw new Error('ResponseSizeTooLarge')
    }

    return res.data
  } catch (error) {
    console.error('Error fetching report data:', error)
    throw error
  }
}

export default fetchDashboardPanelContent
