import React, { Fragment } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import ReportInPDF from './ReportInPDF'

import './PDF.css'

const PDFComponent = () => {
  // const reportColumns: any = JSON.parse(reportData)[0]
  const reportData = JSON.parse(localStorage.getItem('reportData'))

  const reportColumns = reportData[0]

  let dataItems = []
  for (let row = 1; row < reportData.length; ++row) {
    let item = {}
    for (let column = 0; column < reportColumns.length; ++column) {
      item[reportColumns[column]] = reportData[row][column] ?? ' '
    }
    item['key'] = row
    dataItems.push(item)
  }

  const data = {
    id: localStorage.getItem('uuid'),
    columns: reportColumns,
    transDate: localStorage.getItem('reportDate'),
    items: dataItems,
  }

  return (
    <Fragment>
      <PDFViewer
        width={document.documentElement.clientWidth}
        height={document.documentElement.clientHeight}
        className="PDF"
      >
        <ReportInPDF data={data} />
      </PDFViewer>
    </Fragment>
  )
}

export default PDFComponent
