import { Report } from 'interfaces/reports'

export const parseReport = (payload: Report) => {
  return {
    ...payload,
    filters: typeof payload.filters === 'string' ? JSON.parse(payload.filters) : payload.filters,
  }
}

export default parseReport
