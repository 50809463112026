import axios from 'axios'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Button, Grid, Message, Loader, Segment } from 'semantic-ui-react'
import { useReactToPrint } from 'react-to-print'
import PrintModelView from 'Components/PrintModelView'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useQuery from 'Utils/hooks/useQuery'

const Container = styled.div`
  display: inline;

  @media print {
    overflow: visible !important;
    height: auto !important;
    max-height: none !important;
  }
`
const PageBreak = styled.div`
  @media print {
    page-break-after: always;
  }
`

type TParams = { hash: string }
interface IData {
  id: string
  title: string
  name: string
  email: string
  hash: string
  tenant: string
  info: any
  createdAt: string
  userLabel?: string
}

const URL = 'https://jstm1estza.execute-api.us-east-2.amazonaws.com/v0'

export const DisplayInfo = ({ match }: RouteComponentProps<TParams>) => {
  const [data, setData] = useState<IData[]>()
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const query = useQuery()
  const form = query.get('form')
  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `${URL}/information/${match.params.hash}${form ? '?form=' + form : ''}`
        const result = await axios(endpoint)
        setData(result.data)
      } catch (err) {
        setError(true)
        setErrorMsg('Link inválido')
      }
    }
    fetchData()
  }, [match.params.hash, form])

  if (!match.params.hash) return null
  return (
    <Grid textAlign="center" style={{ background: '#f7f7f7' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 500 }}>
        <Content data={data} error={error} errorMsg={errorMsg} />
      </Grid.Column>
    </Grid>
  )
}

interface IContent {
  error: boolean
  data: IData[] | undefined
  errorMsg: string
}

const Content = ({ data, error, errorMsg }: IContent) => {
  const [loading, setLoading] = useState(false)
  const contentToPrint = useRef(null)
  const [printMode, setPrintMode] = useState(false)
  const { t } = useTranslation()

  const reactToPrintContent = React.useCallback(() => contentToPrint.current, [])

  const handleOnBeforeGetContent = async () => {
    setLoading(true)
    setPrintMode(true)
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve()
      }, 100)
    })
  }

  const handleAfterPrint = React.useCallback(() => {
    setLoading(false)
  }, [])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Print This Document',
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: handleAfterPrint,
    onPrintError: handleAfterPrint,
    removeAfterPrint: true,
  })

  const handleClick = React.useCallback(() => {
    handlePrint()
  }, [handlePrint])

  if (error) return <Message error header="Erro" content={errorMsg} />

  if (!data) return <Loader active />

  return (
    <Fragment>
      <Container ref={contentToPrint}>
        {data.map((d) => (
          <div key={d.id}>
            <PrintModelView
              name={d.title}
              email={d.email}
              code={d.id}
              createdAtLabel="Solicitado às: "
              createdByLabel={d.userLabel ?? 'Responsável: '}
              createdBy={d.name}
              createdAt={d.createdAt}
              fields={d.info}
              printMode={printMode}
            />
            <PageBreak />
          </div>
        ))}
      </Container>

      <Segment basic>
        <Button primary onClick={handleClick} loading={loading} disabled={loading}>
          {t('print')}
        </Button>
      </Segment>
    </Fragment>
  )
}
