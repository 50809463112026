import { point } from '@turf/helpers'
import transformRotate from '@turf/transform-rotate'

const innerPosition = (pos, type, centerInnerMap, angleInnerMap) => {
  let center = centerInnerMap || [-46.397305, -23.32695]
  let angle = angleInnerMap || 21.8
  if (type === 'ref') {
    return {
      lat: transformRotate(point(pos), angle * -1, { pivot: point(center) }).geometry.coordinates[1],
      lng: transformRotate(point(pos), angle * -1, { pivot: point(center) }).geometry.coordinates[0],
    }
  }
  if (type === 'marker') {
    let coord = transformRotate(point([pos.lng, pos.lat]), angle * -1, { pivot: point(center) }).geometry.coordinates
    return { lat: coord[1], lng: coord[0] }
  }
  if (type === 'coordinates') {
    let newpos = []
    newpos[0] = pos[0]
      ? pos[0].map((p) => {
          let coord = transformRotate(point([p[0], p[1]]), angle * -1, { pivot: point(center) }).geometry.coordinates
          return [coord[0], coord[1]]
        })
      : pos[0]
    return newpos
  }

  if (type === 'array') {
    let coord = transformRotate(point([pos[1], pos[0]]), angle * -1, { pivot: point(center) }).geometry.coordinates
    return [coord[1], coord[0]]
  }
  if (type === 'reverseRef') {
    return {
      lat: transformRotate(point(pos), angle, { pivot: point(center) }).geometry.coordinates[1],
      lng: transformRotate(point(pos), angle, { pivot: point(center) }).geometry.coordinates[0],
    }
  }
  if (type === 'reverseMarker') {
    let coord = transformRotate(point([pos.lng, pos.lat]), angle, { pivot: point(center) }).geometry.coordinates
    return { lat: coord[1], lng: coord[0] }
  }
  if (type === 'reverseArray') {
    let coord = transformRotate(point([pos[1], pos[0]]), angle, { pivot: point(center) }).geometry.coordinates
    return [coord[1], coord[0]]
  }
  if (type === 'reverseArrayLngLat') {
    let coord = transformRotate(point([pos[1], pos[0]]), angle, { pivot: point(center) }).geometry.coordinates
    return [coord[0], coord[1]]
  }
  if (type === 'fences') {
    let coords = pos.length === 2 ? [pos] : pos[0]
    let res = coords.map(
      (eachPos) =>
        transformRotate(point([eachPos[0], eachPos[1]]), angle, { pivot: point(center) }).geometry.coordinates
    )
    return pos.length === 2 ? res[0] : [res]
  }
  if (type === 'refs') {
    return transformRotate(point([pos[0], pos[1]]), angle, { pivot: point(center) }).geometry.coordinates
  }
  if (type === 'routes') {
    return pos.map(
      (eachPos) =>
        transformRotate(point([eachPos[0], eachPos[1]]), angle, { pivot: point(center) }).geometry.coordinates
    )
  }
}

export { innerPosition }
