import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactTable from 'Utils/reactTable'
import useFetchData from 'Utils/hooks/useFetchData'
import { useObjSelector } from '../../../../Utils/hooks/useObjSelector'
import { RootStore } from '../../../../Store/initialStore'
import {
  deleteDataLabelings,
  setDataLabelingIDs,
  setDataLabelingWIP,
  setDataLabelingsProp,
} from 'Store/actions/dataLabelings-actions'
import { useDispatch, useSelector } from 'react-redux'
import { Sidebar, Segment, Icon, Button } from 'semantic-ui-react'
import DataLabelings from 'Utils/reactTable/modals/dataLabelings/DataLabelings'
import SideBarRight from 'Components/sideBarRight/SideBarRight'
import { GenericColumns } from 'Utils/reactTable/components/GenericColumns'
import { setFalse } from 'Store/actions/toggle-action'
import styled from 'styled-components'
import m from 'moment'
import { DataLabeling } from 'interfaces/dataLabelings'

const CollapseSlide = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  ${({ visible }) => (visible ? 'right: 3.2em;' : 'right: 0.5em;')};
`

export const DataLabelingsContent = ({ windowWidth = 2000 }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  useFetchData({
    independentFetches: [{ fetchAction: 'dataLabelings' }, { fetchAction: 'assets' }],
    finishAction: () => setLoading(false),
  })

  const dataLabelingsIds = useObjSelector((state: RootStore) => state.dataLabelings.allIds)
  const dataLabelingsById = useObjSelector((state: RootStore) => state.dataLabelings.byId)
  const showLabelingContent = useObjSelector((state: RootStore) => state.dataLabelings.showLabelingContent)
  const assetsById = useObjSelector((state: RootStore) => state.assets.byId)
  const openSideBar = useSelector((state: RootStore) => state.dataLabelings.openSideBar)
  const hiddenColumns = useObjSelector(
    (state) => state.login.preferences.routesPanels.byId['dataLabelings'].hiddenColumns
  )
  const entityLabel = useSelector((state: RootStore) => state.login.preferences.routesPanels.byId.dataLabelings.label)

  interface IAPIData {
    items: Item[]
    uniqueShifts: unknown[]
    uniqueVehicles: string[]
    uniquePhases: unknown[]
    rechartsYamazumi: unknown[]
    uniqueCycles: unknown[]
    mensurationData: unknown[]
    vehicleParam: String
    shiftParam: String
  }

  interface Item {
    labelings: unknown[]
    phases: unknown[]
    startTime: any
  }

  const PusherStyle = {
    width: `${openSideBar ? '53%' : '95vw'}`,
    transform: `translate(4vw, 0vh)`,
    height: '86vh',
    position: 'relative',
    // display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    backgroundColor: 'white', //default do semanticui é #cce2ff
    // paddingLeft: '1.2vw',
    overflow: 'auto',
  }

  const [theseColumns, setTheseColumns] = useState([])

  const columns = GenericColumns({
    columns: [
      {
        accessor: 'id',
        Header: t('pdtId'),

        Cell: ({ value }: any) => {
          return value.split('-')[0]
        },
      },
      {
        accessor: 'activity.code',
        Header: t('pdtCode'),

        Cell: ({ value }: any) => {
          return <span>{`${value}`}</span>
        },
      },
      {
        accessor: 'activity.name',
        Header: t('pdtName'),
        Cell: ({ value }: any) => {
          return <span>{`${value}`}</span>
        },
      },
      {
        accessor: 'shift',
        Header: t('shift'),
        Cell: ({ value }: any) => {
          return <span>{`${value}`}</span>
        },
      },
      {
        Header: t('startTime'),
        accessor: 'startTime',
        Cell: ({ value }: any) => {
          return <span>{m(value).format('DD/MM/YY HH:mm:ss')}</span>
        },
      },
      {
        Header: t('endTime'),
        accessor: 'endTime',
        Cell: ({ value }: any) => {
          return <span>{m(value).format('DD/MM/YY HH:mm:ss')}</span>
        },
      },
      {
        Header: t('leftDevice'),
        accessor: 'leftDevice',
        Cell: ({ value }: { value: string }) => {
          return <span>{assetsById[value]?.name}</span>
        },
      },
      {
        Header: t('rightDevice'),
        accessor: 'rightDevice',
        Cell: ({ value }: { value: string }) => {
          return <span>{assetsById[value]?.name}</span>
        },
      },
      {
        accessor: 'taktTime',
        Header: t('taktTime'),
        Cell: ({ value }: any) => {
          return <span>{`${value}`}</span>
        },
      },
      {
        accessor: 'targetCycleTime',
        Header: t('targetCycleTime'),
        Cell: ({ value }: any) => {
          return <span>{`${value}`}</span>
        },
      },
      {
        Header: t('phases'),
        accessor: 'phases',
        Cell: ({ value }: any) => {
          return value?.length
        },
      },
      {
        id: 'labelings',
        Header: t('labels'),
        accessor: ({ labelings }: { labelings: DataLabeling['labelings'] }) => {
          return labelings?.length ?? 0
        },
        Cell: ({ value }: any) => {
          return value
        },
      },
      'description',
    ],
  })

  const data = React.useMemo(() => {
    return dataLabelingsIds.map((id: string) => {
      const { assets, ...dataLabeling } = dataLabelingsById[id]
      return { ...dataLabeling, leftDevice: assets[0], rightDevice: assets[1] }
    })
  }, [dataLabelingsById, dataLabelingsIds])

  useEffect(() => {
    return () => {
      dispatch(setDataLabelingIDs([]))
    }
  }, [dispatch])

  return showLabelingContent ? (
    <Sidebar.Pushable as={Segment} style={{ overflow: 'hidden' }}>
      <SideBarRight visible={openSideBar} />
      <Sidebar.Pusher dimmed={false} style={PusherStyle} className="dataLabelingPusher">
        <CollapseSlide
          icon
          onClick={() => dispatch(setDataLabelingsProp(`openSideBar`, !openSideBar))}
          floated="right"
          visible={openSideBar}
        >
          <Icon name={openSideBar ? 'arrow right' : 'arrow left'} />
        </CollapseSlide>
        <DataLabelings />
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  ) : (
    <ReactTable
      table={'dataLabelings'}
      tableLabel={entityLabel}
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
      theseColumns={theseColumns}
      setTheseColumns={setTheseColumns}
      initialSortBy={{ desc: true, id: 'createdAt' }}
      showPlaceholders={loading}
      newItemClick={() => {
        dispatch(setDataLabelingsProp(`form.open`, true))
        dispatch(setDataLabelingWIP())
      }}
      onEditClick={(id: string) => {
        dispatch(setDataLabelingWIP(id))
        dispatch(setDataLabelingsProp(`form.open`, true))
      }}
      onLabelsClick={(id: string) => {
        dispatch(setDataLabelingWIP(id))
        dispatch(setDataLabelingsProp(`showLabelingContent`, true))
        dispatch(setFalse('sideBarLeft'))
      }}
      deleteAction={({ ids }: { ids: string[] }) => {
        dispatch(deleteDataLabelings(ids))
      }}
    />
  )
}
