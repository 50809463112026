import * as z from 'zod'

// Função que retorna um schema com validações que requerem acesso à lista de valores existentes
const createStepThreeSchema = (timeProp?: string) => {
  return z.object({
    filters: z.object({
      timeProp: z.string(),
    }),
    startTime: z.string(),
    endTime: z.string(),
  })
}

export default createStepThreeSchema
