/*  
  Author: Bruno Melo/Luís Mourão
  Type: Reducer
  
  Description: 
    -  
  - 
   
  TODO: 
    - Tornar o SET_CURRENT_ASSET generalizado para vários IDS (Caso Necessário)
*/

import { logError } from 'Utils/analytics'
import { notificationState /* NotificationStatus */ } from 'Utils/notifications'
import produce from 'immer'
import initialStore from 'Store/initialStore'

export default function assetsReducer(state = initialStore.assets, { type, id, payload }) {
  let errorTypes = ['assetNew', 'updateassetNew', 'updatedeleteassetNew']

  if (errorTypes.includes(type)) {
    if (payload.idtipo) {
      payload.name = payload.nome
      payload.idType = payload.idtipo
      payload.time = Date.now()
      payload.invisible = false
      payload.sensor = payload.sensores
      payload.serialNumber = payload.serialNumber
        ? payload.serialNumber
        : payload.deviceId
        ? `Sem Número de Sério (Id: ${payload.deviceId})`
        : `Clique em editar para associar a um dispositivo`
    }

    if (payload === null) {
      logError(type + ' null: ' + id)
      return state
    }
    if (!id && !payload.id) {
      logError(type + ' without ID: ' + id)
      return state
    }
    if (!payload.info && type !== 'updatedeleteassetNew') {
      logError(type + ' without info ' + id)
      return state
    }

    if (!payload.idType && type !== 'updatedeleteassetNew') {
      logError(type + ' without tipo ' + id)
      return state
    }
  }
  const splitType = type.split('#')[0]

  switch (splitType) {
    case 'FETCH_ASSETS_START':
      return { ...state, loading: true }

    case 'FETCH_ASSETS':
      payload.result = typeof payload?.body === 'string' ? JSON.parse(payload.body) : null
      if (payload === null || payload.success === false) {
        console.error('Error in assets initial store')
        return state
      }
      if (!payload) {
        console.warn('No assets in store!')
        return state
      }
      if (!payload.result) {
        console.warn("Asset's store empty!")
        return state
      }

      return produce(state, (newState) => {
        newState.allIds = []
        newState.byId = {}
        newState.groups = []
        newState.current = {}
        newState.loading = false
        let assets = payload.result
        if (Array.isArray(assets) && assets.length > 0) {
          assets.forEach((e) => {
            newState.byId[e.id] = e
            newState.allIds.push(e.id?.toString())
            e.groups &&
              e.groups.forEach((group) => {
                if (!newState.groups.includes(group)) newState.groups.push(group)
              })
          })
        }
        newState.groups.sort((prev, next) => prev.localeCompare(next))
        newState.success = true
      })

    case 'FETCH_ACTIVE_ASSETS':
      notificationState.removeAllNotifications()
      return {
        ...state,
        allActiveIds: payload.allIds,
        allActivePeopleIds: [...payload.app, ...payload.sb, ...payload.sw].map((element) => {
          return typeof element === 'number' ? element.toString() : element
        }),
        allActiveAssetsIds: [...payload.tracker, ...payload.geotag],
        allActiveAppIds: payload.app,
        allActiveSbIds: payload.sb,
        // errorIds: undefined,
      }

    // case 'FETCH_ACTIVE_ASSETS_FAILURE':
    //   const errorId = notificationState.addNotification({
    //     title: 'Você está offline!',
    //     message: 'Estamos tentando reconectar...',
    //     status: NotificationStatus.ERROR,
    //     sticky: true,
    //   })
    //   const errorIds = state.errorId ? [...state.errorId, errorId] : [errorId]
    //   return { ...state, errorIds }

    case 'FETCH_ASSETS_ASSOCIATIONS_SUCCESS':
      return { ...state, associations: payload ? JSON.parse(payload.body) : state.associations }

    case 'FETCH_ASSETS_LOGOUTS_SUCCESS':
      return { ...state, logouts: JSON.parse(payload.body) }

    case 'assetNew':
      return produce(state, (newState) => {
        let idNum = parseInt(id)
        newState.byId[idNum] = payload
        newState.allIds.push(id?.toString())
      })

    case 'updateassetNew':
      return produce(state, (newState) => {
        let idNum = parseInt(id)
        newState.byId[idNum] = payload
        if (payload.invisible === true) {
          newState.allActiveIds = state.allActiveIds.filter((e) => e !== idNum.toString())
        }
      })

    case 'updatedeleteassetNew': {
      const allIdsNew = state.allIds.filter((e) => e.toString() !== id)
      const allActiveIdsNew = state.allActiveIds.filter((e) => e.toString() !== id)
      return { ...state, allIds: allIdsNew, allActiveIds: allActiveIdsNew }
    }

    case 'TOGGLE_ASSET_VISIBILITY':
      return produce(state, (newState) => {
        id.forEach((eachId) => {
          let asset = state.byId[eachId]
          if (asset) newState.byId[eachId].invisible = payload
        })
      })

    case 'SET_CURRENT_ASSET':
      return { ...state, current: state.byId[id] }

    case 'UPDATE_ASSET_REQUEST':
      return { ...state, current: { ...state.byId[id], isUploading: true, hasError: null } }

    case 'UPDATE_ASSET_NAME_SUCCESSFULL':
      /*update avatar or update name?*/
      return {
        ...state,
        current: {
          ...state.current,
          isUploading: false,
          hasError: false,
          name: payload,
        },
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            name: payload,
          },
        },
      }

    case 'UPDATE_ASSET_SUCCESSFULL':
      /*update avatar or update name?*/
      let newInfo = {}
      newInfo = payload.url ? { ...state.byId[id].info, url: payload.url } : { ...state.byId[id].info, ...payload.info }
      return {
        ...state,
        current: {
          ...state.current,
          isUploading: false,
          hasError: false,
          info: newInfo,
        },
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            info: newInfo,
          },
        },
      }

    case 'UPDATE_ASSET_FAILURE':
      return {
        ...state,
        current: { ...state.current, isAvatarLoading: false, hasAvatarError: true },
      }

    case 'UPDATE_ASSET_GROUPS':
      /*if(payload.createdBy === state.wip.createdBy)
            return state;*/
      return produce(state, (newState) => {
        let n = 0 //nro de itens daquele grupo
        state.allIds.forEach((i) => {
          if (payload.selected.includes(state.byId[i].name)) {
            if (payload.associate) {
              if (!state.byId[i].groups.includes(payload.groupName)) newState.byId[i].groups.push(payload.groupName)
            } else newState.byId[i].groups = state.byId[i].groups.filter((f) => f !== payload.groupName)
          }
          //It counts how many itens still have this group
          if (state.byId[i].groups.includes(payload.groupName)) n = n + 1
        })
        /*Update the Group's List*/
        if (payload.associate) {
          if (!state.groups.includes(payload.groupName)) newState.groups.push(payload.groupName)
        }
        /* else{   //Dando erro, ver na release 1.28
          if(n===0) {
          newState.groups = newState.groups.filter((j)=>j!==payload.groupName)}  
        } */
      })

    case 'DELETE_ASSET_GROUPS':
      return produce(state, (newState) => {
        if (payload.ids)
          payload.ids.forEach((id, i) => {
            if (state.byId[id]) newState.byId[id].groups = []
          })
      })

    case 'FETCH_ASSET_IMAGE_START':
      return produce(state, (newState) => {
        newState.byId[payload].loadingImage = true
      })

    case 'FETCH_ASSET_IMAGE_SUCCESS':
      return produce(state, (newState) => {
        if (payload.assetId) {
          newState.byId[payload.assetId].info.image = payload.response.photo
          newState.byId[payload.assetId].info.image.loadType = 'base64'
          newState.byId[payload.assetId].loadingImage = false
        }
      })

    case 'FETCH_ASSET_IMAGE_FAILURE':
      return produce(state, (newState) => {
        newState.byId[payload].loadingImage = false
      })

    case 'FETCH_ASSET_DETAILS_SUCCESS':
      return produce(state, (newState) => {
        if (payload.info) {
          newState.byId[payload.id].info = payload.info
        }
      })

    case 'FETCH_ASSET_DETAILS_FAILURE':
      return state

    case 'INSERT_ACTION_START':
    case 'UPDATE_ACTION_START':
    case 'DELETE_ACTION_START':
      return { ...state, cudLoading: true }

    case 'INSERT_ACTION_SUCCESS':
    case 'UPDATE_ACTION_SUCCESS':
    case 'DELETE_ACTION_SUCCESS':
      return { ...state, cudLoading: false, cudSuccess: true }

    case 'INSERT_ACTION_FAILURE':
    case 'UPDATE_ACTION_FAILURE':
    case 'DELETE_ACTION_FAILURE':
      return { ...state, cudLoading: false, cudSuccess: false, error: payload }

    case 'CLEAR_ASSET_STATE':
      return { ...state, cudLoading: undefined, hasError: undefined, cudSuccess: undefined }

    default:
      return state
  }
}
