import React, { useEffect, useCallback } from 'react'
import Maps from '.'
import { saveLoginPreferences } from 'Store/actions/login-action'
import { useDispatch, useSelector } from 'react-redux'
import { setTrue, setFalse } from 'Store/actions/toggle-action'
import { setTableProp } from 'Store/actions/tables-action'
import useFetchData from 'Utils/hooks/useFetchData'
import { fetchActiveAssets } from 'Store/actions/assets-action'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'

const RELOAD_ACTIVE_ASSET_TIME = 30000

const RenderMap = () => {
  const dispatch = useDispatch()
  useFetchData({
    independentFetches: [
      {
        fetchAction: 'assets',
      },
      {
        fetchAction: 'profiles',
      },
      {
        fetchAction: 'notifications',
      },
      {
        fetchAction: 'units',
      },
    ],
    dependentFetches: [
      { fetchAction: 'geoModulesByIds', fetchParams: {} },
      { fetchAction: 'geoModulesByIds', fetchParams: { getCoords: true } },
    ],
    errorAction: useCallback(() => {
      dispatch(setFalse('loadingScreen'))
      // for now we just alert the user that there was an error
      alert('Ocorreu um erro ao carregar os dados. Por favor, tente novamente.')
      dispatch(setTrue('errorScreen'))
    }, [dispatch]),
    finishAction: () => dispatch(setFalse('loadingScreen')),
  })

  const loginProfile = useSelector((state) => state.login.perfil)
  const showFirstInteraction = useSelector((state) => state.toggle.showFirstInteraction)

  const handleUnload = useCallback(
    (e) => {
      const message = 'o/'
      ;(e || window.event).returnValue = message //Gecko + IE
      dispatch(saveLoginPreferences())
      return message
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(setTableProp('current.tab', 'map'))
    dispatch(setTrue('displayingMap'))
    dispatch(setTrue('loadingScreen'))
  }, [dispatch])

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload)
    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [handleUnload])

  useEffect(() => {
    dispatch(fetchActiveAssets())
    const id = setInterval(() => {
      dispatch(fetchActiveAssets(true))
    }, RELOAD_ACTIVE_ASSET_TIME)
    return () => clearInterval(id)
  }, [dispatch])

  return (
    <>
      <Maps />
      <ModalTemplate
        header={'Bem-vindo à Plataforma Phygitall!'}
        open={loginProfile === 'CC' && showFirstInteraction}
        saveContent="INICIAR MONITORAMENTO"
        size="small"
        dimmer="blurring"
        cancelButton={false}
        onSave={() => dispatch(setFalse('showFirstInteraction'))}
        onClose={() => dispatch(setFalse('showFirstInteraction'))}
        closeIcon={false}
      >
        <br />
        <p style={{ textAlign: 'center' }}>Clique para acessar o Proteção à Vida</p>
      </ModalTemplate>
    </>
  )
}

export default RenderMap
