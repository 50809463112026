import React from 'react'
import { Form, Input, InputOnChangeData, Button, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useCanAccess } from 'Utils/rbac'
import InputField from 'Components/forms/generic/InputField'

interface StatsBarFormTagsEditProps {
  tagsList: { key: string; value: unknown; existing: boolean }[]
  windowHeight: number
  onChange: (_: any, { value, id }: InputOnChangeData) => void
  onAddTag: () => void
  onRemoveTag: (idx: number) => void
}

const StatsBarFormTagsEdit = ({
  tagsList,
  windowHeight = 0,
  onChange,
  onAddTag,
  onRemoveTag,
}: StatsBarFormTagsEditProps) => {
  const canAccess = useCanAccess('WRITE')
  const { t } = useTranslation()

  return (
    <Form
      style={{
        paddingLeft: '10px',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: `${windowHeight - 424}px`,
        paddingRight: '10px',
      }}
    >
      {tagsList.length ? (
        tagsList.map(({ key, value, existing }, idx) => (
          <Form.Group widths="6" key={idx}>
            <InputField
              id={'key'}
              label={t('key')}
              placeholder={t('key')}
              width="5"
              value={key}
              onChange={(_, { value, id }) => onChange(_, { value, id, idx })}
              max={8}
            />
            <Form.Field
              required={false}
              // readOnly={!canAccess}
              id={'value'}
              control={Input}
              label={t('Value')}
              placeholder={t('Value')}
              width="10"
              value={value}
              onChange={(_: any, { value, id }: any) => onChange(_, { value, id, idx })}
            />
            {canAccess && (
              <Icon
                id="icon-delete"
                name="trash alternate outline"
                color="grey"
                size="large"
                onClick={() => onRemoveTag(idx)}
                style={{ cursor: 'pointer', marginTop: '30px' }}
              />
            )}
          </Form.Group>
        ))
      ) : (
        <p>{t('statsBar.tags.noExistingTags')}</p>
      )}

      {canAccess && (
        <Button onClick={onAddTag} style={{ marginTop: '10px' }} icon="plus" content={t('statsBar.tags.addTags')} />
      )}
    </Form>
  )
}

export default StatsBarFormTagsEdit
