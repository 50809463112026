export const colors = [
  'blue',
  'orange',
  'green',
  'violet',
  'yellow',
  'red',
  'purple',
  'teal',
  'violet',
  'olive',
  'pink',
  'brown',
  'gray',
]

export const colorFormat = (color) => {
  const format = {
    blue: '#3498db',
    yellow: '#f1c40f',
    green: '#07bc0c', //'#06a30a',
    red: '#e74c3c', //'#ec7467',
    black: '#000000',
    white: '#FFFFFF',
    gray: '#666364',
  }
  return format[color] || color
}

export const semanticColors = (color) => {
  const format = {
    red: '#DB2828',
    orange: '#F2711C',
    yellow: '#FBBD08',
    olive: '#B5CC18',
    green: '#21BA45',
    teal: '#00B5AD',
    blue: '#2185D0',
    violet: '#6435C9',
    purple: '#564FF0',
    pink: '#E03997',
    brown: '#A5673F',
    gray: '#767676',
    black: '#1B1C1D',
  }
  return format[color] || color
}

export const lightColors = (color) => {
  const format = {
    red: '#F4B9B9',
    orange: '#FFC18F',
    yellow: '#FFDA8F',
    olive: '#B5CC18',
    green: '#8DD994',
    teal: '#B0DCF2',
    blue: '#B0DCF2',
    violet: '#C8C6F7',
    purple: '#d5bfe1',
    pink: '#F0BCDF',
    brown: '#D1D1D1',
    gray: '#C3D0D4',
    black: '#666',
  }
  return format[color] || color
}
