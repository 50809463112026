import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import getDistance from 'Utils/getDistance'

export const StatsBarFormGeoLength = ({ geometry }) => {
  const { t } = useTranslation()

  let routeLength = 0
  geometry.coordinates.forEach((e, i) => {
    if (i < geometry.coordinates.length - 1) {
      routeLength += getDistance(
        [geometry.coordinates[i][1], geometry.coordinates[i][0]],
        [geometry.coordinates[i + 1][1], geometry.coordinates[i + 1][0]]
      )
    }
  })
  let fLength = routeLength >= 1000 ? Math.round(routeLength) / 1000 + ' km' : Math.round(routeLength) + ' m'
  return (
    <Form>
      <Form.Field
        id="form-area"
        control={Input}
        label={t('Stretch')}
        placeholder={t('Stretch')}
        value={fLength || 'missing coords to calculate length'}
      />
    </Form>
  )
}
export default StatsBarFormGeoLength
