import { mutateEntity } from 'Apis/mutateEntity'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'
import { API } from 'aws-amplify'
import { createProfile, deleteProfile, updateProfile } from 'graphql/mutations'
import { listProfilesByTenant } from 'graphql/queries'
import { onChangeProfile, onDeleteProfile } from 'graphql/subscriptions'

export const setProfilesProp = (prop, value) => {
  return {
    type: 'SET_PROFILES_PROP',
    prop,
    value,
  }
}

export const setProfilesProps = (payload, source) => {
  return {
    type: 'SET_PROFILES_PROPS',
    payload,
    source,
  }
}

export const fetchProfiles = (token) => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  try {
    dispatch({ type: 'FETCH_PROFILES_START', tenant })
    const response = await API.graphql({
      query: listProfilesByTenant,
      variables: { tenant, nextToken: token, limit: 10000 },
    })
    const payload = response.data.listProfilesByTenant
    dispatch({
      type: 'FETCH_PROFILES_SUCCESS',
      payload,
    })
    if (payload.nextToken) await dispatch(fetchProfiles(payload.nextToken))
    return response
  } catch (err) {
    console.error('error fetching profiles', err)
  }
}

export const mutateProfile = () => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  const { updatedAt, __typename, ...wip } = getState().profiles.wip
  const email = getState().login.email
  const fieldsToBeRemoved = getState().profiles.form.fieldsToBeRemoved

  const mutation = wip.id ? 'update' : 'create'

  let input = {
    tenant,
    ...wip,
    updatedBy: email,
  }

  if (mutation === 'create') {
    input.createdBy = email
  }

  if (fieldsToBeRemoved.length > 0) {
    input.customFields =
      input.customFields?.length > 0 ? input.customFields.filter((e) => !fieldsToBeRemoved.includes(e.id)) : undefined
  }

  await mutateEntity({
    entity: 'Profile',
    dispatch,
    input,
    query: mutation === 'update' ? updateProfile : createProfile,
  })
}

export const setProfileWIP = (id) => {
  return { type: 'SET_PROFILE_WIP', id }
}

export const deleteProfiles =
  ({ ids }) =>
  async (dispatch) => {
    try {
      for (const id of ids) {
        dispatch({ type: 'DELETE_PROFILES_START', id })
        await API.graphql({
          query: deleteProfile,
          variables: {
            input: {
              id,
            },
          },
        })
        dispatch({ type: 'DELETE_PROFILES_SUCCESS', id })
      }
    } catch (err) {
      dispatch({ type: 'DELETE_PROFILES_FAILURE' })
      console.error('error:', err)
      notifyError()
    } finally {
      notifySuccess()
    }
  }

export const subscribeProfile = (type) => (dispatch, getState) => {
  const isDelete = type === 'delete'
  const tenant = getState().login.empresa
  return API.graphql({
    query: isDelete ? onDeleteProfile : onChangeProfile,
    variables: {
      tenant,
    },
  }).subscribe({
    next: ({ provider, value }) => {
      const payload = value.data?.[isDelete ? 'onDeleteProfile' : 'onChangeProfile']
      isDelete
        ? dispatch({ type: 'DELETE_PROFILE_SUCCESS', payload })
        : dispatch({ type: 'SAVE_PROFILE_SUCCESS', payload })
    },
    error: (error) => console.warn('error', error),
  })
}

export const clearProfileForm = () => {
  return { type: 'CLEAR_PROFILE_FORM' }
}

export const setCurrentProfile = ({ id = '', payload = undefined }) => {
  return { type: 'SET_CURRENT_PROFILE', id, payload }
}
