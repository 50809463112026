/*  Author: Luís Mourão
    Type: reducer
    Description: reducer from notificationProfiles store
    To do list:
*/

import { omit } from 'lodash'
import initialStore from 'Store/initialStore'
import produce from 'immer'

const initialState = initialStore.notificationProfiles

export default function notificationProfiles(state = initialState, { id, payload = [], type }) {
  switch (type) {
    case 'FETCH_NOTIFICATION_PROFILES_START':
      return { ...state, loading: true }

    case 'FETCH_NOTIFICATION_PROFILES':
      return produce(state, (newState) => {
        payload.forEach((e) => {
          newState.byId[e.id] = e
          if (!newState.allIds.includes(e.id)) newState.allIds.push(e.id)
        })
        newState.loading = false
      })

    case 'SAVE_NOTIFICATION_PROFILE':
      return produce(state, (newState) => {
        newState.byId[id] = payload
        if (!newState.allIds.includes(id)) newState.allIds.push(id)
      })

    case 'CLEAN_NOTIFICATION_PROFILES':
      return initialState

    case 'DELETE_NOTIFICATION_PROFILE':
      return produce(state, (newState) => {
        newState.allIds = newState.allIds.filter((each) => each !== id)
        newState.byId = omit(newState.byId, [id])
      })

    default:
      return state
  }
}
