/*  Author: Bruno Melo
    Type: Const Store
    Description: All initial/empty info from store;
    To do list: -Checar se está completo
*/

import { Roles } from 'Utils/rbac'
import { DataLabelings, dataLabelings } from '../interfaces/dataLabelings'
import { Activities, activities } from 'interfaces/activities'
import { Mensurations, mensurations } from 'interfaces/mensurations'
import { Reports, reports } from 'interfaces/reports'
import { Profiles, profiles } from 'interfaces/profiles'
import { ModulesStore } from '../interfaces/modules'
import { changeLogs, ChangeLogs } from 'interfaces/changeLogs'
import { Dashboards, dashboards } from 'interfaces/dashboards'

type LoginStore = {
  authMethod?: 'msal' | 'auth0'
  loading: boolean
  empresa: string
  email: string
  perfil: Roles
  favorites: any
  favFields: any
  followMe: any
  refMap?: any
  section?: string
  id_usuario?: number
  id_empresa?: number
  id_perfil?: number
  language?: string
  connection?: string
  mapLayers: string[]
  preferences: LoginPreferences
  cudLoading: boolean
  cudSuccess: boolean
}

interface LoginPreferences {
  [key: string]: any // TODO deal with this 'any'
}

interface NormalizedObjects<T> {
  byId: { [id: string]: T | undefined }
  allIds: string[]
  groups: string[]
  current?: any
  loading?: boolean
  wip?: any
  hasError?: boolean
  errors?: {}
  success?: boolean
  cudLoading: boolean
  cudSuccess: boolean
}

type RulesActions = 'platformNotification' | 'toSendEmail' | 'app'

interface RulesItem {
  id: string
  notificationProfileId: string
  name: string
  tenant: string
  status: 'on' | 'off'
  action: RulesActions[]
  createdBy: string
  description: string
  message: string
  scope: string
  sourceType: 'assetGroup' | 'asset'
  targetType: 'fenceGroup' | 'fence'
  sendTo: string
  createdAt: string
  updatedAt: string
  targetId: string[]
  sourceId: string[]
  editable: boolean | null
}

export interface MessageItem {
  id: string
  label: string
  type: 'generic' | 'app' | 'web' | 'email' | 'smartphone'
  title: string | null
  body: string | null
  extra?: string | null
  tags?: string | null
}

type RulesStore = NormalizedObjects<RulesItem>
type MessageStore = NormalizedObjects<MessageItem>
type DevicesStore = {
  id: string
  name: string
  alias: string
  serial_number: string
  modelId: number
  assets: string[]
  model: string
  info: null
  status: boolean
  mackey: string | null
  mackeyble: string | null
  uuid: string | null
  state: number
  createdAt: string
  cudLoading: boolean
  cudSuccess: boolean
}
export interface RootStore {
  assets: any //TODO: add types,
  measures: any
  login: LoginStore
  toggle: any //TODO: add types,
  projects: any //TODO: add types,
  people: any //TODO: add types,
  indoor: any //TODO: add types,
  filters: any //TODO: add types,
  history: any //TODO: add types,
  notification: any //TODO: add types,
  system: any //TODO: add types,
  devices: NormalizedObjects<DevicesStore> //TODO: add types,
  rules: RulesStore
  messages: MessageStore
  interactions: any //TODO: add types,
  dashboardStellantis: any //TODO: add types,
  dashboards: any //TODO: add types,
  notificationProfiles: any //TODO: add types,
  cards: object //TODO: add types (probably object)
  tables: any //TODO: add types,
  modules: ModulesStore
  locationHist: any
  tenants: {
    available: unknown[]
    current: { id: number; name: string; label: string; info: any; features: any }
  }
  dataLabelings: DataLabelings
  activities: Activities
  mensurations: Mensurations
  reports: Reports
  profiles: Profiles
  changeLogs: ChangeLogs
  betaDashboards: Dashboards
}

let initialStore: RootStore = {
  assets: {
    byId: {},
    allIds: [],
    groups: [],
    current: {},
    allActiveIds: [],
    allActivePeopleIds: [],
    allActiveAssetsIds: [],
    success: false, // 'Read' here
    cudSuccess: false, // CRUD without 'Read' ↑
    loading: false,
    cudLoading: false, // check above ↑
  },
  measures: {
    allIds: [],
    byId: {},
    current: {
      allCategories: [],
      byCategory: {},
    },
    loadingCategories: false,
    loadingCategoryMeasures: false,
    cudLoading: false,
    cudSuccess: false,
    timeActiveAssets: [],
  },
  login: {
    loading: true,
    empresa: '',
    email: '',
    perfil: '',
    favorites: [],
    favFields: [],
    followMe: [],
    connection: 'connected',
    mapLayers: [],
    preferences: {
      routesPanels: {
        allIds: [
          'activities',
          'dataLabelings',
          'insights',
          'people',
          'interactions',
          'assets',
          'associations',
          'devices',
          'firefighter',
          'measuresHist',
          'locationHist',
          'units',
          'fences',
          'refs',
          'messages',
          'simulations',
          'notificationProfiles',
          'notifications',
          'rules',
          'phases',
          'motionChart',
          'reports',
          'dashboard',
          'mensurations',
          'betaReports',
          'preferences',
          'types',
          'accessControl',
          'dashboards',
          // 'driver',
        ],
        byId: {
          people: {
            id: 'people',
            icon: 'user',
            label: 'Pessoas',
            hiddenColumns: ['id', 'location', 'info.matricula', 'info.createdBy', 'info.createdAt'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/people',
          },
          interactions: {
            id: 'interactions',
            icon: 'group',
            label: 'Interações',
            hiddenColumns: [],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/interactions',
          },
          accessControl: {
            id: 'accessControl',
            icon: 'lock',
            label: 'Controle de Acesso',
            hiddenColumns: [],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/accessControl',
          },
          assets: {
            id: 'assets',
            icon: 'truck',
            label: 'Ativos',
            hiddenColumns: ['location', 'info.matricula', 'info.createdBy', 'info.createdAt'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/assets',
          },
          associations: {
            id: 'associations',
            icon: 'connectdevelop',
            label: 'Associações',
            hiddenColumns: ['assetId', 'deviceId'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/associations',
          },
          devices: {
            id: 'devices',
            icon: 'microchip',
            label: 'Dispositivos',
            hiddenColumns: [
              'id',
              'alias',
              'info.description',
              'mackey',
              'mackeyble',
              'info.destino',
              'info.saida',
              'info.modo',
              'info.connectivity',
              'comments',
              'info.devaddr',
              'info.responsavel',
              'info.model_info.fw_version',
              'info.mac',
            ],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/devices',
          },
          firefighter: {
            id: 'firefighter',
            icon: 'fire extinguisher',
            label: 'Brigadistas',
            hiddenColumns: [],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/firefighter',
            blank: true,
          },
          measuresHist: {
            id: 'measuresHist',
            icon: 'history',
            label: 'Medidas',
            type: 'permanent',
            filters: [
              {
                id: 'relativeTime',
                icon: 'calendar',
                title: 'Intervalo',
                description: 'Selecione o Intervalo de Tempo',
                value: [],
              },
              { id: 'asset', icon: 'user', title: 'Pessoa/Ativo', description: 'Selecione o Ativo', value: [] },
              { id: 'category', icon: 'sitemap', title: 'Categoria', description: 'Selecione a Categoria', value: [] },
              { id: 'measure', icon: 'info circle', title: 'Medida', description: 'Selecione a Medida', value: [] },
            ],
            route: '/measuresHist',
          },
          fences: {
            id: 'fences',
            icon: 'th',
            label: 'Perímetros',
            hiddenColumns: ['id'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/fences',
          },
          locationHist: {
            id: 'locationHist',
            icon: 'history',
            label: 'Histórico de Locais',
            hiddenColumns: ['assetId', 'deviceId'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/locationHist',
          },

          units: {
            id: 'units',
            icon: 'map',
            label: 'Plantas',
            hiddenColumns: ['id'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/units',
          },
          refs: {
            id: 'refs',
            icon: 'map marker alternate',
            label: 'Referências',
            hiddenColumns: ['id', 'info.properties.description'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/refs',
          },
          simulations: {
            id: 'simulations',
            icon: 'envelope',
            label: 'Simulações',
            hiddenColumns: [],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/simulations',
          },
          messages: {
            id: 'messages',
            icon: 'envelope',
            label: 'Mensagens',
            hiddenColumns: ['status', 'extra', 'updatedBy', 'updatedFrom', 'createdBy', 'createdFrom'],
            groupedValuesColumns: ['simId'],
            type: 'permanent',
            route: '/messages',
          },
          notificationProfiles: {
            id: 'notificationProfiles',
            icon: 'warning',
            label: 'Perfis',
            hiddenColumns: ['vibration', 'description', 'id'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/notificationProfiles',
          }, // vibrations is not listed as a possible column for this table
          notifications: {
            id: 'notifications',
            icon: 'bell outline',
            label: 'Notificações',
            hiddenColumns: ['id'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/notifications',
          },
          rules: {
            id: 'rules',
            icon: 'alarm',
            label: 'Regras',
            hiddenColumns: ['description', 'message', 'sendTo'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/rules',
          },
          phases: {
            id: 'phases',
            icon: 'configure',
            label: 'Fases',
            type: 'permanent',
            route: '/phases',
          },
          insights: {
            id: 'insights',
            icon: 'dashboard',
            label: 'Insights',
            hiddenColumns: [],
            type: 'permanent',
            blank: 'true',
            route: '/insights',
          },
          dashboard: {
            id: 'dashboard',
            icon: 'dashboard',
            label: 'Dashboard',
            hiddenColumns: [],
            type: 'permanent',
            route: '/dashboard',
            staticMode: false,
            blank: true,
            layout: [
              { i: '0', x: 0, y: 0, w: 3, h: 7, minW: 2, minH: 3 },
              { i: '1', x: 3, y: 0, w: 3, h: 7, minW: 2, minH: 3 },
              { i: '2', x: 6, y: 0, w: 5, h: 7, minW: 2, minH: 3 },
              { i: '3', x: 0, y: 8, w: 3, h: 7, minW: 2, minH: 3 },
              { i: '4', x: 3, y: 8, w: 3, h: 7, minW: 2, minH: 3 },
              { i: '5', x: 6, y: 8, w: 5, h: 7, minW: 2, minH: 3 },
              { i: '6', x: 0, y: 16, w: 3, h: 7, minW: 2, minH: 3 },
              { i: '7', x: 3, y: 16, w: 3, h: 7, minW: 2, minH: 3 },
              // { i: '8', x: 6, y: 16, w: 5, h: 7, minW: 2, minH: 3 },
              // { i: '9', x: 0, y: 24, w: 3, h: 7, minW: 2, minH: 3 },
              // { i: '10', x: 3, y: 24, w: 3, h: 7, minW: 2, minH: 3 },
              // { i: '11', x: 6, y: 24, w: 5, h: 7, minW: 2, minH: 3 },
              // { i: '12', x: 0, y: 32, w: 3, h: 7, minW: 2, minH: 3 },
              // { i: '13', x: 3, y: 32, w: 3, h: 7, minW: 2, minH: 3 },
              // { i: '14', x: 6, y: 32, w: 5, h: 7, minW: 2, minH: 3 },
              // { i: '15', x: 0, y: 40, w: 3, h: 7, minW: 2, minH: 3 },
            ],
            panelsIds: [
              'panel01',
              'panel02',
              'panel03',
              'panel04',
              'panel05',
              'panel06',
              'panel07',
              'panel08',
              // 'panel09',
              // 'panel10',
              // 'panel11',
              // 'panel12',
              // 'panel13',
              // 'panel14',
              // 'panel15',
              // 'panel16',
            ],
            panelsById: {
              panel01: {
                id: 'panel01',
                key: 'panel01',
                label: 'Pessoas no Site por Atribuição',
                resource: '29829',
                filters: {
                  field: '',
                },
                type: 'donut',
                description: '',
                static: false,
                time: 'rtm',
              },
              panel02: {
                id: 'panel02',
                key: 'panel02',
                label: 'Pessoas no Site por Perímetro',
                resource: '29821',
                filters: {},
                order: 2,
                type: 'bars',
                description: '',
                static: false,
                time: 'rtm',
              },
              panel03: {
                id: 'panel03',
                key: 'panel03',
                label: 'Pessoas no Site por Perímetro',
                resource: '29821',
                filters: {},
                order: 3,
                type: 'bars',
                cards: [
                  {
                    id: 'card1',
                    label: 'Quantidade de ',
                    assignment: '',
                    order: 1,
                    goal: 1,
                    filters: {
                      relativeTime: 'RT',
                    },
                  },
                  {
                    id: 'card2',
                    label: 'Quantidade de ',
                    assignment: '',
                    order: 2,
                    goal: 1,
                    filters: {
                      relativeTime: 'RT',
                    },
                  },
                  {
                    id: 'card3',
                    label: 'Quantidade de ',
                    assignment: '',
                    order: 3,
                    goal: 1,
                    filters: {
                      relativeTime: 'RT',
                    },
                  },
                  {
                    id: 'card4',
                    label: 'Quantidade de ',
                    assignment: '',
                    order: 4,
                    goal: 1,
                    filters: {
                      relativeTime: 'RT',
                    },
                  },
                  {
                    id: 'card5',
                    label: 'Quantidade de ',
                    assignment: '',
                    order: 5,
                    goal: 1,
                    filters: {
                      relativeTime: 'RT',
                    },
                  },
                  {
                    id: 'card6',
                    label: 'Quantidade de ',
                    assignment: '',
                    order: 6,
                    goal: 1,
                    filters: {
                      relativeTime: 'RT',
                    },
                  },
                ],
                time: 'rel',
              },
              panel04: {
                id: 'panel04',
                key: 'panel04',
                label: 'Pessoas no Site por Status',
                resource: '29830',
                filters: {},
                order: 1,
                type: 'donut',
                time: 'rtm',
              },
              panel05: {
                id: 'panel05',
                key: 'panel05',
                label: 'Pessoas no Site por Dispositivo',
                resource: '29831',
                filters: {},
                order: 1,
                type: 'donut',
                startTime: '2022-03-01T11:14',
                time: 'rtm',
                endTime: '2023-03-31T11:15',
                timeRelative: '7d',
              },
              panel06: {
                id: 'panel06',
                key: 'panel06',
                label: 'Uso do Dispositivo',
                resource: '29831',
                filters: {},
                order: 1,
                type: 'line',
                startTime: '2022-10-01T07:13',
                time: 'abs',
                endTime: '2023-03-31T07:13',
              },
              panel07: {
                id: 'panel07',
                key: 'panel07',
                label: 'Violação Regras pela Vida',
                resource: '29821',
                filters: {},
                order: 1,
                type: 'pie',
                time: 'rel',
              },
              panel08: {
                id: 'panel08',
                key: 'panel08',
                label: 'Acesso Perímetro Indevido',
                resource: '29831',
                filters: {},
                order: 1,
                type: 'donut',
                time: 'rel',
              },
            },
          },
          manualYamazumi: {
            id: 'manualYamazumi',
            icon: 'dashboard',
            label: 'manualYamazumi',
            defaultLabel: 'default_manualYamazumi',
            type: 'permanent',
            route: '/manualYamazumi',
          },
          automaticYamazumi: {
            id: 'automaticYamazumi',
            icon: 'dashboard',
            label: 'automaticYamazumi',
            defaultLabel: 'default_automaticYamazumi',
            type: 'permanent',
            route: '/automaticYamazumi',
          },
          cycleTimeMeasurement: {
            id: 'cycleTimeMeasurement',
            icon: 'dashboard',
            label: 'cycleTimeMeasurement',
            defaultLabel: 'default_cycleTimeMeasurement',
            type: 'permanent',
            route: '/cycleTimeMeasurement',
          },
          motionChart: {
            id: 'motionChart',
            icon: 'dashboard',
            label: 'Movimentos',
            defaultLabel: 'default_motionChart',
            type: 'permanent',
            route: '/motionChart',
          },
          preferences: {
            id: 'preferences',
            icon: 'settings',
            label: 'Preferências',
            defaultLabel: 'default_preferences',
            type: 'permanent',
            route: '/preferences',
          },
          reports: {
            id: 'reports',
            icon: 'clipboard list',
            label: 'Relatórios',
            defaultLabel: 'default_reports',
            type: 'permanent',
            route: '/reports',
            reportsAllIds: [],
            reportsById: {},
          },
          betaReports: {
            id: 'betaReports',
            icon: 'download',
            label: 'Relatórios (Beta)',
            defaultLabel: 'default_reports',
            type: 'permanent',
            route: '/betaReports',
            reportsAllIds: [],
            reportsById: {},
          },
          dashboards: {
            id: 'dashboards',
            path: 'dashboards/:code?',
            icon: 'chart bar',
            label: 'Dashboards (Beta)',
            defaultLabel: 'default_dashboards',
            type: 'permanent',
            route: '/dashboards',
          },
          activities: {
            id: 'activities',
            icon: 'child',
            label: 'Atividades',
            type: 'permanent',
            route: '/activities',
          },
          dataLabelings: {
            id: 'dataLabelings',
            icon: 'line graph',
            label: 'AI Maker',
            type: 'permanent',
            route: '/dataLabelings',
            hiddenColumns: ['id'],
          },
          mensurations: {
            id: 'mensurations',
            icon: 'stopwatch',
            label: 'Mensurações',
            type: 'permanent',
            route: '/mensurations',
          },
          driver: {
            id: 'driver',
            icon: 'line graph',
            label: 'Direcionador',
            type: 'permanent',
            route: '/driver',
          },
          types: {
            id: 'types',
            icon: 'user md',
            label: 'Atribuições',
            hiddenColumns: ['id', 'info.description'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/types',
          },
        },
      },
      center: { lat: -12.46876, lng: -62.797851 },
      zoom: 4,
      mapLayers: [],
      refs: [],
      fences: [],
      routes: [],
      indoor: [],
    },
    cudLoading: false,
    cudSuccess: false,
  },
  tenants: {
    available: [],
    current: {
      id: 0,
      name: '',
      label: '',
      info: {},
      features: [],
    },
  },
  toggle: {
    confirmScreen: false,
    deviceEditMode: false,
    fencesEditionMode: false,
    HistMoreThan1Day: false,
    historyFlag: true,
    loadingScreen: false,
    mapDragging: true,
    markerNew: false,
    missingStatsBarSave: false,
    missingStatsBarSaveGeoJson: false,
    modalScreen: false,
    refreshFencesDashboard: false,
    showConfig: false,
    showDashboard: false,
    showDiscreteAntpath: false,
    showDividerAntpath: true,
    showDropdownNotification: false,
    showFitBounds: false,
    showHeatMap: false,
    showHistoryCalendar: true,
    showHistorySteps: false,
    showInnerMap: false,
    showIntegerAntpath: false,
    showPolyline: true,
    showRawHist: false,
    showRouteByProximity: true,
    showStaticFilter: false,
    showStatsBar: false,
    showStatsBarImage: false,
    sideBar: false,
    updateIndoor: false,
    displayingMap: false,
    uploadModal: false,
    offline: false,
    openPDFModal: false,
    showTableColumnsModal: false,
    showFirstInteraction: true,
    showEmergencyModal: false,
  },
  projects: [],
  people: {
    cudLoading: false,
    cudSuccess: false,
  },
  indoor: {
    items: [],
    current: {},
    cudLoading: false,
    cudSuccess: false,
  },
  filters: {
    searchValue: '',
    searchField: '',
    searchText: '',
    timeslider: {
      min: 0,
      max: 0,
      total: 1,
    },
    search: [],
    result: [],
  },
  history: {
    byId: {},
    allIds: [],
    allAssetsFilteredIndexes: [],
    current: [],
    allDataIds: [],
    showModal: false,
    popup: {},
    loading: false,
    loadingCategories: false,
    loadingCategoryMeasures: false,
    success: false,
    error: false,
    type: undefined,
    slider: {
      min: 0,
      max: 0,
    },
    size: 0,
    holes: { items: [], percentiles: [], currentGeoHash: '' },
    motion: {
      byId: {},
      allIds: [],
    },
  },
  notification: {
    byId: {},
    allIds: [],
    array: [],
    assetIds: [],
    currentPage: 0,
    isFetching: true,
    initialFetch: true,
    allActiveNotifications: [],
    cudLoading: false,
    cudSuccess: false,
    assetsInEmergency: [],
    emergencyLoading: false,
    emergencyNotificationByFenceId: {},
    sound: { playing: false },
  },
  system: {
    confirm: {},
    modal: {},
    loadingProgress: 0,
  },
  modules: {
    fences: {
      byId: {},
      allIds: [],
      groups: [],
      cudLoading: false,
      cudSuccess: false,
    },
    refs: {
      byId: {},
      allIds: [],
      groups: [],
      cudLoading: false,
      cudSuccess: false,
    },
    routes: {
      byId: {},
      allIds: [],
      groups: [],
      cudLoading: false,
      cudSuccess: false,
    },
    reports: {
      byId: {},
      allIds: [],
      groups: [],
      current: {},
      cudLoading: false,
      cudSuccess: false,
    },
    units: {
      byId: {},
      allIds: [],
      groups: [],
      current: {},
      cudLoading: false,
      cudSuccess: false,
    },
    wip: {},
    cudLoading: false,
    cudSuccess: false,
    lastFetchTime: '',
  },
  devices: {
    byId: {},
    allIds: [],
    groups: [],
    cudLoading: false,
    cudSuccess: false,
  },
  rules: {
    byId: {},
    allIds: [],
    groups: [],
    loading: false,
    wip: {},
    errors: {},
    cudLoading: false,
    cudSuccess: false,
  },
  messages: {
    byId: {},
    allIds: [],
    groups: [],
    loading: false,
    cudLoading: false,
    cudSuccess: false,
  },
  interactions: {
    byId: {},
    allIds: [],
    cudLoading: false,
    cudSuccess: false,
  },
  dashboardStellantis: {
    charts: {},
    cards: {},
  },
  dashboards: {
    allIds: ['dashboard', 'rules', 'people', 'fences', 'activity', 'firefighter'],
    byId: {
      dashboard: {
        id: '-1',
        name: 'Dashboard',
        description: 'Dashboard interativo e Personalizável por Usuário',
        createdBy: 'bruno@phygitall.rio',
        createdAt: '2021-03-02T08:00:00.173Z',
        status: 'on',
        type: 'default',
        defaultLayout: {
          id: 'dashboard',
          icon: 'dashboard',
          label: 'Dashboard',
          hiddenColumns: [],
          type: 'permanent',
          route: '/dashboard',
          staticMode: false,
          blank: true,
          layout: [
            { i: '0', x: 0, y: 0, w: 3, h: 10, minW: 2, minH: 3 },
            { i: '1', x: 3, y: 0, w: 3, h: 10, minW: 2, minH: 3 },
            { i: '2', x: 6, y: 0, w: 5, h: 10, minW: 2, minH: 3 },
            { i: '3', x: 0, y: 11, w: 3, h: 10, minW: 2, minH: 3 },
            { i: '4', x: 3, y: 11, w: 3, h: 10, minW: 2, minH: 3 },
            { i: '5', x: 6, y: 11, w: 5, h: 10, minW: 2, minH: 3 },
          ],
          panelsIds: ['panel01', 'panel02', 'panel03', 'panel04', 'panel05', 'panel06'],
          panelsById: {
            panel01: {
              id: 'panel01',
              key: 'panel01',
              label: 'Pessoas no Site por Atribuição',
              resource: '29829',
              filters: {
                field: '',
              },
              type: 'donut',
              description: '',
              static: false,
              time: 'rtm',
            },
            panel02: {
              id: 'panel02',
              key: 'panel02',
              label: 'Pessoas no Site por Perímetro',
              resource: '29821',
              filters: {},
              order: 2,
              type: 'bars',
              description: '',
              static: false,
              time: 'rtm',
            },
            panel03: {
              id: 'panel03',
              key: 'panel03',
              label: "KPI's",
              resource: 'kpis',
              filters: {},
              order: 3,
              type: 'bars',
              nCards: '6',
              description: "KPI's da empresa",
              colCards: '3',
              panelType: 'kpis',
              cards: [
                {
                  id: 'card1',
                  label: 'Quantidade de ',
                  assignment: '',
                  order: 1,
                  goal: 1,
                  filters: {
                    relativeTime: 'RT',
                  },
                },
                {
                  id: 'card2',
                  label: 'Quantidade de ',
                  assignment: '',
                  order: 2,
                  goal: 1,
                  filters: {
                    relativeTime: 'RT',
                  },
                },
                {
                  id: 'card3',
                  label: 'Quantidade de ',
                  assignment: '',
                  order: 3,
                  goal: 1,
                  filters: {
                    relativeTime: 'RT',
                  },
                },
                {
                  id: 'card4',
                  label: 'Quantidade de ',
                  assignment: '',
                  order: 4,
                  goal: 1,
                  filters: {
                    relativeTime: 'RT',
                  },
                },
                {
                  id: 'card5',
                  label: 'Quantidade de ',
                  assignment: '',
                  order: 5,
                  goal: 1,
                  filters: {
                    relativeTime: 'RT',
                  },
                },
                {
                  id: 'card6',
                  label: 'Quantidade de ',
                  assignment: '',
                  order: 6,
                  goal: 1,
                  filters: {
                    relativeTime: 'RT',
                  },
                },
              ],
              time: 'rel',
            },
            panel04: {
              id: 'panel04',
              key: 'panel04',
              label: 'Pessoas no Site por Status',
              resource: '29830',
              filters: {},
              order: 1,
              type: 'donut',
              time: 'rtm',
            },
            panel05: {
              id: 'panel05',
              key: 'panel05',
              label: 'Pessoas no Site por Dispositivo',
              resource: '29831',
              filters: {},
              order: 1,
              type: 'donut',
              startTime: '2022-03-01T11:14',
              time: 'rtm',
              endTime: '2023-03-31T11:15',
              timeRelative: '7d',
            },
            panel06: {
              id: 'panel06',
              key: 'panel06',
              label: 'Uso do Dispositivo',
              resource: '29831',
              filters: {},
              order: 1,
              type: 'line',
              startTime: '2022-10-01T07:13',
              time: 'abs',
              endTime: '2023-03-31T07:13',
            },
          },
        },
        pannelAllIds: [
          'panel01',
          'panel02',
          'panel03',
          'panel04',
          'panel05',
          'panel06',
          'panel07',
          'panel08',
          'panel09',
          'panel10',
          'panel11',
          'panel12',
          'panel13',
          'panel14',
          'panel15',
          'panel16',
        ],
        pannelsById: {
          panel01: {
            data: [],
            loading: true,
          },
          panel02: {
            data: [],
            loading: true,
          },
          panel03: {
            data: [],
            loading: true,
          },
          panel04: {
            data: [],
            loading: true,
          },
          panel05: {
            data: [],
            loading: true,
          },
          panel06: {
            data: [],
            loading: true,
          },
          panel07: {
            data: [],
            loading: true,
          },
          panel08: {
            data: [],
            loading: true,
          },
          panel09: {
            data: [],
            loading: true,
          },
          panel10: {
            data: [],
            loading: true,
          },
          panel11: {
            data: [],
            loading: true,
          },
          panel12: {
            data: [],
            loading: true,
          },
          panel13: {
            data: [],
            loading: true,
          },
          panel14: {
            data: [],
            loading: true,
          },
          panel15: {
            data: [],
            loading: true,
          },
          panel16: {
            data: [],
            loading: true,
          },
        },
      },

      rules: {
        id: '0',
        name: 'Regras',
        description: 'Estatísticas gerais sobre Regras',
        createdBy: 'luis@phygitall.rio',
        createdAt: '2021-05-20T16:54:17.173Z',
        selected: [],
        status: 'on',
        type: 'default',
        updateInterval: 30,
        params: {
          category: 'rulesAnalytics',
        },
        resource: 'assets/measures',
        cards: {
          byId: {},
        },
      },
      fences: {
        id: '1',
        name: 'Perímetros',
        description: 'Estatísticas gerais sobre Perímetros',
        createdBy: 'luis@phygitall.rio',
        createdAt: '2021-07-22T17:14:17.173Z',
        selected: [],
        status: 'on',
        updateInterval: 30,
        params: {
          category: 'fencesLocation',
        },
        byTime: {},
      },
      activity: {
        id: '2',
        name: 'Dashboard',
        description: 'Estatísticas gerais sobre os Ciclos de Operação',
        createdBy: 'luis@phygitall.rio',
        createdAt: '2021-06-22T17:14:17.173Z',
        status: 'on',
        updateInterval: 30,
      },
      people: {
        id: '3',
        name: 'Pessoas',
        description: 'Dados sobre as pessoas atualmente na planta',
        createdBy: 'bruno@phygitall.rio',
        createdAt: '2022-02-01T13:14:17.173Z',
      },
      firefighter: {
        id: '4',
        name: 'Brigadistas',
        description: 'Status de Presença dos Brigadistas',
        createdBy: 'matheus@phygitall.rio',
        createdAt: '2022-01-27T16:30:00.000Z',
        status: 'on',
        updateInterval: 30,
      },
    },
    currentId: 'dashboard',
    filter: '',
    loading: false,
    timeAgo: 'realTime',
  },
  notificationProfiles: {
    byId: {},
    allIds: [],
    loading: true,
    cudLoading: false,
    cudSuccess: false,
  },
  cards: {
    byId: {
      devices: {
        title: 'devicesTotal',
        typesIds: ['app', 'sb'],
        typesTotal: {
          app: {
            counter: 0,
            icon: 'mobile alternate',
            color: 'black',
            label: 'APP',
            tooltip: 'appCardDevicesTip',
            size: 'mini',
          },
          sb: {
            counter: 0,
            icon: 'mobile',
            color: 'black',
            label: 'S.B.',
            tooltip: 'sbCardDevicesTip',
            size: 'mini',
          },
        },
        statusIds: [
          {
            order: 0,
            id: 'inUse',
            label: 'inUse',
            tooltip: 'deviceInUse',
            color: 'blue',
            icon: 'device-full',
          },
          {
            order: 1,
            id: 'charging',
            label: 'charging',
            tooltip: 'deviceCharging',
            color: '#FFBF00',
            icon: 'device-charging',
          },
          {
            order: 2,
            id: 'available',
            label: 'available',
            tooltip: 'deviceAvailable',
            color: 'green',
            icon: 'device-discharge',
          },
          {
            order: 3,
            id: 'disconnected',
            label: 'disconnected',
            tooltip: 'deviceDisconnnected',
            color: 'red',
            icon: 'device-unknown',
          },
        ],
        statusCounter: {
          inUse: { app: 0, sb: 0 },
          charging: { app: '----', sb: 0 },
          available: { app: '----', sb: 0 },
          disconnected: { app: 0, sb: 0 },
        },
      },
    },
    allIds: ['lives', 'devices'],
    loading: true,
  },
  tables: {
    allIds: [
      'people',
      'peopleType',
      'associations',
      'devices',
      'assets',
      'fences',
      //'indoor',
      'refs',
      'rules',
      'messages',
      'notificationProfiles',
      'simulations',
      'insights',
      'firefighter',
      'locationHist',
      'logouts',
      'measuresHist',
      'interactions',
      'units',
      'motionChart',
      'reports',
      'dashboard',
      'dataLabelings',
      'activities',
      'mensurations',
      'betaReports',
      'accessControl',
    ],
    byId: {
      people: {
        label: 'Pessoas',
        icon: 'user',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'groups', 'columns', 'delete', 'export', 'refresh'],
      },
      accessControl: {
        icon: 'shield alternate',
        label: 'Controle de Acesso',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['columns', 'calendar', 'export'],
      },
      assets: {
        label: 'Ativos',
        icon: 'truck',
        autoUpdate: true,
        loading: false,
        showModal: false,
        allActivePeopleIds: [],
        allActiveAssetsIds: [],
        menuButtons: ['new', 'edit', 'groups', 'columns', 'delete', 'export', 'refresh'],
      },
      locationHist: {
        label: 'locationHist',
        icon: 'history',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['columns', 'export', 'refresh'],
      },
      logouts: {
        label: 'Logout',
        icon: 'log out',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['columns', 'export', 'refresh'],
      },
      devices: {
        label: 'Dispositivos',
        icon: 'microchip',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['provision', 'edit', 'columns', 'export', 'refresh'],
      },
      firefighter: {
        label: 'Brigadistas',
        icon: 'fire extinguisher',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['export', 'toggle fullscreen'],
      },
      measuresHist: {
        data: [],
        label: 'measuresHist',
        icon: 'history',
        autoUpdate: true,
        loading: false,
        showModal: false,
        // menuButtons: ['histFilter', 'export', 'refresh', 'saveMeasuresHist', 'viewOptions'],
        categoriesOptions: [],
        assetsOptions: [],
        measuresOptions: [],
        selectedRelativeTime: [],
        selectedAsset: [],
        selectedCategory: [],
        selectedMeasures: [],
        showSaveModal: false,
        filters: [
          {
            id: 'relativeTime',
            iconName: 'calendar',
            title: 'Intervalo',
            description: 'Selecione o Intervalo de Tempo',
          },
          { id: 'asset', iconName: 'user', title: 'Pessoa/Ativo', description: 'Selecione o Ativo' },
          { id: 'category', iconName: 'sitemap', title: 'Categoria', description: 'Selecione a Categoria' },
          { id: 'measure', iconName: 'info circle', title: 'Medida', description: 'Selecione a Medida' },
        ],
        currentFilterStep: 0,
        filterStepLoading: undefined,
        filterStepError: undefined,
      },
      historySteps: {
        data: [],
        label: 'historySteps',
        icon: 'history',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: [],
        categoriesOptions: [],
        assetsOptions: [],
        measuresOptions: [],
        selectedRelativeTime: [],
        selectedAsset: [],
        selectedCategory: [],
        selectedMeasures: [],
        showSaveModal: false,
        filters: [
          {
            id: 'relativeTime',
            iconName: 'calendar',
            title: 'Intervalo',
            description: 'Selecione o Intervalo de Tempo',
          },
          { id: 'asset', iconName: 'user', title: 'Pessoa/Ativo', description: 'Selecione o Ativo' },
          {
            id: 'category',
            iconName: 'sitemap',
            title: 'Tipo de Ocorrência',
            description: 'Selecione o tipo de Ocorrência',
          },
        ],
        currentFilterStep: 0,
        filterStepLoading: undefined,
        filterStepError: undefined,
      },
      units: {
        label: 'Plantas',
        icon: 'map',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'groups', 'columns', 'delete', 'export', 'refresh'],
      },
      indoor: {
        label: 'Plantas',
        icon: 'map',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'groups', 'columns', 'delete', 'export', 'refresh'],
      },
      refs: {
        label: 'Referências',
        icon: 'map marker alternate',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['columns', 'delete', 'details', 'export'],
      },
      messages: {
        label: 'Mensagens',
        icon: 'envelope',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'columns', 'delete', 'import', 'export', 'refresh'],
      },
      notificationProfiles: {
        label: 'Perfis',
        icon: 'warning',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'columns', 'delete', 'export'],
      },
      rules: {
        label: 'Regras',
        icon: 'alarm',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'columns', 'delete', 'export'],
      },
      fences: {
        label: 'Perímetros',
        icon: 'th',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['rules', 'columns', 'delete', 'import', 'export', 'details'],
      },
      insights: {
        label: 'Insights',
        icon: 'dashboard',
        autoUpdate: true,
        loading: false,
        showModal: false,
        // insights is somewhat complex, so I'll deal with the menuButtons later
        // menuButtons: ['export', 'time', 'refresh'],
      },
      dashboard: {
        label: 'Dashboard',
        icon: 'dashboard',
        autoUpdate: true,
        loading: true,
        showModal: false,
        menuButtons: ['toggle fullscreen'],
      },
      reports: {
        label: 'Relatórios',
        icon: 'download',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: null, // the reports tab only has menuButtons when a report has been requested
        fetchLoading: false,
        fetchSuccess: false,
        showDateModal: false,
        reportType: '',
        reportData: '',
        reportColumns: [],
        reportPayload: '',
      },
      betaReports: {
        label: 'Relatórios (Beta)',
        icon: 'download',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: null, // the reports tab only has menuButtons when a report has been requested
        fetchLoading: false,
        fetchSuccess: false,
        showDateModal: false,
        reportType: '',
        reportData: '',
        reportColumns: [],
        reportPayload: '',
      },
      interactions: {
        label: 'Interações',
        icon: 'group',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['columns', 'export', 'refresh'],
      },
      notifications: {
        byId: {},
        allIds: [],
        label: 'Notifications',
        autoUpdate: true,
        loading: false,
        menuButtons: ['columns', 'calendar', 'export'],
      },
      preferences: {
        label: 'Preferências',
        icon: 'settings',
        showModal: false,
        menuButtons: [],
      },

      // the next tabs are not present in the PhygitallDevs tenant
      manualYamazumi: {
        label: 'manualYamazumi',
        icon: 'dashboard',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['export', 'time', 'shift', 'carModel'],
      },
      automaticYamazumi: {
        label: 'automaticYamazumi',
        icon: 'dashboard',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['export', 'time', 'shift', 'carModel'],
      },
      cycleTimeMeasurement: {
        label: 'cycleTimeMeasurement',
        icon: 'dashboard',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: [], // this tab actually had all the buttons, but I think it can be empty for now
      },
      motionChart: {
        label: 'Movimentos',
        icon: 'dashboard',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['toggle fullscreen'],
        selectedAssets: [],
      },
      dataLabelings: {
        label: 'SW&K',
        icon: 'dashboard',
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'labels', 'columns', 'delete', 'resume', 'mensurations', 'driver'],
      },
      activities: {
        label: 'Atividades',
        icon: 'child',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'delete', 'columns', 'export'],
      },
      mensurations: {
        label: 'Mensurações',
        icon: 'stopwatch',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: null,
      },
    },
    current: { autoUpdate: true, tab: '' },
    error: null,
  },
  locationHist: [],
  dataLabelings: dataLabelings,
  activities: activities,
  mensurations: mensurations,
  reports,
  profiles,
  changeLogs,
  betaDashboards: dashboards,
}

export default initialStore
