/*  Author: Luís Mourão
  Description: Rules Form to Create and Edit		 
    To do list: 
    - Use auto generated schema.json to control required ruleWip
    - Split Component into Identification/Condition/Action parts	
    - Create rule WIP on store to maintain the current ruleWip state
    - Refactor editId, currentId and useEffect logics
*/

import React, { useEffect, useState } from 'react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { Input, Form, Dropdown, Transition, Divider, Header, Label } from 'semantic-ui-react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { fetchNotificationProfiles } from 'Store/actions/notificationProfiles-action'
import { saveRule, setCurrentId, setRuleWip, setRulesErrors } from 'Store/actions/rules-action'
import { setAutoUpdate, setShowModal } from 'Store/actions/tables-action'
import styled from 'styled-components'
import ActionRuleFields from './ActionRuleFields'
import { fetchMessages } from 'Store/actions/messages-action'
import { union } from 'lodash'

export default function RulesForm({ addOpen, confirmAction, handleCancel, editId }) {
  const dispatch = useDispatch()

  const currentId = useSelector((state) => state.rules.currentId)
  const assetsAllIds = useSelector((state) => state.assets.allIds, shallowEqual)
  const assetsById = useSelector((state) => state.assets.byId, shallowEqual)
  const assetsGroup = useSelector((state) => state.assets.groups, shallowEqual)
  const fences = useSelector((state) => state.modules.fences, shallowEqual)
  const rulesIds = useSelector((state) => state.rules.allIds, shallowEqual)
  const refs = useSelector((state) => state.modules.refs)
  const ruleWip = useSelector((state) => state.rules.wip, shallowEqual)
  const rule = useSelector(
    (state) => (confirmAction === 'update' ? state.rules.byId[currentId] || {} : {}),
    shallowEqual
  )
  const tenant = useSelector((state) => state.login.empresa)
  const assetTypes = useSelector((state) => state.profiles, shallowEqual)
  const errors = useSelector((state) => state.rules.errors, shallowEqual)
  const cudLoading = useSelector((state) => state.tables.byId.rules.cudLoading)

  const [checkForm, setCheckForm] = useState(false)
  const sortedRefTypes = assetTypes.allIds
    .filter((tp) => assetTypes.byId[tp].type === 'refs')
    .map((id) => ({
      key: id,
      value: id,
      text: assetTypes.byId[id].name,
    }))

  useEffect(() => {
    return () => {
      dispatch(setCurrentId(null))
    }
  }, [dispatch])

  useEffect(() => {
    if (confirmAction === 'update' && editId) dispatch(setCurrentId(editId))
    if (confirmAction !== 'update') dispatch(setCurrentId(null))
  }, [editId, confirmAction, dispatch])

  useEffect(() => {
    dispatch(
      setRuleWip({
        id: rule?.id || '',
        name: rule?.name || '',
        description: rule?.description || '',
        sourceId: rule?.sourceId || [],
        sourceProp: rule?.sourceProp || '',
        sourcePropValue: rule?.sourcePropValue || '',
        sourceType: rule?.sourceType || '',
        scope: rule?.scope || '',
        targetId: rule?.targetId || [],
        targetType: rule?.targetType || '',
        targetProp: rule?.targetProp || '',
        targetPropValue: rule?.targetPropValue || [],
        threshold: rule?.threshold,
        action: rule?.action || [{ type: 'noAction' }],
        notificationProfileId: rule?.notificationProfileId || 'default',
        status: rule?.status || 'on',
        sendTo: rule?.sendTo || '',
        editable: rule?.editable || false,
      })
    )
    dispatch(fetchNotificationProfiles())
    dispatch(fetchMessages())
    dispatch(setAutoUpdate('rules', false))
    dispatch(setShowModal('rules', true))
    return () => {
      dispatch(setAutoUpdate('rules', true))
      dispatch(setShowModal('rules', false))
    }
  }, [dispatch, rule])

  async function checkValues() {
    const required = ['id', 'name', 'scope', 'notificationProfileId']
    let errors = {}
    setCheckForm(true)
    required.forEach((each) => {
      if (!ruleWip[each]) errors[each] = 'required'
    })
    if (!ruleWip.sourceType || !ruleWip.targetType) errors.type = 'required'
    if (rulesIds.includes(ruleWip.id) && confirmAction !== 'update')
      errors.id = 'Já existe uma Regra com essa Identificação'
    if (ruleWip.id?.length > 6) errors.id = 'Esse campo Permite no Máximo 6 Caracteres'
    if (!ruleWip.sourceId?.length && (!ruleWip.sourceProp || !ruleWip.sourcePropValue)) {
      errors.sourceId = 'required'
    }
    ruleWip.action.forEach((eachAction, actionIndex) => {
      if (!eachAction.messageProfileId && eachAction.type !== 'noAction') {
        if (!errors.action) errors.action = []
        if (!errors.action[actionIndex]) errors.action[actionIndex] = {}
        errors.action[actionIndex].messageProfileId = 'O Campo Mensagem é Obrigatório para cada Ação Adicionada'
      }
      if (!eachAction.type) {
        if (!errors.action) errors.action = []
        if (!errors.action[actionIndex]) errors.action[actionIndex] = {}
        errors.action[actionIndex].type = 'O Campo Ação é Obrigatório'
      }
    })

    if (Object.keys(errors).length === 0) {
      await dispatch(saveRule({ mutation: confirmAction, fields: ruleWip }))
      handleCancel()
    } else {
      dispatch(setRulesErrors(errors))
    }
  }

  const uniqueValues = {
    onBoarding() {
      return [
        {
          key: 'Sim',
          value: 'Sim',
          text: 'Sim',
        },
        {
          key: 'Não',
          value: 'Não',
          text: 'Não',
        },
      ]
    },
    atribuição() {
      const res = {}
      assetTypes.allIds
        .filter((tp) => assetTypes.byId[tp].type === 'people')
        .forEach((eachId) => {
          const value = assetTypes.byId[eachId]
          if (!res[value.name])
            res[value.name] = {
              key: eachId,
              value: eachId,
              text: value.name,
            }
        })
      return Object.values(res)
    },
    grupo() {
      return assetsGroup.map((eachGroup) => ({
        key: eachGroup,
        value: eachGroup,
        text: eachGroup,
      }))
    },
    default() {
      let res = {}
      assetsAllIds.forEach((assetId) => {
        const valueOption = assetsById[assetId].info[ruleWip.sourceProp]
        if (valueOption && !res[valueOption]) {
          res[valueOption] = {
            key: valueOption.toString(),
            value: valueOption.toString(),
            text: valueOption.toString(),
          }
        }
      })
      return Object.values(res)
    },
    id() {
      let res = []
      assetsAllIds.forEach((eachAsset) =>
        res.push({
          key: assetsById[eachAsset].id?.toString(),
          value: assetsById[eachAsset].id?.toString(),
          text: assetsById[eachAsset].name,
        })
      )
      return res
    },

    certifications() {
      const allCertifications = assetsAllIds.reduce((acc, assetId) => {
        const values = assetsById[assetId].info?.[ruleWip.sourceProp]
        if (!values || typeof values !== 'string') return acc
        const assetCertifications = values.split(',').map((value) => value.trim())
        return union(acc, assetCertifications)
      }, [])

      return allCertifications.map((certification) => ({
        key: certification,
        value: certification,
        text: certification,
      }))
    },
    sector() {
      const allSectors = assetsAllIds.reduce((acc, assetId) => {
        const sector = assetsById[assetId].info?.[ruleWip.sourceProp]
        if (!sector || typeof sector !== 'string') return acc
        if (acc.includes(sector)) return acc
        return [...acc, sector]
      }, [])

      return allSectors.map((sector) => ({
        key: sector,
        value: sector,
        text: sector,
      }))
    },
  }

  //const EDITABLE = ruleWip.editable
  //const EDITABLE = confirmAction !== 'update'
  const DISABLED = false

  return (
    <ModalTemplate
      onClose={() => handleCancel()}
      open={addOpen}
      size="large"
      header={confirmAction === 'insert' ? `Adicionar Regra` : <span>Atualizar Regra</span>}
      onSave={() => {
        checkValues()
      }}
      onCancel={() => handleCancel()}
      loading={cudLoading}
    >
      {addOpen && (
        <Form onClick={() => setCheckForm(false)}>
          <br />
          <Form.Group widths="equal">
            <FormField inline required error={checkForm && !ruleWip.id}>
              <label>ID</label>
              <Input
                name="id"
                fluid
                placeholder="Identificação Única da regra"
                value={ruleWip.id || ''}
                onChange={(e, { name, value }) => dispatch(setRuleWip({ ...ruleWip, [name]: value }))}
                disabled={confirmAction === 'update'}
              />
              {errors.id && errors.id !== 'required' && checkForm && (
                <Label color="red" pointing>
                  {errors.id}
                </Label>
              )}
            </FormField>
            <FormField inline required error={checkForm && !ruleWip.name}>
              <label>Nome</label>
              <Input
                name="name"
                fluid
                placeholder="Nome da Regra"
                value={ruleWip.name || ''}
                onChange={(e, { name, value }) => dispatch(setRuleWip({ ...ruleWip, [name]: value }))}
              />
            </FormField>
          </Form.Group>
          {/* <br/> */}
          <FormField>
            <label>Descrição</label>
            <Input
              name="description"
              fluid
              placeholder="Descrição da Regra"
              value={ruleWip.description || ''}
              onChange={(e, { name, value }) => dispatch(setRuleWip({ ...ruleWip, [name]: value }))}
            />
          </FormField>
          {confirmAction !== 'update' && (
            <FormField inline required error={checkForm && (!ruleWip.sourceType || !ruleWip.targetType)}>
              <label>Tipo</label>
              <Dropdown
                fluid
                selection
                search
                placeholder="Tipo de Regra"
                onChange={(e, { value }) => dispatch(setRuleWip({ ...ruleWip, ...JSON.parse(value) }))}
                value={JSON.stringify({ sourceType: ruleWip.sourceType, targetType: ruleWip.targetType })}
                //disabled={confirmAction === 'update'}
                options={[
                  {
                    key: 'assetProp#fence',
                    value: JSON.stringify({ sourceType: 'assetProp', targetType: 'fence' }),
                    text: ['TiplamTest', 'Tiplam', 'Phygitall'].includes(tenant)
                      ? 'Pessoa(s)/Perímetro(s)'
                      : 'Pessoa(s)/Cerca(s)',
                  },
                  {
                    key: 'assetProp#ref',
                    value: JSON.stringify({ sourceType: 'assetProp', targetType: 'ref' }),
                    text: 'Pessoa(s)/Referência(s)',
                  },
                  // {
                  //   key: 'asset#fence',
                  //   value: JSON.stringify({ sourceType: 'asset', targetType: 'fence' }),
                  //   text: ['TiplamTest', 'Tiplam', 'Phygitall'].includes(tenant) ? 'Pessoa/Perímetros' : 'Pessoa/Cerca',
                  // },
                  // {
                  //   key: 'asset#fenceGroup',
                  //   value: JSON.stringify({ sourceType: 'asset', targetType: 'fenceGroup' }),
                  //   text: ['TiplamTest', 'Tiplam', 'Phygitall'].includes(tenant)
                  //     ? 'Pessoa/Grupo de Perímetros'
                  //     : 'Pessoa/Grupo de Cerca',
                  // },
                  // {
                  //   key: 'assetGroup#fence',
                  //   value: JSON.stringify({ sourceType: 'assetGroup', targetType: 'fence' }),
                  //   text: ['TiplamTest', 'Tiplam', 'Phygitall'].includes(tenant)
                  //     ? 'Grupo de Pessoa/Perímetros'
                  //     : 'Grupo de Pessoa/Cerca',
                  // },
                  // {
                  //   key: 'assetGroup#fenceGroup',
                  //   value: JSON.stringify({ sourceType: 'assetGroup', targetType: 'fenceGroup' }),
                  //   text: ['TiplamTest', 'Tiplam', 'Phygitall'].includes(tenant)
                  //     ? 'Grupo de Pessoas/Grupo de Perímetros'
                  //     : 'Grupo de Pessoa/Grupo de Cerca',
                  // },
                ]}
                style={{ zIndex: '10' }}
                upward={false}
              />
            </FormField>
          )}
          <br />
          <br />
          {ruleWip.sourceType && (
            <Divider horizontal>
              <Header as="h4">Se</Header>
            </Divider>
          )}
          <br />
          <br />

          {ruleWip.sourceType === 'assetProp' && (
            <Form.Group widths="equal">
              <FormField inline required error={checkForm && !ruleWip.sourceProp} width="4">
                <Transition transitionOnMount={true} animation="fade right" duration="1700">
                  <label>{'Propriedade'}</label>
                </Transition>
                <Transition transitionOnMount={true} animation="fade" duration="1400">
                  <Dropdown
                    selection
                    search
                    fluid
                    placeholder="Selecionar Propriedade"
                    onChange={(e, { value }) =>
                      dispatch(
                        setRuleWip({
                          ...ruleWip,
                          sourceProp: value,
                          sourcePropValue: value === '<any>' ? ['<any>'] : '',
                        })
                      )
                    }
                    value={ruleWip.sourceProp}
                    disabled={DISABLED}
                    options={[
                      // {
                      //   key: 'id',
                      //   value: 'id',
                      //   text: 'Nome',
                      // },
                      {
                        key: 'Atribuição',
                        value: 'atribuição',
                        text: 'Atribuição',
                      },
                      {
                        key: 'Cargo',
                        value: 'cargo',
                        text: 'Cargo',
                      },
                      {
                        key: 'sector',
                        value: 'sector',
                        text: 'Setor',
                      },
                      {
                        key: 'onBoarding',
                        value: 'onBoarding',
                        text: 'Ambientado',
                      },
                      {
                        key: 'Treinamento',
                        value: 'certifications',
                        text: 'Treinamento',
                      },
                      {
                        key: 'any',
                        value: '<any>',
                        text: 'Qualquer Pessoa',
                      },
                      {
                        key: 'Grupo',
                        value: 'grupo',
                        text: 'Grupo',
                      },
                    ]}
                    style={{ zIndex: '9' }}
                    upward={false}
                  />
                </Transition>
              </FormField>
              {ruleWip.sourceProp && ruleWip.sourceProp !== '<any>' && (
                <FormField inline required error={checkForm && !ruleWip.sourcePropValue}>
                  <Transition transitionOnMount={true} animation="fade right" duration="1700">
                    <label>Valor</label>
                  </Transition>
                  <Transition transitionOnMount={true} animation="fade" duration="1400">
                    <Dropdown
                      selection
                      search
                      fluid
                      placeholder="Selecionar Valor"
                      onChange={(e, { value }) => dispatch(setRuleWip({ ...ruleWip, sourcePropValue: value }))}
                      value={ruleWip.sourcePropValue}
                      disabled={DISABLED}
                      options={
                        uniqueValues[ruleWip.sourceProp] ? uniqueValues[ruleWip.sourceProp]() : uniqueValues.default()
                      }
                      multiple={ruleWip.sourceProp !== 'onBoarding'}
                      style={{ zIndex: '9' }}
                      upward={false}
                    />
                  </Transition>
                </FormField>
              )}
            </Form.Group>
          )}

          {ruleWip.sourceType && (
            <Form.Group widths="equal">
              {ruleWip.sourceType === 'asset' && (
                <FormField inline required error={checkForm && !ruleWip.sourceId}>
                  <Transition transitionOnMount={true} animation="fade right" duration="1700">
                    <label>Pessoa</label>
                  </Transition>
                  <Transition transitionOnMount={true} animation="fade" duration="1400">
                    <Dropdown
                      selection
                      search
                      fluid
                      placeholder="Selecionar Pessoa"
                      onChange={(e, { value }) => dispatch(setRuleWip({ ...ruleWip, sourceId: value }))}
                      value={ruleWip.sourceId[0]}
                      disabled={DISABLED}
                      options={assetsAllIds.map((eachAsset) => ({
                        key: assetsById[eachAsset].id?.toString(),
                        value: assetsById[eachAsset].id?.toString(),
                        text: assetsById[eachAsset].name,
                      }))}
                      style={{ zIndex: '9' }}
                      multiple
                      upward={false}
                    />
                  </Transition>
                </FormField>
              )}
              {ruleWip.targetType.includes('ref') && (
                <FormField inline required error={checkForm && !ruleWip.scope} width="4">
                  <Transition transitionOnMount={true} animation="fade" duration="1700">
                    <label style={{ textAlign: ruleWip.sourceType === 'assetProp' ? 'left' : 'center' }}>
                      Aproximar-se
                    </label>
                  </Transition>
                  <Transition transitionOnMount={true} animation="fade" duration="1400">
                    <Dropdown
                      selection
                      fluid
                      placeholder="Aproximar-se"
                      onChange={(e, { value }) =>
                        dispatch(setRuleWip({ ...ruleWip, targetProp: value, targetPropValue: [] }))
                      }
                      value={ruleWip.targetProp}
                      disabled={DISABLED}
                      options={[
                        { key: '0', value: 'id', text: 'de uma referência' },
                        { key: '1', value: 'type', text: 'de um tipo de referência' },
                        //{ key: '2', value: 'nextAnyRef', text: 'de qualquer referência' },
                      ]}
                      style={{ zIndex: '8' }}
                      upward={false}
                    />
                  </Transition>
                </FormField>
              )}
              {ruleWip.targetType.includes('fence') && (
                <FormField inline required error={checkForm && !ruleWip.scope} width="4">
                  <Transition transitionOnMount={true} animation="fade" duration="1700">
                    <label style={{ textAlign: ruleWip.sourceType === 'assetProp' ? 'left' : 'center' }}>
                      Condição
                    </label>
                  </Transition>
                  <Transition transitionOnMount={true} animation="fade" duration="1400">
                    <Dropdown
                      selection
                      fluid
                      placeholder="Entrar/Sair"
                      onChange={(e, { value }) => dispatch(setRuleWip({ ...ruleWip, scope: value }))}
                      value={ruleWip.scope}
                      disabled={DISABLED}
                      options={[
                        { key: '0', value: 'inout', text: 'Entrar/Sair' },
                        { key: '1', value: 'in', text: 'Entrar' },
                        { key: '2', value: 'out', text: 'Sair' },
                      ]}
                      style={{ zIndex: '8' }}
                      upward={false}
                    />
                  </Transition>
                </FormField>
              )}
              {ruleWip.targetType === 'ref' && ruleWip.targetProp === 'type' && (
                <FormField inline width="6">
                  <Transition transitionOnMount={true} animation="fade left" duration="1700">
                    <label style={{ textAlign: ruleWip.sourceType !== 'assetProp' ? 'right' : 'left' }}>
                      {'Tipo(s) de Referência(s)'}
                    </label>
                  </Transition>

                  <Transition transitionOnMount={true} animation="fade" duration="1400">
                    <Dropdown
                      selection
                      search
                      fluid
                      placeholder={'Selecione o(s) Tipo(s) de Referência(s)'}
                      onChange={(e, { value }) => dispatch(setRuleWip({ ...ruleWip, targetPropValue: value }))}
                      value={ruleWip.targetPropValue}
                      disabled={DISABLED}
                      options={sortedRefTypes}
                      style={{ zIndex: '8' }}
                      multiple
                      upward={false}
                    />
                  </Transition>
                </FormField>
              )}
              {ruleWip.targetType === 'ref' && ruleWip.targetProp === 'id' && (
                <FormField inline width="6">
                  <Transition transitionOnMount={true} animation="fade left" duration="1700">
                    <label style={{ textAlign: ruleWip.sourceType !== 'assetProp' ? 'right' : 'left' }}>
                      {'Referências'}
                    </label>
                  </Transition>

                  <Transition transitionOnMount={true} animation="fade" duration="1400">
                    <Dropdown
                      selection
                      search
                      fluid
                      placeholder={'Selecione a(s) Referência(s)'}
                      onChange={(e, { value }) => dispatch(setRuleWip({ ...ruleWip, targetPropValue: value }))}
                      value={ruleWip.targetPropValue}
                      disabled={DISABLED}
                      options={refs.allIds.map((eachRef) => {
                        const label = refs.byId[eachRef]?.label
                        return {
                          key: refs.byId[eachRef].id,
                          value: refs.byId[eachRef].id,
                          text: label,
                        }
                      })}
                      style={{ zIndex: '8' }}
                      multiple
                      upward={false}
                    />
                  </Transition>
                </FormField>
              )}
              {ruleWip.targetType === 'ref' && ruleWip.targetPropValue?.length > 0 && (
                <FormField inline width="6">
                  <Transition transitionOnMount={true} animation="fade left" duration="1700">
                    <label style={{ textAlign: ruleWip.sourceType !== 'assetProp' ? 'right' : 'left' }}>
                      {'Distância mínima'}
                    </label>
                  </Transition>

                  <Transition transitionOnMount={true} animation="fade" duration="1400">
                    <Dropdown
                      selection
                      search
                      fluid
                      placeholder={'Selecione a distância mínima atendida'}
                      onChange={(e, { value }) => dispatch(setRuleWip({ ...ruleWip, scope: 'le', threshold: value }))}
                      value={ruleWip.threshold}
                      disabled={DISABLED}
                      options={[
                        /* { key: 'd-1', value: '0.2', text: 'menos de 0.2 metros' },
                        { key: 'd0', value: '0.5', text: 'menos de 0.5 metros' },*/
                        { key: 'd1', value: 1, text: 'menos de 1 metro' },
                        { key: 'd2', value: 2, text: 'menos de 2 metros' },
                        { key: 'd3', value: 3, text: 'menos de 3 metros' },
                        { key: 'd4', value: 5, text: 'menos de 5 metros' },
                        { key: 'd5', value: 8, text: 'menos de 8 metros' },
                        { key: 'd6', value: 10, text: 'menos de 10 metros' },
                      ]}
                      style={{ zIndex: '8' }}
                      upward={false}
                    />
                  </Transition>
                </FormField>
              )}
              {ruleWip.targetType === 'fence' && (
                <FormField inline>
                  <Transition transitionOnMount={true} animation="fade left" duration="1700">
                    <label style={{ textAlign: ruleWip.sourceType !== 'assetProp' ? 'right' : 'left' }}>
                      {['TiplamTest', 'Tiplam', 'Phygitall'].includes(tenant) ? 'Perímetros' : 'Cerca Virtual'}
                    </label>
                  </Transition>

                  <Transition transitionOnMount={true} animation="fade" duration="1400">
                    <Dropdown
                      selection
                      search
                      fluid
                      placeholder={
                        ['TiplamTest', 'Tiplam', 'Phygitall'].includes(tenant)
                          ? 'Selecione o(s) Perímetro(s)'
                          : 'Selecione a(s) Cerca(s)'
                      }
                      onChange={(e, { value }) => dispatch(setRuleWip({ ...ruleWip, targetId: value }))}
                      value={ruleWip.targetId}
                      disabled={DISABLED}
                      options={fences.allIds.map((eachFence) => {
                        const label = fences.byId[eachFence]?.label
                        return {
                          key: fences.byId[eachFence].id,
                          value: fences.byId[eachFence].id,
                          text: label,
                        }
                      })}
                      style={{ zIndex: '8' }}
                      multiple
                      upward={false}
                    />
                  </Transition>
                </FormField>
              )}
            </Form.Group>
          )}

          <br />
          {(ruleWip.sourceId || ruleWip.sourceProp) && ruleWip.scope && <ActionRuleFields checkForm />}
          <br />
        </Form>
      )}
    </ModalTemplate>
  )
}

const FormField = styled(Form.Field)`
  margin-bottom: 18px !important;
`

// const TextBox = styled.span`
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   max-width: ${(props) => props.maxWidth}px;
//   display: inline-block;
//   font-size: smaller;
// `
