import React, { useCallback, useEffect, useState } from 'react'
import m from 'moment'
import useAsync from 'Utils/hooks/useAsync'
import userInfoIntegration from 'Apis/integrations'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import ItemDetailsModal from './ItemDetailsModal'
import ItemDetailsGenericModal from './ItemDetailsGenericModal'

const LinkExtra = styled.span`
  cursor: pointer;
  text-decoration: ${(props) => (props.textDecoration ? 'underline' : 'none')};
`
export const AssetNameSpan = styled.span`
  cursor: pointer;
  text-decoration: ${(props) => (props.textDecoration ? 'underline' : 'none')};
  margin-top: 5px;
  margin-left: 6px;
  font-size: 1.2em;
  font-weight: 600;
  white-space: nowrap;
  width: 100%;
  max-width: ${(props) => (props.chars || '22') + 'ch'};
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`

const isNameDifferent = (name1, name2) => {
  if (!name1 || !name2) return false
  const firstName1 = name1.split(' ')[0]
  const firstName2 = name2.split(' ')[0]
  if (firstName1.toLowerCase() === firstName2.toLowerCase()) return false
  return true
}

export const ItemDetails = ({ id, assetId, assetName, spanStyle, chars }) => {
  const [open, setOpen] = useState(false)
  const [underline, setUnderline] = useState(false)
  const [dateRef, setDateRef] = useState(new Date().toISOString())
  const [loadingButton, setLoadingButton] = useState(false)
  const nfcId = useSelector((state) => state.assets.byId?.[assetId]?.info?.cardId)
  const tenant = useSelector((state) => state.login.empresa)
  const [neverFound, setNeverFound] = useState(false)

  const { execute, value, error } = useAsync(
    useCallback(() => userInfoIntegration.getUserInfoByNfc(nfcId, dateRef), [nfcId, dateRef]),
    false
  )

  const tryPreviousDate = async (_e, _p, date) => {
    setLoadingButton(true)
    const newDate = await userInfoIntegration.getLatestDate(assetId, date || dateRef)
    const response = await userInfoIntegration.getUserInfoByNfc(nfcId, newDate)
    if (newDate === '1970-01-01T00:00:00.000Z') {
      setNeverFound(true)
      setLoadingButton(false)
      return
    }
    setDateRef(newDate)
    if (response.status === 204) {
      await tryPreviousDate(undefined, undefined, new Date(newDate).getTime())
      return
    }
    setLoadingButton(false)
  }

  const formattedDateRef = m(dateRef).format('lll')

  const handleOpenModal = useCallback(() => {
    execute()
    setOpen(true)
  }, [execute])

  useEffect(() => {
    setDateRef(new Date().toISOString())
    setNeverFound(false)
  }, [assetId])

  useEffect(() => {
    if (open) {
      execute()
    }
  }, [open, execute])

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={handleOpenModal}
      open={open}
      trigger={
        spanStyle !== 'popup' ? (
          <LinkExtra
            textDecoration={underline ? 'underline' : 'none'}
            onMouseEnter={() => setUnderline(true)}
            onMouseLeave={() => setUnderline(false)}
          >
            {assetName}
          </LinkExtra>
        ) : (
          <AssetNameSpan
            title={assetName}
            alt={assetName}
            textDecoration={underline ? 'underline' : 'none'}
            onMouseEnter={() => setUnderline(true)}
            onMouseLeave={() => setUnderline(false)}
            chars={chars}
          >
            {assetName}
          </AssetNameSpan>
        )
      }
    >
      {tenant === 'Tiplam' ? (
        <ItemDetailsModal
          id={nfcId}
          assetId={assetId}
          dateRef={formattedDateRef}
          value={value}
          setOpen={setOpen}
          error={error}
          neverFound={neverFound}
          tryPreviousDate={tryPreviousDate}
          loadingButton={loadingButton}
          unreliable={isNameDifferent(assetName, value?.data?.name)}
        />
      ) : (
        <ItemDetailsGenericModal
          id={nfcId}
          dateRef={formattedDateRef}
          setOpen={setOpen}
          assetId={assetId}
          assetName={assetName}
        />
      )}
    </Modal>
  )
}

export default ItemDetails
