import React, { useRef } from 'react'
import { VideoUpload } from '../Components/Video/VideoUpload'
import { VideoPlayer } from '../Components/Video/VideoPlayer'
import { useVideoPlayer } from 'Components/Video/useVideoPlayer'

export const VideoExampleView = () => {
  const [videoUrlKey, setVideoUrlKey] = React.useState<string | null>(null)

  const onUpload = (ev: React.ChangeEvent<HTMLInputElement>, url: string) => {
    setVideoUrlKey(url)
  }
  const videoElement = useRef(null)
  const { playerState, togglePlay, toggleMute, setVideoCurrentTime, ...handleProps } = useVideoPlayer(videoElement)

  const skipToTimestamp = () => {
    setVideoCurrentTime(10)
  }

  const foward = () => {
    setVideoCurrentTime(playerState.currentVideoTime + 10)
  }

  return (
    <div style={styles.container}>
      <VideoUpload onUpload={onUpload} />
      <VideoPlayer
        videoUrlKey={videoUrlKey}
        videoRef={videoElement}
        playerState={playerState}
        togglePlay={togglePlay}
        toggleMute={toggleMute}
        {...handleProps}
      />
      <pre>{JSON.stringify(playerState, null, 2)}</pre>
      {videoUrlKey && <button onClick={skipToTimestamp}>Pular para 10 segundos</button>}
      {videoUrlKey && <button onClick={foward}>Avançar 10 segundos</button>}
    </div>
  )
}

const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
}
