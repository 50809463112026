import React from 'react'
import { Path, Svg } from '@react-pdf/renderer'

const Separator = ({ style }) => (
  <Svg height="25" style={{ paddingTop: 10, ...style }}>
    <Path d="M 0,0 L 1000,0" stroke="rgb(0, 0, 0)" strokeWidth={1} />
  </Svg>
)

export default Separator
