/*  
  Author: Luís Mourão
  Type: Reducer
  
  Description: 
    - 
    - 
  TODO: 
*/

import initialStore from 'Store/initialStore'
import exportReport from 'Utils/exportReport'
import _ from 'lodash'
import m from 'moment'
import produce from 'immer'

export default function dashboardsReducer(
  state = initialStore.dashboards,
  { body, type, id, payload, fences, rules, token }
) {
  let data
  switch (type) {
    case 'FETCH_DASHBOARD_START':
      return { ...state, loading: true }

    case 'FETCH_DASHBOARD_SUCCESS':
      return produce(state, (newState) => {
        if (token) {
          Object.keys(state.byId[id].byTime).forEach((eachKey) => {
            if (state.byId[id]?.byTime?.[eachKey] && payload.result?.[eachKey]?.timeWindows) {
              newState.byId[id].byTime[eachKey].timeWindows = {
                ...state.byId[id].byTime[eachKey].timeWindows,
                ...payload.result[eachKey]?.timeWindows,
              }
            }
          })
        } else {
          newState.byId[id].byTime = payload.result
        }
        newState.byId[id].selected =
          payload.result && state.byId[id].selected.length === 0
            ? [
                Object.entries(payload.result)
                  ?.filter((f) => {
                    if (fences) return fences.includes(f[0])
                    else return rules.includes(f[0])
                  })
                  .sort(
                    (a, b) =>
                      Object.values(b[1]?.timeWindows || []).reduce((prev, current) => prev + current, 0) -
                      Object.values(a[1]?.timeWindows || []).reduce((prev, current) => prev + current, 0)
                  )?.[0]?.[0],
              ]
            : state.byId[id].selected

        newState.loading = false
      })

    case 'EXPORT_RULES_DASHBOARD_REPORT':
      data = state.byId['rules']?.byTime
      var exportRulesData = [['Nome da Regra', 'Contagem de Acionamentos', 'Início', 'Fim']]
      if (data) {
        Object.entries(data).forEach((each) => {
          const ruleId = each[0]
          const ruleName = rules.byId[ruleId]?.name
          const ruleCount = each[1].timeWindows
          exportRulesData = exportRulesData.concat(
            Object.entries(ruleCount)
              .map((eachTime, i, all) => {
                return [ruleName, eachTime[1], i > 0 ? all[i - 1][0] : '', eachTime[0]]
              })
              .filter((e, i) => i > 0)
          )
        })

        exportReport(exportRulesData, `Relatório_Insights_Regras_${m().format('YYYYMMDD')}`)
      }

      return state

    case 'EXPORT_INTERACTIONS_DASHBOARD_REPORT':
      //Falta concluir
      data = state.byId['fences']?.byTime
      var exportInteractData = [['Nome do Perímetro', 'Id do Perímetro', 'Contagem de Interações', 'Início', 'Fim']]
      if (data) {
        Object.entries(data).forEach((each) => {
          const fenceId = each[0]
          const fenceName = fences.byId[fenceId]?.label
          const fenceCount = each[1].timeWindows
          exportInteractData = exportInteractData.concat(
            Object.entries(fenceCount)
              .map((eachTime, i, all) => {
                return [fenceName, fenceId, eachTime[1], i > 0 ? all[i - 1][0] : '', eachTime[0]]
              })
              .filter((e, i) => i > 0)
          )
        })

        exportReport(exportInteractData, `Relatório_InsightsB_${m().format('YYYYMMDD')}`)
      }
      return state

    case 'EXPORT_FENCES_DASHBOARD_REPORT':
      data = state.byId['fences']?.byTime
      var exportData = [['Nome do Perímetro', 'Contagem de Pessoas', 'Início', 'Fim']]
      //console.log('o Estado: ', state)
      if (data) {
        Object.entries(data).forEach((each) => {
          const fenceId = each[0]
          const fenceName = fences.byId[fenceId]?.label
          const fenceCount = each[1].timeWindows
          exportData = exportData.concat(
            Object.entries(fenceCount)
              .map((eachTime, i, all) => {
                return [fenceName, eachTime[1], i > 0 ? all[i - 1][0] : '', eachTime[0]]
              })
              .filter((e, i) => i > 0)
          )
        })

        exportReport(exportData, `Relatório_InsightsB_${m().format('YYYYMMDD')}`)
      }
      return state

    case 'CHANGE_CURRENT_DASHBOARD':
      return produce(state, (newState) => {
        newState.currentId = id
        if (id === 'rules' && state.timeAgo === 'realTime') newState.timeAgo = '1d'
        newState.filter = ''
      })

    case 'CHANGE_CURRENT_DASHBOARD_TIME':
      return { ...state, timeAgo: payload }

    case 'SET_DASHBOARD_FILTER':
      return { ...state, filter: payload }

    case 'SET_SELECTED_CARD':
      return produce(state, (newState) => {
        if (Array.isArray(payload)) {
          newState.byId[id].selected = payload
        } else {
          if (state.byId[id].selected.includes(payload)) {
            if (state.byId[id]?.selected)
              newState.byId[id].selected = state.byId[id]?.selected?.filter((f) => f !== payload)
          } else {
            newState.byId[id].selected = _.uniq(state.byId[id].selected.concat(payload))
          }
        }
      })

    default:
      return state
  }
}
