import React, { memo } from 'react'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { Polyline, Tooltip } from 'react-leaflet'

const HistHolesLines = () => {
  const histHolesLines = useObjSelector((state) => state.history?.holesLines)
  return histHolesLines ? (
    histHolesLines?.map((eachLine, i) => {
      return (
        <Polyline
          key={i}
          positions={eachLine.geometry.coordinates.map((e) => [e[1], e[0]])}
          weight="5"
          dashArray="3, 6"
          dashOffset="0"
          color={eachLine.properties.color}
        >
          {
            <Tooltip
              direction="bottom"
              permanent={false}
            >{`Rótulo: ${eachLine.properties.label}  |  Detecções: ${eachLine.properties.count}`}</Tooltip>
          }
        </Polyline>
      )
    })
  ) : (
    <span>n/a</span>
  )
}

export default memo(HistHolesLines)
