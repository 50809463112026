import { urlBase, URL_BASE } from 'Apis/rest'
import axios from 'axios'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'

/*global localStorage*/

export const clearDeviceStates = () => {
  return {
    type: 'SENSOR_CLEAR_STATES',
  }
}

export const updateDeviceById = (id, newValues) => async (dispatchEvent) => {
  dispatchEvent({
    type: 'UPDATE_SENSOR_START',
  })
  const jwt = localStorage.getItem('id_token')
  const url = `${URL_BASE}/devices/details/${id}`
  const config = {
    headers: { Authorization: `Bearer ${jwt}` },
  }
  try {
    await axios.put(url, newValues, config).then(
      dispatchEvent({
        type: 'UPDATE_SENSOR_SUCCESS',
        payload: {
          serial_number: newValues.serial_number,
          info: { description: newValues.description, responsavel: newValues.responsavel, destino: newValues.destino },
        },
        id,
      })
    )
    notifySuccess()
  } catch (error) {
    dispatchEvent({
      type: 'UPDATE_SENSOR_ERROR',
    })
    notifyError()
    console.log(error)
  }
}

export const provisionDevice = (device) => async (dispatchEvent) => {
  dispatchEvent({
    type: 'PROVISION_DEVICE_START',
  })
  const jwt = localStorage.getItem('id_token')
  const url = `${URL_BASE}/devices/provision`
  const config = {
    headers: { Authorization: `Bearer ${jwt}` },
  }
  try {
    const res = await axios.post(url, device, config)
    console.log('res provision device', res)
    dispatchEvent({
      type: 'PROVISION_DEVICE_SUCCESS',
      payload: res.data,
    })
    notifySuccess()
  } catch (error) {
    dispatchEvent({
      type: 'PROVISION_DEVICE_ERROR',
      payload: error.response.data ?? error,
    })
    console.log(error)
    notifyError()
  }
}

export const unProvisionDevices = (deviceIds) => async (dispatch) => {
  dispatch({
    type: 'UNPROVISION_DEVICES_START',
  })
  const jwt = localStorage.getItem('id_token')
  const url = `${URL_BASE}/devices/unprovision`
  const config = {
    headers: { Authorization: `Bearer ${jwt}` },
  }
  try {
    const res = await axios.post(url, { deviceIds }, config)
    console.log('res unprovision devices', res)
    dispatch({
      type: 'UNPROVISION_DEVICES_SUCCESS',
      payload: res.data,
    })
    notifySuccess()
  } catch (error) {
    dispatch({
      type: 'UNPROVISION_DEVICES_ERROR',
      payload: error.response.data ?? error,
    })
    console.log(error)
    notifyError()
  }
}

export const toggleChannel = (params) => async (dispatch) => {
  try {
    const jwt = localStorage.getItem('id_token')
    dispatch({
      type: 'TOGGLE_CHANNEL',
      payload: params,
    })
    axios
      .post('https://nodered.phygitall.com/togglechannel', params, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((res) => {
        dispatch({
          type: 'TOGGLE_CHANNEL_SUCCESS',
          payload: res?.data,
        })
        notifySuccess()
      })
  } catch (err) {
    console.error(err)
    dispatch({
      type: 'TOGGLE_CHANNEL_ERROR',
      payload: err,
    })
    notifyError()
  }
}

export const fetchDevices = () => async (dispatch, getState) => {
  const jwt = localStorage.getItem('id_token')
  try {
    dispatch({ type: 'FETCH_DEVICES_START' })
    const {
      data: { result },
    } = await axios.get(`${URL_BASE}/devices`, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    if (!result) {
      dispatch({ type: 'FETCH_DEVICES_FAILURE', payload: result })
    } else {
      dispatch({ type: 'FETCH_DEVICES_SUCCESS', payload: result })
    }
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_DEVICES_FAILURE', payload: err })
  }
}

export const clearDevices = () => async (dispatch) => {
  dispatch({ type: 'CLEAR_DEVICES' })
}

export const updateDevice =
  ({ id, field, value }) =>
  async (dispatch, getState) => {
    const jwt = localStorage.getItem('id_token')
    try {
      if (!id) console.error('UpdateDevice without id:' + id)
      else {
        dispatch({ type: 'UPDATE_DEVICE_START' })
        axios
          .put(
            `${urlBase}/devices?id=` + id,
            { field: field, value: value },
            {
              headers: {
                Authorization: 'Bearer ' + jwt,
              },
            }
          )
          .then((resp) => {
            if (!resp.data.body) {
              console.error('UPDATE_DEVICE_FAILURE', resp.data)
              dispatch({ type: 'UPDATE_DEVICE_FAILURE', payload: resp.data })
            } else {
              dispatch({ type: 'UPDATE_DEVICE', payload: resp.data.body })
            }
          })
      }
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_DEVICES_FAILURE', payload: err })
    }
  }
