import React, { useMemo } from 'react'
import { DropdownProps, Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { setProfilesProp } from 'Store/actions/profiles-action'
import InputStoreField from '../generic/InputStoreField'
import TextAreaStoreField from '../generic/TextAreaStoreField'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { RootStore } from 'Store/initialStore'
import DropdownStoreField from '../generic/DropdownStoreField'
import { useTranslation } from 'react-i18next'
import colorOptions from 'Utils/theme/colors'
import { getEntityField } from 'Store/reducers/reducerUtils'
import { AssetIconOptions, PeopleIconOptions, OtherIconOptions } from 'Resources/Icons/iconesSvg/iconesSVG.js'
import SvgIcon from 'Utils/components/SvgIcon'
import styled from 'styled-components'
import ErrorBoundary from 'Utils/ErrorBoundary/ErrorBoundary'

const FormField = styled(Form.Field)`
  padding-left: 50px !important;
`

const ProfilesStepOneForm: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const step = useObjSelector((state) => state.profiles.form.steps?.items[0])
  const errors = useObjSelector((state) => state.profiles.form.errors?.[0])
  const currentId = useSelector((state: RootStore) => state.profiles.current?.id)
  const type = useSelector((state: RootStore) => getEntityField(state, 'profiles', 'type'))
  const color = useSelector((state: RootStore) => getEntityField(state, 'profiles', 'color'))
  const icon = useSelector((state: RootStore) => getEntityField(state, 'profiles', 'icon'))

  const profileAllIds = useObjSelector((state) => state.profiles.allIds)
  const profilesById = useObjSelector((state) => state.profiles.byId)

  const refProfileOptions = useMemo(() => {
    return profileAllIds.reduce((acc: DropdownProps[], id) => {
      const profile = profilesById[id]
      if (profile?.type === 'refs') {
        acc.push({ key: id, text: profile.name, value: id })
      }
      return acc
    }, [])
  }, [profileAllIds, profilesById])

  const colors = colorOptions.map((e) => {
    e.text = t(e.text)
    return e
  })

  const icons = type === 'people' ? PeopleIconOptions : type === 'assets' ? AssetIconOptions : OtherIconOptions

  return (
    <ErrorBoundary>
      <Form.Group widths={16}>
        <DropdownStoreField
          width={4}
          entity="profiles"
          label="Tipo"
          name="type"
          placeholder="Selecione o tipo"
          value={'type'}
          options={['assets', 'people', 'fences', 'refs', 'routes'].map((e) => ({ key: e, text: t(e), value: e }))}
          onChange={(_, { value }) => {
            dispatch(setProfilesProp('wip.type', value))
          }}
          required={true}
          showFeedback={step.showFeedback}
          error={errors?.type}
          disabled={!!currentId}
        />
        <InputStoreField
          width={8}
          entity="profiles"
          label="Nome"
          name="name"
          placeholder="Digite o nome"
          value={'name'}
          onChange={(_, { name, value }) => dispatch(setProfilesProp(`wip.${name}`, value))}
          required={true}
          showFeedback={step.showFeedback}
          error={errors?.name}
        />
        <InputStoreField
          width={4}
          entity="profiles"
          label="Código"
          name="code"
          placeholder="Digite o Código de identificação"
          value={'code'}
          onChange={(_, { name, value }) => dispatch(setProfilesProp(`wip.${name}`, value))}
          required={true}
          showFeedback={step.showFeedback}
          error={errors?.code}
          maxLength={8}
          disabled={!!currentId}
        />
      </Form.Group>
      <br />
      <Form.Group>
        <DropdownStoreField
          width={7}
          entity="profiles"
          label="Cor"
          name="color"
          placeholder="Selecione a cor"
          value={'color'}
          options={colors}
          onChange={(_, { value }) => dispatch(setProfilesProp('wip.color', value))}
          required={true}
          showFeedback={step.showFeedback}
          error={errors?.color}
        />
        <DropdownStoreField
          width={7}
          entity="profiles"
          label="Ícone"
          name="icon"
          placeholder="Selecione o ícone"
          value={'icon'}
          options={icons}
          onChange={(_, { value }) => dispatch(setProfilesProp('wip.icon', value))}
          required={true}
          showFeedback={step.showFeedback}
          error={errors?.icon}
        />
        <FormField required={false} width={2}>
          <label>{t('Preview')}</label>
          <div style={{ paddingLeft: '12px', paddingTop: '7px' }}>
            <SvgIcon color={color} name={icon} />
          </div>
        </FormField>
      </Form.Group>
      {type === 'people' && (
        <DropdownStoreField
          entity="profiles"
          label="Permissão de Formulários"
          placeholder="Selecione a(s) Permissão(s)"
          value={'formPermission'}
          options={refProfileOptions}
          onChange={(_, { value }) => {
            dispatch(setProfilesProp('wip.formPermission', value))
          }}
          multiple
        />
      )}
      <br />
      <TextAreaStoreField
        entity="profiles"
        label="Descrição"
        name="description"
        placeholder="Descreva o relatório"
        value={'description'}
        onChange={(_, { name, value }) => dispatch(setProfilesProp(`wip.${name}`, value))}
      />
    </ErrorBoundary>
  )
}

export default ProfilesStepOneForm
