import React from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

export const GraphArea = ({ data = [], distinct = [], maxValue = 'auto' }) => {
  const maxDataValue =
    !distinct.lenght &&
    data.reduce((prev, curr) => {
      return parseFloat(curr.value) > prev ? parseFloat(curr.value) : prev
    }, 5)
  return (
    <ResponsiveContainer>
      <AreaChart width={700} height={800} data={data}>
        <defs>
          {distinct?.length === 0 ? (
            <linearGradient id="value" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          ) : (
            distinct.map((dis, index) => (
              <linearGradient key={'cell-' + index} id={'cell-' + dis.name} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={dis.color} stopOpacity={0.8} />
                <stop offset="95%" stopColor={dis.color} stopOpacity={0.2} />
              </linearGradient>
            ))
          )}
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
        <YAxis domain={[0, maxDataValue || maxValue]} />
        <Tooltip />
        <Legend />
        {distinct?.length === 0 ? (
          <Area type="monotone" dataKey="value" stroke="#82ca9d" fillOpacity={1} fill="#8884d8" />
        ) : (
          distinct.map((dis) => (
            <Area
              type="monotone"
              dataKey={dis.name}
              key={`cell-${dis.name}`}
              stroke={dis.color}
              fillOpacity={1}
              fill={`url(#cell-${dis.name})`}
            />
          ))
        )}
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default GraphArea

/*<AreaChart width={730} height={250} data={data}
  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
  <defs>
    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
    </linearGradient>
  </defs>
  <XAxis dataKey="name" />
  <YAxis />
  <CartesianGrid strokeDasharray="3 3" />
  <Tooltip />
  <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
  <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
</AreaChart> */
