/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getNotificationProfile = /* GraphQL */ `query GetNotificationProfile($id: ID!) {
  getNotificationProfile(id: $id) {
    id
    name
    tenant
    bgColor
    status
    screenTime
    icon
    description
    level
    titleColor
    bodyColor
    actionColor
    dateColor
    txtColor
    createdBy
    theme
    iconColor
    sound
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationProfileQueryVariables,
  APITypes.GetNotificationProfileQuery
>;
export const getRule = /* GraphQL */ `query GetRule($id: ID!) {
  getRule(id: $id) {
    id
    notificationProfileId
    notificationProfile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    name
    tenant
    status
    createdBy
    description
    action {
      type
      messageProfileId
    }
    message
    messageId
    scope
    sourceId
    sourceProp
    sourcePropValue
    sourceType
    targetProp
    targetPropValue
    targetId
    targetType
    threshold
    sendTo
    editable
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetRuleQueryVariables, APITypes.GetRuleQuery>;
export const getMessageProfile = /* GraphQL */ `query GetMessageProfile($id: ID!) {
  getMessageProfile(id: $id) {
    id
    tenant
    label
    type
    title
    body
    extra
    tags
    createdBy
    status
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetMessageProfileQueryVariables,
  APITypes.GetMessageProfileQuery
>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    tenant
    assetId
    message
    title
    time
    type
    deviceId
    profileId
    profile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    ruleId
    confirmedBy
    confirmedAt
    closedBy
    closedAt
    location
    originType
    id
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const getProfile = /* GraphQL */ `query GetProfile($id: ID!) {
  getProfile(id: $id) {
    id
    code
    tenant
    name
    type
    color
    icon
    description
    customFields {
      id
      label
      type
      options
      sectionId
    }
    properties
    status
    createdAt
    createdBy
    createForm
    updatedAt
    updatedBy
    updateForm
    formPermission
  }
}
` as GeneratedQuery<
  APITypes.GetProfileQueryVariables,
  APITypes.GetProfileQuery
>;
export const listAssetsLocation = /* GraphQL */ `query ListAssetsLocation(
  $tenant: String!
  $sortDirection: ModelSortDirection
  $filter: ModelAssetLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listAssetsLocation(
    tenant: $tenant
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      assetId
      deviceId
      lat
      lng
      location
      fencesLocation
      groupsLocation
      analytics
      tenant
      time
      motion
      coords
      battery
      connectivity
      model_info
      sessionStatus
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAssetsLocationQueryVariables,
  APITypes.ListAssetsLocationQuery
>;
export const listAssetIdLocation = /* GraphQL */ `query ListAssetIdLocation(
  $tenant: String!
  $assetId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAssetLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listAssetIdLocation(
    tenant: $tenant
    assetId: $assetId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      assetId
      deviceId
      lat
      lng
      location
      fencesLocation
      groupsLocation
      analytics
      tenant
      time
      motion
      coords
      battery
      connectivity
      model_info
      sessionStatus
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAssetIdLocationQueryVariables,
  APITypes.ListAssetIdLocationQuery
>;
export const listSessions = /* GraphQL */ `query ListSessions(
  $tenant: String!
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSessions(
    tenant: $tenant
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      assetId
      deviceId
      status
      startTime
      endTime
      createdBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSessionsQueryVariables,
  APITypes.ListSessionsQuery
>;
export const getLastSessionByAsset = /* GraphQL */ `query GetLastSessionByAsset(
  $assetId: String!
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  getLastSessionByAsset(
    assetId: $assetId
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      assetId
      deviceId
      status
      startTime
      endTime
      createdBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetLastSessionByAssetQueryVariables,
  APITypes.GetLastSessionByAssetQuery
>;
export const listSessionsByCreatedByStartTime = /* GraphQL */ `query ListSessionsByCreatedByStartTime(
  $createdBy: String!
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSessionsByCreatedByStartTime(
    createdBy: $createdBy
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      assetId
      deviceId
      status
      startTime
      endTime
      createdBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSessionsByCreatedByStartTimeQueryVariables,
  APITypes.ListSessionsByCreatedByStartTimeQuery
>;
export const listNotificationProfiles = /* GraphQL */ `query ListNotificationProfiles(
  $tenant: String!
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotificationProfiles(
    tenant: $tenant
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationProfilesQueryVariables,
  APITypes.ListNotificationProfilesQuery
>;
export const listRules = /* GraphQL */ `query ListRules(
  $tenant: String!
  $sortDirection: ModelSortDirection
  $filter: ModelRuleFilterInput
  $limit: Int
  $nextToken: String
) {
  listRules(
    tenant: $tenant
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      notificationProfileId
      notificationProfile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      name
      tenant
      status
      createdBy
      description
      action {
        type
        messageProfileId
      }
      message
      messageId
      scope
      sourceId
      sourceProp
      sourcePropValue
      sourceType
      targetProp
      targetPropValue
      targetId
      targetType
      threshold
      sendTo
      editable
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListRulesQueryVariables, APITypes.ListRulesQuery>;
export const listMessageProfiles = /* GraphQL */ `query ListMessageProfiles(
  $tenant: String!
  $sortDirection: ModelSortDirection
  $filter: ModelMessageProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessageProfiles(
    tenant: $tenant
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      label
      type
      title
      body
      extra
      tags
      createdBy
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMessageProfilesQueryVariables,
  APITypes.ListMessageProfilesQuery
>;
export const getLoginPreferences = /* GraphQL */ `query GetLoginPreferences(
  $tenant: String!
  $login: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLoginPreferencesFilterInput
  $limit: Int
  $nextToken: String
) {
  getLoginPreferences(
    tenant: $tenant
    login: $login
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      tenant
      login
      center {
        lat
        lng
      }
      zoom
      mapLayers
      refs
      fences
      routes
      indoor
      tables
      routesPanels
      id
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.GetLoginPreferencesQueryVariables,
  APITypes.GetLoginPreferencesQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $tenant: String!
  $time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(
    tenant: $tenant
    time: $time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      tenant
      assetId
      message
      title
      time
      type
      deviceId
      profileId
      profile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      ruleId
      confirmedBy
      confirmedAt
      closedBy
      closedAt
      location
      originType
      id
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const listNotificationsByType = /* GraphQL */ `query ListNotificationsByType(
  $tenant: String!
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotificationsByType(
    tenant: $tenant
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      tenant
      assetId
      message
      title
      time
      type
      deviceId
      profileId
      profile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      ruleId
      confirmedBy
      confirmedAt
      closedBy
      closedAt
      location
      originType
      id
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsByTypeQueryVariables,
  APITypes.ListNotificationsByTypeQuery
>;
export const listGeoModulesByTenant = /* GraphQL */ `query ListGeoModulesByTenant(
  $tenant: String!
  $label: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGeoModuleFilterInput
  $limit: Int
  $nextToken: String
) {
  listGeoModulesByTenant(
    tenant: $tenant
    label: $label
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      label
      code
      type
      geometry {
        type
        coordinates
        mRadius
      }
      profile
      profileInfo {
        id
        code
        tenant
        name
        type
        color
        icon
        description
        customFields {
          id
          label
          type
          options
          sectionId
        }
        properties
        status
        createdAt
        createdBy
        createForm
        updatedAt
        updatedBy
        updateForm
        formPermission
      }
      history {
        updatedAt
        updatedBy
        fields
      }
      properties
      description
      defaultInvisible
      state
      loadPreference
      createdAt
      createdBy
      createForm
      updatedBy
      updateForm
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListGeoModulesByTenantQueryVariables,
  APITypes.ListGeoModulesByTenantQuery
>;
export const listGeoModulesByTenantLoadPreference = /* GraphQL */ `query ListGeoModulesByTenantLoadPreference(
  $tenant: String!
  $loadPreference: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGeoModuleFilterInput
  $limit: Int
  $nextToken: String
) {
  listGeoModulesByTenantLoadPreference(
    tenant: $tenant
    loadPreference: $loadPreference
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      label
      code
      type
      geometry {
        type
        coordinates
        mRadius
      }
      profile
      profileInfo {
        id
        code
        tenant
        name
        type
        color
        icon
        description
        customFields {
          id
          label
          type
          options
          sectionId
        }
        properties
        status
        createdAt
        createdBy
        createForm
        updatedAt
        updatedBy
        updateForm
        formPermission
      }
      history {
        updatedAt
        updatedBy
        fields
      }
      properties
      description
      defaultInvisible
      state
      loadPreference
      createdAt
      createdBy
      createForm
      updatedBy
      updateForm
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListGeoModulesByTenantLoadPreferenceQueryVariables,
  APITypes.ListGeoModulesByTenantLoadPreferenceQuery
>;
export const listGeoModulesByTenantGeoType = /* GraphQL */ `query ListGeoModulesByTenantGeoType(
  $tenant: String!
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGeoModuleFilterInput
  $limit: Int
  $nextToken: String
) {
  listGeoModulesByTenantGeoType(
    tenant: $tenant
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      label
      code
      type
      geometry {
        type
        coordinates
        mRadius
      }
      profile
      profileInfo {
        id
        code
        tenant
        name
        type
        color
        icon
        description
        customFields {
          id
          label
          type
          options
          sectionId
        }
        properties
        status
        createdAt
        createdBy
        createForm
        updatedAt
        updatedBy
        updateForm
        formPermission
      }
      history {
        updatedAt
        updatedBy
        fields
      }
      properties
      description
      defaultInvisible
      state
      loadPreference
      createdAt
      createdBy
      createForm
      updatedBy
      updateForm
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListGeoModulesByTenantGeoTypeQueryVariables,
  APITypes.ListGeoModulesByTenantGeoTypeQuery
>;
export const listGeoModulesByTenantProfile = /* GraphQL */ `query ListGeoModulesByTenantProfile(
  $tenant: String!
  $profile: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGeoModuleFilterInput
  $limit: Int
  $nextToken: String
) {
  listGeoModulesByTenantProfile(
    tenant: $tenant
    profile: $profile
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      label
      code
      type
      geometry {
        type
        coordinates
        mRadius
      }
      profile
      profileInfo {
        id
        code
        tenant
        name
        type
        color
        icon
        description
        customFields {
          id
          label
          type
          options
          sectionId
        }
        properties
        status
        createdAt
        createdBy
        createForm
        updatedAt
        updatedBy
        updateForm
        formPermission
      }
      history {
        updatedAt
        updatedBy
        fields
      }
      properties
      description
      defaultInvisible
      state
      loadPreference
      createdAt
      createdBy
      createForm
      updatedBy
      updateForm
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListGeoModulesByTenantProfileQueryVariables,
  APITypes.ListGeoModulesByTenantProfileQuery
>;
export const listDataLabeling = /* GraphQL */ `query ListDataLabeling(
  $tenant: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDataLabelingFilterInput
  $limit: Int
  $nextToken: String
) {
  listDataLabeling(
    tenant: $tenant
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      name
      createdAt
      createdBy
      assets
      shift
      startTime
      endTime
      activityId
      activity {
        id
        tenant
        code
        name
        createdAt
        createdBy
        operationUnit
        section
        updatedAt
        updatedBy
        createForm
        properties
        description
        extraInfo
      }
      createForm
      updatedAt
      updatedBy
      description
      properties
      extraInfo
      taktTime
      targetCycleTime
      phases {
        index
        name
        description
        color
        code
        vehicle {
          name
        }
        ticon {
          code
          name
          qty
        }
        va
        nva
      }
      labelings {
        cycle
        phaseIndex
        startTime
        endTime
        vehicle
      }
      videoUrlKey
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListDataLabelingQueryVariables,
  APITypes.ListDataLabelingQuery
>;
export const listReportsByTenant = /* GraphQL */ `query ListReportsByTenant(
  $tenant: String!
  $code: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportsByTenant(
    tenant: $tenant
    code: $code
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      code
      tenant
      label
      scope
      startTime
      endTime
      description
      filters
      hiddenColumns
      groupBy
      sortBy {
        desc
        id
      }
      access
      createdBy
      createForm
      updatedBy
      updateForm
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListReportsByTenantQueryVariables,
  APITypes.ListReportsByTenantQuery
>;
export const listDashboardsByTenant = /* GraphQL */ `query ListDashboardsByTenant(
  $tenant: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDashboardFilterInput
  $limit: Int
  $nextToken: String
) {
  listDashboardsByTenant(
    tenant: $tenant
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      code
      tenant
      label
      description
      panels
      timeType
      startTime
      endTime
      access
      showAsDefault
      createdBy
      createForm
      updatedBy
      updateForm
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListDashboardsByTenantQueryVariables,
  APITypes.ListDashboardsByTenantQuery
>;
export const listActivities = /* GraphQL */ `query ListActivities(
  $tenant: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listActivities(
    tenant: $tenant
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      code
      name
      createdAt
      createdBy
      operationUnit
      section
      updatedAt
      updatedBy
      createForm
      properties
      description
      extraInfo
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListActivitiesQueryVariables,
  APITypes.ListActivitiesQuery
>;
export const listMensurations = /* GraphQL */ `query ListMensurations(
  $tenant: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMensurationFilterInput
  $limit: Int
  $nextToken: String
) {
  listMensurations(
    tenant: $tenant
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      name
      createdAt
      createdBy
      shift
      vehicle
      activityId
      activity {
        id
        tenant
        code
        name
        createdAt
        createdBy
        operationUnit
        section
        updatedAt
        updatedBy
        createForm
        properties
        description
        extraInfo
      }
      description
      section
      updatedAt
      updatedBy
      properties
      extraInfo
      taktTime
      targetCycleTime
      operator
      performedBy
      phases {
        index
        name
        description
        color
        code
        vehicle {
          name
        }
        ticon {
          code
          name
          qty
        }
        va
        nva
      }
      cyclePhaseDurations {
        duration
        phases {
          duration
        }
      }
      statistics
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMensurationsQueryVariables,
  APITypes.ListMensurationsQuery
>;
export const listProfilesByTenant = /* GraphQL */ `query ListProfilesByTenant(
  $tenant: String!
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfilesByTenant(
    tenant: $tenant
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      code
      tenant
      name
      type
      color
      icon
      description
      customFields {
        id
        label
        type
        options
        sectionId
      }
      properties
      status
      createdAt
      createdBy
      createForm
      updatedAt
      updatedBy
      updateForm
      formPermission
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListProfilesByTenantQueryVariables,
  APITypes.ListProfilesByTenantQuery
>;
export const listChangeLogsByTenantEntityId = /* GraphQL */ `query ListChangeLogsByTenantEntityId(
  $tenant: String!
  $entityId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChangeLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listChangeLogsByTenantEntityId(
    tenant: $tenant
    entityId: $entityId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenant
      entity
      entityId
      log
      updatedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListChangeLogsByTenantEntityIdQueryVariables,
  APITypes.ListChangeLogsByTenantEntityIdQuery
>;
