// I tried to translate these text values inside this file but I couldn't manage to.
// So I ended up t()'ing every piece of code that uses this variable text property.

const colorOptions = [
  { key: 'color1', text: 'yellow', value: 'yellow', label: { color: 'yellow', empty: true, circular: true } },
  { key: 'color2', text: 'blue', value: 'blue', label: { color: 'blue', empty: true, circular: true } },
  { key: 'color3', text: 'teal', value: 'teal', label: { color: 'teal', empty: true, circular: true } },
  { key: 'color5', text: 'grey', value: 'grey', label: { color: 'grey', empty: true, circular: true } },
  { key: 'color6', text: 'orange', value: 'orange', label: { color: 'orange', empty: true, circular: true } },
  { key: 'color7', text: 'brown', value: 'brown', label: { color: 'brown', empty: true, circular: true } },
  { key: 'color8', text: 'black', value: 'black', label: { color: 'black', empty: true, circular: true } },
  { key: 'color9', text: 'pink', value: 'pink', label: { color: 'pink', empty: true, circular: true } },
  { key: 'color10', text: 'purple', value: 'purple', label: { color: 'purple', empty: true, circular: true } },
  { key: 'color11', text: 'green', value: 'green', label: { color: 'green', empty: true, circular: true } },
  { key: 'color12', text: 'olive', value: 'olive', label: { color: 'olive', empty: true, circular: true } },
  { key: 'color13', text: 'red', value: 'red', label: { color: 'red', empty: true, circular: true } },
  { key: 'color14', text: 'violet', value: 'violet', label: { color: 'violet', empty: true, circular: true } },
]
export default colorOptions
