import React from 'react'
import { useMsal } from '@azure/msal-react'
import { Button } from 'semantic-ui-react'

function handleLogin(instance) {
  instance.loginPopup({ scopes: ['User.Read'] }).catch((e) => {
    console.error(e)
  })
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInWithAzure = () => {
  const { instance } = useMsal()

  return (
    <Button color="orange" onClick={() => handleLogin(instance)}>
      Conectar com Azure
    </Button>
  )
}
