import React from 'react'
import styled from 'styled-components'
import { Grid, Label, Icon } from 'semantic-ui-react'
import TimeSince from 'Utils/components/TimeSince'
import TimeAbsolute from 'Utils/components/TimeAbsolute'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
/*  Author: Bruno Melo e Luís Mourão
    Type: Dumb
	Description: Last time a data was sent about this asset				 
	To do list: -
				
*/
const InfoTimeContainer = styled(Grid.Column)``

export const PopupInfoTime = ({ assetId, mode }) => {
  const { t } = useTranslation()

  const histType = useSelector((state) => state.history.type)
  const infoTime = useSelector((state) => state[mode].byId[assetId]?.info?.time)

  return (
    <InfoTimeContainer width={12} verticalAlign="middle" textAlign="right">
      <Label>
        <Icon name="eye" title={t('PopupSignalsLastSeen')} />
        {<PopupTime infoTime={infoTime} mode={histType} />}
      </Label>
    </InfoTimeContainer>
  )
}

const PopupTime = ({ infoTime, mode }) => {
  const { t } = useTranslation()
  if (!infoTime) return t('Never')
  if (!mode) return <TimeSince date={infoTime} />
  return <TimeAbsolute date={infoTime} seconds />
}
export default PopupInfoTime
