import Dropdown from 'rc-dropdown'
import Menu, { Item as MenuItem } from 'rc-menu'
import 'rc-dropdown/assets/index.css'
import 'rc-menu/assets/index.css'
import React, { Component } from 'react'
import { setTrue, setFalse } from 'Store/actions/toggle-action'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

class DropdownFilter extends Component {
  state = {
    visible: false,
    selected: this.props.routeByProximity ? ['0'] : ['1'],
    name: 'Dados Corrigidos',
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected !== this.state.selected) {
      if (this.state.selected.length > 1) {
        this.setState({ name: 'Dados Corrigidos' })
      } else {
        switch (this.state.selected[0]) {
          //case "3": this.setState({ name: "Correção Proximidade" }); break;
          case '1':
            this.setState({ name: 'Dados sem Correção' })
            break
          case '0':
            this.setState({ name: 'Correção Rota' })
            break
          //case "0": this.setState({ name: "Correção Estática" }); break;
          default:
            break
        }
      }
    }
  }

  onVisibleChange = (visible) => {
    this.setState({
      visible,
    })
  }

  saveSelected = ({ selectedKeys, item }) => {
    //console.log('selectedKeys', selectedKeys)
    switch (selectedKeys[selectedKeys.length - 1]) {
      case '0': {
        this.setState({ selected: ['0'] })
        this.props.setTrue('showRouteByProximity', this.props)
        this.props.setFalse('showStaticFilter', this.props)
        break
      }
      case '1': {
        this.setState({ selected: ['1'] })
        this.props.setFalse('showStaticFilter', this.props)
        this.props.setFalse('showRouteByProximity', this.props)
        break
      }
      default:
        break
    }
  }

  removeSelected = ({ selectedKeys, key }) => {
    if (selectedKeys.length === 0) this.setState({ selected: ['1'] })
    else this.setState({ selected: selectedKeys })
    if (key === '1') {
      setFalse('showRouteByProximity', this.props)
    }
  }

  render() {
    let menu

    menu = (
      <Menu
        style={{
          width: '170px',
          height: Object.values(this.props.options).length > 13 ? this.props.height : 'auto',
          overflow: this.props.overflow,
        }}
        multiple={true}
        onSelect={this.saveSelected}
        onDeselect={this.removeSelected}
        selectedKeys={this.state.selected}
      >
        {Object.values(this.props.options).map((value, i) => (
          <MenuItem key={i}>{value}</MenuItem>
        ))}
      </Menu>
    )

    return (
      <Dropdown
        trigger={['hover']}
        onVisibleChange={this.onVisibleChange}
        visible={this.state.visible}
        closeOnSelect={false}
        overlay={menu}
        animation="slide-up"
      >
        <Icon size="small" name={'filter'} style={{ color: '#4b72b2' }} />
      </Dropdown>
    )
  }
}

const mapDispatchToProps = {
  setFalse: setFalse,
  setTrue: setTrue,
}

export default connect(null, mapDispatchToProps)(DropdownFilter)
