import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'

export type Roles = 'ADM' | 'SUP' | 'DEV' | 'USER' | 'OPE' | 'CC' | ''
type Action = 'READ' | 'WRITE' | 'DELETE' | 'DEBUG'
interface CanAccessProps {
  actions: Action | Action[]
  roles: Roles
  resource?: string
}

export const canAccess = ({ actions, roles, resource }: CanAccessProps) => {
  if (!actions) return false
  if (roles === 'ADM') return true
  if (roles === 'SUP') {
    if (actions === 'READ' || actions.includes('READ')) return true
    if (actions === 'WRITE' || actions.includes('WRITE')) return true
  }
  if (roles === 'DEV') {
    return true
  }
  if (roles === 'CC') {
    if (resource === 'news') return true
  }
  if (roles === 'USER') {
    if (actions === 'READ' || actions.includes('READ')) return true
  }
  return false
}

export const useCanAccess = (actions: Action | Action[]) => {
  const roles = useSelector((state: RootStore) => state.login.perfil)
  return canAccess({ actions, roles })
}
