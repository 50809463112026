/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onChangeAssetLocation = /* GraphQL */ `subscription OnChangeAssetLocation($tenant: String!) {
  onChangeAssetLocation(tenant: $tenant) {
    id
    assetId
    deviceId
    lat
    lng
    location
    fencesLocation
    groupsLocation
    analytics
    tenant
    time
    motion
    coords
    battery
    connectivity
    model_info
    sessionStatus
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeAssetLocationSubscriptionVariables,
  APITypes.OnChangeAssetLocationSubscription
>;
export const onChangeNotificationProfile = /* GraphQL */ `subscription OnChangeNotificationProfile($tenant: String!) {
  onChangeNotificationProfile(tenant: $tenant) {
    id
    name
    tenant
    bgColor
    status
    screenTime
    icon
    description
    level
    titleColor
    bodyColor
    actionColor
    dateColor
    txtColor
    createdBy
    theme
    iconColor
    sound
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeNotificationProfileSubscriptionVariables,
  APITypes.OnChangeNotificationProfileSubscription
>;
export const onDeleteNotificationProfile = /* GraphQL */ `subscription OnDeleteNotificationProfile($tenant: String!) {
  onDeleteNotificationProfile(tenant: $tenant) {
    id
    name
    tenant
    bgColor
    status
    screenTime
    icon
    description
    level
    titleColor
    bodyColor
    actionColor
    dateColor
    txtColor
    createdBy
    theme
    iconColor
    sound
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationProfileSubscriptionVariables,
  APITypes.OnDeleteNotificationProfileSubscription
>;
export const onChangeRule = /* GraphQL */ `subscription OnChangeRule($tenant: String!) {
  onChangeRule(tenant: $tenant) {
    id
    notificationProfileId
    notificationProfile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    name
    tenant
    status
    createdBy
    description
    action {
      type
      messageProfileId
    }
    message
    messageId
    scope
    sourceId
    sourceProp
    sourcePropValue
    sourceType
    targetProp
    targetPropValue
    targetId
    targetType
    threshold
    sendTo
    editable
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeRuleSubscriptionVariables,
  APITypes.OnChangeRuleSubscription
>;
export const onDeleteRule = /* GraphQL */ `subscription OnDeleteRule($tenant: String!) {
  onDeleteRule(tenant: $tenant) {
    id
    notificationProfileId
    notificationProfile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    name
    tenant
    status
    createdBy
    description
    action {
      type
      messageProfileId
    }
    message
    messageId
    scope
    sourceId
    sourceProp
    sourcePropValue
    sourceType
    targetProp
    targetPropValue
    targetId
    targetType
    threshold
    sendTo
    editable
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRuleSubscriptionVariables,
  APITypes.OnDeleteRuleSubscription
>;
export const onChangeMessageProfile = /* GraphQL */ `subscription OnChangeMessageProfile($tenant: String!) {
  onChangeMessageProfile(tenant: $tenant) {
    id
    tenant
    label
    type
    title
    body
    extra
    tags
    createdBy
    status
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeMessageProfileSubscriptionVariables,
  APITypes.OnChangeMessageProfileSubscription
>;
export const onDeleteMessageProfile = /* GraphQL */ `subscription OnDeleteMessageProfile($tenant: String!) {
  onDeleteMessageProfile(tenant: $tenant) {
    id
    tenant
    label
    type
    title
    body
    extra
    tags
    createdBy
    status
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMessageProfileSubscriptionVariables,
  APITypes.OnDeleteMessageProfileSubscription
>;
export const onChangeNotification = /* GraphQL */ `subscription OnChangeNotification($tenant: String!) {
  onChangeNotification(tenant: $tenant) {
    tenant
    assetId
    message
    title
    time
    type
    deviceId
    profileId
    profile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    ruleId
    confirmedBy
    confirmedAt
    closedBy
    closedAt
    location
    originType
    id
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeNotificationSubscriptionVariables,
  APITypes.OnChangeNotificationSubscription
>;
export const onDeleteNotification = /* GraphQL */ `subscription OnDeleteNotification($tenant: String!) {
  onDeleteNotification(tenant: $tenant) {
    tenant
    assetId
    message
    title
    time
    type
    deviceId
    profileId
    profile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    ruleId
    confirmedBy
    confirmedAt
    closedBy
    closedAt
    location
    originType
    id
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationSubscriptionVariables,
  APITypes.OnDeleteNotificationSubscription
>;
export const onChangeGeoModule = /* GraphQL */ `subscription OnChangeGeoModule($tenant: String!) {
  onChangeGeoModule(tenant: $tenant) {
    id
    tenant
    label
    code
    type
    geometry {
      type
      coordinates
      mRadius
    }
    profile
    profileInfo {
      id
      code
      tenant
      name
      type
      color
      icon
      description
      customFields {
        id
        label
        type
        options
        sectionId
      }
      properties
      status
      createdAt
      createdBy
      createForm
      updatedAt
      updatedBy
      updateForm
      formPermission
    }
    history {
      updatedAt
      updatedBy
      fields
    }
    properties
    description
    defaultInvisible
    state
    loadPreference
    createdAt
    createdBy
    createForm
    updatedBy
    updateForm
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeGeoModuleSubscriptionVariables,
  APITypes.OnChangeGeoModuleSubscription
>;
export const onDeleteGeoModule = /* GraphQL */ `subscription OnDeleteGeoModule($tenant: String!) {
  onDeleteGeoModule(tenant: $tenant) {
    id
    tenant
    label
    code
    type
    geometry {
      type
      coordinates
      mRadius
    }
    profile
    profileInfo {
      id
      code
      tenant
      name
      type
      color
      icon
      description
      customFields {
        id
        label
        type
        options
        sectionId
      }
      properties
      status
      createdAt
      createdBy
      createForm
      updatedAt
      updatedBy
      updateForm
      formPermission
    }
    history {
      updatedAt
      updatedBy
      fields
    }
    properties
    description
    defaultInvisible
    state
    loadPreference
    createdAt
    createdBy
    createForm
    updatedBy
    updateForm
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGeoModuleSubscriptionVariables,
  APITypes.OnDeleteGeoModuleSubscription
>;
export const onChangeReport = /* GraphQL */ `subscription OnChangeReport($tenant: String!) {
  onChangeReport(tenant: $tenant) {
    id
    code
    tenant
    label
    scope
    startTime
    endTime
    description
    filters
    hiddenColumns
    groupBy
    sortBy {
      desc
      id
    }
    access
    createdBy
    createForm
    updatedBy
    updateForm
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeReportSubscriptionVariables,
  APITypes.OnChangeReportSubscription
>;
export const onDeleteReport = /* GraphQL */ `subscription OnDeleteReport($tenant: String!) {
  onDeleteReport(tenant: $tenant) {
    id
    code
    tenant
    label
    scope
    startTime
    endTime
    description
    filters
    hiddenColumns
    groupBy
    sortBy {
      desc
      id
    }
    access
    createdBy
    createForm
    updatedBy
    updateForm
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReportSubscriptionVariables,
  APITypes.OnDeleteReportSubscription
>;
export const onChangeProfile = /* GraphQL */ `subscription OnChangeProfile($tenant: String!) {
  onChangeProfile(tenant: $tenant) {
    id
    code
    tenant
    name
    type
    color
    icon
    description
    customFields {
      id
      label
      type
      options
      sectionId
    }
    properties
    status
    createdAt
    createdBy
    createForm
    updatedAt
    updatedBy
    updateForm
    formPermission
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeProfileSubscriptionVariables,
  APITypes.OnChangeProfileSubscription
>;
export const onDeleteProfile = /* GraphQL */ `subscription OnDeleteProfile($tenant: String!) {
  onDeleteProfile(tenant: $tenant) {
    id
    code
    tenant
    name
    type
    color
    icon
    description
    customFields {
      id
      label
      type
      options
      sectionId
    }
    properties
    status
    createdAt
    createdBy
    createForm
    updatedAt
    updatedBy
    updateForm
    formPermission
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProfileSubscriptionVariables,
  APITypes.OnDeleteProfileSubscription
>;
export const onChangeDashboard = /* GraphQL */ `subscription OnChangeDashboard($tenant: String!) {
  onChangeDashboard(tenant: $tenant) {
    id
    code
    tenant
    label
    description
    panels
    timeType
    startTime
    endTime
    access
    showAsDefault
    createdBy
    createForm
    updatedBy
    updateForm
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnChangeDashboardSubscriptionVariables,
  APITypes.OnChangeDashboardSubscription
>;
export const onDeleteDashboard = /* GraphQL */ `subscription OnDeleteDashboard($tenant: String!) {
  onDeleteDashboard(tenant: $tenant) {
    id
    code
    tenant
    label
    description
    panels
    timeType
    startTime
    endTime
    access
    showAsDefault
    createdBy
    createForm
    updatedBy
    updateForm
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDashboardSubscriptionVariables,
  APITypes.OnDeleteDashboardSubscription
>;
