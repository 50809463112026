/*  Author: Felipe Pegado, Luís Mourão
    Type: Smart
    Description:  first component rendered; 
                  register service worker for version control;
                  sentry to catch errors;
    To do list: -Script de teste da troca de versão
*/

import React from 'react'
import { render } from 'react-dom'
import Routes from './Routes'
import { createBrowserHistory } from 'history'
import { unregister } from './registerServiceWorker'
import { initializeSentry, logError } from 'Utils/analytics'
import initializeI18n from 'Resources/Locale/i18n'
import { Provider } from 'react-redux'
import { store } from './Store/configureStore.js'
import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './Views/Authentication/authConfig'
import { MsalProvider } from '@azure/msal-react'
// import { Hub } from 'aws-amplify'

const msalInstance = new PublicClientApplication(msalConfig)
export const history = createBrowserHistory()

Amplify.configure(awsExports)

if (process.env.NODE_ENV === 'production') {
  try {
    initializeSentry('https://273d83e26eb14c3986a60a3c5e935baf@sentry.io/1427067')
  } catch (err) {
    console.error(err)
  }
}

try {
  initializeI18n()
} catch (e) {
  logError(e)
}

// Hub.listen('api', (data) => {
//   const { payload } = data
//   if (payload.event === 'ConnectionStateChange') {
//     const connectionState = payload.data.connectionState
//   }
// })

try {
  render(
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <Routes history={history} />
      </Provider>
    </MsalProvider>,
    document.getElementById('root')
  )
} catch (e) {
  logError(e)
}

unregister()

/* registerServiceWorker({
  onUpdated: () => {
    notificationState.addNotification({
      title: 'Atualização Disponível',
      message:
        'Acabamos de instalar uma nova versão, recarregue a página para atualizar!',
      status: NotificationStatus.SUCCESS,
      sticky: true,
      actions: {
        primary: [
          {
            run: () => document.location.reload(),
            label: 'Recarregar Página',
          },
        ],
      },
    });
  },
  onInstalled: () => {
  },
}) */
