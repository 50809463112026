import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useStoreSelector from 'Utils/hooks/useStoreSelector'
import PanelBodyKPI from './PanelBodyKPI'
import { handlePanelFetchResult } from 'Store/actions/betaDashboards-action'
import GraphDonut from 'Components/Cards/GraphDonut'
import GraphBars from 'Components/Cards/GraphBars'

interface Props {
  panelId: string
}

const PanelBody: React.FC<Props> = ({ panelId }) => {
  const dispatch = useDispatch()

  const type = useStoreSelector(
    (state) => state.betaDashboards.wip?.panels?.[panelId]?.type ?? state.betaDashboards.current?.panels[panelId]?.type
  )

  const isInitFetch = useStoreSelector(
    (state) => Object.keys(state.betaDashboards.current?.panels[panelId]?.filters ?? {}).length > 0
  )

  const refreshedAt = useStoreSelector((state) => state.betaDashboards.refreshedAt)

  const result = useStoreSelector((state) => state.betaDashboards.panelsInfo?.byId?.[panelId]?.result)

  const h = useStoreSelector(
    (state) =>
      state.betaDashboards.wip?.panels?.[panelId]?.layout?.h ??
      state.betaDashboards.current?.panels?.[panelId]?.layout?.h ??
      3
  )

  useEffect(() => {
    if (isInitFetch || refreshedAt) {
      dispatch(handlePanelFetchResult({ panelId }))
    }
  }, [isInitFetch, dispatch, panelId, refreshedAt])

  if (typeof result === 'number' || result?.length > 0) {
    switch (type) {
      case 'kpi':
        return <PanelBodyKPI panelId={panelId} />
      case 'donut':
        return Array.isArray(result) && result.length > 0 ? <GraphDonut data={result} /> : null
      case 'bars':
        return Array.isArray(result) && result.length > 0 ? <GraphBars data={result} /> : null
      default:
        return null
    }
  } else {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80%',
          fontWeight: '700',
          fontSize: `${20 + (h - 2) * 10}px`,
        }}
      >
        <span style={{ color: 'grey' }}>Sem Dados</span>
      </div>
    )
  }
}

export default PanelBody
