import { REPORT_URL } from 'Apis/rest'
import { API } from 'aws-amplify'
import axios from 'axios'
import { createDashboard, updateDashboard, deleteDashboard } from 'graphql/mutations'
import { listDashboardsByTenant } from 'graphql/queries'
import { onChangeDashboard, onDeleteDashboard } from 'graphql/subscriptions'
import { isNull, omitBy } from 'lodash'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'
import generateRandomCode from 'Utils/generateRandomCode'
import getFormattedTime from 'Utils/getFormatedTime'
import mergeObjects from 'Utils/mergeObjects'
import { v4 } from 'uuid'

export const setBetaDashboardProps = (payload) => (dispatch) => {
  dispatch({
    type: 'SET_BETA_DASHBOARD_PROPS',
    payload,
  })
}

export const fetchBetaDashboards = (token) => async (dispatch, getState) => {
  const { empresa: tenant, email } = getState().login

  try {
    if (!token) dispatch({ type: 'FETCH_BETA_DASHBOARDS_START', tenant })
    const response = await API.graphql({
      query: listDashboardsByTenant,
      variables: { tenant, nextToken: token },
    })
    const { items, nextToken } = response.data.listDashboardsByTenant
    dispatch({
      type: 'FETCH_BETA_DASHBOARDS_SUCCESS',
      payload: items,
      email,
    })
    if (nextToken) await dispatch(fetchBetaDashboards(nextToken))
  } catch (err) {
    console.error('error fetching dashboards', err)
    dispatch({
      type: 'FETCH_BETA_DASHBOARDS_FAILURE',
      err,
    })

    //O erro pode ocorrer em apenas poucos itens do total. Essa lógica faz com que os itens que não tiverem erros apareçam
    const items = err?.data?.listDashboardsByTenant?.items || []
    const nextToken = err?.data?.listDashboardsByTenant?.nextToken

    dispatch({
      type: 'FETCH_BETA_DASHBOARDS_SUCCESS',
      payload: items.filter((dashboard) => dashboard?.id),
      email,
    })

    if (nextToken) await dispatch(fetchBetaDashboards(nextToken))
  }
}

export const mutateBetaDashboard = () => async (dispatch, getState) => {
  const { empresa, email } = getState().login
  const fields = getState().betaDashboards.wip
  const formType = getState().betaDashboards.form.type
  const isEditing = getState().betaDashboards.form.isEditing
  const isFormOpen = getState().betaDashboards.form.open
  const mutation = isEditing || formType === 'filter' || !isFormOpen ? 'update' : 'create'
  const { id } = mutation === 'update' ? getState().betaDashboards.current ?? {} : {}

  const { createdAt, updatedAt, startTime, endTime, panels: wipPanels, ...rest } = fields

  const currentPanels = getState().betaDashboards.current.panels

  const mergedPanels = omitBy(mergeObjects(currentPanels, wipPanels) ?? {}, isNull)

  const randomId = v4()

  const panels =
    mutation === 'create'
      ? {
          [randomId]: {
            id: randomId,
            label: 'Painel 01',
            layout: { x: 0, y: 0, w: 3, h: 3 },
          },
        }
      : mergedPanels

  const code =
    mutation === 'create' && !fields.code ? generateRandomCode({ firstSegLen: 3, secondSegLen: 2 }) : fields.code

  let input = {
    ...(id && { id }),
    ...rest,
    ...(code && { code }),
    panels: JSON.stringify(panels),
    tenant: empresa,
    ...(mutation === 'create' ? { createdBy: email } : {}),
    createForm: 'platform',
    updatedBy: email,
    updateForm: 'platform',
    ...(startTime ? { startTime: getFormattedTime(startTime) } : {}),
    ...(endTime ? { endTime: getFormattedTime(endTime) } : {}),
  }

  dispatch({ type: 'SAVE_BETA_DASHBOARDS_START', input, mutation })

  try {
    const query = mutation === 'update' ? updateDashboard : createDashboard
    const { data } = await API.graphql({ query, variables: { input } })
    dispatch({
      type: 'SAVE_BETA_DASHBOARDS_SUCCESS',
      payload: data?.[mutation === 'update' ? 'updateDashboard' : 'createDashboard'],
      email,
    })
    notifySuccess()
    return code
  } catch (error) {
    console.error({ error, input })
    dispatch({ type: 'SAVE_BETA_DASHBOARDS_FAILURE', input, mutation })
    notifyError()
  }
}

export const subscribeDashboard = (type) => (dispatch, getState) => {
  const isDelete = type === 'delete'
  const tenant = getState().login.empresa
  return API.graphql({
    query: isDelete ? onDeleteDashboard : onChangeDashboard,
    variables: {
      tenant,
    },
  }).subscribe({
    next: ({ provider, value }) => {
      const payload = value.data?.[isDelete ? 'onDeleteDashboard' : 'onChangeDashboard']
      isDelete
        ? dispatch({ type: 'DELETE_BETA_DASHBOARD_SUCCESS', payload })
        : dispatch({ type: 'SAVE_BETA_DASHBOARD_SUCCESS', payload })
    },
    error: (error) => console.warn('error', error),
  })
}

export const clearBetaDashboardForm = () => {
  return { type: 'CLEAR_BETA_DASHBOARD_FORM' }
}

export const addNewDashboardPanel = () => {
  return {
    type: 'ADD_NEW_DASHBOARD_PANEL',
  }
}

export const openPanelForm = (panelId) => {
  return {
    type: 'OPEN_PANEL_FORM',
    payload: { panelId },
  }
}

export const openDashboardForm = ({ isEditing }) => {
  return {
    type: 'OPEN_DASHBOARD_FORM',
    payload: { isEditing },
  }
}

// Essa ação foi criada para osquertar os fetches por contexto
export const handlePanelFetchResult =
  ({ panelId }) =>
  async (dispatch, getState) => {
    const current = getState().betaDashboards.current.panels[panelId]
    const wip = getState().betaDashboards.wip.panels?.[panelId] ?? {}

    const panel = mergeObjects(current, wip)

    const { scope, startTime, endTime, type } = panel
    const { timeProp, ...others } = panel.filters

    const payload = {
      startTime,
      endTime,
      type,
      ...others,
    }

    switch (scope) {
      case 'references':
        dispatch(fetchPanelRefsResult({ panelId }))
        break
      case 'rules':
        dispatch(fetchPanelResult({ path: 'ruleTriggers', payload, panelId }))
        break
      default:
        break
    }
  }

// Essa ação foi criada para formatar o payload de entrada do endpoint do contexto refs
export const fetchPanelRefsResult =
  ({ panelId }) =>
  async (dispatch, getState) => {
    const current = getState().betaDashboards.current.panels[panelId]
    const wip = getState().betaDashboards.wip.panels?.[panelId] ?? {}

    const panel = mergeObjects(current, wip)

    const payload = {
      startTime: panel.startTime,
      endTime: panel.endTime,
      type: panel.type,
      ...panel.filters,
    }

    dispatch(fetchPanelResult({ path: 'fetchGeoModules', payload, panelId }))
  }

export const fetchPanelResult =
  ({ path, payload, panelId }) =>
  async (dispatch, getState) => {
    const jwt = localStorage.getItem('id_token')

    dispatch({ type: 'FETCH_PANEL_RESULT_START', payload: { ...payload, panelId } })

    try {
      const response = await axios.post(`${REPORT_URL}/${path}`, payload, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })

      if (response.data?.errorType?.includes('ResponseSizeTooLarge')) {
        throw new Error('ResponseSizeTooLarge')
      }

      dispatch({
        type: 'FETCH_PANEL_RESULT_SUCCESS',
        payload: { data: response.data, panelId },
      })
    } catch (err) {
      console.error(err)
      dispatch({
        type: 'FETCH_PANEL_RESULT_FAILURE',
        payload: { err: err.message || err, panelId },
      })
      if (err?.response?.data?.message === 'Endpoint request timed out')
        notifyError({ message: 'Tempo limite de consulta excedido. Tente outro intervalo de tempo' })
      else {
        console.warn('Checar filtro', payload)
        //notifyError({ message: 'Filtro inadequado' })
      }
    }
  }

export const deleteDashboardPanel = (panelId) => {
  return {
    type: 'DELETE_DASHBOARD_PANEL',
    payload: { panelId },
  }
}

export const deleteDashboards = (ids) => async (dispatch, getState) => {
  dispatch({ type: 'DELETE_DASHBOARD_START', payload: { ids } })

  const results = []

  for (const id of ids) {
    try {
      await API.graphql({
        query: deleteDashboard,
        variables: {
          input: {
            id,
          },
        },
      })
      results.push({ status: 'success', id })
    } catch (err) {
      console.error('error:', err)
      results.push({ status: 'failure', id })
    }
  }

  const successes = results.filter((result) => result.status === 'success').map((result) => result.id)
  if (successes.length > 0) {
    dispatch({ type: 'DELETE_DASHBOARD_SUCCESS', payload: { ids: successes } })
    notifySuccess()
  }

  const failures = results.filter((result) => result.status === 'failure').map((result) => result.id)
  if (failures.length > 0) {
    dispatch({ type: 'DELETE_DASHBOARD_FAILURE', payload: { ids: failures } })
    notifyError()
  }
}

export const setPanelProp = ({ panelId, field, value }) => {
  return {
    type: 'SET_PANEL_PROP',
    payload: { panelId, field, value },
  }
}
