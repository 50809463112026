import React from 'react'
import { clearActivityForm, mutateActivity, setActivitiesProps, setActivityWIP } from 'Store/actions/activities-action'
import { useDispatch } from 'react-redux'
import ActivitiesIdentificationForm from 'Components/forms/activities/ActivitiesIdentificationForm'
import ActivitiesLocationForm from 'Components/forms/activities/ActivitiesLocationForm'
import StepForm from '../StepForm'
import steps from '../activities/schemas/steps'

function ActivitiesForm() {
  const dispatch = useDispatch()

  return (
    <StepForm
      entity="activities"
      widths={2}
      schemas={steps}
      forms={[<ActivitiesIdentificationForm />, <ActivitiesLocationForm />]}
      setProps={setActivitiesProps}
      values={[{ fields: ['code', 'name'] }, {}]}
      onSave={() => dispatch(mutateActivity())}
      onClose={() => {
        dispatch(setActivityWIP())
        dispatch(clearActivityForm())
      }}
    />
  )
}

export default ActivitiesForm
