import React, { useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import DropdownStoreField from './DropdownStoreField'
import { fetchReportsCategoryMeasures } from 'Store/actions/reports-action'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { jsonGroup } from 'Components/Map/PopupMarker/jsonGroup'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'

interface GenericCategoriesMeasuresFieldsProps {
  setProps: (field: string, value: any) => void
}

const GenericCategoriesMeasuresFields: React.FC<GenericCategoriesMeasuresFieldsProps> = ({ setProps }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const typedJsonGroup: Record<string, any> = jsonGroup

  const loadingCategories = useSelector((state: RootStore) => state.reports.loadingCategories)
  const categoryOptions = useObjSelector((state) => [
    ...state.reports.categoryOptions,
    { key: '*', text: 'Todas as Categorias', value: '*', style: { fontWeight: 'bold' } }, // Add this item at end
  ])
  const category = useSelector(
    (state: RootStore) => state.reports.wip.filters?.category || state.reports.current?.filters?.category
  )
  const loadingMeasures = useSelector(
    (state: RootStore) => state.reports.measuresByCategory[category as string]?.loading
  )
  const measuresOptions = useObjSelector(
    (state) => state.reports.measuresByCategory[category as string]?.measures || []
  )
  const isDevUser = useSelector((state: RootStore) => state.login.perfil === 'DEV')
  const loginProfile = useSelector((state: RootStore) => state.login.perfil)

  useEffect(() => {
    if (category && (!measuresOptions || measuresOptions.length === 0)) {
      dispatch(fetchReportsCategoryMeasures(category))
    }
  }, [category, dispatch, setProps, measuresOptions])

  const disableMeasures = !category || (Array.isArray(category) && category.length !== 1)

  const formatedCatOpts = categoryOptions
    .filter((each) => loginProfile === 'DEV' || typedJsonGroup[each.value as string]?.levelAccess === 'basic')
    .map((cat) => {
      return {
        key: cat.key,
        value: cat.value,
        text: capitalize(t(cat.text as string)),
        icon: typedJsonGroup[cat.value as string] ? typedJsonGroup[cat.value as string]?.getIcon() : 'bug',
      }
    })

  return (
    <Form.Group widths="equal">
      <DropdownStoreField
        entity="reports"
        label="Categoria(s)"
        value={'filters.category'}
        placeholder={'Selecionar Categoria'}
        name="category"
        onChange={(_, { value }) => {
          if (value.includes('*')) {
            setProps(
              'wip.filters.category',
              categoryOptions
                .filter((e) => typeof e === 'string' || e?.value !== '*')
                .map((e) => (typeof e === 'string' ? e : e?.value))
            )
          } else {
            setProps('wip.filters.category', value)
          }
        }}
        options={formatedCatOpts}
        noResultsMessage={'Nenhum resultado encontrado no filtro selecionado.'}
        loading={loadingCategories}
        multiple={isDevUser}
        required
        sort
      />
      <DropdownStoreField
        entity="reports"
        key="measures"
        label="Medida(s)"
        value={'filters.measures'}
        placeholder={loadingMeasures ? 'Carregando...' : 'Selecionar Medida(s)'}
        name="measures"
        onChange={(_, { value }) => setProps('wip.filters.measures', value)}
        options={measuresOptions}
        noResultsMessage={'Nenhum resultado encontrado no filtro selecionado.'}
        loading={loadingMeasures}
        disabled={disableMeasures}
        multiple
      />
    </Form.Group>
  )
}

export default GenericCategoriesMeasuresFields
