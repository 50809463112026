export const FILTER_MARKERS = 'FILTER_MARKERS'
export const FILTER_TIME = 'FILTER_TIME'

export const filterMarkers = (value, field) => ({
  type: FILTER_MARKERS,
  id: '',
  payload: { value: value, field: field },
})

export const filterTime = (data) => ({
  type: FILTER_TIME,
  id: '',
  payload: data,
})

export const setSearch = (payload) => ({
  type: 'SET_SEARCH',
  payload: payload,
})

export const setFilter = (text, result) => ({
  type: 'SET_FILTER',
  payload: { searchText: text, result: result },
})
