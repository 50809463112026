import React, { Fragment, useCallback, useEffect, Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Grid, Dropdown, List, Segment, Form, Checkbox, Divider } from 'semantic-ui-react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { difference, union } from 'lodash'
import { UseGroupByRowProps, UseTableRowProps } from 'Components/SidebarMenu/Contents/react-table-config'
import { RootStore } from 'Store/initialStore'
import { saveRule } from 'Store/actions/rules-action'
import styled from 'styled-components'
/*  Author: Bruno Melo
    Type: Smart
	Description: Content of Groups Actions;
	To do list: -

*/
const SelectedsSpan = styled.span`
    display: block;
    margin: 0 0 .28571429rem 0;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
}`

interface FenceBaseRow {
  rulesText: string
  groups: string[]
  id: string
  label: string
  properties: any
}

interface SelectedItem extends UseGroupByRowProps<FenceBaseRow>, UseTableRowProps<FenceBaseRow> {}
type GroupActions = 'updateRules' | 'insert' | 'update' | 'delete' | ''
interface UpdateChanges {
  groupName: string
  selected: string[]
  type: GroupActions
}
interface GroupsReactTableProps {
  table: string
  selected: SelectedItem[]
  groupsOpen: boolean
  setGroupsOpen: (b: boolean) => void
  setGroupsAction: Dispatch<SetStateAction<GroupActions>>
}

export const GroupsReactTable = ({
  setGroupsAction,
  groupsOpen,
  setGroupsOpen,
  table,
  selected,
}: GroupsReactTableProps) => {
  const dispatch = useDispatch()
  if (table === 'people') table = 'assets'
  const [updateChanges, setUpdateChanges] = React.useState<UpdateChanges[]>([])
  const [selectedState, setSelectedState] = React.useState<FenceBaseRow[]>([])
  const rules = useSelector((state: RootStore) => state.rules.byId, shallowEqual)
  const rulesIds = useSelector((state: RootStore) => state.rules.allIds, shallowEqual)
  const [groupsValue, setGroupsValue] = React.useState<string[]>([])
  const [errorState, setErrorState] = React.useState('')
  const getOptions = useCallback(
    () =>
      rulesIds
        .filter((r) => rules[r]?.id && rules[r]?.targetType === 'fence' && rules[r]?.editable === true)
        .map((r) => ({ text: (rules[r]?.id || '') + ' - ' + (rules[r]?.name || ''), value: rules[r]?.id || '' }))
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))
        .map((g) => ({ key: 'saveRules' + g.value, value: g.value, text: g.text })),
    [rulesIds, rules]
  )

  useEffect(() => {
    if (!selected[0] || table !== 'fences') return
    setGroupsValue(selected[0].original.rulesText?.split(',').map((i) => i.trim()))
    setSelectedState(selected.filter((s) => !s.isGrouped).map((s) => s.original))
  }, [selected, table])

  const handleChange = (e: any, { value }: any) => {
    let removeThisGroups = difference(groupsValue, value)
    let selectedNames = selected.filter((s) => !s.isGrouped).map((s) => s.original.label)
    let changes = union<UpdateChanges>(updateChanges, [
      { groupName: removeThisGroups[0], selected: selectedNames, type: 'delete' },
    ])
    setUpdateChanges(changes)
    setGroupsValue(value)
    setErrorState('')
  }

  const clearStates = () => {
    setErrorState('')
    setGroupsAction('')
    setGroupsOpen(false)
    setUpdateChanges([])
  }

  const handleSave = () => {
    if (errorState) {
      return
    }
    const original = selected[0].original.rulesText?.split(',').map((i) => i.trim())
    const add = difference(groupsValue, original)
    const sub = difference(original, groupsValue)
    add.forEach((v) => {
      if (rules[v]?.targetId?.includes(selected[0].original.id)) return
      dispatch(
        saveRule({
          mutation: 'update',
          fields: {
            ...rules[v],
            //@ts-ignore
            targetId: [...rules[v]?.targetId, selected[0].original.id],
          },
        })
      )
    })
    sub.forEach((v) => {
      dispatch(
        saveRule({
          mutation: 'update',
          fields: {
            ...rules[v],
            targetId: rules[v]?.targetId?.filter((f) => f !== selected[0].original.id),
          },
        })
      )
    })
    clearStates()
  }

  return (
    <ModalTemplate
      size="small"
      onClose={() => clearStates()}
      open={groupsOpen}
      header="Editar Regras"
      saveContent={'Salvar'}
      saveColor={'green'}
      onSave={handleSave}
      onCancel={() => clearStates()}
    >
      <Grid>
        <Grid.Column key={'col1e'}>
          <Segment key={'segGroups' + table} style={{ border: 'none', boxShadow: 'none' }}>
            <List key={'lis1'}>
              <List.Header>
                <Segment style={{ border: 'none', boxShadow: 'none', padding: '0 1em' }}>
                  <Form>
                    <Form.Field>
                      <label>'Regras</label>
                      <Dropdown
                        fluid
                        search
                        selection
                        multiple={true}
                        key={'dropdownSaveGroups'}
                        onChange={handleChange}
                        placeholder={`Escolha uma regra`}
                        value={groupsValue}
                        noResultsMessage={'Sem resultados'}
                        options={getOptions()}
                      />
                    </Form.Field>
                  </Form>
                </Segment>
              </List.Header>
              <Divider />
              <Segment
                key={'segGroupslist' + table}
                style={{ border: 'none', overflowY: 'auto', maxHeight: '27em', boxShadow: 'none' }}
              >
                <SelectedsSpan>Itens Selecionados:</SelectedsSpan>
                {selectedState?.map((s) => (
                  <List.Item key={'listItem' + s?.label}>
                    {s?.label && (
                      <Fragment key={'frag' + s?.label}>
                        <Checkbox key={'chkbox' + s?.label} label={s?.label} name={s?.label} checked={true} disabled />
                        <br />
                      </Fragment>
                    )}
                  </List.Item>
                ))}
              </Segment>
            </List>
          </Segment>
        </Grid.Column>
      </Grid>
    </ModalTemplate>
  )
}

export default GroupsReactTable
