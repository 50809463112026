export default function signalsReducer(state = [], { type, id, payload }) {
  let newState = ''
  switch (type) {
    case 'signalsInitialStore':
      if (payload === null) {
        console.error('Error in signals initial store')
        return []
      }
      if (!payload[0]) {
        console.warn('No signals in store!')
        return []
      }
      if (!payload[0].signals) {
        console.warn("Signal's store empty!")
        return []
      }
      return payload[0].signals

    case 'signalNew':
      newState = Object.values(state)
      newState.unshift(payload)
      return { ...newState }

    case 'atualizaSinal':
      newState = Object.assign({}, state)
      Object.entries(newState).forEach((data) => {
        if (id === data[1].id) {
          //console.log('Paypay', payload);
          switch (payload.campo) {
            case 'Cor':
              newState[data[0]].info.color = payload.valor
              break
            case 'Nome':
              newState[data[0]].name = payload.valor
              break
            default:
              console.log('Erro no signals reducer, atualiza Sinal sem campo preparado para atualizar')
          }
        }
      })
      return Object.assign({}, state, newState)

    case 'updatesignalNew':
      newState = Object.assign({}, state)
      Object.entries(newState).forEach((data) => {
        if (payload.id === data[1].id) {
          newState[data[0]].name = payload.name
          return newState
        }
      })
      return Object.assign({}, state, newState)

    case 'deletaSinais':
      newState = state
      Object.entries(newState).forEach((data) => {
        if (payload.indexOf(data[1].id) !== -1) delete newState[data[0]]
      })
      return { ...newState }

    case 'updatedeletesignalNew':
      newState = Object.assign({}, state)
      Object.entries(newState).forEach((data) => {
        if (payload.id === data[1].id) delete newState[data[0]]
      })
      return Object.assign({}, newState)
    default:
      return state
  }
}
