import React from 'react'
import { Form, FormProps } from 'semantic-ui-react'
import styled from 'styled-components'

interface FormGroupProps extends FormProps {
  children: React.ReactNode
}

const FormGroup = ({ children, ...rest }: FormGroupProps) => <StyledFormGroup {...rest}>{children}</StyledFormGroup>

const StyledFormGroup = styled(Form.Group)`
  margin-bottom: 25px !important;
`

export default FormGroup
