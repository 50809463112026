export const getMinTime = (allIds, byId, min) => {
  if (!(Array.isArray(allIds) && allIds.length > 0)) return null
  if (!byId) return null

  let id = allIds.find((e) => {
    if (byId[e]) return byId[e].byIndex[min]
    else return false
  })

  if (byId[id] && byId[id].byIndex[min]) return byId[id].byIndex[min].info.time
}

export const getMaxTime = (allIds, byId, max) => {
  if (!(Array.isArray(allIds) && allIds.length > 0)) return null
  if (!byId) return null

  const id = allIds.find((e) => {
    if (byId[e]) return byId[e].byIndex[max]
    else return false
  })

  if (byId[id] && byId[id].byIndex[max]) return byId[id].byIndex[max].info.time
  else return null
}
