import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { RootStore } from 'Store/initialStore'
import { useHistory } from 'react-router-dom'
import { Checkbox, Form, Popup } from 'semantic-ui-react'
import StepForm from 'Components/forms/StepForm'
import createStepOneSchema from './schemas/stepId'
import InputStoreField from 'Components/forms/generic/InputStoreField'
import TextAreaStoreField from 'Components/forms/generic/TextAreaStoreField'
import { clearBetaDashboardForm, mutateBetaDashboard, setBetaDashboardProps } from 'Store/actions/betaDashboards-action'

function DashboardForm() {
  const dispatch = useDispatch()
  const history = useHistory()

  const step = useObjSelector((state) => state.betaDashboards.form.steps?.items[0])
  const errors = useObjSelector((state) => state.betaDashboards.form.errors?.[0])
  const currentId = useSelector((state: RootStore) => state.betaDashboards.current?.id)
  const access = useSelector(
    (state: RootStore) => state.betaDashboards.wip?.access ?? state.betaDashboards.current?.access
  )
  const createdBy = useSelector((state: RootStore) => state.betaDashboards.current?.createdBy)
  const login = useSelector((state: RootStore) => state.login.email)

  const isEditing = useSelector((state: RootStore) => state.betaDashboards.form.isEditing)

  const betaDashboardsById = useObjSelector((state) => state.betaDashboards.byId)

  const uniqueCodes = useMemo(() => {
    return [...new Set(Object.values(betaDashboardsById).map((betaDashboard) => betaDashboard.code))]
  }, [betaDashboardsById])

  const uniqueLabels = useMemo(() => {
    return [...new Set(Object.values(betaDashboardsById).map((betaDashboard) => betaDashboard.label))]
  }, [betaDashboardsById])

  const stepOneSchema = createStepOneSchema(uniqueCodes, uniqueLabels, !!currentId)

  return (
    <StepForm
      entity="betaDashboards"
      schemas={[stepOneSchema]}
      forms={[
        <>
          <Form.Group widths={16}>
            <InputStoreField
              width={6}
              entity="betaDashboards"
              label="Código"
              name="code"
              placeholder="Digite o Código de identificação"
              value={'code'}
              onChange={(_, { name, value }) => dispatch(setBetaDashboardProps([[`wip.${name}`, value]]))}
              required={false}
              showFeedback={step.showFeedback}
              error={errors?.code}
              maxLength={8}
              disabled={isEditing}
            />
            <InputStoreField
              width={10}
              entity="betaDashboards"
              label="Nome"
              name="label"
              placeholder="Nomeie o relatório"
              value={'label'}
              onChange={(_, { name, value }) => dispatch(setBetaDashboardProps([[`wip.${name}`, value]]))}
              required={true}
              showFeedback={step.showFeedback}
              error={errors?.label}
            />
          </Form.Group>
          <br />
          <TextAreaStoreField
            entity="dashboards"
            label="Descrição"
            name="description"
            placeholder="Descreva o relatório"
            value={'description'}
            onChange={(_, { name, value }) => dispatch(setBetaDashboardProps([[`wip.${name}`, value]]))}
          />
          <br />
          {!createdBy ||
            (login === createdBy && (
              <Popup
                trigger={
                  <Checkbox
                    label="Disponível para todos usuários da organização"
                    onChange={(_, { checked }) => {
                      dispatch(setBetaDashboardProps([[`wip.access`, checked ? 'PUBLIC' : 'PRIVATE']]))
                    }}
                    checked={access === 'PUBLIC'}
                  />
                }
                content="Ao marcar essa opção todos os usuários com permissão de acesso poderão visualizar esse relatório."
                inverted
                position="left center"
              />
            ))}
        </>,
      ]}
      setProps={setBetaDashboardProps}
      values={[{ fields: ['scope'] }, {}]}
      onSave={async () => {
        if (isEditing) {
          dispatch(mutateBetaDashboard())
        } else {
          try {
            const newCode = await dispatch(mutateBetaDashboard())
            !isEditing && history.push(`/app/dashboards/${newCode}`)
          } catch (error) {
            console.error('Erro ao mutar o betaDashboard:', error)
          }
        }
      }}
      onClose={() => {
        dispatch(clearBetaDashboardForm())
        dispatch(setBetaDashboardProps([[`form.isEditing`, false]]))
      }}
      fixedHeight="70vh"
      saveContent="Salvar"
    />
  )
}

export default DashboardForm
