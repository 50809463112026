import { point, featureCollection } from '@turf/helpers'
import transformRotate from '@turf/transform-rotate'
import distance from '@turf/distance'
import center from '@turf/center'
import bearing from '@turf/bearing'
import getSafe from 'Utils/getSafe.js'
import L from 'leaflet'

export default function innerMeasures(corners, company, item) {
  //console.log('item', item)
  let res = {}
  let turfPoints = [
    point([corners[0].lng, corners[0].lat]),
    point([corners[1].lng, corners[1].lat]),
    point([corners[3].lng, corners[3].lat]),
    point([corners[2].lng, corners[2].lat]),
  ]
  let obj = {}
  turfPoints.forEach((l0, i0) =>
    turfPoints.forEach((l1, i1) => {
      if (i0 < i1) {
        let turfDistance = distance(l0, l1, { units: 'meters' })
        obj[i0.toString() + i1.toString()] = turfDistance
      }
    })
  )
  let orderObj = Object.entries(obj).sort((a, b) => {
    return b[1] - a[1]
  })
  //console.log(orderObj)
  let vertices = item?.info?.orientantion === 'portrait' ? orderObj[4][0] : orderObj[3][0]
  let angle = bearing(turfPoints[vertices[1]], turfPoints[vertices[0]])
  res.bearing = angle > 0 ? angle - 90 : angle + 90
  res.center = center(featureCollection(turfPoints))?.geometry?.coordinates
  let rotate = transformRotate(featureCollection(turfPoints), res.bearing * -1, {
    pivot: res.center,
  }).features
  res.corners = [
    getSafe(() => new L.latLng([rotate[0].geometry.coordinates[1], rotate[0].geometry.coordinates[0]]), null),
    getSafe(() => new L.latLng([rotate[1].geometry.coordinates[1], rotate[1].geometry.coordinates[0]]), null),
    getSafe(() => new L.latLng([rotate[3].geometry.coordinates[1], rotate[3].geometry.coordinates[0]]), null),
    getSafe(() => new L.latLng([rotate[2].geometry.coordinates[1], rotate[2].geometry.coordinates[0]]), null),
  ]
  return res
}
