import React, { FunctionComponent, MouseEventHandler } from 'react'
import styled from 'styled-components'

const StyledSpan = styled.span<{
  minWidth: string
  width: string
  padding: string
  capitalize: boolean
}>`
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ width }) => width};
  display: inline-block;
  vertical-align: bottom;
  padding: ${({ padding }) => padding} !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'none')};
`
const StyledAnchor = styled(StyledSpan)`
  cursor: pointer;
  text-decoration: none;
  color: #4b72b2;
  &:hover {
    text-decoration: underline;
  }
`

interface TableSpanProps {
  value: string
  isLink?: boolean
  width?: string
  capitalize?: boolean
  padding?: string
  minWidth?: string
  noTitle?: boolean
  onClick?: MouseEventHandler<HTMLSpanElement>
  onMouseDown?: MouseEventHandler<HTMLSpanElement>
}

const TableSpan: FunctionComponent<TableSpanProps> = ({
  value,
  isLink = false,
  width = '350px',
  capitalize = false,
  padding = '10px',
  minWidth = '0px',
  noTitle = false,
  onClick,
  onMouseDown,
}) => {
  const Component = isLink ? StyledAnchor : StyledSpan

  return (
    <Component
      title={noTitle ? undefined : value}
      width={width}
      capitalize={capitalize}
      padding={padding}
      minWidth={minWidth}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {value}
    </Component>
  )
}
export default TableSpan
