import * as z from 'zod'

const stepOneSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, 'form.error.min').max(80, 'form.error.max'),
  code: z.string().optional(),
  description: z.string().optional().nullable(),
})

export type StepOne = z.infer<typeof stepOneSchema>

export default stepOneSchema
