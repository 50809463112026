import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { showConfirm, hideConfirm } from 'Store/actions/system-action'
import { saveGeoModule } from 'Store/actions/modules-action'
import { setFalse } from 'Store/actions/toggle-action'
import { changeLoginPreferences } from 'Store/actions/login-action'
import { GeoModuleStates } from 'API'
interface Props {
  id: string
  moduleType: string
  label?: string | null
  state?: GeoModuleStates | null
}

const SideFormStateAction: React.FC<Props> = ({ id, moduleType, label, state }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isClosed = state === 'closed'

  const currentProps = {
    label: isClosed ? 'Reativar' : 'Encerrar',
    icon: isClosed ? 'redo' : 'check',
    color: isClosed ? 'yellow' : 'green',
    question:
      t('areYouSureYouWishToContinue') +
      (isClosed
        ? `Ao Confirmar este(a) ${t(
            moduleType.slice(0, -1)
          )} ficará marcado(a) como reativado voltando a aparecer em Relatórios e no Tempo Real.`
        : `Ao Confirmar este(a) ${t(
            moduleType.slice(0, -1)
          )} ficará marcado(a) como encerrado(a) não aparecendo mais, exceto em Relatórios.`),
  }

  const currentName = `${currentProps.label}${label?.split(']').pop()?.trim() || ''}`

  const handleClick = () => {
    const data = {
      content: (
        <div style={{ height: 'auto', fontSize: '1.5em', padding: '10px' }}>
          <span>{currentProps.question}</span>
        </div>
      ),
      onConfirm: () => {
        dispatch(
          saveGeoModule({
            mutation: 'update',
            fields: {
              id,
              state: isClosed ? 'reactivated' : 'closed',
              label: currentName,
            },
            type: moduleType,
          })
        )
        dispatch(hideConfirm())
        dispatch(
          changeLoginPreferences({
            field: moduleType,
            preferences: { [id]: { id, invisible: !isClosed } },
          })
        )
        dispatch(setFalse('showStatsBar'))
      },
      onCancel: () => dispatch(hideConfirm()),
      confirmHeader: `${currentProps.label} ${t(moduleType.slice(0, -1))} "${label}"`,
    }
    dispatch(showConfirm(data))
  }

  return (
    <Button
      circular
      icon={currentProps.icon}
      title={currentProps.label}
      style={{
        cursor: 'pointer',
      }}
      onClick={handleClick}
    />
  )
}
export default SideFormStateAction
