import { CustomField } from 'API'
import _ from 'lodash'
import { Layout } from 'react-grid-layout'

export interface handleLayoutChangeParams {
  newLayout: Layout[]
  customFields: CustomField[]
  customFieldsById?: _.Dictionary<CustomField>
  sectionState?: Record<
    string,
    {
      isExpanded: boolean
    }
  >
}

const handleLayoutChange = ({
  newLayout,
  customFields,
  sectionState,
  customFieldsById,
}: handleLayoutChangeParams): CustomField[] => {
  console.log('input', {
    newLayout,
    customFields,
    sectionState,
    customFieldsById,
  })
  //condições necessárias:
  // Só ordenar seções se todas as seções estiverem colapsadas

  //casos de uso:
  // - Ordenar seções colapsadas
  // - Ordenar entre campos normais dentro de uma seção
  // - Ordenar entre campos normais fora de uma seção existente
  // - Ordenar entre campos normais sem seção existente
  // - Mudar campo normal de uma seção para outra
  let currentSectionId: CustomField['sectionId'] = null

  const result = newLayout.reduce((acc: CustomField[], curr) => {
    const iSplit = curr.i.split('#')
    const cfId = iSplit[1]
    const cf = customFieldsById?.[cfId]
    if (!cf) return acc

    if (cf.type === 'section') {
      currentSectionId = cfId
    }

    if (cf.type === 'section' && !sectionState?.[cfId]?.isExpanded) {
      acc = [...acc, cf, ...customFields.filter((field) => field.sectionId === cfId)]
    } else {
      acc = [
        ...acc,
        {
          ...cf,
          sectionId: cf.sectionId ? currentSectionId : null,
        },
      ]
    }

    return acc
  }, [])

  console.log('result:', result)
  return result
}

export default handleLayoutChange
