import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts'

/**
 * GraphBars component renders a bar chart using Recharts.
 *
 * @param {Object} props - The component props.
 * @param {any[]} props.data - The data to be displayed in the bar chart.
 * @param {Object[]} [props.distinct=[]] - An optional array of distinct items for the chart.
 * @param {number|string} [props.maxValue='auto'] - The maximum value for the Y-axis.
 *
 * @returns {JSX.Element} The rendered bar chart component.
 */
export const GraphBars = ({ data = [], distinct = [], maxValue = 'auto' }) => {
  return (
    <ResponsiveContainer>
      <BarChart
        width={700}
        height={800}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
        <YAxis domain={[0, maxValue]} />
        <Tooltip />
        {distinct.length !== 0 && <Legend />}
        {distinct.length === 0 && <Bar dataKey="value" />}
        {distinct.length === 0
          ? data.map((entry, index) => <Cell cursor="pointer" fill={entry.fill} key={`cell-${index}`} />)
          : distinct.map((dis) => <Bar dataKey={dis.name} key={`cellb-${dis.name}`} fill={dis.color} />)}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default GraphBars
