export const generateAcronym = (name: string) => {
  if (!name) return '' // Return an empty string if the name is falsy
  if (name.split(' ').length === 1) return name.toUpperCase().slice(0, 3)
  return name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .replace(/\W+(?!$)/g, '')
    .toUpperCase()
}
