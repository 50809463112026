import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'

const TimeAbsolute = (props) => {
  const format = getFormat(props)
  const {
    date,
    seconds: _seconds,
    shortDate: _shortDate,
    dateOnly: _dateOnly,
    timeOnly: _timeOnly,
    clock24Hours: _clock24Hours,
    ...carriedProps
  } = props
  return <time {...carriedProps}>{moment(date).local().format(format)}</time>
}

TimeAbsolute.propTypes = {
  date: PropTypes.any.isRequired,
  dateOnly: PropTypes.bool,
  timeOnly: PropTypes.bool,
  shortDate: PropTypes.bool,
  seconds: PropTypes.bool,
  clock24Hours: PropTypes.bool,
}

export default TimeAbsolute

const getFormat = ({ dateOnly, timeOnly, seconds = true, shortDate, clock24Hours }) => {
  // October 26, 2020
  if (dateOnly) {
    return 'LL'
  }

  // 4:57 PM
  if (timeOnly) {
    return 'LT'
  }

  if (shortDate) {
    return 'DD/MM/YYYY'
  }
  // 3 de Jul de 2020 às 09:14
  if (clock24Hours) {
    return 'D MMMM YYYY HH:mm:ss z'
  }

  // Oct 26, 2020 11:30:30 AM
  if (seconds) {
    return 'D MMM YYYY HH:mm:ss'
    //return 'll LTS z'
  }

  // Default is Oct 26, 2020 11:30 AM
  return 'lll'
}
