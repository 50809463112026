import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Segment, Checkbox, List, Icon, Button } from 'semantic-ui-react'
// import dataGroup from './dataGroup'
import styled from 'styled-components'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { saveLoginPreferences, saveColumnsPreference, saveReportsColumnsPreference } from 'Store/actions/login-action'
import { useRouteMatch } from 'react-router-dom'

/*  Author: Bruno Melo
    Type: SmartS
	Description: Content of Columns Actions;			 
	To do list: -
				
*/

const CheckBoxCapitalized = styled(Checkbox)`
  .checkbox label {
    text-transform: capitalize;
  }
`
const StyledSegment = styled(Segment)`
   {
    height: 60vh;
    overflow: auto;
  }
`

export const ColumnsReactTable = ({
  columnsOpen,
  setColumnsOpen,
  t,
  table,
  columnsStr,
  allTableProps,
  setHiddenColumnsProp,
}) => {
  // temporary copy of data just for the list display
  // only change the actual columns after saving
  let match = useRouteMatch()
  const reportId = match?.params?.id
  const [checkListColumns, setCheckListColumns] = React.useState([])
  const hiddenColumns = checkListColumns.filter((c) => !c.isVisible).map((c) => c.id)
  // used to display save message when needed
  const [oneColumnChecked, setOneColumnChecked] = React.useState(true)

  useEffect(() => {
    const columns = JSON.parse(columnsStr)
    if (columnsOpen) {
      setCheckListColumns(
        columns.map((c) => {
          return {
            id: c.id,
            isVisible: c.isVisible,
            Header: c.Header,
          }
        })
      )
    }
  }, [columnsOpen, columnsStr])

  const columns = JSON.parse(columnsStr)

  const dispatch = useDispatch()

  const handleToggle = (checkmark) => {
    setCheckListColumns(
      checkListColumns.map((c) => {
        return { id: c.id, isVisible: checkmark, Header: c.Header }
      })
    )
  }

  const checkAtLeastOne = (columns) => {
    const atLeastOneColumn = columns.filter((c) => c.isVisible)
    return atLeastOneColumn.length > 0 ? true : false
  }

  const handleCheck = (id, checked) => {
    // update local state
    setCheckListColumns(
      checkListColumns.map((c) => {
        if (c.id === id) {
          // isVisible is the inverse of current checked (toggled)
          return { id: c.id, isVisible: !checked, Header: c.Header }
        } else {
          return c
        }
      })
    )
  }

  const handleSave = () => {
    const columnCondition = checkAtLeastOne(checkListColumns)
    // requires at least one column displayed to save
    if (columnCondition) {
      if (setHiddenColumnsProp) {
        setHiddenColumnsProp(hiddenColumns)
      } else {
        table === 'reports'
          ? dispatch(saveReportsColumnsPreference(reportId, hiddenColumns, false))
          : dispatch(saveColumnsPreference(table, hiddenColumns, false))
        dispatch(saveLoginPreferences())
      }
      setColumnsOpen(false)
      // update the local columns
      allTableProps.setHiddenColumns(checkListColumns.filter((clc) => !clc.isVisible).map((c) => c.id))
    }

    setOneColumnChecked(columnCondition)
  }

  return (
    <ModalTemplate
      onClose={() => setColumnsOpen(false)}
      open={columnsOpen}
      size="small"
      header={t('allColumnsAvailable')}
      onSave={handleSave}
      onCancel={() => setColumnsOpen(false)}
    >
      {columns.length === 0 ? (
        <p>{t('noColumns')}</p>
      ) : (
        <>
          <Grid columns={1}>
            <Grid.Column key={'col.' + table}>
              <Button
                primary
                style={{ background: '#4b72b2' }}
                content={t('ToggleAll')}
                size="small"
                onClick={() => handleToggle(true)}
                icon="check square outline"
              />
              <Button
                secondary
                content={t('UntoggleAll')}
                size="small"
                onClick={() => handleToggle(false)}
                icon="square outline"
              />
              <StyledSegment key={'seg.' + table}>
                <List key={'list.' + table}>
                  <List.Header>
                    <Icon name={'columns'} />
                    {table === 'reports' ? 'Relatórios' : t(table)}
                  </List.Header>

                  {checkListColumns.map((column) => {
                    // get the checked status
                    const checked = checkListColumns.find((each) => each.id === column.id).isVisible
                    return (
                      column && (
                        <List.Item
                          key={'li' + table + '.' + column.id}
                          onClick={() => {
                            handleCheck(column.id, checked)
                          }}
                        >
                          <CheckBoxCapitalized
                            label={t(column.Header)}
                            id={column.id}
                            checked={checked}
                            onChange={(e, data) => {
                              handleCheck(data.id, checked)
                            }}
                          />
                        </List.Item>
                      )
                    )
                  })}
                </List>
              </StyledSegment>
            </Grid.Column>
          </Grid>

          <br />

          {!oneColumnChecked ? (
            <Segment inverted color="red">
              {t('atLeastOneColumnRequired')}
            </Segment>
          ) : (
            ''
          )}
        </>
      )}
    </ModalTemplate>
  )
}

export default ColumnsReactTable
