import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'Utils/reactTable'
import { deleteGeoModules, saveGeoModule, saveGroup, setCurrentModule } from 'Store/actions/modules-action'
import { InputTemplate } from 'Utils/components/InputTemplate.js'
import { saveColumnsPreference } from 'Store/actions/login-action'
import { SvgIcon } from 'Utils/components/SvgIcon.jsx'
import useFetchData from 'Utils/hooks/useFetchData'
import { GenericColumns } from 'Utils/reactTable/components/GenericColumns'
import ModuleDetailsContainer from 'Components/modals/ModuleDetailsContainer'
import ClickableLink from 'Utils/components/ClickableLink'
import moment from 'moment'
import { setChangeLogsProps } from 'Store/actions/changeLogs-action'
import { setTrue } from 'Store/actions/toggle-action'
import ChangeLogs from 'Components/modals/ChangeLogs'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

export const FencesContent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [currentSort, setCurrentSort] = useState('label')
  const [loading, setLoading] = useState(true)
  useFetchData({
    independentFetches: [{ fetchAction: 'geoModules' }, { fetchAction: 'rules' }, { fetchAction: 'profiles' }],
    finishAction: () => setLoading(false),
  })

  const tableName = 'fences'
  const tableLabel = 'Perímetros'
  const fencesStr = useSelector((state /*: RootStore*/) => JSON.stringify(state.modules[tableName]))
  const rulesStr = useSelector((state /*: RootStore*/) => JSON.stringify(state.rules))

  const assetTypesStr = useSelector((state /*: RootStore */) => JSON.stringify(state.profiles))
  const types = React.useMemo(() => {
    const assetTypes = JSON.parse(assetTypesStr)
    return assetTypes.allIds
      .filter((tp) => assetTypes.byId[tp]?.type === 'fences')
      .map((t) => ({
        key: t,
        value: t,
        text: assetTypes.byId[t]?.name,
        color: assetTypes.byId[t]?.color,
        iconsvg: assetTypes.byId[t]?.icon,
      }))
  }, [assetTypesStr])

  const typeName = React.useCallback(
    (id) => {
      return JSON.parse(assetTypesStr)?.byId?.[id]?.name || ' '
    },
    [assetTypesStr]
  )

  /*const colors = colorOptions.map((e) => {
    e.text = t(e.text)
    return e
  })*/

  const groupAction /*: GroupActions*/ = (groupName, [selected], action) =>
    dispatch(saveGroup('fences', groupName, [selected], action))
  const deleteAction /*: DeleteAction*/ = (ids) => dispatch(deleteGeoModules({ type: 'fences', ...ids }))

  // let hiddenColumns = ['Tiplam', 'TiplamTest', 'Netflix'].includes(empresa) ? ['id'] : ['id', 'info.properties.risk']
  const hiddenColumns = useSelector(
    (state /*: RootStore*/) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns
  )
  // let hiddenButtons = ['new', 'edit', 'fullscreen', 'toggle fullscreen', 'calendar']

  const groupedValuesColumns = useSelector(
    (state /*: RootStore*/) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )
  const showModuleDetails = useSelector((state) => state.toggle.showModuleDetails)
  const showChangeLogs = useSelector((state) => state.toggle.showChangeLogs)
  const current = useObjSelector((state) => state.modules.current)

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])
  const noType = '<<' + t('noAssignment') + '>>'

  const formatColumns = React.useMemo(() => {
    const typeColor = (id /*:string*/) => types.filter((f) => f.value === id)[0]?.color
    const typeIcon = (id /*:string*/) => types.filter((f) => f.value === id)[0]?.iconsvg
    return [
      {
        Header: t('Code'),
        accessor: 'code',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ids`,
      },
      {
        Header: t('Fences'),
        accessor: 'label',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ${t('Fences')}`,
        Cell: (props) => (
          <InputTemplate
            options={undefined} //enquanto InputTemplate não está em typescript
            value={props.value || '<<sem nome>>'}
            id={props?.cell?.row?.values?.id}
            action={(id /*: string*/, field /*: string*/, value /*: string*/) =>
              dispatch(saveGeoModule({ mutation: 'update', fields: { id, [field]: value }, type: 'fences' }))
            }
            field={'label'}
            table={tableName}
          />
        ),
      },
      // {
      //   Header: t('Groups'),
      //   accessor: 'groupsText',
      //   aggregate: 'uniqueCount',
      //   Aggregated: ({ value }) => `${value} ${'grupos'}`,
      // },
      {
        Header: t('Rules'),
        accessor: 'rulesText',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'regras'}`,
      },
      {
        Header: t('Assignment'),
        accessor: 'profile',
        align: 'center',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${value === '1' ? t('Assignment') : t('Assignments')}`,
        Cell: (props) => {
          const rowId = props?.cell?.row?.original?.id
          return (
            <>
              <SvgIcon
                title={'Clique no nome para editá-lo'}
                color={typeColor(props.value)}
                name={typeIcon(props.value)}
                compact
              />
              <br />
              <InputTemplate
                options={types}
                value={props.value || noType}
                id={rowId}
                action={(id /*:string*/, field /*:string*/, value /*:string*/) => {
                  dispatch(saveGeoModule({ mutation: 'update', fields: { id, [field]: value }, type: 'fences' }))
                }}
                field="profile"
                table={tableName}
                setCurrent={() => dispatch(setCurrentModule(rowId, 'fences'))}
              />
            </>
          )
        },
      },
      // {
      //   Header: t('description'),
      //   accessor: 'description',
      //   aggregate: 'count',
      //   Aggregated: ({ value }) => `${value} descrições`,
      //   Cell: (props) => {
      //     return <TableSpan value={props.value || '  '} width="400px" />
      //   },
      // },
    ] /* as TableColumn[]*/
  }, [t, dispatch, noType, types])

  const columns = [
    ...formatColumns,
    ...GenericColumns({
      columns: [
        {
          accessor: 'updatedAt',
          Header: t('updatedAt'),
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => {
            return `${value} Data(s)`
          },
          Cell: ({
            value,
            row: {
              original: { id },
            },
          }) => {
            return (
              <ClickableLink
                title={moment(value).format('lll')}
                onClick={() => {
                  dispatch(setCurrentModule(id, 'fences'))
                  dispatch(setChangeLogsProps([['entityId', id]]))
                  dispatch(setTrue('showChangeLogs'))
                }}
              >
                {moment(value).fromNow()}
              </ClickableLink>
            )
          },
        },
      ],
    }),
  ]

  const checkRule = (
    targetType /*?: string*/,
    targetId /*?: string[]*/,
    id /*?: string*/,
    editable /*?: boolean | null*/
  ) => {
    if (targetType === 'fence') {
      return id && targetId?.includes(id) && editable
    }
    return false
  }

  const data = React.useMemo(() => {
    const fences = JSON.parse(fencesStr)
    const rules = JSON.parse(rulesStr)

    return fences.allIds.map((id /*: any*/) => {
      let row = fences.byId[id]
      let typeColor = types?.filter((f) => f.key === row?.info?.properties?.type)?.[0]?.color || 'green'
      let typeIcon = types?.filter((f) => f.key === row?.info?.properties?.type)?.[0]?.iconsvg || 'fence'
      let groupsText = row?.groups
        //?.filter((g) => !g?.startsWith('R0'))
        ?.sort()
        ?.join(', ')

      let rulesText = rules.allIds
        .filter((f /*: string*/) =>
          checkRule(rules.byId?.[f]?.targetType, rules?.byId?.[f]?.targetId, id, rules?.byId?.[f]?.editable)
        )
        .map((m /*: string*/) => rules.byId[m]?.id)
        .sort()
        .join(', ')
      return {
        ...row,
        id: id,
        groupsText,
        rulesText,
        type: row?.profile,
        typeIcon: typeIcon,
        typeColor: typeColor,
      }
    })
  }, [fencesStr, rulesStr, types])

  return (
    <>
      <ReactTable
        table={tableName}
        tableLabel={tableLabel}
        hiddenColumns={hiddenColumns}
        columns={columns}
        data={data}
        groupAction={groupAction}
        deleteAction={deleteAction}
        currentGroup={currentGroup}
        setCurrentGroup={setCurrentGroup}
        initialSortBy={{ desc: false, id: 'label' }}
        setCurrentSort={setCurrentSort}
        currentSort={currentSort}
        typeName={typeName}
        showPlaceholders={loading}
      />
      {showModuleDetails && <ModuleDetailsContainer type={tableName} />}
      {showChangeLogs && <ChangeLogs entity="geoModule" currentItem={current} />}
    </>
  )
}
