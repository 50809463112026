import React from 'react'
import AuthWrapper from './Views/Authentication/AuthWrapper'
import { LoadingApp } from './Components/LoadingApp'

//import Auth from './Auth/Auth.js';
/*  Author: Felipe Pegado
    Type: Smart
  Description: Store Provider;
         Webchat button;				 
  To do list: -Criar um component funcional para o botão do chat;
      	
*/
const Home = ({ auth }) => {
  return (
    <AuthWrapper auth={auth}>
      <LoadingApp auth={auth} />
    </AuthWrapper>
  )
}

export default Home
