import * as z from 'zod'

interface Params {
  values: Record<string, unknown>
  schema: z.ZodType<any, any>
  onError: (errors: z.ZodFormattedError<z.infer<z.ZodType<any, any>>> | unknown) => void
  onSuccess: () => void
}

function validateForm({ values, schema, onError, onSuccess }: Params): void {
  const result = schema.safeParse(values)

  if (result.success) {
    onSuccess()
  } else {
    let { _errors, ...errorRes } = result.error.format()
    errorRes = Object.entries(errorRes).reduce((acc: any, entry) => {
      const errorField = entry?.[0]

      const { _errors, ...rest } = entry?.[1] as any
      if (Object.keys(rest).length > 0 || (errorField && _errors[0])) {
        acc[errorField] = _errors?.[0] ? _errors : 'error'
      }
      return acc
    }, {})

    onError(errorRes)
  }
}

export default validateForm
