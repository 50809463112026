import React from 'react'
import { logToSentry } from 'Utils/analytics'

class ErrorBoundary extends React.Component {
  // static defaultProps = {
  //   FallbackComponent: ErrorBoundaryFallbackComponent,
  // };

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      info: null,
      previousLocation: null,
    }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location !== state.previousLocation) {
      return {
        error: null,
        info: null,
        previousLocation: props.location,
      }
    }

    return null
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any Components below and re-render with error message
    const { onError } = this.props
    if (typeof onError === 'function') {
      try {
        console.error(error, errorInfo)
        logToSentry(error, errorInfo)
        onError.call(this, error, errorInfo ? errorInfo.componentStack : '')
      } catch (catchError) {
        console.error(error, { errorInfo, catchError })
        logToSentry(error, { errorInfo, catchError })
      }
    }
    this.setState({ error, errorInfo })
  }

  render() {
    const { children, FallbackComponent } = this.props
    const { error, errorInfo } = this.state

    if (error !== null) {
      return FallbackComponent ? (
        <FallbackComponent componentStack={errorInfo ? errorInfo.componentStack : ''} error={error} />
      ) : null
    }
    return children || null
  }
}

export default ErrorBoundary
