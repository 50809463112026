import React from 'react'
import { useTranslation } from 'react-i18next'
//import { Checkbox, /*Icon,Label */ } from 'semantic-ui-react'
import m from 'moment'
import dataPhases from './phases_data.json'
import ReactTable from 'Utils/reactTable'
//import {fetchRules} from 'Store/actions/rules-action'

const hiddenColumnsFallback = [
  'createdBy',
  'createdAt',
  'status',
  'groupsText',
  'MediaTgTotRealAmostra1',
  'MediaTgTotRealAmostra3',
  'MediaTgTotRealSemanal',
  'MediaTgTotRealMensal',
]
export const PhasesContent = () => {
  const { t } = useTranslation()
  const tableName = 'fases'
  let hiddenColumns = hiddenColumnsFallback

  const columns = React.useMemo(
    () => [
      {
        Header: 'Atividade',
        accessor: 'activity',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'atividades'}`,
      },
      {
        Header: 'Turnos',
        accessor: 'shiftLabel',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'turnos'}`,
      },
      {
        Header: 'Ciclos',
        accessor: 'cycleLabel',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'ciclos'}`,
      },
      { Header: 'Fase', accessor: 'phase', aggregate: 'uniqueCount', Aggregated: ({ value }) => `${value} ${'fases'}` },
      {
        Header: 'Início',
        accessor: 'start',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'inícios'}`,
        Cell: ({ value }) => <span title={m(value).format('lll')}>{m(value).format('DDMMM HH:mm:ss')}</span>,
      },
      {
        Header: 'Duração (s)',
        accessor: 'duration',
        aggregate: 'sum',
        Aggregated: ({ value }) => `Total: ${value}s / ${Math.round((value / 60) * 10) / 10}min`,
      },
      {
        Header: 'Usuários',
        accessor: 'userName',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'usuários'}`,
      },
      {
        Header: 'Saturação',
        accessor: 'saturation',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'avaliações'}`,
      },

      // , Cell: ({ value }) => <span title={'Shift '+value}>{'Turno '+(value+1)}</span>},
      {
        Header: t('Description'),
        accessor: 'description',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'descrições'}`,
      },
      {
        Header: 'tg Min Teórico',
        accessor: 'tgMin',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `Total: ${value}`,
      },
      {
        Header: 'VA / NVA',
        accessor: 'vanva',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'valores'}`,
      },

      //   { Header: 'Q x F', accessor: 'QxF', aggregate: 'uniqueCount', Aggregated: ({ value }) => `${value} ${'valores'}` },
      //  { Header: 'Tg Tot Teórico', accessor: 'tgTotMin', aggregate: 'sum', Aggregated: ({ value }) => `Total: ${value}` },
      /* { Header: 'Média Tg Tot Real Amostra1', accessor: 'MediaTgTotRealAmostra1', aggregate: 'sum', Aggregated: ({ value }) => `Total: ${value}` },
    { Header: 'Média Tg Tot Real Amostra2', accessor: 'MediaTgTotRealAmostra2', aggregate: 'sum', Aggregated: ({ value }) => `Total: ${value}` },
    { Header: 'Média Tg Tot Real Amostra3', accessor: 'MediaTgTotRealAmostra3', aggregate: 'sum', Aggregated: ({ value }) => `Total: ${value}` },
    { Header: 'Média Tg Tot Real Diário', accessor: 'MediaTgTotRealDiario', 
      style:{backgroundColor:'red'},
      aggregate: 'sum', Aggregated: ({ value }) => `Total: ${value}` },
    { Header: 'Média Tg Tot Real Semanal', accessor: 'MediaTgTotRealSemanal', aggregate: 'sum', Aggregated: ({ value }) => `Total: ${value}` },
    { Header: 'Média Tg Tot Real Mensal', accessor: 'MediaTgTotRealMensal', aggregate: 'sum', Aggregated: ({ value }) => `Total: ${value}` },
    */
      /*{ Header: 'Criado por', accessor: 'createdBy', aggregate: 'uniqueCount', Aggregated: ({ value }) => `${value} ${t('hours')}` },
    { Header: 'Criado em', accessor: 'createdAt', aggregate: 'uniqueCount', Aggregated: ({ value }) => `${value} ${t('hours')}`, Cell: ({ value }) => <span title={m(value).format('lll')}>{m(value).fromNow()}</span> },
    { Header: 'Status', accessor: 'status', aggregate: 'uniqueCount', Aggregated: ({ value }) => `${value} ${t('status')}`, Cell: ({ value }) => <Checkbox toggle checked={value} /> },
  */
    ],
    [t]
  )
  // const rows = table?.allIds?.sort((prev, next) => table?.byId[next]?.time?.localeCompare(table?.byId[prev]?.time))
  const data = React.useMemo(
    () =>
      dataPhases?.map((row) => {
        row.activity = row.phase === 0 ? 'Inativo' : row.phase === 1 ? 'Anomalia' : 'Chicote'
        row.userName = 'Usuário' + (row.shift + 1)
        row.cycleLabel = 'Ciclo ' + (row.cycle + 1).toString().padStart(2, '0')
        row.shiftLabel = 'Turno ' + (row.shift + 1)
        row.saturation = row.phase === 0 ? 'Dessaturação' : 'Saturação'
        return row
      }),
    []
  )
  /* return [{
      ciclo:'Chicote', level: 1,
      code:'F0373778.1', description:'Local de Trabalho:Interno no vão do motor',
      groups:[], groupsText: [].toString(),
      tgMin:0, QxF:1, tgTotMin: 0, VA_NVA:'NONE-NONE',   
      MediaTgTotRealAmostra1: 0, MediaTgTotRealAmostra2: 0,  MediaTgTotRealAmostra3: 0,    
      MediaTgTotRealDiario: 0, MediaTgTotRealSemanal:0, MediaTgTotRealMensal: 0,
      createdaAt: '2021-03-24 18:21:03',createdBy: 'Adm Phygitall',status: 'On'
    }
      , {
        ciclo:'Chicote', level: 2,
        code:'F0373778.1', description:'Executar leitura da ficha macrocav para verificação do modelo, versão e opcionais',
        groups:[], groupsText: [].toString(),
        tgMin:0, QxF:1, tgTotMin: 0, VA_NVA:'NONE-NONE',   
        MediaTgTotRealAmostra1: 0, MediaTgTotRealAmostra2: 0,  MediaTgTotRealAmostra3: 0,    
        MediaTgTotRealDiario: 0, MediaTgTotRealSemanal:0, MediaTgTotRealMensal: 0,
        createdaAt: '2021-03-24 18:21:05',createdBy: 'Adm Phygitall',status: 'On'
      } , {
        ciclo:'Chicote', level: 3,
        code:'F0373778.1', description:'Vindo do veículo anterior, assentar-se no vão de porta anterior lado esquerdo com as duas pernas p',
        groups:[], groupsText: [].toString(),
        tgMin:0.104, QxF:1, tgTotMin: 0.104, VA_NVA:'NONE-NONE',   
        MediaTgTotRealAmostra1: 0, MediaTgTotRealAmostra2: 0,  MediaTgTotRealAmostra3: 0,    
        MediaTgTotRealDiario: 0, MediaTgTotRealSemanal:0, MediaTgTotRealMensal: 0,
        createdaAt: '2021-03-24 18:21:05',createdBy: 'Adm Phygitall',status: 'On'
      } , {
        ciclo:'Chicote', level: 4,
        code:'F0373778.1', description:'Com a mão posicionada sobre o chicote, afrouxar os dedos deslizar a mão sobre o chicote até a pre',
        groups:[], groupsText: [].toString(),
        tgMin:0.148, QxF:1, tgTotMin: 0.148, VA_NVA:'NONE-NONE',   
        MediaTgTotRealAmostra1: 0, MediaTgTotRealAmostra2: 0,  MediaTgTotRealAmostra3: 0,    
        MediaTgTotRealDiario: 0, MediaTgTotRealSemanal:0, MediaTgTotRealMensal: 0,
        createdaAt: '2021-03-24 18:21:05',createdBy: 'Adm Phygitall',status: 'On'
      } , {
        ciclo:'Chicote', level: 5,
        code:'F0373778.1', description:'Com a mão no chicote, afrouxar os dedos deslizar a mão sobre o chicote até a presilha pré dispost',
        groups:[], groupsText: [].toString(),
        tgMin:0.243, QxF:1, tgTotMin: 0.243, VA_NVA:'NONE-NONE',   
        MediaTgTotRealAmostra1: 0, MediaTgTotRealAmostra2: 0,  MediaTgTotRealAmostra3: 0,    
        MediaTgTotRealDiario: 0, MediaTgTotRealSemanal:0, MediaTgTotRealMensal: 0,
        createdaAt: '2021-03-24 18:21:05',createdBy: 'Adm Phygitall',status: 'On'
      }
    
    
    
      ]

      /*(rows?.map((id) => {
              let row = table?.byId[id]
              return {
                     user:'Qualquer Pessoa',
                     interact: 'Qualquer Outra Pessoa', 
                     time:row.time,
                     type:row.type,
                     criticity:'Máximo',
                     distance:row.distance,
                     scope:row.scope
              }
            })),[table,rows])*/

  return <ReactTable table={tableName} hiddenColumns={hiddenColumns} columns={columns} data={data} />
}
