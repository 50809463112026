import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Menu, Sidebar } from 'semantic-ui-react'
import SideFormContent from 'Components/sideForm/SideFormContent'
import { useRouteMatch } from 'react-router-dom'
import { MODULES_SIDE_FORM_WIDTH } from 'Utils/constants/screenSizes'
import { RootStore } from '../../Store/initialStore'
interface StatsBarProps {
  isMobile: boolean
  sideBarLeft: boolean
}

const SideForm: React.FC<StatsBarProps> = ({ isMobile, sideBarLeft }) => {
  const show = useSelector((state: RootStore) => state.toggle.showStatsBar)

  const match = useRouteMatch()
  const showSidebar = match?.isExact && match.path === '/app' && show

  const sideBarStyle: React.CSSProperties = {
    zIndex: 4999,
    background: 'rgba(255,255,255,1)',
    left: sideBarLeft ? (isMobile ? '20vw' : '8vw') : '0vw',
    // textColor: 'white',
    width: isMobile ? '80vw' : `${MODULES_SIDE_FORM_WIDTH}vw`,
    minWidth: '282px',
    borderTop: '1px solid rgb(206, 206, 206)',
    borderBottom: '1px solid rgba(206, 206, 206, 0.3)',
    paddingTop: '2px',
    height: '100vh !important',
  }
  return (
    showSidebar && (
      <Sidebar
        as={Menu}
        animation="push"
        icon="labeled"
        inverted
        direction="left"
        vertical
        visible={show}
        style={sideBarStyle}
        width="wide"
      >
        <SideFormContent />
      </Sidebar>
    )
  )
}
export default memo(SideForm)
