export const measureOptions = {
  position: 'bottomright',
  primaryLengthUnit: 'kilometers',
  secondaryLengthUnit: 'meters',
  primaryAreaUnit: 'sqmeters',
  secondaryAreaUnit: 'hectares',
  activeColor: '#db4a29',
  decPoint: ',',
  thousandsSep: '.',
  completedColor: '#9b2d14',
}

//export const draw = withTranslation()(drawJ);
export const draw = (t) => {
  return {
    draw: {
      toolbar: {
        // #TODO: this should be reorganized where actions are nested in actions
        // ex: actions.undo  or actions.cancel
        actions: {
          title: t('DrawCancel'),
          text: t('DrawCancel'),
        },
        finish: {
          title: t('DrawFinish'),
          text: t('DrawFinish'),
        },
        undo: {
          title: t('DrawDeleteLast'),
          text: t('DrawDeleteLast'),
        },
        buttons: {
          polyline: t('DrawCreateRoute'),
          polygon: t('DrawCreatePoly'),
          rectangle: t('DrawCreateRec'),
          circle: t('DrawCreateCircle'),
          marker: t('DrawCreateMarker'),
          circlemarker: '- your text-',
        },
      },
      handlers: {
        circle: {
          tooltip: {
            start: t('DrawCircleStart'),
            end: t('DrawCircleEnd'),
          },
          radius: t('DrawCircleRadius'),
        },
        circlemarker: {
          tooltip: {
            start: '- your text-.',
          },
        },
        marker: {
          tooltip: {
            start: t('DrawMarkerStart'),
          },
        },
        polygon: {
          tooltip: {
            start: t('DrawPolygonStart'),
            cont: t('DrawPolygonCont'),
            end: t('DrawPolygonEnd'),
          },
        },
        polyline: {
          error: '<strong>Erro:</strong> arestas não podem se cruzar!',
          tooltip: {
            start: t('DrawPolylineStart'),
            cont: t('DrawPolylineCont'),
            end: t('DrawPolylineEnd'),
          },
        },
        rectangle: {
          tooltip: {
            start: t('DrawRectangleStart'),
            end: t('DrawRectangleEnd'),
          },
        },
        simpleshape: {
          tooltip: {
            end: t('DrawSimpleShapeEnd'),
          },
        },
      },
    },
    edit: {
      toolbar: {
        actions: {
          save: {
            title: t('DrawActionSaveChanges'),
            text: t('DrawActionSave'),
          },
          cancel: {
            title: t('DrawActionCancel'),
            text: t('DrawCancel'),
          },
          clearAll: {
            title: t('DrawActionClear'),
            text: t('DrawActionClear'),
          },
        },
        buttons: {
          edit: t('DrawActionEdit'),
          editDisabled: t('DrawActionEditDis'),
          remove: t('DrawActionRemove'),
          removeDisabled: t('DrawActionRemoveDis'),
        },
      },
      handlers: {
        edit: {
          tooltip: {
            text: t('DrawHandlersEdit'),
            subtext: t('DrawHandlersSub'),
          },
        },
        remove: {
          tooltip: {
            text: t('DrawHandlersRemove'),
          },
        },
      },
    },
  }
}

export const decodColor = (color) => {
  switch (color) {
    case 'azul':
      return 'blue'
    case 'preto':
      return 'black'
    case 'verde':
      return 'green'
    case 'vermelho':
      return 'red'
    case 'laranja':
      return 'orange'
    case 'amarelo':
      return 'yellow'
    case 'rosa':
      return 'pink'
    case 'branco':
      return 'white'
    case 'violeta':
      return 'violet'
    default:
      return null
  }
}

export const decodColorPor = (color) => {
  switch (color) {
    case 'blue':
      return 'azul'
    case 'black':
      return 'preto'
    case 'green':
      return 'verde'
    case 'red':
      return 'vermelho'
    case 'orange':
      return 'laranja'
    case 'yellow':
      return 'amarelo'
    case 'pink':
      return 'rosa'
    case 'white':
      return 'branco'
    case 'violet':
      return 'violeta'
    default:
      return 'PlotPolygonInputName'
  }
}
