/*  
  Author: Bruno Melo / Felipe / Luís Mourão
  Type: Action
  
  Description: 
    - Ações Assets Store
    - O único parâmetro recebido é a chave que deseja-se alterar
  
  TODO: 
    - Ação para alterar uma lista de chaves da toggleStore
*/
/*global localStorage*/
import { urlBase, URL_BASE } from 'Apis/rest'
import axios from 'axios'
import userInfoIntegration from 'Apis/integrations'
import { getAssetIdDetails } from 'Apis/getAssetIdDetails'
import { setFalse, setTrue } from './toggle-action'
import { saveLoginPreferences } from './login-action'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'

export const fetchAssets = () => async (dispatch) => {
  try {
    const jwt = localStorage.getItem('id_token')
    const headers = {
      headers: { Authorization: 'Bearer ' + jwt },
    }
    dispatch({ type: 'FETCH_ASSETS_START' })
    const res = await axios.get(urlBase + '/assets/', headers)
    dispatch({ type: 'FETCH_ASSETS', payload: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const fetchAssociations = () => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const headers = {
    headers: { Authorization: 'Bearer ' + jwt },
  }
  dispatch({ type: 'FETCH_ASSETS_ASSOCIATIONS_START' })
  try {
    const res = await axios.get(urlBase + '/assets/associations', headers)
    dispatch({ type: 'FETCH_ASSETS_ASSOCIATIONS_SUCCESS', payload: res.data })
  } catch (error) {
    console.error('error fetchAssociations', error)
  }
}

export const fetchLogouts = () => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const headers = {
    headers: { Authorization: 'Bearer ' + jwt },
  }
  dispatch({ type: 'FETCH_ASSETS_LOGOUTS_START' })
  axios
    .get(urlBase + '/assets/applogouts', headers)
    .then((res) => dispatch({ type: 'FETCH_ASSETS_LOGOUTS_SUCCESS', payload: res.data }))
}

export const deleteAssets = (ids) => async (dispatch) => {
  dispatch({ type: 'DELETE_ACTION_START' })
  try {
    const jwt = localStorage.getItem('id_token')
    const headers = {
      headers: { Authorization: 'Bearer ' + jwt },
    }

    await new Promise((r) => setTimeout(r, 10000))
    axios.delete(urlBase + `/assets?ids=${ids}`, headers).then((res) => {
      axios.post(`${urlBase}/devices/totemfiles`, {}, headers).then((res) => {
        dispatch({ type: 'UPDATE_TOTEM_FILES', payload: res })
      })

      dispatch({ type: 'DELETE_ASSETS', payload: ids })
      dispatch({ type: 'DELETE_ACTION_SUCCESS' })
    })
  } catch (err) {
    dispatch({ type: 'DELETE_ACTION_FAILURE' })
  }
}

export const setCurrentAsset = (id) => {
  return {
    type: 'SET_CURRENT_ASSET',
    id: id,
  }
}

export const toggleAsset = (payload, id) => ({
  type: 'TOGGLE_ASSET_VISIBILITY',
  payload,
  id,
})
export const updateAssetAvatar = (id, data) => async (dispatch) => {
  const fd = new FormData()
  fd.append('File', data)
  dispatch({ type: 'UPDATE_ASSET_REQUEST', id })
  try {
    //const res = await request.put('/core/v0/assets/' + id + '/avatar', fd)
    //dispatch({ type: 'UPDATE_ASSET_SUCCESSFULL', payload: res.data.asset, id })
  } catch (err) {
    dispatch({ type: 'UPDATE_ASSET_FAILURE', payload: err })
  }
}

export const updateAsset = (id, changes) => async (dispatch) => {
  dispatch({ type: 'UPDATE_ACTION_START' })
  dispatch({ type: 'UPDATE_ASSET_REQUEST', id })
  try {
    const jwt = localStorage.getItem('id_token')
    const body = { id: id, changes: changes }
    const headers = { headers: { Authorization: 'Bearer ' + jwt } }
    //console.log('Ação salvar grupo, parametros:',body)
    axios.put(`${urlBase}/assets`, body, headers).then((res) => {
      axios.post(`${urlBase}/devices/totemfiles`, {}, headers).then((res) => {
        //console.log(res)
        dispatch({ type: 'UPDATE_TOTEM_FILES', payload: res })
      })
      if (res.data.statusCode === 200 && res.data.body) {
        axios.get(urlBase + '/assets/', headers).then((res) => dispatch({ type: 'FETCH_ASSETS', payload: res.data }))
        dispatch({ type: 'UPDATE_ASSET_NAME_SUCCESSFULL', id: id, payload: res.data.body })
      }
      dispatch({ type: 'UPDATE_ACTION_SUCCESS' })
      notifySuccess()
    })
  } catch (err) {
    dispatch({ type: 'UPDATE_ASSET_FAILURE', payload: err })
    dispatch({ type: 'UPDATE_ACTION_FAILURE', payload: err })
    notifyError()
  }
}

export const saveGroup = (groupName, selected, actionType) => async (dispatch) => {
  try {
    const jwt = localStorage.getItem('id_token')
    const body = { groupName, selected, associate: actionType !== 'delete' }
    let authorization = { Authorization: 'Bearer ' + jwt }
    const headers = { headers: authorization }
    //console.log('Ação salvar grupo',actionType,' parametros:',body)

    switch (actionType) {
      case 'insert':
        axios
          .post(`${urlBase}/assets/groups`, body, headers)
          .then(dispatch({ type: 'UPDATE_ASSET_GROUPS', payload: body }))
          .then(() => axios.post(`https://node-red.phygitall.com.br/assetsChanged`, authorization, headers))
        break
      case 'update':
        axios
          .put(`${urlBase}/assets/groups`, body, headers)
          .then(dispatch({ type: 'UPDATE_ASSET_GROUPS', payload: body }))
          .then(() => axios.post(`https://node-red.phygitall.com.br/assetsChanged`, authorization, headers))
        break
      case 'delete':
        axios
          .put(`${urlBase}/assets/groups`, body, headers)
          .then(dispatch({ type: 'UPDATE_ASSET_GROUPS', payload: body }))
          .then(() => axios.post(`https://node-red.phygitall.com.br/assetsChanged`, authorization, headers))
        break
      default:
        console.error('Missing action type: ' + actionType)
    }
  } catch (err) {
    console.error('Error in saveGroup', err)
  }
}

export const insertAssets = (body) => async (dispatch) => {
  dispatch({ type: 'INSERT_ACTION_START' })

  try {
    const jwt = localStorage.getItem('id_token')
    const headers = { headers: { Authorization: 'Bearer ' + jwt } }
    //console.log('Ação salvar grupo',actiontype,' parametros:',body)

    axios.post(`${urlBase}/assets/`, body, headers).then((res) => {
      //console.log(res)
      dispatch({ type: 'INSERT_ASSETS', payload: res })
      dispatch({ type: 'INSERT_ACTION_SUCCESS' })
      axios.post(`${urlBase}/devices/totemfiles`, {}, headers).then((res) => {
        //console.log(res)
        dispatch({ type: 'UPDATE_TOTEM_FILES', payload: res })
      })
    })
  } catch (err) {
    console.error('Error in saveGroup', err)
    dispatch({ type: 'INSERT_ACTION_FAILURE', payload: err })
  }
}

export const updateAction = (id, changes) => async (dispatch) => {
  dispatch({ type: 'UPDATE_ACTION_START' })
  try {
    const jwt = localStorage.getItem('id_token')
    const body = { id: id, changes: changes }
    const headers = { headers: { Authorization: 'Bearer ' + jwt } }
    await axios.post(`${urlBase}/assets`, body, headers)
    dispatch({ type: 'UPDATE_ACTION_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'UPDATE_ASSET_FAILURE', payload: err })
    dispatch({ type: 'UPDATE_ACTION_FAILURE', payload: err })
  }
}

export const deleteGroup = (ids) => async (dispatch) => {
  dispatch({ type: 'DELETE_ACTION_START' })
  try {
    dispatch({ type: 'DELETE_ASSET_GROUPS_START' })
    const jwt = localStorage.getItem('id_token')
    let authorization = { Authorization: 'Bearer ' + jwt }
    const headers = { headers: authorization }
    // console.log('Ação apagar grupo, parametros:', ids)
    //let reqs = []
    //console.log('Ação salvar grupo, parametros:',body)
    axios
      .delete(`${urlBase}/assets/groups/id=[${ids}]`, headers)
      .then(() => {
        dispatch({ type: 'DELETE_ASSET_GROUPS', payload: ids })
        dispatch({ type: 'DELETE_ACTION_SUCCESS' })
        notifySuccess()
      })
      .then(() =>
        axios.post(`https://node-red.phygitall.com.br/assetsChanged`, authorization, {
          headers: authorization,
        })
      )
  } catch (err) {
    console.error('Error in saveGroup', err)
    dispatch({ type: 'DELETE_ACTION_FAILURE' })
    notifyError()
  }
}

export const exportFirefightersReport = () => {
  return [['teste', 'teste']]
  //dispatch({ type: 'EXPORT_FIREFIGHTERS_REPORT' })
}

export const fetchActiveAssets =
  (saveLogin = false) =>
  async (dispatch) => {
    try {
      const jwt = localStorage.getItem('id_token')
      const headers = { headers: { Authorization: 'Bearer ' + jwt } }
      const res = await axios.get(`${URL_BASE}/assets/active`, headers)
      dispatch({ type: 'FETCH_ACTIVE_ASSETS', payload: res.data })
      dispatch(setFalse('offline'))
      saveLogin && dispatch(saveLoginPreferences())
    } catch (err) {
      console.log('fetchActiveAssetsErr', err)
      if (typeof err === 'object' && err.toJSON && err.toJSON()?.message === 'Network Error') {
        dispatch({ type: 'FETCH_ACTIVE_ASSETS_FAILURE' })
        dispatch(setTrue('offline'))
        // document.getElementById('ContainerMainContent').style.filter = 'grayscale(1)'
      }
      if (err.response.status === 401) {
        console.warn("Token expired, can't fetch active assets")
      }
    }
  }

export const fetchAssetImage = (nfcId, assetId) => async (dispatch) => {
  //const tenant = getState().login.empresa
  try {
    dispatch({
      type: 'FETCH_ASSET_IMAGE_START',
      payload: assetId,
    })

    const response = await userInfoIntegration
      .getUserInfoByNfc(nfcId, new Date().toISOString())
      .then((resp) => {
        return resp.data
      })
      .catch((error) => {
        return error
      })

    dispatch({
      type: 'FETCH_ASSET_IMAGE_SUCCESS',
      payload: { response, assetId },
    })

    // const { items } = response.data.listNotificationProfiles
    // dispatch({
    //   type: 'FETCH_NOTIFICATION_PROFILES',
    //   payload: items,
    // })
  } catch (err) {
    dispatch({
      type: 'FETCH_ASSET_IMAGE_FAILURE',
      payload: assetId,
    })
    console.error('error fetching asset image', err)
  }
}

export const fetchAssetDetails = (assetIds) => async (dispatch) => {
  for (const assetId of assetIds) {
    try {
      const response = await getAssetIdDetails(assetId)
        .then((resp) => {
          return resp
        })
        .catch((error) => {
          return error
        })

      dispatch({
        type: 'FETCH_ASSET_DETAILS_SUCCESS',
        payload: response,
      })
    } catch (err) {
      dispatch({
        type: 'FETCH_ASSET_DETAILS_FAILURE',
        payload: assetId,
      })
      console.error('error fetching asset details', err)
    }
  }
}
