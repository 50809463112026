/*  Author: Luís Mourão
    Type: Smart
  Description: Render antpath component				 
    To do list: 
    - Melhorar perfomance do antpath			
*/

import React, { Component } from 'react'
import getSafe from 'Utils/getSafe.js'
import { connect } from 'react-redux'
import Antpath from 'Libs/react-leaflet-ant-path'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { semanticColors } from 'Utils/colorsFormat'

class HistAntpath extends Component {
  render() {
    const { allIds, byId, max, min, routeByProximity, inner, assets, numData, currentInner, profilesById } = this.props

    return allIds.map((assetId) => {
      const assetColor = getSafe(() => semanticColors(profilesById[assets[assetId].info.type].color), 'black')

      let antpath = []

      byId[assetId].allFilteredIndexes
        .filter((eachIndex) => {
          return byId[assetId].byIndex[eachIndex]?.info?.antpath?.points?.length && eachIndex <= max && eachIndex >= min
        })
        .forEach((filteredIndex, k) => {
          let { points, divided } = byId[assetId].byIndex[filteredIndex]?.info?.antpath

          if (k === 0) antpath = [[points[points.length - 1], points[points.length - 1]]]
          else {
            if (divided === 'false' || !divided) {
              antpath[antpath.length - 1] = antpath[antpath.length - 1].concat(points)
            } else {
              if (points.length === 1) antpath.push([points[0], points[0]])
              else antpath.push(points)
            }
          }
        })
      if (inner)
        antpath = antpath.map((each) =>
          each.map((array) => innerPosition(array, 'array', currentInner?.center, currentInner?.bearing))
        )

      if (antpath.length) {
        return (
          <Antpath
            key={assetId + ' ap'}
            positions={antpath}
            options={{
              color: assetColor,
              weight: 7,
              hardwareAccelerated: true,
              delay: 3000,
              pulseColor: routeByProximity ? 'white' : '#FBB',
              paused: numData > 8000,
              reverse: false,
              dashArray: [10, 20],
            }}
          />
        )
      } else return null
    })
  }
}

const mapStateToProps = (state) => ({
  routeByProximity: state.toggle.showRouteByProximity,
  inner: state.toggle.showInnerMap,
  max: state.history.slider.filteredMax,
  min: state.history.slider.filteredMin,
  byId: state.history.byId,
  allIds: state.history.allIds,
  numData: state.history.filteredSize,
  currentInner: state.indoor.current?.inner,
  profilesById: state.profiles.byId,
  assets: state.assets.byId,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(HistAntpath)
