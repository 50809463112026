/*  
  Author: Bruno Melo/Luís Mourão
  Type: Reducer
  
  Description: 
    -  
	- 
	 
  TODO: 
    - Tornar o SET_CURRENT_ASSET generalizado para vários IDS (Caso Necessário)
*/

import produce from 'immer'

export default function cardsReducer(state = {}, { type, id, payload }) {
  switch (type) {
    case 'FETCH_DEVICES':
      return produce(state, (newState) => {
        let sensors = payload[0].sensors

        let deviceModels = []
        let deviceModelsCounts = {}

        if (Array.isArray(sensors) && sensors.length > 0) {
          let inUseCounter = 0
          let chargingCounter = 0
          let availableCounter = 0
          let disconnectedCounter = 0

          sensors.forEach((device) => {
            if (!deviceModels.includes(device.model)) {
              deviceModels.push(device.model)
              deviceModelsCounts[device.model] = 1
            } else {
              deviceModelsCounts[device.model] = deviceModelsCounts[device.model] + 1
            }

            if (device.model === 'Smartbadge c/ GPS') {
              switch (device.state) {
                case 1:
                  disconnectedCounter = disconnectedCounter + 1
                  break
                case 2:
                  availableCounter = availableCounter + 1
                  break
                case 3:
                  inUseCounter = inUseCounter + 1
                  break
                case 4:
                  chargingCounter = chargingCounter + 1
                  break
                default:
                  break
              }
            }
          })

          newState.byId.devices.statusCounter.inUse.sb = inUseCounter
          newState.byId.devices.statusCounter.charging.sb = chargingCounter
          newState.byId.devices.statusCounter.available.sb = availableCounter
          newState.byId.devices.statusCounter.disconnected.sb = disconnectedCounter

          newState.byId.devices.typesIds = deviceModels
          deviceModels.forEach((type) => {
            if (type === 'Smartphone') newState.byId.devices.typesTotal.app.counter = deviceModelsCounts[type]
            else if (type === 'Smartbadge c/ GPS')
              newState.byId.devices.typesTotal.sb.counter = deviceModelsCounts[type]
            else {
              newState.byId.devices.typesTotal[type] = {}
              newState.byId.devices.typesTotal[type].counter = deviceModelsCounts[type]
              newState.byId.devices.typesTotal[type].icon = 'exclamation'
              newState.byId.devices.typesTotal[type].color = 'black'
              newState.byId.devices.typesTotal[type].label = type.slice(0, 4)
              newState.byId.devices.typesTotal[type].tooltip = type
              newState.byId.devices.typesTotal[type].size = 'mini'
            }
          })
        }
      })

    default:
      return state
  }
}
