import React from 'react'
import { Form, Input, Label } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { setDataLabelingsProp } from 'Store/actions/dataLabelings-actions'
import { RootStore } from 'Store/initialStore'
import { useTranslation } from 'react-i18next'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import EntityDropdown from 'Components/dropdowns/EntityDropdown'

const DataLabelingsIdForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentStep = useSelector((state: RootStore) => state.dataLabelings.form?.steps?.current)
  const activityId = useSelector((state: RootStore) => state.dataLabelings.wip?.activityId)
  const activityFormError = useSelector(
    (state: RootStore) => state.dataLabelings.form?.errors?.[currentStep]?.activityId
  )
  const shift = useSelector((state: RootStore) => state.dataLabelings.wip?.shift)
  const description = useSelector((state: RootStore) => state.dataLabelings.wip?.description)

  const shiftError = useObjSelector((state: RootStore) => state.dataLabelings.form?.errors?.[currentStep]?.shift)
  const showFeedback = useSelector(
    (state: RootStore) => state.dataLabelings.form?.steps.items[currentStep].showFeedback
  )

  const taktTime = useSelector((state: RootStore) => state.dataLabelings.wip?.taktTime)
  const targetCycleTime = useSelector((state: RootStore) => state.dataLabelings.wip?.targetCycleTime)

  return (
    <>
      <Form.Group widths="equal">
        <Form.Field inline error={showFeedback && !!activityFormError} required>
          <label>{t('workstation')}</label>
          <EntityDropdown
            entity="activities"
            value={activityId}
            onChange={(value: string) => dispatch(setDataLabelingsProp(`wip.activityId`, value))}
            placeholder={t('workstationPlaceholder')}
          />
        </Form.Field>
        <Form.Field inline required error={showFeedback && !!shiftError}>
          <label>{t('shift')}</label>
          <Input
            name="shift"
            fluid
            placeholder={t('shiftPlaceholder')}
            value={shift}
            onChange={(e, { name, value }) => {
              dispatch(setDataLabelingsProp(`wip.shift`, value))
            }}
          />
          {showFeedback && shiftError?.[0] && shiftError?.[0] !== 'Required' && (
            <Label color="red" pointing>
              {/* @ts-ignore */}
              {t(shiftError?.[0])}
            </Label>
          )}
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field inline>
          <label>{t('targetCycleTime')}</label>
          <Input
            name="targetCycleTime"
            fluid
            placeholder={t('targetCycleTimePlaceholder')}
            value={targetCycleTime}
            onChange={(e, { name, value }) => {
              dispatch(setDataLabelingsProp(`wip.targetCycleTime`, parseInt(value)))
            }}
            type="number"
          />
        </Form.Field>
        <Form.Field inline>
          <label>{t('taktTime')}</label>
          <Input
            name="taktTime"
            fluid
            placeholder={t('taktTimePlaceholder')}
            value={taktTime}
            onChange={(e, { name, value }) => {
              dispatch(setDataLabelingsProp(`wip.taktTime`, parseInt(value)))
            }}
            type="number"
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <label>{t('description')}</label>
        <Form.TextArea
          name={'description'}
          fluid
          placeholder={t('descriptionPlaceholder')}
          value={description ?? ''}
          onChange={(e, { name, value }) => {
            dispatch(setDataLabelingsProp(`wip.description`, value))
          }}
        />
      </Form.Field>
    </>
  )
}

export default DataLabelingsIdForm
