import React, { useCallback } from 'react'
import { Segment, Header, Dropdown, DropdownItemProps } from 'semantic-ui-react'
import PaginationReactTable from './components/pagination'
import MenuTableIcons from './components/MenuTableIcons'
import GlobalFilter from './components/GlobalFilter'
import { useTranslation } from 'react-i18next'
import { isEmpty, get } from 'lodash'
import { ReactTableTemplateHeaderInterface } from './ReactTableTemplateInterfaces'
//import { IMEInput } from 'Utils/imeInput'
import NewItemRT from './modals/NewItemRT'
import { ConfirmReactTable } from './modals/ConfirmReactTable'
import { RowInfo } from 'Components/SidebarMenu/Contents/react-table-config'
import { Link, Route } from 'react-router-dom'
import m from 'moment'

const getHeaderTitle = (table: string, t: any, tableLabel?: string | DropdownItemProps[]) => {
  if (Array.isArray(tableLabel)) {
    return (
      <Dropdown
        selection
        defaultValue={tableLabel[0].value}
        options={tableLabel.map((label) => ({
          key: label.key,
          text: t(label.text),
          value: label.value,
          as: Link,
          to: '/app/' + label.value,
        }))}
      />
    )
  }
  if (tableLabel) return tableLabel
  return t(table)
}

export const ReactTableTemplateHeader = ({
  cudLoading,
  cudSuccess,
  FormModal,
  insertAction,
  updateAction,
  deleteGroupAction,
  addOpen,
  confirmAction,
  confirmOpen,
  confirmText,
  typeName,
  menuButtons,
  columns,
  data,
  dashboard,
  insightActions,
  insightActionsValues,
  // for the IME
  value,
  setValue,
  //
  fetchAction,

  // the variables below are from the base interface
  table,
  tableLabel,
  deleteAction,
  // set ReactTableTemplate states
  setAddOpen,
  setColumnsOpen,
  setConfirmAction,
  setConfirmOpen,
  setConfirmText,
  setGroupsAction,
  setGroupsOpen,
  setNewClick,
  // has a lot of variables
  allTableProps,
  handleIds,
  handleLabels,
  newClick,
  newItemClick = () => null,
  onEditClick = () => null,
  onLabelsClick = () => null,
  onLabelingsClick = () => null,
  setCurrentSelectedRowIds,
  setProp,
  onSave,
  onFilterClick,
}: ReactTableTemplateHeaderInterface) => {
  const { t } = useTranslation()

  const getResolvedState = useCallback(() => {
    let res
    if (table === 'reports') {
      const body = data.map((row: any) => {
        return columns.map((column: any) => {
          return row[column.id]?.displayValue || ''
        })
      })
      res = [columns.map((c: any) => c.Header), ...body]
    } else {
      const cols = columns.filter((c) => typeof c.accessor !== 'function')
      const body = data.reduce((acc: any, d) => {
        const row = cols.reduce((rowAcc: any, c, i) => {
          let value

          if (c.accessor.toString().endsWith('consentAcceptedAt')) {
            value = d.consentAcceptedAt
          } else {
            switch (c.accessor) {
              case 'id':
                value = '#' + (i + 1).toString().padStart(5, '0')
                break
              case 'type':
                value = d.typetext
                break
              case 'info.color':
                value = d.colortext
                break
              case 'info.table':
                value = d.tabletext
                break
              default:
                value = get(d, c.accessor)
                if (value && typeof value === 'string' && m(value, undefined, true).isValid()) {
                  value = m(value).format('DD/MM/YY HH:mm')
                }
                if (typeof value === 'boolean') {
                  value = value ? 'Sim' : 'Não'
                }
                break
            }
          }

          if (typeof value === 'string') {
            value = value.replace(/;/g, '||').replace(/,/g, '|')
          }

          rowAcc.push(value)
          return rowAcc
        }, [])

        acc.push(row)
        return acc
      }, [])

      res = [cols.map((c: any) => c.Header), ...body]
    }
    return res
  }, [columns, data, table])
  function getSelectedIds(rows: any): any {
    const selectedIds = []

    for (const row of rows) {
      // Check if the row itself is selected
      if (row?.original?.id) {
        selectedIds.push(row.original.id)
      }

      // Check if the row has sub-rows (grouped rows)
      if (row.subRows && row.subRows.length > 0) {
        selectedIds.push(...getSelectedIds(row.subRows)) // Recursively collect selected IDs from sub-rows
      }
    }

    return selectedIds
  }
  const selectedIds = getSelectedIds(allTableProps.selectedFlatRows)

  //const prefLabel = useObjSelector((state) => state.login.preferences?.routesPanels?.byId?.[table]?.label)
  return (
    <Segment.Group horizontal style={{ margin: '0', border: 'none', boxShadow: 'none', padding: '0' }}>
      <Route path="/app/news">
        <Header style={{ textTransform: 'capitalize', position: 'relative', top: '25%' }}>
          {getHeaderTitle(table, t, tableLabel)}
        </Header>
      </Route>
      <Segment>
        <NewItemRT
          addOpen={addOpen}
          setAddOpen={setAddOpen}
          t={t}
          insertAction={insertAction}
          updateAction={updateAction}
          newClick={newClick}
          setNewClick={setNewClick}
          table={table}
          tableLabel={tableLabel}
          ids={allTableProps.selectedFlatRows.map((d: RowInfo) => d?.original?.id || d?.original?.sk)}
          confirmAction={confirmAction}
          context={table}
          FormModal={FormModal}
        />
        <ConfirmReactTable
          table={table}
          confirmOpen={confirmOpen}
          setConfirmOpen={setConfirmOpen}
          t={t}
          confirmAction={confirmAction}
          deleteAction={deleteAction}
          message={confirmText}
          ids={handleIds()}
          labels={handleLabels()}
          setConfirmText={setConfirmText}
          setConfirmAction={setConfirmAction}
          deleteGroupAction={deleteGroupAction}
          cudLoading={cudLoading}
          cudSuccess={cudSuccess}
        />
        <Header style={{ textTransform: 'capitalize', position: 'relative', top: '25%' }}>
          {getHeaderTitle(table, t, tableLabel)}
        </Header>
      </Segment>
      {table !== 'insights' && (
        <GlobalFilter setGlobalFilter={allTableProps.setGlobalFilter} value={value} setValue={setValue} />
      )}

      <MenuTableIcons
        typeName={typeName}
        dashboard={dashboard}
        t={t}
        exportAction={getResolvedState}
        insightActions={insightActions}
        insightActionsValues={insightActionsValues}
        setAddOpen={setAddOpen}
        setConfirmOpen={setConfirmOpen}
        setConfirmAction={setConfirmAction}
        noSelectedRows={isEmpty(selectedIds)}
        setNewClick={setNewClick}
        setConfirmText={setConfirmText}
        setColumnsOpen={setColumnsOpen}
        fetchAction={fetchAction}
        refresh={typeof fetchAction === 'function' || ['Dashboard', 'Perímetros'].includes(dashboard)}
        setGroupsOpen={setGroupsOpen}
        setGroupsAction={setGroupsAction}
        deleteBtn={typeof deleteAction === 'function'}
        edit={selectedIds.length !== 1}
        menuButtons={menuButtons}
        table={table}
        newItemClick={newItemClick}
        onEditClick={onEditClick}
        selectedRowIds={selectedIds}
        onLabelsClick={onLabelsClick}
        onLabelingsClick={onLabelingsClick}
        setCurrentSelectedRowIds={setCurrentSelectedRowIds}
        setProp={setProp}
        onSave={onSave}
        onFilterClick={onFilterClick}
      />

      <PaginationReactTable
        gotoPage={allTableProps.gotoPage}
        previousPage={allTableProps.previousPage}
        nextPage={allTableProps.nextPage}
        canPreviousPage={allTableProps.canPreviousPage}
        canNextPage={allTableProps.canNextPage}
        pageIndex={allTableProps.state.pageIndex}
        pageCount={allTableProps.pageCount}
        pageSize={allTableProps.state.pageSize}
        setPageSize={allTableProps.setPageSize}
        pageOptionsLength={allTableProps.pageOptions.length}
        rowsLength={allTableProps.rows.length}
      />
    </Segment.Group>
  )
}
