import React, { useMemo } from 'react'
import ReactGridLayout from 'react-grid-layout'
import { useDispatch } from 'react-redux'
import { setBetaDashboardProps } from 'Store/actions/betaDashboards-action'
import { v4 } from 'uuid'
import DashboardPanel from './panels/DashboardPanel'
import useStoreSelector from 'Utils/hooks/useStoreSelector'
import { Panel } from 'interfaces/dashboards'
import { isEqual, pickBy } from 'lodash'
import mergeObjects from 'Utils/mergeObjects'

const DEFAULT_LAYOUT = {
  i: v4(),
  x: 0,
  y: 6,
  w: 3,
  h: 9,
  minW: 2,
  minH: 3,
}

const DashboardContent = ({ windowWidth = 2000 }) => {
  const dispatch = useDispatch()

  const panelsStr = useStoreSelector((state) => {
    const currentPanels = state.betaDashboards.current?.panels
    const wipPanels = state.betaDashboards.wip?.panels

    const object = mergeObjects(currentPanels, wipPanels)

    return JSON.stringify(object)
  })

  const openForm = useStoreSelector((state) => state.betaDashboards.form?.open)

  const panels: Record<string, Panel> = useMemo(() => JSON.parse(panelsStr), [panelsStr])

  const panelsList = useMemo(() => (panels ? Object.values(panels) : []), [panels])

  const layout = useMemo(
    () =>
      panelsList?.reduce((acc: any, panel) => {
        if (!panel?.id) return acc
        const { id, type, layout: panelLayout } = panel
        const minW = type !== 'kpi' ? 3 : 2
        const minH = type !== 'kpi' ? 7 : 3
        const w = Math.max(panelLayout?.w ?? 0, minW)
        const h = Math.max(panelLayout?.h ?? 0, minH)

        acc.push({
          ...panelLayout,
          i: id,
          w,
          h,
          minW,
          minH,
          maxW: 10,
        })

        return acc
      }, []) || [DEFAULT_LAYOUT],
    [panelsList]
  )

  return (
    <ReactGridLayout
      isDraggable={!openForm}
      className="layout"
      layout={layout}
      cols={12}
      rowHeight={30}
      width={windowWidth}
      onLayoutChange={(newLayout) => {
        const newPanels = newLayout.reduce<Record<string, Panel>>((acc, eachLayout) => {
          const { i, x, y, w, h } = eachLayout
          const panel = panels ? panels[i] : { id: i, label: 'Painel 01', layout: { x, y, w, h } }
          acc[i] = {
            ...panel,
            layout: { x, y, w, h },
          }
          return acc
        }, {})
        const newPanelsValues = Object.values(newPanels)

        const panelsLayout = panelsList.map((e) => e?.layout)
        const newPanelsLayout = newPanelsValues.map((e) => e?.layout)

        // Comparação utilizando Lodash.
        //Isso é feito para não adicionar no WIP valores que não foram alterados em relação ao que já está persistido no current
        const areLayoutsEqual = isEqual(panelsLayout, newPanelsLayout)

        if (newPanelsValues.length && !areLayoutsEqual) {
          // Painéis com valor null no wip, devem ser entendidos como painéis deletados pelo usuário e que ainda não foram salvos
          const deletedPanels = pickBy(panels, (value) => value === null)
          // Essa info não pode ser removida do wip, para não desfazer uma remoção em progresso(ainda não salva)
          dispatch(setBetaDashboardProps([['wip.panels', { ...newPanels, ...deletedPanels }]]))
        }
      }}
    >
      {panelsList?.map((panel, index) => {
        if (!panel?.id) return null
        return (
          <div key={panel?.id}>
            <DashboardPanel panelId={panel?.id} />
          </div>
        )
      })}
    </ReactGridLayout>
  )
}

export default DashboardContent
