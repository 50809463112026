import { filtersSchema } from 'schemas/filters'
import * as z from 'zod'

// Função que retorna um schema com validações que requerem acesso à lista de valores existentes
const createStepTwoSchema = (scope: 'measures' | 'rules' | 'references' = 'measures', isDevUser: boolean) => {
  const schemas = {
    measures: z.object({
      filters: z.object({
        assetProp: z.string().optional(),
        assets: z.array(z.string()).optional(), // assets is now an array of strings
        category: isDevUser ? z.array(z.string()).optional() : z.array(z.string()).nonempty(),
        measures: z.array(z.string()).optional(), // measures is now an array of strings
      }),
    }),
    rules: z.object({
      filters: z
        .object({
          selectedAssets: filtersSchema,
          selectedRules: z.array(z.string()).optional().nullable(),
        })
        .optional()
        .nullable(),
    }),
    references: z.object({
      filters: z.object({
        profileId: z.string(),
      }),
    }),
  }

  return schemas[scope]
}

export default createStepTwoSchema
