import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setTableProp } from 'Store/actions/tables-action'
import { Dropdown, Input, Form, Transition } from 'semantic-ui-react'
import { fetchAssetsByMeasureTime, fetchAssetsMeasuresByAbsoluteTime } from 'Store/actions/measures-action.js'

const options = [
  {
    key: '1h',
    value: '1h',
    text: 'Última Hora',
  },
  {
    key: '3h',
    value: '3h',
    text: 'Últimas 3 Horas',
  },
  {
    key: '24h',
    value: '24h',
    text: 'Últimas 24 Horas',
  },
  {
    key: '7d',
    value: '7d',
    text: 'Última Semana',
  },
  {
    key: 'absoluteTime',
    value: 'absoluteTime',
    text: 'Personalizado',
    //disabled: true,
  },
]

function MeasuresHistTimeDropdown() {
  const dispatch = useDispatch()
  const currentTableId = useSelector((state) => state.tables.current?.id ?? 'measuresHist')

  const selectedRelativeTime = useSelector((state) => state.tables.byId[currentTableId].selectedRelativeTime)
  const selectedAbsoluteStartTime = useSelector((state) => state.tables.byId[currentTableId].selectedAbsoluteStartTime)
  const selectedAbsoluteEndTime = useSelector((state) => state.tables.byId[currentTableId].selectedAbsoluteEndTime)
  const showAbsoluteTime = selectedRelativeTime.includes('absoluteTime')

  const filterStepError = useSelector((state) => state.tables.byId[currentTableId].filterStepError)

  const clearSelectedValues = useCallback(() => {
    dispatch(setTableProp(`byId.${currentTableId}.selectedAsset`, []))
    dispatch(setTableProp(`byId.${currentTableId}.selectedAssetProp`, ''))
    dispatch(setTableProp(`byId.${currentTableId}.selectedCategory`, []))
    dispatch(setTableProp(`byId.${currentTableId}.selectedMeasures`, []))
  }, [dispatch, currentTableId])

  const handleNextStep = useCallback(
    (value) => {
      dispatch(setTableProp(`byId.${currentTableId}.selectedRelativeTime`, [value]))
      clearSelectedValues()
      if (value) dispatch(fetchAssetsByMeasureTime(value))
    },
    [currentTableId, clearSelectedValues, dispatch]
  )

  const setFilter = useCallback(
    (_e, { value }) => {
      if (value === 'absoluteTime') {
        clearSelectedValues()
        dispatch(setTableProp(`byId.${currentTableId}.selectedRelativeTime`, ['absoluteTime']))
        return
      }
      handleNextStep(value)
    },
    [handleNextStep, clearSelectedValues, currentTableId, dispatch]
  )

  const handleChangeAbsTimeStart = useCallback(
    (e) => {
      dispatch(setTableProp(`byId.${currentTableId}.selectedAbsoluteStartTime`, [e.target.value]))
    },
    [currentTableId, dispatch]
  )

  const handleChangeAbsTimeEnd = useCallback(
    (e) => {
      dispatch(setTableProp(`byId.${currentTableId}.selectedAbsoluteEndTime`, [e.target.value]))
    },
    [currentTableId, dispatch]
  )

  useEffect(() => {
    if (selectedAbsoluteStartTime && selectedAbsoluteEndTime) {
      clearSelectedValues()
      dispatch(
        fetchAssetsMeasuresByAbsoluteTime(
          new Date(selectedAbsoluteStartTime).toISOString(),
          new Date(selectedAbsoluteEndTime).toISOString()
        )
      )
    }
  }, [dispatch, selectedAbsoluteStartTime, selectedAbsoluteEndTime, clearSelectedValues])

  return (
    <>
      <label>Intervalo de Tempo</label>
      <Dropdown
        selection
        fluid
        placeholder="Selecione o Intervalo de Tempo"
        onChange={setFilter}
        value={selectedRelativeTime[0]}
        options={options}
        upward={false}
        error={filterStepError === 0}
      />
      <br />
      {showAbsoluteTime && (
        <Form.Group widths="equal">
          <Form.Field inline required error={!selectedAbsoluteStartTime} width="4">
            <Transition transitionOnMount={true} animation="fade down" duration="2300">
              <label>Início</label>
            </Transition>
            <Input
              required
              fluid
              type="datetime-local"
              id="start"
              name="date-start"
              onChange={handleChangeAbsTimeStart}
              value={selectedAbsoluteStartTime || ''}
              min={'2022-01-01'}
              max={selectedAbsoluteEndTime}
            />
          </Form.Field>
          <Form.Field inline required error={!selectedAbsoluteEndTime} width="4">
            <Transition transitionOnMount={true} animation="fade down" duration="2300">
              <label>Fim</label>
            </Transition>
            <Input
              required
              fluid
              type="datetime-local"
              id="start"
              name="date-end"
              value={selectedAbsoluteEndTime || ''}
              min={selectedAbsoluteStartTime}
              max={new Date().toISOString().slice(0, 10)}
              onChange={handleChangeAbsTimeEnd}
            />
          </Form.Field>
        </Form.Group>
      )}
    </>
  )
}

export default MeasuresHistTimeDropdown
