/*  Author: Luís Mourão
    Type: Smart
	Description: Render Marker from History				 
    To do list: 
    - Avaliar tranformação das coordenadas ao girar o Mapa
    - Substituir connect por useSelector			
*/

import React, { useEffect } from 'react'
import { useLeaflet } from 'react-leaflet'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import RenderMarker from 'Components/Map/MapMarkers/RenderMarker'
import { changeHistFlag } from 'Store/actions/history-action'

const HistMarker = () => {
  const { map } = useLeaflet()
  const dispatch = useDispatch()

  const histById = useSelector((state) => state.history.byId, shallowEqual)
  const histInit = useSelector((state) => state.history.initial, shallowEqual)

  useEffect(() => {
    if (histInit) {
      let bounds = []

      Object.values(histById).forEach((eachAsset) => {
        Object.values(eachAsset.byIndex).forEach((eachIndex) => {
          if (eachIndex?.info?.antpath?.points) bounds = bounds.concat(eachIndex?.info?.antpath?.points)
        })
      })

      if (bounds.length === 1) map.panTo(bounds[0])
      else if (bounds.length > 0) map.fitBounds(bounds, { padding: [10, 10] })
      else console.warn('Dados sem lat/lng')
      dispatch(changeHistFlag('initial', false))
    }
  }, [map, histById, dispatch, histInit])

  return Object.entries(histById).map((each) => {
    return <RenderMarker assetId={each[0]} mode="history" key={each[0]} />
  })
}

export default HistMarker
