import React from 'react'
import { TableColumn } from '../types'
import { TFunction } from 'i18next'
//import TableSpan from 'Components/SidebarMenu/TableSpan'
import { DateTime } from 'Utils/reactTable/components/DateTime'
import TimeAbsolute from 'Utils/components/TimeAbsolute'
//import { threeColumns } from 'Utils/threeColumns'

export const NotificationsColumns = (t: TFunction) => {
  return [
    {
      Header: t('title'),
      accessor: 'title',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'títulos'}`,
    },
    {
      Header: t('message'),
      accessor: 'message',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} adicionais`,
      Cell: ({ value }) => {
        return (
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '500px', display: 'inline-block' }}>
            {value}
          </div>
        )
      },
    },
    {
      Header: t('time'),
      accessor: 'time',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'tipos'}`,
      Cell: ({ value }) => <TimeAbsolute date={value} seconds />,
    },
    {
      Header: t('updatedAt'),
      accessor: 'updatedAt',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} hour`,
      Cell: ({ value }) => <DateTime value={value} />,
    },
    {
      Header: t('createdAt'),
      accessor: 'createdAt',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} hour`,
      Cell: ({ value }) => <DateTime value={value} />,
    },
    //@ts-ignore
    // ...threeColumns(t),
  ] as TableColumn[]
}
