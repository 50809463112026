import React, { memo } from 'react'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { useSelector } from 'react-redux'
import { FeatureGroup, Pane, Polygon, GeoJSON, Circle, Tooltip } from 'react-leaflet'
import styled from 'styled-components'

const PolygonStyle = styled(Polygon)`
  ${(props) => {
    return ['Tiplam', 'TiplamTest'].includes(props.empresa) ? 'cursor: grab !important' : ''
  }};
`

export const RenderCreationFence = ({ wip, click }) => {
  let geoJson = wip?.geoJson
  const tooltipText = wip['form-name']
  const typeId = wip['form-type']
  const typeData = useSelector((state) => state.profiles.byId[typeId]) || {}
  const geometry = geoJson.geometry
  const typeGeometry = geometry?.type
  const coordinates = geometry?.coordinates

  const color = typeData?.color ? typeData.color : 'blue'
  const inner = useSelector((state) => state.toggle.showInnerMap)
  const currentInner = useSelector((state) => state.indoor.current?.inner)
  //const canAccess = useCanAccess('WRITE')
  //const moduleType = 'fences'
  const zIndex = 440
  //const currentId = useSelector((state) => state.modules[moduleType]?.current?.id)
  const empresa = useSelector((state) => state.login.empresa)
  const fencesEditionMode = useSelector((state) => state.toggle.fencesEditionMode)

  let coords = []
  if (typeGeometry === 'Polygon')
    coords = coordinates[0].map((e) =>
      inner ? innerPosition([e[1], e[0]], 'array', currentInner?.center, currentInner?.bearing) : [e[1], e[0]]
    )
  if (typeGeometry === 'Point')
    coords = inner
      ? innerPosition([coordinates[1], coordinates[0]], 'array', currentInner?.center, currentInner?.bearing)
      : [coordinates[1], coordinates[0]]
  return (
    <FeatureGroup key={'fenceCreation'} clickable={true}>
      <Pane name={'pane#fenceCreation'} style={{ zIndex: zIndex || 220 }}>
        {typeGeometry === 'Polygon' && (
          <PolygonStyle
            fillOpacity={0.5}
            empresa={empresa}
            key={'fenceCreation' + fencesEditionMode}
            weight={1}
            interactive={empresa !== 'Tiplam' || fencesEditionMode}
            positions={coords}
            color={typeData?.color ? typeData?.color : color ? color : 'blue'}
          >
            <Pane name={'pane#ToolTipfenceCreation'} style={{ zIndex: zIndex + 1 || 221 }}>
              {tooltipText && (
                <Tooltip permanent direction="bottom">
                  {tooltipText}
                </Tooltip>
              )}{' '}
            </Pane>
          </PolygonStyle>
        )}
        {typeGeometry === 'MultiPolygon' && (
          <GeoJSON
            fillOpacity={0.5}
            key={'fenceCreation' + fencesEditionMode}
            data={geoJson}
            interactive={empresa !== 'Tiplam' || fencesEditionMode}
            color={typeData?.color ? typeData?.color : color ? color : 'blue'}
          >
            <Pane name={'pane#ToolTipfenceCreation'} style={{ zIndex: zIndex + 1 || 221 }}>
              {tooltipText && (
                <Tooltip permanent direction="bottom">
                  {tooltipText}
                </Tooltip>
              )}
            </Pane>
          </GeoJSON>
        )}
        {typeGeometry === 'Point' && (
          <Circle
            fillOpacity={0.5}
            key={'fenceCreation' + fencesEditionMode}
            radius={geometry.mRadius}
            interactive={empresa !== 'Tiplam' || fencesEditionMode}
            center={coords}
            color={typeData?.color ? typeData?.color : color ? color : 'blue'}
          >
            <Pane name={'pane#ToolTipfenceCreation'} style={{ zIndex: zIndex + 1 || 221 }}>
              {tooltipText && (
                <Tooltip permanent direction="bottom" offset={[0, 20]}>
                  {tooltipText}
                </Tooltip>
              )}
            </Pane>
          </Circle>
        )}
      </Pane>
    </FeatureGroup>
  )
}

export default memo(RenderCreationFence)
