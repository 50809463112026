import React from 'react'
import { Button, Segment } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useCanAccess } from 'Utils/rbac'
import { useDispatch, useSelector } from 'react-redux'
import { clearWiP, saveGeoModule, setModulesProps } from 'Store/actions/modules-action'
import { setFalse } from 'Store/actions/toggle-action'
import validateForm from 'Utils/validateForm'
import moduleSchema from 'Components/forms/modules/schemas/moduleSchema'
import _, { cloneDeep } from 'lodash'
import confirmClose from './utils/confirmClose'
import useEntitySelector from 'Utils/hooks/useEntitySelector'
import { v4 } from 'uuid'
import { changeImagesPath } from './utils/changeImagesPath'

const StatsBarFooter = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const canAccess = useCanAccess('WRITE')

  const wip = useSelector((state) => state.modules.wip)
  const isUpdate = !!wip.id
  const current = useSelector((state) => state.modules.current)
  const type = useEntitySelector('modules', 'type')
  const cudLoading = useSelector((state) => state.modules.cudLoading)
  const tenant = useSelector((state) => state.login.empresa)

  const profileId = useEntitySelector('modules', 'profile')
  const customFieldsStruct = useSelector((state) =>
    profileId ? state.profiles.byId[profileId]?.customFields || [] : []
  )

  const handleSave = () => {
    validateForm({
      values: isUpdate ? _.merge({}, current, wip) : wip,
      schema: moduleSchema,
      onError: (err) => {
        dispatch(setModulesProps([['form.errors', err]]))
      },
      onSuccess: async () => {
        let wipToSave = cloneDeep(wip)

        if (!isUpdate && (wip.properties?.customFields || wip.properties?.url)) {
          wipToSave.id = v4()

          dispatch(setModulesProps([['cudLoading', true]]))

          const newProperties = await changeImagesPath({
            tenant,
            moduleId: wipToSave.id,
            customFieldsStruct,
            properties: wipToSave.properties,
          })

          if (newProperties) wipToSave.properties = newProperties
        }

        await dispatch(
          saveGeoModule({
            mutation: isUpdate ? 'update' : 'create',
            fields: wipToSave,
            type,
          })
        )
        dispatch(setFalse('showStatsBar'))
        dispatch(setFalse('fencesEditionMode'))
        dispatch(clearWiP(type))
      },
    })
  }

  const handleCancel = () => {
    confirmClose({ wip, dispatch, type, tenant })
  }

  return (
    <Segment style={{ width: '100%', bottom: 0, position: 'absolute', border: 'none' }}>
      <Button
        floated="left"
        icon={{ name: 'delete', style: { zIndex: '1' } }}
        fluid={!canAccess}
        id="form-button-cancel-statsbar"
        onClick={handleCancel}
        content={canAccess ? t('Cancel') : t('Close')}
        style={{ minWidth: '120px' }}
        title={t('clickToCancel')}
      />
      {canAccess && (
        <Button
          floated="right"
          icon={'check'}
          id="form-button-save-statsbar"
          onClick={handleSave}
          color="blue"
          content={t('Save')}
          style={{ minWidth: '120px' }}
          title={t('clickToSave')}
          loading={cudLoading}
        />
      )}
    </Segment>
  )
}
export default StatsBarFooter
