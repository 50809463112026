import React, { useState, useRef, LegacyRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Dimmer, Image } from 'semantic-ui-react'
import styled from 'styled-components'
import { uploadImage, removeImage } from 'Store/actions/system-action'
import { RootStore } from 'Store/initialStore'

interface Props {
  perfil: string
  tenant: string
}

const Logo = styled(Image)`
  > img {
    max-height: 52px;
  }
`

const TenantLogo: React.FC<Props> = ({ perfil, tenant }) => {
  const [active, setActive] = useState(false)
  const [objectUrl, setObjectUrl] = useState('')

  const currentUrl = useSelector((state: RootStore) => state?.tenants?.current?.info?.url)

  useEffect(() => {
    if (currentUrl) {
      setObjectUrl(currentUrl)
    }
  }, [currentUrl])

  const fileRef: LegacyRef<HTMLInputElement> = useRef(null)
  const revokeUrl = () => objectUrl && window.URL.revokeObjectURL(objectUrl)

  const handleSelect = ({ target }: any) => {
    const file = target?.files?.[0]
    if (!file) return
    if (!/^image\//.test(file.type)) {
      alert('Imagem inválida')
      return
    }
    revokeUrl()
    const oURL = window.URL.createObjectURL(file)
    setObjectUrl(oURL)

    uploadImage(file, 'logo')
  }

  const handleShow = () => setActive(true)
  const handleHide = () => setActive(false)
  const handleEdit = () => fileRef.current && fileRef.current?.click()
  const hideImage = (error: any) => {
    setObjectUrl('./imageError.png')
    // console.log(error)
  }

  const removeUrl = async () => {
    try {
      removeImage(url.split('/')[url.split('/').length - 1], 'logo')

      revokeUrl()
      //  hideImage();
    } catch (err) {
      console.error(err)
      alert('Erro ao deletar a imagem')
    }
  }

  const allowedProfiles = ['dev', 'adm', 'sup']
  const isAllowed = allowedProfiles.includes(perfil.toLocaleLowerCase())
  const content = isAllowed ? (
    <div>
      <input type="file" onChange={handleSelect} accept="image/*" style={{ display: 'none' }} ref={fileRef} />
      <Button icon="photo" primary size="mini" onClick={handleEdit} />
      <Button icon="trash" size="mini" negative onClick={removeUrl} />
    </div>
  ) : (
    <div>
      <input type="file" accept="image/*" style={{ display: 'none' }} ref={fileRef} />
    </div>
  )
  const url =
    objectUrl ||
    currentUrl ||
    'https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/navBarLogo/' + tenant + '.png'
  return (
    <Dimmer.Dimmable
      as={Logo}
      dimmed={active}
      dimmer={isAllowed ? { active, content } : { content }}
      onMouseEnter={handleShow}
      onMouseLeave={handleHide}
      onError={hideImage}
      src={url}
      alt={'Logo'}
    />
  )
}

export default TenantLogo
