import React, { useEffect, useState } from 'react'
import { logError } from 'Utils/analytics'
import App from 'App'
import { fetchLogin, setLoginConnection } from 'Store/actions/login-action'
import { useDispatch, useSelector } from 'react-redux'
import { switchLanguage } from 'Resources/Locale/i18n'
import { useMsal } from '@azure/msal-react'
import { createImageFromInitials } from 'Utils/components/ImageInitials'
import { loginRequest, userSecurityGroup } from 'Views/Authentication/authConfig'
// import { saveLoginPreferences } from 'Store/actions/login-action'
import { Redirect } from 'react-router-dom'
import { subscribeNotificationChange } from 'Store/actions/notification-action'
import { LoadingScreen } from 'Utils/LoadingScreen'
import { useTranslation } from 'react-i18next'

export const LoadingApp = ({ auth }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const msal = useMsal()
  const language = useSelector((state) => state.login.language)
  const storeTenant = useSelector((state) => state.login.empresa)
  const login = useSelector((state) => state.login.email)
  const userInfo = handleUser(auth.userProfile, msal)
  const [profile, setProfile] = useState(userInfo)
  const loadingLogin = useSelector((state) => state.login.loading)

  useEffect(() => {
    let subNotification
    if (profile?.empresa) {
      subNotification = dispatch(subscribeNotificationChange(profile?.empresa, profile?.email))
    }
    return () => {
      subNotification && subNotification.unsubscribe()
    }
  }, [dispatch, profile])

  useEffect(() => {
    window.addEventListener('online', () => {
      dispatch(setLoginConnection('reconnected'))
      // document.getElementById('ContainerMainContent').style.filter = 'grayscale(0)'
      console.log('reconnected')
    })
  }, [dispatch])

  useEffect(() => {
    window.onoffline = () => {
      console.log('The network connection has been lost.')
      dispatch(setLoginConnection('disconnected'))
    }
  }, [dispatch])

  useEffect(() => switchLanguage(language), [language])

  useEffect(() => {
    const { getProfile } = auth
    let userInfoSafe = profile
    ;(async () => {
      if (!userInfoSafe) {
        try {
          const myProfile = await getProfile()
          userInfoSafe = handleUser(myProfile)
          setProfile(userInfoSafe)
          return
        } catch (e) {
          logError(e)
          return
        }
      }
      if (!userInfoSafe) return
      if (userInfoSafe.auth === 'msal') {
        await requestAccessToken(msal.accounts, msal.instance)
      }
      dispatch(fetchLogin(userInfoSafe))
    })()
  }, [msal.accounts, msal.instance, dispatch, auth, profile])
  // calls the isAuthenticated method in authentication service

  if (profile?.perfil === 'NA') {
    return <Redirect to="/not-authorized" />
  }
  if (profile && storeTenant && login && !loadingLogin) {
    return (
      <div id="app-container">
        {profile && storeTenant && login && !loadingLogin && (
          <React.StrictMode>
            <App profile={profile} />
          </React.StrictMode>
        )}
      </div>
    )
  } else {
    return (
      <LoadingScreen
        carregando={true}
        text={t('loading')}
        text1={t('Takingtoolong')}
        text2={t('Checkyourconnection')}
      />
    )
  }
}

const handleUser = (userAuth0, msal) => {
  if (userAuth0) {
    let empresa = userAuth0['http://plataforma.phygitall.com.br/empresa']
    let perfil = userAuth0['http://plataforma.phygitall.com.br/perfil'] ?? 'POC'
    let section = userAuth0['http://plataforma.phygitall.com.br/section'] ?? 'default'
    const email = userAuth0.email ?? userAuth0.name
    const picture = userAuth0.picture ?? createImageFromInitials(200, userAuth0.name, '#0B563B')
    return { empresa, perfil, section, email, picture, auth: 'auth0' }
  } else if (msal.accounts.length > 0) {
    const acc = msal.accounts[0]
    const roles = acc.idTokenClaims['roles']
    return {
      empresa: 'Tiplam',
      perfil: userSecurityGroup(roles),
      section: 'default',
      email: acc.username,
      name: acc.name,
      picture: createImageFromInitials(200, acc.name ?? 'Fulano', '#0B563B'),
      auth: 'msal',
    }
  }
}

async function requestAccessToken(acc, instance) {
  const request = {
    ...loginRequest,
    account: acc[0],
  }
  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  try {
    const response = await instance.acquireTokenSilent(request)
    console.log(new Date().getTime(), response)
    localStorage.setItem('id_token', response.accessToken)
  } catch (err) {
    console.error(err)
    const response = await instance.acquireTokenPopup(request)
    localStorage.setItem('id_token', response.accessToken)
  }
}
