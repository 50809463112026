import React from 'react'
import { View, StyleSheet /* , Font */ } from '@react-pdf/renderer'
import ReportTableHeader from './ReportTableHeader'
import ReportTableRow from './ReportTableRow'

//Font.register({ family: 'Times-Roman' })

const styles = StyleSheet.create({
  tableContainer: {
    //flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    // marginTop: 10,
    borderWidth: 1,
    borderColor: '#B2BEB5',
  },
})

const ReportItemsTable = ({ items, allStyles, columns }) => {
  const width = 540
  const columnWidth = width / columns.length
  return (
    <View style={styles.tableContainer}>
      <ReportTableHeader headerStyles={allStyles.headerStyles} columns={columns} />
      <ReportTableRow rowStyles={allStyles.rowStyles} items={items} columnWidth={columnWidth} />
    </View>
  )
}

export default ReportItemsTable
