import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { setTableProp } from 'Store/actions/tables-action'
import { useTranslation } from 'react-i18next'

function MeasuresHistMeasuresDropdown() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentTableId = useSelector((state) => state.tables.current?.id ?? 'measuresHist')
  const measuresOptions = useSelector((state) => state.tables.byId.measuresHist.measuresOptions)
  const filterStepLoading = useSelector((state) => state.tables.byId.measuresHist.filterStepLoading)
  const selectedMeasures = useSelector((state) => state.tables.byId[currentTableId].selectedMeasures)
  const filterStepError = useSelector((state) => state.tables.byId[currentTableId].filterStepError)

  const auxOptions = measuresOptions.length > 0 ? measuresOptions : selectedMeasures
  const options = auxOptions
    .map((measure) => ({ key: measure, value: measure, text: t(measure) }))
    .sort((a, b) => a.text?.localeCompare(b.text))

  return (
    <Dropdown
      selection
      multiple
      search
      fluid
      placeholder="Selecionar Medidas"
      upward={false}
      options={options}
      onChange={(a, { value }) => dispatch(setTableProp(`byId.${currentTableId}.selectedMeasures`, value))}
      noResultsMessage={
        filterStepLoading === 3 ? 'Carregando opções, aguarde...' : 'Nenhum resultado encontrado no filtro selecionado.'
      }
      loading={filterStepLoading === 3}
      value={selectedMeasures}
      error={filterStepError === 3}
      clearable
    ></Dropdown>
  )
}

export default MeasuresHistMeasuresDropdown
