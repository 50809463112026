import React, { useEffect, useMemo } from 'react'
import { fetchAssetImage } from 'Store/actions/assets-action'
import { useDispatch } from 'react-redux'
import useFetchData from 'Utils/hooks/useFetchData'
import useImageUrl from 'Utils/hooks/useImageUrl'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import PeopleBoardContent from './PeopleBoardContent'
import { createImageFromInitials } from 'Utils/components/ImageInitials'

export const PeopleBoard = ({ assetId, name, cardId }) => {
  const dispatch = useDispatch()
  const loadingImage = useObjSelector((state) => state.assets.byId[assetId]?.loadingImage)
  const tenant = useObjSelector((state) => state.login.empresa)
  const assetImageContent = useObjSelector((state) => state.assets.byId[assetId]?.info?.image?.content)
  const assetImageType = useObjSelector((state) => state.assets.byId[assetId]?.info?.image?.type)
  const hasIntegration = useObjSelector((state) => state.tenants.current?.info?.integrations?.peopleData)
  const measures = useObjSelector((state) => state.measures?.byId?.[assetId]?.info?.fencesLocation)
  const fenceNames = measures && Object.values(measures)?.map((ms) => ms.name)

  const { signedURL, loadingImage: loadingSignedImage } = useImageUrl(`${tenant}/images/avatar/${assetId}.png`, {
    fallbackUrl: createImageFromInitials(200, name, '#4b72b2'),
  })

  const imageUri = useMemo(() => {
    if (!assetImageContent) return '../images/noPhoto.png'
    if (hasIntegration) {
      return `data:image/${assetImageType};base64,${assetImageContent}`
    }
    return assetImageContent
  }, [assetImageType, assetImageContent, hasIntegration])

  useEffect(() => {
    if (hasIntegration) dispatch(fetchAssetImage(cardId, assetId))
  }, [dispatch, cardId, assetId, hasIntegration])

  useFetchData({
    independentFetches: [
      {
        fetchAction: 'measures',
      },
    ],
  })

  if (hasIntegration) {
    return (
      <PeopleBoardContent
        assetId={assetId}
        name={name}
        imageUri={imageUri}
        fenceNames={fenceNames}
        loadingImage={loadingImage}
      />
    )
  }

  return (
    <PeopleBoardContent
      assetId={assetId}
      name={name}
      imageUri={signedURL}
      fenceNames={fenceNames}
      loadingImage={loadingSignedImage}
    />
  )
}
