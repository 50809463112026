import { MapLayer, withLeaflet } from 'react-leaflet'
import './lib/leaflet-distortableimage'
import './lib/leaflet-path-transform'
import L from 'leaflet'
//import _ from 'lodash';

class ReactDistortableImageOverlayMapLayer extends MapLayer {
  createLeafletElement(props) {
    this.distortableImage = new L.DistortableImageOverlay(props.url, this.getOptions(props))
    L.DomEvent.on(this.distortableImage, 'load', this.distortableImage.editing.enable, this.distortableImage.editing)

    L.DomEvent.on(
      this.distortableImage,
      'load',
      () => {
        this.distortableImage._image.style.opacity = this.props.opacity
        //this.handleEditModeState(props.editMode);
        L.DomEvent.on(
          this.distortableImage._image,
          'mouseup',
          (e) => {
            this.props.onSelection(this.props.id)
          },
          this.distortableImage
        )
        this.distortableImage.on(
          'edit',
          (update) => {
            this.props.onUpdate(update.sourceTarget._corners)
          },
          this.distortableImage
        )
      },
      this.distortableImage
    )
    return this.distortableImage
  }

  updateLeafletElement(fromProps, toProps) {
    /* this.distortableImage.on('dragend', 
		 _.throttle((e) => this.props.onUpdate(e.sourceTarget._corners), 500, {'leading': false})
		, this.distortableImage); */
    this.distortableImage.on(
      'delete:image',
      (e) => {
        this.props.onDelete(toProps)
      },
      this.distortableImage
    )
    //this.handleEditModeState(toProps.editMode);
  }
}
export default withLeaflet(ReactDistortableImageOverlayMapLayer)
