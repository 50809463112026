import React from 'react'
import { RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Legend } from 'recharts'

//import { colors, lightColors, semanticColors } from 'Utils/colorsFormat'

export const GraphRadar = ({ data = [], distinct = [], maxValue = 'auto' }) => {
  const maxDataValue =
    !distinct.lenght &&
    data.reduce((prev, curr) => {
      return parseFloat(curr.value) > prev ? parseFloat(curr.value) : prev
    }, 5)
  return (
    <ResponsiveContainer>
      <RadarChart cx={'50%'} cy={'50%'} outerRadius={'80%'} data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        <PolarRadiusAxis domain={[0, maxDataValue || maxValue]} />
        {distinct?.length === 0 ? (
          <Radar name="radar" dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
        ) : (
          distinct.map((dis) => (
            <Radar
              name={dis.name}
              dataKey={dis.name}
              key={`radar-${dis.name}`}
              fill={dis.color}
              stroke={dis.color}
              fillOpacity={0.6}
            />
          ))
        )}
        {distinct?.length !== 0 && <Legend />}
      </RadarChart>
    </ResponsiveContainer>
  )
}

export default GraphRadar
