import React from 'react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { useSelector, useDispatch } from 'react-redux'
import { setTableProp, /*fetchMeasuresHistory, */ setShowModal } from 'Store/actions/tables-action'
import { /*Dropdown,*/ Form } from 'semantic-ui-react'
import styled from 'styled-components'
import HistoryStepsHeader from 'Utils/reactTable/modals/historySteps/HistoryStepsHeader'
import HistoryStepsTime from 'Utils/reactTable/modals/historySteps/HistoryStepsTime'
import HistoryStepsAssetsDropdown from 'Utils/reactTable/modals/historySteps/HistoryStepsAssetsDropdown'
import HistoryStepsCategoriesDropdown from 'Utils/reactTable/modals/historySteps/HistoryStepsCategoriesDropdown'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { setFalse } from 'Store/actions/toggle-action'
import { fetchHolesLinesHist } from 'Store/actions/history-action'

const FormField = styled(Form.Field)`
  margin-bottom: 18px !important;
`

const HistorySteps = () => {
  const dispatch = useDispatch()

  let currentTableId = 'historySteps'
  const showModal = useSelector((state) => state.toggle.showHistorySteps)
  const currentFilterStep = useSelector((state) => state.tables.byId[currentTableId]?.currentFilterStep || 0)
  const filterStep = useObjSelector((state) => state.tables.byId[currentTableId]?.filters?.[currentFilterStep] || {})
  const selectedRelativeTime = useSelector((state) => state.tables.byId[currentTableId].selectedRelativeTime)
  const selectedAbsoluteStartTime = useSelector((state) => state.tables.byId[currentTableId].selectedAbsoluteStartTime)
  const selectedAbsoluteEndTime = useSelector((state) => state.tables.byId[currentTableId].selectedAbsoluteEndTime)
  const showAbsoluteTime = selectedRelativeTime?.includes('absoluteTime')
  const selectedAsset = useSelector((state) => state.tables.byId[currentTableId].selectedAsset)

  function filterRightAction() {
    let showNextStep = false
    if (
      (currentFilterStep === 0 && selectedRelativeTime.length > 0 && !showAbsoluteTime) ||
      (currentFilterStep === 0 &&
        selectedRelativeTime.length > 0 &&
        showAbsoluteTime &&
        selectedAbsoluteStartTime &&
        selectedAbsoluteEndTime) ||
      (currentFilterStep === 1 && selectedAsset.length > 0)
    ) {
      dispatch(setTableProp(`byId.${currentTableId}.currentFilterStep`, currentFilterStep + 1))
      showNextStep = true
    }
    if (currentFilterStep === 2) {
      showNextStep = true
      dispatch(fetchHolesLinesHist())
      dispatch(setShowModal(`showHistorySteps`, false))
    }
    if (!showNextStep) {
      dispatch(setTableProp(`byId.${currentTableId}.filterStepError`, currentFilterStep))
    }
  }

  return (
    <ModalTemplate
      onClose={() => dispatch(setFalse(`showHistorySteps`))}
      open={showModal}
      size="large"
      header={<HistoryStepsHeader />}
      saveContent={currentFilterStep === 2 ? 'Carregar' : 'Próximo'}
      onSave={filterRightAction}
      onCancel={() => dispatch(setFalse(`showHistorySteps`))}
      fixedHeight="25vh"
    >
      <Form style={{ marginTop: '5vh', marginBottom: '6.6vh' }}>
        {filterStep.id === 'relativeTime' && (
          <FormField inline required error={false}>
            <HistoryStepsTime />
          </FormField>
        )}
        {filterStep.id === 'asset' && (
          <FormField inline required error={false}>
            <HistoryStepsAssetsDropdown />
          </FormField>
        )}
        {filterStep.id === 'category' && <HistoryStepsCategoriesDropdown />}
      </Form>
    </ModalTemplate>
  )
}

export default HistorySteps
