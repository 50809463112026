import moment from 'moment'
import React, { ForwardedRef, forwardRef, Fragment } from 'react'
import { SegmentGroup, Segment, Label, Image, Header } from 'semantic-ui-react'
import AmpImage from './images/AmpImage'
import styled from 'styled-components'
import { chunk } from 'lodash'

const BoldSpan = styled.span`
  font-weight: bold;
`

const StyledHeader = styled(Header)`
  margin: 0px !important;
`
type ModelFiled = {
  id: string
  type: string
  value: any
  label: string
}
interface PrintModelViewProps {
  email: string
  name: string
  code: string
  createdByLabel?: string
  createdBy?: string
  createdAtLabel?: string
  createdAt: string
  fields: {
    groupedSegments: ModelFiled[]
    segments: ModelFiled[]
  }
  printMode?: boolean
}

const PrintModelView = forwardRef(
  (
    {
      email,
      name,
      code,
      createdBy = '',
      createdAt,
      createdByLabel = 'Criado por: ',
      createdAtLabel = 'Criado às: ',
      fields,
      printMode,
    }: PrintModelViewProps,
    ref: ForwardedRef<HTMLDivElement | null>
  ) => {
    const groupedSegmentPairs = chunk(fields.groupedSegments, 2)

    return (
      <div ref={ref} style={{ margin: printMode ? 15 : 0 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '99%' }}>
          <span style={{ fontSize: '12px' }}>{`Emissão: ${moment().format('DD/MM/YYYY HH:mm')}`}</span>
          <span style={{ fontSize: '12px' }}>{`Por: ${email}`}</span>
        </div>

        <SegmentGroup>
          <Segment
            size="large"
            textAlign="center"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Image
              src="https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/Tiplam/miniLogo.png"
              size="tiny"
            />
            <StyledHeader size="tiny">{`${name}`}</StyledHeader>
            <Label circular color={'yellow'} size="large">
              {code}
            </Label>
          </Segment>

          <SegmentGroup horizontal>
            <Segment style={{ flex: 1 }}>
              <BoldSpan>{createdByLabel}</BoldSpan>
              {createdBy}
            </Segment>
            <Segment style={{ flex: 1 }}>
              <BoldSpan>{createdAtLabel}</BoldSpan>
              {moment(createdAt).format('DD/MM/YYYY HH:mm')}
            </Segment>
          </SegmentGroup>

          {fields.segments.length > 0 || fields.groupedSegments.length > 0 ? (
            <>
              {groupedSegmentPairs.map((segmentPair, pairIndex) => (
                <SegmentGroup key={pairIndex} horizontal style={{ flex: 1 }}>
                  {segmentPair.map(({ id, label, value }) => (
                    <Segment key={id} style={{ flex: 1 }}>
                      <BoldSpan>{`${label}${label.includes('?') ? '' : ':'} `}</BoldSpan>
                      {value === true ? 'Sim' : value === false ? 'Não' : value?.toString()}
                    </Segment>
                  ))}
                </SegmentGroup>
              ))}
              {fields.segments.map((field: any) => {
                if (field.type === 'images') {
                  return (
                    <Fragment key={field.id}>
                      <Segment
                        secondary
                        textAlign="center"
                        style={{
                          borderBottom: '1px solid rgba(34,36,38,.15)',
                          borderTop: '1px solid rgba(34,36,38,.15)',
                        }}
                      >
                        <BoldSpan>{`${field.label}`}</BoldSpan>
                      </Segment>
                      {field.value ? (
                        <Image.Group
                          size="small"
                          style={{ display: 'flex', justifyContent: 'space-around', marginTop: '8px' }}
                        >
                          {field.value.map((img: any, index: number) => {
                            return <AmpImage path={img.path} key={img.path} />
                          })}
                        </Image.Group>
                      ) : (
                        <Segment placeholder></Segment>
                      )}
                    </Fragment>
                  )
                }
                return (
                  <Fragment key={field.id}>
                    <Segment secondary textAlign="center" key={field.id} style={{ flex: 1 }}>
                      <BoldSpan>{`${field.label}`}</BoldSpan>
                    </Segment>
                    <Segment textAlign={!field.value ? 'left' : 'center'}>
                      {field.value || 'Nenhuma descrição cadastrada'}{' '}
                    </Segment>
                  </Fragment>
                )
              })}
            </>
          ) : (
            <Segment textAlign="center">Nenhum campo configurado</Segment>
          )}
        </SegmentGroup>
      </div>
    )
  }
)

export default PrintModelView
