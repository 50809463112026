import { FormStepItems, StoreForm } from 'interfaces/formStepItems'
import { Steps } from 'Components/forms/activities/schemas/steps'

export interface Activity extends Steps {}

export interface WipActivity extends Partial<Activity> {}

export interface Activities {
  allIds: string[]
  byId: Record<string, Activity>
  current: Activity | null
  wip: WipActivity
  form: StoreForm
  loading: boolean
}

export const activitiesItems: FormStepItems[] = [
  {
    id: 'identification',
    icon: 'info',
    title: 'Identificação',
    description: 'Identifique a Atividade',
    showFeedback: false,
    disabled: false,
  },
  {
    id: 'location',
    icon: 'map marker',
    title: 'Localização',
    description: 'Indique a localização',
    showFeedback: false,
    disabled: false,
  },
]

export const activities: Activities = {
  allIds: [],
  byId: {},
  current: null,
  wip: {},
  form: {
    steps: {
      current: 0,
      items: activitiesItems,
    },
    open: true,
    loading: false,
  },
  loading: true,
}
