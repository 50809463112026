import { omit } from 'lodash'
import { createRule, updateRule, deleteRule } from 'graphql/mutations'
import { API } from 'aws-amplify'
import { listRules } from 'graphql/queries'
import { onChangeRule, onDeleteRule } from 'graphql/subscriptions'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'
import { mutateEntity } from 'Apis/mutateEntity'

export const fetchRules = (company) => async (dispatch, getState) => {
  const tenant = company || getState().login.empresa
  try {
    dispatch({ type: 'FETCH_RULES_START', tenant })
    const response = await API.graphql({
      query: listRules,
      variables: { tenant },
    })
    const { items } = response.data.listRules
    dispatch({
      type: 'FETCH_RULES_SUCCESS',
      payload: items,
    })
  } catch (err) {
    console.error('error fetching rules', err)
  }
}

export const saveRule =
  ({ mutation, fields }) =>
  async (dispatch, getState) => {
    const tenant = getState().login.empresa
    let input = {
      ...fields,
      id: fields.id.includes('#') ? fields.id : `${tenant}#${fields.id}`,
      tenant,
      createdBy: getState().login.email,
      editable: fields.targetId?.length === 0 || fields.editable ? true : false,
    }
    input = omit(input, ['createdAt', 'updatedAt', 'notificationProfile'])
    input.action = input.action.map((action) => {
      return omit(action, ['__typename'])
    })

    await mutateEntity({
      entity: 'Rule',
      dispatch,
      input,
      query: mutation === 'update' ? updateRule : createRule,
    })
  }

export const deleteRules =
  ({ ids }) =>
  async (dispatch, getState) => {
    const tenant = getState().login.empresa
    for (const id of ids) {
      try {
        dispatch({ type: 'DELETE_RULE_START', id })
        await API.graphql({
          query: deleteRule,
          variables: {
            input: {
              id: `${tenant}#${id}`,
            },
          },
        })
        notifySuccess()
      } catch (err) {
        console.error('error:', err)
        notifyError()
      }
    }
  }

export const subscribeRuleChange = () => (dispatch, getState) => {
  const tenant = getState().login.empresa
  const subscription = API.graphql({
    query: onChangeRule,
    variables: {
      tenant,
    },
  }).subscribe({
    next: ({ provider, value }) => {
      const payload = value.data.onChangeRule
      dispatch({ type: 'SAVE_RULE', payload: omit(payload, ['notificationProfile']), id: payload.id })
    },
    error: (error) => console.warn('error', error),
    complete: () => {
      console.warn('WebSocket connection closed unexpectedly.')
      //setTimeout(connectToWebSocket, 5000) // Use exponential backoff
    },
  })
  return subscription
}

export const subscribeRuleDelete = () => (dispatch, getState) => {
  const tenant = getState().login.empresa
  return API.graphql({
    query: onDeleteRule,
    variables: {
      tenant,
    },
  }).subscribe({
    next: ({ provider, value }) => {
      const data = value.data.onDeleteRule
      dispatch({ type: 'DELETE_RULE', id: data.id })
    },
    error: (error) => console.warn('error', error),
  })
}

export const clearRules = () => ({
  type: 'CLEAR_RULES',
})

export const setRuleWip = (payload) => ({
  type: 'SET_RULE_WIP',
  payload,
})

export const setCurrentId = (id) => ({
  type: 'SET_CURRENT_ID',
  id,
})

export const setRulesErrors = (payload) => ({
  type: 'SET_RULES_ERRORS',
  payload,
})

export const setCurrentRule = ({ id = '', payload = undefined }) => {
  return { type: 'SET_CURRENT_RULE', id, payload }
}
