import React, { useState, useRef, useMemo } from 'react'
import { Button, Modal, Dimmer } from 'semantic-ui-react'
import { useReactToPrint } from 'react-to-print'
import styled from 'styled-components'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import moment from 'moment'
import { GeoModule } from '../../interfaces/modules'
import { setFalse } from 'Store/actions/toggle-action'
import { useDispatch, useSelector } from 'react-redux'
import ModalCloseIcon from 'Utils/reactTable/components/ModalCloseIcon'
import { ReactComponent as LoadingLogo } from 'Resources/Icons/loading-logo.svg'
import { RootStore } from 'Store/initialStore'
import { useTranslation } from 'react-i18next'
import { CustomField } from 'API'
import ModuleDetailsContent from './ModuleDetailsContent'

interface ModuleDetailsProps {
  type: 'refs' | 'fences'
}

const StyledModal = styled(Modal)`
  z-index: 1000 !important;
`

const ModuleDetalis: React.FC<ModuleDetailsProps> = ({ type }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const contentToPrint = useRef(null)

  const module = useObjSelector((state) => state.modules.current as GeoModule)
  const profile = useObjSelector((state) => (module.profile ? state.profiles.byId[module.profile] : undefined))

  const email = useSelector((state: RootStore) => state.login.email)

  const fieldsBySection = useMemo(() => {
    if (profile && module) {
      const profileFields: CustomField[] | undefined | null = profile.customFields || []
      const moduleFields = module.properties?.customFields

      return profileFields.reduce(
        (
          acc: Record<
            string,
            {
              label: string // section label
              segments: Array<any>
              groupedSegments: Array<any>
            }
          >,
          profileField
        ) => {
          if (moduleFields?.[profileField.id]) {
            let value = moduleFields[profileField.id]
            let targetKey: 'segments' | 'groupedSegments' = 'groupedSegments'

            const fieldSectionId = profileField.sectionId ?? 'noSection'
            const section = profileFields.find((field) => field.id === fieldSectionId)

            switch (profileField.type) {
              case 'dateTime':
                value = moment(moduleFields[profileField.id]).format('DD/MM/YYYY')
                break
              case 'multiple':
                targetKey = 'segments'
                value = Array.isArray(moduleFields[profileField.id]) ? moduleFields[profileField.id].join(', ') : ''
                break
              case 'textArea':
                targetKey = 'segments'
                break
              case 'images':
                targetKey = 'segments'
                break
            }

            if (!acc[fieldSectionId])
              acc[fieldSectionId] = { label: section?.label ?? '', segments: [], groupedSegments: [] }
            acc[fieldSectionId][targetKey] = [...acc[fieldSectionId][targetKey], { ...profileField, value }]
          }
          return acc
        },
        { noSection: { label: '', segments: [], groupedSegments: [] } }
      )
    } else return { noSection: { label: '', segments: [], groupedSegments: [] } }
  }, [module, profile])

  const [contentMargin, setContentMargin] = useState('0px')

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: 'Print This Document',
    onAfterPrint: () => {
      setContentMargin('0px')
      setLoading(false)
    },
    removeAfterPrint: true,
  })

  const [loading, setLoading] = useState(false)

  return (
    <StyledModal
      open
      onClose={() => {
        dispatch(setFalse('showModuleDetails'))
      }}
      closeIcon={
        <ModalCloseIcon
          action={() => {
            dispatch(setFalse('showModuleDetails'))
          }}
        />
      }
    >
      <Dimmer active={loading}>
        <div className="logoTelaCarregamento">
          <LoadingLogo />
        </div>
      </Dimmer>

      <Modal.Content>
        <ModuleDetailsContent
          ref={contentToPrint}
          email={email}
          name={profile?.name ?? ''}
          fieldsBySection={fieldsBySection}
          code={module.code ?? ''}
          createdAt={module.createdAt}
          createdBy={profile?.createdBy}
          printMode={contentMargin !== '0px'}
        />
      </Modal.Content>

      <Modal.Actions style={{ height: '65px' }}>
        <Button
          onClick={() => dispatch(setFalse('showModuleDetails'))}
          style={{ float: 'left', minWidth: '120px' }}
          icon="close"
          content={t('Close')}
        />

        <Button
          onClick={() => {
            setLoading(true)
            setContentMargin('15px')
            setTimeout(() => {
              handlePrint()
            }, 100)
          }}
          icon="print"
          content={t('print')}
          style={{ backgroundColor: '#3498db', color: 'white' }}
        />
      </Modal.Actions>
    </StyledModal>
  )
}

export default ModuleDetalis
