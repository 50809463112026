/*  Author: Bruno Melo
    Type: Smart
	Description: 
    TODO: 
    -
    -
*/
import React, { useState, useMemo } from 'react'
import { Dropdown, Icon, Grid, Input, Popup, Button } from 'semantic-ui-react'
import getSafe from 'Utils/getSafe.js'
import fitBounds from 'Utils/fitBounds.js'
import { changeLoginPreferences } from 'Store/actions/login-action'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import styled from 'styled-components'
import { setCurrentModule, fetchGeoModulesById, fetchGeoModulesByIds } from 'Store/actions/modules-action'
import MapMethods from 'Utils/components/MapMethods'
import { debounce } from 'throttle-debounce'
import { regExpEscape } from 'Utils/escape'
import { SvgIcon } from 'Utils/components/SvgIcon.jsx'
import { notifyInfo } from 'Utils/components/SystemToasts'
import { logError } from 'Utils/analytics'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import getDisplayName from 'Utils/getDisplayName'

const DropdownStyled = styled(Dropdown)`
  color: #4b72b2;
`
const DropdownMenu = styled(Dropdown.Menu)`
  border-top-width: 0px !important;
`
const DropdownGridInput = styled(Grid)`
  width: 415px;
  padding-left: 10px;
`
const DropdownGridHeader = styled(Grid)`
  width: 425px;
  padding-left: 0px !important;
  padding-right: 0 !important;
  margin: 0px !important;
`
const DropdownHeader = styled(Dropdown.Header)`
  font-size: 1.33em;
  font-weight: 800;
`
const DropdownGridItem = styled(Grid)`
  width: 425px;
  border-radius: 5px;
`
const DropdownGridName = styled(Grid.Column)`
  padding: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 14px;
  padding-right: 0;
`

const ItemEyeGrid = styled(Grid.Column)`
  padding: 10px !important;
  cursor: pointer;
`
const DropdownDivider = styled(Dropdown.Divider)`
  margin-bottom: 10px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
`

const TextHeader = styled.span`
  margin-left: 8px !important;
  display: inline-block;
  margin-top: 0px;
  padding-top: 4px;
  height: 22px;
  position: absolute;
  white-space: nowrap;
  width: 295px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DropdownGeomodules = ({ type = 'fences', perfil, refMap, mainIcon }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const debounceCurrent = () => debounce(300, menuItemHover)

  /*useSelector*/
  const inner = useSelector((state) => state.toggle.showInnerMap)
  const modules = useObjSelector((state) => state.modules[type])
  const profiles = useObjSelector((state) => state.profiles)
  const preferences = useObjSelector((state) => state.login.preferences[type])
  const limitDB = useSelector((state) => state.tenants.current?.info?.limitModules)
  const wip = useObjSelector((state) => state.modules.wip)

  /*this.state*/
  const [bounds, setBounds] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)

  const limitModules = limitDB || 200

  /*useMemo*/

  /*Divide the list by type*/
  const spaceTypes = useMemo(() => {
    return profiles?.allIds
      .filter((fid) => profiles?.byId[fid].type === type)
      .map((tId) => ({
        color: profiles?.byId[tId].color,
        icon: profiles?.byId[tId].icon,
        name: profiles?.byId[tId].name,
        value: tId,
      }))
      .sort((a, b) => (a.name && b.name ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : 1))
  }, [profiles, type])

  const displayNames = useMemo(() => {
    return modules.allIds.reduce((acc, moduleId) => {
      const { code, label, profile } = modules.byId[moduleId]
      const profileName = profiles?.byId[profile]?.name

      const displayName = getDisplayName({ moduleCode: code, moduleLabel: label, profileName })

      acc[moduleId] = displayName

      return acc
    }, {})
  }, [modules, profiles])

  const filtered = useMemo(() => {
    if (!modules) return []

    const regex = new RegExp(regExpEscape(searchValue), 'i')
    return modules.allIds.reduce((acc, id) => {
      const module = modules.byId[id]
      if (module?.properties?.invisible !== false || perfil === 'DEV') {
        const profileName = profiles?.byId[module?.profile]?.name
        const displayName = displayNames[id]
        if (regex.test(displayName || '') || regex.test(profileName || '')) {
          acc.push({
            ...module,
            label: displayName,
          })
        }
      }
      return acc
    }, [])
  }, [modules, searchValue, perfil, profiles, displayNames])

  const visibleFilteredLen = useMemo(() => {
    return (
      modules.allIds.filter((id) => {
        return !preferences[id]?.invisible
      })?.length || 0
    )
  }, [preferences, modules.allIds])

  const hiddenFilteredLen = useMemo(
    () => modules.allIds.length - visibleFilteredLen,
    [modules.allIds.length, visibleFilteredLen]
  )

  const showAll = useMemo(() => {
    const filteredLen = filtered.length
    if (hiddenFilteredLen < filteredLen && filteredLen > limitModules) {
      return false
    } else return hiddenFilteredLen >= visibleFilteredLen
  }, [hiddenFilteredLen, visibleFilteredLen, filtered.length, limitModules])

  // Colocar no modules-action
  const loadGeoModule = async (id) => {
    dispatch({ type: 'FETCH_GEO_MODULE_BY_ID_START', id })
    const module = await fetchGeoModulesById(id)
    dispatch({
      type: 'FETCH_GEO_MODULES_SUCCESS',
      payload: [module],
    })
    return module
  }

  /*On eye Clicked from each Type*/
  const headerIconClick = (all, sType, status) => {
    /* if (all.length > limitModules && status === true) {
      notifyInfo({ msg: t('EyesShowAllLimit') })
      return
    } */
    if (Array.isArray(all) && all.length > 0) {
      all.forEach((e) => {
        preferences[e.id] = {
          id: e.id,
          invisible: !status,
        }
      })
    }
    if (preferences) {
      dispatch(changeLoginPreferences({ field: type, preferences }))
    }
    if (status) {
      const modulesToFetch = all.filter((e) => !modules.byId[e.id].geometry).map((a) => a.id)
      if (modulesToFetch.length > 0) dispatch(fetchGeoModulesByIds({ ids: modulesToFetch, loadingScreen: true }))
    }
  }

  const checkThese = (typeToCheck) => {
    const filteredByTypeIds = filtered.filter((ftf) => ftf.profile === typeToCheck).map((ftfm) => ftfm.id) || []
    const allInvisible = filteredByTypeIds.filter((fbti) => preferences[fbti] && preferences[fbti].invisible) || []
    return allInvisible.length >= filteredByTypeIds.length / 2
  }

  //Mounts each dropdownItem
  //To Do: Refactor this function
  const sortMap = (filtered) => {
    if (Array.isArray(filtered) && filtered.length > 0) {
      let res = []
      spaceTypes.forEach((spaceType, j) => {
        filtered
          .filter((fgeo) => getSafe(() => fgeo.profile.toString() === spaceType.value.toString(), false))
          .sort((prev, next) => prev.label.localeCompare(next.label))
          .forEach((e, i) => {
            const invisible = preferences[e.id]?.invisible || false
            const status = checkThese(spaceType.value.toString())
            if (!i) {
              if (j) res.push(<DropdownDivider key={'dpGeoModDivEnd' + j} />)
              //Profiles headers
              res.push(
                <DropdownGridHeader className="header" key={spaceType.name}>
                  <DropdownGridName width={14}>
                    <DropdownHeader>
                      <SvgIcon title={spaceType.name} color={spaceType.color} name={spaceType.icon} compact />
                      <TextHeader title={spaceType.name}>{spaceType.name}</TextHeader>
                    </DropdownHeader>
                  </DropdownGridName>
                  <ItemEyeGrid width={2} textAlign="right" style={{ left: '2px' }}>
                    <Icon
                      onClick={() =>
                        headerIconClick(
                          filtered.filter((fge) => getSafe(() => fge.profile === spaceType.value, false)),
                          spaceType.value,
                          status
                        )
                      }
                      name={status ? 'eye slash' : 'eye'}
                      title={status ? t('EyesShow') : t('EyesHide')}
                      size={'large'}
                    />
                  </ItemEyeGrid>
                </DropdownGridHeader>
              )
            }
            //Each DropdownItem by profile
            res.push(
              <Dropdown.Item value={e.id} key={spaceType.name + e.id}>
                <DropdownGridItem id="DropdownGridItem" onMouseEnter={() => debounceCurrent(e.id)}>
                  <DropdownGridName onClick={() => itemClick(e.id)} width={14} title={e.label}>
                    {e.label}
                  </DropdownGridName>
                  <ItemEyeGrid width={2} textAlign="right" style={{ left: '2px' }}>
                    {wip.loading === true && wip.id === e.id ? (
                      <Icon name="spinner" size="small" loading />
                    ) : (
                      <Icon
                        onClick={() => iconClick(e.id, !invisible)}
                        name={invisible ? 'eye slash' : 'eye'}
                        title={invisible ? t('EyesShow') : t('EyesHide')}
                        size="small"
                      />
                    )}
                  </ItemEyeGrid>
                </DropdownGridItem>
              </Dropdown.Item>
            )
          })
      })
      // No profile items
      filtered
        .filter((fG) => getSafe(() => !fG.profile, false))
        .sort((prev, next) => prev.label.localeCompare(next.label))
        .forEach((e, i) => {
          const invisible = preferences[e.id]?.invisible || false
          const status = checkThese(undefined)
          if (!i) {
            res.push(<DropdownDivider key={'dpFencesDivEndNone'} />)
            res.push(
              <DropdownGridHeader className="header" key={'nonenonenone'}>
                <DropdownGridName width={14}>
                  <DropdownHeader>
                    <SvgIcon title={'Sem Atribuição'} color={'gray'} name={'FaCircle'} compact />
                    <TextHeader title={t('none')}>{t('none')}</TextHeader>
                  </DropdownHeader>
                </DropdownGridName>
                <ItemEyeGrid width={2} textAlign="right" style={{ left: '2px' }}>
                  <Icon
                    onClick={() =>
                      headerIconClick(
                        filtered.filter((fence) => getSafe(() => !fence.profile, false)),
                        'none',
                        status
                      )
                    }
                    name={status ? 'eye slash' : 'eye'}
                    title={status ? t('EyesShow') : t('EyesHide')}
                    size={'large'}
                  />
                </ItemEyeGrid>
              </DropdownGridHeader>
            )
          }
          res.push(
            <Dropdown.Item value={e.id} key={'none' + e.id}>
              <DropdownGridItem id="DropdownGridItem" onMouseEnter={() => debounceCurrent(e.id, type)}>
                <DropdownGridName onClick={() => itemClick(e.id)} width={14} title={e.label}>
                  {e.label}
                </DropdownGridName>
                <ItemEyeGrid width={2} textAlign="right" style={{ left: '2px' }}>
                  {wip.loading === true && wip.id === e.id ? (
                    <Icon name="spinner" size="small" loading />
                  ) : (
                    <Icon
                      onClick={() => iconClick(e.id, !invisible)}
                      name={invisible ? 'eye slash' : 'eye'}
                      title={invisible ? t('EyesShow') : t('EyesHide')}
                      size="small"
                    />
                  )}
                </ItemEyeGrid>
              </DropdownGridItem>
            </Dropdown.Item>
          )
        })
      return res
    } else return null
  }

  //Goes to the selected item
  const itemClick = async (id) => {
    try {
      let coords = modules.byId[id].geometry?.coordinates
      let radius = modules.byId[id].geometry?.mRadius
      dispatch(setCurrentModule(id, type))
      if (!coords) {
        const module = await loadGeoModule(id)
        coords = module.geometry.coordinates
        radius = module.geometry.mRadius
      }
      dispatch(changeLoginPreferences({ field: type, preferences: { [id]: { id, invisible: false } } }))
      if (inner) coords = innerPosition(coords, 'coordinates')
      if (!radius) fitBounds(type, coords, refMap)
      else setBounds({ type, coords, radius })
    } catch (e) {
      logError(e)
    }
  }

  //Each eye from each perimeter
  const iconClick = async (id, invisible) => {
    if (!modules.byId[id].geometry) await loadGeoModule(id)
    dispatch(changeLoginPreferences({ field: type, preferences: { [id]: { id, invisible } } }))
  }

  //If lost focus in the dropdown
  const handleBlur = () => {
    setOpen(false)
  }

  //Big eye in all function
  const headerAllClick = (all) => {
    if (all.length > limitModules && showAll) {
      notifyInfo({ msg: t('EyesShowAllLimit') })
      return
    }
    if (Array.isArray(all) && all.length > 0) {
      all.forEach((e) => {
        preferences[e.id] = {
          id: e.id,
          invisible: !showAll,
        }
      })
    }
    dispatch(changeLoginPreferences({ field: type, preferences }))
    //Load coordinates of modules that were previously hidden. When necessary
    if (showAll) {
      const ids = all?.reduce((acc, curr) => {
        if (!curr.geometry) return [...acc, curr.id]
        return acc
      }, [])
      if (ids.length > 0) {
        dispatch(fetchGeoModulesByIds({ ids, loadingScreen: true }))
      }
    }
  }

  //Passing the mouse over each item
  const menuItemHover = (id) => {
    dispatch(setCurrentModule(id, type))
  }

  //Search: filter the itens
  const handleSearch = (e, { value }) => {
    setSearchValue(value)
  }

  return (
    <DropdownStyled
      trigger={
        <Popup
          trigger={<Icon name={mainIcon} size="large" onClick={() => setOpen(!open)} />}
          content={t(type[0].toUpperCase() + type.slice(1))}
          position="bottom center"
          size="tiny"
          inverted
        />
      }
      icon={null}
      pointing="top right"
      onBlur={handleBlur}
      style={{ pointerEvents: 'auto' }}
      open={open}
    >
      <Dropdown.Menu>
        <DropdownGridInput className="header">
          <Grid.Column width={8}>
            <Input
              onChange={(e, d) => handleSearch(e, d)}
              icon
              iconPosition="left"
              placeholder={t(type[0].toUpperCase() + type.slice(1)) + '...'}
              size="large"
              style={{ width: '150px' }}
              onKeyUp={(e) => {
                if (e.key === ' ') {
                  e.target.value = e.target.value + ' '
                  e.stopPropagation()
                }
              }}
            >
              <Icon name={'square'} />
              <input />
            </Input>
          </Grid.Column>
          {filtered.length > 0 && (
            <Grid.Column width={8} textAlign="right">
              <Button
                color={filtered?.length >= limitModules && showAll ? 'red' : 'blue'}
                onClick={() => {
                  headerAllClick(filtered)
                }}
                title={
                  filtered?.length >= limitModules
                    ? 'Desabilitado por ter ' + limitModules + ' itens ou mais, por favor usar o filtro ao lado'
                    : showAll
                    ? t('EyesAllShow')
                    : t('EyesAllHide')
                }
              >
                <Icon name={showAll ? 'eye slash' : 'eye'} />
                <span style={{ marginLeft: '7px' }}>
                  {(showAll ? t('EyesAllShow') : t('EyesAllHide')) + ' (' + filtered?.length + ')'}
                </span>
              </Button>
            </Grid.Column>
          )}
        </DropdownGridInput>
        <DropdownDivider />
        <DropdownMenu scrolling>
          {filtered.length > 0 ? (
            sortMap(filtered)
          ) : (
            <Dropdown.Item text={t('No' + type[0].toUpperCase() + type.slice(1))} disabled />
          )}
        </DropdownMenu>
        {bounds && <MapMethods type="circle" fit={bounds} cb={() => setBounds(null)} />}
      </Dropdown.Menu>
    </DropdownStyled>
  )
}

export default React.memo(DropdownGeomodules)
