import React, { Fragment } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { setRuleWip } from 'Store/actions/rules-action'
import { Input, Form, Dropdown, Transition, Divider, Header, Icon, Grid } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ActionRuleFields = ({ checkForm }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const ruleWip = useSelector((state) => state.rules.wip)
  const notificationProfiles = useSelector((state) => state.notificationProfiles)
  const { allIds: messagesIds, byId: messagesById } = useSelector((state) => state.messages, shallowEqual)
  const errors = useSelector((state) => state.rules.errors, shallowEqual)

  function changeAction(value, index, field) {
    let action
    if (value === 'removeAction') {
      action = ruleWip.action.filter((e, i) => i !== index)
    } else {
      const newAction = { ...ruleWip.action[index], [field]: value }
      action = { ...ruleWip.action, [index]: newAction }
    }
    dispatch(setRuleWip({ ...ruleWip, action: Object.values(action) }))
  }

  function actionTypesOptions(actions) {
    let options = [
      { key: '1', value: 'platformNotification', text: t('platformNotification') },
      { key: '2', value: 'toSendEmail', text: t('toSendEmail') },
      { key: '3', value: 'postNotification', text: 'Notificar Dispositivo' },
    ]

    if (actions.length > 1) {
      options.push({ key: '4', value: 'removeAction', text: t('removeAction'), icon: 'trash' })
    } else {
      options.push({ key: '0', value: 'noAction', text: t('noAction') })
    }

    return options
  }

  function createNewAction() {
    dispatch(setRuleWip({ ...ruleWip, action: ruleWip.action.concat({}) }))
  }

  return (
    <>
      <br />
      <Divider horizontal>
        <Header as="h4">Então</Header>
      </Divider>
      <br />
      <br />
      {ruleWip.action.map((eachAction, actionIndex) => {
        const actionOptions = actionTypesOptions(ruleWip.action)
        return (
          <Fragment key={actionIndex}>
            <Form.Group widths="equal">
              <Form.Field inline required error={errors.action?.[actionIndex]?.type}>
                <Transition transitionOnMount={true} animation="fade down" duration="2000">
                  <label>{`${t('Action')} ${eachAction.type === 'noAction' ? '' : actionIndex + 1}`}</label>
                </Transition>
                <Dropdown
                  selection
                  fluid
                  placeholder="Selecionar Ação"
                  onChange={(e, { value }) => changeAction(value, actionIndex, 'type')}
                  value={eachAction.type}
                  options={actionOptions}
                  style={{ zIndex: 7 - actionIndex }}
                  upward={false}
                />
              </Form.Field>
              {eachAction?.type === 'toSendEmail' && (
                <Form.Field
                  inline
                  required={eachAction?.type === 'toSendEmail'}
                  error={checkForm && eachAction.type === 'toSendEmail' && !ruleWip.sendTo}
                >
                  <label>{t('sendTo')}</label>
                  <Input
                    name="sendTo"
                    fluid
                    placeholder="Email válido"
                    onChange={(e, { name, value }) => dispatch(setRuleWip({ ...ruleWip, [name]: value }))}
                    value={ruleWip.sendTo}
                  />
                </Form.Field>
              )}
              {(eachAction.type === 'platformNotification' || eachAction.type === 'postNotification') && (
                <Form.Field inline>
                  <Transition transitionOnMount={true} animation="fade left" duration="2300">
                    <label>Perfil da Notificação</label>
                  </Transition>
                  <Dropdown
                    name="notificationProfileId"
                    required
                    selection
                    fluid
                    placeholder="Selecionar Perfil da Notificação"
                    onChange={(e, { name, value }) => dispatch(setRuleWip({ ...ruleWip, [name]: value }))}
                    value={ruleWip.notificationProfileId}
                    options={notificationProfiles.allIds
                      .filter((id) => notificationProfiles.byId[id].status)
                      .map((fiteredId) => ({
                        key: fiteredId,
                        value: fiteredId,
                        text: notificationProfiles.byId[fiteredId].name,
                      }))}
                    style={{ zIndex: 7 - actionIndex }}
                    error={!!errors?.notificationProfileId}
                    upward={false}
                  />
                </Form.Field>
              )}
            </Form.Group>
            {eachAction && eachAction.type !== 'noAction' && (
              <FormField
                inline
                required={true}
                style={{ zIndex: 7 - actionIndex }}
                error={!!errors?.action?.[actionIndex]?.messageProfileId}
              >
                <Transition transitionOnMount={true} animation="fade left" duration="2300">
                  <label>Mensagem</label>
                </Transition>
                <Dropdown
                  placeholder="Selecionar Perfil de Mensagem"
                  selection
                  fluid
                  name="message"
                  required
                  options={messagesIds
                    .filter((id) => messagesById[id]?.label)
                    .map((id) => ({
                      key: id,
                      text: id.split('#')[1] + ' - ' + messagesById[id]?.label,
                      value: id,
                    }))}
                  value={ruleWip.action[actionIndex].messageProfileId}
                  onChange={(e, { name, value }) => changeAction(value, actionIndex, 'messageProfileId')}
                />
              </FormField>
            )}
            <br />
          </Fragment>
        )
      })}
      <br />
      {ruleWip.action[0].type !== 'noAction' && (
        <Grid centered style={{ marginTop: '2px !important' }}>
          <Icon
            name="plus"
            circular
            title="Acrescentar Ação"
            onClick={() => createNewAction()}
            style={{ cursor: 'pointer' }}
          />
        </Grid>
      )}
    </>
  )
}

const FormField = styled(Form.Field)`
  margin-bottom: 18px !important;
`

export default ActionRuleFields
