import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, /*Icon,*/ List, Header, Button, Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import { ItemDetailsField } from './ItemDetailsField.jsx'

const fieldOrder = [
  {
    field: 'name',
    label: 'Nome',
    type: 'string',
    desc: 'Nome e Sobrenome da pessoa',
  },
  {
    field: 'cardId',
    label: 'NFC',
    type: 'string',
    desc: 'Valor do NFC em decimal (escrito atrás do crachá plástico)',
  },
  {
    field: 'onBoarding',
    label: 'Ambientação',
    type: 'string',
    desc: 'Se recebeu treinamento de ambientação',
  },
  {
    field: 'email',
    label: 'E-mail',
    type: 'string',
    desc: 'Email da pessoa, se disponível',
  },
  {
    field: 'registration',
    label: 'Matrícula',
    type: 'number | null',
    desc: 'Número da matrícula do funcionário, se disponível',
  },
  {
    field: 'company',
    label: 'Empresa',
    type: 'string',
    desc: 'Empresa da pessoa, se disponível',
  },
  {
    field: 'position',
    label: 'Cargo',
    type: 'string | null',
    desc: 'Cargo da pessoa, se disponível',
  },
  // {
  //   field: 'assignment',
  //   label: 'Assignment',
  //   type: 'enum | null',
  //   desc: 'Dentre as Atribuições possíveis da tabela de atribuições',
  // }
]
const DivInfo = styled.div`
  margin-left: 40px;
  width: 800px;
`

const ItemDetailsGenericModal = ({ id, dateRef, setOpen, assetId, assetName }) => {
  const assetById = useSelector((state) => state.assets.byId?.[assetId])
  const assetInfo = useSelector((state) => state.assets.byId?.[assetId]?.info)
  const dataArray = [
    assetById.name,
    assetInfo.cardId,
    assetInfo.onBoarding,
    assetInfo.email,
    assetInfo.registration,
    assetInfo.company,
    assetInfo.position,
  ]

  return (
    <>
      <Modal.Header>
        Detalhes do {assetName || id} - hora de referência: {dateRef}
      </Modal.Header>
      <Modal.Content image>
        <DivInfo>
          <Header style={{ marginBottom: '34px' }}>Informações Pessoais</Header>
          <Grid columns={'equal'}>
            <Grid.Column width={6}>
              <List>
                {fieldOrder.map(
                  (li, i) =>
                    i % 2 === 0 && <ItemDetailsField key={`detailItem${li.field}`} li={li} value={dataArray[i]} />
                )}
              </List>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={4}>
              <List>
                {fieldOrder.map(
                  (li, i) =>
                    i % 2 === 1 && <ItemDetailsField key={`detailItem${li.field}`} li={li} value={dataArray[i]} />
                )}
              </List>
            </Grid.Column>
          </Grid>
        </DivInfo>
      </Modal.Content>
      <Modal.Actions>
        {/*<Button color='black' onClick={() => setOpen(false)}>
        Nope
        </Button>*/}
        <Button content="OK" labelPosition="right" icon="checkmark" onClick={() => setOpen(false)} positive />
      </Modal.Actions>
    </>
  )
}

export default ItemDetailsGenericModal
