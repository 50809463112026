import React, { useMemo, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import EntityDropdownField from './EntityDropdownField'
import DropdownField from './DropdownField'
import useFetchData from 'Utils/hooks/useFetchData'

interface AssetsPropsFieldsProps {
  selectedAssets: string[] | string
  selectedProfiles: string[] | string
  setSelectedAssets: (value: any) => void
  setSelectedProfiles: (value: any) => void
  required?: boolean
  multiple?: boolean
}

const AssetsPropsFields: React.FC<AssetsPropsFieldsProps> = ({
  selectedAssets,
  selectedProfiles,
  setSelectedAssets,
  setSelectedProfiles,
  required = true,
  multiple = true,
}) => {
  useFetchData({
    independentFetches: [
      {
        fetchAction: 'assets',
      },
    ],
  })

  const assetIds = useSelector((state: RootStore) => state.assets.allIds)
  const assetsById = useSelector((state: RootStore) => state.assets.byId)
  const loading = useSelector((state: RootStore) => state.assets.loading)

  const nameOptions = useMemo(() => {
    return assetIds.reduce((acc: any, assetId: string) => {
      const asset = assetsById[assetId]

      const text = `${asset.name || ''}`

      acc.push({
        key: assetId,
        value: assetId,
        text,
      })

      return acc
    }, [])
  }, [assetIds, assetsById])

  const registrationIdOptions = useMemo(() => {
    return assetIds.reduce((acc: any, assetId: string) => {
      const asset = assetsById[assetId]

      const text = asset.info?.registrationId

      if (text) {
        acc.push({
          key: assetId,
          value: assetId,
          text,
        })
      }

      return acc
    }, [])
  }, [assetIds, assetsById])

  const [selectedAssetProp, setSelectedAssetProp] = useState<'name' | 'profile' | 'registrationId' | '<any>'>('<any>')

  const handleChange = (e: React.SyntheticEvent<HTMLElement>, { value }: { value: 'name' | 'profile' | '<any>' }) => {
    setSelectedAssetProp(value)
    setSelectedAssets(multiple ? [] : '')
  }

  return (
    <Form.Group widths="equal">
      <DropdownField
        label="Ativo/Pessoa"
        value={selectedAssetProp}
        placeholder={'Selecionar Propriedade'}
        onChange={handleChange}
        options={[
          {
            key: 'Atribuição',
            value: 'profile',
            text: 'Atribuição',
          },
          {
            key: 'name',
            value: 'name',
            text: 'Nome',
          },
          {
            key: 'registrationId',
            value: 'registrationId',
            text: 'Matrícula',
          },
          {
            key: '<any>',
            value: '<any>',
            text: 'Qualquer Ativo/Pessoa',
          },
        ]}
        noResultsMessage={'Nenhum resultado encontrado no filtro selecionado.'}
        required={required}
        clearable={false}
        search={false}
      />
      {selectedAssetProp === 'name' && (
        <DropdownField
          label="Nome"
          value={selectedAssets}
          placeholder="Selecionar Nome(s)"
          onChange={(_, { value }: { value: string }) => {
            setSelectedAssets(value)
          }}
          options={nameOptions}
          noResultsMessage={'Nenhum resultado encontrado no filtro selecionado.'}
          required={false}
          clearable={false}
          search={true}
          loading={loading}
          lazyLoad={false}
        />
      )}
      {selectedAssetProp === 'profile' && (
        <EntityDropdownField
          label="Atribuição"
          entity="profiles"
          value={selectedProfiles}
          name="profiles"
          placeholder="Selecionar Atribuição(s)"
          onChange={(value: any) => {
            console.log('value', value)
            setSelectedProfiles(value)
          }}
          multiple={multiple}
          filter={(item) => item.type === 'people'}
        />
      )}
      {selectedAssetProp === 'registrationId' && (
        <DropdownField
          label="Matrícula"
          value={selectedAssets}
          placeholder={'Selecionar Matrícula'}
          onChange={(_, { value }: { value: string }) => {
            setSelectedAssets(value)
          }}
          options={registrationIdOptions}
          noResultsMessage={'Nenhum resultado encontrado no filtro selecionado.'}
          required={false}
          clearable={false}
          search={true}
          loading={loading}
          lazyLoad={false}
        />
      )}
    </Form.Group>
  )
}

export default AssetsPropsFields
