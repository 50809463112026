import React, { Fragment } from 'react'
import { Table, Icon, Segment, HeaderProps } from 'semantic-ui-react'
import { ColumnsReactTable } from './modals/ColumnsReactTable'
// import { GroupsReactTable } from './modals/GroupsReactTable'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Loading from 'Utils/components/Loading'
import { ReactTableTemplateContentInterface } from './ReactTableTemplateInterfaces'
import NoDataMessage from 'Utils/components/NoDataMessage'
import HeaderInfoComponent from 'Components/tables/HeaderInfoComponent'
import { GroupsReactTable } from './modals/GroupsReactTable'

const TableHeaderCell = styled(Table.HeaderCell)`
  position: sticky;
  top: -1px;
  z-index: 3;
`

export const ReactTableTemplateContent = ({
  showTable,
  showPlaceholders,
  groupAction,
  // ReactTableTemplate states
  columnsOpen,
  groupsAction,
  groupsOpen,
  table,
  allTableProps,
  setColumnsOpen,
  setGroupsAction,
  setGroupsOpen,
  setHiddenColumnsProp,
}: ReactTableTemplateContentInterface) => {
  const { t } = useTranslation()

  return (
    <Segment style={{ border: 'none', boxShadow: 'none' }}>
      <ColumnsReactTable
        setColumnsOpen={setColumnsOpen}
        columnsOpen={columnsOpen}
        t={t}
        columnsStr={JSON.stringify(allTableProps.columns)}
        allTableProps={allTableProps}
        table={table}
        setHiddenColumnsProp={setHiddenColumnsProp}
      />
      <GroupsReactTable
        setGroupsOpen={setGroupsOpen}
        groupsOpen={groupsOpen}
        setGroupsAction={setGroupsAction}
        selected={allTableProps.selectedFlatRows}
        table={table}
      />

      {showTable && (
        <Fragment>
          <Segment
            style={{
              overflowY: 'auto',
              height: '76vh',
              border: 'none',
              boxShadow: 'none',
              padding: '0',
              margin: '0',
            }}
          >
            <Table striped color={'blue'} singleLine structured {...allTableProps.getTableProps()}>
              <Table.Header>
                <HeaderInfoComponent />
                {allTableProps.headerGroups.map((headerGroup: HeaderProps) => (
                  <Table.Row {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: { [key: string]: any }) => {
                      const props = { ...column.getHeaderProps(column.getSortByToggleProps()) }
                      const { onClick, ...styles } = props
                      return (
                        <TableHeaderCell textAlign={column?.align || 'left'} {...styles}>
                          {column.canGroupBy ? (
                            <Icon
                              {...column.getGroupByToggleProps()}
                              name={column.isGrouped ? 'minus square outline' : 'plus square outline'}
                            />
                          ) : null}

                          <span onClick={onClick}>{column.render('Header')}</span>
                          {column.isSorted ? (
                            <Icon onClick={onClick} name={column.isSortedDesc ? 'sort down' : 'sort up'} />
                          ) : null}
                        </TableHeaderCell>
                      )
                    })}
                  </Table.Row>
                ))}
              </Table.Header>

              {showPlaceholders || allTableProps.data.length === 0 ? (
                <>
                  {showPlaceholders ? (
                    <div
                      style={{
                        position: 'absolute',
                        height: '70vh',
                        paddingTop: '30vh',
                        left: '46vw',
                      }}
                    >
                      <Loading />
                    </div>
                  ) : (
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        right: '0',
                      }}
                    >
                      <NoDataMessage table />
                    </div>
                  )}
                </>
              ) : (
                <Table.Body {...allTableProps.getTableBodyProps()}>
                  {allTableProps.page.map((row: { [key: string]: any }) => {
                    allTableProps.prepareRow(row)
                    return (
                      <Table.Row {...row.getRowProps()}>
                        {row.cells.map((cell: { [key: string]: any }) => (
                          <Table.Cell
                            style={{ minWidth: cell.column?.minWidth, minHeigth: '500px' }}
                            textAlign={cell?.column?.align || 'left'}
                            verticalAlign="middle"
                            {...cell.getCellProps()}
                          >
                            {cell.isGrouped ? (
                              <>
                                <Icon
                                  name={row.isExpanded ? 'minus square outline' : 'plus square outline'}
                                  {...row.getToggleRowExpandedProps()}
                                />
                                {cell.render('Cell')} ({row.subRows.length})
                              </>
                            ) : cell.isAggregated ? (
                              cell.render('Aggregated')
                            ) : cell.isPlaceholder ? null : (
                              cell.render('Cell')
                            )}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              )}
            </Table>
          </Segment>
        </Fragment>
      )}
    </Segment>
  )
}
