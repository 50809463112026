//import { logError } from 'Utils/analytics';

export default function getSafe(fn, cat, type, warn) {
  try {
    if (!type) {
      if (fn() === undefined || fn() === null || fn().toString() === 'NaN') return cat
      else return fn()
    } else {
      if (fn() === undefined || fn() === null || fn().toString() === 'NaN' || typeof fn() !== type) return cat
      else return fn()
    }
  } catch (e) {
    //Por enquanto, só fazemos o log em desenvolvimento, pois há muito erro ainda.
    if (process.env.NODE_ENV === 'development' && warn) console.warn(warn, e)
    return cat
  }
}
