import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Radio, Select } from 'semantic-ui-react'
import { RootStore } from 'Store/initialStore'
import styled from 'styled-components'
import { setPanelProp } from 'Store/actions/betaDashboards-action'
import PanelStepFourDonut from './PanelStepFourDonut'

const FormField = styled(Form.Field)`
  margin-bottom: 2.5vh !important;
`

type Props = {
  panelId: string
}

function PanelStepFour({ panelId }: Props) {
  const dispatch = useDispatch()

  const type = useSelector(
    (state: RootStore) =>
      state.betaDashboards?.wip?.panels?.[panelId]?.type ?? state.betaDashboards.current?.panels[panelId]?.type ?? ''
  )

  const agg = useSelector(
    (state: RootStore) =>
      state.betaDashboards?.wip?.panels?.[panelId]?.filters?.agg ??
      state.betaDashboards.current?.panels[panelId]?.filters?.agg ??
      ''
  )

  const goal = useSelector(
    (state: RootStore) =>
      state.betaDashboards?.wip?.panels?.[panelId]?.filters?.goal ??
      state.betaDashboards.current?.panels[panelId]?.filters?.goal ??
      ''
  )

  const benchmark = useSelector(
    (state: RootStore) =>
      state.betaDashboards?.wip?.panels?.[panelId]?.filters?.benchmark ??
      state.betaDashboards.current?.panels[panelId]?.filters?.benchmark ??
      ''
  )

  useEffect(() => {
    if (!agg) {
      dispatch(setPanelProp({ panelId, field: 'filters.agg', value: 'count' }))
    }
  }, [agg, dispatch, panelId])

  switch (type) {
    case 'kpi':
      return (
        <Form>
          <Form.Group widths="equal" inline>
            <label>Agregação</label>
            <Form.Field control={Radio} name="aggregation" label="Contagem" value="count" checked={true} />
          </Form.Group>
          <br />
          <Form.Group widths="16">
            <FormField
              control={Select}
              width="8"
              name={'label'}
              label="Comparador"
              placeholder={'Comparador'}
              options={[
                { key: '>=', value: '>=', text: 'Maior ou Igual' },
                { key: '>', value: '>', text: 'Maior' },
                { key: '=', value: '=', text: 'Igual' },
                { key: '<', value: '<', text: 'Menor' },
                { key: '<=', value: '<=', text: 'Menor ou Igual' },
                { key: '<>', value: '<>', text: 'Diferente' },
              ]}
              value={benchmark}
              onChange={(_: any, { value }: any) =>
                dispatch(setPanelProp({ panelId, field: 'filters.benchmark', value: value }))
              }
            />
            <FormField
              control={Input}
              width="8"
              name={'label'}
              label="Meta"
              placeholder={'Meta'}
              type={'number'}
              value={goal}
              onChange={(_: any, { value }: any) =>
                dispatch(setPanelProp({ panelId, field: 'filters.goal', value: value ? Number(value) : '' }))
              }
            />
          </Form.Group>
        </Form>
      )
    case 'donut':
    case 'bars':
      return <PanelStepFourDonut panelId={panelId} />
    default:
      return null
  }
}

export default PanelStepFour
