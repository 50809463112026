import React, { PureComponent } from 'react'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  Label,
} from 'recharts'
import { PieGraphSmall } from './PieGraphSmall.jsx'
import m from 'moment'
import dataPhases from '../Phases/phases_data.json'
import { Icon } from 'semantic-ui-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

// Mock com dados estatísticos das coletas do dia 24/08/22
const data = [
  {
    name: '24/08 Fiorino Shift A-B',
    dessaturação_turno_01: 20,
    fase_01_turno_01: 16,
    fase_02_turno_01: 23,
    fase_03_turno_01: 6,
    fase_04_turno_01: 23,
    fase_05_turno_01: 19,
    dessaturação_turno_02: 25,
    fase_01_turno_02: 23,
    fase_02_turno_02: 19,
    fase_03_turno_02: 5,
    fase_04_turno_02: 11,
    fase_05_turno_02: 16,
  },
  {
    name: '24/08 Single Pickup Shift A-B',
    dessaturação_turno_01: 23,
    fase_01_turno_01: 17,
    fase_02_turno_01: 22,
    fase_03_turno_01: 7,
    fase_04_turno_01: 23,
    fase_05_turno_01: 16,
    dessaturação_turno_02: 19,
    fase_01_turno_02: 23,
    fase_02_turno_02: 19,
    fase_03_turno_02: 5,
    fase_04_turno_02: 11,
    fase_05_turno_02: 16,
  },
  {
    name: '24/08 Mobi Shift A-B',
    dessaturação_turno_01: 21,
    fase_01_turno_01: 15,
    fase_02_turno_01: 21,
    fase_03_turno_01: 5,
    fase_04_turno_01: 15,
    fase_05_turno_01: 16,
    dessaturação_turno_02: 18,
    fase_01_turno_02: 23,
    fase_02_turno_02: 19,
    fase_03_turno_02: 5,
    fase_04_turno_02: 11,
    fase_05_turno_02: 16,
  },
  {
    name: '24/08 Double Pickup Shift A-B',
    dessaturação_turno_01: 20,
    fase_01_turno_01: 23,
    fase_02_turno_01: 19,
    fase_03_turno_01: 6,
    fase_04_turno_01: 13,
    fase_05_turno_01: 16,
    dessaturação_turno_02: 25,
    fase_01_turno_02: 17,
    fase_02_turno_02: 23,
    fase_03_turno_02: 6,
    fase_04_turno_02: 21,
    fase_05_turno_02: 20,
  },
]

class CardLineChart extends PureComponent {
  //static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';
  static emptyArray = []
  render() {
    const { filters, tgMin, color, name } = this.props.currentCard
    const { time } = this.props.filters
    const { pieDataAll, dashboard, selectedChart, charts, currentSelected, t } = this.props
    const selected = currentSelected || selectedChart
    const barChart = charts.data
    let dataByShift1 = dataPhases.filter((f) => [0].includes(f.shift) && filters.includes(f.phase)) || this.emptyArray
    let dataByShift2 = dataPhases.filter((f) => [1].includes(f.shift) && filters.includes(f.phase)) || this.emptyArray
    let dataByShift3 = dataPhases.filter((f) => [2].includes(f.shift) && filters.includes(f.phase)) || this.emptyArray
    let datas = { turno1: dataByShift1, turno2: dataByShift2, turno3: dataByShift3 }
    let turns = ['turno1', 'turno2', 'turno3']
    let offsets = { turno1: 0, turno2: 0, turno3: 0 }
    let maxs = { turno1: -Infinity, turno2: -Infinity, turno3: -Infinity }
    let mins = { turno1: Infinity, turno2: Infinity, turno3: Infinity }

    /*let dataByShift = (dataByShift1.length()>=dataByShift2.length()&&dataByShift1.length()>=dataByShift3.length())?
                      dataByShift1:(dataByShift2.length()>=dataByShift3.length())?dataByShift2:dataByShift3*/
    const dataA = dataByShift1.map((a, i) => {
      a.turno1 = Math.round(((a['duration'] - tgMin) / tgMin) * 100) /*10)/10*/
      a.turno2 = dataByShift2[i]
        ? Math.round(((dataByShift2[i].duration - tgMin) / tgMin) * 100) /**10)/10*/
        : undefined
      a.turno3 = dataByShift3[i]
        ? Math.round(((dataByShift3[i].duration - tgMin) / tgMin) * 100) /**10)/10*/
        : undefined
      turns.forEach((turno) => {
        if (a[turno]) {
          if (a[turno] > maxs[turno]) maxs[turno] = a[turno]
          if (a[turno] < mins[turno]) mins[turno] = a[turno]
        }
      })
      a.start = m(a.start).format('DDMMM HH:mm:ss')
      a.index = i
      return a
    })

    turns.forEach((turno) => {
      if (maxs[turno] <= 0) {
        offsets[turno] = 0
      } else if (mins[turno] >= 0) {
        offsets[turno] = 1
      } else offsets[turno] = maxs[turno] / (maxs[turno] - mins[turno])
    })

    /* const gradientOffset = (key) => {
         const dataMax = Math.max(...dataA.map((i) => i[key]));
         const dataMin = Math.min(...dataA.map((i) => i[key]));
       
         if (dataMax <= 0) {
           return 0;
         }
         if (dataMin >= 0) {
           return 1;
         }
       
         return (dataMax / (dataMax - dataMin))||0;
       };*/

    var barChartData =
      barChart?.length > 0
        ? barChart
            .filter((e) => e.label[0].duration < 50)
            .map((each) => {
              const ciclo = each.label[0].duration + each.label[1].duration
              var fill
              if (ciclo > 105.336) fill = '#c40233' //red
              if (ciclo > 100.548 && ciclo <= 105.336) fill = '#FFC30B' //yellow
              if (ciclo > 90.972 && ciclo <= 100.548) fill = 'green'
              if (ciclo > 86.211 && ciclo <= 90.972) fill = '#66afe9' // blue
              if (ciclo <= 86.211) fill = 'grey'
              return {
                name: m(each.datetime)
                  .format('DD/MM HH:mm')
                  .replace('14/05', '09/02')
                  .replace('12:', '13:')
                  .replace('11:', '12:')
                  .replace('28/07', '24/08'),
                dessaturação: each.label[0].duration,
                saturação: each.label[1].duration,
                fill,
                ciclo,
                'Fase 1': each.label['p1'].duration,
                'Fase 2': each.label['p2'].duration,
                'Fase 3': each.label['p3'].duration,
                'Fase 4': each.label['p4'].duration,
                'Fase 5': each.label['p5'].duration,
              }
            })
        : null

    return !name && dashboard !== 'Dashboard' ? (
      <span />
    ) : (
      <div style={{ width: '100%', height: '150px', display: 'block', marginTop: '30px' }}>
        <p style={{ marginLeft: '30px' }}>
          {name === 'Tempo Total da Amostra'
            ? t('insights_graph_sentence_1') + t('insights_graph_sentence_2')
            : t('insights_graph_sentence_1-2') + t('insights_graph_sentence_2')}{' '}
          "<span style={{ fontWeight: '600' }}>{t('Cycle')}</span>"
        </p>
        {name === 'Tempo Total da Amostra' ? (
          <PieGraphSmall
            colors={['red', 'purple', 'green', 'yellow', 'brown', 'orange']}
            height={450}
            width={450}
            data={pieDataAll}
          />
        ) : dashboard === 'Dashboard' ? (
          <div style={{ width: '100%', height: '96%' }}>
            {selected === 'ciclo' && (
              <ResponsiveContainer key={'rc01'} width="100%" height="150%" style={{ marginTop: '15px' }}>
                <BarChart width={730} height={250} data={barChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    allowDecimals={false}
                    label={{ angle: -90, position: 'insideLeft', value: t('insights_duration_s') }}
                  />
                  <Tooltip />
                  <Legend
                    verticalAlign="top"
                    wrapperStyle={{ lineHeight: '40px' }}
                    height={55}
                    width="101%"
                    payload={[
                      { value: t('insights_bar_chart_red'), type: 'bar', id: 'ID01', color: '#c40233' },
                      { value: t('insights_bar_chart_yellow'), type: 'bar', id: 'ID02', color: '#FFC30B' },
                      { value: t('insights_bar_chart_green'), type: 'bar', id: 'ID03', color: 'green' },
                      { value: t('insights_bar_chart_blue'), type: 'bar', id: 'ID04', color: '#66afe9' },
                      { value: t('insights_bar_chart_grey'), type: 'bar', id: 'ID05', color: 'grey' },
                    ]}
                  />
                  <Brush
                    dataKey="name"
                    height={30}
                    stroke="#4b72b2"
                    startIndex={barChartData?.length > 30 ? barChartData?.length - 30 : 0}
                  />
                  <Bar dataKey="ciclo" />
                  <ReferenceLine y={96} stroke="gray" strokeDasharray="5 8">
                    <Label value={t('insights_theoretical')} position="right" />
                  </ReferenceLine>
                </BarChart>
              </ResponsiveContainer>
            )}
            {selected === 'saturação' && (
              <ResponsiveContainer key={'rc02'} width="100%" height="150%" style={{ marginTop: '15px' }}>
                <BarChart width={730} height={250} data={barChartData?.map((e) => _.omit(e, 'fill'))}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    allowDecimals={false}
                    label={{ angle: -90, position: 'insideLeft', value: t('insights_duration_s') }}
                  />
                  <Tooltip />
                  <Legend
                    verticalAlign="top"
                    wrapperStyle={{ lineHeight: '40px' }}
                    height={55}
                    width="101%"
                    payload={[
                      { value: t('insights_saturation'), type: 'bar', id: 'ID01', color: '#66afe9' },
                      { value: t('insights_desaturation'), type: 'bar', id: 'ID02', color: '#c40233' },
                    ]}
                  />
                  <Brush
                    dataKey="name"
                    height={30}
                    stroke="#4b72b2"
                    startIndex={barChartData?.length > 30 ? barChartData?.length - 30 : 0}
                  />
                  <Bar dataKey="dessaturação" stackId="a" fill="#c40233" />
                  <Bar dataKey="Fase 1" stackId="a" fill="#66afe9" />
                  <Bar dataKey="Fase 2" stackId="a" fill="#66afe9" />
                  <Bar dataKey="Fase 3" stackId="a" fill="#66afe9" />
                  <Bar dataKey="Fase 4" stackId="a" fill="#66afe9" />
                  <Bar dataKey="Fase 5" stackId="a" fill="#66afe9" />
                  <ReferenceLine y={96} stroke="gray" strokeDasharray="5 8">
                    <Label value="Teórico" position="right" />
                  </ReferenceLine>
                </BarChart>
              </ResponsiveContainer>
            )}
            {selected === 'shift' && (
              <ResponsiveContainer width="100%" height="150%" style={{ marginTop: '15px' }}>
                <BarChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis unit="s" />
                  <Tooltip stype={{ zIndex: '-1000' }} />
                  <Legend
                    verticalAlign="top"
                    wrapperStyle={{ lineHeight: '40px' }}
                    height={55}
                    width="101%"
                    payload={[
                      { value: 'NVA', type: 'bar', id: 'ID01', color: '#c40233' },
                      { value: t('Fase 1'), type: 'bar', id: 'ID02', color: 'green' },
                      { value: t('Fase 2'), type: 'bar', id: 'ID03', color: '#FFC30B' },
                      { value: t('Fase 3'), type: 'bar', id: 'ID04', color: '#a5673f' },
                      { value: t('Fase 4'), type: 'bar', id: 'ID05', color: '#f2711c' },
                      { value: t('Fase 5'), type: 'bar', id: 'ID05', color: '#2185d0' },
                    ]}
                  />
                  <Bar dataKey="dessaturação_turno_01" stackId="1" fill="#c40233" />
                  <Bar dataKey="fase_01_turno_01" stackId="1" fill="green" />
                  <Bar dataKey="fase_02_turno_01" stackId="1" fill="#FFC30B" />
                  <Bar dataKey="fase_03_turno_01" stackId="1" fill="#a5673f" />
                  <Bar dataKey="fase_04_turno_01" stackId="1" fill="#f2711c" />
                  <Bar dataKey="fase_05_turno_01" stackId="1" fill="#2185d0" />
                  <Bar dataKey="dessaturação_turno_02" stackId="2" fill="#c40233" />
                  <Bar dataKey="fase_01_turno_02" stackId="2" fill="green" />
                  <Bar dataKey="fase_02_turno_02" stackId="2" fill="#FFC30B" />
                  <Bar dataKey="fase_03_turno_02" stackId="2" fill="#a5673f" />
                  <Bar dataKey="fase_04_turno_02" stackId="2" fill="#f2711c" />
                  <Bar dataKey="fase_05_turno_02" stackId="2" fill="#2185d0" />
                  {/* <Bar dataKey="pv" stackId="a" fill="#8884d8" />
                  <Bar dataKey="amt" stackId="a" fill="#82ca9d" />
                  <Bar dataKey="uv" fill="#ffc658" /> */}
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        ) : (
          time.map((turno) => (
            <ResponsiveContainer key={'rc'} width="100%" height="100%" style={{ marginTop: '15px' }}>
              <AreaChart
                width={500}
                height={400}
                data={dataA}
                syncId="anyId"
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="index" />
                <YAxis allowDecimals={false} />
                <Tooltip
                  content={(value) => (
                    <div style={{ backgroundColor: 'white' }}>
                      <p>
                        <Icon name="percent" size="small" color={color} />:{dataA[value.label]?.[turno]}%
                      </p>
                      <p>
                        <Icon name="clock" size="small" color={color} />:
                        {m(datas[turno][value.label]?.start).format('DDMMM HH:mm:ss')}
                      </p>
                    </div>
                  )}
                />
                <Legend verticalAlign="top" height={36} />
                <defs>
                  <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={offsets[turno]} stopColor="red" stopOpacity={1} />
                    <stop offset={offsets[turno]} stopColor="green" stopOpacity={1} />
                  </linearGradient>
                </defs>
                <Area type="monotone" dataKey={turno} stroke={color} fill="url(#splitColor)" />
              </AreaChart>
            </ResponsiveContainer>
          ))
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedChart: state.dashboardStellantis.charts?.selected,
  charts: state.dashboardStellantis.charts,
})

export default withTranslation()(connect(mapStateToProps)(CardLineChart))
