import L from 'leaflet'
import React from 'react'
import { MapLayer, withLeaflet, LeafletProvider } from 'react-leaflet'
import 'leaflet.smooth_marker_bouncing'
/**
 * Add any custom behavious to react leaflet marker
 * Bounce: https://github.com/hosuaby/Leaflet.SmoothMarkerBouncing
 */
class Marker extends MapLayer {
  createLeafletElement(props) {
    const options = this.getOptions(props)
    const el = new L.marker(props.position, options)
    if (options.bounce) {
      el.toggleBouncing()
    }
    this.contextValue = { ...props.leaflet, popupContainer: el }
    return el
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.position !== fromProps.position) {
      try {
        this.leafletElement.setLatLng(toProps.position)
      } catch (err) {}
    }
    if (toProps.bounce !== fromProps.bounce) {
      try {
        this.leafletElement.toggleBouncing()
      } catch (err) {
        console.error('Error ao iniciar o bounce')
      }
    }
    if (toProps.icon !== fromProps.icon) {
      try {
        this.leafletElement.setIcon(toProps.icon)
      } catch (err) {}
    }
    if (toProps.zIndexOffset !== fromProps.zIndexOffset) {
      this.leafletElement.setZIndexOffset(toProps.zIndexOffset)
    }
    if (toProps.opacity !== fromProps.opacity) {
      this.leafletElement.setOpacity(toProps.opacity)
    }
    if (toProps.draggable !== fromProps.draggable) {
      if (toProps.draggable === true) {
        this.leafletElement.dragging.enable()
      } else {
        this.leafletElement.dragging.disable()
      }
    }
  }

  render() {
    const { children } = this.props
    return children == null || this.contextValue == null ? null : (
      <LeafletProvider value={this.contextValue}>{children}</LeafletProvider>
    )
  }
}

export default withLeaflet(Marker)
