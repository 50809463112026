import React from 'react'
import moment from 'moment'
import { Dropdown, Icon, Label, List } from 'semantic-ui-react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { calcWipCycles, removeDataLabeling, setDataLabelingsProp } from 'Store/actions/dataLabelings-actions'
import { COMPLEMENTARY_CHART_COLORS } from 'Utils/complementaryColors'
import { Phase } from 'API'
// import { notifyInfo } from 'Utils/components/SystemToasts'

interface Props {
  phases?: (Phase | null)[]
}

const StyledListItem = styled(List.Item)`
  font-style: italic;
  font-size: 13px;
`

const StyledLabel = styled(Label)`
  background-color: ${(props) => COMPLEMENTARY_CHART_COLORS[props.phases?.[props.value]?.color]} !important;
  border-color: ${(props) => COMPLEMENTARY_CHART_COLORS[props.phases?.[props.value]?.color]} !important;
  color: #fff !important;
`

const StyledInput = styled.input`
  width: 86px;
  height: 25px;
  padding-right: 0px !important;
`

const VEHICLES = ['Argo', 'Fastback', 'Fiorino/Partner', 'Mobi', 'Pulse', 'Strada CD', 'Strada CS']

const vehicleOptions = VEHICLES.map((vehicle) => ({
  key: vehicle,
  text: vehicle,
  value: vehicle,
}))

const DataLabelingsSideTableColumns = ({ phases }: Props) => {
  const dispatch = useDispatch()

  const CellInput = (value = '', id: string, timeIndex = 'startTime', sideTime: string) => {
    let valueTime = value || sideTime
    return (
      <StyledInput
        value={moment(valueTime).format('HH:mm:ss')}
        type="time"
        step="1" // Adiciona suporte a segundos no input do tipo 'time'
        onChange={({ target: { value: newValue = '' } }) => {
          const timeParts = newValue.split(':')
          const seconds = timeParts.length === 3 ? parseInt(timeParts[2], 10) : 0

          const newValueISOString = moment(valueTime)
            .set({
              hour: parseInt(timeParts[0], 10),
              minute: parseInt(timeParts[1], 10),
              second: seconds,
            })
            .toISOString()

          dispatch(setDataLabelingsProp(`wip.labelings[${id}].${timeIndex}`, newValueISOString))
        }}
      />
    )
  }

  return [
    {
      Header: 'Ciclo',
      accessor: 'cycle',
      Cell: ({ value = 0, row: { original: { labelIndex = 0 } = {} } = { original: { labelIndex: 0 } } }) => (
        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{value}</div>
      ),
      aggregate: 'uniqueCount',
      Aggregated: ({ value }: { value: string }) => `${value}`,
    },
    {
      Header: 'Veículo',
      accessor: 'vehicle',
      Cell: ({ value = 0, row: { original: { labelIndex = 0 } = {} } = { original: { labelIndex: 0 } } }) => {
        return (
          <Dropdown
            options={vehicleOptions}
            onChange={(e, { value }) => {
              dispatch(setDataLabelingsProp(`wip.labelings[${labelIndex}].vehicle`, value))
              dispatch(calcWipCycles())
            }}
            trigger={value || 'Veículo'}
          />
        )
      },
      aggregate: 'uniqueCount',
      Aggregated: ({ value }: { value: string }) => `${value}`,
    },
    {
      Header: 'Fase',
      accessor: 'phaseIndex',
      Cell: ({ value = 0, row: { original: { labelIndex = 0 } = {} } = { original: { labelIndex: 0 } } }) => {
        return (
          <Dropdown
            floating
            trigger={
              <StyledLabel circular phases={phases} value={value}>
                {value + 1}
              </StyledLabel>
            }
            title={phases?.[value]?.name}
          >
            <Dropdown.Menu>
              {phases?.map((phase: Phase | null, index) => {
                return (
                  <Dropdown.Item
                    value={index}
                    key={index}
                    onClick={() => {
                      dispatch(setDataLabelingsProp(`wip.labelings[${labelIndex}].phaseIndex`, index))
                      dispatch(calcWipCycles())
                    }}
                  >
                    <StyledLabel circular phases={phases} value={index} size="tiny" title={phase?.name}>
                      {index + 1}
                    </StyledLabel>
                    {phase?.name}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        )
      },
      aggregate: 'uniqueCount',
      Aggregated: ({ value }: { value: string }) => `${value}`,
    },

    {
      Header: 'Início',
      accessor: 'startTime',
      Cell: ({
        value = '',
        row: {
          original: { endTime = '', labelIndex = '' },
        },
      }) => {
        return CellInput(value, labelIndex, 'startTime', endTime)
      },
      disableGroupBy: true,
      aggregate: 'min',
      Aggregated: ({ value }: { value: string }) => `min: ${value ? moment(value).format('HH:mm:ss') : ''}`,
    },
    {
      Header: 'Fim',
      accessor: 'endTime',
      Cell: ({
        value = '',
        row: {
          original: { startTime = '', labelIndex = '' },
        },
      }) => {
        return CellInput(value, labelIndex, 'endTime', startTime)
      },
      disableGroupBy: true,
      aggregate: (values: string[]) => {
        return values.sort()[values.length - 1]
      },
      Aggregated: ({ value }: { value: string }) => `max: ${value ? moment(value).format('HH:mm:ss') : ''}`,
    },
    {
      Header: 'Duração(s)',
      accessor: (rowData: { startTime?: string; endTime?: string }) => {
        if (!rowData.startTime || !rowData.endTime) return ''
        const startTime = new Date(rowData.startTime).getTime()
        const endTime = new Date(rowData.endTime).getTime()
        const roundTime = Math.round((endTime - startTime) / 1000)
        return roundTime
      },
      Cell: ({
        value = '',
        row: {
          original: { startTime = '' },
        },
      }) => (
        <div
          style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}
          onClick={() => dispatch(setDataLabelingsProp('currentVideoTime', startTime))}
        >
          {value}{' '}
        </div>
      ),
      disableGroupBy: true,
      aggregate: (values: number[]) => {
        const findRepeatedValue = (arr: number[], value: number) => arr.filter((v) => v === value).length >= 2

        const uniqueValues = [...new Set(values)]
        const minValue = uniqueValues.find((v) => findRepeatedValue(values, v))
        const maxValue = [...uniqueValues].reverse().find((v) => findRepeatedValue(values, v))

        const total = values.reduce((sum, value) => sum + value, 0)

        return {
          min: minValue || Math.min(...values),
          max: maxValue || Math.max(...values),
          avg: total / values.length,
          total,
        }
      },

      Aggregated: ({ value, ...rest }: { value: any }) => {
        return (
          <List>
            <StyledListItem>Min(2): {value.min}</StyledListItem>
            <StyledListItem>Média: {value.avg.toFixed(1)}</StyledListItem>
            <StyledListItem>Max(2): {value.max}</StyledListItem>
            <StyledListItem>Soma: {value.total}</StyledListItem>
          </List>
        )
      },
    },
    {
      Header: 'Ação',
      Cell: ({ row: { original: { labelIndex = 0 } = {} } = { original: { labelIndex: 0 } } }) => {
        return (
          <Icon
            title="Clique para excluir"
            name="trash alternate"
            onClick={() => dispatch(removeDataLabeling({ index: labelIndex }))}
            style={{ cursor: 'pointer' }}
          />
        )
      },
      align: 'right',
    },
  ]
}

export default DataLabelingsSideTableColumns
