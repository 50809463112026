import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { colorFormat } from 'Utils/colorsFormat'
import getSafe from 'Utils/getSafe.js'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { saveColumnsPreference } from 'Store/actions/login-action'
import useFetchData from 'Utils/hooks/useFetchData'
import { lastSeenColumn } from 'Utils/lastSeenColumn'
import { fetchDevices } from 'Store/actions/devices-action'
import { subscribeAssetLocationChange } from 'Store/actions/measures-action'

export const DevicesContent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useFetchData({
    independentFetches: [{ fetchAction: 'devices' }],
  })

  useEffect(() => {
    const sub = dispatch(subscribeAssetLocationChange())
    return () => {
      sub.unsubscribe()
    }
  }, [dispatch])

  const fetchAction = () => {
    dispatch(fetchDevices())
  }

  const tableName = 'devices'
  const devices = useObjSelector((state) => state.devices)

  const groupedValuesColumns = useObjSelector(
    (state) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const loading = useSelector((state) => state.devices.loading)

  const [currentGroup, setCurrentGroup] = useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = React.useMemo(() => {
    /* const updateAction = (params) => dispatch(updateDevice(params))*/

    return [
      {
        Header: t('uniqueID'),
        accessor: 'id',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ${t('ids')}`,
      },
      {
        Header: t('Device'),
        accessor: 'name',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ${t('devices')}`,
      },
      {
        Header: t('Serial Number'),
        accessor: 'serial_number',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} SN's`,
      },
      {
        Header: t('Description'),
        accessor: 'info.description',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ${value === 1 ? t('Description') : t('Descriptions')}`,
      },
      {
        Header: t('Model'),
        accessor: 'model',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('models')}`,
      },
      lastSeenColumn(t),
      {
        Header: t('Assets'),
        accessor: 'assets',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('assets')}`,
      },
      {
        Header: t('Destiny'),
        accessor: 'info.destino',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('destinations')}`,
      },
      {
        Header: t('ExitDate'),
        accessor: 'info.saida',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('days')}`,
      },
      {
        Header: t('Mode'),
        accessor: 'info.modo',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('modes')}`,
      },
      {
        Header: t('Connectivity'),
        accessor: 'info.connectivity',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('modes')}`,
      },
      {
        Header: t('Devaddr'),
        accessor: 'info.devaddr',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('keys')}`,
      },
      {
        Header: t('Rate'),
        accessor: 'info.recorrencia',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('rates')}`,
      },
      {
        Header: t('FwVersion'),
        accessor: 'info.fwVersion',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('versions')}`,
      },
      {
        Header: t('Battery'),
        accessor: 'info.battery.lastBatLevel',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('macs')}`,
        Cell: ({ value }) => {
          let color = 'green'
          if (getSafe(() => value.split('%')[0], false) < 20) color = 'red'
          return <span style={{ color: colorFormat(color) }}>{value}</span>
        },
      },
      {
        Header: t('RSSI'),
        accessor: 'info.status.rssi',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('rssis')}`,
      },
      {
        Header: t('SNR'),
        accessor: 'info.status.snr',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('snrs')}`,
      },
      {
        Header: t('Mackey'),
        accessor: 'mackey',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('keys')}`,
      },
      {
        Header: t('Mackeyble'),
        accessor: 'mackeyble',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('keys')}`,
      },
      {
        Header: t('DevEUI LoRa'),
        accessor: 'info.deveui',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('keys')}`,
      },
      {
        Header: t('Manager'),
        accessor: 'info.responsavel',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('managers')}`,
      },
      {
        Header: t('comments'),
        accessor: 'comments',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('keys')}`,
      },
      {
        Header: t('pcbNumber'),
        accessor: 'pcb_number',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} PCB's`,
      },
      {
        Header: 'Appeui',
        accessor: 'info.appeui',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Appeui's`,
      },
      {
        Header: 'Appkey',
        accessor: 'info.appkey',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Appkey's`,
      },
      {
        Header: 'Appskey',
        accessor: 'info.appskey',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Appskey's`,
      },
      {
        Header: 'Netwskey',
        accessor: 'info.netwskey',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Netwskey's`,
      },
      {
        Header: 'UUID',
        accessor: 'uuid',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} UUID's`,
      },
      {
        Header: t('createdAt'),
        accessor: 'createdAt',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'horários'}`,
        Cell: ({ value }) => <span title={m(value).format('lll')}>{m(value).fromNow()}</span>,
      },
    ]
  }, [t])

  const data = React.useMemo(() => {
    return devices.allIds?.reduce((acc, id) => {
      const row = devices?.byId[id]
      if (row) {
        acc.push({ ...row, id })
      }
      return acc
    }, [])
  }, [devices])

  return (
    <ReactTable
      table={tableName}
      // hiddenColumns={hiddenColumns}
      // hiddenButtons={hiddenButtons}
      columns={columns}
      data={data}
      showPlaceholders={loading}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      initialSortBy={{ desc: false, id: 'name' }}
      fetchAction={fetchAction}
    />
  )
}
