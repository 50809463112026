import React from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { get } from 'lodash'
import DropdownField, { DropdownFieldProps } from './DropdownField'

interface GenericDropdownFieldProps extends DropdownFieldProps {
  entity: string
}

const DropdownStoreField: React.FC<GenericDropdownFieldProps> = ({ value, ...fields }) => {
  const storeValue = useSelector((state: RootStore) => {
    if (fields.entity) {
      return get(state, `${fields.entity}.wip.${value}`) ?? get(state, `${fields.entity}.current.${value}`)
    } else return get(state, `${value}`)
  })

  return <DropdownField value={storeValue} {...fields} />
}

export default DropdownStoreField
