import React from 'react'
import { Grid, Button, Icon, Segment, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useSignOut } from 'Utils/hooks/useLogout'

export const NotAuthorized = () => {
  const handleLogout = useSignOut()

  return (
    <Grid textAlign="center" style={{ height: '100vh', background: '#f0f2f5', padding: '20px' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment padded="very" raised>
          <Header as="h2" icon textAlign="center">
            <Icon name="lock" circular />
            <Header.Content>Acesso Negado</Header.Content>
          </Header>
          <p>Este usuário não tem permissão suficiente para acessar este aplicativo.</p>
          <Button primary fluid size="large" as={Link} to="/login" onClick={handleLogout} style={{ marginTop: '20px' }}>
            Sair
          </Button>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}
