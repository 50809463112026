import React from 'react'
import { PieChart, Pie, Cell } from 'recharts'

export const PieGraphSmall = ({ data, color = 'blue', colors, width = 80, height = 80 }) => {
  const dataUsed = data || [
    { name: 'Group A', value: 7.2 },
    { name: 'Group B', value: 6.1 },
    { name: 'Group C', value: 5.4 },
    { name: 'Group D', value: 7.6 },
    { name: 'Group E', value: 6.0 },
    { name: 'Group F', value: 3.8 },
    { name: 'Group G', value: 1.5 },
  ]

  const COLORS = colors ? colors : ['#4C5762', color]

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: 'xx-small' }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  return (
    <PieChart nameKey="name" width={width} height={height} style={{ marginLeft: '0' }}>
      <Pie
        data={dataUsed}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={height / 2}
        fill="#8884d8"
        dataKey="value"
        nameKey="name"
        minAngle={15}
      >
        {dataUsed.map((entry, index) => {
          return <Cell key={`cell-${index}`} fill={entry.color ? entry.color : COLORS[index % COLORS.length]} />
        })}
      </Pie>
    </PieChart>
  )
}
