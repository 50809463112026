import React, { useMemo } from 'react'
import StepForm from '../StepForm'
import { useDispatch, useSelector } from 'react-redux'
import { clearReportForm, mutateReports, setReportsProp, setReportsProps } from 'Store/actions/reports-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { RootStore } from 'Store/initialStore'
import { useHistory } from 'react-router-dom'
import createStepOneSchema from './schemas/stepId'
import { Checkbox, Form, Popup } from 'semantic-ui-react'
import InputStoreField from '../generic/InputStoreField'
import TextAreaStoreField from '../generic/TextAreaStoreField'

function ReportsIdForm() {
  const dispatch = useDispatch()
  const history = useHistory()

  const step = useObjSelector((state) => state.reports.form.steps?.items[0])
  const errors = useObjSelector((state) => state.reports.form.errors?.[0])
  const currentId = useSelector((state: RootStore) => state.reports.current?.id)
  const code = useSelector((state: RootStore) => state.reports.wip?.code)
  const access = useSelector((state: RootStore) => state.reports.wip?.access ?? state.reports.current?.access)
  const createdBy = useSelector((state: RootStore) => state.reports.current?.createdBy)
  const login = useSelector((state: RootStore) => state.login.email)

  const reportsById = useObjSelector((state) => state.reports.byId)

  const uniqueCodes = useMemo(() => {
    return [...new Set(Object.values(reportsById).map((report) => report.code))]
  }, [reportsById])

  const uniqueLabels = useMemo(() => {
    return [...new Set(Object.values(reportsById).map((report) => report.label))]
  }, [reportsById])

  const stepOneSchema = createStepOneSchema(uniqueCodes, uniqueLabels, !!currentId)

  return (
    <StepForm
      entity="reports"
      schemas={[stepOneSchema]}
      forms={[
        <>
          <Form.Group widths={16}>
            <InputStoreField
              width={6}
              entity="reports"
              label="Código"
              name="code"
              placeholder="Digite o Código de identificação"
              value={'code'}
              onChange={(_, { name, value }) => dispatch(setReportsProp(`wip.${name}`, value))}
              required={true}
              showFeedback={step.showFeedback}
              error={errors?.code}
              maxLength={8}
              disabled={!!currentId}
            />
            <InputStoreField
              width={10}
              entity="reports"
              label="Nome"
              name="label"
              placeholder="Nomeie o relatório"
              value={'label'}
              onChange={(_, { name, value }) => dispatch(setReportsProp(`wip.${name}`, value))}
              required={true}
              showFeedback={step.showFeedback}
              error={errors?.label}
            />
          </Form.Group>
          <br />
          <TextAreaStoreField
            entity="reports"
            label="Descrição"
            name="description"
            placeholder="Descreva o relatório"
            value={'description'}
            onChange={(_, { name, value }) => dispatch(setReportsProp(`wip.${name}`, value))}
          />
          <br />
          {!createdBy ||
            (login === createdBy && (
              <Popup
                trigger={
                  <Checkbox
                    label="Disponível para todos usuários da organização"
                    onChange={(_, { checked }) => {
                      dispatch(setReportsProp(`wip.access`, checked ? 'PUBLIC' : 'PRIVATE'))
                    }}
                    checked={access === 'PUBLIC'}
                  />
                }
                content="Ao marcar essa opção todos os usuários com permissão de acesso poderão visualizar esse relatório."
                inverted
                position="left center"
              />
            ))}
        </>,
      ]}
      setProps={setReportsProps}
      values={[{ fields: ['scope'] }, {}]}
      onSave={() => {
        dispatch(mutateReports())
        dispatch(setReportsProp(`form.open`, false))
        !currentId && history.push(`/app/betaReports/${code}`)
      }}
      onClose={() => {
        dispatch(clearReportForm())
        dispatch(setReportsProp(`form.isEditing`, false))
      }}
      fixedHeight="70vh"
      saveContent="Salvar"
    />
  )
}

export default ReportsIdForm
