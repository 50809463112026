import React, { memo, useEffect, useState } from 'react'
import { Checkbox } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { InputTemplate } from 'Utils/components/InputTemplate.js'
import { updatePerson, insertPeople, deletePeople } from 'Store/actions/people-action'
import { fetchAssets, saveGroup, deleteGroup } from 'Store/actions/assets-action'
import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { twoColumns } from 'Utils/twoColumns'
import { SvgIcon } from 'Utils/components/SvgIcon.jsx'
import { useCanAccess } from 'Utils/rbac'
//import ConsentButton from 'Utils/components/ConsentButton'
import { showConfirm, hideConfirm } from 'Store/actions/system-action'
import { saveColumnsPreference } from 'Store/actions/login-action'
import useFetchData from 'Utils/hooks/useFetchData'
import SendTokenEmail from 'Components/SidebarMenu/Contents/People/SendTokenEmail'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { lastSeenColumn } from 'Utils/lastSeenColumn'
import ImageUpload from 'Components/ImageUpload'

//import { RootStore } from 'Store/initialStore'

/* interface PeopleContentProps {
  assetsAllIdsStr: string
  assetsByIdStr: string
  assetsMeasuresStr: string
} */

const columnsDefault = ['icon', 'id', 'name', 'type', 'info.cardId', 'status', 'token']

// const hiddenButtons = ['rules', 'import', 'fullscreen', 'toggle fullscreen', 'calendar']

const PeopleContent = memo(
  () => {
    const [loading, setLoading] = useState(true)
    useFetchData({
      independentFetches: [
        {
          fetchAction: 'rules',
        },
        {
          fetchAction: 'profiles',
        },
        {
          fetchAction: 'tenants',
        },
      ],
      dependentFetches: [
        {
          fetchAction: 'assets',
        },
        {
          fetchAction: 'measures',
        },
      ],
      finishAction: () => setLoading(false),
    })

    const table = 'people'
    const assetsAllIds = useObjSelector((state) => state.assets.allIds)
    const assetsById = useObjSelector((state) => state.assets.byId)
    const tenantColumns = useObjSelector((state) => state.tenants.current.info?.routes?.people?.columns)
    const cudLoading = useSelector((state) => state.people.cudLoading)
    const cudSuccess = useSelector((state) => state.people.cudSuccess)
    const tenant = useSelector((state) => state.login.empresa)
    const { t } = useTranslation()
    const canAccess = useCanAccess('WRITE')

    const assetTypes = useObjSelector((state) => state.profiles)

    const types = React.useMemo(
      () =>
        assetTypes.allIds
          .filter((tp) => assetTypes.byId[tp].type === 'people')
          .map((t) => ({
            key: t,
            value: t,
            text: assetTypes.byId[t].name,
            color: assetTypes.byId[t].color,
            iconsvg: assetTypes.byId[t].icon,
          })),
      [assetTypes]
    )

    const typeName = React.useCallback(
      (id) => {
        return assetTypes?.byId?.[id]?.name || ' '
      },
      [assetTypes]
    )
    const rules = useObjSelector((state) => state.rules)
    const dispatch = useDispatch()

    const fetchAction = React.useCallback(async () => {
      setLoading(true)
      await dispatch(fetchAssets())
      setLoading(false)
    }, [dispatch])
    const insertAction = React.useCallback(
      (body) => {
        dispatch(insertPeople(body))
      },
      [dispatch]
    )
    const updateAction = React.useCallback(
      (id, changes) => {
        dispatch(updatePerson(id, changes))
      },
      [dispatch]
    )
    const deleteAction = React.useCallback(
      (ids /* : string */) => {
        dispatch(deletePeople(ids.ids))
      },
      [dispatch]
    )
    const groupAction = React.useCallback((g, s, a) => dispatch(saveGroup(g, s, a)), [dispatch])
    const deleteGroupAction = React.useCallback((ids) => deleteGroup(ids), [])
    // let hiddenColumns = ['id', 'location', 'info.matricula', 'info.createdBy', 'info.createdAt']
    const hiddenColumns = useObjSelector((state) => state.login.preferences.routesPanels.byId[table].hiddenColumns)

    const groupedValuesColumns = useObjSelector(
      (state) => state.login.preferences.routesPanels.byId[table].groupedValuesColumns
    )

    const measuresById = useObjSelector((state) => state.measures.byId)

    const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
    useEffect(() => {
      dispatch(saveColumnsPreference(table, false, currentGroup))
    }, [currentGroup, dispatch])

    const noType = '<<' + t('noAssignment') + '>>'

    const columns = React.useMemo(() => {
      const typeColor = (id) => types.filter((f) => f.value === id)[0]?.color
      const typeIcon = (id) => types.filter((f) => f.value === id)[0]?.iconsvg || 'worker'
      const allColumnsAvailable = [
        {
          Header: t('Photo'),
          accessor: 'icon',
          align: 'center',
          aggregate: 'uniqueCount',
          disableGlobalFilter: true,
          Cell: (props) => {
            if (!props.row.original?.id) return null
            return <ImageUpload imagePath={`${tenant}/images/avatar/${props.row.original.id}.png`} />
          },
        },
        {
          Header: t('uniqueID'),
          accessor: 'id',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} id`,
          disableGlobalFilter: true,
        },
        {
          Header: t('Code'),
          accessor: 'info.uniqueID',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} code`,
        },
        {
          Header: 'NFC',
          accessor: 'info.cardId',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} nfc`,
        },
        {
          Header: t('Name'),
          accessor: 'name',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} nomes`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="name"
              table="people"
            />
          ),
        },
        {
          Header: t('Assignment'),
          accessor: 'type',
          align: 'center',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} ${value === 1 ? t('Assignment') : t('Assignments')}`,
          Cell: (props) => {
            return (
              <>
                <SvgIcon
                  title={'Clique no nome para editá-lo'}
                  color={typeColor(props.value)}
                  name={typeIcon(props.value)}
                  compact
                />
                <br />
                <InputTemplate
                  options={types}
                  value={props.value || noType}
                  id={props?.cell?.row?.original?.id}
                  action={(id, field, value) => {
                    dispatch(updatePerson(id, { [field]: value }))
                  }}
                  field="type"
                  table={table}
                />
              </>
            )
          },
        },
        {
          Header: t('Assignment'),
          accessor: 'typetext',
          align: 'left',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} ${value === 1 ? t('Assignment') : t('Assignments')}`,
        },
        lastSeenColumn(t),
        {
          Header: t('Groups'),
          accessor: 'groupsText',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} grupos`,
        },
        {
          Header: t('Rules'),
          accessor: 'rulesText',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} ${'regras'}`,
        },
        {
          Header: t('Master'),
          accessor: 'info.master',
          align: 'center',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value}`,
          Cell: ({ value }) => <Checkbox checked={value} disabled />,
        },
        {
          Header: t('Sector'),
          accessor: 'info.sector',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} setores`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.sector"
              table={table}
            />
          ),
        },
        {
          Header: t('info.position'),
          accessor: 'info.position',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} cargos`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.position"
              table={table}
            />
          ),
        },
        {
          Header: t('RegistrationId'),
          accessor: 'info.registrationId',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} matrículas`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.resgistrationId"
              table={table}
              type={'number'}
            />
          ),
        },
        {
          Header: t('info.email'),
          accessor: 'info.email',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} e-mails`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.email"
              table={table}
            />
          ),
        },
        {
          Header: t('info.supervisor'),
          accessor: 'info.supervisor',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} e-mails`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.supervisor"
              table={table}
            />
          ),
        },
        {
          Header: t('info.company'),
          accessor: 'info.company',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} empresas`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.company"
              table={table}
            />
          ),
        },
        {
          Header: t('info.onBoarding'),
          accessor: 'info.onBoarding',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} Ambientações`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.onBoarding"
              table={table}
            />
          ),
        },
        {
          Header: t('info.certifications'),
          accessor: 'info.certifications',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} empresas`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.certifications"
              table={table}
            />
          ),
        },
        {
          Header: t('Description'),
          accessor: 'info.description',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} descrições`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.description"
              table={table}
            />
          ),
        },
        {
          Header: t('info.consentAcceptedAt'),
          accessor: 'info.consentAcceptedAt',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} consentimentos`,
          Cell: ({ value }) => (
            <span title={value ? m(value).format('DD/MM/YY HH:mm:ss') : 'Aguardando aprovação'}>
              {value ? m(value).format('DD/MM/YY') : 'Aguardando'}
            </span>
          ),
        },
        {
          Header: t('info.consentAcceptedAt'),
          accessor: 'consentAcceptedAt',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} consentimentos`,
        },
        {
          Header: t('info.consentStatus'),
          accessor: 'info.consentStatus',
          align: 'center',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} formas de consentimento`,
          Cell: ({ value }) => value || 'Pendente',
        },
        {
          Header: t('peopleTable.header.sendTokenEmail'),
          accessor: 'token',
          align: 'center',
          aggregate: 'uniqueCount',
          Aggregated: () => `-`,
          Cell: ({ cell }) => <SendTokenEmail assetId={cell.row.original.id} email={cell.row.original.info.email} />,
        },
        {
          Header: t('consent'),
          accessor: 'status',
          align: 'center',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} ${t('status')}`,
          Cell: ({ value, cell }) => {
            const handleClick = () => {
              let now = new Date()
              let data = !value
                ? {
                    content: (
                      <div style={{ height: 'auto', fontSize: '1.5em', padding: '10px' }}>
                        <p>
                          {t('consentTerms.uponAcceptance') + ' ' + t('consentTerms.dataAceptance') + ' '}
                          <a href="https://phygitall.com.br/privacidade/" rel="noopener noreferrer" target="_blank">
                            {t('consentTerms.termOfConsent')}
                          </a>
                          {' ' +
                            t('consentTerms.dataCollectionAuthorization') +
                            ' ' +
                            t('consentTerms.dataCollectionStart')}
                        </p>
                        <p>{t('consentTerms.wishToProceed')}</p>
                      </div>
                    ),
                    onConfirm: () => {
                      dispatch(
                        updatePerson(cell?.row?.original?.id, {
                          consentAcceptedAt: now.toISOString(),
                          consentStatus: 'Via plataforma',
                        })
                      )
                      dispatch(hideConfirm())
                    },
                    onCancel: () => dispatch(hideConfirm()),
                    confirmHeader: 'Aceitar Manualmente o Termo de Consentimento de Coleta de Dados?',
                  }
                : {
                    content: (
                      <div style={{ height: 'auto', fontSize: '1.5em', padding: '5px' }}>
                        <p>
                          {t('consentTerms.uponAcceptance') + ' ' + t('consentTerms.dataRevocation') + ' '}
                          <a href="https://phygitall.com.br/privacidade/" rel="noopener noreferrer" target="_blank">
                            {t('consentTerms.termOfConsent')}
                          </a>
                          {' ' +
                            t('consentTerms.dataCollectionAuthorization') +
                            ' ' +
                            t('consentTerms.dataCollectionCease') +
                            ' '}
                        </p>
                        <p>{t('consentTerms.wishToProceed')}</p>
                      </div>
                    ),
                    onConfirm: () => {
                      dispatch(
                        updatePerson(cell?.row?.original?.id, {
                          consentAcceptedAt: now.toISOString(),
                          consentStatus: 'Revogado Manual',
                        })
                      )
                      dispatch(hideConfirm())
                    },
                    onCancel: () => dispatch(hideConfirm()),
                    confirmHeader: t('consentTerms.revokeTitle'),
                  }
              dispatch(showConfirm(data))
            }
            return (
              <Checkbox
                toggle
                onClick={handleClick}
                checked={value ? true : false}
                title={
                  !canAccess
                    ? 'Status atual da pessoa quanto ao envio de dados.'
                    : value
                    ? 'Clique para revogar manualmente o envio dos dados.'
                    : 'Clique aceitar manualmente o envio dos dados.'
                }
                disabled={!canAccess}
              />
            )
          },
        },
        {
          Header: t('info.sexo'),
          accessor: 'info.sexo',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} sexos`,
        },
        {
          Header: t('info.raça'),
          accessor: 'info.raça',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} raças`,
        },
        {
          Header: t('info.idade'),
          accessor: 'info.idade',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} idades`,
        },
        {
          Header: t('info.rendaFamiliar'),
          accessor: 'info.rendaFamiliar',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} rendas`,
        },
        {
          Header: t('info.escolaridade'),
          accessor: 'info.escolaridade',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} escolaridades`,
        },
        {
          Header: t('info.automovel'),
          accessor: 'info.automovel',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} automóveis`,
        },
        {
          Header: t('info.residencia'),
          accessor: 'info.residencia',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} residencias`,
        },
        ...twoColumns(t, true, canAccess),
      ]
      return allColumnsAvailable.filter((c) =>
        ['id', 'name', 'lastSeen', ...(tenantColumns || columnsDefault)].includes(c.accessor)
      )
    }, [t, dispatch, types, noType, canAccess, tenantColumns, tenant])

    const rows = assetsAllIds.filter((id) => assetsById[id]?.idType === 2 && assetsById[id]?.auto === false)
    const checkRule = (targetType, targetId, groups = [], id) => {
      if (targetId === '<any>') return true
      if (targetType === 'fenceGroup') return groups.includes(targetId)
      return targetId === id.toString()
    }
    const data = React.useMemo(
      () =>
        rows.map((id) => {
          let asset = assetsById[id]
          let rulesText = rules.allIds
            ?.filter((f) => checkRule(rules?.byId?.[f]?.sourceType, rules?.byId?.[f]?.sourceId, asset?.groups, id))
            ?.map((m) => (rules.byId[m].id.includes('#') ? rules.byId[m].id.split('#')[1] : rules.byId[m].id))
            ?.sort()
            ?.join(', ')
          let typeColor = types?.filter((f) => f.key === asset?.info?.type)?.[0]?.color || 'green'
          let typeIcon = types?.filter((f) => f.key === asset?.info?.type)?.[0]?.iconsvg || 'worker'
          let typetext = types?.filter((f) => f.key === asset?.info?.type)?.[0]?.text || ''

          const lastSeen = measuresById[id]?.info?.time

          return {
            id: asset?.id,
            name: asset?.name,
            groups: asset?.groups,
            groupsText: asset?.groups?.sort()?.toString(),
            info: asset?.info,
            consentAcceptedAt: asset?.info?.consentAcceptedAt
              ? m(asset?.info?.consentAcceptedAt).format('DD/MM/YYYY HH:MM')
              : 'Aguardando',
            type: asset?.info?.type,
            typetext: typetext,
            typeIcon: typeIcon,
            typeColor: typeColor,
            status: ![null, undefined, 'Pendente', 'Revogado Manual'].includes(asset?.info?.consentStatus),
            createdAt: asset?.createdAt,
            createdBy: asset?.createdBy,
            rulesText,
            lastSeen,
          }
        }),
      [rows, assetsById, rules, types, measuresById]
    )

    return (
      <ReactTable
        table={table}
        initialSortBy={{ desc: false, id: 'name' }}
        columns={columns}
        hiddenColumns={hiddenColumns}
        data={data}
        insertAction={insertAction}
        fetchAction={fetchAction}
        updateAction={updateAction}
        deleteAction={deleteAction}
        groupAction={groupAction}
        deleteGroupAction={deleteGroupAction}
        currentGroup={currentGroup}
        setCurrentGroup={setCurrentGroup}
        typeName={typeName}
        cudLoading={cudLoading}
        cudSuccess={cudSuccess}
        showPlaceholders={loading}
      />
    )
  },
  (props, nextProps) => {
    return !nextProps.autoUpdate
  }
)

export default PeopleContent
