import React, { useState, useMemo } from 'react'
import { Dropdown, Form, Transition, Label } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { setTableProp, fetchTableCategories, fetchCategoriesByAbsoluteTime } from 'Store/actions/tables-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import useFetchData from 'Utils/hooks/useFetchData'

function HistoryStepsAssetsDropdown() {
  useFetchData({
    independentFetches: [{ fetchAction: 'assets' }, { fetchAction: 'profiles' }],
  })

  const dispatch = useDispatch()
  const currentTableId = 'historySteps'
  const filterStepError = useSelector((state) => state.tables.byId[currentTableId].filterStepError)
  const assetsTypesIds = useObjSelector((state) => state.profiles.allIds)
  const filterStepLoading = useSelector((state) => state.tables.byId.measuresHist.filterStepLoading)
  const selectedAbsoluteStartTime = useSelector((state) => state.tables.byId[currentTableId].selectedAbsoluteStartTime)
  const selectedAbsoluteEndTime = useSelector((state) => state.tables.byId[currentTableId].selectedAbsoluteEndTime)
  const assets = useObjSelector((state) => state.assets.byId)
  const assetsIds = useObjSelector((state) => state.assets.allIds)
  const selectedAsset = useSelector((state) => JSON.stringify(state.tables.byId[currentTableId].selectedAsset))
  const selectedRelativeTime = useObjSelector((state) => state.tables.byId[currentTableId].selectedRelativeTime)
  const assetsTypes = useObjSelector((state) => state.profiles.byId)

  const [labelError, setLabelError] = useState('')

  const assetsTypesOptions = useMemo(() => {
    return [
      {
        key: '*',
        value: JSON.stringify(['<any>']),
        text: 'Qualquer Atribuição',
      },
      ...assetsTypesIds
        .filter(
          (typeId) =>
            ['assets', 'people'].includes(assetsTypes[typeId]?.type) &&
            assetsIds.find((assetId) => assets[assetId].info?.type === typeId)
        )
        .map((id) => {
          const assetType = assetsTypes[id]
          return {
            key: id,
            value: JSON.stringify(assetsIds.filter((assetId) => assets[assetId].info?.type === id)),
            text: assetType.name,
          }
        })
        .sort((a, b) => a.text?.localeCompare(b.text)),
    ]
  }, [assetsTypesIds, assets, assetsIds, assetsTypes])

  function setFilter(value) {
    dispatch(setTableProp(`byId.${currentTableId}.selectedAsset`, value))
    dispatch(setTableProp(`byId.${currentTableId}.selectedCategory`, []))
    dispatch(setTableProp(`byId.${currentTableId}.selectedMeasures`, []))
    if (value.length > 0) {
      if (selectedAbsoluteStartTime && selectedAbsoluteEndTime) {
        dispatch(
          fetchCategoriesByAbsoluteTime(
            value,
            new Date(selectedAbsoluteStartTime).toISOString(),
            new Date(selectedAbsoluteEndTime).toISOString()
          )
        )
      } else {
        dispatch(fetchTableCategories({ assetId: value, relativeTime: selectedRelativeTime[0] }))
      }
      setLabelError('')
    } else setLabelError('Nenhum ativo associado a essa atribuição')
  }

  return (
    <Form.Group widths="equal">
      <Form.Field inline required error={filterStepError === 1} width="4">
        <Transition transitionOnMount={true} animation="fade down" duration="2300">
          <label>Atribuição</label>
        </Transition>
        <Dropdown
          selection
          fluid
          placeholder="Selecionar Atribuição"
          upward={false}
          options={assetsTypesOptions}
          onChange={(_a, { value }) => setFilter(JSON.parse(value))}
          noResultsMessage={
            filterStepLoading === 1
              ? 'Carregando opções, aguarde...'
              : 'Nenhum resultado encontrado no filtro selecionado.'
          }
          loading={filterStepLoading === 1}
          value={selectedAsset}
          error={filterStepError === 1}
        ></Dropdown>
        {labelError && (
          <Label color="red" pointing>
            {labelError}
          </Label>
        )}
      </Form.Field>
    </Form.Group>
  )
}

export default HistoryStepsAssetsDropdown
