/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createAssetLocation = /* GraphQL */ `mutation CreateAssetLocation(
  $input: CreateAssetLocationInput!
  $condition: ModelAssetLocationConditionInput
) {
  createAssetLocation(input: $input, condition: $condition) {
    id
    assetId
    deviceId
    lat
    lng
    location
    fencesLocation
    groupsLocation
    analytics
    tenant
    time
    motion
    coords
    battery
    connectivity
    model_info
    sessionStatus
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateAssetLocationMutationVariables,
  APITypes.CreateAssetLocationMutation
>;
export const updateAssetLocation = /* GraphQL */ `mutation UpdateAssetLocation(
  $input: UpdateAssetLocationInput!
  $condition: ModelAssetLocationConditionInput
) {
  updateAssetLocation(input: $input, condition: $condition) {
    id
    assetId
    deviceId
    lat
    lng
    location
    fencesLocation
    groupsLocation
    analytics
    tenant
    time
    motion
    coords
    battery
    connectivity
    model_info
    sessionStatus
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssetLocationMutationVariables,
  APITypes.UpdateAssetLocationMutation
>;
export const deleteAssetLocation = /* GraphQL */ `mutation DeleteAssetLocation(
  $input: DeleteAssetLocationInput!
  $condition: ModelAssetLocationConditionInput
) {
  deleteAssetLocation(input: $input, condition: $condition) {
    id
    assetId
    deviceId
    lat
    lng
    location
    fencesLocation
    groupsLocation
    analytics
    tenant
    time
    motion
    coords
    battery
    connectivity
    model_info
    sessionStatus
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteAssetLocationMutationVariables,
  APITypes.DeleteAssetLocationMutation
>;
export const createSession = /* GraphQL */ `mutation CreateSession(
  $input: CreateSessionInput!
  $condition: ModelSessionConditionInput
) {
  createSession(input: $input, condition: $condition) {
    id
    tenant
    assetId
    deviceId
    status
    startTime
    endTime
    createdBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSessionMutationVariables,
  APITypes.CreateSessionMutation
>;
export const updateSession = /* GraphQL */ `mutation UpdateSession(
  $input: UpdateSessionInput!
  $condition: ModelSessionConditionInput
) {
  updateSession(input: $input, condition: $condition) {
    id
    tenant
    assetId
    deviceId
    status
    startTime
    endTime
    createdBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSessionMutationVariables,
  APITypes.UpdateSessionMutation
>;
export const deleteSession = /* GraphQL */ `mutation DeleteSession(
  $input: DeleteSessionInput!
  $condition: ModelSessionConditionInput
) {
  deleteSession(input: $input, condition: $condition) {
    id
    tenant
    assetId
    deviceId
    status
    startTime
    endTime
    createdBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSessionMutationVariables,
  APITypes.DeleteSessionMutation
>;
export const createNotificationProfile = /* GraphQL */ `mutation CreateNotificationProfile(
  $input: CreateNotificationProfileInput!
  $condition: ModelNotificationProfileConditionInput
) {
  createNotificationProfile(input: $input, condition: $condition) {
    id
    name
    tenant
    bgColor
    status
    screenTime
    icon
    description
    level
    titleColor
    bodyColor
    actionColor
    dateColor
    txtColor
    createdBy
    theme
    iconColor
    sound
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationProfileMutationVariables,
  APITypes.CreateNotificationProfileMutation
>;
export const updateNotificationProfile = /* GraphQL */ `mutation UpdateNotificationProfile(
  $input: UpdateNotificationProfileInput!
  $condition: ModelNotificationProfileConditionInput
) {
  updateNotificationProfile(input: $input, condition: $condition) {
    id
    name
    tenant
    bgColor
    status
    screenTime
    icon
    description
    level
    titleColor
    bodyColor
    actionColor
    dateColor
    txtColor
    createdBy
    theme
    iconColor
    sound
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationProfileMutationVariables,
  APITypes.UpdateNotificationProfileMutation
>;
export const deleteNotificationProfile = /* GraphQL */ `mutation DeleteNotificationProfile(
  $input: DeleteNotificationProfileInput!
  $condition: ModelNotificationProfileConditionInput
) {
  deleteNotificationProfile(input: $input, condition: $condition) {
    id
    name
    tenant
    bgColor
    status
    screenTime
    icon
    description
    level
    titleColor
    bodyColor
    actionColor
    dateColor
    txtColor
    createdBy
    theme
    iconColor
    sound
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationProfileMutationVariables,
  APITypes.DeleteNotificationProfileMutation
>;
export const createRule = /* GraphQL */ `mutation CreateRule(
  $input: CreateRuleInput!
  $condition: ModelRuleConditionInput
) {
  createRule(input: $input, condition: $condition) {
    id
    notificationProfileId
    notificationProfile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    name
    tenant
    status
    createdBy
    description
    action {
      type
      messageProfileId
    }
    message
    messageId
    scope
    sourceId
    sourceProp
    sourcePropValue
    sourceType
    targetProp
    targetPropValue
    targetId
    targetType
    threshold
    sendTo
    editable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateRuleMutationVariables,
  APITypes.CreateRuleMutation
>;
export const updateRule = /* GraphQL */ `mutation UpdateRule(
  $input: UpdateRuleInput!
  $condition: ModelRuleConditionInput
) {
  updateRule(input: $input, condition: $condition) {
    id
    notificationProfileId
    notificationProfile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    name
    tenant
    status
    createdBy
    description
    action {
      type
      messageProfileId
    }
    message
    messageId
    scope
    sourceId
    sourceProp
    sourcePropValue
    sourceType
    targetProp
    targetPropValue
    targetId
    targetType
    threshold
    sendTo
    editable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateRuleMutationVariables,
  APITypes.UpdateRuleMutation
>;
export const deleteRule = /* GraphQL */ `mutation DeleteRule(
  $input: DeleteRuleInput!
  $condition: ModelRuleConditionInput
) {
  deleteRule(input: $input, condition: $condition) {
    id
    notificationProfileId
    notificationProfile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    name
    tenant
    status
    createdBy
    description
    action {
      type
      messageProfileId
    }
    message
    messageId
    scope
    sourceId
    sourceProp
    sourcePropValue
    sourceType
    targetProp
    targetPropValue
    targetId
    targetType
    threshold
    sendTo
    editable
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteRuleMutationVariables,
  APITypes.DeleteRuleMutation
>;
export const createMessageProfile = /* GraphQL */ `mutation CreateMessageProfile(
  $input: CreateMessageProfileInput!
  $condition: ModelMessageProfileConditionInput
) {
  createMessageProfile(input: $input, condition: $condition) {
    id
    tenant
    label
    type
    title
    body
    extra
    tags
    createdBy
    status
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageProfileMutationVariables,
  APITypes.CreateMessageProfileMutation
>;
export const updateMessageProfile = /* GraphQL */ `mutation UpdateMessageProfile(
  $input: UpdateMessageProfileInput!
  $condition: ModelMessageProfileConditionInput
) {
  updateMessageProfile(input: $input, condition: $condition) {
    id
    tenant
    label
    type
    title
    body
    extra
    tags
    createdBy
    status
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageProfileMutationVariables,
  APITypes.UpdateMessageProfileMutation
>;
export const deleteMessageProfile = /* GraphQL */ `mutation DeleteMessageProfile(
  $input: DeleteMessageProfileInput!
  $condition: ModelMessageProfileConditionInput
) {
  deleteMessageProfile(input: $input, condition: $condition) {
    id
    tenant
    label
    type
    title
    body
    extra
    tags
    createdBy
    status
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageProfileMutationVariables,
  APITypes.DeleteMessageProfileMutation
>;
export const createLoginPreferences = /* GraphQL */ `mutation CreateLoginPreferences(
  $input: CreateLoginPreferencesInput!
  $condition: ModelLoginPreferencesConditionInput
) {
  createLoginPreferences(input: $input, condition: $condition) {
    tenant
    login
    center {
      lat
      lng
    }
    zoom
    mapLayers
    refs
    fences
    routes
    indoor
    tables
    routesPanels
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateLoginPreferencesMutationVariables,
  APITypes.CreateLoginPreferencesMutation
>;
export const updateLoginPreferences = /* GraphQL */ `mutation UpdateLoginPreferences(
  $input: UpdateLoginPreferencesInput!
  $condition: ModelLoginPreferencesConditionInput
) {
  updateLoginPreferences(input: $input, condition: $condition) {
    tenant
    login
    center {
      lat
      lng
    }
    zoom
    mapLayers
    refs
    fences
    routes
    indoor
    tables
    routesPanels
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateLoginPreferencesMutationVariables,
  APITypes.UpdateLoginPreferencesMutation
>;
export const deleteLoginPreferences = /* GraphQL */ `mutation DeleteLoginPreferences(
  $input: DeleteLoginPreferencesInput!
  $condition: ModelLoginPreferencesConditionInput
) {
  deleteLoginPreferences(input: $input, condition: $condition) {
    tenant
    login
    center {
      lat
      lng
    }
    zoom
    mapLayers
    refs
    fences
    routes
    indoor
    tables
    routesPanels
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteLoginPreferencesMutationVariables,
  APITypes.DeleteLoginPreferencesMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    tenant
    assetId
    message
    title
    time
    type
    deviceId
    profileId
    profile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    ruleId
    confirmedBy
    confirmedAt
    closedBy
    closedAt
    location
    originType
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    tenant
    assetId
    message
    title
    time
    type
    deviceId
    profileId
    profile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    ruleId
    confirmedBy
    confirmedAt
    closedBy
    closedAt
    location
    originType
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    tenant
    assetId
    message
    title
    time
    type
    deviceId
    profileId
    profile {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
    ruleId
    confirmedBy
    confirmedAt
    closedBy
    closedAt
    location
    originType
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const createGeoModule = /* GraphQL */ `mutation CreateGeoModule(
  $input: CreateGeoModuleInput!
  $condition: ModelGeoModuleConditionInput
) {
  createGeoModule(input: $input, condition: $condition) {
    id
    tenant
    label
    code
    type
    geometry {
      type
      coordinates
      mRadius
    }
    profile
    profileInfo {
      id
      code
      tenant
      name
      type
      color
      icon
      description
      customFields {
        id
        label
        type
        options
        sectionId
      }
      properties
      status
      createdAt
      createdBy
      createForm
      updatedAt
      updatedBy
      updateForm
      formPermission
    }
    history {
      updatedAt
      updatedBy
      fields
    }
    properties
    description
    defaultInvisible
    state
    loadPreference
    createdAt
    createdBy
    createForm
    updatedBy
    updateForm
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateGeoModuleMutationVariables,
  APITypes.CreateGeoModuleMutation
>;
export const updateGeoModule = /* GraphQL */ `mutation UpdateGeoModule(
  $input: UpdateGeoModuleInput!
  $condition: ModelGeoModuleConditionInput
) {
  updateGeoModule(input: $input, condition: $condition) {
    id
    tenant
    label
    code
    type
    geometry {
      type
      coordinates
      mRadius
    }
    profile
    profileInfo {
      id
      code
      tenant
      name
      type
      color
      icon
      description
      customFields {
        id
        label
        type
        options
        sectionId
      }
      properties
      status
      createdAt
      createdBy
      createForm
      updatedAt
      updatedBy
      updateForm
      formPermission
    }
    history {
      updatedAt
      updatedBy
      fields
    }
    properties
    description
    defaultInvisible
    state
    loadPreference
    createdAt
    createdBy
    createForm
    updatedBy
    updateForm
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateGeoModuleMutationVariables,
  APITypes.UpdateGeoModuleMutation
>;
export const deleteGeoModule = /* GraphQL */ `mutation DeleteGeoModule(
  $input: DeleteGeoModuleInput!
  $condition: ModelGeoModuleConditionInput
) {
  deleteGeoModule(input: $input, condition: $condition) {
    id
    tenant
    label
    code
    type
    geometry {
      type
      coordinates
      mRadius
    }
    profile
    profileInfo {
      id
      code
      tenant
      name
      type
      color
      icon
      description
      customFields {
        id
        label
        type
        options
        sectionId
      }
      properties
      status
      createdAt
      createdBy
      createForm
      updatedAt
      updatedBy
      updateForm
      formPermission
    }
    history {
      updatedAt
      updatedBy
      fields
    }
    properties
    description
    defaultInvisible
    state
    loadPreference
    createdAt
    createdBy
    createForm
    updatedBy
    updateForm
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteGeoModuleMutationVariables,
  APITypes.DeleteGeoModuleMutation
>;
export const createDataLabeling = /* GraphQL */ `mutation CreateDataLabeling(
  $input: CreateDataLabelingInput!
  $condition: ModelDataLabelingConditionInput
) {
  createDataLabeling(input: $input, condition: $condition) {
    id
    tenant
    name
    createdAt
    createdBy
    assets
    shift
    startTime
    endTime
    activityId
    activity {
      id
      tenant
      code
      name
      createdAt
      createdBy
      operationUnit
      section
      updatedAt
      updatedBy
      createForm
      properties
      description
      extraInfo
    }
    createForm
    updatedAt
    updatedBy
    description
    properties
    extraInfo
    taktTime
    targetCycleTime
    phases {
      index
      name
      description
      color
      code
      vehicle {
        name
      }
      ticon {
        code
        name
        qty
      }
      va
      nva
    }
    labelings {
      cycle
      phaseIndex
      startTime
      endTime
      vehicle
    }
    videoUrlKey
  }
}
` as GeneratedMutation<
  APITypes.CreateDataLabelingMutationVariables,
  APITypes.CreateDataLabelingMutation
>;
export const updateDataLabeling = /* GraphQL */ `mutation UpdateDataLabeling(
  $input: UpdateDataLabelingInput!
  $condition: ModelDataLabelingConditionInput
) {
  updateDataLabeling(input: $input, condition: $condition) {
    id
    tenant
    name
    createdAt
    createdBy
    assets
    shift
    startTime
    endTime
    activityId
    activity {
      id
      tenant
      code
      name
      createdAt
      createdBy
      operationUnit
      section
      updatedAt
      updatedBy
      createForm
      properties
      description
      extraInfo
    }
    createForm
    updatedAt
    updatedBy
    description
    properties
    extraInfo
    taktTime
    targetCycleTime
    phases {
      index
      name
      description
      color
      code
      vehicle {
        name
      }
      ticon {
        code
        name
        qty
      }
      va
      nva
    }
    labelings {
      cycle
      phaseIndex
      startTime
      endTime
      vehicle
    }
    videoUrlKey
  }
}
` as GeneratedMutation<
  APITypes.UpdateDataLabelingMutationVariables,
  APITypes.UpdateDataLabelingMutation
>;
export const deleteDataLabeling = /* GraphQL */ `mutation DeleteDataLabeling(
  $input: DeleteDataLabelingInput!
  $condition: ModelDataLabelingConditionInput
) {
  deleteDataLabeling(input: $input, condition: $condition) {
    id
    tenant
    name
    createdAt
    createdBy
    assets
    shift
    startTime
    endTime
    activityId
    activity {
      id
      tenant
      code
      name
      createdAt
      createdBy
      operationUnit
      section
      updatedAt
      updatedBy
      createForm
      properties
      description
      extraInfo
    }
    createForm
    updatedAt
    updatedBy
    description
    properties
    extraInfo
    taktTime
    targetCycleTime
    phases {
      index
      name
      description
      color
      code
      vehicle {
        name
      }
      ticon {
        code
        name
        qty
      }
      va
      nva
    }
    labelings {
      cycle
      phaseIndex
      startTime
      endTime
      vehicle
    }
    videoUrlKey
  }
}
` as GeneratedMutation<
  APITypes.DeleteDataLabelingMutationVariables,
  APITypes.DeleteDataLabelingMutation
>;
export const createReport = /* GraphQL */ `mutation CreateReport(
  $input: CreateReportInput!
  $condition: ModelReportConditionInput
) {
  createReport(input: $input, condition: $condition) {
    id
    code
    tenant
    label
    scope
    startTime
    endTime
    description
    filters
    hiddenColumns
    groupBy
    sortBy {
      desc
      id
    }
    access
    createdBy
    createForm
    updatedBy
    updateForm
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateReportMutationVariables,
  APITypes.CreateReportMutation
>;
export const updateReport = /* GraphQL */ `mutation UpdateReport(
  $input: UpdateReportInput!
  $condition: ModelReportConditionInput
) {
  updateReport(input: $input, condition: $condition) {
    id
    code
    tenant
    label
    scope
    startTime
    endTime
    description
    filters
    hiddenColumns
    groupBy
    sortBy {
      desc
      id
    }
    access
    createdBy
    createForm
    updatedBy
    updateForm
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportMutationVariables,
  APITypes.UpdateReportMutation
>;
export const deleteReport = /* GraphQL */ `mutation DeleteReport(
  $input: DeleteReportInput!
  $condition: ModelReportConditionInput
) {
  deleteReport(input: $input, condition: $condition) {
    id
    code
    tenant
    label
    scope
    startTime
    endTime
    description
    filters
    hiddenColumns
    groupBy
    sortBy {
      desc
      id
    }
    access
    createdBy
    createForm
    updatedBy
    updateForm
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportMutationVariables,
  APITypes.DeleteReportMutation
>;
export const createDashboard = /* GraphQL */ `mutation CreateDashboard(
  $input: CreateDashboardInput!
  $condition: ModelDashboardConditionInput
) {
  createDashboard(input: $input, condition: $condition) {
    id
    code
    tenant
    label
    description
    panels
    timeType
    startTime
    endTime
    access
    showAsDefault
    createdBy
    createForm
    updatedBy
    updateForm
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateDashboardMutationVariables,
  APITypes.CreateDashboardMutation
>;
export const updateDashboard = /* GraphQL */ `mutation UpdateDashboard(
  $input: UpdateDashboardInput!
  $condition: ModelDashboardConditionInput
) {
  updateDashboard(input: $input, condition: $condition) {
    id
    code
    tenant
    label
    description
    panels
    timeType
    startTime
    endTime
    access
    showAsDefault
    createdBy
    createForm
    updatedBy
    updateForm
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateDashboardMutationVariables,
  APITypes.UpdateDashboardMutation
>;
export const deleteDashboard = /* GraphQL */ `mutation DeleteDashboard(
  $input: DeleteDashboardInput!
  $condition: ModelDashboardConditionInput
) {
  deleteDashboard(input: $input, condition: $condition) {
    id
    code
    tenant
    label
    description
    panels
    timeType
    startTime
    endTime
    access
    showAsDefault
    createdBy
    createForm
    updatedBy
    updateForm
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteDashboardMutationVariables,
  APITypes.DeleteDashboardMutation
>;
export const createActivity = /* GraphQL */ `mutation CreateActivity(
  $input: CreateActivityInput!
  $condition: ModelActivityConditionInput
) {
  createActivity(input: $input, condition: $condition) {
    id
    tenant
    code
    name
    createdAt
    createdBy
    operationUnit
    section
    updatedAt
    updatedBy
    createForm
    properties
    description
    extraInfo
  }
}
` as GeneratedMutation<
  APITypes.CreateActivityMutationVariables,
  APITypes.CreateActivityMutation
>;
export const updateActivity = /* GraphQL */ `mutation UpdateActivity(
  $input: UpdateActivityInput!
  $condition: ModelActivityConditionInput
) {
  updateActivity(input: $input, condition: $condition) {
    id
    tenant
    code
    name
    createdAt
    createdBy
    operationUnit
    section
    updatedAt
    updatedBy
    createForm
    properties
    description
    extraInfo
  }
}
` as GeneratedMutation<
  APITypes.UpdateActivityMutationVariables,
  APITypes.UpdateActivityMutation
>;
export const deleteActivity = /* GraphQL */ `mutation DeleteActivity(
  $input: DeleteActivityInput!
  $condition: ModelActivityConditionInput
) {
  deleteActivity(input: $input, condition: $condition) {
    id
    tenant
    code
    name
    createdAt
    createdBy
    operationUnit
    section
    updatedAt
    updatedBy
    createForm
    properties
    description
    extraInfo
  }
}
` as GeneratedMutation<
  APITypes.DeleteActivityMutationVariables,
  APITypes.DeleteActivityMutation
>;
export const createMensuration = /* GraphQL */ `mutation CreateMensuration(
  $input: CreateMensurationInput!
  $condition: ModelMensurationConditionInput
) {
  createMensuration(input: $input, condition: $condition) {
    id
    tenant
    name
    createdAt
    createdBy
    shift
    vehicle
    activityId
    activity {
      id
      tenant
      code
      name
      createdAt
      createdBy
      operationUnit
      section
      updatedAt
      updatedBy
      createForm
      properties
      description
      extraInfo
    }
    description
    section
    updatedAt
    updatedBy
    properties
    extraInfo
    taktTime
    targetCycleTime
    operator
    performedBy
    phases {
      index
      name
      description
      color
      code
      vehicle {
        name
      }
      ticon {
        code
        name
        qty
      }
      va
      nva
    }
    cyclePhaseDurations {
      duration
      phases {
        duration
      }
    }
    statistics
  }
}
` as GeneratedMutation<
  APITypes.CreateMensurationMutationVariables,
  APITypes.CreateMensurationMutation
>;
export const updateMensuration = /* GraphQL */ `mutation UpdateMensuration(
  $input: UpdateMensurationInput!
  $condition: ModelMensurationConditionInput
) {
  updateMensuration(input: $input, condition: $condition) {
    id
    tenant
    name
    createdAt
    createdBy
    shift
    vehicle
    activityId
    activity {
      id
      tenant
      code
      name
      createdAt
      createdBy
      operationUnit
      section
      updatedAt
      updatedBy
      createForm
      properties
      description
      extraInfo
    }
    description
    section
    updatedAt
    updatedBy
    properties
    extraInfo
    taktTime
    targetCycleTime
    operator
    performedBy
    phases {
      index
      name
      description
      color
      code
      vehicle {
        name
      }
      ticon {
        code
        name
        qty
      }
      va
      nva
    }
    cyclePhaseDurations {
      duration
      phases {
        duration
      }
    }
    statistics
  }
}
` as GeneratedMutation<
  APITypes.UpdateMensurationMutationVariables,
  APITypes.UpdateMensurationMutation
>;
export const deleteMensuration = /* GraphQL */ `mutation DeleteMensuration(
  $input: DeleteMensurationInput!
  $condition: ModelMensurationConditionInput
) {
  deleteMensuration(input: $input, condition: $condition) {
    id
    tenant
    name
    createdAt
    createdBy
    shift
    vehicle
    activityId
    activity {
      id
      tenant
      code
      name
      createdAt
      createdBy
      operationUnit
      section
      updatedAt
      updatedBy
      createForm
      properties
      description
      extraInfo
    }
    description
    section
    updatedAt
    updatedBy
    properties
    extraInfo
    taktTime
    targetCycleTime
    operator
    performedBy
    phases {
      index
      name
      description
      color
      code
      vehicle {
        name
      }
      ticon {
        code
        name
        qty
      }
      va
      nva
    }
    cyclePhaseDurations {
      duration
      phases {
        duration
      }
    }
    statistics
  }
}
` as GeneratedMutation<
  APITypes.DeleteMensurationMutationVariables,
  APITypes.DeleteMensurationMutation
>;
export const createProfile = /* GraphQL */ `mutation CreateProfile(
  $input: CreateProfileInput!
  $condition: ModelProfileConditionInput
) {
  createProfile(input: $input, condition: $condition) {
    id
    code
    tenant
    name
    type
    color
    icon
    description
    customFields {
      id
      label
      type
      options
      sectionId
    }
    properties
    status
    createdAt
    createdBy
    createForm
    updatedAt
    updatedBy
    updateForm
    formPermission
  }
}
` as GeneratedMutation<
  APITypes.CreateProfileMutationVariables,
  APITypes.CreateProfileMutation
>;
export const updateProfile = /* GraphQL */ `mutation UpdateProfile(
  $input: UpdateProfileInput!
  $condition: ModelProfileConditionInput
) {
  updateProfile(input: $input, condition: $condition) {
    id
    code
    tenant
    name
    type
    color
    icon
    description
    customFields {
      id
      label
      type
      options
      sectionId
    }
    properties
    status
    createdAt
    createdBy
    createForm
    updatedAt
    updatedBy
    updateForm
    formPermission
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfileMutationVariables,
  APITypes.UpdateProfileMutation
>;
export const deleteProfile = /* GraphQL */ `mutation DeleteProfile(
  $input: DeleteProfileInput!
  $condition: ModelProfileConditionInput
) {
  deleteProfile(input: $input, condition: $condition) {
    id
    code
    tenant
    name
    type
    color
    icon
    description
    customFields {
      id
      label
      type
      options
      sectionId
    }
    properties
    status
    createdAt
    createdBy
    createForm
    updatedAt
    updatedBy
    updateForm
    formPermission
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfileMutationVariables,
  APITypes.DeleteProfileMutation
>;
export const createChangeLog = /* GraphQL */ `mutation CreateChangeLog(
  $input: CreateChangeLogInput!
  $condition: ModelChangeLogConditionInput
) {
  createChangeLog(input: $input, condition: $condition) {
    id
    tenant
    entity
    entityId
    log
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateChangeLogMutationVariables,
  APITypes.CreateChangeLogMutation
>;
export const updateChangeLog = /* GraphQL */ `mutation UpdateChangeLog(
  $input: UpdateChangeLogInput!
  $condition: ModelChangeLogConditionInput
) {
  updateChangeLog(input: $input, condition: $condition) {
    id
    tenant
    entity
    entityId
    log
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateChangeLogMutationVariables,
  APITypes.UpdateChangeLogMutation
>;
export const deleteChangeLog = /* GraphQL */ `mutation DeleteChangeLog(
  $input: DeleteChangeLogInput!
  $condition: ModelChangeLogConditionInput
) {
  deleteChangeLog(input: $input, condition: $condition) {
    id
    tenant
    entity
    entityId
    log
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteChangeLogMutationVariables,
  APITypes.DeleteChangeLogMutation
>;
