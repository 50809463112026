import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sliderChange, saveHistory } from 'Store/actions/history-action'
import { setFalse } from 'Store/actions/toggle-action'
import { withTranslation } from 'react-i18next'
import SearchBox from './SearchBox.jsx'

/*  Author: Luis Fernando/Bruno Melo
    Type: Smart
  Description: Handle the search info
  TODO: Change dropdown to support multiple selection options
*/

class Search extends Component {
  state = {
    cancelButton: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.search !== prevProps.search) {
      if (this.props.search !== '') this.setState({ cancelButton: true })
    }
  }

  changeSearch = (i) => {
    if (this.state.cancelButton !== i) this.setState({ cancelButton: i })
  }

  cancelClick = () => {
    if (!this.props.histType) this.setState({ cancelButton: false })
    else {
      this.props.saveHistory([]) //Setar o history vazio
      this.props.sliderChange({ min: 0, max: 0, total: 1 }) //Setar o timeslider para dados do initialStore
      this.setState({ cancelButton: false })
    }
  }

  render() {
    let searchProps = {
      cancelButton: this.state.cancelButton,
      changeSearch: this.changeSearch,
      divider: false,
      // width: '21vw',
      value: this.props.search,
      assets: this.props.assets,
      measures: this.props.measures,
      showFitBounds: this.props.showFitBounds,
      isMobileSized: this.props.isMobileSized,
      login: this.props.login,
      cancelClick: this.cancelClick,
      cancelTitle: this.props.t('DrawClean'),
    }

    return <SearchBox {...searchProps} />
  }
}

const mapStateToProps = (state) => ({
  search: state.filters.searchValue,
  assets: state.assets.byId,
  measures: state.measures.byId,
  showFitBounds: state.toggle.showFitBounds,
  histType: state.history.type,
  login: state.login,
})

const mapDispatchToProps = {
  setFalse: setFalse,
  sliderChange: sliderChange,
  saveHistory: saveHistory,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Search))
