import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { Input, Form, Message /*, Checkbox */ } from 'semantic-ui-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
//import schema from 'graphql/schema.json'
import { GenericFormProps } from './types/GenericFormProps'
import {
  provisionDevice,
  clearDeviceStates,
  fetchDevices,
  unProvisionDevices,
  updateDeviceById,
} from 'Store/actions/devices-action'

import { useDispatch /*, useSelector */ } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
// copying this from TypesForm.jsx
type FieldsState = {
  serial: string
}

type FormState = {
  serial_number: string
  description: string
  destino: string
  responsavel: string
}

type ErrorForm = {
  serial: boolean
}

interface DeviceProvisionProps extends GenericFormProps {
  ids?: string[]
}

const DeviceProvisionForm: React.FC<DeviceProvisionProps> = ({ handleCancel, addOpen, setAddOpen, ids }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loading = useObjSelector((state: RootStore) => state.devices.cudLoading)
  const errorMessage = useObjSelector((state: RootStore) => state.devices.errors) as string
  const success = useObjSelector((state: RootStore) => state.devices.success)
  const [unprovisionMode] = useState(ids?.length ? true : false)
  const editMode = useObjSelector((state: RootStore) => state.toggle.deviceEditMode)
  const info = useObjSelector((state: RootStore) => (ids?.[0] ? state.devices.byId[ids[0]]?.info || '' : '')) as any
  const serial_numberDf = useObjSelector((state: RootStore) =>
    ids?.[0] ? state.devices.byId[ids[0]]?.serial_number || '' : ''
  )

  //const [showAllDevices, setShowAllDevices] = React.useState(false)

  // maybe it doesn't have to contain '<FieldsState>' ?
  const [fields, setFields] = useState<FieldsState>({
    serial: '',
  })

  const [formValues, setFormValues] = useState<FormState>({
    serial_number: serial_numberDf,
    description: info['description'] || '',
    destino: info['destino'] || '',
    responsavel: info['responsavel'] || '',
  })

  console.log('fields', fields)
  // const [formObj, setFormObj] = useState({})
  const [errorObj, setErrorObj] = useState<ErrorForm>({ serial: false })

  const onSave = useCallback(() => {
    if (success) {
      setAddOpen(false)
      dispatch(clearDeviceStates())
      dispatch(fetchDevices())
      return
    }
    if (!fields.serial) {
      setErrorObj({ ...errorObj, serial: true })
      return
    }
    dispatch(provisionDevice(fields))
    //setAddOpen(false)
  }, [fields, errorObj, dispatch, setAddOpen, success])

  const onSaveUnprovision = useCallback(() => {
    if (ids?.length === 0) {
      setAddOpen(false)
      dispatch(fetchDevices())
      return
    }
    dispatch(unProvisionDevices(ids))
  }, [ids, dispatch, setAddOpen])

  const handleSaveDevice = useCallback(() => {
    /*if (ids?.length === 0) {
      setAddOpen(false)
      dispatch(fetchDevices())
      return
    }
    dispatch(unProvisionDevices(ids))*/
    if (!ids) alert('Erro: Perdeu o ID')
    else {
      dispatch(updateDeviceById(ids[0], formValues))
      setAddOpen(false)
    }
  }, [formValues, ids, dispatch, setAddOpen])

  if (editMode) {
    return (
      <ModalTemplate
        size="small"
        header="Editar Dispositivo"
        saveContent={t('lblsave')}
        open={addOpen}
        onClose={handleCancel}
        onSave={handleSaveDevice}
        onCancel={handleCancel}
        loading={loading}
      >
        <Form>
          <Form.Group>
            <Form.Field required={false} width={3}>
              <label>{'Número de Série'}</label>
              <Input
                name={'serial_number'}
                fluid
                value={formValues.serial_number}
                onChange={(_e, { name, value }) => setFormValues({ ...formValues, [name]: value })}
              />
            </Form.Field>
            <Form.Field width="8" required={false} /*error={errors.name}*/>
              <label>{'Descrição'}</label>
              <Input
                placeholder="Descrição..."
                name={'description'}
                fluid
                value={formValues.description}
                onChange={(_e, { name, value }) => setFormValues({ ...formValues, [name]: value })}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field required={false} width={8}>
              <label>{'Destino'}</label>
              <Input
                name={'destiny'}
                fluid
                value={formValues.destino}
                onChange={(_e, { name, value }) => setFormValues({ ...formValues, [name]: value })}
              />
            </Form.Field>
            <Form.Field width="8" required={false} /*error={errors.name}*/>
              <label>{'Responsável'}</label>
              <Input
                placeholder="Responsável"
                name={'responsable'}
                fluid
                value={formValues.responsavel}
                onChange={(_e, { name, value }) => setFormValues({ ...formValues, [name]: value })}
              />
            </Form.Field>
          </Form.Group>
          {/* <Form.Field required={false} width={5}>
            <label>{t('Assignment')}</label>
            <Dropdown
              defaultValue={fields.type}
              placeholder={noType}
              search
              selection
              options={[
                {
                  key: 'noType',
                  text: noType,
                  value: '',
                },
                ...typesAllIds
                  .filter((tp) => typesById[tp]?.info?.table === 'assets.assets')
                  .map((e) => ({
                    key: typesById[e]?.id,
                    text: typesById[e]?.info?.label,
                    value: typesById[e]?.id,
                  }))
                  .sort((a, b) => (a.text && b.text ? a.text.toLowerCase().localeCompare(b.text.toLowerCase()) : 1)),
              ]}
              onChange={(e, { value }) => handleChange(fields, 'type', value as string)}
            />
            </Form.Field>*/}

          {/* <Form.Group>
          <Form.Field required={false} width={16}>
            <DeviceDropdown
              {...props}
              handleChange={handleChange}
              fields={fields}
              fetchDevices={fetchDevices}
              showAllDevices={showAllDevices}
            />
          </Form.Field>
          </Form.Group>*/}
          {/*<Form.Group>
            <Form.Field
              name={'showAllDevices'}
              control={Checkbox}
              checked={showAllDevices}
              label="Visualizar todos os dispositivos"
              required={false}
              width={16}
              onChange={() => setShowAllDevices(!showAllDevices)}
            ></Form.Field>
        </Form.Group>*/}
        </Form>
      </ModalTemplate>
    )
  }

  if (unprovisionMode) {
    return (
      <ModalTemplate
        size="small"
        header="Tem certeza que desejar desprovisionar os dispositivos selecionados?"
        saveContent={ids?.length ? 'Desprovisionar' : 'Fechar'}
        open={addOpen}
        onClose={handleCancel}
        onSave={onSaveUnprovision}
        onCancel={handleCancel}
        loading={loading}
      >
        <p>Desprovisionar um dispositivo significa que ele será removido da empresa e não poderá mais ser utilizado.</p>
        {!!ids?.length && (
          <p>
            Os ids dos dispositivos a serem desprovisionados são: <b>{ids.join(', ')}</b>
          </p>
        )}
        {<SuccessMessage success={success} message="Dispositivos desprovisionados com sucesso!" />}
        {<WarningMessage error={errorMessage} />}
      </ModalTemplate>
    )
  }

  return (
    <ModalTemplate
      onClose={handleCancel}
      open={addOpen}
      saveContent={success ? t('Close') : t('lblsave')}
      size="small"
      loading={loading}
      header={<span>{`Provisionar dispositivos`}</span>}
      onSave={onSave}
      onCancel={handleCancel}
    >
      <Form>
        <p>
          Provisionar um dispositivo significa que ele será adicionado a esta empresa e poderá ser utilizado para coleta
          de dados.
        </p>
        <p>Não é possível provisionar dispositivos que não estejam no tenant da Phygitall.</p>
        <Form.Group>
          <Form.Field width="10" required={true} error={errorObj.serial}>
            <label>Serial</label>
            <Input
              name="serial"
              fluid
              type="number"
              value={fields.serial}
              onChange={(e, { name, value }) => setFields({ ...fields, [name]: value })}
            />
          </Form.Field>
        </Form.Group>
        {<WarningMessage error={errorMessage} />}
        {<SuccessMessage success={success} message="Dispositivo provisionado com sucesso!" />}
      </Form>
    </ModalTemplate>
  )
}

const WarningMessage = ({ error }: { error: string }) => {
  if (error) {
    return <Message negative>{error}</Message>
  }
  return null
}

const SuccessMessage = ({ success, message }: { success?: boolean; message: string }) => {
  if (success) {
    return <Message positive>{message}</Message>
  }
  return null
}

export default DeviceProvisionForm
