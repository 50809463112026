import { generateAcronym } from './generateAcronym'

/**
 * Determines the display name for a module based on its label, code, and the user's profile name.
 *
 * @param {Object} params - The parameters object.
 * @param {string} [params.moduleLabel] - The label of the module.
 * @param {string} [params.moduleCode] - The code of the module.
 * @param {string} [params.profileName] - The profile name used to generate an acronym if the label is not available.
 * @returns {string} The display name for the module.
 *
 * @example
 * ```typescript
 * const params1 = { moduleLabel: "Dashboard", moduleCode: "DB", profileName: "Administrator" };
 * const displayName1 = getDisplayName(params1); // "Dashboard"
 *
 * const params2 = { moduleCode: "DB", profileName: "Administrator" };
 * const displayName2 = getDisplayName(params2); // "ADM-DB"
 *
 * const params3 = { moduleCode: "DB" };
 * const displayName3 = getDisplayName(params3); // "DB"
 *
 * const params4 = { profileName: "Administrator" };
 * const displayName4 = getDisplayName(params4); // "ADM"
 *
 * const params5 = {};
 * const displayName5 = getDisplayName(params5); // ""
 * ```
 */
function getDisplayName(params: { moduleLabel?: string; moduleCode?: string; profileName?: string }): string {
  if (params.moduleLabel?.trim()) {
    return params.moduleLabel
  }

  if (params.profileName?.trim()) {
    const acronym = generateAcronym(params.profileName)
    const codeSuffix = params.moduleCode ? `-${params.moduleCode}` : ''
    return `${acronym}${codeSuffix}`
  }

  return params.moduleCode ?? ''
}

export default getDisplayName
