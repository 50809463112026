import React, { Fragment, useEffect, useMemo } from 'react'
import {
  useTable,
  useSortBy,
  /*useFilters, */ useGlobalFilter,
  usePagination,
  useRowSelect,
  useGroupBy,
  useExpanded,
  TableOptions,
} from 'react-table'
import { Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { setAutoUpdate } from 'Store/actions/tables-action'
import { ReactTableTemplateHeader } from './ReactTableTemplateHeader'
import { ReactTableTemplateContent } from './ReactTableTemplateContent'
import { RootStore } from 'Store/initialStore'
import { ReactTableTemplateInterface } from './ReactTableTemplateInterfaces'
import { useRouteMatch } from 'react-router-dom'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { setFalse } from 'Store/actions/toggle-action'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: { indeterminate: boolean; rest: object }, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>(null)
    const resolvedRef = (ref || defaultRef) as React.MutableRefObject<HTMLInputElement>

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

export const ReactTableTemplate = ({
  table,
  tableLabel,
  deleteAction,
  typeName,
  menuButtons,
  columns,
  data,
  dashboard,
  dashboardId,
  dashboardOptions,
  insightActions,
  insightActionsValues,
  fetchAction,
  cudLoading,
  cudSuccess,
  showTable = true,
  showPlaceholders = false,
  FormModal,
  insertAction,
  updateAction,
  groupAction,
  deleteGroupAction,
  hiddenColumns = [],
  currentGroup,
  setCurrentGroup,
  newItemClick = () => null,
  onEditClick = () => null,
  onLabelsClick = () => null,
  onLabelingsClick = () => null,
  setProp,
  onSave,
  onFilterClick,
  setHiddenColumnsProp,
  setSortByProp,
  setGroupByProp,
  initialSortBy,
  columnsOpenProp,
}: ReactTableTemplateInterface) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [columnsOpen, setColumnsOpen] = React.useState(false)

  useEffect(() => {
    if (columnsOpenProp) {
      setColumnsOpen(columnsOpenProp)
      dispatch(setFalse('showTableColumnsModal'))
    }
  }, [columnsOpenProp, dispatch])

  const [value, setValue] = React.useState('')
  /*Grupos*/
  const [groupsOpen, setGroupsOpen] = React.useState(false)

  type GroupActions = 'updateRules' | 'insert' | 'update' | 'delete' | ''
  const [groupsAction, setGroupsAction] = React.useState<GroupActions>('')

  /*Expanded*/
  const [currentExpanded, setCurrentExpanded] = React.useState([])

  /*selectedRowIds*/
  const [selectedRowIds, setCurrentSelectedRowIds] = React.useState({})

  /*pageIndex*/
  const [pageIndex, setPageIndex] = React.useState(0)

  const profile = useSelector((state: RootStore) => state.login.perfil)
  const specialProfiles = ['dev', 'adm', 'sup']

  const empresa = useSelector((state: RootStore) => state.login.empresa)
  const autoUpdate = useSelector((state: RootStore) => state.tables.byId[table]?.autoUpdate)
  const showModal = useSelector((state: RootStore) => state.tables.byId[table]?.showModal)
  const showInput = useSelector((state: RootStore) => state.tables.byId[table]?.showInput)
  let match = useRouteMatch()
  const reportId = match?.params?.id
  const preferencesColumns = useObjSelector((state: RootStore) =>
    table === 'reports'
      ? state.login.preferences.routesPanels.byId.reports?.reportsById?.[reportId]?.hiddenColumns
      : state.login.preferences.routesPanels.byId?.[table]?.hiddenColumns
  )

  const [addOpen, setAddOpen] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [confirmText, setConfirmText] = React.useState('Texto explicando o que acontece ao executar essa ação.')
  const [confirmAction, setConfirmAction] = React.useState('')
  const [newClick, setNewClick] = React.useState(true)

  interface CustomTableOptions<D extends object> extends TableOptions<D> {
    autoResetExpanded?: boolean
  }
  const allTableProps: any = useTable(
    {
      columns,
      data,
      autoResetExpanded: false,
      autoResetSelectedRows: true,
      autoResetSortBy: false,
      initialState: {
        pageIndex,
        pageSize: 30,
        hiddenColumns: preferencesColumns || hiddenColumns,
        ...(initialSortBy && { sortBy: [initialSortBy] }),
        expanded: currentExpanded || [],
        groupBy: currentGroup || [],
        globalFilter: value,
        selectedRowIds,
      },
    } as CustomTableOptions<{ [key: string]: any }>,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      // only show true id column to certain profiles
      if (!specialProfiles.includes(profile.toLocaleLowerCase())) {
        columns.forEach((c: { [key: string]: any }, index: number) => {
          if (c.Header === 'id') {
            columns.splice(index, 1)
          }
        })
      }

      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: (useRowSelect: { [key: string]: any }) => (
            <div>
              <IndeterminateCheckbox {...useRowSelect.getToggleAllRowsSelectedProps({ title: t('SelectAllRows') })} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: { [key: string]: any }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps({ title: t('SelectRow') })} />
            </div>
          ),
          // aggregate:'count',Aggregated:({value})=>`${value} selecionados`, canGroupBy: true
        },
        ...columns,
      ])
    }
  )

  useEffect(() => {
    const selectedRowsLen = Object.keys(allTableProps.state.selectedRowIds).length
    if (selectedRowsLen > 0 && autoUpdate) dispatch(setAutoUpdate(table, false))
    if (!autoUpdate && selectedRowsLen === 0 && !showInput && !showModal) dispatch(setAutoUpdate(table, true))
  }, [autoUpdate, allTableProps.state.selectedRowIds, dispatch, table, showInput, showModal])

  useEffect(() => {
    setCurrentSelectedRowIds(allTableProps.state.selectedRowIds)
  }, [allTableProps.state.selectedRowIds])

  useEffect(() => {
    if (allTableProps.state.sortBy?.[0]?.id) {
      setPageIndex(0)
      setSortByProp &&
        typeof allTableProps.state.sortBy?.[0]?.id === 'string' &&
        setSortByProp(allTableProps.state.sortBy[0])
    }
  }, [allTableProps.state.sortBy, setSortByProp])

  useEffect(() => {
    const currentGroup = allTableProps.state.groupBy
    if (currentGroup) setPageIndex(0)
    if (setCurrentGroup && currentGroup) setCurrentGroup(allTableProps.state.groupBy)
    setGroupByProp && currentGroup && setGroupByProp(allTableProps.state.groupBy)
  }, [allTableProps.state.groupBy, setCurrentGroup, setGroupByProp])

  useEffect(() => {
    const currentExp = allTableProps.state.expanded
    if (setCurrentExpanded && currentExp) setCurrentExpanded(currentExp)
  }, [allTableProps.state.expanded])

  useEffect(() => {
    const pageIndex = allTableProps.state.pageIndex
    setPageIndex(pageIndex || 0)
  }, [allTableProps.state.pageIndex])

  useEffect(() => {
    if (preferencesColumns) {
      allTableProps.setHiddenColumns(preferencesColumns)
    }
  }, [preferencesColumns, allTableProps])

  const handleIds = useMemo(
    () => () => {
      let ids: string[] = []
      allTableProps.selectedFlatRows.forEach((d: any) => {
        if (d.isGrouped && !d.isExpanded)
          ids = [...ids, ...d.subRows.map((sr: any) => sr?.original?.id || sr?.original?.sk)]
        // if(d.isGrouped && d.isExpanded) //faz nada
        if (!d.isGrouped) ids = [...ids, d?.original?.id || d?.original?.sk]
      })
      return ids
    },
    [allTableProps.selectedFlatRows]
  )

  const handleLabels = useMemo(
    () => () => {
      let ids: string[] = []
      allTableProps.selectedFlatRows.forEach((d: any) => {
        if (d.isGrouped && !d.isExpanded)
          ids = [
            ...ids,
            ...d.subRows.map(
              (sr: any) =>
                sr?.original?.info?.label ||
                sr?.original?.info?.properties?.label ||
                sr?.original?.name ||
                sr?.original?.label
            ),
          ]
        // if(d.isGrouped && d.isExpanded) //faz nada
        if (!d.isGrouped)
          ids = [
            ...ids,
            d?.original?.info?.label || d?.original?.info?.properties?.label || d?.original?.name || d?.original?.label,
          ]
      })
      return ids
    },
    [allTableProps.selectedFlatRows]
  )

  return (
    <Fragment>
      <Modal.Header style={{ padding: '0', height: '10vh' }}>
        <ReactTableTemplateHeader
          cudLoading={cudLoading}
          cudSuccess={cudSuccess}
          FormModal={FormModal}
          insertAction={insertAction}
          updateAction={updateAction}
          deleteGroupAction={deleteGroupAction}
          handleLabels={handleLabels}
          handleIds={handleIds}
          /*Sets */
          setAddOpen={setAddOpen}
          setColumnsOpen={setColumnsOpen}
          setConfirmAction={setConfirmAction}
          setConfirmOpen={setConfirmOpen}
          setConfirmText={setConfirmText}
          setGroupsAction={setGroupsAction}
          setGroupsOpen={setGroupsOpen}
          setNewClick={setNewClick}
          setValue={setValue}
          /* -- End of sets -- */
          addOpen={addOpen}
          confirmAction={confirmAction}
          confirmOpen={confirmOpen}
          confirmText={confirmText}
          typeName={typeName}
          menuButtons={menuButtons}
          columns={columns}
          data={data}
          empresa={empresa}
          dashboard={dashboard}
          dashboardId={dashboardId}
          dashboardOptions={dashboardOptions}
          insightActions={insightActions}
          insightActionsValues={insightActionsValues}
          // for the IME
          value={value}
          fetchAction={fetchAction}
          // the variables below are from the base interface
          tableLabel={tableLabel}
          table={table}
          deleteAction={deleteAction}
          allTableProps={allTableProps}
          newClick={newClick}
          newItemClick={newItemClick}
          onEditClick={onEditClick}
          onLabelsClick={onLabelsClick}
          onLabelingsClick={onLabelingsClick}
          setCurrentSelectedRowIds={setCurrentSelectedRowIds}
          setProp={setProp}
          onSave={onSave}
          onFilterClick={onFilterClick}
        />
      </Modal.Header>
      <Modal.Content>
        <ReactTableTemplateContent
          showTable={showTable}
          showPlaceholders={showPlaceholders}
          groupAction={groupAction}
          // ReactTableTemplate states
          columnsOpen={columnsOpen}
          groupsAction={groupsAction}
          groupsOpen={groupsOpen}
          // the variables below are from the base interface
          table={table}
          deleteAction={deleteAction}
          // set ReactTableTemplate states
          setColumnsOpen={setColumnsOpen}
          setGroupsAction={setGroupsAction}
          setGroupsOpen={setGroupsOpen}
          // has a lot of variables
          allTableProps={allTableProps}
          setHiddenColumnsProp={setHiddenColumnsProp}
        />
      </Modal.Content>
    </Fragment>
  )
}

ReactTableTemplate.whyDidYouRender = true

export default React.memo(ReactTableTemplate)
