import produce from 'immer'
import _ from 'lodash'

/**
 * This function performs log denormalization by transforming the provided log object.
 *
 * @param log - The log object that contains various properties that need to be denormalized.
 *
 * The function:
 * - Converts `log.properties.customFields` to a new structure with labels instead of IDs.
 *   - Excludes fields of type 'images'.
 * - Maps `log.profile` to `log.profileInfo.name`.
 * - Reassigns the `properties` field with a focus on the `customFields`.
 * - Unpacks tag key-value pairs from `log.properties.tags` into the draft object, prefixed with '#'.
 * - Computes the length of `customFields` if available.
 */

export default function logDenormalization(log: any) {
  return produce(log, (draft: any) => {
    if (log?.properties?.customFields) {
      const customFieldsById = _.keyBy(log.profileInfo?.customFields, 'id')
      draft.properties.customFields = Object.entries(log.properties.customFields).reduce(
        (acc: any, [cfId, cfValue]) => {
          const cf = customFieldsById?.[cfId]
          if (cf?.type !== 'images' && cf?.label) {
            acc[customFieldsById?.[cfId]?.label] = cfValue
          }
          return acc
        },
        {}
      )
    }
    if (log?.profile) {
      draft.profile = log.profileInfo?.name
    }
    if (log?.properties) {
      draft.properties = {
        customFields: draft.properties.customFields,
      }
      if (log.properties.tags) {
        const tagEntries = Object.entries(log.properties.tags)
        tagEntries.forEach(([tagKey, tagValue]) => {
          draft[`#${tagKey}`] = tagValue
        })
      }
    }
    if (log?.customFields) {
      draft.customFields = log.customFields?.length
    }
  })
}
