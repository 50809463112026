import React, { useState, useEffect } from 'react'
import {
  LineChart as LineGraph,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from 'recharts'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import m from 'moment'
import { Button, Divider, Dropdown, Grid, Header, Popup, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import _ from 'lodash'
import { setSelectedCard } from 'Store/actions/dashboards-action'

const GridHeader = styled(Grid)`
  margin-top: 40px !important;
`
const style = {
  borderRadius: 0,
  opacity: 0.7,
  padding: '2em',
}

const RulesLineChart = () => {
  const dispatch = useDispatch()

  const data = useSelector((state) => state.dashboards.byId['rules']?.byTime)
  const rules = useSelector((state) => state.rules.byId, shallowEqual)
  const timeAgo = useSelector((state) => state.dashboards.timeAgo)
  const selected = useSelector((state) => state.dashboards.byId['rules'].selected)

  const [dataState, setDataState] = useState([])

  const [rulesOptions, setRulesOptions] = useState([])

  const [showChart, setShowChart] = useState(timeAgo === 'realTime' ? false : true)

  useEffect(() => {
    if (data) {
      let newDataState = {}
      setRulesOptions(
        _.uniq(Object.keys(data).concat(selected))
          .map((eachKey) => {
            return {
              key: eachKey,
              text: rules[eachKey]?.name,
              value: eachKey,
            }
          })
          .filter((f) => f.text)
      )
      Object.entries(data)
        .filter((e) => {
          return selected.includes(e[0])
        })
        .forEach((each) => {
          const ruleCount = each[1].timeWindows
          Object.entries(ruleCount).forEach((eachTime) => {
            const formatTime = m(eachTime[0]).format('DD/MM HH:mm')
            if (newDataState[formatTime]) {
              newDataState[formatTime].contagem += eachTime[1]
            } else {
              newDataState[formatTime] = {
                time: eachTime[0],
                formatTime,
                contagem: eachTime[1],
              }
            }
          })
        })
      setDataState(Object.values(newDataState).sort((a, b) => a.time.localeCompare(b.time)))
    }
  }, [data, rules, selected])

  return (
    <>
      <Grid columns="equal">
        <Grid.Column verticalAlign="bottom">
          <Header as="h3" onClick={() => setShowChart(!showChart)} style={{ cursor: 'pointer' }}>
            <Icon name={showChart ? 'angle down' : 'angle right'} size="mini" />
            {`Gráfico - ${timeAgo === 'realTime' ? 'Histórico' : timeAgo === '1d' ? 'Último Dia' : 'Última Semana'}`}
          </Header>
        </Grid.Column>
        <Grid.Column width="13">
          <Divider horizontal>
            <Header as="h4">
              <Icon name="chart line" />
            </Header>
          </Divider>
        </Grid.Column>
      </Grid>
      {showChart && data && Object.values(data).length > 0 && timeAgo !== 'realTime' ? (
        <div style={{ width: '100%', height: '600px', paddingTop: '15px' }}>
          <GridHeader fluid columns={2}>
            <Grid.Column floated="left" width={15}>
              <Dropdown
                placeholder="Regras"
                multiple
                selection
                options={rulesOptions}
                onChange={(c, { value }) => dispatch(setSelectedCard('rules', value))}
                value={selected}
              />
            </Grid.Column>
            <Grid.Column floated="right" width={1}>
              <Popup
                trigger={
                  <Button
                    icon={{ name: 'info circle', style: { paddingLeft: '0px' } }}
                    style={{ float: 'right' }}
                    size="tiny"
                  />
                }
                content="Contagem de Pessoas ou Ativos que estiveram no(s) Perímetro(s) em cada Período indicado."
                style={style}
                inverted
                position="left center"
              />
            </Grid.Column>
          </GridHeader>
          <ResponsiveContainer width="100%" height="70%">
            <LineGraph
              width={500}
              height={300}
              data={dataState}
              margin={{
                top: 45,
                right: 30,
                left: 10,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="formatTime" minTickGap={15} />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: '400px' }} />
              <Brush data={dataState} dataKey="formatTime" height={30} stroke="#4b72b2" />
              <Line type="monotone" dataKey="contagem" stroke="#4b72b2" fill="#4b72b2" />
            </LineGraph>
          </ResponsiveContainer>
        </div>
      ) : (
        showChart && (
          <>
            <br />
            <Header size="small">Não há Dados para o Período Selecionado</Header>
          </>
        )
      )}
    </>
  )
}

export default RulesLineChart
