import React, { useEffect, useState, useCallback } from 'react'
import { useSelector /*, useDispatch */ } from 'react-redux'
import GraphArea from './GraphArea'
import GraphBars from './GraphBars'
import GraphLine from './GraphLine'
import GraphPie from './GraphPie'
import GraphDonut from './GraphDonut'
import GraphRadar from './GraphRadar'
import GraphKPI from './GraphKPI'
import Loading from 'Utils/components/Loading'
import { colors, lightColors, semanticColors } from 'Utils/colorsFormat.js'
import { useTranslation } from 'react-i18next'
import { GraphFallback } from './GraphFallback'
import { max } from 'lodash'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import fetchDashboardPanelContent from 'Utils/fetchDashboardPanelContent'

const REFRESH_INTERVAL = 60000

const DashboardPanelContent = ({ panelId, panelPrefs, layoutStats, setGood }) => {
  let delayInterval = REFRESH_INTERVAL + parseInt(panelId.replace('panel', '')) * 1000
  const { t } = useTranslation()
  const tenant = useSelector((state) => state.login.empresa)
  const panel = useObjSelector((state) => state.dashboards.byId['dashboard']?.pannelsById?.[panelId])

  const reportConfig = useObjSelector((state) => state.modules.reports.byId[panelPrefs.resource])

  const defineColor = (color) =>
    ['Tiplam', 'PhygitallDevs', 'Logicalis'].includes(tenant) ? lightColors(color) : semanticColors(color)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [data, setData] = useState(panel?.data?.map((d, i) => ({ ...d, fill: defineColor(colors[i % 12]) })))
  const [distinctValues, setDistinctValues] = useState([])
  const [maxValue, setMaxValue] = useState(0)

  let scope = reportConfig?.info?.scope
  let filterType = reportConfig?.info?.filterType
  let filters = reportConfig?.info?.filters

  const fetchData = useCallback(
    async (showLoading) => {
      setError(false)
      const defineColor2 = (color) =>
        ['Tiplam', 'PhygitallDevs', 'Logicalis'].includes(tenant) ? lightColors(color) : semanticColors(color)

      let params = {
        time: panelPrefs.time || 'rtm',
        filters: { ...filters, ...panelPrefs.filters },
      }
      if (!scope) return
      params.scope = scope
      params.filterType = filterType
      params.aggregate = panelPrefs.custom?.aggregation
      params.grouping = panelPrefs.custom?.grouping
      params.panelType = panelPrefs?.panelType
      if (params.time === 'abs') {
        params.startTime = panelPrefs.startTime.replace('T', ' ') + ':00'
        params.endTime = panelPrefs.endTimeNow
          ? new Date().toISOString().replace('T', ' ').slice(0, -5)
          : panelPrefs.endTime.replace('T', ' ') + ':00'
      } else {
        params.relativeTime = panelPrefs.timeRelative || '1d'
      }
      try {
        if (showLoading) setLoading(true)

        let res = await fetchDashboardPanelContent({ scope, params, panelPrefs })

        if (panelPrefs.resource === '30261' && panelPrefs?.panelType !== 'kpi' && res.result?.[0]) {
          const header = res.result[0]
          const resData = res?.result?.filter((_, i) => i !== 0)

          setDistinctValues(
            header.reduce((acc, current, i) => {
              if (i === 0) return []
              return [...acc, { name: current, color: defineColor2(colors[i % 12]) }]
            }, [])
          )

          setData(
            resData.reduce((acc, current) => {
              const rowObj = header.reduce((obj, each, index) => {
                return {
                  ...obj,
                  // Eixo X é definido como 'name' nos componentes que renderizam os gráficos
                  [index === 0 ? 'name' : each]: current[index],
                }
              }, {})
              return [...acc, rowObj]
            }, [])
          )
        } else {
          if (typeof res.result === 'string' && panelPrefs?.panelType === 'kpi')
            setData([{ value: parseFloat(res.result) }])
          else {
            let onlyData = res?.result?.filter((rd, i) => i !== 0)
            let onlyValue = onlyData.map((d) => parseInt(res.distinct ? d[2] : d[1]))
            setMaxValue(max(onlyValue))
            if (res.distinct && ['area', 'line', 'bars', 'radar'].includes(panelPrefs.type)) {
              setDistinctValues(res.distinct.map((dis, j) => ({ name: dis, color: defineColor2(colors[j % 12]) })))
              let multiData = []
              let multiCol = {}
              onlyData.forEach((d, i) => {
                /* if it's the first time set a name to the column*/
                if (!multiCol.name) multiCol = { name: d[0] }
                /* If it isn't the last one with this value add it in the column*/
                multiCol[d[1]] = d[2]
                if (i === onlyData.length - 1 || d[0] !== onlyData[i + 1][0]) {
                  multiData.push(multiCol)
                  multiCol = {}
                }
              })
              setData(multiData)
            } else {
              setDistinctValues([])
              setData(
                onlyData
                  ?.map((d, i) => ({ name: d[0], value: d[d.length - 1], fill: defineColor2(colors[i % 12]) }))
                  .sort((a, b) => b.value - a.value)
              )
            }
          }
        }
        setLoading(false)
        return res
      } catch (err) {
        console.error(err)
        setError(true)
      }
      // ...
    },
    [panelPrefs, setData, setMaxValue, setDistinctValues, setLoading, tenant, scope, filterType, filters]
  )

  useEffect(() => {
    if (panelPrefs?.resource !== 'kpis') {
      fetchData(true)
      /*Só vai ficar atualizando o painel se for Tempo Real ou Se for tempo Definido sem Tempo Final (ou seja de um tempo definido inicial até "agora" (tempo real))*/
      if (['rtm', 'rel'].includes(panelPrefs?.time) || (panelPrefs?.time === 'abs' && panelPrefs?.endTimeNow)) {
        const id = setInterval(() => {
          fetchData(false)
        }, delayInterval)
        return () => clearInterval(id)
      }
    } else setData([])
  }, [fetchData, panelPrefs.resource, panelPrefs.time, panelPrefs.endTimeNow, setData, delayInterval])
  if (!scope)
    return (
      <div style={stylesCard}>
        <span>Configure</span>{' '}
      </div>
    )

  if (error) return <GraphFallback retry={fetchData} />
  if (loading)
    return (
      <div style={{ paddingTop: '18px' }}>
        <Loading />
      </div>
    )

  panelPrefs?.resource === '30261' && console.log('data', data)
  if (!data || data.length === 0) {
    return (
      <div style={stylesCard}>
        {' '}
        <span>{t('NoData')}</span>{' '}
      </div>
    )
  }

  const content = () => {
    if (panelPrefs?.panelType === 'kpi')
      return (
        <GraphKPI
          data={data}
          layoutStats={layoutStats}
          kpiFormat={panelPrefs.kpiFormat}
          meta={panelPrefs.meta || panelPrefs.meta === 0 ? { val: panelPrefs.meta, comp: panelPrefs.metaComp } : null}
          setGood={setGood}
          resource={panelPrefs.resource}
        />
      )
    else {
      switch (panelPrefs.type) {
        case 'area':
          return <GraphArea data={data} distinct={distinctValues} maxValue={maxValue} />
        case 'donut':
          return <GraphDonut data={data} layoutStats={layoutStats} />
        case 'pie':
          return <GraphPie data={data} />
        case 'bars':
          return <GraphBars data={data} distinct={distinctValues} maxValue={maxValue} />
        case 'line':
          return <GraphLine data={data} distinct={distinctValues} maxValue={maxValue} />
        case 'radar':
          return <GraphRadar data={data} distinct={distinctValues} maxValue={maxValue} />
        default:
          return <GraphBars data={data} distinct={distinctValues} maxValue={maxValue} />
      }
    }
  }

  //let data = rawData?.filter((rd,i)=>i!==0)?.map((d,i)=>({name:d[0],value:d[1],fill:defineColor(colors[i])}))
  //panel.data.map((d,i)=>({...d,fill:defineColor(colors[i])}))

  return content()
}

const stylesCard = {
  width: '100%',
  textAlign: 'center',
  minHeight: '100%',
  paddingTop: '35px',
  fontSize: '35px',
  lineHeight: '40px',
  color: '#666',
}

export default DashboardPanelContent
