import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import L from 'leaflet'
import { useDispatch } from 'react-redux'
import { updateWiP } from 'Store/actions/modules-action'
//import { setTrue } from 'Store/actions/toggle-action'

export const EditingModule = ({ editId, dataOnClick, refMap, moduleType }) => {
  const dispatch = useDispatch()
  let type = dataOnClick?.layer?.toGeoJSON()?.geometry?.type
  const editMode = useSelector((state) => state.modules[moduleType].geoWip.editMode)
  const [layerId, setLayerId] = useState()
  let geojsonlayer
  let map = refMap
  if (editMode && dataOnClick && !layerId) {
    if (type === 'LineString') {
      geojsonlayer = L.polyline(dataOnClick.layer._latlngs)
    } else if (type === 'Polygon') {
      geojsonlayer = L.polygon(dataOnClick.layer._latlngs)
    } else {
      geojsonlayer = L.circle(dataOnClick.layer._latlng, dataOnClick.layer._mRadius)
    }
    setLayerId(geojsonlayer)
    geojsonlayer.addTo(map)
    geojsonlayer.options.editing || (geojsonlayer.options.editing = {})
    geojsonlayer.editing.enable()
    geojsonlayer.on('edit', function (e) {
      let geoData = e.target.toGeoJSON()
      dispatch(
        updateWiP({
          moduleType: moduleType,
          id: editId,
          editMode: false,
          geometry: geoData.geometry,
          mradius: e.target._mRadius,
        })
      )
    })
  }

  useEffect(() => {
    return () => {
      map.removeLayer(layerId)
    }
  }, [layerId, map])
  return <></>
}
