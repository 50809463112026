import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Marker, FeatureGroup, Tooltip } from 'react-leaflet'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { icones } from 'Resources/Icons/iconsLeaflet'
import { toggle, setTrue } from 'Store/actions/toggle-action'
import { setCurrentStatsBar } from 'Store/actions/system-action'
import { updateWiP, setCurrentModule, initiateModuleUpdate } from 'Store/actions/modules-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import _ from 'lodash'
import getDisplayName from 'Utils/getDisplayName'

/*  Author: Bruno Melo
    Type: Smart
    Description: Render Marker from References
    To do list: 
    - 			
*/

const RenderRefMarker = ({ moduleId }) => {
  const moduleType = 'refs'
  const dispatch = useDispatch()
  const color = useSelector((state) => state.modules[moduleType]?.byId[moduleId]?.properties?.color)
  const statsbarId = useSelector((state) => state?.system?.statsbar?.id)
  const wipId = useSelector((state) => state.modules[moduleType]?.geoWip?.id)

  const geo = useSelector((state) => {
    const wipGeo = state.modules.wip?.geometry
    const currGeo = state.modules[moduleType]?.byId[moduleId]?.geometry

    return wipId === moduleId && wipGeo !== undefined ? wipGeo : currGeo
  })

  const lat = useSelector((state) => {
    const wipLat = state.modules.wip?.geometry?.coordinates?.[1]
    const currLat = state.modules[moduleType]?.byId[moduleId]?.geometry?.coordinates[1]

    return wipId === moduleId && wipLat !== undefined ? wipLat : currLat
  })

  const lng = useSelector((state) => {
    const wipLng = state.modules.wip?.geometry?.coordinates?.[0]
    const currLng = state.modules[moduleType]?.byId[moduleId]?.geometry?.coordinates[0]

    return wipId === moduleId && wipLng !== undefined ? wipLng : currLng
  })

  const inner = useSelector((state) => state.toggle.showInnerMap)
  const currentInner = useSelector((state) => state.indoor.current?.inner)
  const tenant = useSelector((state) => state.login.empresa)

  const markerClick = () => {
    //activate the toggle
    if (moduleId === statsbarId && !editionMode) {
      dispatch(toggle('showStatsBar'))
      setPermanentState(false)
    } else {
      dispatch(setTrue('showStatsBar'))
    }
    if (wipId !== moduleId) {
      dispatch(initiateModuleUpdate({ id: moduleId, type: moduleType }))
    }
    dispatch(setCurrentStatsBar(null, 'modules', moduleType))
    dispatch(setCurrentStatsBar(moduleId, 'modules', moduleType))
    dispatch(setCurrentModule(moduleId, moduleType))
    //alert('Aqui eu abro o sidebar do Módulo com id ' + moduleId)
  }
  const moduleLabel = useSelector((state) => state.modules[moduleType]?.byId[moduleId]?.label)
  const moduleCode = useSelector((state) => state.modules[moduleType]?.byId[moduleId]?.code)
  const moduleProfileId = useSelector((state) => state.modules[moduleType]?.byId[moduleId]?.profile)
  const moduleProfile = useObjSelector((state) => state.profiles?.byId[moduleProfileId])

  const profileName = moduleProfile?.name

  const tooltipText = getDisplayName({ moduleLabel, moduleCode, profileName })
  let position = inner ? innerPosition([lng, lat], 'ref', currentInner?.center, currentInner?.bearing) : { lat, lng }

  const state = useSelector((state) => state.modules[moduleType]?.byId[moduleId]?.state)

  const dragEnd = (data) => {
    dispatch(
      updateWiP({
        moduleType: moduleType,
        id: moduleId,
        geometry: { ...geo, coordinates: [_.round(data.target._latlng.lng, 6), _.round(data.target._latlng.lat, 6)] },
      })
    )
  }

  //Tooltip custom (it shows Selected, it shows when editing)
  const editionMode = useSelector((state) => state.modules[moduleType]?.geoWip?.editMode)
  const idTooltipText = tooltipText
  const [tooltipState, setTooltipState] = useState(idTooltipText)
  const [permanentState, setPermanentState] = useState(false)

  useEffect(() => {
    if (moduleId === statsbarId) {
      setPermanentState(true)
      if (editionMode) setTooltipState(`(Editando) ` + idTooltipText)
      else setTooltipState(idTooltipText)
    } else setPermanentState(false)
  }, [moduleId, statsbarId, editionMode, idTooltipText])
  const preferences = useSelector((state) => state.login.preferences[moduleType][moduleId])
  if (!geo) return null
  if (!position?.lat || typeof position.lat !== 'number') return null

  return (
    (preferences || state !== 'closed') && (
      <FeatureGroup key={'refFeatureGroup' + moduleId} onClick={() => markerClick()}>
        <Marker
          key={'refMarkerGroup' + moduleId}
          position={position}
          icon={icones({
            color: moduleProfile?.color ? moduleProfile.color : color ? color : 'black',
            icon: moduleProfile?.icon ? moduleProfile.icon : null,
            tenant: tenant,
          })}
          draggable={moduleId === wipId && editionMode === true}
          ondragend={dragEnd}
          onmouseover={() => setPermanentState(true)}
          onmouseout={() => moduleId !== statsbarId && setPermanentState(false)}
          opacity={state === 'closed' ? 0.4 : 1}
        >
          <Tooltip permanent={permanentState} direction="bottom">
            {tooltipState}
          </Tooltip>
        </Marker>
      </FeatureGroup>
    )
  )
}

export default memo(RenderRefMarker)
