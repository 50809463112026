import React from 'react'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Message } from 'semantic-ui-react'
import EntityDropdownField from 'Components/forms/generic/EntityDropdownField'
import GenericAssetsPropsFields from 'Components/forms/generic/GenericAssetsPropsFields'
import GenericCategoriesMeasuresFields from 'Components/forms/generic/GenericCategoriesMeasuresFields'
import DropdownField from 'Components/forms/generic/DropdownField'
import { setBetaDashboardProps, setPanelProp } from 'Store/actions/betaDashboards-action'
import CustomFieldFilter from 'Utils/components/CustomFieldFilter'
import RuleFilters from 'features/filters/components/RuleFilters'

type Props = {
  panelId: string
}

function PanelStepTwoForm({ panelId }: Props) {
  const dispatch = useDispatch()

  const showFeedback = useObjSelector((state: RootStore) => state.betaDashboards.form?.steps.items[1]?.showFeedback)

  const errors = useObjSelector((state: RootStore) => state.betaDashboards.form?.errors?.[1])

  const scope = useSelector(
    (state: RootStore) =>
      state.betaDashboards.wip?.panels?.[panelId]?.scope || state.betaDashboards.current?.panels?.[panelId]?.scope
  )

  const category = useSelector(
    (state: RootStore) =>
      state.betaDashboards.wip?.panels?.[panelId]?.filters?.category ||
      state.betaDashboards.current?.panels?.[panelId]?.filters?.category
  )

  const fport: any = useSelector(
    (state: RootStore) =>
      state.betaDashboards.wip?.panels?.[panelId]?.filters?.fport ||
      state.betaDashboards.current?.panels?.[panelId]?.filters?.fport
  )

  const devices: any = useSelector(
    (state: RootStore) =>
      state.betaDashboards.wip?.panels?.[panelId]?.filters?.devices ||
      state.betaDashboards.current?.panels?.[panelId]?.filters?.devices
  )

  const profileId: any = useSelector(
    (state: RootStore) =>
      state.betaDashboards.wip?.panels?.[panelId]?.filters?.profileId ||
      state.betaDashboards.current?.panels?.[panelId]?.filters?.profileId
  )

  const customField: any = useSelector(
    (state: RootStore) =>
      state.betaDashboards.wip?.panels?.[panelId]?.filters?.customField ||
      state.betaDashboards.current?.panels?.[panelId]?.filters?.customField
  )

  switch (scope) {
    case 'measures':
      return (
        <Form>
          <EntityDropdownField
            entity="devices"
            label="Dispositivo(s)"
            placeholder="Selecione o(s) dispositivo(s)"
            onChange={(value: any) => {
              dispatch(setBetaDashboardProps([['wip.filters.devices', value]]))
            }}
            prefix={'serial_number'}
            value={devices}
            multiple
            clearable
          />
          <GenericAssetsPropsFields
            setProps={(field = '', value: any) => dispatch(setBetaDashboardProps([[field, value]]))}
          />
          <br />
          <GenericCategoriesMeasuresFields
            setProps={(field = '', value: any) => dispatch(setBetaDashboardProps([[field, value]]))}
          />
          {errors?.filters && showFeedback && (
            <Message negative style={{ marginTop: '30px' }}>
              <p>Selecione todos os campos obrigatórios</p>
            </Message>
          )}
          {Array.isArray(category) && category?.length > 1 && (
            <DropdownField
              label="Fport"
              placeholder="Digite o(s) Fport(s)"
              multiple
              search
              allowAdditions
              additionLabel="Adicionar"
              onChange={(_, { value }) => {
                dispatch(setBetaDashboardProps([['wip.filters.fport', value]]))
              }}
              options={fport ? fport?.map((e: any) => ({ key: e, text: e, value: e })) : []}
              value={fport as (string | number)[]}
              noResultsMessage={fport && fport.length > 0 ? 'Digite o(s) Fport(s)' : ''}
            />
          )}
        </Form>
      )

    case 'references':
      return (
        <Form>
          <EntityDropdownField
            entity="profiles"
            label="Atribuição"
            placeholder="Selecione a Atribuição"
            onChange={(value: any) => {
              dispatch(setPanelProp({ panelId, field: 'filters.profileId', value }))
            }}
            value={profileId}
            clearable
            filter={(value) => value.type === 'refs'}
            required
            showFeedback={showFeedback}
            error={!!errors?.filters}
          />
          <CustomFieldFilter
            profileId={profileId}
            id={customField?.id}
            value={customField?.value}
            setState={({ id, value }: any) => {
              if (id) dispatch(setPanelProp({ panelId, field: `filters.customField`, value: { id, value } }))
              else dispatch(setPanelProp({ panelId, field: `filters.customField`, value: undefined }))
            }}
          />
        </Form>
      )

    default:
      return (
        <RuleFilters
          selectAssets={(state) =>
            state.betaDashboards.wip?.panels?.[panelId]?.filters?.selectedAssets ||
            state.betaDashboards.current?.panels?.[panelId]?.filters?.selectedAssets
          }
          selectRules={(state) =>
            state.betaDashboards.wip?.panels?.[panelId]?.filters?.selectedRules ||
            state.betaDashboards.current?.panels?.[panelId]?.filters?.selectedRules
          }
          selectFences={(state) =>
            state.betaDashboards.wip?.panels?.[panelId]?.filters?.fences ||
            state.betaDashboards.current?.panels?.[panelId]?.filters?.fences
          }
          selectShowFeedback={(state) => state.betaDashboards.form?.steps.items[1].showFeedback}
          selectErrors={(state) => state.betaDashboards.form?.errors?.[1]}
          setProp={(field = '', value: any) => {
            console.log('field', field)
            const fieldFormat = field.split('wip.')?.[1]
            dispatch(setPanelProp({ panelId, field: fieldFormat, value }))
          }}
        />
      )
  }
}

export default PanelStepTwoForm
