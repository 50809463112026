import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'
import TimeAbsolute from 'Utils/components/TimeAbsolute'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { infoField } from 'Components/Map/PopupMarker/jsonGroup'

/*  Author: Felipe Pegado / Luís Mourão
    Type: dumb
	Description: Show the group name and the group data time;				 
  To do list: -				
*/

const HeaderRow = styled(Grid.Row)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`
const HeaderTitle = styled.span`
  font-weight: bold;
`
const PopupInfoHeader = ({ assetId, mode }) => {
  const { t } = useTranslation()

  const currentCategory = useSelector((state) => state[mode].byId[assetId]?.currentCategory)
  const categoryMeasuresStr = useSelector((state) =>
    JSON.stringify(state[mode].byId[assetId]?.info?.[currentCategory] || {})
  )
  const categoryMeasures = JSON.parse(categoryMeasuresStr)

  let infoFields = categoryMeasures && infoField(categoryMeasures, t, currentCategory)
  const time = (infoFields || []).find((e) => ['time', 'Hora', 'hora', 'tempo'].includes(e?.campo))?.title

  return (
    <Grid columns={2}>
      <HeaderRow key="header">
        {currentCategory ? (
          <Fragment>
            <Grid.Column width={6}>
              <HeaderTitle>{t(currentCategory)}</HeaderTitle>
            </Grid.Column>
            <Grid.Column width={10}>{time && <TimeAbsolute date={time} style={{ float: 'right' }} />}</Grid.Column>
          </Fragment>
        ) : (
          <span>{t('selectTabPopup')}</span>
        )}
      </HeaderRow>
    </Grid>
  )
}

export default PopupInfoHeader
