function simpleHash(str = '') {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

function seededRandom(key = '', min = 0, max = 1) {
  const SEED = simpleHash(key)
  const random = Math.abs(SEED % (max - min)) + min
  return random
}

export default seededRandom
