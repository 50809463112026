import { Dashboard } from 'interfaces/dashboards'

export const parseDashboard = (payload: Dashboard) => {
  return {
    ...payload,
    panels: typeof payload.panels === 'string' ? JSON.parse(payload.panels) : payload.panels,
  }
}

export default parseDashboard
