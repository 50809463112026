import React, { useCallback } from 'react'
import Search from './Search'
import NavButtons from './NavButtons'
//import { ReactComponent as Logo } from 'Resources/Icons/mini-logo.svg'
import { ReactComponent as LogoEmpresa } from 'Resources/Icons/logo-empresa.svg'
import { useDispatch } from 'react-redux'
import { toggle } from 'Store/actions/toggle-action'
import styled from 'styled-components'
import { Transition, Icon, Menu } from 'semantic-ui-react'
import TenantLogo from './TenantLogo'
import { connect } from 'react-redux'
import LogoPhygitall from '../LogoPhygitall'

const LogoAmbevSvg = styled(LogoEmpresa)`
  fill: #0b4589;
  max-height: 30px;
  max-width: 185px;
`
const NavBar = (Props) => {
  const dispatch = useDispatch()
  const handleClick = useCallback(() => dispatch(toggle('sideBarLeft')), [dispatch])

  return (
    <Menu secondary>
      <Menu.Item position="left">
        <Menu.Item icon>
          <Icon name="bars" size="big" onClick={handleClick} style={{ color: '#4b72b2' }} />
        </Menu.Item>

        {Props.windowWidth > 960 && (
          <Menu.Item>
            <LogoPhygitall />
          </Menu.Item>
        )}

        <Menu.Item>
          <Search isMobileSized={Props.isMobileSized} />
        </Menu.Item>
      </Menu.Item>

      {Props.windowWidth > 1230 && (
        <Menu.Item position="left" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
          <Transition animation={'scale'} duration={5000} transitionOnMount={true}>
            {Props.company !== 'Ambev' ? <TenantLogo tenant={Props.company} perfil={Props.perfil} /> : <LogoAmbevSvg />}
          </Transition>
        </Menu.Item>
      )}

      <Menu.Item position="right">
        <NavButtons {...Props} />
      </Menu.Item>
    </Menu>
  )
}

const mapStateToProps = (state) => ({
  perfil: state.login.perfil,
})

export default connect(mapStateToProps)(NavBar)
