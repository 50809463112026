import React from 'react'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { setReportsProp } from 'Store/actions/reports-action'
import { useDispatch, useSelector } from 'react-redux'
import GenericAssetsPropsFields from '../generic/GenericAssetsPropsFields'
import GenericCategoriesMeasuresFields from '../generic/GenericCategoriesMeasuresFields'
import { Form, Message } from 'semantic-ui-react'
import EntityDropdownField from '../generic/EntityDropdownField'
import DropdownField from '../generic/DropdownField'
import RuleFilters from 'features/filters/components/RuleFilters'

function ReportsStepTwoForm() {
  const dispatch = useDispatch()

  const showFeedbackItems = useObjSelector((state: RootStore) => state.reports.form?.steps.items[1])

  const errors = useObjSelector((state: RootStore) => state.reports.form?.errors?.[1])

  const scope = useSelector((state: RootStore) => state.reports.wip?.scope || state.reports.current?.scope)

  const category = useSelector(
    (state: RootStore) => state.reports.wip?.filters?.category || state.reports.current?.filters?.category
  )

  const fport: any = useSelector(
    (state: RootStore) => state.reports.wip?.filters?.fport || state.reports.current?.filters?.fport
  )

  const devices: any = useSelector(
    (state: RootStore) => state.reports.wip?.filters?.devices || state.reports.current?.filters?.devices
  )

  const profileId: any = useSelector(
    (state: RootStore) => state.reports.wip?.filters?.profileId || state.reports.current?.filters?.profileId
  )

  switch (scope) {
    case 'measures':
      return (
        <Form>
          <EntityDropdownField
            entity="devices"
            label="Dispositivo(s)"
            placeholder="Selecione o(s) dispositivo(s)"
            onChange={(value: any) => {
              dispatch(setReportsProp('wip.filters.devices', value))
            }}
            prefix={'serial_number'}
            value={devices}
            multiple
            clearable
          />
          <GenericAssetsPropsFields setProps={(field = '', value: any) => dispatch(setReportsProp(field, value))} />
          <br />
          <GenericCategoriesMeasuresFields
            setProps={(field = '', value: any) => dispatch(setReportsProp(field, value))}
          />
          {errors?.filters && showFeedbackItems.showFeedback && (
            <Message negative style={{ marginTop: '30px' }}>
              <p>Selecione todos os campos obrigatórios</p>
            </Message>
          )}
          {Array.isArray(category) && category?.length > 1 && (
            <DropdownField
              label="Fport"
              placeholder="Digite o(s) Fport(s)"
              multiple
              search
              allowAdditions
              additionLabel="Adicionar"
              onChange={(_, { value }) => {
                dispatch(setReportsProp('wip.filters.fport', value))
              }}
              options={fport ? fport?.map((e: any) => ({ key: e, text: e, value: e })) : []}
              value={fport as (string | number)[]}
              noResultsMessage={fport && fport.length > 0 ? 'Digite o(s) Fport(s)' : ''}
            />
          )}
        </Form>
      )

    case 'references':
      return (
        <Form>
          <EntityDropdownField
            entity="profiles"
            label="Atribuição"
            placeholder="Selecione a Atribuição"
            onChange={(value: any) => {
              dispatch(setReportsProp('wip.filters.profileId', value))
            }}
            value={profileId}
            clearable
            filter={(value) => value.type === 'refs'}
          />
        </Form>
      )

    default:
      return (
        <RuleFilters
          selectAssets={(state) =>
            state.reports.wip.filters?.selectedAssets || state.reports.current?.filters?.selectedAssets
          }
          selectRules={(state) =>
            state.reports.wip?.filters?.selectedRules || state.reports.current?.filters?.selectedRules
          }
          selectFences={(state) => state.reports.wip?.filters?.fences || state.reports.current?.filters?.fences}
          selectShowFeedback={(state) => state.reports.form?.steps.items[1].showFeedback}
          selectErrors={(state) => state.reports.form?.errors?.[1]}
          setProp={(field = '', value: any) => dispatch(setReportsProp(field, value))}
        />
      )
  }
}

export default ReportsStepTwoForm
