import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button, Header, Modal, Segment } from 'semantic-ui-react'
import { RootStore } from 'Store/initialStore'

const OfflineBanner = () => {
  const offline: boolean = useSelector((state: RootStore) => state.toggle.offline)

  const { t } = useTranslation()
  const attemptRefresh = () => {
    window.location.reload()
  }

  return (
    <Modal open={offline} closeOnDimmerClick={false}>
      <Modal.Header style={{ backgroundColor: 'red' }}>
        <Segment
          id="offlineMode"
          style={{ backgroundColor: 'red', padding: '0' }}
          basic
          inverted
          textAlign="center"
          size="massive"
          onClick={attemptRefresh}
        >
          {t('offline_header_text')}
        </Segment>
      </Modal.Header>

      <Modal.Content>
        <Header>{t('offline_content_title')}</Header>
        <Modal.Description style={{ whiteSpace: 'pre-wrap' }}>
          {t('offline_content_step_0')}
          <ul>
            <li> {t('offline_content_step_1')} </li>
            <li> {t('offline_content_step_2')} </li>
            <li> {t('offline_content_step_3')} </li>
            <li> {t('offline_content_step_4')} </li>
          </ul>
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button fluid color="green" onClick={attemptRefresh} style={{ margin: 'auto' }}>
          {t('offline_refresh_button')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default OfflineBanner
