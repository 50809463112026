import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { RootStore } from 'Store/initialStore'
import DropdownField from 'Components/forms/generic/DropdownField'
import InputField from 'Components/forms/generic/InputField'
import { setBetaDashboardProps, setPanelProp } from 'Store/actions/betaDashboards-action'
import { Form, Radio } from 'semantic-ui-react'
import TextAreaField from 'Components/forms/generic/TextAreaField'
import styled from 'styled-components'

const FormRadioGroup = styled(Form.Group)`
  margin-bottom: 3.5vh !important;
`

type Props = {
  panelId: string
}

function PanelStepOneForm({ panelId }: Props) {
  const dispatch = useDispatch()

  const step = useObjSelector((state) => state.betaDashboards.form.steps?.items[0])

  const errors = useObjSelector((state) => state.betaDashboards.form.errors?.[0])

  const label = useSelector(
    (state: RootStore) =>
      state.betaDashboards?.wip?.panels?.[panelId]?.label ?? state.betaDashboards.current?.panels[panelId]?.label ?? ''
  )
  const scope = useSelector(
    (state: RootStore) =>
      state.betaDashboards?.wip?.panels?.[panelId]?.scope ?? state.betaDashboards.current?.panels[panelId]?.scope ?? ''
  )
  const description = useSelector(
    (state: RootStore) =>
      state.betaDashboards?.wip?.panels?.[panelId]?.description ??
      state.betaDashboards.current?.panels[panelId]?.description ??
      ''
  )
  const type = useSelector(
    (state: RootStore) =>
      state.betaDashboards?.wip?.panels?.[panelId]?.type ?? state.betaDashboards.current?.panels[panelId]?.type ?? ''
  )

  return (
    <>
      <FormRadioGroup inline>
        <label>
          Tipo de Painel <span style={{ color: 'red' }}>*</span>
        </label>
        <Form.Field
          control={Radio}
          label="Gráfico de Barra"
          value="bars"
          checked={type === 'bars'}
          onChange={(_: any, { value }: any) => {
            dispatch(setBetaDashboardProps([[`panelsInfo.byId.${panelId}.result`, []]]))
            dispatch(setPanelProp({ panelId, field: 'type', value }))
          }}
        />
        <Form.Field
          control={Radio}
          label="Gráfico de Pizza"
          value="donut"
          checked={type === 'donut'}
          onChange={(_: any, { value }: any) => {
            dispatch(setBetaDashboardProps([[`panelsInfo.byId.${panelId}.result`, []]]))
            dispatch(setPanelProp({ panelId, field: 'type', value }))
          }}
        />
        <Form.Field
          control={Radio}
          label="Indicador"
          value="kpi"
          checked={type === 'kpi'}
          onChange={(_: any, { value }: any) => {
            dispatch(setBetaDashboardProps([[`panelsInfo.byId.${panelId}.result`, []]]))
            dispatch(setPanelProp({ panelId, field: 'type', value }))
          }}
          error={step.showFeedback && errors?.type}
        />
      </FormRadioGroup>
      <br />
      <Form.Group widths="equal">
        <InputField
          label="Nome"
          value={label}
          onChange={(_, { value }) => {
            dispatch(setPanelProp({ panelId, field: 'label', value }))
          }}
          placeholder="Digite o nome do painel"
        />
        <DropdownField
          label="Contexto"
          name="scope"
          placeholder="Selecione o contexto do relatório"
          value={scope}
          // options={contextOptions}
          options={[
            {
              key: 'references',
              text: 'references',
              value: 'references',
            },
            {
              key: 'rules',
              text: 'rules',
              value: 'rules',
            },
          ]}
          onChange={(_, { value }) => {
            dispatch(setPanelProp({ panelId, field: 'scope', value }))
            if (value === 'measures') {
              dispatch(setPanelProp({ panelId, field: 'filters', value: {} }))
            }
          }}
          required={true}
          showFeedback={step.showFeedback}
          error={errors?.scope}
        />
      </Form.Group>
      <br />
      <TextAreaField
        label="Descrição"
        value={description}
        onChange={(_, { value }) => {
          dispatch(setPanelProp({ panelId, field: 'description', value }))
        }}
        placeholder="Digite uma descrição para o painel"
      />
    </>
  )
}

export default PanelStepOneForm
