import React from 'react'

const style = {
  width: '100%',
  height: '100%',
  marginTop: '100px',
  display: 'inline-block',
}
const title = {
  width: '277px',
  margin: 'auto',
  fontWeight: 'bold',
}
const subtitle = {
  width: '400px',
  margin: 'auto',
}

const AppFallback = () => (
  <div style={style}>
    <React.Fragment>
      <h2 style={title}> Ocorreu um erro inesperado! </h2>
      <h3 style={subtitle}>Estamos trabalhando para consertar esse erro. Tente recarregar a página enquanto isso.</h3>
    </React.Fragment>
  </div>
)

export default AppFallback
