/*  Author: Luís Mourão
    Type: Func
	Description: Validate received coordinates, being able to limit by the project area
	TODO: 
*/

const validCoords = (lat, lng, limitLat = [], limitLng = []) => {
  if (typeof lat === 'number' && typeof lng === 'number') {
    if (Array.isArray(limitLat) && Array.isArray(limitLng) && limitLat.length === 2 && limitLng.length === 2) {
      if (lat > limitLat[0] && lat < limitLat[1] && lng > limitLng[0] && lng < limitLng[1]) return true
      else {
        console.warn('Coordinates out of project area')
        return false
      }
    } else {
      if (lat > -90 && lat < 90 && lng > -180 && lng < 180) return true
      else {
        console.warn('Extrapolated coordinate limit')
        return false
      }
    }
  } else {
    console.warn('Coordinates must be numbers')
    return false
  }
}

export default validCoords
