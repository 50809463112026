import { StepOne } from '../Components/forms/mensurations/schemas/stepOne'
import { StepTwo } from 'Components/forms/mensurations/schemas/stepTwo'
import { StepThree } from 'Components/forms/mensurations/schemas/stepThree'
import { StoreForm } from './formStepItems'
import { SemanticICONS } from 'semantic-ui-react'

interface PhaseInfo {
  duration: number
}

interface CycleInfo {
  duration: number
  phases: PhaseInfo[]
}

export interface Mensuration extends StepOne, StepTwo, StepThree {
  cyclePhaseDurations: CycleInfo[]
}

export interface WipMensuration extends Partial<Mensuration> {}

export interface Mensurations {
  allIds: string[]
  byId: Record<string, Mensuration>
  current: Mensuration | null
  wip: WipMensuration
  form: StoreForm
  loading: boolean
}

export const mensurations: Mensurations = {
  allIds: [],
  byId: {},
  current: null,
  wip: {},
  form: {
    steps: {
      current: 0,
      items: [
        {
          id: 'identification',
          icon: 'hashtag' as SemanticICONS,
          title: 'Identificação',
          description: 'Identifique a Mensuração',
          showFeedback: false,
          disabled: false,
        },
        {
          id: 'info',
          icon: 'info' as SemanticICONS,
          title: 'Informações',
          description: 'Dados sobre a mensuração',
          showFeedback: false,
          disabled: true,
        },
        {
          id: 'phases',
          icon: 'tasks' as SemanticICONS,
          title: 'Fases',
          description: 'Descreva as fases',
          showFeedback: false,
          disabled: true,
        },
      ],
    },
    open: true,
    loading: false,
  },
  loading: true,
}
