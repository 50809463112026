import React from 'react'
import RenderRefMarker from 'Components/Map/MapMarkers/RenderRefMarker.js'
import RenderCreationMarker from 'Components/Map/MapMarkers/RenderCreationMarker.js'

import { useSelector } from 'react-redux'

export const MapRefs = ({ ids }) => {
  const moduleType = 'refs'
  const geoWip = useSelector((state) => state.modules[moduleType].geoWip)
  const preferences = useSelector((state) => state.login.preferences[moduleType])

  return (
    <>
      {geoWip?.geoJson && <RenderCreationMarker wip={geoWip} />}
      {ids
        ?.filter((f) => !preferences[f]?.invisible)
        ?.map((eachModule) => {
          const moduleId = eachModule?.toString()
          if (!moduleId) {
            console.error('Module without id!')
            return null
          }
          return <RenderRefMarker key={moduleId} moduleId={moduleId} />
        })}
    </>
  )
}
