import React, { useState } from 'react'
import { Button, Modal, Icon, Grid, List } from 'semantic-ui-react'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import styled from 'styled-components'
import ItemDetailsField from './ItemDetailsField'

export default function NotificationDetailsModal({ notificationId, help }) {
  const notification = useObjSelector((state) => state.notification.byId[notificationId])
  const [open, setOpen] = useState(false)

  const INFOS = [
    { field: 'confirmedBy', label: `Confirmado por`, type: 'string' },
    { field: 'confirmedAt', label: 'Confirmado em', type: 'time' },
    { field: 'closedBy', label: `Concluída por`, type: 'string' },
    { field: 'closedAt', label: 'Concluída em', type: 'time' },
  ]

  const header = help ? 'Detalhes do pedido de ajuda' : `Detalhes da emergência - ${notification.title}`

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Icon name="external" size="small" style={{ cursor: 'pointer' }} />}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <DivInfo>
          <Grid columns={'equal'}>
            <Grid.Column width={6}>
              <List>
                {INFOS.map(
                  (li, i) => i % 2 === 0 && <ItemDetailsField key={i} li={li} value={notification[li.field]} />
                )}
              </List>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={4}>
              <List>
                {INFOS.map(
                  (li, i) => i % 2 === 1 && <ItemDetailsField key={i} li={li} value={notification[li.field]} />
                )}
              </List>
            </Grid.Column>
          </Grid>
        </DivInfo>
      </Modal.Content>
      <Modal.Actions>
        <Button content="OK" labelPosition="right" icon="checkmark" onClick={() => setOpen(false)} positive />
      </Modal.Actions>
    </Modal>
  )
}

const DivInfo = styled.div`
  margin: 30px;
  width: 800px;
`
