import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faPowerOff,
  faSquare,
  faCheck,
  faTimes,
  faUsers,
  faProjectDiagram,
  faCar,
  faEye,
  faMap,
  faStar,
  faBatteryFull,
  faBatteryHalf,
  faMapMarkerAlt,
  faLocationArrow,
  faBolt,
  faDotCircle,
  faTachometerAlt,
  faClock,
  faInfo,
  faBatteryThreeQuarters,
  faBatteryQuarter,
  faBatteryEmpty,
  faTrash,
  faTrashAlt,
  faPlus,
  faFileExport,
  faArrowDown,
  faAngleDown,
  faAngleUp,
  faEyeSlash,
  faWifi,
  faAsterisk,
  faFilter,
  faLanguage,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faPowerOff,
  faSquare,
  faCheck,
  faTimes,
  faUsers,
  faProjectDiagram,
  faCar,
  faEye,
  faMap,
  faStar,
  faBatteryFull,
  faBatteryHalf,
  faMapMarkerAlt,
  faLocationArrow,
  faBolt,
  faDotCircle,
  faTachometerAlt,
  faClock,
  faInfo,
  faBatteryThreeQuarters,
  faBatteryQuarter,
  faBatteryEmpty,
  faTrash,
  faTrashAlt,
  faPlus,
  faFileExport,
  faArrowDown,
  faAngleDown,
  faAngleUp,
  faEyeSlash,
  faWifi,
  faAsterisk,
  faFilter,
  faLanguage,
  faGlobe
)
