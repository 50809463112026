import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggle, setTrue, setFalse } from 'Store/actions/toggle-action'
import { saveLanguage, saveLanguageNow } from 'Store/actions/login-action'
import { sliderChange, saveHistory } from 'Store/actions/history-action'
import { showConfirm, hideConfirm } from 'Store/actions/system-action'
import { filterTime } from 'Store/actions/filters-action'
import { fetchHeatMap } from 'Store/actions/heatmap-action'
import { withTranslation } from 'react-i18next'
import NavButtonsGrid from './NavButtonsGrid.jsx'
import { selectLanguage } from 'Resources/Locale/selectLanguage'

/*  Author: Bruno Melo
    Type: Smart
    Description: self explanatory
    TODO: - Botões devem aparecer de acordo com o perfil
          - Passar botões como um vetor
*/

class NavButtons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hideMenu: true,
      favoritesClass: 'botao_navbar',
      innermapClass: this.props.showInnerMap ? 'botao_navbar botaoNavbarFavoritesFiltrando' : 'botao_navbar',
      lan: 'English',
    }
  }

  componentDidUpdate(prevProps) {
    let prevLan = prevProps.lang ? prevProps.lang : 'pt'
    let nextLan = this.props.lang ? this.props.lang : 'pt'

    if (prevLan !== nextLan) {
      selectLanguage(nextLan, this.props.i18n)
    }

    if (!prevProps.showInnerMap && this.props.showInnerMap) {
      this.setState({ innermapClass: 'botao_navbar botaoNavbarFavoritesFiltrando' })
    }
    if (prevProps.showInnerMap && !this.props.showInnerMap) {
      this.setState({ innermapClass: 'botao_navbar' })
    }
  }

  clearHistory = () => {
    const { timeslider, hist } = this.props
    hist && this.props.saveHistory([])
    if (timeslider.min || timeslider.max || timeslider.total !== 1)
      this.props.sliderChange({ min: 0, max: 0, total: 1 })
  }

  render() {
    /*const buttons = [{}]*/

    return (
      <NavButtonsGrid {...this.props} favoritesClass={this.state.favoritesClass} onClickHeatMap={this.onClickHeatMap} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    showHeatMap: state.toggle.showHeatMap,
    HistMoreThan1Day: state.toggle.HistMoreThan1Day,
    showInnerMap: state.toggle.showInnerMap,
    showFences: state.toggle.showConfigFence,
    lang: state.login.language,
    refMap: state.login.refMap,
    hist: state.history.allIds.length > 0,
    timeslider: state.filters.timeslider,
    rights: state.login.empresa,
    email: state.login.email,
    indoor: state.indoor.items,
    routes: state.modules.routes.byId,
    refs: state.modules.refs.byId,
    hasHeatmap: state.heatmap?.allIds.length > 0,
  }
}

const mapDispatchToProps = {
  fetchHeatMap,
  filterTime,
  toggle,
  setTrue,
  setFalse,
  saveHistory,
  sliderChange,
  saveLanguage,
  saveLanguageNow,
  showConfirm,
  hideConfirm,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NavButtons))
