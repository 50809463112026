type Params = {
  /**
   * Length of the first segment of the code (default is 4).
   */
  firstSegLen?: number
  /**
   * Length of the first segment of the code (default is 4).
   */
  secondSegLen?: number
}

/**
 * Generates a random code in the format "XXXX-XXXXX", where
 * each "X" is a digit from 0 to 9.
 *
 * The function creates two segments:
 * - The first segment contains a specified number of digits (default 4).
 * - The second segment contains a specified number of digits (default 5).
 *
 * The code is returned as a string, separated by a hyphen.
 *
 * @param {Params} params - The parameters for generating the code.
 * @returns {string} The randomly generated code.
 */

function generateRandomCode({ firstSegLen = 4, secondSegLen = 5 }: Params): string {
  const generateSegment = (length: number): string =>
    Array.from({ length }, () => Math.floor(Math.random() * 10)).join('')

  const firstSegment = generateSegment(firstSegLen)
  const secondSegment = generateSegment(secondSegLen)

  return `${firstSegment}-${secondSegment}`
}

export default generateRandomCode
