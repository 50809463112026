import { urlBase } from 'Apis/rest'
import axios from 'axios'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'

export const deletePeople = (ids) => async (dispatch) => {
  dispatch({ type: 'DELETE_PEOPLE_ACTION_START' })
  try {
    const jwt = localStorage.getItem('id_token')
    const headers = { headers: { Authorization: 'Bearer ' + jwt } }
    await axios.delete(`${urlBase}/assets?ids=${ids}`, headers)
    dispatch({ type: 'DELETE_ASSETS', payload: ids })
    const res = await axios.post(`${urlBase}/devices/totemfiles`, {}, headers)
    dispatch({ type: 'UPDATE_TOTEM_FILES', payload: res })
    dispatch({ type: 'DELETE_PEOPLE_ACTION_SUCCESS' })
    notifySuccess()
  } catch (err) {
    console.error('Error deleting people', err)
    dispatch({ type: 'DELETE_PEOPLE_ACTION_FAILURE' })
    notifyError('Error deleting people')
  }
}

export const updatePerson = (id, changes) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PEOPLE_ACTION_START' })
  dispatch({ type: 'UPDATE_ASSET_REQUEST', id })
  const jwt = localStorage.getItem('id_token')
  const body = { id, changes }
  const headers = { headers: { Authorization: 'Bearer ' + jwt } }

  try {
    const res = await axios.put(`${urlBase}/assets`, body, headers)
    axios.post(`${urlBase}/devices/totemfiles`, {}, headers).then((res) => {
      dispatch({ type: 'UPDATE_TOTEM_FILES', payload: res })
    })
    if (res.data.status === 200 && res.data.body) {
      const assetsRes = await axios.get(urlBase + '/assets/', headers)
      dispatch({ type: 'FETCH_ASSETS', payload: assetsRes.data })
      dispatch({ type: 'UPDATE_ASSET_NAME_SUCCESSFULL', id: id, payload: res.data.body })
    }
    dispatch({ type: 'UPDATE_PEOPLE_ACTION_SUCCESS' })
    notifySuccess()
  } catch (err) {
    console.error(err)
    dispatch({ type: 'UPDATE_ASSET_FAILURE', payload: err })
    dispatch({ type: 'UPDATE_PEOPLE_ACTION_FAILURE', payload: err })
    notifyError()
  } finally {
    dispatch({ type: 'UPDATE_ASSET_FINISH' })
  }
}

export const insertPeople = (body) => async (dispatch) => {
  dispatch({ type: 'INSERT_PEOPLE_ACTION_START' })

  const jwt = localStorage.getItem('id_token')
  const headers = { headers: { Authorization: 'Bearer ' + jwt } }

  try {
    const response = await axios.post(`${urlBase}/assets/`, body, headers)
    dispatch({ type: 'INSERT_ASSETS', payload: response })
    dispatch({ type: 'INSERT_PEOPLE_ACTION_SUCCESS' })
    notifySuccess()
    try {
      const response = await axios.post(`${urlBase}/devices/totemfiles`, {}, headers)
      dispatch({ type: 'UPDATE_TOTEM_FILES', payload: response })
    } catch (err) {
      console.error('Error updating totem files', err)
    }
  } catch (err) {
    console.error('Error in saveGroup', err)
    dispatch({ type: 'INSERT_PEOPLE_ACTION_FAILURE', payload: err })
    notifyError()
  }
}
