import React from 'react'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import useStoreSelector from 'Utils/hooks/useStoreSelector'
import ModuleDetalis from './ModuleDetalis'
import CustomDetails from './CustomDetalis'
import MockDetails from './MockDetails'

interface ModuleDetailsContainerProps {
  type: 'refs' | 'fences'
}

const ModuleDetailsContainer: React.FC<ModuleDetailsContainerProps> = ({ type }) => {
  const moduleProfile = useObjSelector((state) => state.modules.current?.profile || '')
  const tenant = useStoreSelector((state) => state.login.empresa)

  const isDefaultLayout = ['28112', '29969', '30298'].includes(moduleProfile)

  return tenant === 'TiplamTest' ? (
    <MockDetails />
  ) : isDefaultLayout ? (
    <CustomDetails type={type} />
  ) : (
    <ModuleDetalis type={type} />
  )
}

export default ModuleDetailsContainer
