import React, { Fragment } from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { toggle } from 'Store/actions/toggle-action'

const HistInfoDisplay = ({ firstElement, secondElement, width = 8, onClick, showCalendar }) => {
  const dispatch = useDispatch()

  return (
    <DateText width={width} textAlign="right" onClick={onClick}>
      <DateElement>{firstElement}</DateElement>
      {secondElement && (
        <Fragment>
          <DateElement> ~ </DateElement>
          <DateElement>{secondElement}</DateElement>
        </Fragment>
      )}
      <Icon name={'angle ' + (showCalendar ? 'up' : 'down')} onClick={() => dispatch(toggle('showHistoryCalendar'))} />
    </DateText>
  )
}

const DateText = styled(Grid.Row)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  cursor: pointer;
`

const DateElement = styled.span`
  font-weight: 700;
  font-size: 13px;
  vertical-align: text-top;
`

export default HistInfoDisplay
