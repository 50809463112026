import { BrowserCacheLocation } from '@azure/msal-browser'

export const msalConfig = {
  auth: {
    clientId: '6407fc70-68c4-4890-ba5d-79ec238625d0',
    authority: 'https://login.microsoftonline.com/eef8f41a-1fa3-486c-9dc3-2fa5f63c9231',
    redirectUri: 'https://vli.phygitall.com.br/callback',
    postLogoutRedirectUri: 'https://vli.phygitall.com.br/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read', 'Group.Read.All'],
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com',
}

const securityGroups = {
  GroupOperator: 'f20a1e08-521a-4b00-864b-7fc8e2708aba',
  GroupSupervisor: '2d804dbe-cb5f-48a0-93c3-852c711985cb',
  GroupAdmin: 'a910db31-7b6e-47de-894f-08a34f06d391',
}

export const permissions = {
  [securityGroups.GroupOperator]: 'OPE',
  [securityGroups.GroupSupervisor]: 'SUP',
  [securityGroups.GroupAdmin]: 'ADM',
}

const userRolesHierarchy = {
  [permissions[securityGroups.GroupAdmin]]: 1,
  [permissions[securityGroups.GroupSupervisor]]: 2,
  [permissions[securityGroups.GroupOperator]]: 3,
}

export const userSecurityGroup = (roles: string[]): string => {
  const securityGroupsHashes = Object.values(securityGroups)
  const userPermissionsHashes = securityGroupsHashes.filter((el) => roles.includes(el))
  if (!userPermissionsHashes.length) return 'NA'
  const uPermissions = userPermissionsHashes.map((p) => permissions[p])
  if (!uPermissions.length) return 'NA'
  uPermissions.sort((a, b) => userRolesHierarchy[a] - userRolesHierarchy[b])
  return uPermissions[0]
}
