import React from 'react'
import { Segment, Icon, Header, Grid } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import useEntitySelector from 'Utils/hooks/useEntitySelector'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import confirmClose from './utils/confirmClose'
import ImageUpload from 'Components/ImageUpload'
import { v4 } from 'uuid'
import ImageWithLoader from 'Components/images/ImageWithLoader'
import { saveGeoModuleImage, setModulesProps } from 'Store/actions/modules-action'

const StatsBarHeader = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const wip = useSelector((state: RootStore) => state.modules.wip)
  const isUpdate = !!wip.id
  const label = useEntitySelector('modules', 'label')
  const type = useEntitySelector('modules', 'type')
  const url = useSelector(
    (state: RootStore) => state.modules.wip.properties?.url ?? state.modules.current?.properties?.url
  )
  const tenant = useSelector((state: RootStore) => state.login.empresa)
  const currentId = useSelector((state: RootStore) => state.modules.current?.id)

  const shortenedName = isUpdate ? label?.slice(0, 30) : label?.slice(0, 30) ?? t('newItem')
  const gridStyle = {
    width: '25vw',
    minWidth: '230px',
    justifyContent: 'space-between',
  }

  const handleCancel = () => {
    confirmClose({ wip, dispatch, type })
  }

  return (
    <Segment style={{ borderTop: 'none' }}>
      <Grid columns={16} style={gridStyle}>
        <Grid.Column width={3} style={{}}>
          <Header as="h3">
            <ImageUpload
              imagePath={url}
              uploadPath={`public/${tenant}/geoModules/${currentId}/images/${v4()}`}
              onUpload={async (path, file) => {
                if (currentId) {
                  // Atualiza a imagem do módulo [atualização]
                  dispatch(saveGeoModuleImage({ newValue: path }))
                } else {
                  // Adiciona a nova imagem [criação]
                  dispatch(
                    setModulesProps([
                      [`wip.properties.url`, path],
                      [`wip.properties.imageFile`, file],
                    ])
                  )
                }
              }}
              onDelete={async () => {
                if (currentId) {
                  dispatch(saveGeoModuleImage({ newValue: null }))
                } else {
                  dispatch(
                    setModulesProps([
                      [`wip.properties.url`, null],
                      [`wip.properties.imageFile`, null],
                    ])
                  )
                }
              }}
              trigger={
                url ? null : (
                  <ImageWithLoader
                    src="https://react.semantic-ui.com/images/wireframe/square-image.png"
                    avatar
                    size="medium"
                  />
                )
              }
              validateObjectExistence
              triggerSize="medium"
              preview={!currentId}
            />
          </Header>
        </Grid.Column>
        <Grid.Column width={10} style={{ top: '1.8px' }} verticalAlign="middle">
          <Header as="h3">
            <span>{shortenedName}</span>
          </Header>
        </Grid.Column>
        <Grid.Column width={3} style={{ textAlign: 'right' }}>
          <Icon
            title={t('Close')}
            name="close"
            onClick={handleCancel}
            style={{ position: 'relative', cursor: 'pointer', bottom: '8px' }}
          />
        </Grid.Column>
      </Grid>
    </Segment>
  )
}
export default StatsBarHeader
