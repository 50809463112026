import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Table } from 'semantic-ui-react'
import formatValue from './formatValue'
import _ from 'lodash'

const ChangeLogTable = ({ previousObject, currentObject }) => {
  const { t } = useTranslation()

  const showPreviousColumn = !_.isEmpty(previousObject)

  const renderRows = () => {
    const keys = Object.keys(currentObject)
    return keys.reduce((rows, key) => {
      const prevValue = previousObject[key]
      const currValue = currentObject[key]

      // converte para string objetos e array para fins de comparação
      const scalarPrevValue = typeof prevValue === 'object' ? JSON.stringify(prevValue) : prevValue
      const scalarcurrValue = typeof currValue === 'object' ? JSON.stringify(currValue) : currValue

      if (scalarPrevValue !== scalarcurrValue) {
        const fieldLabel = key.includes('properties.customFields.') ? key.split('.')[2] : t(key)

        if (!prevValue && !currValue) return rows
        rows.push(
          <Table.Row key={key}>
            <Table.Cell verticalAlign="middle">{fieldLabel}</Table.Cell>
            {showPreviousColumn && (
              <Table.Cell title={prevValue} verticalAlign="middle">
                {formatValue(prevValue, t)}
              </Table.Cell>
            )}
            <Table.Cell title={currValue} verticalAlign="middle">
              {formatValue(currValue, t)}
            </Table.Cell>
          </Table.Row>
        )
      }

      return rows
    }, [])
  }

  return (
    <Container>
      <Table basic={true} celled fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: '18%' }}>Campo</Table.HeaderCell>
            {showPreviousColumn && <Table.HeaderCell style={{ width: '41%' }}>Valor Anterior</Table.HeaderCell>}
            <Table.HeaderCell
              textAlign={showPreviousColumn ? 'left' : 'left'}
              style={{ width: showPreviousColumn ? '41%' : '82%' }}
            >
              Valor Atual
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            <>
              {renderRows()}
              {currentObject.updatedBy && previousObject.updatedBy && (
                <Table.Row>
                  <Table.Cell>Alterado por</Table.Cell>
                  <Table.Cell>{previousObject.updatedBy}</Table.Cell>
                  <Table.Cell>{currentObject.updatedBy}</Table.Cell>
                </Table.Row>
              )}
            </>
          }
        </Table.Body>
      </Table>
    </Container>
  )
}

export default ChangeLogTable
