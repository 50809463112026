import React from 'react'
import { Header } from 'semantic-ui-react'
import { useClock } from 'Utils/components/timeClock.jsx'
import styled from 'styled-components'

export const ModalClock = ({ fullscreenMode }) => {
  const Clock = styled('div')`
    padding-right: 2vw;
    text-align: center;
    position: relative;
    top: ${fullscreenMode ? '0' : '1.5vh'};
  `

  const { time } = useClock()

  return (
    <Header as="h2">
      <Clock>{time}</Clock>
    </Header>
  )
}

export default ModalClock
