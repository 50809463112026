import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'semantic-ui-react'

export interface TextAreaFieldProps {
  label: string
  onChange: (
    event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement | HTMLElement, Event>,
    data: any
  ) => void
  name?: string
  placeholder?: string
  value?: string
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({ ...fields }) => {
  const { t } = useTranslation()

  return (
    <Form.Field>
      <label>{t(fields.label)}</label>
      <Form.TextArea
        name={fields.name}
        placeholder={t(fields?.placeholder || '')}
        value={fields.value || ''}
        onChange={fields.onChange}
        style={{ height: '80px' }}
      />
    </Form.Field>
  )
}

export default TextAreaField
