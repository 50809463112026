import React, { memo, useEffect, useState } from 'react'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { useSelector } from 'react-redux'
import { FeatureGroup, Polyline, Tooltip } from 'react-leaflet'

export const RenderRoute = ({ id, click }) => {
  //const routeData = useSelector((state) => state.modules.routes?.byId[id])
  const moduleType = 'routes'

  const typeId = useSelector((state) => state.modules[moduleType]?.byId[id]?.profile)
  const typeData = useSelector((state) => state.profiles?.byId[typeId]) || {}
  const coordinates = useSelector((state) => state.modules[moduleType]?.byId[id]?.geometry?.coordinates) || []
  const tooltipText = useSelector((state) => state.modules[moduleType]?.byId[id]?.label) || ''
  const color = useSelector((state) => state.modules[moduleType]?.byId[id]?.properties?.color)
  //const currentId = useSelector((state) => state.modules[moduleType]?.current?.id)
  const inner = useSelector((state) => state.toggle.showInnerMap)
  const currentInner = useSelector((state) => state.indoor.current?.inner)

  const statsbarId = useSelector((state) => state?.system?.statsbar?.id)
  const editionMode = useSelector((state) => state.modules[moduleType]?.geoWip?.editMode)
  const idTooltipText = tooltipText
  const [tooltipState, setTooltipState] = useState(idTooltipText)
  const [permanentState, setPermanentState] = useState(false)

  useEffect(() => {
    if (statsbarId === id) {
      setPermanentState(true)
      if (editionMode) setTooltipState(`(Editando) ` + idTooltipText)
      else setTooltipState(idTooltipText)
    } else setPermanentState(false)
  }, [id, statsbarId, editionMode, idTooltipText])

  const state = useSelector((state) => state.modules[moduleType]?.byId[id]?.state)

  const preferences = useSelector((state) => state.login.preferences[moduleType][id])
  return (
    (preferences || state !== 'closed') && (
      <FeatureGroup
        key={id}
        onClick={(data) => click(data, id)}
        onmouseover={() => setPermanentState(true)}
        onmouseout={() => id !== statsbarId && setPermanentState(false)}
      >
        <Polyline
          positions={coordinates.map((e) =>
            inner ? innerPosition([e[1], e[0]], 'array', currentInner?.center, currentInner?.bearing) : [e[1], e[0]]
          )}
          weight="2"
          dashArray="3, 6"
          dashOffset="0"
          color="gray"
          opacity={state === 'closed' ? 0.4 : 1}
        >
          {
            <Tooltip permanent={permanentState} direction="bottom">
              {tooltipState}
            </Tooltip>
          }
        </Polyline>
        <Polyline
          opacity={state === 'closed' ? 0.4 : 1}
          positions={coordinates.map((e) =>
            inner ? innerPosition([e[1], e[0]], 'array', currentInner?.center, currentInner?.bearing) : [e[1], e[0]]
          )}
          weight="2"
          dashArray="3, 6"
          dashOffset="6"
          color={typeData?.color ? typeData.color : color ? color : 'blue'}
        />
        <Tooltip permanent={permanentState} direction="bottom">
          {tooltipState}
        </Tooltip>
      </FeatureGroup>
    )
  )
}

export default memo(RenderRoute)
