import StepForm from 'Components/forms/StepForm'
import React from 'react'
import filterStepOneSchema from '../../schemas/filterStepOne'
import PanelStepOneForm from './PanelStepOneForm'
import { handlePanelFetchResult, setBetaDashboardProps } from 'Store/actions/betaDashboards-action'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import createStepTwoSchema from 'Components/forms/reports/schemas/stepTwo'
import PanelStepTwoForm from './PanelStepTwoForm'
import PanelStepThreeForm from './PanelStepThreeForm'
import createStepThreeSchema from 'Components/forms/reports/schemas/stepThree'
import PanelStepFour from './PanelStepFour'
import createStepFourSchema from 'Components/forms/reports/schemas/stepFour'

type Props = {
  panelId: string
}

const PanelForm: React.FC<Props> = ({ panelId }) => {
  const dispatch = useDispatch()

  const scope = useSelector(
    (state: RootStore) =>
      state.betaDashboards?.wip?.panels?.[panelId]?.scope ?? state.betaDashboards.current?.panels[panelId]?.scope ?? ''
  )

  const type = useSelector(
    (state: RootStore) =>
      state.betaDashboards?.wip?.panels?.[panelId]?.type ?? state.betaDashboards.current?.panels[panelId]?.type ?? ''
  )

  const isDevUser = useSelector((state: RootStore) => state.login.perfil === 'DEV')

  const stepTwoSchema = createStepTwoSchema((scope as 'measures') || 'rules', isDevUser)

  const stepThreeSchema = createStepThreeSchema()

  const stepFourSchema = createStepFourSchema(type as 'kpi' | 'donut')

  return (
    <StepForm
      entity="betaDashboards"
      schemas={[filterStepOneSchema, stepTwoSchema, stepThreeSchema, stepFourSchema]}
      forms={[
        <PanelStepOneForm panelId={panelId} />,
        <PanelStepTwoForm panelId={panelId} />,
        <PanelStepThreeForm panelId={panelId} />,
        <PanelStepFour panelId={panelId} />,
      ]}
      setProps={setBetaDashboardProps}
      values={[{ fields: ['scope'] }]}
      onSave={() => {
        dispatch(setBetaDashboardProps([[`form.open`, false]]))
        dispatch(handlePanelFetchResult({ panelId }))
      }}
      onClose={() => {
        dispatch(setBetaDashboardProps([[`form.steps.current`, 0]]))
      }}
      saveContent="load"
      size="fullscreen"
      wipPath={`betaDashboards.wip.panels[${panelId}]`}
      currentPath={`betaDashboards.current.panels[${panelId}]`}
    />
  )
}

export default PanelForm
