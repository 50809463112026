// src/hooks/useRuleFilters.ts
import { RootStore } from 'Store/initialStore'
import useStoreSelector from 'Utils/hooks/useStoreSelector'

export type UseRuleFiltersOptions = {
  selectAssets: (state: RootStore) => any
  selectShowFeedback: (state: RootStore) => any
  selectRules: (state: RootStore) => any
  selectErrors: (state: RootStore) => any
  selectFences: (state: RootStore) => any
}

const useRuleFilters = ({
  selectAssets,
  selectShowFeedback,
  selectRules,
  selectErrors,
  selectFences,
}: UseRuleFiltersOptions) => {
  const selectedAssets = useStoreSelector(selectAssets)
  const showFeedback = useStoreSelector(selectShowFeedback)
  const selectedRules = useStoreSelector(selectRules)
  const selectedErrors = useStoreSelector(selectErrors)
  const selectedFences = useStoreSelector(selectFences)

  return { selectedAssets, showFeedback, selectedRules, selectedErrors, selectedFences }
}

export default useRuleFilters
