import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import moment from 'moment'
import React, { useMemo } from 'react'
// import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ReferenceLine,
  LabelList,
  //   LabelList,
} from 'recharts'
import { Label } from 'semantic-ui-react'
// import { lightColors } from 'Utils/colorsFormat'

interface YamazumiBarChartProps {
  data: any[]
  showPhases: boolean
}

function TurnLabel() {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3px', marginLeft: '55px' }}
    >
      <Label>{'Ciclos'}</Label>
    </div>
  )
}
const valueAccessor =
  (attribute: string) =>
  ({ payload }: { payload: { attribute: string } }) => {
    return payload[attribute] || ''
  }

const YamazumiBarChart: React.FC<YamazumiBarChartProps> = ({ data, showPhases }) => {
  const formatData = useMemo(() => data?.map((e, i) => ({ ...e, index: i + 1 })), [data])

  const selectedChart = useSelector((state: RootStore) => state.dashboardStellantis.charts.selected)

  const lastActivityDataLabeling = useObjSelector((state: RootStore) => state.dataLabelings.lastActivityDataLabeling)

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={formatData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="index" />
        <YAxis unit="s" />
        <Tooltip
          formatter={(value, name) => {
            const res = `${value}s`
            return [res, name]
          }}
          labelFormatter={(value, props) => {
            const time = moment(props[0]?.payload?.minStartTime).format('DD/MM HH:mm:ss')
            const res = `${value} - ${time}`
            return [res]
          }}
        />
        <Legend content={TurnLabel} />

        {selectedChart === 'ciclo' ? (
          <>
            <Bar dataKey="phase#1" stackId={'cycle'} fill="#B0DCF2">
              <LabelList valueAccessor={valueAccessor('phase#1')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#2" stackId={'cycle'} fill="#8DD994">
              <LabelList valueAccessor={valueAccessor('phase#2')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#3" stackId={'cycle'} fill="#F4B9B9">
              <LabelList valueAccessor={valueAccessor('phase#3')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#4" stackId={'cycle'} fill="#FFC18F">
              <LabelList valueAccessor={valueAccessor('phase#4')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#5" stackId={'cycle'} fill="#FFDA8F">
              <LabelList valueAccessor={valueAccessor('phase#5')} style={{ backgroundColor: 'white' }} />
            </Bar>
          </>
        ) : (
          <Bar dataKey="duration" stackId={'cycle'} fill="rgb(102, 175, 233)">
            <LabelList valueAccessor={valueAccessor('duration')} style={{ backgroundColor: 'white' }} />
          </Bar>
        )}

        <ReferenceLine y={lastActivityDataLabeling?.taktTime || 77} label="TT" stroke="red" />
        <ReferenceLine
          y={lastActivityDataLabeling?.targetCycleTime || 65}
          label="TCT"
          stroke="blue"
          strokeDasharray="3 3"
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default YamazumiBarChart
