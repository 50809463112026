import React from 'react'
import { useSelector } from 'react-redux'
import DropdownStoreField from './DropdownStoreField'
import { DropdownProps, Form } from 'semantic-ui-react'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { RootStore } from 'Store/initialStore'
import CustomTimeFields from './CustomTimeFields'
import InputStoreField from './InputStoreField'

/*
Casos de uso:
 - atalho relativo
 - absoluto
*/

const options = [
  {
    key: '1h',
    value: 'ago(1h)',
    text: 'Última Hora',
  },
  {
    key: '24h',
    value: 'ago(24h)',
    text: 'Últimas 24 Horas',
  },
  {
    key: '7d',
    value: 'ago(7d)',
    text: 'Última Semana',
  },
  // {
  //   key: 'absoluteTime',
  //   value: 'absoluteTime',
  //   text: 'Absoluto',
  // },
  {
    key: 'relativeTime',
    value: 'relativeTime',
    text: 'Personalizado',
  },
]

export interface TimePropsFieldsInterface {
  setProp: Function
}

function TimePropsFields({ setProp }: TimePropsFieldsInterface) {
  const errors = useObjSelector((state) => state.reports.form.errors?.[2])

  const timeProp = useSelector(
    (state: RootStore) => state.reports.wip.filters?.timeProp ?? state.reports.current?.filters?.timeProp
  )

  const showFeedback = useObjSelector((state: RootStore) => state.reports.form?.steps.items[2].showFeedback)

  const handleTimePropChange = (value: string) => {
    setProp(`wip.filters.timeProp`, value)
    if (value.includes('ago')) {
      setProp(`wip.startTime`, value)
      setProp(`wip.endTime`, 'now()')
    }
  }

  return (
    <>
      <DropdownStoreField
        entity="reports"
        label="Intervalo de Tempo"
        value={'filters.timeProp'}
        name="timeProp"
        placeholder="Selecione um intervalo de tempo"
        onChange={(_, { value }: DropdownProps) => {
          handleTimePropChange(value as string)
        }}
        options={options}
        required
        sort={false}
        error={errors?.timeProp}
        showFeedback={showFeedback}
        clearable={false}
      />

      <br />
      {timeProp === 'absoluteTime' && (
        <Form.Group widths="equal">
          <InputStoreField
            entity="reports"
            label="Início"
            name="startTime"
            placeholder="Defina o início"
            value={'startTime'}
            onChange={(_, { name, value }) => setProp(`wip.${name}`, value)}
            inputType="datetime-local"
            required={true}
            showFeedback={showFeedback}
            error={errors?.startTime}
          />
          <InputStoreField
            entity="reports"
            label="Fim"
            name="endTime"
            placeholder="Defina o fim"
            value={'endTime'}
            onChange={(_, { name, value }) => setProp(`wip.${name}`, value)}
            inputType="datetime-local"
            required={true}
            showFeedback={showFeedback}
            error={errors?.endTime}
          />
        </Form.Group>
      )}
      {timeProp === 'relativeTime' && (
        <>
          <CustomTimeFields prefix="start" setProp={setProp} />
          <br />
          <br />
          <CustomTimeFields prefix="end" setProp={setProp} />
        </>
      )}
    </>
  )
}

export default TimePropsFields
