import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'semantic-ui-react'
import EntityDropdown, { EntityDropdownProps } from 'Components/dropdowns/EntityDropdown'

interface EntityDropdownFieldProps extends EntityDropdownProps {
  label: string
  name?: string
  required?: boolean
  showFeedback?: boolean
  error?: boolean
}

const EntityDropdownField: React.FC<EntityDropdownFieldProps> = ({ showFeedback, ...fields }) => {
  const { t } = useTranslation()

  return fields.entity ? (
    <Form.Field key={fields.key} required={fields.required} error={showFeedback && fields?.error}>
      {/* <Transition transitionOnMount={true} animation="fade down" duration="2300"> */}
      <label>{t(fields.label)}</label>
      {/* </Transition> */}
      <EntityDropdown {...fields} />
    </Form.Field>
  ) : null
}

export default EntityDropdownField
