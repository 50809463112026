import { jsonGroup } from 'Components/Map/PopupMarker/jsonGroup'
import parseReport from 'Utils/parseReports'
import produce from 'immer'
import set from 'immutable-set'
import { omit } from 'lodash'
import { setStoreProps } from './reducerUtils'

export default function reportsReducer(state = {}, { type, payload, prop, value, id, email }) {
  switch (type) {
    case 'FETCH_REPORTS_START':
      return produce(state, (newState) => {
        newState.loading = true
      })

    case 'FETCH_REPORTS_SUCCESS':
      return produce(state, (newState) => {
        newState.byId = payload.reduce((acc, report) => {
          if (report?.access === 'PUBLIC' || email === report.createdBy) {
            acc[report.id] = parseReport(report)
          }
          return acc
        }, {})
        newState.allIds = Object.keys(newState.byId)
        newState.loading = false
      })

    case 'FETCH_REPORTS_FAILURE':
      return produce(state, (newState) => {
        newState.loading = false
      })

    case 'SET_REPORTS_PROP':
      return set(state, prop, value)

    case 'SET_REPORTS_PROPS':
      return setStoreProps(state, payload)

    case 'SET_CURRENT_REPORT':
      return produce(state, (newState) => {
        newState.current = payload ?? newState.byId[id] ?? {}
        newState.wip = { code: newState.current.code, id: newState.current.id }
      })

    case 'SET_CURRENT_WIP_REPORT':
      return produce(state, (newState) => {
        newState.current = newState.wip
      })

    case 'SAVE_REPORTS_START':
      return produce(state, (newState) => {
        newState.form.loading = true
      })

    case 'SAVE_REPORTS_FAILURE':
      return produce(state, (newState) => {
        newState.form.loading = false
      })

    case 'SAVE_REPORTS_SUCCESS':
      if (!payload?.id) return state
      return produce(state, (newState) => {
        if (!newState.allIds.includes(payload.id)) newState.allIds.push(payload.id)
        const parsedPayload = parseReport(payload)
        newState.byId[payload.id] = parsedPayload
        newState.current = parsedPayload
        newState.form.loading = false
        newState.form.open = false
      })

    case 'SAVE_REPORT_SUCCESS':
      if (payload?.access !== 'PUBLIC') return state
      return produce(state, (newState) => {
        newState.byId[payload.id] = parseReport(payload)
        if (!state.allIds.includes(payload.id)) newState.allIds.push(payload.id)
      })

    case 'CLEAR_REPORT_FORM':
      return produce(state, (newState) => {
        newState.form.steps.items = newState.form.steps.items.map((item, index) => {
          return { ...item, disabled: !(index === 0), completed: false, showFeedback: false }
        })
        newState.form.steps.current = 0
        //newState.wip = {}
        //newState.current = {}
      })

    case 'FETCH_REPORTS_CATEGORIES_START':
      return produce(state, (newState) => {
        newState.loadingCategories = true
      })

    case 'FETCH_REPORTS_CATEGORIES_SUCCESS':
      if (!payload?.categories) {
        return { ...state, loadingCategories: false }
      }
      return produce(state, (newState) => {
        newState.categoryOptions = Object.keys(payload.categories).reduce((acc, current) => {
          if (current !== 'time' && (payload.loginProfile === 'DEV' || jsonGroup[current]?.levelAccess === 'basic'))
            acc = [...acc, { key: current, text: current, value: current }]
          return acc
        }, [])
        newState.loadingCategories = false
      })

    case 'FETCH_REPORTS_MEASURES_START':
      return produce(state, (newState) => {
        const category = state.wip.filters?.category || state.current.filters?.category
        if (category) {
          newState.measuresByCategory[category] = {
            loading: true,
          }
        }
      })

    case 'FETCH_REPORTS_MEASURES_SUCCESS':
      return produce(state, (newState) => {
        const category = state.wip.filters?.category || state.current.filters?.category
        if (category) {
          newState.measuresByCategory[category] = {
            measures: payload,
            loading: false,
          }
        }
      })

    case 'FETCH_REPORTS_MEASURES_FAILURE':
      return produce(state, (newState) => {
        const category = state.wip.filters?.category || state.current.filters?.category
        if (category) {
          newState.measuresByCategory[category] = {
            loading: false,
          }
        }
      })

    case 'FETCH_REPORTS_MEASURES_HISTORY_START':
      return produce(state, (newState) => {
        newState.loadingItem = true
      })

    case 'FETCH_REPORTS_MEASURES_HISTORY_SUCCESS':
      return produce(state, (newState) => {
        newState.result = payload
        newState.loadingItem = false
      })

    case 'FETCH_REPORTS_MEASURES_HISTORY_FAILURE':
      return produce(state, (newState) => {
        newState.loadingItem = false
        newState.result = []
      })

    case 'DELETE_REPORTS_START':
      return { ...state, loading: true }

    case 'DELETE_REPORTS_SUCCESS':
      if (!payload.ids) return state
      return produce(state, (newState) => {
        newState.allIds = newState.allIds.filter((each) => !payload.ids.includes(each))
        newState.byId = omit(newState.byId, [payload.ids])
        newState.loading = false
      })

    case 'DELETE_REPORTS_FAILURE':
      return { ...state, loading: false }

    case 'DELETE_REPORT_SUCCESS':
      return produce(state, (newState) => {
        delete newState.byId[payload.id]
        newState.allIds = state.allIds.filter((each) => {
          return each !== payload.id
        })
      })

    case 'GET_REPORT_START':
      return produce(state, (newState) => {
        newState.loadingItem = true
      })

    case 'GET_REPORT_SUCCESS':
      return produce(state, (newState) => {
        const report = payload[0]
        if (report?.access === 'PUBLIC' || email === report?.createdBy) {
          const parsedReport = parseReport(report)
          newState.byId[report.id] = parsedReport
          newState.current = parsedReport
          newState.allIds = [report.id]
        }
      })

    case 'GET_REPORT_FAILURE':
      return produce(state, (newState) => {
        newState.loadingItem = false
      })

    default:
      return state
  }
}
