import React from 'react'
import DropdownMenu from 'Utils/DropdownMenu'
import styled from 'styled-components'

/*  Author: Bruno Melo
    Type: Dumb
	Description: Search component appearance and visual effects
	TODO: Change dropdown to support multiple selection options

const SearchBoxStyled = styled.div`
  width: ${(props) => (props.isMobileSized ? (props.windowWidth > 320 ? 60 : 45) : 25)}vw;
  // font-size: 15px;
  height: 37px;
  vertical-align: middle;
  // color: #67676; 
  `
  */

const SearchBoxStyled = styled.div`
  width: ${(props) => (props.isMobileSized ? (props.windowWidth > 320 ? 50 : 35) : 25)}vw;
`
const SearchBox = (props) => {
  return (
    <SearchBoxStyled isMobileSized={props.isMobileSized} windowWidth={props.windowWidth}>
      <DropdownMenu {...props} />
    </SearchBoxStyled>
  )
}

export default SearchBox
