import React from 'react'
//import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
/*  
  Author: Bruno Melo
  Type: Dumb
  Description:Define the Grid format for the buttons on navbar;
              Separate the props of each part of the grid;				 
  To do list:-Use a array of objects to mount the buttons				
*/

import AvatarMenu from 'Components/Header/NavBar/Dropdowns/AvatarMenu/AvatarMenu'
import MobileMenu from 'Components/Header/NavBar/Dropdowns/MobileMenu'
// import DropdownDevs from 'Components/Header/NavBar/Dropdowns/DropdownDevs'
import DropdownUnits from 'Components/Header/NavBar/Dropdowns/DropdownUnits'
import DropdownPeople from 'Components/Header/NavBar/Dropdowns/Assets/DropdownPeople'
import DropdownAssets from 'Components/Header/NavBar/Dropdowns/Devices/DropdownAssets'
import DropdownHistory from 'Components/Header/NavBar/Dropdowns/DropdownHistory'
import DropdownNotification from 'Components/Header/NavBar/Dropdowns/DropdownNotification'
import DropdownEmergency from 'Components/Header/NavBar/Dropdowns/DropdownEmergency'
import { useSelector } from 'react-redux'
import UploadModal from './Dropdowns/UploadModal'
import DashboardTitle from 'Components/SidebarMenu/Contents/Dashboard/DashboardTitle'
import DropdownGeomodules from 'Components/Header/NavBar/Dropdowns/DropdownGeomodules'

export const NavButtonsGrid = (Props) => {
  const displayingMap = useSelector((state) => state.toggle.displayingMap)
  let displayingNavButtons
  if (displayingMap) {
    displayingNavButtons = [
      // <Button basic key="dropdev">
      //   {Props.perfil === 'DEV' && <DropdownDevs />}
      // </Button>,
      <Button basic key="dropdownEmergency">
        <DropdownEmergency />
      </Button>,
      <Button basic key="dropdownHistory">
        <DropdownHistory />
      </Button>,
      <Button basic key="DropdownAssets">
        <DropdownAssets />
      </Button>,
      <Button basic key="dropdownAsset">
        <DropdownPeople />
      </Button>,
      <Button basic key="dropdownRefs">
        <DropdownGeomodules
          type={'refs'}
          mainIcon={'map marker alternate'}
          perfil={Props.perfil}
          refMap={Props.refMap}
        />
      </Button>,
      <Button basic key="dropdownRoutes">
        <DropdownGeomodules type={'routes'} mainIcon={'road'} perfil={Props.perfil} refMap={Props.refMap} />
      </Button>,
      <Button basic key="dropdownFences">
        <DropdownGeomodules type={'fences'} mainIcon={'th large'} perfil={Props.perfil} refMap={Props.refMap} />
      </Button>,
      <Button basic key="dropdownUnits">
        <DropdownUnits indoor={Props.indoor} perfil={Props.perfil} refMap={Props.refMap} />
      </Button>,
      <Button basic key="dropdownNoification">
        <DropdownNotification />
      </Button>,
    ]
  } else {
    displayingNavButtons = [
      <Button basic key="dropdownNotification">
        <DropdownNotification />
      </Button>,
    ]
  }

  return (
    <>
      {!displayingMap && <DashboardTitle />}
      <Button.Group icon compact>
        {Props.isMobileSized ? (
          <Button>
            <MobileMenu {...Props} />
          </Button>
        ) : (
          <Button.Group>{displayingNavButtons.map((button) => button)}</Button.Group>
        )}
        <Button basic className="boxButtonsNavbarItemImg">
          <AvatarMenu
            picture={Props.profile.picture}
            rights={Props.rights}
            lang={Props.lang}
            save={Props.saveLanguage}
            savenow={Props.saveLanguageNow}
            email={Props.email}
          />
        </Button>
      </Button.Group>
      <UploadModal />
    </>
  )
}
export default NavButtonsGrid
