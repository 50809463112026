import * as z from 'zod'

const stepOneSchema = z.object({
  // code: z.string().min(1, 'form.error.min').max(8, 'form.error.max'),
  // label: z.string().min(1, 'form.error.min').max(60, 'form.error.max'),
  scope: z.string().min(1, 'form.error.min'),
  // description: z.string().optional(),
})

export type StepOne = z.infer<typeof stepOneSchema>

export default stepOneSchema
