import axios from 'axios'
import { urlBase } from 'Apis/rest'
/*global localStorage*/
export const fetchInteractions = () => async (dispatch, getState) => {
  //let tenant = getState().login.empresa
  //let fetchTime = getState().interactions.fetchTime
  let assets = getState().assets.byId
  let res
  const jwt = localStorage.getItem('id_token')
  let authorization = { Authorization: 'Bearer ' + jwt }
  const headers = { headers: authorization }
  //console.log('Chamada do FecthInteractions', headers)
  // if(!fetchTime)
  try {
    dispatch({ type: 'FETCH_INTERACTIONS_START' })
    res = await axios.get(`${urlBase}/interactions`, headers).then((resp) => {
      //console.log('resposta', resp)
      return resp.data.result
    })
    dispatch({ type: 'FETCH_INTERACTIONS', payload: res, assets: assets })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_INTERACTIONS_FAILURE', payload: err })
  }
}
