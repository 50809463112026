import React from 'react'
import { Dropdown, Input, Form } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { setTableProp } from 'Store/actions/tables-action'
import styled from 'styled-components'

function HistoryStepsCategoriesDropdown() {
  const dispatch = useDispatch()
  const currentTableId = 'historySteps'
  const filterStepLoading = useSelector((state) => state.tables.byId.measuresHist.filterStepLoading)
  const selectedCategory = useSelector((state) => state.tables.byId[currentTableId].selectedCategory)
  const segSize = useSelector((state) => state.tables.byId[currentTableId].segSize)
  const filterStepError = useSelector((state) => state.tables.byId[currentTableId].filterStepError)

  let newoptions = [
    { key: 3, text: 'Aceleração Brusca', value: 'mov_aceleracao' },
    { key: 1, text: 'Buraco - Algoritmo 1', value: 'Buraco' },
    { key: 1, text: 'Buraco - Algoritmo 2', value: 'mov_buraco2' },
    // { key: 1, text: 'Buraco - Algoritmo 3', value: 'mov_buraco' },
    { key: 5, text: 'Conversão Brusca à direita', value: 'mov_conv_direita' },
    { key: 6, text: 'Converção Brusca à Esquerda', value: 'mov_conv_esquerda' },
    { key: 2, text: 'Frenagem Brusca', value: 'mov_frenagem' },
    { key: 4, text: 'Ondulação', value: 'mov_ondulacao' },
    { key: 7, text: 'Travessia Ondulada', value: 'mov_trav_elevada' },
  ]

  return (
    <Form.Group widths="equal" inline>
      <FormField inline error={false}>
        <label>Tipo de Ocorrência</label>
        <Dropdown
          selection
          search
          fluid
          placeholder="Selecionar Categoria"
          upward={false}
          options={newoptions}
          onChange={(a, { value }) => dispatch(setTableProp(`byId.${currentTableId}.selectedCategory`, [value]))}
          noResultsMessage={
            filterStepLoading === 2
              ? 'Carregando opções, aguarde...'
              : 'Nenhum resultado encontrado no filtro selecionado.'
          }
          loading={filterStepLoading === 2}
          value={selectedCategory[0]}
          error={filterStepError === 2}
        ></Dropdown>
      </FormField>

      <FormField inline error={false}>
        <label>Tamanho do trecho (m)</label>
        <Input
          type="number"
          min="10"
          max="1000"
          value={segSize}
          onChange={(e) => {
            console.log(e.target.value)
            dispatch(setTableProp(`byId.${currentTableId}.segSize`, e.target.value))
          }}
          fluid
        />
      </FormField>
    </Form.Group>
  )
}

const FormField = styled(Form.Field)`
  margin-bottom: 18px !important;
`

export default HistoryStepsCategoriesDropdown
