// DataLabelingYamazumi.tsx
import React, { useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  LabelList,
  Label,
} from 'recharts'
import { lightColors } from 'Utils/colorsFormat'
import { Header, Icon } from 'semantic-ui-react'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

interface DataLabelingYamazumiProps {
  thisData: any
  vaNva: any
  uniqueShifts: any
  uniquePhases: any
  uniqueVehicles: any
  setCurrentCar: React.Dispatch<React.SetStateAction<string>>
  setCurrentShift: React.Dispatch<React.SetStateAction<string>>
  vehicleParam: string
  shiftParam: string
  lastDataLabeling: any
  t: (key: string) => string
  valueAccessor: (attribute: string) => ({ payload }: { payload: any }) => number | string
}

const DataLabelingYamazumi: React.FC<DataLabelingYamazumiProps> = ({
  thisData,
  vaNva,
  uniqueShifts,
  uniquePhases,
  uniqueVehicles,
  setCurrentCar,
  setCurrentShift,
  vehicleParam,
  shiftParam,
  //   lastDataLabeling,
  t,
  valueAccessor,
}) => {
  const [exchange, setExchange] = useState(false)

  const lastDataLabeling = useObjSelector((state) => state.dataLabelings.wip)

  return (
    <>
      <div className="row" style={{ display: 'flex' }}>
        <div className="column" style={{ flex: 5 }}>
          <Header as="h3">{'Yamazumi'}</Header>
        </div>
        <div className="column">
          <Icon name="exchange" onClick={() => setExchange(!exchange)} />
        </div>
      </div>

      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          width={500}
          height={280}
          data={exchange ? vaNva : thisData}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 80]} />
          <Tooltip />
          <Legend
            content={() => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '3px',
                  marginLeft: '55px',
                }}
              >
                <Label>{t('shift1&2')}</Label>
              </div>
            )}
          />
          {exchange ? (
            <>
              <Bar key={'nva'} dataKey={'nva'} fill={lightColors('yellow')} stackId={'vaNva'}>
                <LabelList
                  valueAccessor={valueAccessor('nva')}
                  style={{ backgroundColor: 'white', fill: '#1b1c1d8f' }}
                />
              </Bar>
              <Bar key={'va'} dataKey={'va'} fill={lightColors('green')} stackId={'vaNva'}>
                <LabelList
                  valueAccessor={valueAccessor('va')}
                  style={{ backgroundColor: 'white', fill: '#1b1c1d8f' }}
                />
              </Bar>
            </>
          ) : (
            uniqueShifts.reduce((acc: any, shift = '') => {
              const combined = uniquePhases.map((phase: any, index: number) => {
                const dataKey = `${shift}_${phase?.index + 1}`
                return (
                  <Bar
                    key={dataKey}
                    dataKey={dataKey}
                    stackId={shift as string}
                    fill={lightColors(phase.color)}
                    onClick={(e, t) => {
                      setCurrentCar(uniqueVehicles[t]?.toString() || '')
                      setCurrentShift(shift as string)
                    }}
                    style={
                      phase?.vehicle === vehicleParam && shift === shiftParam
                        ? { border: '3px green solid' }
                        : { cursor: 'pointer' }
                    }
                  >
                    <LabelList
                      valueAccessor={valueAccessor(dataKey)}
                      style={{ backgroundColor: 'white', fill: '#1b1c1d8f' }}
                    />
                  </Bar>
                )
              })
              return [...acc, ...combined]
            }, [])
          )}

          <ReferenceLine
            y={lastDataLabeling?.taktTime ?? 0}
            label={`TT - ${lastDataLabeling?.taktTime}"`}
            stroke="red"
            isFront
          />
          <ReferenceLine
            y={lastDataLabeling?.targetCycleTime ?? 0}
            label={`TCT - ${lastDataLabeling?.targetCycleTime}"`}
            stroke="blue"
            strokeDasharray="3 3"
            isFront
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default DataLabelingYamazumi
