import React, { useState, useRef } from 'react'
import { Button, Header, Image, Modal, SegmentGroup, Segment, Label, Dimmer } from 'semantic-ui-react'
import { useReactToPrint } from 'react-to-print'
import styled from 'styled-components'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { GeoModule } from 'interfaces/modules'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { setFalse } from 'Store/actions/toggle-action'
import ModalCloseIcon from 'Utils/reactTable/components/ModalCloseIcon'
import { ReactComponent as LoadingLogo } from 'Resources/Icons/loading-logo.svg'
import AmpImage from 'Components/images/AmpImage'
import { RootStore } from 'Store/initialStore'
import { useTranslation } from 'react-i18next'

const BoldSpan = styled.span`
  font-weight: bold;
`

const StyledHeader = styled(Header)`
  margin: 0px !important;
`

interface CustomDetailsProps {
  type: 'refs' | 'fences'
}

const CustomDetails: React.FC<CustomDetailsProps> = ({ type }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const contentToPrint = useRef(null)

  const [loading, setLoading] = useState(false)
  const [contentMargin, setContentMargin] = useState('0px')

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: 'Print This Document',
    onAfterPrint: () => {
      setContentMargin('0px')
      setLoading(false)
    },
    removeAfterPrint: true,
  })

  const module = useObjSelector((state) => state.modules.current as GeoModule)

  const paths = module.properties?.customFields?.['af33153b-4ccf-45bd-a4f1-c250d26d229e']

  const email = useSelector((state: RootStore) => state.login.email)

  return (
    <Modal
      open
      onClose={() => {
        dispatch(setFalse('showModuleDetails'))
      }}
      closeIcon={
        <ModalCloseIcon
          action={() => {
            dispatch(setFalse('showModuleDetails'))
          }}
        />
      }
    >
      <Dimmer active={loading}>
        <div className="logoTelaCarregamento">
          <LoadingLogo />
        </div>
      </Dimmer>
      <Modal.Content>
        <div ref={contentToPrint} style={{ margin: contentMargin }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '99%' }}>
            <span style={{ fontSize: '12px' }}>
              {`MOD-000290 - Registro de Notificação e Interdição - Rev: 02 - Data: 29/07/2016`}
            </span>
            <span style={{ fontSize: '12px' }}>{`Por: ${email}`}</span>
          </div>

          <SegmentGroup>
            <Segment
              size="large"
              textAlign="center"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Image
                src="https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/Tiplam/miniLogo.png"
                size="tiny"
              />

              <StyledHeader size="tiny">{'REGISTRO DE NOTIFICAÇÃO E INTERDIÇÃO'}</StyledHeader>
              <Label circular color={'yellow'} size="large">
                {module.code ?? '137'}
              </Label>
            </Segment>

            <SegmentGroup horizontal>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Data: </BoldSpan>
                {moment(module.properties?.customFields?.data).format('DD/MM/YYYY')}
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Hora: </BoldSpan>
                {moment(module.properties?.customFields?.data).format('HH:mm')}
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Local: </BoldSpan>
                {module.properties?.customFields?.['1067e52a-756f-423a-bc06-77a929667513'] ?? 'Não Cadastrado'}
              </Segment>
            </SegmentGroup>
            <SegmentGroup horizontal>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>De: </BoldSpan>
                {module.properties?.customFields?.['3532694d-a7d4-4b86-b06a-eed2b33dfc1f'] ?? 'Não Cadastrado'}
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Gerência: </BoldSpan>Saúde Segurança e MA
              </Segment>
            </SegmentGroup>
            <SegmentGroup horizontal>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Para: </BoldSpan>
                {module.properties?.customFields?.['713c0a1c-f60b-440e-aa7b-594139687bf6'] ?? 'Não Cadastrado'}
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Gerência: </BoldSpan>
                {module.properties?.customFields?.gerenciaresponsa ?? 'Não Cadastrado'}
              </Segment>
            </SegmentGroup>

            <Segment style={{ flex: 1 }}>
              <BoldSpan>Área / Equipamento / Atividade: </BoldSpan>
              {module.properties?.customFields?.['1067e52a-756f-423a-bc06-77a929667513'] ?? 'Não Cadastrado'}
            </Segment>

            <Segment>
              {module.profile === '30298'
                ? 'Esta área / equipamento / atividade fica interditada por não atender as exigências mínimas de segurança, colocando em risco a integridade física dos empregados, conforme a Portaria3214/78'
                : '( X ) Notificação - Está notificado e orientado a tomar imediata providência evitando o risco de ocorrência deacidentes'}
            </Segment>

            <Segment secondary textAlign="center">
              <BoldSpan>Descrição da condição de risco</BoldSpan>
            </Segment>
            <Segment>{module.description ?? 'Não Cadastrado'}</Segment>

            <Segment secondary textAlign="center">
              <BoldSpan>
                Atendidas as recomendações abaixo, o Emitente deverá ser comunicado. Após correção, o campo “Liberação
                de área” deve ser preenchido pelo Responsável pela área
              </BoldSpan>
            </Segment>
            <Segment>
              <BoldSpan>Recomendações: </BoldSpan>
              {module.properties?.customFields?.['fb3ec3fe-717c-46a4-91cc-726c83800b59'] ?? 'Não Cadastrado'}
            </Segment>

            <SegmentGroup horizontal>
              <Segment secondary textAlign="center" style={{ flex: 1 }}>
                <BoldSpan>Assinatura Responsável pela área interditada / Testemunha: </BoldSpan>
              </Segment>
              <Segment secondary textAlign="center" style={{ flex: 1 }}>
                <BoldSpan>Assinatura do Emitente: </BoldSpan>
              </Segment>
            </SegmentGroup>
            <SegmentGroup horizontal>
              <Segment style={{ flex: 1, height: '50px' }}></Segment>
              <Segment style={{ flex: 1, height: '50px' }}></Segment>
            </SegmentGroup>

            <Segment secondary textAlign="center">
              <BoldSpan>Imagens</BoldSpan>
            </Segment>

            {Array.isArray(paths) && paths.length > 0 ? (
              <Segment textAlign="center">
                <Image.Group size="small" style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {paths.map(({ path }, index) => {
                    return <AmpImage path={path} key={index} />
                  })}
                </Image.Group>
              </Segment>
            ) : (
              <Segment textAlign="center">Nenhuma imagem cadastrada</Segment>
            )}
            <Segment>
              <BoldSpan>Providências: </BoldSpan>
              {module.properties?.customFields?.['624601e3-5f87-4494-9978-2fa7ffcdda94'] ?? 'Não Cadastrado'}
            </Segment>
            <Segment>
              <BoldSpan>Observação(es): </BoldSpan>
              {module.properties?.customFields?.['04b20597-9ae6-41f7-861c-e36da7da702a'] ?? 'Não Cadastrado'}
            </Segment>
            <Segment secondary textAlign="center">
              <BoldSpan>Liberação da área</BoldSpan>
            </Segment>
            <SegmentGroup horizontal>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Nome: </BoldSpan>
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Assinatura: </BoldSpan>
              </Segment>
            </SegmentGroup>
            <SegmentGroup horizontal>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Gerência: </BoldSpan>
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Data: </BoldSpan>
              </Segment>
            </SegmentGroup>
          </SegmentGroup>
        </div>
      </Modal.Content>

      <Modal.Actions style={{ height: '65px' }}>
        <Button
          onClick={() => dispatch(setFalse('showModuleDetails'))}
          style={{ float: 'left', minWidth: '120px' }}
          icon="close"
          content={t('Close')}
        />

        <Button
          onClick={() => {
            setLoading(true)
            setContentMargin('15px')
            setTimeout(() => {
              handlePrint()
            }, 100)
          }}
          icon="print"
          content={t('print')}
          style={{ backgroundColor: '#3498db', color: 'white' }}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default CustomDetails
