import React from 'react'
import { List, Label, Placeholder, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import m from 'moment'

const ItemLI = styled(List.Item)`
  padding-right: 20px;
`
export const ItemDetailsField = ({ li, value, tenant, unreliableAlert, loading }) => {
  const [show, setShow] = React.useState(false)
  const unregisteredText = tenant === 'Tiplam' ? 'Não cadastrado no Suricato' : 'Não Cadastrado'
  const { t } = useTranslation()
  const liValue = (li) => {
    if (li?.type?.toLowerCase()?.startsWith('timestamp')) return value ? m(value).format('lll') : unregisteredText
    if (li?.type?.toLowerCase()?.startsWith('array')) return value ? value.toString() : unregisteredText
    return value || unregisteredText
  }

  return tenant === 'Tiplam' ? (
    <ItemLI onClick={() => setShow(!show)}>
      {show && <Label pointing="below">{li.desc}</Label>}
      <List.Header color="green">{t(li.label)}</List.Header>
      {loading ? (
        <Placeholder fluid>
          <Placeholder.Line length="short" />
        </Placeholder>
      ) : value && unreliableAlert ? (
        <>
          <div style={{ display: 'inline' }}>
            <Icon name="exclamation triangle" color="yellow" />
          </div>
          <span>{liValue(li)}</span>
        </>
      ) : (
        liValue(li)
      )}
    </ItemLI>
  ) : (
    <ItemLI onClick={() => setShow(!show)}>
      {show && li.desc && <Label pointing="below">{li.desc}</Label>}
      <List.Header color="green">{t(li.label)}</List.Header>
      {value ? (li.type === 'time' ? m(value).format('lll') : value) : unregisteredText}
    </ItemLI>
  )
}
export default ItemDetailsField
