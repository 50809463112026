import React, { useEffect, useState, useMemo } from 'react'
//import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'Utils/reactTable'
//import { fetchRules, clearRules, subscribeRuleChange, subscribeRuleDelete } from 'Store/actions/rules-action'
import { RootStore } from 'Store/initialStore'
import { SimulationsColumns } from './columns'
import { useTranslation } from 'react-i18next'
import { saveColumnsPreference } from 'Store/actions/login-action'
import useAsync from 'Utils/hooks/useAsync'
import { fetchSimulations, deleteSimulation } from 'Apis/simulation'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'

const tableName = 'simulations'
const menuButtons = ['new', 'edit', 'delete', 'refresh' /* , 'run', 'stop' */]

export const SimulationsContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tableLabel = 'Simulações'

  const { value, execute, status /* , error  */ } = useAsync(fetchSimulations, true)

  const deleteAction = async ({ ids }: { ids: string[] }) => {
    try {
      await deleteSimulation(ids[0])
      execute()
      notifySuccess(/* 'Simulação excluída com sucesso' */)
    } catch (e) {
      notifyError()
      console.error(e)
    }
  }

  // let hiddenColumns = ['status', 'extra', 'updatedBy', 'updatedFrom', 'createdBy', 'createdFrom']
  const hiddenColumns = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns
  )
  const groupedValuesColumns = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = useMemo(() => SimulationsColumns(t, execute), [t, execute])

  const rows = value?.allIds || []
  const byId = value?.byId || {}
  const data = useMemo(() => rows.map((id: string) => byId[id]), [rows, byId])

  return (
    <ReactTable
      table={tableName}
      tableLabel={tableLabel}
      hiddenColumns={hiddenColumns}
      columns={columns}
      data={data}
      fetchAction={execute}
      deleteAction={deleteAction}
      initialSortBy={{ desc: false, id: 'label' }}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      showPlaceholders={status === 'pending'}
      menuButtons={menuButtons}
    />
  )
}
