import React from 'react'
import loading from './loading.svg'
import styled from 'styled-components'
/*  Author: Felipe Pegado
    Type: Dumb
    Description: loading page;
    To do list: -Verificar redundância com a tela de carregamento
                -transforma em função jsx
                -styled callback
*/
const Callback = () => (
  <StyledCallback>
    <img src={loading} alt="loading" />
  </StyledCallback>
)

const StyledCallback = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
`

export default Callback
