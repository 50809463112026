import React, { Fragment } from 'react'
import { Step, Icon } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { setTableProp } from 'Store/actions/tables-action'
import TableSpan from 'Components/SidebarMenu/TableSpan'
import { useTranslation } from 'react-i18next'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

function MeasuresHistFilterHeader({ disabled }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const currentTableId = useSelector((state) => state.tables.current?.id ?? 'measuresHist')
  const assets = useObjSelector((state) => state.assets.byId)
  const filters = useObjSelector((state) => state.tables.byId[currentTableId].filters)
  const currentFilterStep = useSelector((state) => state.tables.byId[currentTableId].currentFilterStep || 0)
  const filterStepLoading = useSelector((state) => state.tables.byId.measuresHist.filterStepLoading)
  const selectedRelativeTime = useObjSelector((state) => state.tables.byId[currentTableId].selectedRelativeTime)
  const selectedAbsoluteStartTime = useObjSelector(
    (state) => state.tables.byId[currentTableId].selectedAbsoluteStartTime
  )
  const selectedAbsoluteEndTime = useObjSelector((state) => state.tables.byId[currentTableId].selectedAbsoluteEndTime)
  const selectedAsset = useObjSelector((state) => state.tables.byId[currentTableId].selectedAsset)
  const selectedCategory = useObjSelector((state) => state.tables.byId[currentTableId].selectedCategory)
  const selectedMeasures = useObjSelector((state) => state.tables.byId[currentTableId].selectedMeasures)

  return (
    <Step.Group widths={filters.length} size="tiny">
      {filters.map((step, i) => {
        const currentSelected =
          (step.id === 'relativeTime' &&
            selectedRelativeTime
              .map((e) => {
                if (e === 'absoluteTime') return ['Personalizado']
                return t(e)
              })
              .join(',')) ||
          (step.id === 'asset' &&
            selectedAsset.length > 0 &&
            selectedAsset.map((e) => (e === '<any>' ? 'Qualquer Pessoa' : assets[e]?.name)).join(', ')) ||
          (step.id === 'category' && selectedCategory.length > 0 && selectedCategory.map((e) => t(e)).join(', ')) ||
          (step.id === 'measure' && selectedMeasures.length > 0 && selectedMeasures.map((e) => t(e)).join(', '))

        const isActive = i === currentFilterStep
        const isAssetStepEnabledIfAbsoluteTime =
          selectedRelativeTime.includes('absoluteTime') && selectedAbsoluteStartTime && selectedAbsoluteEndTime
        const isAssetStepEnabledIfRelativeTime =
          selectedRelativeTime.length > 0 && !selectedRelativeTime.includes('absoluteTime')
        const isAssetStepEnabled = isAssetStepEnabledIfAbsoluteTime || isAssetStepEnabledIfRelativeTime
        const isEnabled =
          isActive ||
          (['relativeTime', 'asset'].includes(step.id) && isAssetStepEnabled) ||
          (step.id === 'category' && selectedAsset.length > 0) ||
          (step.id === 'measure' && selectedCategory.length > 0)
        const isLoading = i === filterStepLoading
        const completed = currentSelected.length > 0
        return (
          <Fragment key={i}>
            <Step
              active={isActive}
              disabled={disabled || !isEnabled}
              onClick={() => isEnabled && dispatch(setTableProp(`byId.${currentTableId}.currentFilterStep`, i))}
              completed={completed}
            >
              <Icon name={isLoading ? 'refresh' : step.iconName} loading={isLoading} />
              <Step.Content>
                <Step.Title>{step.title}</Step.Title>
                <Step.Description>
                  {completed ? <TableSpan value={currentSelected} width="200px" /> : step.description}
                </Step.Description>
              </Step.Content>
            </Step>
          </Fragment>
        )
      })}
    </Step.Group>
  )
}

export default MeasuresHistFilterHeader
