import * as z from 'zod'

// Definindo os enums
const CustomFieldTypes = z.enum([
  'boolean',
  'date',
  'dateTime',
  'list',
  'multiple',
  'number',
  'text',
  'textArea',
  'checkList',
  'images',
  'section',
])

// Definindo o schema CustomField
const customFields = z
  .array(
    z.object({
      id: z.string(),
      label: z.string().min(1),
      type: CustomFieldTypes,
      options: z.array(z.string()).optional().nullable(),
    })
  )
  .optional()
  .nullable()

const StepTwoSchema = z.object({
  customFields,
})

export default StepTwoSchema
