import React from 'react'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

interface GraphFallbackProps {
  retry: () => void
}

export const GraphFallback = ({ retry }: GraphFallbackProps) => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20,
      }}
    >
      <p>{t('dashboardArea.graphs.fallback.title')}</p>
      <Button onClick={retry}>{t('dashboardArea.graphs.fallback.buttonText')}</Button>
    </div>
  )
}
