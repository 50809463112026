import { Dispatch } from 'redux'
import { insertStopped, setModulesProps } from 'Store/actions/modules-action'
import { setFalse } from 'Store/actions/toggle-action'
import { RootStore } from 'Store/initialStore'

interface ConfirmCloseParams {
  wip: RootStore['modules']['wip']
  dispatch: Dispatch
  type?: 'fences' | 'refs' | 'routes' | null
}

const confirmClose = ({ wip, dispatch, type }: ConfirmCloseParams): boolean => {
  const isUpdate = Boolean(wip.id)
  const noChanges = isUpdate ? Object.keys(wip).length <= 1 : Object.keys(wip).length === 0

  const resetState = () => {
    dispatch(setFalse('showStatsBar'))
    dispatch(
      setModulesProps([
        ['wip', {}],
        ['current', undefined],
      ])
    )
    dispatch(insertStopped({ moduleType: type }))
    return true
  }

  if (noChanges || window.confirm('Tem certeza que deseja perder as modificações feitas?')) {
    return resetState()
  }

  return false
}

export default confirmClose
