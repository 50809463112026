import React, { Fragment } from 'react'
import { Icon } from 'semantic-ui-react'

const MissingSignals = (props) => {
  return props.signals.map((s, index) => {
    return s.name ? (
      <Fragment key={'MissingSignals' + s.name + index}>
        <Icon
          size={s.size || 'large'}
          name={s.name || 'exclamation'}
          color={s.color || 'red'}
          style={{ marginLeft: '-2px', marginTop: '-2px' }}
        />
        {props.description && <em>{s.text}</em>}
      </Fragment>
    ) : (
      <Fragment key={'MissingSignals' + s.name + index}>
        <Icon.Group style={{ marginRight: '3px' }}>
          <Icon name={s} style={{ marginTop: '-1px' }} />
          <Icon size="large" name="dont" color="yellow" style={{ marginLeft: '-2px', marginTop: '-2px' }} />
        </Icon.Group>
        {props.description && <em>{'Dado sem GPS válido'}</em>}
      </Fragment>
    )
  })
}
export default MissingSignals
