import axios from 'axios'
import { urlBase, URL_BASE } from 'Apis/rest'
import { API } from 'aws-amplify'
import { listNotifications, listNotificationsByType } from 'graphql/queries'
import { onChangeNotification } from 'graphql/subscriptions'
import { createNotification, updateNotification } from 'graphql/mutations'

export const TOUCH_NOTIFICATION = 'TOUCH_NOTIFICATION'
export const FETCH_NOTIFICATION_COUNT = 'FETCH_NOTIFICATION_COUNT'
export const CLEAR_NOTIFICATIONS = 'server/clearNotification'
export const HIDE_NEW_NOTIFICATION = 'hideNewNotification'
export const NOTIFICATION_COUNT = 'notificationCount'

//var array_payload = [];
export const setNotificationProps = (payload) => {
  return {
    type: 'SET_NOTIFICATION_PROPS',
    payload,
  }
}

export const touchNotification = (msgId) => (dispatch, getState) => {
  let email = getState().login.email
  dispatch({
    type: TOUCH_NOTIFICATION,
    payload: {
      msgId,
      email,
    },
  })
}

export const clearNotifications = () => (dispatch, getState) => {
  const email = getState().login.email
  dispatch({
    type: CLEAR_NOTIFICATIONS,
    payload: {
      email,
      time: Date.now(),
    },
  })
}

export const hideNewNotification = (id) => ({
  type: HIDE_NEW_NOTIFICATION,
  id,
})

export const fetchMessages =
  (page = 0) =>
  async (dispatch) => {
    const jwt = localStorage.getItem('id_token')
    try {
      dispatch({
        type: 'FETCH_NOTIFICATION_START',
        payload: page,
      })
      axios
        .get(urlBase + '/notifications', {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
          params: {
            page,
          },
        })
        .then((res) =>
          dispatch({
            type: 'FETCH_NOTIFICATION_SUCCESS',
            payload: res.data,
          })
        )
    } catch (err) {
      console.error(err)
      dispatch({
        type: 'FETCH_NOTIFICATION_FAILURE',
        payload: err,
      })
    }
  }

export const feedbackMessage = (params, input) => async (dispatch) => {
  try {
    const jwt = localStorage.getItem('id_token')
    dispatch({
      type: 'NOTIFICATION_FEEDBACK_START',
      payload: { id: input.id },
    })
    await axios.post(URL_BASE + '/sos/confirm', params, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch(mutateNotification({ input, mutation: 'update' }))
  } catch (err) {
    console.error('NOTIFICATION_FEEDBACK_ERROR', err)
    dispatch({
      type: 'NOTIFICATION_FEEDBACK_ERROR',
      payload: { id: input.id },
    })
  }
}

export const broadcastMessage =
  (params, notificationInput, mutation = 'create') =>
  async (dispatch) => {
    try {
      const jwt = localStorage.getItem('id_token')
      if (mutation === 'update') {
        dispatch({
          type: 'NOTIFICATION_FEEDBACK_START',
          payload: { id: notificationInput.id },
        })
      }
      dispatch({
        type: 'NOTIFICATION_BROADCAST_START',
        payload: params,
      })
      const emergencyRes = await axios.post(urlBase + `/emergency/evacuation/trigger`, params, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      await dispatch(
        mutateNotification({
          input: notificationInput,
          mutation,
        })
      )
      dispatch({
        type: 'NOTIFICATION_BROADCAST_SUCCESS',
        payload: emergencyRes?.data,
      })
    } catch (err) {
      console.error(err)
      dispatch({
        type: 'NOTIFICATION_BROADCAST_ERROR',
        payload: err,
      })
    }
  }

export const changeCurrentPage = (page) => ({
  type: 'CHANGE_NOTIFICATION_PAGE',
  payload: page,
})

export const fetchNotifications = (nextToken) => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  const nextToken = getState().notification.nextToken
  const initialFetch = getState().notification.initialFetch
  if (!nextToken && !initialFetch) return
  try {
    dispatch({ type: 'FETCH_NOTIFICATIONS_START', tenant })
    const response = await API.graphql({
      query: listNotifications,
      variables: { tenant, limit: 10, nextToken, sortDirection: 'DESC' },
    })
    const payload = response.data.listNotifications
    dispatch({
      type: 'FETCH_NOTIFICATION_SUCCESS',
      payload,
    })
  } catch (err) {
    console.error('error fetching notifications', err)
  }
}

export const subscribeNotificationChange = (tenant, login) => (dispatch) => {
  return API.graphql({
    query: onChangeNotification,
    variables: {
      tenant,
    },
  }).subscribe({
    next: ({ provider, value }) => {
      const payload = value?.data?.onChangeNotification
      dispatch({ type: 'NEW_NOTIFICATION', payload, login })
    },
    error: (error) => console.warn('error', error),
  })
}

export const mutateNotification =
  ({ input, mutation }) =>
  async (dispatch) => {
    dispatch({ type: 'MUTATE_NOTIFICATION_START', input, mutation })
    try {
      var response = await API.graphql({
        query: mutation === 'create' ? createNotification : updateNotification,
        variables: {
          input,
        },
      })
    } catch (err) {
      var error = err
      console.error('error:', err)
    }
    if (!error) dispatch({ type: 'MUTATE_NOTIFICATION_SUCCESS', payload: response?.data?.[`${mutation}Notification`] })
  }

export const stopEmergency = () => ({ type: 'EMERGENCY_STOP' })

export const stopSound = () => ({ type: 'SOUND_STOP' })

export const setEmergencyBounds = () => ({ type: 'SET_EMERGENCY_BOUNDS' })

export const setCurrentNotification = (id) => ({ type: 'SET_CURRENT_NOTIFICATION', payload: { id } })

export const fetchEmergency = (nextToken) => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  try {
    dispatch({ type: 'SEARCH_NOTIFICATIONS_START', tenant })
    const response = await API.graphql({
      query: listNotificationsByType,
      variables: { tenant, type: { eq: 'evacuation' }, nextToken },
    })

    const payload = response.data.listNotificationsByType
    if (payload.nextToken) dispatch(fetchEmergency(payload.nextToken))
    dispatch({
      type: 'SEARCH_NOTIFICATIONS_SUCCESS',
      payload,
    })
  } catch (err) {
    console.error('error search notifications', err)
  }
}
