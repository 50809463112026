function aggregateStepsByPhaseIndex(labelSteps: any) {
  const phaseSums = {}
  const phaseCounts = {}

  // Loop through each entry and sum steps by phaseIndex
  labelSteps.forEach(({ phaseIndex, steps }: { phaseIndex: number; steps: number }) => {
    if (!phaseSums[phaseIndex]) {
      phaseSums[phaseIndex] = 0
      phaseCounts[phaseIndex] = 0
    }
    phaseSums[phaseIndex] += steps
    phaseCounts[phaseIndex]++
  })

  // Transform sums into averages and round them to nearest integer
  return Object.keys(phaseSums).map((index) => ({
    phaseIndex: parseInt(index),
    steps: Math.round(phaseSums[index] / phaseCounts[index]),
  }))
}

export default aggregateStepsByPhaseIndex
