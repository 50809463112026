/*  
  Author: Luís Mourão
  Type: Reducer
  
  Description: 
    - 
    - 
  TODO: 
*/

import initialStore from 'Store/initialStore'
import set from 'immutable-set'
import { union } from 'lodash'
import getSafe from 'Utils/getSafe.js'
import produce from 'immer'

export default function tablesReducer(state = initialStore.tables, { type, id, payload, prop, value }) {
  const formatTheDuration = (seconds = 0, rate = 1) => {
    let duration = seconds * rate
    return (
      Math.floor(duration / 3600) // Horas
        .toString()
        .padStart(2, '0') +
      ':' +
      Math.floor((duration % 3600) / 60) // Minutos
        .toString()
        .padStart(2, '0') +
      ':' +
      Math.round(duration % 60) //Segundos
        .toString()
        .padStart(2, '0')
    ).toString()
  }
  const splitType = type.split('#')[0]

  switch (splitType) {
    case 'SET_TABLE_AUTO_UPDATE': {
      const auxState = set(state, `byId.${id}.autoUpdate`, payload)
      return set(auxState, `current.autoUpdate`, payload)
    }

    case 'DELETE_RULE':
      return set(state, 'byId.rules.autoUpdate', true)

    case 'SET_TABLE_SHOW_MODAL':
      const stepState = set(state, `current.showModal`, payload)
      return set(stepState, `byId.${id}.showModal`, payload)

    case 'FETCH_RULES_START':
      return set(state, `byId.rules.loading`, true)

    case 'FETCH_RULES':
      return set(state, `byId.rules.loading`, false)

    case 'FETCH_RULES_FAILURE':
      return set(state, `byId.rules.loading`, false)

    case 'SET_TABLE_SHOW_INPUT':
      return set(state, `byId.${id}.showInput`, payload)

    case 'FETCH_ASSETS_ASSOCIATIONS_START':
      return set(state, `byId.associations.loading`, true)

    case 'FETCH_ASSETS_ASSOCIATIONS_SUCCESS':
      return set(state, `byId.associations.loading`, false)

    case 'FETCH_LOCATION_HIST_START':
      return set(state, `byId.locationHist.loading`, true)

    case 'FETCH_LOCATION_HIST_SUCCESS':
      return set(state, `byId.locationHist.loading`, false)

    case 'FETCH_HISTORY_START':
      return set(state, `byId.measuresHist.loading`, true)

    case 'FETCH_HISTORY_SUCCESS':
      return set(state, `byId.measuresHist.loading`, false)

    case 'SET_TABLE_PROP':
      return set(state, prop, value)

    case 'FETCH_TABLE_CATEGORIES_START':
      return set(state, 'byId.measuresHist.filterStepLoading', 2)

    case 'FETCH_TABLE_CATEGORIES_SUCCESS':
      return {
        ...state,
        byId: set(
          state.byId,
          ['measuresHist', ['filterStepLoading', 'categoriesOptions']],
          [undefined, getSafe(() => Object.keys(payload), [])]
        ),
      }

    case 'FETCH_TABLE_CATEGORIES_FAILURE':
      return set(state, 'byId.measuresHist.filterStepLoading', undefined)

    case 'TOGGLE_TABLE_BOOLEAN':
      return set(state, `byId.${id}.${prop}`, !state.byId[id][prop])

    case 'FETCH_ASSETS_BY_MEASURES_TIME_START':
      return set(state, 'byId.measuresHist.filterStepLoading', 1)

    case 'FETCH_ASSETS_BY_MEASURES_TIME_SUCCESS':
      return {
        ...state,
        byId: set(state.byId, ['measuresHist', ['filterStepLoading', 'assetsOptions']], [undefined, payload]),
      }

    case 'FETCH_TABLE_MEASURES_START':
      return set(state, 'byId.measuresHist.filterStepLoading', 3)

    case 'FETCH_TABLE_MEASURES_SUCCESS':
      return {
        ...state,
        byId: set(state.byId, ['measuresHist', ['filterStepLoading', 'measuresOptions']], [undefined, payload]),
      }

    case 'FETCH_TABLE_MEASURES_HISTORY_START':
      return {
        ...state,
        byId: set(state.byId, [id, ['loading', 'showModal']], [true, false]),
      }

    case 'FETCH_TABLE_MEASURES_HISTORY_SUCCESS':
      return produce(state, (newState) => {
        newState.error = null
        newState.byId[id].loading = false
        newState.byId[id].data = payload || []
      })

    case 'FETCH_LOGIN_PREFERENCES_SUCCESS':
      if (!payload) return state
      else {
        const createdPanels = payload.routesPanels?.filter((e) => e.type === 'created') || []
        return {
          ...state,
          allIds: state.allIds.concat(createdPanels.map((e) => e.id)),
          byId: {
            ...state.byId,
            ...createdPanels.reduce(
              (a, c) => ({
                ...a,
                [c.id]: {
                  ...c,
                  selectedRelativeTime: c.filters?.find((e) => e.id === 'relativeTime')?.value,
                  selectedAsset: c.filters?.find((e) => e.id === 'asset')?.value,
                  selectedCategory: c.filters?.find((e) => e.id === 'category')?.value,
                  selectedMeasures: c.filters?.find((e) => e.id === 'measure')?.value,
                },
              }),
              {}
            ),
          },
        }
      }

    case 'SET_NEW_ROUTE_PANEL': {
      const auxState = set(state, 'byId', {
        ...state.byId,
        [value.id]: {
          ...value,
          selectedRelativeTime: value?.filters[0]?.value,
          selectedAsset: value?.filters[1]?.value,
          selectedCategory: value?.filters[2]?.value,
          selectedMeasures: value?.filters[3]?.value,
        },
      })
      if (state.allIds.includes(value.id)) return auxState
      else return set(auxState, 'allIds', [value.id, ...state.allIds])
    }

    // case 'NEW_ASSET_LOCATION': {
    //   return produce(state, (newState) => {
    //     if (newState.byId.motion?.data?.[payload.id] && payload.motion) {
    //       newState.byId.motion.data[payload.id] = newState.byId.motion.data[payload.id]
    //         .filter((e, i) => i !== 0)
    //         .concat(JSON.parse(payload.motion))
    //     }
    //   })
    // }

    case 'FETCH_TABLE_MEASURES_HISTORY_FAILURE':
      return {
        ...state,
        error: payload,
        byId: set(state.byId, [id, ['loading', 'data']], [false, []]),
      }

    // please refactor this ; I just wanna set showModal to true
    case 'SELECT_DATE_REPORTS_OPEN':
      return {
        ...state,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            showDateModal: true,
            reportType: payload,
            reportViewForm: id,
          },
        },
      }

    case 'SELECT_DATE_REPORTS_REOPEN':
      return produce(state, (newState) => {
        newState.byId.reports.showDateModal = true
      })

    case 'SELECT_DATE_REPORTS_CLOSE':
      return produce(state, (newState) => {
        newState.byId.reports.fetchLoading = false
        newState.byId.reports.fetchSuccess = false
        newState.byId.reports.showDateModal = false
        newState.byId.reports.reportData = ''
      })

    case 'FETCH_REPORT_START':
      return produce(state, (newState) => {
        newState.byId.reports.fetchLoading = true
        newState.byId.reports.fetchSuccess = false
        newState.byId.reports.fetchInterval = payload
      })

    case 'FETCH_REPORT_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            fetchLoading: false,
            fetchSuccess: true,
            reportData: payload,
            reportPayload: payload,
            reportRows: payload.filter((p, i) => i !== 0),
            reportColumns: payload[0],
          },
        },
      }

    case 'FETCH_REPORT_FAILURE':
      return {
        ...state,
        error: payload,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            fetchLoading: false,
            fetchSuccess: false,
            reportData: '',
            showDateModal: false,
          },
        },
      }

    case 'FETCH_REPORT_CLEAR_STATE':
      return produce(state, (newState) => {
        newState.error = payload
        const reports = newState.byId.reports
        reports.fetchLoading = false
        reports.fetchSuccess = false
        reports.reportData = ''
        reports.reportColumns = ''
        reports.reportPayload = ''
        reports.reportViewForm = ''
        reports.reportType = ''
        reports.showDateModal = false
        reports.headerInfo = []
      })

    case 'EXPORT_FENCES_DASHBOARD_REPORT':
      return {
        ...state,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            fetchLoading: false,
            fetchSuccess: true,
          },
        },
      }

    case 'FETCH_TABLE_NOTIFICATIONS_START':
      return {
        ...state,
        byId: set(state.byId, [id, ['loading', 'byId', 'allIds']], [true, { ...state.byId[id].allIds }, []]),
      }

    case 'FETCH_TABLE_NOTIFICATIONS_SUCCESS':
      const notificationsById = {
        ...state.byId[id].byId,
        ...payload.reduce((res, notification) => {
          return { ...res, [notification.id]: notification }
        }, {}),
      }
      const allNotificationsIds = union(
        state.byId[id].allIds,
        payload.map((notification) => notification.id)
      )
      return {
        ...state,
        byId: set(state.byId, [id, ['loading', 'byId', 'allIds']], [false, notificationsById, allNotificationsIds]),
      }

    case 'FETCH_TABLE_NOTIFICATIONS_FAILURE':
      return {
        ...state,
        error: payload,
        byId: set(state.byId, [id, ['loading', 'byId']], [false, { ...state.byId[id].allIds }]),
      }

    case 'CREATE_MODULE_REQUEST':
      return produce(state, (newState) => {
        if (newState.byId[payload.moduleType]) {
          newState.byId[payload.moduleType].cudLoading = true
        }
      })

    case 'CREATE_MODULE_SUCCESSFULL':
      return produce(state, (newState) => {
        newState.byId[payload.type].cudLoading = false
      })

    case 'DYNAMIC_UPDATE_SUCCESS':
      return produce(state, (newState) => {
        newState.byId[payload.type].cudLoading = false
      })

    case 'SAVE_RULE_START':
      return produce(state, (newState) => {
        newState.byId['rules'].cudLoading = true
      })

    case 'SAVE_RULE_SUCCESS':
      return produce(state, (newState) => {
        newState.byId['rules'].cudLoading = false
      })

    case 'SAVE_RULE_FAILURE':
      return produce(state, (newState) => {
        newState.byId['rules'].cudLoading = false
      })

    case 'SEARCH_NOTIFICATIONS_SUCCESS':
      return produce(state, (newState) => {
        newState.byId['reports'].showDateModal = false
        newState.byId['reports'].fetchLoading = false
        newState.byId['reports'].reportViewForm = 'preview'
        newState.byId['reports'].reportPayload = payload.items
          ?.reduce(
            (acc, obj, index) => {
              const { tenant, profileId, deviceId, ruleId, id, type, ...fields } = obj
              Object.entries(fields).forEach(([prop, value]) => {
                if (!acc[0].includes(prop)) acc[0].push(prop)
                if (index > 0) {
                  if (!acc[index]) acc[index] = []
                  acc[index][
                    acc[0].findIndex((columnName) => {
                      return columnName === prop
                    })
                  ] = value
                }
              })
              return acc
            },
            [[]]
          )
          .map((row, i) => {
            let sdate
            if (row[3] && i !== 0) {
              let date = new Date(row[3])
              row[3] = date.toLocaleTimeString('lll')
            }
            if (row[11] && i !== 0) {
              let date = new Date(row[11])
              row[11] = date.toLocaleString('lll')
            }
            if (row[6] && i !== 0) {
              let date = new Date(row[6])
              row[6] = date.toLocaleString('lll')
            }
            if (row[10] && i !== 0) {
              sdate = new Date(row[10])
              row[10] = sdate.toLocaleString('lll')
            }
            if (row[8] && i !== 0) {
              let edate = new Date(row[8])
              let duration = parseInt(edate - sdate) / 1000 // segundos
              row[8] = edate.toLocaleString('lll')
              row[12] = formatTheDuration(duration, 1)
              row[13] = formatTheDuration(duration, 0.27)
              row[14] = formatTheDuration(duration, 0.14)
              row[15] = formatTheDuration(duration, 0.56)
              row[16] = formatTheDuration(duration, 1.56)
              let nro = Math.ceil(Math.random() * 5)
              row[17] = nro
              row[18] = 5 - nro
            }
            return row
          })
        newState.byId['reports'].reportColumns = [
          ...newState.byId['reports'].reportPayload[0],
          'Duração',
          'Tempo Médio',
          'Tempo Mínimo',
          'Tempo Máximo',
          'Tempo Total',
          'Usuários no Ponto de Encontro',
          'Nulos (Não chegaram ao ponto de encontro)',
        ]
      })

    case 'FETCH_AREA_ABANDONMENT_REPORT_START':
      return produce(state, (newState) => {
        newState.byId.reports.fetchLoading = true
        newState.byId.reports.fetchSuccess = false
        newState.byId.reports.fetchInterval = payload
      })

    case 'FETCH_AREA_ABANDONMENT_REPORT_SUCCESS':
      return produce(state, (newState) => {
        if (payload?.result) {
          const { result, headerInfo } = payload

          newState.byId.reports.headerInfo = headerInfo
          newState.byId.reports.fetchLoading = false
          newState.byId.reports.fetchSuccess = true
          newState.byId.reports.reportData = result
          newState.byId.reports.reportPayload = result
          newState.byId.reports.reportRows = result.filter((p, i) => i !== 0)
          newState.byId.reports.reportColumns = result[0]
          newState.byId.reports.reportType = 'avgTimeReport'
        }
      })

    default:
      return state
  }
}
