/*  Author: Felipe Pegado
    Type: Smart
	Description: Controls all the date logics related to the history calendar
	TODO: 
*/

import React, { Fragment, useState, useEffect, useCallback } from 'react'
import DateCalendar from 'Libs/calendar/dateRange/dateCalendar.js'
import DateRange from 'Libs/calendar/dateRange/dateRange.js'
import { Grid, Message } from 'semantic-ui-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const Warning = ({ visible, setVisible }) => {
  const { t } = useTranslation()
  return (
    visible && (
      <Message warning onDismiss={() => setVisible(false)}>
        <Message.Header>{t('NoHistData')}</Message.Header>
        <p>{t('NoHistInfo')}</p>
      </Message>
    )
  )
}

const HistCalendar = ({ handleSave, ...props }) => {
  const histType = useSelector((state) => state.history.type)
  const canShowMoreThanOneDay = useSelector((state) => state.tenants.current.info?.history?.canShowMoreThanOneDay)

  const [time, setTime] = useState({})
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()
  const [visible, setVisible] = useState(false)
  const handleSelectDateRange = useCallback(({ start, end }) => {
    setTime({ start, end })
  }, [])
  const handleSubmit = useCallback(() => handleSave(time), [time, handleSave])

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        setVisible(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [visible])

  useEffect(() => {
    if (props.warning) {
      setVisible(true)
    }
  }, [props.warning])

  useEffect(() => {
    const minDate = props.projects?.[0]?.start || '2018-01-01 00:00'
    const maxDate = props.projects?.[0]?.end || {}
    const initialTime =
      props.start ||
      moment(props.projects?.[0]?.end || {})
        .startOf('day')
        .toISOString()
    const endTime = props.end || props.projects?.[0]?.end || new Date().toISOString()
    setTime({ start: moment(initialTime), end: moment(endTime) })
    setMaxDate(moment(maxDate).toDate())
    setMinDate(minDate ? moment(minDate).toDate() : moment().subtract(24, 'month').toDate())
  }, [props.projects, props.start, props.end])

  if (!props.showCalendar) return null
  return (
    <Fragment>
      {canShowMoreThanOneDay || ['heatmap', 'hole', 'holesLines'].includes(histType) ? (
        <Grid.Row centered columns={1} style={{ padding: 0 }}>
          <Grid.Column style={{ minWidth: 'max-content' }} width={3}>
            <DateRange
              start={time.start}
              end={time.end}
              minDate={minDate}
              maxDate={maxDate}
              type={histType}
              onChange={handleSelectDateRange}
              loadingButton={props.loadingButton}
              className="rdr-DateInput"
              onSubmit={handleSubmit}
            />
            <Warning visible={visible} setVisible={setVisible} />
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row centered columns={1} style={{ padding: 0 }}>
          <Grid.Column style={{ minWidth: 'max-content' }} width={3}>
            <DateCalendar
              showTimePicker
              start={time.start}
              end={time.end}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleSelectDateRange}
              loadingButton={props.loadingButton}
              className="rdr-DateInput"
            />
            <Warning visible={visible} setVisible={setVisible} />
          </Grid.Column>
        </Grid.Row>
      )}
    </Fragment>
  )
}

export default HistCalendar
