const assetsFilter = { source: 'assets', label: 'assets', type: 'multiple' }
const fencesFilter = { source: 'fences', label: 'fences', type: 'multiple' }
const rulesFilter = { source: 'rules', label: 'rules', type: 'multiple' }

export const reportFilters = {
  rules: [assetsFilter, fencesFilter, rulesFilter],
}

export const CONTEXTS = ['rules', 'measures', 'references']

export const contextOptions = CONTEXTS.map((e) => ({ key: e, text: e, value: e }))
