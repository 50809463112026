import React from 'react'
import { Dropdown, Form, Input, Label } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { setMensurationsProp } from 'Store/actions/mensurations-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import FormGroup from '../FormGroup'
import { useTranslation } from 'react-i18next'

const MensurationsInfoForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const activityId = useSelector((state: RootStore) => state.mensurations.wip?.activityId)
  const targetCycleTime = useSelector((state: RootStore) => state.mensurations.wip?.targetCycleTime)

  const vehicle = useSelector((state: RootStore) => state.mensurations.wip?.vehicle)
  const operator = useSelector((state: RootStore) => state.mensurations.wip?.operator)

  const errors = useObjSelector((state: RootStore) => state.mensurations.form?.errors?.[1])

  const showFeedback = useSelector((state: RootStore) => state.mensurations.form?.steps.items[1].showFeedback)

  const allActivitiesIds = useObjSelector((state: RootStore) => state.activities.allIds)
  const activitiesById = useObjSelector((state: RootStore) => state.activities.byId)

  const loadingActivities = useSelector((state: RootStore) => state.activities.loading)

  const ErrorLabel = ({ field = '' }) => {
    //@ts-ignore
    if (showFeedback && errors?.[field]?.[0] && errors?.[field]?.[0] !== 'Required') {
      return (
        <Label color="red" pointing>
          {/* @ts-ignore */}
          {t(errors?.[field]?.[0])}
        </Label>
      )
    } else return null
  }

  return (
    <>
      <FormGroup widths="equal">
        <Form.Field error={showFeedback && !!errors?.activityId} required>
          <label>{t('workstation')}</label>
          <Dropdown
            selection
            fluid
            placeholder={loadingActivities ? 'Carregando opções, aguarde...' : 'Selecione o PDT'}
            onChange={(e, { value }) => dispatch(setMensurationsProp(`wip.activityId`, value))}
            value={activityId}
            options={allActivitiesIds.map((activityId: string) => ({
              key: activityId,
              value: activityId,
              text: activitiesById[activityId]?.name,
            }))}
            noResultsMessage={
              loadingActivities ? 'Carregando opções, aguarde...' : 'Nenhum resultado encontrado no filtro selecionado.'
            }
            loading={loadingActivities}
          />
          <ErrorLabel field="activityId" />
        </Form.Field>
        <Form.Field inline required error={showFeedback && !!errors?.targetCycleTime}>
          <label>{t('targetCycleTime')}</label>
          <Input
            type="number"
            name="targetCycleTime"
            fluid
            placeholder="Digite um número"
            value={targetCycleTime}
            onChange={(e, { name, value }) => {
              dispatch(setMensurationsProp(`wip.${name}`, parseInt(value)))
            }}
          />
          <ErrorLabel field="targetCycleTime" />
        </Form.Field>
      </FormGroup>
      <FormGroup widths="equal">
        <Form.Field inline required error={showFeedback && !!errors?.vehicle}>
          <label>{t('vehicle')}</label>
          <Input
            name="vehicle"
            fluid
            placeholder="Digite o modelo do veículo"
            value={vehicle}
            onChange={(e, { name, value }) => {
              dispatch(setMensurationsProp(`wip.${name}`, value))
            }}
          />
          <ErrorLabel field="vehicle" />
        </Form.Field>
        <Form.Field inline required error={showFeedback && !!errors?.operator}>
          <label>{t('operator')}</label>
          <Input
            name="operator"
            fluid
            placeholder="Digite o nome do operador"
            value={operator}
            onChange={(e, { name, value }) => {
              dispatch(setMensurationsProp(`wip.${name}`, value))
            }}
          />
          <ErrorLabel field="operator" />
        </Form.Field>
      </FormGroup>
    </>
  )
}

export default MensurationsInfoForm
