import { Emitter } from './events'

export let NotificationStatus = Object.freeze({ NOTICE: 0, SUCCESS: 1, WARNING: 2, ERROR: 3 })
export let NotificationChange = Object.freeze({ ADD: 0, CHANGE: 1 })

export class NotificationState {
  constructor() {
    this._onNotificationUpdated = new Emitter()
    this._onNotificationRemoved = new Emitter()
    this.onNotificationUpdated = this._onNotificationUpdated.event
    this.onNotificationRemoved = this._onNotificationRemoved.event
    this.notifications = new Map()
  }
  getNotifications() {
    return this.notifications
  }
  addNotification(notification) {
    const id = notification.id || Date.now()
    this.notifications.set(id, notification)
    this._onNotificationUpdated.emit({
      type: NotificationChange.ADD,
      id,
      notification,
    })
    return id
  }
  updateNotification(id, notification) {
    this.notifications.set(id, notification)
    this._onNotificationUpdated.emit({
      type: NotificationChange.CHANGE,
      id,
      notification,
    })
  }
  removeNotification(id) {
    if (this.notifications.has(id)) {
      this.notifications.delete(id)
      this._onNotificationRemoved.emit({
        id,
      })
    }
  }
  removeAllNotifications() {
    this.notifications.forEach((v, k) => {
      this.removeNotification(k)
    })
  }
}
