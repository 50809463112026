import React, { useMemo } from 'react'
import m from 'moment'
import { useTranslation } from 'react-i18next'

interface Props {
  inInfo?: boolean
  columns?: (string | Column)[]
  defaults?: boolean
}

export interface Column {
  id?: string
  accessor: string | Function
  Header?: string
  aggregate?: string
  Aggregated?: Function
  Cell?: Function
  sortType?: string | Function
  align?: string
}

export const GenericColumns = ({ inInfo = false, columns = [], defaults = true }: Props) => {
  const { t } = useTranslation()

  return useMemo(() => {
    const tableColumnsById =
      columns?.reverse()?.reduce((acc: Record<string, Column | {}>, column, index) => {
        const key =
          typeof column === 'string'
            ? column
            : column.id || (typeof column.accessor === 'string' ? column.accessor : index)
        acc[key] =
          typeof column === 'string'
            ? {
                Header: t(column),
                accessor: column,
                aggregate: 'uniqueCount',
                Aggregated: ({ value }: any) => `${value} ${t(column)}(s)`,
                Cell: ({ value }: any) => value ?? '',
                sortType: 'basic',
              }
            : {
                id: column.id || column.accessor,
                Header: t(column.Header || '') || t(typeof column.accessor === 'string' ? column.accessor : ''),
                accessor: column.accessor,
                aggregate: column.aggregate || 'uniqueCount',
                Aggregated:
                  column.Aggregated ||
                  (({ value }: any) => `${value} ${t(typeof column.accessor === 'string' ? column.accessor : '')}(s)`),
                Cell: column.Cell ? column.Cell : ({ value }: any) => value ?? '',
                sortType: column.sortType ?? 'basic',
                align: column.align ?? 'left',
              }
        return acc
      }, {}) ?? {}

    const spanTime = ({ value }: any) => <span title={m(value).format('lll')}>{m(value).fromNow()}</span>

    const defaultColumn = ({ accessor, Aggregated, Cell }: any) => {
      return {
        Header: t(accessor),
        accessor: inInfo ? `info.${accessor}` : accessor,
        aggregate: 'uniqueCount',
        Aggregated,
        Cell,
      }
    }

    const defaultColumnsById = defaults
      ? {
          updateForm: defaultColumn({
            accessor: 'updateForm',
            Aggregated: ({ value }: any) => {
              return `${value} Via(s)`
            },
            Cell: ({ value }: any) => (value === 'platform' ? 'Plataforma' : value ?? 'platform'),
          }),
          updatedBy: defaultColumn({
            accessor: 'updatedBy',
            Aggregated: ({ value }: any) => {
              return `${value} Usuário(s)`
            },
            Cell: ({ value }: any) => value ?? '',
          }),
          updatedAt: defaultColumn({
            accessor: 'updatedAt',
            Aggregated: ({ value }: any) => {
              return `${value} Data(s)`
            },
            Cell: spanTime,
          }),
          createForm: defaultColumn({
            accessor: 'createForm',
            Aggregated: ({ value }: any) => {
              return `${value} Via(s)`
            },
            Cell: ({ value = 'Plataforma' }: { value?: string }) =>
              value === 'platform' ? 'Plataforma' : value ?? 'platform',
          }),
          createdBy: defaultColumn({
            accessor: 'createdBy',
            Aggregated: ({ value }: any) => {
              return `${value} Usuário(s)`
            },
            Cell: ({ value }: any) => value ?? 'ADM',
          }),
          createdAt: defaultColumn({
            accessor: 'createdAt',
            Aggregated: ({ value }: any) => {
              return `${value} Data(s)`
            },
            Cell: spanTime,
          }),
        }
      : {}

    const columnsById = {
      ...defaultColumnsById,
      ...tableColumnsById,
    }

    return Object.values(columnsById).reverse()
  }, [inInfo, t, columns, defaults])
}
