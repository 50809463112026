import React, { useMemo, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ModalHeader from 'Utils/modals/ModalHeader'
import Loading from 'Utils/components/Loading'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Header, Modal, Segment } from 'semantic-ui-react'
import m from 'moment'
import { useTranslation } from 'react-i18next'
import useFetchData from 'Utils/hooks/useFetchData'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import EntityDropdown from 'Components/dropdowns/EntityDropdown'
import { setTableProp } from 'Store/actions/tables-action'
import { CHART_COLORS } from 'Utils/complementaryColors'

export const MotionContent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const measures = ['aXSpread', 'aYSpread', 'aZSpread']

  useFetchData({ independentFetches: [{ fetchAction: 'measures' }] })

  const loading = useSelector((state) => state.history.loading)
  const motionDataStore = useObjSelector((state) => state.history.motion.byId || {})

  const selectedAssets = useObjSelector((state) => state.tables.byId.motionChart.selectedAssets || [])

  let motionData = useMemo(() => {
    const res = Object.entries(motionDataStore).filter(([assetId, assetData]) => {
      return selectedAssets.includes(assetId)
    })
    return res.length > 0 ? res : [[0, []]]
  }, [motionDataStore, selectedAssets])

  return !loading ? (
    <>
      <ModalHeader
        name={t('motion')}
        menuButtons={['toggle fullscreen']}
        noFilter
        style={{ padding: '0', height: '10vh' }}
      />
      <Modal.Content
        style={{
          overflowY: 'auto',
          height: '70vh',
          border: 'none',
          boxShadow: 'none',
          marginLeft: '2vw',
          marginRight: '2vw',
        }}
      >
        {motionData.map(([assetId, assetMotionData], index) => {
          return (
            <Fragment key={assetId}>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div style={{ flex: 1 }}>
                  <Header>{`Eixos Acelerômetro`}</Header>
                </div>
                {index === 0 && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '10px' }}>
                      <EntityDropdown
                        entity="assets"
                        value={selectedAssets[0]}
                        placeholder="Selecione o ativo da Esquerda"
                        onChange={(value) => {
                          dispatch(setTableProp('byId.motionChart.selectedAssets[0]', value))
                        }}
                      />
                    </div>
                    <div>
                      <EntityDropdown
                        entity="assets"
                        value={selectedAssets[1]}
                        placeholder="Selecione o ativo da Direita"
                        onChange={(value) => {
                          dispatch(setTableProp('byId.motionChart.selectedAssets[1]', value))
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <Segment style={{ height: '40%' }}>
                <ResponsiveContainer>
                  <LineChart
                    data={assetMotionData}
                    syncId="anyId"
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="timestamp"
                      minTickGap={20}
                      tickFormatter={(value) => {
                        return m(value).format('DD/MM HH:mm:ss')
                      }}
                    />
                    <YAxis domain={[-6000, 6000]} />
                    <Tooltip
                      labelFormatter={(value) => {
                        return m(value).format('DD/MM HH:mm:ss')
                      }}
                    />
                    <Legend
                      verticalAlign="top"
                      height={36}
                      formatter={(value, c, i) => {
                        return t(value)
                      }}
                    />
                    {measures.map((measure, index) => {
                      return (
                        <Line
                          type="monotone"
                          dataKey={measure}
                          stroke={CHART_COLORS[index]}
                          fill={CHART_COLORS[index]}
                          activeDot={{ r: 8 }}
                          connectNulls={true}
                          isAnimationActive={false}
                        />
                      )
                    })}
                  </LineChart>
                </ResponsiveContainer>
              </Segment>
            </Fragment>
          )
        })}
      </Modal.Content>
    </>
  ) : (
    <div>
      <Loading />
      <h4 style={{ textAlign: 'center' }}>{t('loading_icon_message')}</h4>
    </div>
  )
}
