import React from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

/*  Author: Thiago Porfirio
    Type: 
    Description: 
    - Dropdown mobile version
    - 
*/

const MobileOptions = [
  {
    key: 'Notificação',
    text: 'Notificação',
    value: 'Notificação',
    icon: 'bell',
  },
  {
    key: 'Plantas',
    text: 'Plantas',
    value: 'Plantas',
    icon: 'building',
  },
  {
    key: 'Cercas',
    text: 'Cercas',
    value: 'Cercas',
    icon: 'th large',
  },
  {
    key: 'Rotas',
    text: 'Rotas',
    value: 'Rotas',
    icon: 'road',
  },
  {
    key: 'Reference',
    text: 'Reference',
    value: 'Reference',
    icon: 'map marker alternate',
  },
  {
    key: 'Assets',
    text: 'Assets',
    value: 'Assets',
    icon: 'map marker alternate',
  },
  {
    key: 'History',
    text: 'History',
    value: 'History',
    icon: 'history',
  },
  {
    key: 'Emergency',
    text: 'Emergency',
    value: 'Emergency',
    icon: 'bullhorn',
  },
  {
    key: 'Devices',
    text: 'Devices',
    value: 'Devices',
    icon: 'id badge',
  },
  // {
  //   key: 'porfirio',
  //   text: 'THPorfirio',
  //   value: 'Porfirio',
  //   icon: 'th large',
  // },
]

const MobileMenuLabel = styled.span`
  padding-left: 2px;
  max-width: 40vw;
`

const DropdownMobile = () => (
  <Dropdown pointing="top right" icon={'th large'} style={{ color: '#4b72b2', maxHeight: '25vh' }}>
    <Dropdown.Menu>
      {MobileOptions.map((m) => (
        <Dropdown.Item key={m.key}>
          <MobileMenuLabel style={{ color: '#4b72b2' }}>
            <Icon name={m.icon} />
            {m.text}
          </MobileMenuLabel>
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
)

export default DropdownMobile
