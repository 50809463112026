import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'
import { API } from 'aws-amplify'
import { createActivity, deleteActivity, updateActivity } from 'graphql/mutations'
import { listActivities } from 'graphql/queries'

export const setActivitiesProp = (prop, value) => ({
  type: 'SET_ACTIVITIES_PROP',
  prop,
  value,
})

export const setActivitiesProps = (payload, source) => {
  return {
    type: 'SET_PROFILES_PROPS',
    payload,
    source,
  }
}

export const fetchActivities = (nextToken) => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  try {
    dispatch({ type: 'FETCH_ACTIVITIES_START', tenant })
    const response = await API.graphql({
      query: listActivities,
      variables: { tenant },
    })
    const payload = response.data.listActivities
    dispatch({
      type: 'FETCH_ACTIVITIES_SUCCESS',
      payload,
    })
  } catch (err) {
    console.error('error fetching activities', err)
  }
}

export const mutateActivity = () => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  const { updatedAt, __typename, ...wip } = getState().activities.wip

  const mutation = wip.id ? 'update' : 'create'
  let input = {
    tenant,
    ...wip,
    [mutation === 'create' ? 'createdBy' : 'updatedBy']: getState().login.email,
  }

  dispatch({ type: 'MUTATE_ACTIVITY_START', input, mutation })
  try {
    const response = await API.graphql({
      query: mutation === 'update' ? updateActivity : createActivity,
      variables: {
        input,
      },
    })
    dispatch({
      type: 'MUTATE_ACTIVITY_SUCCESS',
      payload: response?.data?.[mutation === 'update' ? 'updateActivity' : 'createActivity'],
    })
    notifySuccess()
  } catch (err) {
    console.error('error:', err)
    dispatch({ type: 'MUTATE_ACTIVITY_FAILURE', input, mutation })
    notifyError()
  }
}

export const setActivityWIP = (id) => {
  return { type: 'SET_ACTIVITY_WIP', id }
}

export const deleteActivities =
  ({ ids }) =>
  async (dispatch) => {
    for (const id of ids) {
      try {
        dispatch({ type: 'DELETE_ACTIVITIES_START', id })
        await API.graphql({
          query: deleteActivity,
          variables: {
            input: {
              id,
            },
          },
        })
        dispatch({ type: 'DELETE_ACTIVITIES_SUCCESS', id })
        notifySuccess()
      } catch (err) {
        dispatch({ type: 'DELETE_ACTIVITIES_FAILURE', id })
        console.error('error:', err)
        notifyError()
      }
    }
  }

export const clearActivityForm = () => {
  return { type: 'CLEAR_ACTIVITY_FORM' }
}
