import * as z from 'zod'

const StepTwoSchema = z.object({
  operationUnit: z.string().min(1, 'form.error.min').max(30, 'form.error.max'),
  section: z.string().min(1, 'form.error.min').max(30, 'form.error.max'),
})

export type StepTwo = z.infer<typeof StepTwoSchema>

export default StepTwoSchema
