import * as React from 'react'
import { useTransition, animated } from 'react-spring'

import Portal from './Portal'
import { NotificationsContainer } from './styled'
import { NotificationChange, NotificationStatus } from '../state'
import { Toast } from './Toast'

const convertMapToToasts = (notifications) =>
  Array.from(notifications.keys()).map((id) => ({
    id,
    createdAt: Date.now(),
    notification: notifications.get(id),
  }))

const convertNotificationEventToToast = (notificationEvent) => ({
  id: notificationEvent.id,
  createdAt: Date.now(),
  notification: notificationEvent.notification,
})

const isSticky = (toast) => {
  if (toast.notification.status === NotificationStatus.ERROR && toast.notification.actions) {
    return true
  }

  if (toast.notification.sticky) {
    return true
  }

  return false
}

const TIME_ALIVE = {
  [NotificationStatus.SUCCESS]: 7000,
  [NotificationStatus.NOTICE]: 10000,
  [NotificationStatus.WARNING]: 12000,
  [NotificationStatus.ERROR]: 20000,
}

const DEFAULT_COLORS = {
  [NotificationStatus.ERROR]: '#DC3545',
  [NotificationStatus.WARNING]: '#FFD399',
  [NotificationStatus.SUCCESS]: '#5da700',
  [NotificationStatus.NOTICE]: '#40A9F3',
}

const DEFAULT_BUTTON = (props) => React.createElement('button', props)

export const Toasts = ({ state, colors = DEFAULT_COLORS, Button = DEFAULT_BUTTON }) => {
  const [refMap] = React.useState(() => new WeakMap())
  const mouseOverRef = React.useRef(false)
  const [notificationsToShow, setNotificationsToShow] = React.useState(convertMapToToasts(state.getNotifications()))
  const removeNotification = React.useCallback((id) => {
    setNotificationsToShow((notifs) => {
      const newNotifs = notifs.filter((notif) => notif.id !== id)
      const notifToHide = notifs.find((notif) => notif.id === id)
      if (newNotifs.length !== notifs.length) {
        return newNotifs
      }
      if (notifToHide.notification.onHide) {
        notifToHide.notification.onHide()
      }
      return notifs
    })
  }, [])
  React.useEffect(() => {
    const interval = setInterval(() => {
      setNotificationsToShow((notifs) => {
        if (mouseOverRef.current) {
          // Don't remove notifs if there is a mouse there
          return notifs
        }
        const newNotifs = notifs.filter(
          (notif) =>
            isSticky(notif) ||
            Date.now() < notif.createdAt + (notif.notification.timeAlive || TIME_ALIVE[notif.notification.status])
        )
        if (newNotifs.length !== notifs.length) {
          return newNotifs
        }
        return notifs
      })
    }, 100)
    return () => {
      clearInterval(interval)
    }
  }, [])
  React.useEffect(() => {
    const addListener = state.onNotificationUpdated((event) => {
      if (event.type === NotificationChange.ADD) {
        setNotificationsToShow((notifications) => [...notifications, convertNotificationEventToToast(event)])
      }
    })
    return () => {
      addListener()
    }
  }, [state])
  React.useEffect(() => {
    const addListener = state.onNotificationRemoved((event) => {
      setNotificationsToShow((notifications) => notifications.filter((notif) => notif.id !== event.id))
    })
    return () => {
      addListener()
    }
  }, [state])
  const transitions = useTransition(notificationsToShow, (n) => n.id, {
    from: { overflow: 'hidden', opacity: 0, height: 0 },
    enter: (item) => (next) =>
      next({
        overflow: 'hidden',
        opacity: 1,
        height: refMap.get(item) ? refMap.get(item).offsetHeight + 16 : 0,
      }),
    leave: { overflow: 'hidden', opacity: 0, height: 0 },
  })

  return (
    <Portal>
      <NotificationsContainer
        onMouseEnter={() => {
          mouseOverRef.current = true
        }}
        onMouseLeave={() => {
          mouseOverRef.current = false
        }}
      >
        {transitions.map(({ item, props, key }) => (
          <animated.div key={key} style={props}>
            <Toast
              colors={colors}
              Button={Button}
              getRef={(ref) => ref && refMap.set(item, ref)}
              toast={item}
              removeToast={(id) => {
                removeNotification(id)
              }}
            />
          </animated.div>
        ))}
      </NotificationsContainer>
    </Portal>
  )
}
