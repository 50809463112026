/*  
  Author: Bruno Melo/Luís Mourão
  Type: Action
  
  Description: 
    - Ações genéricas para alterar qualquer campo da toggleStore
    - O único parâmetro recebido é a chave que deseja-se alterar
  
  TODO: 
    - Ação para alterar uma lista de chaves da toggleStore
*/

//Inverter o booleano de qualquer chave dentro da toggleStore
export const toggle = (s) => ({
  type: 'TOGGLE',
  payload: s,
})

//Alterar uma Chave da toggleStore para True
export const setTrue = (s) => ({
  type: 'SET_TRUE',
  payload: s,
})

//Alterar uma Chave da toggleStore para False
export const setFalse = (s) => ({
  type: 'SET_FALSE',
  payload: s,
})
