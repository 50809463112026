import React, { Component, Fragment } from 'react'
import { Modal, List, Header } from 'semantic-ui-react'

// Now the main version comes from releaseNotes[0].version
// We need to improve this as a service in future

const releaseNotes = [
  {
    //originBranch: '@luis-upgrade-images-250214'
    version: '2.39.1',
    date: '12MAR2025',
    itemsDescriptions: ['Correções e melhorias na gestão de imagens dos módulos'],
  },
  {
    //originBranch: '@luis-upgrade-images-250214'
    version: '2.39.0',
    date: '18FEV2025',
    itemsDescriptions: ['Nova funcionalidade de gestão de imagens'],
  },
  {
    //originBranch: 'tiplam'
    version: '2.38.4',
    date: '05FEV2025',
    itemsDescriptions: [
      'Correções gerais no Dropdown de módulos georreferenciados',
      'Correção no Tooltip de geomódulos',
      'Correções no formulário lateral de geomódulos',
    ],
  },
  {
    version: '2.38.1',
    date: '24JAN2025',
    itemsDescriptions: ['Correção geral'],
  },
  {
    version: '2.38.0',
    date: '02DEZ2024',
    itemsDescriptions: ['Melhorias e correções gerais'],
  },
  {
    version: '2.37.0',
    date: '18NOV24',
    itemsDescriptions: ['Melhorias e correções gerais'],
  },
  {
    version: '2.36.4',
    date: '24SET24',
    itemsDescriptions: ['Correções gerais'],
  },
  {
    version: '2.36.3',
    date: '16SET24',
    itemsDescriptions: ['Correção na exibição de regra na tabela de perímetros'],
  },
  {
    version: '2.36.2',
    date: '16SET24',
    itemsDescriptions: ['Correções em geral'],
  },
  {
    version: '2.36.1',
    date: '16SET24',
    itemsDescriptions: ['Correção na criação de mensagens'],
  },
  {
    version: '2.36.0',
    date: '20AGO24',
    itemsDescriptions: ['Novo recurso de seções dos campos personalziados'],
  },
  {
    version: '2.35.2',
    date: '30JUL24',
    itemsDescriptions: ['Correção na atualização de módulos'],
  },
  {
    version: '2.35.1',
    date: '23JUL24',
    itemsDescriptions: ['Correção na centralização de ativos'],
  },
  {
    version: '2.35.0',
    date: '15MAR24',
    itemsDescriptions: ['Melhorias no recruso de exportação via PDF'],
  },
  {
    version: '2.34.3',
    date: '09FEV24',
    itemsDescriptions: ['Removido os icones de olhos dos dropdowns de Pessoas e Ativos'],
  },
  {
    version: '2.34.2',
    date: '19JAN24',
    itemsDescriptions: [
      'Removido a limitação de emergência apenas com Usuários Online',
      'Adicionado 2 novos campos no formulário de dispositivo',
    ],
  },
  {
    version: '2.34.1',
    date: '03JAN24',
    itemsDescriptions: ['Fix Imagem do Login', 'Fix Editar Dispositivos'],
  },
]

export default class ReleaseNotes extends Component {
  render() {
    return (
      <Modal
        trigger={
          <span style={{ cursor: 'pointer', marginLeft: '3px' }}>
            {'- Release Notes v' + releaseNotes?.[0]?.version}
          </span>
        }
        closeIcon
        centered={false}
      >
        <Modal.Header>Novidades</Modal.Header>
        {releaseNotes.map((data) => (
          <Fragment key={data?.version}>
            <Header style={{ borderBottom: 'none' }}>
              <span> {'Release ' + data?.version} </span>
              <span style={{ float: 'right', marginRight: '20px' }}> {data?.date} </span>
            </Header>
            <Modal.Content>
              <List bulleted key={data?.version}>
                {data?.itemsDescriptions?.map((note, i) => (
                  <List.Item content={note} key={data?.itemsDescriptions[0]} />
                ))}
              </List>
            </Modal.Content>
          </Fragment>
        ))}
      </Modal>
    )
  }
}
