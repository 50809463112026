import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, Message } from 'semantic-ui-react'

interface Props {
  header?: string
  body?: string
  table?: boolean
}

const NoDataMessage = ({ header = 'noDataMsg', body = 'noDataMsgHelp', table = false }: Props) => {
  const { t } = useTranslation()

  return (
    <Message
      info
      style={
        table
          ? {
              paddingLeft: '38vw',
            }
          : {}
      }
      icon
    >
      <Icon name="search" />
      <Message.Content>
        <Message.Header>{t(header)}</Message.Header>
        <p>{t(body)}</p>
      </Message.Content>
    </Message>
  )
}

export default NoDataMessage
