import { FormStepItems, StoreForm } from 'interfaces/formStepItems'
import { Profile as APIProfile } from '../API'

export interface Profile extends Omit<APIProfile, 'properties'> {
  properties: Record<string, unknown>
}

export interface WipProfile extends Partial<Profile> {}

interface ProfileStoreForm extends StoreForm {
  sectionState: Record<string, { isExpanded: boolean }>
  fieldsToBeRemoved: string[]
  inputFocus: boolean
}

export interface Profiles {
  allIds: string[]
  byId: Record<string, Profile>
  current: Profile | null
  wip: WipProfile
  form: ProfileStoreForm
  openSideBar: boolean
  selectedProfilesIds: string[]
  loading: boolean
}

export const profileSteps: FormStepItems[] = [
  {
    id: 'info',
    icon: 'info',
    title: 'Identificação',
    description: 'Identifique a atribuição',
    showFeedback: false,
    disabled: false,
  },
  {
    id: 'customFields',
    icon: 'filter',
    title: 'Campos Personalizados',
    description: 'Gerencie os Campos',
    showFeedback: false,
    disabled: true,
  },
]

export const profiles: Profiles = {
  allIds: [],
  byId: {},
  current: null,
  wip: {},
  // wip: {
  //   type: ProfileType.assets,
  //   name: 'dffdhb',
  //   code: 'dsxcvds',
  //   color: 'yellow',
  //   icon: 'FaToolbox',
  // },
  form: {
    steps: {
      current: 0,
      items: profileSteps,
    },
    open: false,
    loading: false,
    isEditing: false,
    fieldsToBeRemoved: [],
    sectionState: {},
    inputFocus: false,
  },
  openSideBar: true,
  selectedProfilesIds: [],
  loading: false,
}
