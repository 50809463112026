import m from 'moment'
export const selectLanguage = (i, i18n) => {
  if (!i) return { lan: 'Português' }
  let lan = i.target ? i.target.value : i
  switch (lan) {
    case 'zh': {
      i18n.changeLanguage('zh')
      m.locale('zh-tw')
      return { lan: '中文' }
    }

    case 'fr': {
      i18n.changeLanguage('fr')
      m.locale('fr')
      return { lan: 'Français' }
    }

    case 'es': {
      i18n.changeLanguage('es')
      m.locale('es')
      return { lan: 'Español' }
    }

    case 'pt': {
      i18n.changeLanguage('pt')
      m.locale('pt-br')
      return { lan: 'Português' }
    }

    case 'en': {
      i18n.changeLanguage('en')
      m.locale('en')
      return { lan: 'English' }
    }

    default:
      i18n.changeLanguage('pt')
      m.locale('pt-br')
      return { lan: 'Português' }
  }
}
