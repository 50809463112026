export const parseGeoModules = (newState, payload) => {
  if (payload.geometry) {
    const coordinates = payload.geometry.coordinates
    newState[payload.type].byId[payload.id].geometry.coordinates =
      typeof coordinates === 'string' ? JSON.parse(coordinates) : coordinates
  }

  if (payload.properties) {
    const properties = payload.properties
    newState[payload.type].byId[payload.id].properties =
      typeof properties === 'string' ? JSON.parse(properties) : properties
  }
}
