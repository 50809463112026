import request from 'superagent'
import { urlServer } from 'Utils/url'

export const handleDeleteFile = (id) => {
  request
    .delete(urlServer() + '/map/imageoverlay')
    .send({ id })
    .then((res) => {
      console.log('Resposta: ', res)
    })
    //Problema no servidor
    .catch((err) => {
      console.error('Erro ao apagar dados do banco de dados: ', err)
    })
}
