import React, { memo, useEffect, useRef } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useLeaflet } from 'react-leaflet'
import { setFalse } from 'Store/actions/toggle-action'
import { subscribeAssetLocationChange } from 'Store/actions/measures-action'
import { fetchAssetsLocation } from 'Store/actions/measures-action'
import RenderMarker from './RenderMarker'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { setEmergencyBounds } from 'Store/actions/notification-action'
import { get, union } from 'lodash'
import { notifyInfo } from 'Utils/components/SystemToasts'

/*  Author: Luís Mourão
  Description: Filter and distribution of RTMarkers	 
  To do list: -
*/

const RTMarkers = () => {
  const { map } = useLeaflet()
  const dispatch = useDispatch()

  const activePeople = useSelector((state) => state.assets?.allActivePeopleIds)
  const activeAssets = useSelector((state) => state.assets?.allActiveAssetsIds)
  const measures = useSelector((state) => state.measures.byId, shallowEqual)
  const showFitBounds = useSelector((state) => state.toggle.showFitBounds)
  const filterResult = useSelector((state) => state.filters.result, shallowEqual)
  const searchText = useSelector((state) => state.filters.searchText)
  const tenantLat = useSelector((state) => state.tenants.current?.info?.lat)
  const tenantLng = useSelector((state) => state.tenants.current?.info?.lng)
  const onGoingEmergency = useSelector((state) => state.notification.onGoingEmergency)
  const assetsInEmergency = useSelector((state) => state.notification.assetsInEmergency, shallowEqual)
  const connection = useSelector((state) => state.login.connection)

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevProps = usePrevious({ onGoingEmergency })

  useEffect(() => {
    if (connection !== 'disconnected') {
      dispatch(fetchAssetsLocation())
    }
    const sub = dispatch(subscribeAssetLocationChange())
    return () => {
      sub.unsubscribe()
    }
  }, [dispatch, connection])

  useEffect(() => {
    if (!showFitBounds) return
    if (filterResult.length > 0) {
      const bounds = filterResult.reduce((acc, item) => {
        const coords = get(measures[item.id], `info.coords`, {})

        const lat = coords.lat || coords.latG || coords.latB
        const lng = coords.lng || coords.lngG || coords.lngB

        if (lat && lng) {
          acc.push({ lat, lng })
        }

        return acc
      }, [])
      if (bounds?.length > 0) {
        map.fitBounds(bounds, { maxZoom: 19 })
      } else {
        notifyInfo({ msg: 'Não foi possível centralizar. O dispositivo associado não enviou dados de localização.' })
      }
      // else {
      //   if (!measures[filterResult[0].id]) {
      //     dispatch(fetchAssetCoords(filterResult[0].id))
      //   }
      // }
    }
    dispatch(setFalse('showFitBounds'))
  }, [map, measures, showFitBounds, filterResult, dispatch, tenantLat, tenantLng])

  useEffect(() => {
    // console.log({ prev: prevProps?.onGoingEmergency, onGoingEmergency, initialFetch, assetsInEmergency })
    if (!prevProps?.onGoingEmergency && onGoingEmergency) {
      dispatch(setEmergencyBounds())
      const centers = assetsInEmergency
        .filter((id) => measures[id]?.info?.coords)
        .map((id) => [measures[id].info.coords.lat, measures[id].info.coords.lng])
      if (centers.length === 0) return
      map.fitBounds(centers)
    }
  }, [onGoingEmergency, assetsInEmergency, map, measures, prevProps, dispatch])
  return (
    <>
      {assetsInEmergency
        .filter((id) => measures[id]?.info?.coords?.lat && measures[id]?.info?.coords?.lng)
        .map((assetId) => (
          <RenderMarker key={assetId} assetId={assetId.toString()} />
        ))}
      <MarkerClusterGroup disableClusteringAtZoom={21} spiderfyDistanceMultiplier={2}>
        {searchText.length === 0
          ? union(activePeople, activeAssets)
              .filter((id) => measures[id]?.info?.coords && !assetsInEmergency.includes(id))
              .map((assetId) => <RenderMarker key={assetId} assetId={assetId.toString()} />)
          : filterResult.map(({ id }) => <RenderMarker key={id} assetId={id.toString()} />)}
      </MarkerClusterGroup>
    </>
  )
}

export default memo(RTMarkers)
