import React, { useState, useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { saveRule, deleteRules, setCurrentRule } from 'Store/actions/rules-action'
import { omit } from 'lodash'
import TableSpan from 'Components/SidebarMenu/TableSpan'
import { saveColumnsPreference } from 'Store/actions/login-action'
import useFetchData from 'Utils/hooks/useFetchData'
import ClickableLink from 'Utils/components/ClickableLink'
import moment from 'moment'
import { setChangeLogsProps } from 'Store/actions/changeLogs-action'
import { setTrue } from 'Store/actions/toggle-action'
import ChangeLogs from 'Components/modals/ChangeLogs'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

const AlarmsRulesContent = () => {
  const [loading, setLoading] = useState(true)
  useFetchData({
    independentFetches: [
      {
        fetchAction: 'rules',
      },
      {
        fetchAction: 'notificationProfiles',
      },
      {
        fetchAction: 'assets',
      },
      {
        fetchAction: 'geoModules',
      },
      {
        fetchAction: 'profiles',
      },
    ],
    finishAction: () => setLoading(false),
  })

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const assetsStr = useSelector((state) => JSON.stringify(state.assets))

  const rulesByIdStr = useSelector((state) => JSON.stringify(state.rules.byId))
  const rulesAllIdsStr = useSelector((state) => JSON.stringify(state.rules.allIds))
  const rulesById = JSON.parse(rulesByIdStr)
  const rulesAllIds = JSON.parse(rulesAllIdsStr)

  const tableName = 'rules'
  const assets = JSON.parse(assetsStr)
  const fences = useSelector((state) => state.modules.fences.byId)
  const notificationProfile = useSelector((state) => state.notificationProfiles.byId, shallowEqual)
  const cudLoading = useSelector((state) => state.assets.cudLoading)
  const cudSuccess = useSelector((state) => state.assets.cudSuccess)
  const current = useObjSelector((state) => state.rules.current)

  const deleteAction = (ids) => dispatch(deleteRules(ids))
  // const hiddenButtons = ['rules', 'groups', 'import', 'refresh', 'fullscreen', 'toggle fullscreen', 'calendar']

  const hiddenColumns = useSelector((state) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns)

  const groupedValuesColumns = useSelector(
    (state) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )
  const showChangeLogs = useSelector((state) => state.toggle.showChangeLogs)
  const tenant = useSelector((state) => state.login.empresa)

  const profilesById = useSelector((state) => state.profiles.byId)

  const [currentGroup, setCurrentGroup] = useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = React.useMemo(
    () => [
      {
        Header: t('Code'),
        accessor: 'id',
        aggregate: 'uniqueCount',
        Cell: ({ value }) => <span>{value}</span>,
        Aggregated: ({ value }) => `${value} ${'ids'}`,
      },
      {
        Header: t('Name'),
        accessor: 'name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('names')}`,
        Cell: ({ value }) => <TableSpan value={value} />,
      },
      {
        Header: t('Descrição'),
        accessor: 'description',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('description')}`,
        Cell: ({ value }) => <TableSpan value={value} />,
      },
      {
        Header: t('Type'),
        accessor: 'type',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('types')}`,
        Cell: ({ value }) => <TableSpan value={t(value)} />,
      },
      {
        Header: t('Property'),
        accessor: 'sourceName',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${value === 1 ? t('Property') : t('Properties')}`,
        Cell: ({ value }) => <TableSpan capitalize value={t(value)}></TableSpan>,
      },
      {
        Header: t('Value'),
        accessor: 'sourcePropValue',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${value === 1 ? t('Value') : t('Values')}`,
        Cell: (data) => {
          const sourceProp = data.row?.original?.sourceProp
          const value =
            sourceProp === 'atribuição'
              ? data.value.map((e) => {
                  return profilesById[e]?.name ?? ''
                })
              : data.value

          return <TableSpan capitalize value={value?.map((e) => t(e)).join(', ')}></TableSpan>
        },
      },
      {
        Header: t('Scope'),
        accessor: 'scope',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('scopes')}`,
        Cell: ({ value }) => <span>{t(value)}</span>,
      },
      {
        Header: 'Perímetro/Grupo de Perímetros',
        accessor: 'targetName',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('Fences')}`,
        Cell: ({ value }) => <TableSpan value={value} />,
      },
      {
        Header: t('Actions'),
        accessor: 'action',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('actions')}`,
        Cell: ({ value }) => {
          return (
            <TableSpan value={value.map((e, i) => (i + 1 === value.length ? t(e.type) : t(e.type) + ', '))}></TableSpan>
          )
        },
      },
      {
        Header: 'Perfil de Notificação',
        accessor: 'notificationProfile',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('level(s)')}`,
        Cell: ({ value }) => <span>{t(value)}</span>,
      },
      {
        Header: 'Mensagem',
        accessor: 'message',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'mensagens'}`,
        Cell: ({ value }) => <TableSpan value={value} />,
      },
      {
        Header: t('sendTo'),
        accessor: 'sendTo',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('emails')}`,
      },
      {
        Header: t('Criado por'),
        accessor: 'createdBy',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('names')}`,
      },
      {
        Header: t('Criado em'),
        accessor: 'createdAt',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('hours')}`,
        Cell: ({ value }) => <span title={m(value).format('lll')}>{m(value).fromNow()}</span>,
      },
      {
        accessor: 'updatedAt',
        Header: t('updatedAt'),
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => {
          return `${value} Data(s)`
        },
        Cell: ({
          value,
          row: {
            original: { id },
          },
        }) => {
          return (
            <ClickableLink
              title={moment(value).format('lll')}
              onClick={() => {
                dispatch(setCurrentRule({ id }))
                dispatch(setChangeLogsProps([['entityId', `${tenant}#${id}`]]))
                dispatch(setTrue('showChangeLogs'))
              }}
            >
              {moment(value).fromNow()}
            </ClickableLink>
          )
        },
      },
      {
        Header: t('Status'),
        accessor: 'status',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'status'}`,
        Cell: ({ row }) => {
          return (
            <Checkbox
              toggle
              checked={(row?.original?.status || row.groupByVal) === 'on'}
              disabled={row.isGrouped}
              onChange={(a, { checked }) =>
                dispatch(
                  saveRule({
                    mutation: 'update',
                    fields: {
                      ...omit(row.original, ['notificationProfile', 'sourceName', 'targetName', 'type']),
                      status: checked ? 'on' : 'off',
                    },
                  })
                )
              }
            />
          )
        },
      },
    ],
    [t, dispatch, tenant, profilesById]
  )
  const data = React.useMemo(
    () =>
      rulesAllIds?.map((id) => {
        let row = rulesById?.[id]
        row.message =
          row?.action
            ?.map((eachMsg) => eachMsg.messageProfileId?.split('#')[1] || eachMsg.messageProfileId)
            .join(', ') || 'Sem Mensagem'
        row.notificationProfile = notificationProfile[row?.notificationProfileId]?.name || 'Nenhum'
        row.sourceName = assets.byId[row.sourceId[0]]?.name || row.sourceId[0] || row.sourceProp
        row.sourceId.forEach((sourceId, i) => {
          if (i > 0) row.sourceName += `, ${assets.byId[sourceId]?.name || sourceId}`
        })
        row.targetName = row.targetId
          .map((targetId) => {
            return fences[targetId]?.label || fences[targetId]?.code
          })
          .filter(Boolean)
          .join(', ')

        row.type = `${t(row?.sourceType)}/${t(row?.targetType)}`
        return row
      }),
    [rulesById, rulesAllIds, assets, fences, notificationProfile, t]
  )

  return (
    <>
      <ReactTable
        table={tableName}
        hiddenColumns={hiddenColumns}
        // hiddenButtons={hiddenButtons}
        columns={columns}
        data={data}
        showPlaceholders={loading}
        deleteAction={deleteAction}
        initialSortBy={{ desc: true, id: 'createdAt' }}
        currentGroup={currentGroup}
        setCurrentGroup={setCurrentGroup}
        cudLoading={cudLoading}
        cudSuccess={cudSuccess}
      />
      {showChangeLogs && <ChangeLogs entity="rule" currentItem={current} />}
    </>
  )
}

export default memo(AlarmsRulesContent, (props, nextProps) => {
  return !nextProps.autoUpdate
})
