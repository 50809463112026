import React, { Component, Fragment } from 'react'
import ReactDropzone from 'react-dropzone'
import request from 'superagent'
import Popup from 'reactjs-popup'
import { uploadFile } from 'react-s3'
import { setTrue, setFalse } from 'Store/actions/toggle-action'
import { urlServer } from 'Utils/url'
import { configs3 } from 'Utils/constantes'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { canAccess } from 'Utils/rbac'

class FileUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      dropzoneActive: false,
      mapName: '',
      nameValid: false,
    }
    //openModal = openModal.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mapName !== this.state.mapName) {
      if (this.state.mapName.length > 0) {
        this.setState({ nameValid: true })
      } else this.setState({ nameValid: false })
    }
  }
  fileUpload = (e) => {
    e.preventDefault()
    const { files, mapName } = this.state
    /* Enquanto não tem como inputar os cantos*/
    const cantos = [
      { lat: this.props.center[0] - 0.002, lng: this.props.center[1] - 0.002 },
      { lat: this.props.center[0] - 0.002, lng: this.props.center[1] + 0.002 },
      { lat: this.props.center[0] + 0.002, lng: this.props.center[1] - 0.002 },
      { lat: this.props.center[0] + 0.002, lng: this.props.center[1] + 0.002 },
    ]
    this.props.setTrue('loadingScreen')
    uploadFile(files[0], configs3)
      .then((data) => {
        request
          .post(urlServer() + '/map/upload')
          .send({
            info: { url: data.location, corners: cantos },
            nome: mapName,
            empresa: this.props.empresa,
            login: this.props.login,
          })
          .then((res) => {
            this.props.setFalse('loadingScreen')
            alert('Imagem salva com sucesso!')
            this.props.refMap.setView(this.props.center, 17)
          })
          //Problema no servidor
          .catch((err) => {
            this.props.setFalse('loadingScreen')
            alert('Ocorreu um erro ao subir a imagem!')
            console.error(err)
          })
      })
      //Problema no s3
      .catch((err) => {
        this.props.setFalse('loadingScreen')
        alert('Ocorreu um erro ao subir a imagem na aws!')
        console.error(err)
      })
    this.onCloseModal()
  }
  onPreviewDrop = (files) => {
    if (files.length < 1) {
      this.onCloseModal()
      alert('Só arquivo de imagem é aceito')
    }
    this.setState({
      files: this.state.files.concat(files),
    })
  }
  onDragEnter = (v) => {
    this.setState({
      dropzoneActive: true,
    })
  }

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false,
    })
  }
  onCloseModal = () => {
    this.state.files.map((file) => window.URL.revokeObjectURL(file.preview))
    this.setState({
      files: [],
      dropzoneActive: false,
    })
  }
  handleNameChange = (e) => {
    this.setState({ mapName: e.target.value })
  }

  onClickUpload = () => {
    this.setState({
      dropzoneActive: true,
    })
  }

  escClick = (e) => {
    //console.log(e)
    if (e.keyCode === 27) this.onCloseModal()
  }

  render() {
    const { t } = this.props

    const previewStyle = {
      display: 'inline',
      width: 100,
      height: 100,
    }
    const overlayStyle = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      zIndex: 900,
    }
    const textStyle = {
      textAlign: 'center',
      color: 'white',
      marginTop: 250,
      marginRight: 'auto',
      marginBottom: 0,
      marginLeft: 'auto',
    }

    const button = {
      display: 'none',
      textAlign: 'center',
      width: '37px',
      height: '37px',
      zIndex: 899,
      position: 'absolute',
      right: '0px',
      left: '9.5px',
      bottom: '0px',
      top: '467px',
    }

    return (
      <div onKeyDown={this.escClick} className="files">
        <ReactDropzone
          onFileDialogCancel={() => this.onCloseModal()}
          disableClick={!this.state.dropzoneActive}
          style={{ position: 'relative' }}
          accept="image/*"
          onDrop={this.onPreviewDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
        >
          {this.state.dropzoneActive && (
            <div style={overlayStyle}>
              <div style={textStyle}>
                <h1>{t('FileUploadAdd')}</h1>
                <h3>{t('FileUploadClick')}</h3>
                <h3>{t('FileUploadOnly')}</h3>
                <h3>{t('FileUploadEsc')}</h3>
              </div>
            </div>
          )}
          {this.props.children}
          {canAccess({ roles: this.props.perfil, actions: 'WRITE' }) && (
            <div id={'uploadDiv'} style={button}>
              <button title={t('FileUploadUp')} className={'btnimage'} href="#" onClick={this.onClickUpload}>
                <i className="fa fa-upload fa-lg"></i>
              </button>
            </div>
          )}
        </ReactDropzone>
        <Popup
          open={this.state.files.length > 0}
          position="top center"
          onClose={this.onCloseModal}
          closeOnDocumentClick
        >
          <div className="modal-popup" style={{ textAlign: 'center' }}>
            <button className="close" onClick={this.onCloseModal}>
              &times;
            </button>
            <Fragment>
              <h3>Pré-Visualização</h3>
              {this.state.files.map((file) => (
                <img alt="Preview" key={file.preview} src={file.preview} style={previewStyle} />
              ))}
            </Fragment>
            <form method="post" onSubmit={this.fileUpload}>
              <div className="form-group mx-sm-3 mb-2">
                <label htmlFor="inputNameMap"> Nome do mapa:</label>
                <input
                  id="nome_mapa"
                  className="form-control"
                  type="text"
                  onChange={this.handleNameChange}
                  value={this.state.mapName}
                />
                <button type="submit" className="btn btn-primary" disabled={!this.state.nameValid}>
                  Upload
                </button>
              </div>
            </form>
          </div>
        </Popup>
      </div>
    )
  }
}

const mapDispatchToProps = {
  setTrue: setTrue,
  setFalse: setFalse,
}

export default withTranslation()(connect(null, mapDispatchToProps)(FileUpload))
