import React, { useEffect, memo } from 'react'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { Circle, CircleMarker, Tooltip, useLeaflet } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { clearHolesHist } from 'Store/actions/history-action'

const HistHoles = ({ zoom }) => {
  const { map } = useLeaflet()
  const dispatch = useDispatch()

  const histHoles = useObjSelector((state) => state.history.holes.items)
  const loadingScreen = useSelector((state) => state.toggle.loadingScreen)
  const currentGeoHash = useObjSelector((state) => state.history.holes.currentGeoHash)

  useEffect(() => {
    return () => {
      dispatch(clearHolesHist())
    }
  }, [dispatch])

  useEffect(() => {
    histHoles.length > 0 &&
      map.flyToBounds(
        histHoles.map((hole) => hole.coords),
        { maxZoom: 10 }
      )
  }, [histHoles, map])

  return (
    !loadingScreen &&
    histHoles.map((eachHole, i) => {
      const circleKey = eachHole.geohash === currentGeoHash ? `permanent_${eachHole.geohash}` : eachHole.geohash
      return zoom > 15 ? (
        <Circle center={eachHole.coords} radius={9} color={eachHole.color} key={circleKey}>
          {
            <Tooltip
              direction="bottom"
              permanent={eachHole.geohash === currentGeoHash}
            >{`Rótulo: ${eachHole.geohash.substring(5, 8)}  |  Detecções: ${eachHole.holesCount}`}</Tooltip>
          }
        </Circle>
      ) : (
        <CircleMarker center={eachHole.coords} radius={5} color={eachHole.color} key={i}>
          <Tooltip direction="bottom">{`Rótulo: ${eachHole.geohash.substring(5, 8)}  |  Detecções: ${
            eachHole.holesCount
          }`}</Tooltip>
        </CircleMarker>
      )
    })
  )
}

export default memo(HistHoles)
