import { MODULES_SIDE_FORM_WIDTH } from './constants/screenSizes'

export const handleScreenTransformation = ({
  sideBarLeft,
  isMobileSized,
  showStatsBar,
}: {
  sideBarLeft: boolean
  isMobileSized: boolean
  showStatsBar: boolean
}) => {
  let mapSize = 100

  if (sideBarLeft) {
    isMobileSized ? (mapSize = 80) : (mapSize = 92)
  }
  if (showStatsBar) isMobileSized ? (mapSize = 20) : (mapSize -= MODULES_SIDE_FORM_WIDTH)

  const transformSize = 100 - mapSize

  return { mapSize, transformSize }
}
