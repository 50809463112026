import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Calendar } from 'react-date-range'
import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import TimePicker from '../timePicker'
import { withTranslation } from 'react-i18next'
import languages from './languages.js'
import { Button, Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { fetchHistory } from 'Store/actions/history-action'

// Máximo de dias que pode voltar no histórico
//const MAX_PICKABLE_DAYS = 365;
const TimePickerContainer = styled(Grid)`
  padding-bottom: 20px !important;
`

class DateCalendar extends React.Component {
  state = {
    language: this.props.i18n.language,
  }

  static getDerivedStateFromProps(p, s) {
    if (p.i18n && p.i18n.language !== s.language) return { language: p.i18n.language }
    else return null
  }

  static propTypes = {
    /**
     * Valor da data inicial
     * Aceita JS Date ou objeto moment
     *
     * React não suporta `instanceOf` com valores nulos
     */
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /**
     * Valor da data final
     * Aceita JS Date ou objeto moment
     *
     * React não suporta `instanceOf` com valores nulos
     */
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /**
     * Aparece o negocinho do tempo ou não
     */
    showTimePicker: PropTypes.bool,

    /**
     * Máximo de dias no passado que você pode voltar
     */
    maxPickableDays: PropTypes.number,

    /**
     * Usa UTC
     */
    utc: PropTypes.bool,

    /**
     * trata a mudança do checkbox do UTC
     */
    onChangeUtc: PropTypes.func,

    /**
     * Callbackde quando o valor muda
     */
    onChange: PropTypes.func,
  }

  static defaultProps = {
    showAbsolute: true,
    showRelative: false,
    //maxPickableDays: MAX_PICKABLE_DAYS,
  }

  static contextTypes = {
    router: PropTypes.object,
  }

  static getTimeStringFromDate = (date) => {
    return moment(date).local().format('HH:mm')
  }

  static setDateToTime(dateObj, timeStr, { local } = {}) {
    const [hours, minutes, seconds] = timeStr.split(':')

    const date = new Date(+dateObj)

    if (local) {
      date.setHours(hours, minutes)
    } else {
      date.setUTCHours(hours, minutes)
    }

    if (typeof seconds !== 'undefined') {
      date.setSeconds(seconds)
    }

    return date
  }
  handleSelectDateRange = (date) => {
    const { onChange } = this.props
    let startDate = date
    let endDate = date
    //Se o dia for hoje, setar a hora de agora, se não 23:59
    const end = moment(endDate).isSame(new Date(), 'day') ? moment() : moment(endDate).set({ h: 23, m: 59 })
    onChange({
      start: moment(startDate),
      end,
    })
  }

  handleChangeStart = (e) => {
    const { start, end, onChange, t } = this.props
    const startTime = e.target.value
    if (!startTime) {
      return
    }
    let chosenTime = moment(DateCalendar.setDateToTime(start, startTime, { local: true }))
    if (chosenTime >= moment()) {
      alert(t('HistoryCalendarNoFutureTime'))
      return
    }

    onChange({
      start: chosenTime,
      end,
    })
  }

  handleChangeEnd = (e) => {
    const { start, end, onChange, t, maxDate } = this.props
    const endTime = e.target.value
    let final = end ? end : maxDate

    if (!endTime) {
      return
    }
    let chosenTime = moment(DateCalendar.setDateToTime(final, endTime, { local: true }))
    if (chosenTime >= moment()) {
      alert(t('HistoryCalendarNoFutureTime'))
      return
    }

    onChange({
      start,
      end: chosenTime,
    })
  }

  handleSubmit = () => {
    const { start, end, t, fetchHistory, filter } = this.props
    fetchHistory({
      startTime: moment(start).second(0).toISOString(),
      endTime: moment(end).second(59).toISOString(),
      assetsIds: filter.map((e) => e.id),
    })

    if (start > end) {
      alert(t('HistoryCalendarNoStartAfterEnd'))
      return
    }
  }

  render() {
    const {
      className,
      start,
      end,
      showTimePicker,
      minDate,
      maxDate,
      // closeHistory
    } = this.props

    const startTime = start < maxDate ? DateCalendar.getTimeStringFromDate(new Date(start)) : '00:00'
    const endTime =
      end < maxDate
        ? DateCalendar.getTimeStringFromDate(new Date(end))
        : DateCalendar.getTimeStringFromDate(new Date(maxDate))
    const newStart = start ? moment(start).local().toDate() : moment(maxDate).local().toDate()

    // Restrições no intervalo de tempo que você pode selecionar
    // Não tem como selecionar dados futuros porque não somos videntes (ainda)
    // Queremos `maxPickableDays` - 1 (Se hoje é 5 de Jan, e o max é 3 dias, então o minDate tem que ser  3 de Jan)
    // Subtrai mais um dia para forçar que a data final fique inclusiva

    //const minDate = moment().local().subtract(maxPickableDays, 'days').toDate();
    //const minDate = moment('2019-03-07').toDate();
    //const maxDate = new Date();

    return (
      <div className={className} id="date-range">
        <Calendar
          date={newStart}
          onChange={this.handleSelectDateRange}
          className="rdr-DateRangePicker"
          minDate={minDate}
          maxDate={maxDate}
          locale={languages[this.state.language]}
        />
        {showTimePicker && (
          <TimePickerContainer columns={3} centered>
            <TimePicker
              start={startTime}
              end={endTime}
              onChangeStart={this.handleChangeStart}
              onChangeEnd={this.handleChangeEnd}
              className="rdr-TimePicker"
            />
            {/* <div className='rdr-UTCPicker'>
              <span>UTC: </span>
              <Checkbox
                onChange={onChangeUtc}
                checked={utc || false}
                style={{
                  margin: '0 0 0 0.5em',
                }}
              />
            </div> */}
            <Grid.Column width={3}>
              <Button
                primary
                loading={this.props.loadingButton}
                disabled={this.props.loadingButton}
                size="tiny"
                onClick={this.handleSubmit}
              >
                {this.props.t('DrawFinish')}
              </Button>
            </Grid.Column>
          </TimePickerContainer>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = {
  fetchHistory,
}

const mapStateToProps = (state) => {
  return {
    filter: state.filters.result,
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DateCalendar))
