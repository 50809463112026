/*  Author: Luís Mourão/ Felipe Pegado
    Type: Smart
  Description: 
  TODO: - Criar componente dumb grid com o conteúdo do return(render)
*/

import React, { Component } from 'react'
import { saveFavoriteNow } from 'Store/actions/login-action'
import { filterMarkers, setFilter } from 'Store/actions/filters-action'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { setTrue, setFalse, toggle } from 'Store/actions/toggle-action'
import { AssetContainer } from 'Components/Header/NavBar/Dropdowns/Assets/AssetContainer'
import styled from 'styled-components'
import { Dropdown, Popup, Icon, Label } from 'semantic-ui-react'
import { toggleAsset } from 'Store/actions/assets-action'

// const DropdownStyled = styled(Dropdown)`
//   color: #4b72b2;
//   opacity: 1;
//   z-index: 210 !important;
// `

const DropdownStyled = styled(Dropdown)`
  opacity: 1;
  z-index: 210 !important;
`

class DropdownAssets extends Component {
  state = {
    validFavorites: [],
    validAssets: [],
    searchValue: '',
    searchHide: false,
  }

  itemClick = (id) => {
    const assets = JSON.parse(this.props.assetsStr)
    let name = assets[id].name
    if (name) {
      this.props.filterMarkers(name)
      this.props.setFilter(name, [{ id, type: 'assets' }])
      this.props.setTrue('showFitBounds')
    }
  }

  favClick = (id) => {
    const { saveFavoriteNow, favorites } = this.props
    const newFavorites = favorites.filter((f) => {
      return f.toString() !== id.toString()
    })
    saveFavoriteNow(newFavorites)
  }

  assetClick = (id) => {
    const { saveFavoriteNow, favorites } = this.props
    if (favorites.includes(id)) {
      this.favClick(id)
      return
    }
    const newFavorites = [...favorites, id]
    saveFavoriteNow(newFavorites)
  }

  handleSearch = (event, data) => {
    this.setState({ searchValue: data.value })
  }

  hideSearch(assetArray) {
    const { searchHide } = this.state
    this.props.toggleAsset(!searchHide, assetArray)
    this.setState({ searchHide: !searchHide })
  }

  render() {
    const { favorites, assetsStr, assetsIdsStr, assetsActiveIdsStr } = this.props
    const assetsIds = JSON.parse(assetsIdsStr)
    const assets = JSON.parse(assetsStr)
    const assetsActiveIds = JSON.parse(assetsActiveIdsStr)
    const { searchHide } = this.state
    const activeAssets = assetsActiveIds.filter((a) => assets[a]?.idType === 1)
    const assetsThing = assetsIds.filter((a) => assets[a]?.idType === 1)
    return (
      <DropdownStyled
        className="AssetNot"
        trigger={
          <Popup
            inverted
            size="tiny"
            trigger={
              <Icon
                name="microchip"
                size="large"
                style={{ zIndex: 1, color: '#4b72b2' }}
                onClick={() => this.setState({ open: !this.state.open })}
              />
            }
            content={this.props.t('Assets')}
          />
        }
        icon={{
          children: (
            <Label circular size="mini" color="green" floating>
              {activeAssets.length}
            </Label>
          ),
        }}
        style={{ pointerEvents: 'auto' }}
        onBlur={() => this.setState({ open: false })}
        open={this.state.open}
        disabled={assetsThing.length ? false : true}
        pointing="top right"
        onKeyUp={(e) => {
          if (e.key === ' ') {
            e.target.value = e.target.value + ' '
            e.stopPropagation()
          }
        }}
      >
        <Dropdown.Menu>
          <AssetContainer
            favorites={favorites}
            assetArrayStr={JSON.stringify(assetsThing)}
            activeAssetsStr={JSON.stringify(activeAssets)}
            clickItem={this.itemClick}
            clickFav={this.assetClick}
            assetsStr={JSON.stringify(assets)}
            assetsTitle={this.props.assetsTitle}
            searchValue={this.state.searchValue}
            handleSearch={this.handleSearch}
            hideSearch={(assetArray) => this.hideSearch(assetArray)}
            searchHide={searchHide}
            type={this.props.t('Assets')}
          />
        </Dropdown.Menu>
      </DropdownStyled>
    )
  }
}

const mapStateToProps = (state) => ({
  assetsStr: JSON.stringify(state.assets.byId),
  assetsIdsStr: JSON.stringify(state.assets.allIds),
  assetsActiveIdsStr: JSON.stringify(state.assets.allActiveAssetsIds),
  favorites: state.login.favorites,
  refMap: state.login.refMap,
  assetsTitle: state.projects[0]?.info?.customized?.assetsTitle,
  perfil: state.login.perfil,
  inner: state.toggle.showInnerMap,
})

const mapDispatchToProps = {
  setTrue,
  setFalse,
  saveFavoriteNow,
  filterMarkers,
  toggle,
  toggleAsset,
  setFilter,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DropdownAssets))
