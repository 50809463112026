import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dataPhases from '../Phases/phases_data.json'
import { fetchDashboardStellantis } from 'Store/actions/dashboardStellantis-action'
import {
  fetchDashboard,
  exportFencesDashboardReport,
  exportRulesDashboardReport,
  exportInteractionsDashboardReport,
  changeCurrentDashboard,
} from 'Store/actions/dashboards-action'
import ModalHeader from 'Utils/modals/ModalHeader'
import { fetchInteractions } from 'Store/actions/interactions-action'
import Loading from 'Utils/components/Loading'
import MainCards from 'Utils/reactTable/components/MainCards'
import CardLineChart from 'Components/SidebarMenu/Contents/Dashboard/CardLineChart'
import MainStatistics from 'Utils/reactTable/components/MainStatistics'
import Cards from 'Utils/reactTable/components/Cards.js'
import { Segment, Modal, Grid } from 'semantic-ui-react'
import { fetchRules, clearRules } from 'Store/actions/rules-action'
import { PeoplePieGraph } from 'Components/SidebarMenu/Contents/Dashboard/PeoplePieGraph.jsx'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DashboardPanels from 'Components/Cards/DashboardPanels'

const ChartGrid = styled('div')`;
  margin-right: 14px;
  margin-top:15px;
  height 280px;
`

const StatisticsGrid = styled(Grid)`
  margin-top: 35px !important;
  margin-bottom: 60px !important;
`

const emptyArray = []

export const DashboardContent = ({ currentSelected, windowWidth = 2000 }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dashboards = useSelector((state) => state.dashboards)
  const assetsIdsLength = useSelector((state) => state.assets?.allActivePeopleIds?.length || 0)
  const dashboardsTimeAgo = useSelector((state) => state.dashboards.timeAgo)
  const tenant = useSelector((state) => state.tenants.current)
  const hasDataCollectionFeat = tenant?.features?.includes('data_collection')
  const hasInteractionsFeat = tenant?.features?.includes('interactions')
  const dashboard = hasDataCollectionFeat
    ? dashboards.byId['activity']?.name
    : dashboards.byId[dashboards.currentId]?.name

  /*Informações dos filtros*/
  const [currentCard, setCurrentCard] = useState({ filters: [], tgMin: '', color: '', name: 'Saturação' })
  const [time, setTime] = useState(['turno1', 'turno2'])
  const [carModel, setCarModel] = useState('')

  /*Sobre pegar as informações da Store sobre os dashInfos*/
  const cards = useSelector((state) => state.dashboardStellantis.cards)
  const loading = useSelector((state) =>
    !hasDataCollectionFeat ? state.dashboards.loading : !state.toggle.showDashboard
  )

  useEffect(() => {
    if (tenant?.name) dispatch(fetchRules())
    return () => {
      dispatch(clearRules())
    }
  }, [dispatch, tenant])

  useEffect(() => {
    if (hasDataCollectionFeat) dispatch(fetchDashboardStellantis({ time: 'today' }))
    if (hasInteractionsFeat) dispatch(fetchInteractions())
    if (dashboard === 'Regras') dispatch(fetchDashboard('rules'))
  }, [hasDataCollectionFeat, dispatch, dashboard, hasInteractionsFeat])

  let dashboardOptions = emptyArray

  if (hasDataCollectionFeat) {
    dashboardOptions = [
      {
        key: 'activity',
        text: dashboards.byId['activity'].name,
        value: 'activity',
        onClick: (a, { value }) => dispatch(changeCurrentDashboard(value)),
      },
    ]
  } else {
    /**/
    dashboardOptions = dashboards.allIds
      .filter((e) => e !== 'activity' && e !== 'firefighter' && e !== 'people' && e !== 'fences' && e !== 'rules')
      .map((eachId) => ({
        key: eachId,
        text: dashboards.byId[eachId].name,
        value: eachId,
        onClick: (a, { value }) => {
          dispatch(changeCurrentDashboard(value))
        },
      }))
  }

  /*Tabela que antes apresentávamos no dashboard*/

  // let hiddenButtons = [
  //   'groups',
  //   'columns',
  //   'import',
  //   'new',
  //   'edit',
  //   'delete',
  //   'fullscreen',
  //   'toggle fullscreen',
  //   'calendar',
  // ]
  let menuButtons = ['export', 'refresh', 'time']
  const insightActions = { setCarModel, setTime }
  const insightActionsValues = { time, carModel }

  let exportAction

  /*if (['Tiplam', 'Demo'].includes(empresa)) {
    exportAction = () => {
      window.open('https://phygitall-reports.s3.us-east-2.amazonaws.com/Tiplam/reportLastDay.csv', '_blank')
    }
  } else  {*/
  if (dashboard === 'Perímetros') {
    if (hasInteractionsFeat)
      exportAction = () => {
        dispatch(exportInteractionsDashboardReport())
      }
    else
      exportAction = () => {
        dispatch(exportFencesDashboardReport())
      }
  }
  if (dashboard === 'Regras') {
    exportAction = () => {
      dispatch(exportRulesDashboardReport())
    }
  }
  //}
  let fakeCards
  if (hasDataCollectionFeat && dashboard === 'Dashboard') {
    // hiddenButtons = [
    //   'groups',
    //   'columns',
    //   'import',
    //   'new',
    //   'edit',
    //   'delete',
    //   'rules',
    //   'refresh',
    //   'fullscreen',
    //   'toggle fullscreen',
    //   'calendar',
    // ]
    menuButtons = ['export', 'refresh', 'time']
    const reducerAcc = (acc, val) => acc + val
    const reducerMax = (max, val) => Math.max(max, val)
    const reducerMin = (min, val) => Math.min(min, val)
    const filteredSampleData = dataPhases.filter((f) => time.includes('turno' + (f.shift + 1)))

    const durationByLabel = (phase, type = 'acc') => {
      let result = 0
      let dataValues = filteredSampleData?.filter((f) => phase.includes(f.phase)).map((el) => el['duration'])

      switch (type) {
        case 'acc':
          result = dataValues?.reduce(reducerAcc, 0)
          break
        case 'max':
          result = dataValues?.reduce(reducerMax, -Infinity)
          break
        case 'min':
          result = dataValues?.reduce(reducerMin, Infinity)
          break
        case 'med':
          result = dataValues?.length === 0 ? 0 : dataValues?.reduce(reducerAcc, 0) / dataValues?.length
          break
        case 'mna':
          dataValues.sort((p, n) => p - n)
          result =
            dataValues.length % 2 === 1
              ? dataValues[Math.ceil(dataValues.length / 2)]
              : (dataValues[dataValues.length / 2] + dataValues[dataValues.length / 2 + 1]) / 2
          break
        default:
          break
      }
      if (!result) return 0
      return ['NaN', '-Infinity', 'Infinity'].includes(result.toString()) ? 0 : result
    }
    const toMinutes = (valSec) => {
      return Math.round((valSec / 60) * 10) / 10
    }
    const toFixed1 = (valSec) => {
      return Math.round(valSec * 10) / 10
    }

    fakeCards = [
      {
        data: [
          { name: 'Group B', value: toMinutes(durationByLabel([0], 'acc')) },
          { name: 'Group A', value: toMinutes(durationByLabel([1, 2, 3, 4, 5], 'acc')) },
        ],
        filters: [1, 2, 3, 4, 5],
        tgMin: toFixed1(durationByLabel([1, 2, 3, 4, 5], 'med')),
        color: 'green',
        image: 'list alternate outline',
        header:
          cards?.['card#1']?.title || '' /*(10+toFixed1(durationByLabel([2],'med'))+toFixed1(durationByLabel([3],'med'))
                  +toFixed1(durationByLabel([4],'med'))+toFixed1(durationByLabel([5],'med')))+'s',*/,
        meta: cards?.['card#1']?.titleLabel || '',
        time: 'xxxxx',
        description:
          cards?.['card#1']?.body
            ?.filter((e) => e.source === 'fase 1')
            .map((line) => line?.name + ': ' + line?.value) || emptyArray,
        duration: cards?.['card#1']?.mainInfo || '',
        labelValue: cards?.['card#1']?.mainInfoLabel || '',
      },
      {
        data: [
          { name: 'Group B', value: toMinutes(durationByLabel([0], 'acc')) },
          { name: 'Group A', value: toMinutes(durationByLabel([1, 2, 3, 4, 5], 'acc')) },
        ],
        filters: [1, 2, 3, 4, 5],
        tgMin: toFixed1(durationByLabel([1, 2, 3, 4, 5], 'med')),
        color: 'yellow',
        image: 'list alternate outline',
        header:
          cards?.['card#2']?.title || '' /*(10+toFixed1(durationByLabel([2],'med'))+toFixed1(durationByLabel([3],'med'))
                  +toFixed1(durationByLabel([4],'med'))+toFixed1(durationByLabel([5],'med')))+'s',*/,
        meta: cards?.['card#2']?.titleLabel || '',
        time: 'xxxxx',
        description: cards?.['card#2']?.body?.map((line) => line?.name + ': ' + line?.value) || emptyArray,
        duration: cards?.['card#2']?.mainInfo || '',
        labelValue: cards?.['card#2']?.mainInfoLabel || '',
      },
      {
        data: [
          { name: 'Group B', value: toMinutes(durationByLabel([0], 'acc')) },
          { name: 'Group A', value: toMinutes(durationByLabel([1, 2, 3, 4, 5], 'acc')) },
        ],
        filters: [1, 2, 3, 4, 5],
        tgMin: toFixed1(durationByLabel([1, 2, 3, 4, 5], 'med')),
        color: 'brown',
        image: 'list alternate outline',
        header:
          cards?.['card#3']?.title || '' /*(10+toFixed1(durationByLabel([2],'med'))+toFixed1(durationByLabel([3],'med'))
                  +toFixed1(durationByLabel([4],'med'))+toFixed1(durationByLabel([5],'med')))+'s',*/,
        meta: cards?.['card#3']?.titleLabel || '',
        time: 'xxxxx',
        description: cards?.['card#3']?.body?.map((line) => line?.name + ': ' + line?.value) || emptyArray,
        duration: cards?.['card#3']?.mainInfo || '',
        labelValue: cards?.['card#3']?.mainInfoLabel || '',
      },
      {
        data: [
          { name: 'Group B', value: toMinutes(durationByLabel([0], 'acc')) },
          { name: 'Group A', value: toMinutes(durationByLabel([1, 2, 3, 4, 5], 'acc')) },
        ],
        filters: [1, 2, 3, 4, 5],
        tgMin: toFixed1(durationByLabel([1, 2, 3, 4, 5], 'med')),
        color: 'orange',
        image: 'list alternate outline',
        header:
          cards?.['card#4']?.title || '' /*(10+toFixed1(durationByLabel([2],'med'))+toFixed1(durationByLabel([3],'med'))
                  +toFixed1(durationByLabel([4],'med'))+toFixed1(durationByLabel([5],'med')))+'s',*/,
        meta: cards?.['card#4']?.titleLabel || '',
        time: 'xxxxx',
        description: cards?.['card#4']?.body?.map((line) => line?.name + ': ' + line?.value) || emptyArray,
        duration: cards?.['card#4']?.mainInfo || '',
        labelValue: cards?.['card#4']?.mainInfoLabel || '',
      },
      {
        data: [
          { name: 'Group B', value: toMinutes(durationByLabel([0], 'acc')) },
          { name: 'Group A', value: toMinutes(durationByLabel([1, 2, 3, 4, 5], 'acc')) },
        ],
        filters: [1, 2, 3, 4, 5],
        tgMin: toFixed1(durationByLabel([1, 2, 3, 4, 5], 'med')),
        color: 'blue',
        image: 'list alternate outline',
        header:
          cards?.['card#5']?.title || '' /*(10+toFixed1(durationByLabel([2],'med'))+toFixed1(durationByLabel([3],'med'))
                  +toFixed1(durationByLabel([4],'med'))+toFixed1(durationByLabel([5],'med')))+'s',*/,
        meta: cards?.['card#5']?.titleLabel || '',
        time: 'xxxxx',
        description: cards?.['card#5']?.body?.map((line) => line?.name + ': ' + line?.value) || emptyArray,
        duration: cards?.['card#5']?.mainInfo || '',
        labelValue: cards?.['card#5']?.mainInfoLabel || '',
      },
    ]
  } else {
    if (dashboardsTimeAgo === 'realtime') {
      menuButtons = ['export', 'refresh', 'time']
    } else {
      menuButtons = ['export', 'time']
    }
    // hiddenButtons = [
    //   'groups',
    //   'columns',
    //   'import',
    //   'carModel',
    //   'edit',
    //   'delete',
    //   'shift',
    //   'rules',
    //   'new',
    //   'fullscreen',
    //   'toggle fullscreen',
    //   'calendar',
    // ]
  }
  /*Only a few tenants have people dashboard available*/
  let filtdashboardOptions = emptyArray
  filtdashboardOptions = dashboardOptions.filter((f) =>
    !tenant?.features?.includes('people_dashboard') ? f.text !== 'Pessoas' : true
  )
  let dataPeople = emptyArray
  if (dashboard === 'Pessoas') {
    dataPeople = [
      { name: 'Online', value: assetsIdsLength },
      { name: 'Sem Rede', value: 0 },
      { name: 'Logout Manual', value: 0 },
    ]
    // hiddenButtons = [
    //   'shift',
    //   'carModel',
    //   'time',
    //   'groups',
    //   'columns',
    //   'import',
    //   'new',
    //   'edit',
    //   'delete',
    //   'rules',
    //   'fullscreen',
    //   'toggle fullscreen',
    // ]
    menuButtons = ['export', 'refresh', 'calendar']
  }
  if (dashboard === 'Dashboard') {
    menuButtons = ['default', 'toggle fullscreen', 'pin', 'saveDefault']
  }

  const handleSave = () => {
    alert('aqui eu salvo')
  }

  return !loading ? (
    <>
      <ModalHeader
        dashboard={tenant?.name !== 'Stellantis' && dashboard}
        dashboardOptions={filtdashboardOptions.sort((a, b) =>
          a.text.localeCompare(b.text, 'pt-br', { ignorePunctuation: true })
        )}
        name={'insights_header'}
        exportAction={exportAction}
        insightActions={insightActions}
        insightActionsValues={insightActionsValues}
        onSave={handleSave}
        // hiddenButtons={hiddenButtons}
        menuButtons={menuButtons}
      />
      <Modal.Content style={{ padding: '0', height: 'auto', minHeight: '76vh', overflowY: 'scroll' }}>
        <Segment style={{ border: 'none', boxShadow: 'none' }}>
          {hasDataCollectionFeat && (
            <>
              <ChartGrid>
                <CardLineChart
                  dashboard={dashboard}
                  pieDataAll={emptyArray}
                  currentCard={currentCard}
                  filters={insightActionsValues}
                  currentSelected={currentSelected}
                />
              </ChartGrid>
              <StatisticsGrid centered>
                <MainStatistics />
              </StatisticsGrid>
              <MainCards />
            </>
          )}

          {dashboard === 'Pessoas' ? (
            <PeoplePieGraph data={dataPeople} />
          ) : dashboard === 'Dashboard' ? (
            <DashboardPanels windowWidth={windowWidth} />
          ) : (
            <Cards
              cardTime={t('insights_seconds_ago')}
              key="RTCards"
              fakeCards={fakeCards}
              dashboard={dashboard}
              setCurrentCard={setCurrentCard}
            />
          )}
        </Segment>
      </Modal.Content>
    </>
  ) : (
    <div style={{ marginTop: '35vh' }}>
      <Loading />
      <h4 style={{ textAlign: 'center' }}>{t('loading_icon_message')}</h4>
    </div>
  )
}
