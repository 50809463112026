import React, { memo, useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAssociations } from 'Store/actions/assets-action'
import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { Checkbox } from 'semantic-ui-react'
import { saveColumnsPreference } from 'Store/actions/login-action'
import useFetchData from 'Utils/hooks/useFetchData'

const AssociationsContent = () => {
  const [loading, setLoading] = useState(true)

  useFetchData({
    independentFetches: [{ fetchAction: 'associations' }],
    finishAction: () => setLoading(false),
  })

  const { t } = useTranslation()
  const tableName = 'associations'

  const allIdsStr = useSelector((state) => JSON.stringify(state.assets.associations || []))
  const allIds = JSON.parse(allIdsStr)

  const dispatch = useDispatch()
  const [status, setStatus] = useState([])
  const fetchAction = () => {
    dispatch(fetchAssociations())
  }
  // let hiddenColumns = ['assetId', 'deviceId']
  const hiddenColumns = useSelector((state) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns)

  const groupedValuesColumns = useSelector(
    (state) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = useMemo(
    () => [
      {
        Header: t('assetId'),
        accessor: 'assetId',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ids`,
      },
      {
        Header: t('asset/person'),
        accessor: 'assetName',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} nomes`,
      },
      {
        Header: t('deviceId'),
        accessor: 'deviceId',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ids`,
      },
      {
        Header: t('Device'),
        accessor: 'deviceName',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} nomes`,
      },
      // {
      //   Header: 'Início',
      //   accessor: 'start',
      //   Cell: ({ value }) => (value ? <span title={m(value).format('lll')}> {m(value).fromNow()}</span> : t('Now')),
      //   aggregate: 'uniqueCount',
      //   Aggregated: ({ value }) => `${value} horários`,
      // },
      // {
      //   Header: 'Fim',
      //   accessor: 'end',
      //   Cell: ({ value }) => (value ? <span title={m(value).format('lll')}> {m(value).fromNow()}</span> : t('Now')),
      //   aggregate: 'uniqueCount',
      //   Aggregated: ({ value }) => `${value} horários`,
      //   disableGlobalFilter: true,
      // },
      {
        Header: t('startExtense'),
        accessor: 'startExtense',
        Cell: ({ value }) => (value ? m(value).format('lll') : t('Now')),
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} horários`,
      },
      {
        Header: t('endExtense'),
        accessor: 'endExtense',
        Cell: ({ value }) => (value ? m(value).format('lll') : t('Now')),
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} horários`,
        disableGlobalFilter: true,
      },
      {
        Header: t('Status'),
        accessor: 'status',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('status')}`,
        Cell: ({ value, row: { original } }) => (
          <Checkbox toggle checked={!!value} onChange={(c, { checked }) => setStatus(original?.assetId)} />
        ),
      },
    ],
    [t /*, dispatch*/]
  )

  const rows = allIds

  const data = useMemo(
    () =>
      rows.map((id) => {
        if (status?.toString() === id.assetId?.toString())
          if (id['status'] === true) {
            id.end = Date.now()
            id.status = false
          }
        if (id.start === id.end) id.end = ''
        id.startExtense = id.start
        id.endExtense = id.end

        return id
      }),
    [rows, status]
  )

  return (
    <ReactTable
      table={'associations'}
      tableName={tableName}
      initialSortBy={{ desc: true, id: 'startExtense' }}
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
      fetchAction={fetchAction}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      showPlaceholders={loading}
    />
  )
}

export default memo(AssociationsContent, (props, nextProps) => {
  return !nextProps.autoUpdate
})
