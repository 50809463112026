import React, { useMemo } from 'react'
import StatsBarFormTagsEdit from './StatsBarFormTagsEdit'
import { InputOnChangeData } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { setModulesProps } from 'Store/actions/modules-action'
import { RootStore } from '../../Store/initialStore'

const StatsBarFormTags = ({ windowHeight = 0 }) => {
  const dispatch = useDispatch()

  const tags = useSelector(
    (state: RootStore) => state.modules.wip.properties?.tags ?? state.modules.current?.properties?.tags ?? {}
  )

  const tagsArray = useMemo(() => Object.entries(tags).map(([key, value]) => ({ key, value, existing: true })), [tags])
  const [tagsState, setTagsState] = React.useState(tagsArray)

  const onChange = (_: any, { value, id, idx }: InputOnChangeData) => {
    const newTags = tagsState.map((tag, index) => (index === idx ? { ...tag, [id]: value } : tag))

    setTagsState(newTags)
    dispatch(
      setModulesProps([
        ['wip.properties.tags', newTags.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {})],
      ])
    )
  }
  const onAddTag = () => setTagsState([...tagsState, { key: '', value: '', existing: false }])
  const onRemoveTag = (idx: number) => {
    const newTags = [...tagsState]
    newTags.splice(idx, 1)
    setTagsState(newTags)
    dispatch(
      setModulesProps([
        ['wip.properties.tags', newTags.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {})],
      ])
    )
  }
  return (
    <StatsBarFormTagsEdit
      tagsList={tagsState}
      windowHeight={windowHeight}
      onChange={onChange}
      onAddTag={onAddTag}
      onRemoveTag={onRemoveTag}
    />
  )
}

export default StatsBarFormTags
