import produce from 'immer'
import { set } from 'lodash'

/**
 * Update state properties immutably based on the given payload.
 *
 * This function takes an initial state object and a payload array where each element is a tuple
 * consisting of a property (`prop`) and its corresponding value (`value`). It iterates through
 * the payload array, updating the state immutably for each property-value pair using the `set`
 * function from 'immutable-set'.
 *
 * @param {Object} state - The initial state object to be updated.
 * @param {Array} payload - An array of [prop, value] pairs representing the properties to update and their values.
 *
 * @returns {Object} - The new state object with the updated properties.
 */
export function setStoreProps(state, payload) {
  return produce(state, (newState) => {
    for (const [prop, value] of payload) {
      set(newState, prop, value)
    }
  })
}

export function getEntityField(state, entity, field) {
  return state[entity].wip?.[field] || state[entity].current?.[field]
}
