import * as z from 'zod'

const ticon = z.object({
  name: z.string().optional(),
  code: z.string().optional(),
  qty: z.number().optional(),
})

const vehicle = z.object({
  name: z.string().optional(),
})

const Phase = z.object({
  index: z.number(),
  name: z.string().nonempty(),
  description: z.string(),
  color: z.string(),
  code: z.string().optional(),
  vehicle: z.array(vehicle),
  ticon: z.array(ticon),
})

const stepThreeSchema = z.object({
  phases: z.array(Phase),
})

export type StepThree = z.infer<typeof stepThreeSchema>

export default stepThreeSchema
