import React, { Fragment, useState } from 'react'
import { Dropdown, Input } from 'semantic-ui-react'
import { useLoadData } from 'Utils/hooks/useLoadData'
import { useTranslation } from 'react-i18next'

const Organizations = ({ currentOrg, orgs }) => {
  const [search, setSearch] = useState('')
  const { t } = useTranslation()
  const loadData = useLoadData()
  const handleClick = (a, obj) => {
    loadData(obj.name)
  }
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }
  return (
    <Fragment>
      <Dropdown.Header icon="fort awesome" content={t('Organizations')} />
      <Dropdown.Divider />
      <Input icon="search" iconPosition="left" placeholder={t('Search')} value={search} onChange={handleSearch} />
      {orgs
        .sort((prev, next) => prev.label.localeCompare(next.label))
        .filter((org) => org.label.toLowerCase().includes(search.toLowerCase()))
        .map((org) => (
          <Dropdown.Item selected={org.name === currentOrg} key={org.id} onClick={handleClick} name={org.name}>
            {org.label}
          </Dropdown.Item>
        ))}
    </Fragment>
  )
}

export default Organizations
