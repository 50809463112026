import axios from 'axios'
import { urlServer } from 'Utils/url'
import { notificationState, NotificationStatus } from 'Utils/notifications'

export const urlBase = 'https://1yccc8vzc6.execute-api.us-east-2.amazonaws.com/v0'

export const URL_BASE =
  process.env.NODE_ENV === 'development'
    ? 'https://yyiwc1gyyf.execute-api.us-east-2.amazonaws.com/dev'
    : 'https://0j1x5st14i.execute-api.us-east-2.amazonaws.com/prod'

export const REPORT_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://ptgg373gi8.execute-api.us-east-2.amazonaws.com/dev'
    : 'https://ygei5qn3o5.execute-api.us-east-2.amazonaws.com/prod'

const api = axios.create({
  baseURL: urlServer(),
  timeout: 60000,
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      notificationState.addNotification({
        title: 'Session expired',
        message: 'Your session seems to be expired, please log in again...',
        status: NotificationStatus.ERROR,
        sticky: true,
        actions: {
          primary: [
            {
              run: () => document.location.reload(),
              label: 'Sign in',
            },
          ],
        },
      })
      return Promise.reject(error)
    }
    if (error?.response?.status !== 200 || error?.response?.status !== 201) {
      console.log('error', error)
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export default api
