/*  Author: Luís Mourão
    Type: reducer
    Description: reducer from modules store
    To do list: Incluir Current
*/

import { merge } from 'lodash'
import produce from 'immer'
import initialStore from 'Store/initialStore'
import { parseGeoModules } from 'Utils/parseGeoModules'
import { setStoreProps } from './reducerUtils'

export default function modulesReducer(state = initialStore.modules, { type, id, payload }) {
  switch (type) {
    case 'SET_MODULES_PROPS':
      return setStoreProps(state, payload)

    case 'FETCH_GEO_MODULES_START':
      return produce(state, (newState) => {
        newState.loading = true
      })

    case 'FETCH_MODULES_START':
      return produce(state, (newState) => {
        newState[payload].loading = true
      })

    case 'FETCH_MODULES':
      if (!payload) {
        // console.error('Empty payload in', type)
        return state
      }
      if (!Array.isArray(payload)) {
        console.error('Module initial store payload is not Array')
        return state
      }
      return produce(state, (newState) => {
        payload.forEach((each) => {
          if (!['fences', 'refs', 'routes'].includes(each.moduleType)) {
            if (!newState[each.moduleType]) {
              newState[each.moduleType] = {}
              newState[each.moduleType].byId = {}
              newState[each.moduleType].allIds = []
              newState[each.moduleType].groups = []
              newState.current = {}
            }
            newState[each.moduleType].byId[each.id] = each
            newState[each.moduleType].byId[each.id].groups.forEach((group) => {
              if (!newState[each.moduleType].groups.includes(group)) newState[each.moduleType].groups.push(group)
            })
            if (!newState[each.moduleType].allIds.includes(each.id.toString()))
              newState[each.moduleType].allIds.push(each.id.toString())
          }
        })
      })

    case 'FETCH_GEO_MODULE_BY_ID_START':
      return produce(state, (newState) => {
        newState.wip = { id, loading: true }
      })

    case 'FETCH_GEO_MODULES_SUCCESS':
      return produce(state, (newState) => {
        payload.forEach((each) => {
          if (each) {
            newState[each.type].byId[each.id] = each
            parseGeoModules(newState, each)
            if (!state[each.type].allIds.includes(each.id)) newState[each.type].allIds.push(each.id)
          }
        })
        newState.wip = {}
        newState.loading = false
        newState.lastFetchTime = new Date().toISOString()
        newState.loading = false
      })

    case 'FETCH_GEO_MODULES_FAILURE':
      return { ...state, loading: false }

    case 'UPDATE_MODULE_START':
      return {
        ...state,
        [payload.moduleType]: {
          ...state[payload.moduleType],
          byId: {
            ...state[payload.moduleType].byId,
            [id]: { ...state[payload.moduleType].byId[id], cudLoading: true },
          },
        },
      }
    case 'UPDATE_MODULE_SUCCESSFULL':
    case 'UPDATE_MODULE':
      return produce(state, (newState) => {
        if (!newState[payload.moduleType]) {
          newState[payload.moduleType] = {}
          newState[payload.moduleType].byId = {}
          newState[payload.moduleType].allIds = []
          newState.current = {}
        }
        if (!newState[payload.moduleType].byId[id]) {
          newState[payload.moduleType].byId[id] = {}
          newState[payload.moduleType].allIds.push(id)
        }
        newState[payload.moduleType].byId[id] = {
          info: payload.data.info,
          id: payload.data.id_modulo,
          moduleType: payload.data.type,
          name: payload.data.nome,
          cudLoading: false,
          success: true,
        }
      })

    case 'UPDATE_MODULE_GROUPS_SUCCESSFULL':
    case 'UPDATE_MODULE_GROUPS':
      return produce(state, (newState) => {
        payload.selected.forEach((p) => {
          if (!newState[payload.moduleType]) {
            newState[payload.moduleType] = {}
            newState[payload.moduleType].byId = {}
            newState[payload.moduleType].allIds = []
            newState[payload.moduleType].groups = []
            newState.current = {}
          }
          if (!newState[payload.moduleType].groups.includes(payload.groupName))
            newState[payload.moduleType].groups.push(payload.groupName)

          newState[payload.moduleType].allIds.forEach((modId) => {
            if (payload.selected.includes(newState[payload.moduleType].byId[modId].name))
              if (!newState[payload.moduleType].byId[modId].groups.includes(payload.groupName))
                newState[payload.moduleType].byId[modId].groups.push(payload.groupName)
          })
        })
      })

    case 'REMOVE_MODULE_GROUPS_SUCCESSFULL':
    case 'REMOVE_MODULE_GROUPS':
      return produce(state, (newState) => {
        payload.selected.forEach((p) => {
          if (!newState[payload.moduleType]) {
            newState[payload.moduleType] = {}
            newState[payload.moduleType].byId = {}
            newState[payload.moduleType].allIds = []
            newState[payload.moduleType].groups = []
            newState.current = {}
          }

          newState[payload.moduleType].allIds.forEach((modId) => {
            if (payload.selected.includes(newState[payload.moduleType].byId[modId].name))
              newState[payload.moduleType].byId[modId].groups = newState[payload.moduleType].byId[modId].groups.filter(
                (f) => f !== payload.groupName
              )
          })
        })
      })

    case 'DELETE_MODULE':
    case 'DYNAMIC_DELETE_SUCCESS':
      return produce(state, (newState) => {
        if (!newState[payload.moduleType]) {
          newState[payload.moduleType] = {}
          newState[payload.moduleType].byId = {}
          newState[payload.moduleType].allIds = []
          newState.current = {}
        }
        if (!id) id = payload?.ids?.split(',') || []
        newState[payload.moduleType].allIds = newState[payload.moduleType].allIds.filter((a) => !id.includes(a))
      })

    case 'SET_CURRENT_MODULE':
      return produce(state, (newState) => {
        if (payload) {
          newState.current = id ? state[payload].byId[id] : {}
        }
      })

    case 'MODULE_UPDATE_WIP':
      return produce(state, (newState) => {
        if (payload) {
          /*Check if name or uniqueID*/
          if (payload?.geometry) {
            newState.wip.geometry = payload?.geometry
            if (!payload.changes) payload.changes = {}
            payload.changes.geometry = payload?.geometry
          }
          if (payload?.mradius) {
            if (!payload.changes) payload.changes = {}
            payload.changes.mradius = payload?.mradius
          }
          newState[payload.moduleType].geoWip = merge({}, newState[payload.moduleType].geoWip, payload.changes)
        }
      })

    case 'MODULE_CLEAR_WIP':
      return produce(state, (newState) => {
        newState[payload].geoWip = {}
        newState.wip = {}
      })

    case 'MODULE_FETCH_CHANGES_SUCCESS':
      return produce(state, (newState) => {
        newState.wip = { id: id, changes: payload }
      })

    case 'MODULE_FETCH_CHANGES_CLEAR':
      return produce(state, (newState) => {
        newState.wip = { id: id, changes: [] }
      })

    case 'SAVE_GEO_MODULE_START':
      return { ...state, cudLoading: true }

    case 'SAVE_GEO_MODULE_SUCCESS':
      return produce(state, (newState) => {
        newState[payload.type].byId[payload.id] = payload
        parseGeoModules(newState, payload)
        if (!state[payload.type].allIds.includes(payload.id)) newState[payload.type].allIds.push(payload.id)
        newState.cudLoading = false
        newState.cudSuccess = true
      })

    case 'SAVE_GEO_MODULE_ERROR':
      return produce(state, (newState) => {
        newState.cudLoading = false
      })

    case 'DELETE_GEO_MODULE_SUCCESS':
      return produce(state, (newState) => {
        newState[payload.type].allIds = state[payload.type].allIds.filter((each) => {
          return each !== payload.id
        })
      })

    case 'CREATE_MODULE_REQUEST':
      return { ...state, wip: { ...payload }, [payload.moduleType]: { ...state[payload.moduleType], cudLoading: true } }

    case 'CREATE_MODULE_FAILURE':
      return {
        ...state,
        wip: { ...payload },
        [payload.moduleType]: { ...state[payload.moduleType], loading: false, cudSuccess: false },
      }

    case 'CREATE_MODULE_SUCCESSFULL':
      return produce(state, (newState) => {
        const moduleId = payload.data.id
        newState[payload.type].allIds.push(moduleId)
        newState[payload.type].byId[moduleId] = payload.data
        newState[payload.type].geoWip = {}
        newState[payload.type].cudLoading = false
        newState[payload.type].cudSuccess = true
      })

    case 'INIT_MODULE_INSERT': {
      const { type } = payload
      return produce(state, (newState) => {
        newState.current = undefined
        newState.wip = { type }
      })
    }

    case 'MODULE_INSERT_STARTED':
      return produce(state, (newState) => {
        if (payload) {
          newState[payload.moduleType].geoWip = payload
          newState.wip = { ...newState.wip, geometry: payload.geoJson.geometry }
        }
      })

    case 'INIT_MODULE_UPDATE': {
      const { id, type } = payload
      return produce(state, (newState) => {
        newState.wip = { id }
        newState.current = state[type].byId[id]
      })
    }

    case 'MODULE_INSERT_STOPPED':
      return produce(state, (newState) => {
        if (payload) {
          newState[payload.moduleType].geoWip = {}
          newState.wip = {}
        }
      })

    case 'SET_GEO_MODULE_PROPERTIES':
      return produce(state, (newState) => {
        newState.current.properties = payload.properties
      })

    default:
      return state
  }
}
