import { CustomField } from 'API'
import React, { useMemo } from 'react'
import GridLayout, { Layout } from 'react-grid-layout'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { v4 } from 'uuid'
import ProfileCustomField from './ProfileCustomField'
import _ from 'lodash'
import { setProfilesProps } from 'Store/actions/profiles-action'
import { useDispatch, useSelector } from 'react-redux'
import withWindowDimensions from 'Utils/hocs/withWindowDimensions'
import { RootStore } from 'Store/initialStore'
import handleLayoutChange from './utils/handleLayoutChange'

const ProfileCustomGrids = (props: any) => {
  const dispatch = useDispatch()

  const customFields = useObjSelector(
    (state) =>
      state['profiles'].wip?.['customFields'] ||
      state['profiles'].current?.['customFields'] ||
      ([
        {
          id: v4(),
          label: '',
          type: '',
        },
      ] as unknown as CustomField[])
  )

  const sectionState = useSelector((state: RootStore) => state.profiles.form.sectionState)
  //const inputFocus = useSelector((state: RootStore) => state.profiles.form.inputFocus)

  const customFieldsById = useMemo(() => {
    return _.keyBy(customFields, 'id')
  }, [customFields])

  const layout = useMemo(() => {
    return customFields.reduce((acc: Layout[], field, index) => {
      if (!field.sectionId || sectionState[field.sectionId]?.isExpanded) {
        const lastY = acc.length > 0 ? acc[acc.length - 1].y : 0
        const lastH = acc.length > 0 ? acc[acc.length - 1].h : 0

        acc.push({
          i: `${index.toString()}#${field.id}`,
          x: 0,
          y: index === 0 ? 0 : lastY + lastH,
          w: 1,
          h: ['list', 'multiple'].includes(field.type) ? 8 : field.type === 'section' ? 3 : 5,
          // Seções não podem ser trocadas de lugar enquanto qualquer uma das seções estiverem expandidas
          isDraggable:
            // !inputFocus &&
            field.type !== 'section' || !Object.values(sectionState).some(({ isExpanded }) => isExpanded),
        })
      }
      return acc
    }, [])
  }, [customFields, sectionState])

  const handleDrag = (newLayout: GridLayout.Layout[]) => {
    const newCustomFields = handleLayoutChange({
      newLayout: _.sortBy(newLayout, ['y']),
      customFields,
      customFieldsById,
      sectionState,
    })
    if (newCustomFields?.length > 1) {
      dispatch(setProfilesProps([['wip.customFields', newCustomFields]]))
    }
  }

  return (
    <GridLayout
      className="layout"
      layout={layout}
      cols={1}
      rowHeight={15}
      width={0.925 * props.windowWidth}
      isResizable={false}
      onDragStop={handleDrag}
    >
      {layout.map((item) => {
        const iSplit = item.i.split('#')
        const cfIndex = parseInt(iSplit[0])
        return (
          <div
            key={item.i}
            style={{
              zIndex: 1000 - item.y,
              cursor: 'grab',
            }}
          >
            <ProfileCustomField index={cfIndex} />
          </div>
        )
      })}
    </GridLayout>
  )
}
export default withWindowDimensions(ProfileCustomGrids)
