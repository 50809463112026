import React from 'react'
import { Icon } from 'semantic-ui-react'

export const ModalCloseIcon = ({ action, top = '0.0535rem', right = '0rem', color = 'rgba(0,0,0,.87)' }) => {
  return (
    <Icon
      name="close"
      onClick={() => typeof action === 'function' && action()}
      style={{ top: top, right: right, color: color }}
    />
  )
}

export default ModalCloseIcon
