import VERSION from './version'
import { urlServer } from './url'

export const DNT =
  typeof window !== 'undefined' &&
  Boolean(
    window.doNotTrack === '1' || window.navigator?.doNotTrack === '1' || window.navigator?.msDoNotTrack === '1' /*  ||
      process.env.NODE_ENV === 'development' */
  )

let sentryInitialized = false

function getSentry() {
  return import(/* webpackChunkName: 'sentry' */ '@sentry/browser')
}
export const initializeSentry = async (dsn) => {
  if (!DNT) {
    sentryInitialized = true
    const Sentry = await getSentry()
    //console.log('Versão: ' + process.env.REACT_APP_RELEASE_VERSION)
    return Sentry.init({
      dsn,
      release: process.env.REACT_APP_RELEASE_VERSION || VERSION,
      environment: urlServer(),
    })
  }

  return Promise.resolve()
}

export const logToSentry = async (error, errorInfo) => {
  //Manda o contexto do erro junto com o erro
  if (sentryInitialized) {
    const Sentry = await getSentry()
    Sentry.withScope((scope) => {
      scope.setExtra('errorInfo', errorInfo)
      Sentry.captureException(error)
    })
  }
}

export const logError = async (err) => {
  if (sentryInitialized) {
    const Sentry = await getSentry()
    Sentry.captureException(err)
  }
  if (window.console && console.error) console.error(err)
}
