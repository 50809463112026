import React from 'react'
import styled from 'styled-components'

interface ClickableLinkProps {
  title: string
  onClick: () => void
}

const StyledLink = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #4b72b2;
  }
`

const ClickableLink: React.FC<ClickableLinkProps> = ({ title, onClick, children }) => {
  return (
    <StyledLink title={title} onClick={onClick}>
      {children}
    </StyledLink>
  )
}

export default ClickableLink
