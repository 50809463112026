function convertTSRelativeTime(input: string): string {
  // Regex para extrair a unidade de tempo e o valor
  const regex = /ago\((\d+)([mdh])\)/
  const match = input.match(regex)

  if (match) {
    const value = parseInt(match[1])
    const unit = match[2]

    // Dicionário para mapear as unidades de entrada para saídas em português
    const timeUnits = {
      m: 'minuto',
      h: 'hora',
      d: 'dia',
    }

    const firstWord = {
      m: 'Último',
      h: 'Última',
      d: 'Último',
    }

    let pluralizedUnit = timeUnits[unit]
    let pluralizedWord = firstWord[unit]

    // Pluralização, se necessário
    if (value > 1) {
      pluralizedUnit = timeUnits[unit] + 's'
      pluralizedWord = firstWord[unit] + 's'
    }

    // Construção da frase
    return value === 1 ? `${pluralizedWord} ${pluralizedUnit}` : `${pluralizedWord} ${value} ${pluralizedUnit}`
  }

  throw new Error('Formato de entrada inválido.')
}

export default convertTSRelativeTime
