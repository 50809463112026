import React, { useCallback } from 'react'
//import { ReactComponent as Logo } from 'Resources/Icons/mini-logo.svg'
import { ReactComponent as LogoEmpresa } from 'Resources/Icons/logo-empresa.svg'
import { useDispatch, useSelector } from 'react-redux'
import { toggle } from 'Store/actions/toggle-action'
import styled from 'styled-components'
import { Transition, Icon, Menu, Button } from 'semantic-ui-react'
import TenantLogo from './TenantLogo'
import AvatarMenu from 'Components/Header/NavBar/Dropdowns/AvatarMenu/AvatarMenu'
import LogoPhygitall from '../LogoPhygitall'
import ModalClock from 'Utils/components/ModalClock'
import { saveLanguage, saveLanguageNow } from 'Store/actions/login-action'

const LogoAmbevSvg = styled(LogoEmpresa)`
  fill: #0b4589;
  max-height: 30px;
  max-width: 185px;
`
const LogoOurs = styled(Menu.Item)`
  position: absolute !important;
  left: 40px;
`

const NavBarTab = ({ profile, company, windowWidth }) => {
  const dispatch = useDispatch()
  const handleClick = useCallback(() => dispatch(toggle('sideBarLeft')), [dispatch])
  const lang = useSelector((state) => state.login.language)
  const rights = useSelector((state) => state.login.empresa)
  const email = useSelector((state) => state.login.email)
  const perfil = useSelector((state) => state.login.perfil)

  return (
    <Menu
      style={{
        backgroundColor: '#f3f3f3',
      }}
      secondary
    >
      <Menu.Item position="left">
        <Icon name="bars" size="big" onClick={handleClick} style={{ color: '#4b72b2' }} />
      </Menu.Item>

      <LogoOurs>
        <LogoPhygitall />
      </LogoOurs>

      {windowWidth > 900 && (
        <Menu.Item style={{ position: 'absolute', left: '49.5%', transform: 'translateX(-50%)', padding: '0' }}>
          <Transition animation={'scale'} duration={5000} transitionOnMount={true}>
            {company !== 'Ambev' ? <TenantLogo tenant={company} perfil={perfil} /> : <LogoAmbevSvg />}
          </Transition>
        </Menu.Item>
      )}

      <Menu.Item position="right">
        <ModalClock />
        <Button as="div" labelPosition="right">
          <AvatarMenu
            picture={profile.picture}
            rights={rights}
            lang={lang}
            save={saveLanguage}
            savenow={saveLanguageNow}
            email={email}
          />
        </Button>
      </Menu.Item>
    </Menu>
  )
}

export default NavBarTab
