import React, { useMemo, useEffect } from 'react'
import ReactTable from 'Utils/reactTable'
import { RootStore } from 'Store/initialStore'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useFetchData from 'Utils/hooks/useFetchData'
import { useRouteMatch, Link } from /*, Redirect */ 'react-router-dom'
import { Breadcrumb } from 'semantic-ui-react'
import { Column, GenericColumns } from 'Utils/reactTable/components/GenericColumns'
import {
  fetchReportData,
  fetchReportsCategories,
  getReportByCode,
  // setCurrentCodeReport,
  setCurrentReport,
  setReportsProp,
} from 'Store/actions/reports-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import m from 'moment'
import ReportsForm from 'Components/forms/reports/ReportsForm'
import ReportsIdForm from 'Components/forms/reports/ReportsIdForm'
import { reportsItems, saveReportsItems } from 'interfaces/reports'
import formatReportColumns from 'Utils/formatReportColumns'
import TableSpan from 'Components/SidebarMenu/TableSpan'

interface RowType {
  time: m.Moment
}

const ReportsItemContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  let match = useRouteMatch()
  const reportCode = match?.params?.code

  useFetchData({
    independentFetches: [{ fetchAction: 'assets' }, { fetchAction: 'geoModules' }],
  })

  const loading = useSelector((state: RootStore) => state.reports.loadingItem)
  const result = useSelector((state: RootStore) => state.reports.result)
  const haveCurrent = useSelector((state: RootStore) => !!state.reports.current?.endTime)
  const label = useSelector((state: RootStore) => state.reports.current?.label)
  const categoryOptions = useObjSelector((state) => state.reports.categoryOptions)
  const formType = useSelector((state: RootStore) => state.reports.formType)
  const scope = useSelector((state: RootStore) => state.reports.current?.scope ?? '')

  const hiddenColumns = useObjSelector(
    (state) => state.reports.wip?.hiddenColumns ?? state.reports.current?.hiddenColumns ?? []
  )
  const sortBy = useObjSelector((state) => {
    if (typeof state.reports.wip?.sortBy?.id === 'string') {
      return state.reports.wip?.sortBy
    }
    if (typeof state.reports.current?.sortBy?.id === 'string') {
      return state.reports.current?.sortBy
    }
    return { desc: false, id: 'time' }
  })

  const groupBy = useObjSelector((state) => state.reports.wip?.groupBy ?? state.reports.current?.groupBy ?? [])
  const fencesById = useObjSelector((state) => state.modules.fences.byId)

  useEffect(() => {
    if (!categoryOptions || categoryOptions.length === 0) dispatch(fetchReportsCategories())
  }, [categoryOptions, dispatch])

  useEffect(() => {
    if (reportCode) {
      dispatch(getReportByCode(reportCode))
    }
  }, [reportCode, dispatch])

  let resultColumns = result.reduce((acc: (string | Column)[], item) => {
    Object.keys(item || {}).forEach((key) => {
      if (!['id', 'assetId', 'time', 'subcategory', 'msgTime'].includes(key) && !acc.includes(key)) {
        acc.push(key)
      }
    })
    return acc
  }, [])

  // Verifica se a chave "time" existe no objeto e adiciona no final do array se existir
  if (result.length > 0 && 'time' in result[0]) {
    resultColumns.push({
      id: 'day',
      Header: t('day'),
      accessor: (originalRow: RowType) => m(originalRow['time']).format('ll'),
      Cell: ({ value }: { value: string }) => <TableSpan value={value} width="160px" />,
      aggregate: 'uniqueCount',
      Aggregated: ({ value }: { value: string }) => `${value} dias`,
      //disableGlobalFilter: true,
    })
    resultColumns.push({
      accessor: 'time',
      Cell: ({ value }: any) => {
        return m(value).format('DD/MM/YY HH:mm:ss')
      },
    })
  }

  if (result.length > 0 && 'msgTime' in result[0]) {
    resultColumns.splice(resultColumns.length - 1, 0, {
      Header: t('time'),
      accessor: 'msgTime',
      Cell: ({ value }: any) => {
        return m(value).format('DD/MM/YY HH:mm:ss')
      },
    })
  }

  const data = useMemo(() => {
    return result
  }, [result])

  let columns = GenericColumns({
    columns: loading
      ? [
          {
            accessor: 'Carregando dados. Aguarde...',
          },
        ]
      : formatReportColumns(scope, resultColumns, fencesById, t),
    defaults: false,
  })

  const tableLabel = (
    <Breadcrumb size={'big'}>
      <Breadcrumb.Section>
        <Link
          to={'/app/betaReports'}
          onClick={() => {
            dispatch(setReportsProp('loading', true))
            dispatch(setCurrentReport({}))
          }}
        >
          {t('reports')}
        </Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      <Breadcrumb.Section active>
        {reportCode === 'new' ? 'Novo relatório' : `${reportCode} - ${label ?? ''}`}
      </Breadcrumb.Section>
    </Breadcrumb>
  )

  return (
    <>
      <ReactTable
        key={`${loading}betaReports`}
        tableLabel={tableLabel}
        columns={columns}
        data={data}
        newItemClick={() => dispatch(setReportsProp(`form.open`, true))}
        showPlaceholders={loading}
        initialSortBy={sortBy}
        onSave={() => {
          dispatch(setReportsProp(`form.steps.items`, saveReportsItems))
          dispatch(setReportsProp(`formType`, 'save'))
          dispatch(setReportsProp('form.isEditing', true))
          dispatch(setReportsProp('form.open', true))
        }}
        menuButtons={['histFilter', 'save', 'export', 'columns', 'refresh']}
        setProp={(field: string, value: unknown) => dispatch(setReportsProp(field, value))}
        onFilterClick={() => {
          dispatch(setReportsProp(`form.steps.items`, reportsItems))
          dispatch(setReportsProp('formType', 'filter'))
          dispatch(setReportsProp('form.steps.current', 1))
          dispatch(setReportsProp('form.open', true))
          dispatch(setReportsProp('form.isEditing', true))
        }}
        hiddenColumns={hiddenColumns}
        setHiddenColumnsProp={(columns: unknown) => {
          dispatch(setReportsProp(`wip.hiddenColumns`, columns))
        }}
        setSortByProp={(sortBy: unknown) => {
          !loading && dispatch(setReportsProp(`wip.sortBy`, sortBy))
        }}
        setGroupByProp={(groupBy: unknown) => {
          !loading && dispatch(setReportsProp(`wip.groupBy`, groupBy))
        }}
        currentGroup={groupBy}
        //exportFileName={`${reportCode}`}
        fetchAction={haveCurrent ? () => dispatch(fetchReportData()) : null}
      />
      {formType === 'filter' && <ReportsForm />}
      {formType === 'save' && <ReportsIdForm />}
    </>
  )
}

export default ReportsItemContent
