import initialStore from 'Store/initialStore'
import produce from 'immer'
import { keyBy, union, orderBy } from 'lodash'
import parseChangeLog from 'Utils/parseChangeLog'
import { setStoreProps } from './reducerUtils'

export default function ChangeLogsReducer(state = initialStore.changeLogs, { type, payload, id }) {
  switch (type) {
    case 'FETCH_CHANGE_LOGS_START':
      return { ...state, loading: true }
    case 'FETCH_CHANGE_LOGS_SUCCESS':
      return produce(state, (newState) => {
        const { items = [], nextToken } = payload || {}
        if (!items.length) return { ...state, loading: false }

        const parsedItems = items.map(parseChangeLog)
        const itemIds = parsedItems.map(({ id }) => id)

        newState.allIds = union(state.allIds, itemIds)

        newState.byId = {
          ...state.byId,
          ...keyBy(parsedItems, 'id'),
        }

        newState.allIds = orderBy(newState.allIds, (id) => newState.byId[id].updatedAt, 'desc')

        newState.current = newState.byId[newState.allIds[0]]
        newState.loading = Boolean(nextToken)
      })

    case 'FETCH_CHANGE_LOGS_FAILURE':
      return { ...state, loading: false }

    case 'SET_CHANGE_LOGS_PROPS':
      return setStoreProps(state, payload)

    case 'SET_CURRENT_CHANGE_LOG':
      return produce(state, (newState) => {
        newState.current = payload ?? newState.byId[id] ?? {}
      })

    case 'CLEAR_CHANGE_LOGS':
      return initialStore.changeLogs

    default:
      return state
  }
}
