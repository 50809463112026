import * as z from 'zod'

const filterStepOneSchema = z.object({
  label: z.string().min(1, 'form.error.min').max(60, 'form.error.max').optional().nullable(),
  description: z.string().optional().nullable(),
  scope: z.string().min(1, 'form.error.min'),
  type: z.string().min(1, 'form.error.min'),
})

export type filterStepOne = z.infer<typeof filterStepOneSchema>

export default filterStepOneSchema
