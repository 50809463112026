import { z } from 'zod'

export const filterSchema = z.object({
  field: z.string().optional().nullable(),
  values: z.array(z.string()).optional().nullable(),
})

export const filtersSchema = z.array(filterSchema).optional().nullable()

export type FiltersType = z.infer<typeof filtersSchema>
