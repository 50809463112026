import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { setFalse, setTrue } from 'Store/actions/toggle-action'
import m from 'moment'

export async function exportPDF(name, dispatch) {
  try {
    dispatch(setTrue('loadingScreen'))
    const querySelector = document.querySelector('.table')
    let canvas = await html2canvas(querySelector, {
      logging: true,
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
    })
    let imgData = canvas.toDataURL('image/png')

    let imgWidth = 210
    let pageHeight = 295
    let imgHeight = (canvas.height * imgWidth) / canvas.width
    let heightLeft = imgHeight

    let doc = new JsPDF('p', 'mm')
    let position = 0

    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
    heightLeft -= pageHeight

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight
      doc.addPage()
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
      heightLeft -= pageHeight
    }
    await doc.save(name)
    dispatch(setFalse('loadingScreen'))
  } catch (error) {
    console.log('error', error)
    dispatch(setFalse('loadingScreen'))
  }
}

export const extractAbsoluteInterval = (interval) => {
  if (interval.relativeTime) {
    const intervalPattern = /(\d+)([dh])/
    const match = interval.relativeTime.match(intervalPattern)

    if (match) {
      const value = parseInt(match[1], 10)
      const unit = match[2] // 'd' for days or 'h' for hours

      const endTime = m(interval.reportDate)
      const startTime = unit === 'd' ? m(endTime).subtract(value, 'days') : m(endTime).subtract(value, 'hours')

      return startTime.format('DD/MM/YYYY HH:mm') + ' - ' + endTime.format('DD/MM/YYYY HH:mm')
    }
  }
  const now = m()
  let calendarEndTime = m(interval.endTime)
  calendarEndTime = calendarEndTime > now ? now : calendarEndTime
  return m(interval.startTime).format('DD/MM/YYYY HH:mm') + ' - ' + calendarEndTime.format('DD/MM/YYYY HH:mm')
}
