import React, { useEffect } from 'react'
import { Image } from 'semantic-ui-react'
import { Storage } from '@aws-amplify/storage'

interface UseImageUrlProps {
  path: string
  fallbackUrl?: string
  validateObjectExistence?: boolean
  setLoading?: (value: boolean) => void
}

const FALLBACK_IMAGE_URL = 'https://react.semantic-ui.com/images/wireframe/square-image.png'

/**
 * AmpImage Component
 *
 * A React functional component that fetches and displays an image from AWS Amplify Storage.
 * It also provides a fallback image URL in case the requested image doesn't exist or if an error occurs.
 *
 * @param {Object} props - The component properties.
 * @param {string} props.path - The path to the image in AWS Amplify Storage.
 * @param {string} [props.fallbackUrl] - An optional fallback image URL to use if the specified image cannot be fetched.
 * @param {boolean} [props.validateObjectExistence] - Optional parameter to check if the object exists in storage.
 * @param {function} [props.setLoading] - Optional callback to set loading state, to indicate when the image is being fetched.
 *
 * @returns {JSX.Element} The rendered image component.
 *
 * @example
 * <AmpImage
 *   path="public/my-image.jpg"
 *   fallbackUrl="https://example.com/fallback.jpg"
 *   validateObjectExistence={true}
 *   setLoading={(loading) => log(loading)}
 * />
 */
const AmpImage: React.FC<UseImageUrlProps> = ({ path, fallbackUrl, validateObjectExistence, setLoading }) => {
  const [signedURL, setImageUrl] = React.useState<string | null>(null)
  // const [loadingImage, setLoadingImage] = React.useState<boolean>(false)

  useEffect(() => {
    if (!path) {
      setImageUrl(fallbackUrl ?? FALLBACK_IMAGE_URL)
      return
    }
    const getUrl = async () => {
      try {
        setLoading && setLoading(true)
        //setLoadingImage(true)
        const url = (await Storage.get(path.replace('public/', ''), {
          validateObjectExistence,
        })) as string
        setImageUrl(url)
      } catch (err) {
        console.error(err)
        setImageUrl(fallbackUrl ?? FALLBACK_IMAGE_URL)
      } finally {
        setLoading && setLoading(false)
        //setLoadingImage(false)
      }
    }
    getUrl()
  }, [path, fallbackUrl, validateObjectExistence, setLoading])

  return <Image src={signedURL ?? FALLBACK_IMAGE_URL} style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
}

export default AmpImage
