import i18n from 'i18next'
import Detector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

const initializeI18n = () => {
  i18n
    .use(Backend)
    .use(Detector)
    .use(initReactI18next)
    .init({
      // we init with resources
      fallbackLng: 'pt',
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      // https://stackoverflow.com/questions/27015970/i18next-json-dot-in-key-or-label
      // some things in the translation files have '.' in their names
      keySeparator: '.',
    })
}

export const switchLanguage = (l) => {
  try {
    i18n.changeLanguage(l)
  } catch (e) {
    console.error('Problema na troca de idioma', e)
  }
}

export default initializeI18n
