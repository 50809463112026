import React, { useMemo } from 'react'
import { Form, Label, Icon, Segment, Dropdown, DropdownItemProps } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { setProfilesProps } from 'Store/actions/profiles-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { RootStore } from 'Store/initialStore'
import { useTranslation } from 'react-i18next'
import { CustomField } from 'API'
import { v4 } from 'uuid'
import produce from 'immer'
import ProfileCustomSection from './ProfileCustomSection'

interface Props {
  index: number
}

const ProfileCustomField = ({ index }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const id = useSelector(
    (state: RootStore) =>
      state.profiles.wip.customFields?.[index]?.id || state.profiles.current?.customFields?.[index]?.id || ''
  )
  const type = useSelector(
    (state: RootStore) =>
      state.profiles.wip.customFields?.[index]?.type ?? state.profiles.current?.customFields?.[index]?.type ?? ''
  )
  const label = useSelector(
    (state: RootStore) =>
      state.profiles.wip.customFields?.[index]?.label ?? state.profiles.current?.customFields?.[index]?.label
  )
  const options = useObjSelector(
    (state) =>
      state.profiles.wip.customFields?.[index]?.options || state.profiles.current?.customFields?.[index]?.options
  )

  const customFields: Partial<CustomField>[] = useObjSelector(
    (state) => state.profiles.wip.customFields || state.profiles.current?.customFields || []
  )

  const fieldsToBeRemoved = useObjSelector((state) => state.profiles.form.fieldsToBeRemoved)
  const toDelete = useMemo(() => fieldsToBeRemoved?.includes(id), [fieldsToBeRemoved, id])

  //Efeito de trocar o label para vermelho ao deletar
  const style = {
    display: 'block',
    color: `${toDelete ? 'white' : 'rgba(0,0,0,.87)'}`,
    fontSize: '.92857143em',
    fontWeight: '700',
    backgroundColor: `${toDelete ? '#d01919' : 'white'}`,
  }
  //Sem esse efeito
  const style2 = {
    display: 'block',
    color: `rgba(0,0,0,.87)`,
    fontSize: '.92857143em',
    fontWeight: '700',
    backgroundColor: `white`,
  }

  const handleChange = (name: string, value: unknown) => {
    const newCustomField = produce(customFields, (newValue) => {
      newValue[index] = { id: v4(), ...newValue[index], [name]: value }
    })
    dispatch(setProfilesProps([[`wip.customFields`, newCustomField]]))
  }

  return type === 'section' ? (
    <ProfileCustomSection index={index} label={label} />
  ) : (
    <Segment>
      <Form.Group widths={'equal'}>
        <Form.Field required={true}>
          <Label style={style}>
            {toDelete ? t('field_deleted') : t('field_type')}
            <label style={{ color: 'red' }}> *</label>
          </Label>
          <Dropdown
            name="type"
            placeholder={t('select_field_type')}
            selection
            fluid
            selectOnBlur={false}
            search={true}
            options={[
              {
                key: 'boolean',
                text: t('field_type_boolean'),
                value: 'boolean',
              },
              {
                key: 'date',
                text: t('field_type_date'),
                value: 'date',
              },
              {
                key: 'dateTime',
                text: t('field_type_date_time'),
                value: 'dateTime',
              },
              {
                key: 'images',
                text: t('images'),
                value: 'images',
              },
              {
                key: 'list',
                text: t('field_type_list'),
                value: 'list',
              },
              {
                key: 'multiple',
                text: t('field_type_multilist'),
                value: 'multiple',
              },
              {
                key: 'number',
                text: t('field_type_number'),
                value: 'number',
              },
              {
                key: 'text',
                text: t('field_type_text'),
                value: 'text',
              },
              {
                key: 'textArea',
                text: t('field_type_textarea'),
                value: 'textArea',
              },
            ]}
            value={type}
            onChange={(_, { name, value }) => {
              handleChange(name, value)
            }}
            upward={false}
          />
        </Form.Field>
        <Form.Field required={true}>
          <Label style={style2}>
            {fieldsToBeRemoved?.includes(id) ? t('field_deleted') : t('field_name')}
            <span style={{ color: 'red' }}> *</span>
          </Label>
          <Form.Input
            error={label === ''}
            name="label"
            placeholder={'Digite o nome'}
            fluid
            value={label ?? ''}
            minLength={1}
            onChange={(_, { name, value }) => {
              handleChange(name, value)
            }}
            // Lógica para desativar recurso de ordenação quando o input estiver sendo usado
            // onFocus={() => {
            //   dispatch(setProfilesProps([[`form.inputFocus`, true]]))
            // }}
            // onBlur={() => {
            //   dispatch(setProfilesProps([[`form.inputFocus`, false]]))
            // }}
          />
        </Form.Field>
        {toDelete ? (
          <Icon
            onClick={() =>
              dispatch(setProfilesProps([['form.fieldsToBeRemoved', fieldsToBeRemoved?.filter((f) => f !== id)]]))
            }
            circular
            // inverted
            // color="blue"
            name="undo"
            style={{ cursor: 'pointer', position: 'absolute', right: '5px', top: '5px' }}
            title={t('types_delete_undo')}
          />
        ) : (
          <Icon
            onClick={() =>
              dispatch(
                setProfilesProps([
                  ['form.fieldsToBeRemoved', [...fieldsToBeRemoved, id]],
                  ['wip.customFields', customFields],
                ])
              )
            }
            circular
            // inverted
            // color="red"
            name="trash alternate"
            style={{ cursor: 'pointer', position: 'absolute', right: '5px', top: '5px' }}
            title={t('types_delete_perm')}
          />
        )}
      </Form.Group>
      {['list', 'multiple'].includes(type) && (
        <Form.Group widths="equal">
          <Form.Field required={true}>
            <Label style={style2}>
              {t('field_type_list')}
              <label style={{ color: 'red' }}> *</label>
            </Label>
            <Dropdown
              error={options?.length === 0}
              name="options"
              options={
                (options?.map((opt) => ({ key: opt, text: opt, value: opt })) || []) as unknown as DropdownItemProps[]
              }
              placeholder={t('types_type_here')}
              search
              selection
              fluid
              multiple
              allowAdditions
              additionLabel={t('toAdd')}
              value={options as (string | number | boolean)[] | undefined}
              onChange={(_, { name, value }) => {
                handleChange(name, value)
              }}
              noResultsMessage="Digite novos itens"
              upward={false}
            />
          </Form.Field>
        </Form.Group>
      )}
    </Segment>
  )
}

export default ProfileCustomField
