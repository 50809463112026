import moment from 'moment'
import React, { Fragment } from 'react'
import { Button, Header, Loader, Message, Segment, TextArea } from 'semantic-ui-react'

export interface IData {
  name: string
  email: string
  hash: string
  beginning: string
  expiration: string
  tenantCode: string
  title?: string
  approvedTitle?: string
  approvedMessage: string
  deniedMessage: string
  form?: string
}

interface IContent {
  handleClick: (hasApproved: boolean, justification: string | null) => void
  success: boolean | undefined
  error: boolean
  isLoadingApprove: boolean
  isLoadingReject: boolean
  data: IData | undefined
  approved?: boolean
  errorMsg: string
}

export const Content = ({
  handleClick,
  success,
  data,
  error,
  isLoadingApprove,
  isLoadingReject,
  approved,
  errorMsg,
}: IContent) => {
  const [rejected, setRejected] = React.useState(false)
  const [justification, setJustification] = React.useState('')
  if (error) return <ErrorBox />
  if (!data) return <Loader active />
  if (success)
    return (
      <SuccessBox
        approvedMessage={data.approvedMessage}
        titleMessage={data.approvedTitle}
        deniedMessage={data.deniedMessage}
        hasApproved={approved}
      />
    )
  if (rejected) {
    return (
      <Segment>
        <Header as="h3" color="red" content="Justificativa" />
        <TextArea
          style={{ width: '100%', height: 100 }}
          placeholder="Justifique o motivo da recusa (opcional)"
          onChange={(_, { value }) => {
            if (value) {
              setJustification(value as string)
            }
          }}
        />
        <Button
          onClick={() => {
            setRejected(false)
          }}
        >
          Cancelar
        </Button>
        <Button
          color="red"
          onClick={handleClick.bind(null, false, justification)}
          loading={isLoadingReject}
          disabled={isLoadingReject}
        >
          Rejeitar
        </Button>
      </Segment>
    )
  }
  return (
    <Fragment>
      <Header as="h2" color="blue" textAlign="center" content={data.title ?? 'Autorização de acesso'} />
      <Segment>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Nome do Solicitante</Header>
          <span>{data.name}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Email do Solicitante</Header>
          <span>{data.email}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Data de início</Header>
          <span>{moment(Number(data.beginning)).format('LL')}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Data de fim</Header>
          <span>{moment(Number(data.expiration)).format('LL')}</span>
        </div>
        <Button
          basic
          color="green"
          onClick={handleClick.bind(null, true, null)}
          loading={isLoadingApprove}
          disabled={isLoadingApprove}
        >
          Aprovar
        </Button>
        <Button
          basic
          color="red"
          onClick={() => {
            setRejected(true)
          }}
          loading={isLoadingReject}
          disabled={isLoadingReject}
        >
          Rejeitar
        </Button>
      </Segment>
      {errorMsg.length > 0 && <Message negative header="Erro" content={errorMsg} />}
    </Fragment>
  )
}

const SuccessBox = ({
  approvedMessage,
  deniedMessage,
  hasApproved,
  titleMessage,
}: {
  approvedMessage: string
  deniedMessage: string
  titleMessage?: string
  hasApproved?: boolean
}) => {
  if (hasApproved) {
    return <Message positive header={titleMessage ?? 'Acesso Autorizado'} content={approvedMessage} />
  } else {
    return <Message warning header="Acesso Recusado" content={deniedMessage} />
  }
}

const ErrorBox = () => (
  <Message
    negative
    header="Link inválido ou já utilizado"
    content="Verifique se a aprovação/rejeição já foi realizada, e se o link está correto."
  />
)
