import { ChangeLog as APIChangeLog } from '../API'

export interface ChangeLog extends Omit<APIChangeLog, 'log'> {
  log: Record<string, any>
}

export interface ChangeLogs {
  allIds: string[]
  byId: Record<string, ChangeLog>
  current?: ChangeLog
  loading: boolean
  entityId?: string
}

export const changeLogs: ChangeLogs = {
  allIds: [],
  byId: {},
  current: undefined,
  loading: false,
}
