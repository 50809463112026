import { useObjSelector } from 'Utils/hooks/useObjSelector'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Table, TableHeaderCell } from 'semantic-ui-react'

const HeaderInfoComponent: React.FC = () => {
  const dispatch = useDispatch()
  const headerInfo = useObjSelector((state) => state.tables.byId?.reports?.headerInfo)

  useEffect(() => {
    return () => {
      dispatch({
        type: 'FETCH_REPORT_CLEAR_STATE',
      })
    }
  }, [dispatch])

  if (!Array.isArray(headerInfo) || headerInfo.length === 0) {
    return null
  }

  return (
    <Table.Row textAlign="left">
      <TableHeaderCell colSpan="16">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {headerInfo.map(([field, value], index) => (
            <div
              key={index}
              style={{
                flex: 1,
                textAlign: index === 0 ? 'left' : index === headerInfo.length - 1 ? 'right' : 'center',
                fontStyle: 'italic',
              }}
            >
              {`${field}: ${value}`}
            </div>
          ))}
        </div>
      </TableHeaderCell>
    </Table.Row>
  )
}

export default HeaderInfoComponent
