import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { fetchMeasuresHistory } from 'Store/actions/tables-action'
import { useTranslation } from 'react-i18next'
import TableSpan from 'Components/SidebarMenu/TableSpan'
import { RootStore } from 'Store/initialStore'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { setTableProp } from 'Store/actions/tables-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import LineChartView from '../LineChartView'

const MeasuresHistContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const tableName = useSelector((state: RootStore) => state.tables.byId.measuresHist.label)
  const loading = useSelector((state: RootStore) => state.tables.byId.measuresHist.loading)
  const measuresHistData = useObjSelector((state: RootStore) => state.tables.byId.measuresHist.data)
  const selectedMeasures = useSelector((state: RootStore) => state.tables.byId.measuresHist.selectedMeasures)
  const error = useSelector((state: RootStore) => state.tables.error)
  const currentTableView = useSelector((state: RootStore) => state.tables.byId[tableName]?.view)

  const [currentGroup, setCurrentGroup] = useState([])

  const fetchAction = () => {
    dispatch(fetchMeasuresHistory())
  }

  const columns = useMemo(
    () => [
      {
        Header: t('assetName'),
        accessor: 'assetName',
        aggregate: 'uniqueCount',
        Cell: ({ value }: { value: string }) => <TableSpan value={value} width="400px" capitalize={false} />,
        Aggregated: ({ value }: { value: string | Function }) => `${value} nomes`,
      },

      {
        Header: t('category'),
        accessor: 'category',
        Cell: ({ value }: { value: string }) => t(value),
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: { value: string | Function }) => `${value} Categorias`,
      },
      ...selectedMeasures.map((each: any) => ({
        // I don't know what 'each' should be
        Header: t(each),
        accessor: each,
        Cell: ({ value }: { value: string }) => {
          return typeof value === 'string' ? t(value) : value ?? ''
        },
        aggregate: each === 'Caixas_levantadas' ? 'max' : 'uniqueCount',
        Aggregated: ({ value }: { value: string | Function }) =>
          `${value} ${each === 'Caixas_levantadas' ? '(total)' : 'medidas'}`,
      })),
      {
        id: 'day',
        Header: t('day'),
        accessor: (originalRow: RowType) => m(originalRow['time']).format('ll'),
        Cell: ({ value }: { value: string }) => <TableSpan value={value} width="160px" />,
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: { value: string }) => `${value} dias`,
        disableGlobalFilter: true,
      },
      {
        Header: t('time'),
        accessor: 'time',
        Cell: ({ value }: { value: string }) => (
          <TableSpan value={m(value).format('HH:mm:ss')} capitalize width="160px" />
        ),
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: { value: string | Function }) => `${value} horários`,
        disableGlobalFilter: true,
      },
    ],
    [t, selectedMeasures]
  )

  return (
    <>
      {error === 'ResponseSizeTooLarge' && (
        <ModalTemplate
          header={t(error)}
          open
          cancelContent="Fechar"
          saveButton={false}
          contentPXMargin={30}
          onClose={() => dispatch(setTableProp(`error`, null))}
          onCancel={() => dispatch(setTableProp(`error`, null))}
        >
          <span>Tente um filtro menor por vez. Em breve será possível realizar consultas maiores.</span>
        </ModalTemplate>
      )}
      {!currentTableView || currentTableView === 'table' ? (
        <ReactTable
          table={tableName}
          tableName={tableName}
          initialSortBy={{ desc: true, id: 'time' }}
          columns={columns}
          data={measuresHistData}
          fetchAction={fetchAction}
          currentGroup={currentGroup}
          setCurrentGroup={setCurrentGroup}
          showPlaceholders={loading}
          menuButtons={[
            'histFilter',
            'refresh',
            //'saveMeasuresHist',
            'viewOptions',
            'export',
          ]}
        />
      ) : (
        <LineChartView tableName={tableName} fetchAction={fetchAction} />
      )}
    </>
  )
}

interface RowType {
  time: m.Moment
}

export default MeasuresHistContent
