import m from 'moment'
import { extractAbsoluteInterval } from './exportPDF'

type FormatPDFFunction = ({
  table,
  reportData,
  logoUrl,
  reports,
  empresa,
  uuid,
  t,
}: {
  table: string
  reportData: any
  logoUrl: string
  reports: any
  empresa: string
  preferencesColumns: any
  uuid: string
  t: any
}) => void

const formatPDF: FormatPDFFunction = ({
  table,
  reportData,
  logoUrl,
  reports,
  empresa,
  preferencesColumns,
  uuid,
  t,
}) => {
  let filterReportData = reportData.map((row: any) => {
    return row.map((cell: any) => {
      return cell?.displayValue ?? cell
    })
  })

  if (preferencesColumns) {
    const hiddenColumnsIndex = preferencesColumns.map((columnStringIndex: string) =>
      parseInt(columnStringIndex.replace(/[^\d]/g, ''), 10)
    )

    filterReportData = filterReportData.map((row: any) => {
      return row.filter((_: unknown, columnIndex: number) => {
        return !hiddenColumnsIndex.includes(columnIndex)
      })
    })
  }

  const reportColumns = filterReportData[0]

  if (reportColumns.length > 10) {
    return false
  }

  localStorage.setItem('uuid', JSON.stringify(uuid))
  localStorage.setItem('reportData', JSON.stringify(filterReportData))
  localStorage.setItem('reportLogo', logoUrl)

  if (empresa === 'Tiplam') {
    localStorage.setItem('reportTitle', 'Proteção à Vida')
    localStorage.setItem('reportSubTitle', table ? `Relatório ${t(reports.reportType + '_label')}` : '')
  } else {
    localStorage.setItem('reportTitle', 'Relatório')
    localStorage.setItem('reportSubTitle', table ? `${t(reports.reportType + '_label')}` : '')
  }

  const reportInterval = extractAbsoluteInterval({ ...reports.fetchInterval, reportDate: new Date().toISOString() })

  const reportDate = m().format('DD/MM/YYYY HH:mm')
  localStorage.setItem('reportDate', reportDate)
  localStorage.setItem('reportInterval', reportInterval)

  return true
}

export default formatPDF
