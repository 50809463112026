import React, { useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Icon, Card, Statistic, Menu } from 'semantic-ui-react'
import { SvgIcon } from 'Utils/components/SvgIcon.jsx'
import { saveLoginMapLayer } from 'Store/actions/login-action'

export const CardAssetsTotal = ({ fenceName }) => {
  // cards preferences
  const pref = useSelector((state) => state.login.preferences.mapLayers || [], shallowEqual)
  const [details, setDetails] = useState(pref?.includes('assetsCountDetails'))
  const [empties, setEmpties] = useState(pref?.includes('assetsCountEmpties'))

  const assetsIds = useSelector((state) => state.assets?.allActiveAssetsIds || [], shallowEqual)
  const assets = useSelector((state) => state.assets.byId || {}, shallowEqual)
  const typesIds = useSelector((state) => state.profiles.allIds || [], shallowEqual)
  const types = useSelector((state) => state.profiles.byId || {})
  const countedTypes = (typeId) => assetsIds.filter((a) => parseInt(assets[a]?.info?.type) === parseInt(typeId)).length
  const noTypes = () => assetsIds.filter((a) => !assets[a]?.info?.type).length
  const xNoType = noTypes()
  const typesObj = typesIds
    .filter((tp) => types[tp].type === 'assets')
    .map((t) => ({
      ...types[t],
      x: countedTypes(t),
      smallTitle:
        types[t]?.properties?.uniqueId?.slice(0, 5) || types[t]?.code?.slice(0, 5) || types[t].name?.slice(0, 5),
    }))
    .filter((ft) => empties || ft.x > 0)
    .sort((a, b) =>
      a.smallTitle && b.smallTitle ? a.smallTitle.toLowerCase().localeCompare(b.smallTitle.toLowerCase()) : 1
    )

  /*Margin calculations*/
  const histType = useSelector((state) => state.history.type)
  const total = typesObj.reduce((tot, { x }) => tot + x, xNoType)
  return (
    <Card
      color="blue"
      style={{
        width: `${(typesObj.length + (xNoType !== 0 ? 1 : 0)) * 70 + 70}px`,
        minWidth: '250px',
        marginTop: histType ? '112px' : '62px',
        zIndex: '900',
        backgroundColor: 'rgb(255,255,255,0.6)',
      }}
    >
      <Card.Content>
        <Statistic size="mini" floated="right" color={'black'}>
          <Statistic.Value>
            <Icon name={'users'} /> {total?.toString()?.padStart(4, '0')}
          </Statistic.Value>
        </Statistic>

        <Card.Header>
          {details && (
            <Icon
              title={
                empties ? 'Clique para ocultar os que estão sem valor (0)' : 'Clique para visualizar todos os perfis'
              }
              name={empties ? 'eye' : 'eye slash'}
              onClick={() => {
                saveLoginMapLayer('assetsCountEmpties', !empties)
                setEmpties(!empties)
              }}
              style={{ cursor: 'pointer' }}
            />
          )}
          Total de Ativos{' '}
          {typesObj?.length > 0 && (
            <Icon
              title={details ? 'Clique para ocultar detalhes' : 'Clique para mostrar mais detalhes'}
              onClick={() => {
                saveLoginMapLayer('assetsCountDetails', !details)
                setDetails(!details)
              }}
              style={{ cursor: 'pointer' }}
              name={details ? 'caret down' : 'caret right'}
              color="black"
            />
          )}
        </Card.Header>
        {details && typesObj?.length > 0 && (
          <Card.Description>
            <Menu
              widths={typesObj.length + (xNoType ? 1 : 0)}
              compact
              icon="labeled"
              size="mini"
              style={{ backgroundColor: 'rgb(255,255,255,0.6)' }}
            >
              {typesObj.map((t) => (
                <Menu.Item key={t.name} name={t.name} title={t.name}>
                  <div style={{ height: '30.5px', width: '30.5px' }}>
                    {<SvgIcon title={t.name} color={t.color || 'gray'} name={t.icon || 'FaCircle'} compact />}
                  </div>

                  {t.smallTitle + ': ' + t.x.toString().padStart(3, '0')}
                </Menu.Item>
              ))}
              {xNoType !== 0 && (
                <Menu.Item key="semtipodefinido" name="close">
                  <div style={{ height: '30.5px', width: '30.5px' }}>
                    <SvgIcon title={'Sem tipo definido'} color={'gray'} name={'FaCircle'} compact />
                  </div>
                  {'Indef: ' + xNoType}
                </Menu.Item>
              )}
            </Menu>
          </Card.Description>
        )}
      </Card.Content>
    </Card>
  )
}
export default CardAssetsTotal
