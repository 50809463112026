import Dropdown from 'rc-dropdown'
import Menu, { Item as MenuItem } from 'rc-menu'
import 'rc-dropdown/assets/index.css'
import 'rc-menu/assets/index.css'
import React, { Component } from 'react'
import { setTrue, setFalse } from 'Store/actions/toggle-action'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

class DropdownHist extends Component {
  state = {
    visible: false,
  }

  onVisibleChange = (visible) => {
    this.setState({
      visible,
    })
  }

  selected = []

  saveSelected = ({ selectedKeys }) => {
    this.selected = selectedKeys
    switch (selectedKeys[0]) {
      case '0': {
        this.props.setFalse('showHeatMap')
        this.props.setTrue('showDividerAntpath')
        this.props.setFalse('showDiscreteAntpath')
        break
      }
      case '1': {
        this.props.setFalse('showHeatMap')
        this.props.setFalse('showDividerAntpath')
        this.props.setTrue('showDiscreteAntpath')
        break
      }
      default:
        break
    }
  }

  render() {
    let menu

    menu = (
      <Menu
        style={{
          width: '170px',
          height: Object.values(this.props.options).length > 13 ? this.props.height : 'auto',
          overflow: this.props.overflow,
        }}
        multiple={false}
        onSelect={this.saveSelected}
        onDeselect={this.removeSelected}
        defaultSelectedKeys={['0']}
      >
        {Object.values(this.props.options).map((value, i) =>
          /*gambiarra*/
          value === 'Caminho Interligado' ? (
            <MenuItem disabled key={i}>
              {value}
            </MenuItem>
          ) : (
            <MenuItem key={i}>{value}</MenuItem>
          )
        )}
      </Menu>
    )

    return (
      <Dropdown
        trigger={['hover']}
        onVisibleChange={this.onVisibleChange}
        visible={this.state.visible}
        closeOnSelect={false}
        overlay={menu}
        animation="slide-up"
      >
        <Icon size="small" name={'eye'} style={{ color: '#4b72b2' }} />
      </Dropdown>
    )
  }
}

const mapDispatchToProps = {
  setTrue,
  setFalse,
}

export default connect(null, mapDispatchToProps)(DropdownHist)
