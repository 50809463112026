import React, { useEffect } from 'react'
import { Icon, Placeholder } from 'semantic-ui-react'
import { VideoPlayerState } from './useVideoPlayer'
import './VideoPlayer.css'
import { Storage } from 'aws-amplify'

interface VideoPlayerProps {
  videoUrlKey: string | null
  skipToTimestamp?: number
  width?: number
  height?: number
  videoRef?: React.RefObject<HTMLVideoElement>
  togglePlay?: () => void
  toggleMute?: () => void
  playerState: VideoPlayerState
  handleVideoProgress?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleVideoSpeed?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  handleOnTimeUpdate?: (e: React.SyntheticEvent<HTMLVideoElement, Event>) => void
}

const convertCurrentTimeToFriendlyTime = (currentTime: number) => {
  const minutes = Math.floor(currentTime / 60)
  const seconds = Math.floor(currentTime - minutes * 60)
  return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

export const VideoPlayer = ({
  videoUrlKey,
  videoRef,
  togglePlay,
  playerState,
  handleVideoSpeed,
  handleVideoProgress,
  toggleMute,
  handleOnTimeUpdate,
}: VideoPlayerProps) => {
  const [signedURL, setVideoUrl] = React.useState<string | null>(null)
  useEffect(() => {
    if (!videoUrlKey) return
    const getUrl = async () => {
      const url = (await Storage.get(videoUrlKey, {
        expires: 60 * 60 * 12,
      })) as string
      setVideoUrl(url)
    }
    getUrl()
  }, [videoUrlKey])

  if (!videoUrlKey) return null //<Image src="https://react.semantic-ui.com/images/wireframe/image.png" size="medium" />
  if (!signedURL) {
    return (
      <Placeholder>
        <Placeholder.Image square />
      </Placeholder>
    )
  }

  return (
    <div className="video-wrapper">
      <video ref={videoRef} onTimeUpdate={handleOnTimeUpdate} src={signedURL} />
      <div className="video-controls">
        <div className="video-actions">
          <button onClick={togglePlay}>
            {!playerState.isPlaying ? <Icon name="play" size="small" /> : <Icon name="pause" size="small" />}
          </button>
          <span className="current-time">{convertCurrentTimeToFriendlyTime(playerState.currentVideoTime)}</span>
        </div>

        <input type="range" min="0" max="100" value={playerState.progress} onChange={handleVideoProgress} />
        <select className="velocity" value={playerState.speed} onChange={handleVideoSpeed}>
          <option value="0.50">0.50x</option>
          <option value="1">1x</option>
          <option value="1.25">1.25x</option>
          <option value="2">2x</option>
        </select>
        <button className="mute-btn" onClick={toggleMute}>
          {!playerState.isMuted ? <Icon name="mute" size="small" /> : <Icon name="unmute" size="small" />}
        </button>
      </div>
    </div>
  )
}
