import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { Form } from 'semantic-ui-react'
import React, { useState, useEffect, useCallback } from 'react'
//import schema from 'graphql/schema.json'
import { useDispatch } from 'react-redux'
import { GenericFormProps, Validation } from './types/GenericFormProps'
import { createNews, fetchNews, updateNews } from 'Apis/news'
import useAsync from 'Utils/hooks/useAsync'
import { notifyError } from 'Utils/components/SystemToasts'

type Validations<T extends {}> = Partial<Record<keyof T, Validation>>
type ErrorRecord<T> = Partial<Record<keyof T, string>>

interface NewsItems {
  id: string
  description: string
  title: string
  url?: string
}

const validations: Validations<NewsItems> = {
  title: {
    required: {
      value: true,
      message: 'A mensagem precisa ter um título',
    },
    custom: {
      isValid: (value) => value.length < 50,
      message: 'Título só pode conter até 50 caracteres',
    },
  },
  description: {
    required: {
      value: true,
      message: 'Esse campo é obrigatório',
    },
  },
  url: {
    custom: {
      isValid: (v) => {
        if (!v) return true
        try {
          new URL(v)
          return true
        } catch (e) {
          return false
        }
      },
      message: 'URL inválida',
    },
  },
}

const NewsForm: React.FC<GenericFormProps> = ({ handleCancel, addOpen, confirmAction, editId, setAddOpen }) => {
  const { value, execute } = useAsync(fetchNews, false)
  const dispatch = useDispatch()
  const [fields, setFields] = useState<NewsItems>({
    id: '',
    title: '',
    description: '',
    url: '',
  })
  const [errors, setErrors] = useState<ErrorRecord<NewsItems>>({})
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (confirmAction === 'update' && value) {
      setFields({
        id: value.byId[editId]?.id ?? '',
        title: value.byId[editId]?.title ?? '',
        description: value.byId[editId]?.description ?? '',
        url: value.byId[editId]?.url ?? '',
      })
    }
  }, [confirmAction, value, editId])

  useEffect(() => {
    if (confirmAction === 'update') {
      execute()
    }
  }, [confirmAction, execute])

  useEffect(() => {
    if (success) {
      dispatch({ type: 'CLEAR_MESSAGE_STATE' })
      setAddOpen(false)
    }
  }, [dispatch, setAddOpen, success])

  const handleChange = useCallback(
    (_ev, data) => {
      setFields({ ...fields, [data.name]: data.value })
    },
    [fields]
  )

  const checkForm = (fields: NewsItems) => async () => {
    let valid = true
    const newErrors: ErrorRecord<NewsItems> = {}
    for (const key in validations) {
      const value = fields[key]
      const validation = validations[key]
      if (validation?.required?.value && !value) {
        valid = false
        newErrors[key] = validation?.required?.message
      }

      const pattern = validation?.pattern
      if (pattern?.value && !RegExp(pattern.value).test(value)) {
        valid = false
        newErrors[key] = pattern.message
      }

      const custom = validation?.custom
      if (custom?.isValid && !custom.isValid(value)) {
        valid = false
        newErrors[key] = custom.message
      }
    }

    if (!valid) {
      setErrors(newErrors)
      return
    }
    setErrors({})
    try {
      setLoading(true)
      if (confirmAction === 'update') {
        await updateNews(fields)
      } else {
        await createNews(fields)
      }
      setSuccess(true)
    } catch (e) {
      console.log(e)
      notifyError()
      setSuccess(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ModalTemplate
      onClose={handleCancel}
      open={addOpen}
      loading={loading}
      size="small"
      header={confirmAction === 'insert' ? <span>{`Adicionar novidade`}</span> : <span>{`Editar novidade`}</span>}
      onSave={checkForm(fields)}
      onCancel={handleCancel}
    >
      <Form error>
        <Form.Input
          required={true}
          name="title"
          fluid
          label="Título"
          error={errors.title}
          value={fields.title}
          onChange={handleChange}
        />
        <Form.TextArea
          value={fields.description || ''}
          error={errors.description}
          name="description"
          fluid
          label="Descrição"
          required={true}
          onChange={handleChange}
        />
        <Form.Input fluid label="URL" name="url" error={errors.url} value={fields.url} onChange={handleChange} />
      </Form>
    </ModalTemplate>
  )
}

export default NewsForm
