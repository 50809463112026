/*  
    Author: Luís Mourão
    Type: Renderless Component
	Description: To use Map Methods from leaflet Reference
    TODO: 
    - Testar , Validar e abranger para todos os métodos utilizados por Componentes React na Plataforma
*/

import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import L from 'leaflet'
import 'Libs/react-leaflet-measure/lib/leaflet-measure.css'
import { useLeaflet } from 'react-leaflet'
import MeasureTool from './MeasureTool'

export const MapMethods = ({ cb, fit, type, options, lang }) => {
  const { map } = useLeaflet()
  let refMap = useSelector((state) => state.login.refMap, shallowEqual)
  refMap = map ? map : refMap

  if (!refMap) {
    console.warn('No Leaflet Map Reference')
    return null
  }

  if (type === 'measure') {
    return <MeasureTool options={options} refMap={refMap} lang={lang} />
  }

  if (type === 'circle') {
    let circle = L.circle([fit.coords[1], fit.coords[0]], { radius: fit.radius })
    refMap.fitBounds(circle.addTo(refMap).getBounds(), { paddingTopLeft: [55, 55] })
    if (refMap.hasLayer(circle)) refMap.removeLayer(circle)
    cb()
    return null
  }

  if (type === 'heatmap') {
    refMap.fitBounds(fit, { paddingTopLeft: [60, 60] })
    cb()
    return null
  }
}

export default MapMethods
