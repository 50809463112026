import _ from 'lodash'

function calculatePercentile(values: number[], percentileRank: number): number {
  if (values.length === 0) throw new Error('Array must not be empty')
  const sortedValues = _.sortBy(values)
  const index = (percentileRank / 100) * (sortedValues.length - 1)
  const lowerIndex = Math.floor(index)
  const upperIndex = lowerIndex + 1 < sortedValues.length ? lowerIndex + 1 : lowerIndex
  const interpolation = index - lowerIndex
  return sortedValues[lowerIndex] * (1 - interpolation) + sortedValues[upperIndex] * interpolation
}

export default calculatePercentile
