import React, { useMemo } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import useFetchData from 'Utils/hooks/useFetchData'

interface Props {
  value?: string
  placeholder?: string
  onChange: Function
}

const MeetingPointDropdown = ({ value, placeholder = 'Selecione um', onChange }: Props) => {
  useFetchData({ independentFetches: [{ fetchAction: 'geoModules' }] })

  const fencesAllIds = useObjSelector((state: RootStore) => state.modules.fences.allIds)
  const fencesById = useObjSelector((state: RootStore) => state.modules.fences.byId)

  const loading = useSelector((state: RootStore) => state.modules.loading)
  const tenant = useSelector((state: RootStore) => state.login.empresa)

  const options = useMemo(
    () =>
      fencesAllIds.reduce((acc: any, fenceId: string) => {
        const fence = fencesById[fenceId]
        if (
          (tenant === 'TiplamTest' && fence?.profile === '30252') ||
          (tenant === 'Tiplam' && fence?.profile === '30270')
        ) {
          return [
            ...acc,
            {
              key: fenceId,
              value: fenceId,
              text: `${fence?.label}`,
            },
          ]
        }
        return acc
      }, []),
    [fencesAllIds, fencesById, tenant]
  )

  return (
    <Dropdown
      selection
      placeholder={loading ? 'Carregando opções, aguarde...' : placeholder}
      onChange={(e, { value }) => onChange(value)}
      value={value}
      options={options}
      noResultsMessage={
        loading ? 'Carregando opções, aguarde...' : 'Nenhum resultado encontrado no filtro selecionado.'
      }
      loading={loading}
      search
      clearable
      multiple
      fluid
    />
  )
}

export default MeetingPointDropdown
