import React from 'react'
import { setReportsProp } from 'Store/actions/reports-action'
import { useDispatch } from 'react-redux'
import TimePropsFields from '../generic/TimePropsFields'
import { Form } from 'semantic-ui-react'

function ReportsStepThreeForm() {
  const dispatch = useDispatch()

  return (
    <Form>
      <TimePropsFields setProp={(field: string, value: unknown) => dispatch(setReportsProp(field, value))} />
    </Form>
  )
}

export default ReportsStepThreeForm
