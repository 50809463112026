import React, { useCallback, useEffect } from 'react'
import { Form, SemanticICONS } from 'semantic-ui-react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import Steps from 'Utils/components/Steps'
import FormContainer from './FormContainer'
import { GenericFormProps } from '../types/GenericFormProps'
import { stepsSchema } from './schemas'
import { createDeviceType, createSimulation, getSimulation } from 'Apis/simulation'
import axios from 'axios'
import { URL_BASE } from 'Apis/rest'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Span = styled.span`
  text-transform: capitalize !important;
`

const stepsItems = [
  {
    id: 'basic',
    icon: 'info' as SemanticICONS,
    title: 'Identificação',
    description: 'Identifique a simulação',
    completed: false,
    disabled: false,
    value: '',
    showFeedback: false,
  },
  {
    id: 'payload',
    icon: 'map marker' as SemanticICONS,
    title: 'Rotas',
    description: 'Indique a rota',
    completed: false,
    disabled: false,
    value: '',
    showFeedback: false,
  },
]

const fields = [
  [
    {
      name: 'name',
      label: 'Nome',
      placeholder: 'simulations.fields.name.placeholder',
      type: 'text',
      required: true,
    },
    {
      name: 'interval',
      label: 'simulations.fields.dataInterval.label', //'Data transmission duration',
      placeholder: 'simulations.fields.dataInterval.placeholder',
      type: 'number',
      help: 'simulations.fields.dataInterval.help', //'How long the device will simulate sending data to the defined data topic in seconds',
      required: true,
    },
    {
      name: 'duration',
      label: 'simulations.fields.dataDuration.label',
      placeholder: 'simulations.fields.dataDuration.placeholder',
      type: 'number',
      help: 'simulations.fields.dataDuration.help', //'How often devices will send data during a simulations in seconds',
      required: true,
    },
  ],
  [
    {
      name: 'route',
      label: 'simulations.fields.route.label',
      placeholder: 'simulations.fields.route.placeholder',
      type: 'list',
      help: 'simulations.fields.route.help', //'Select the route that will be simulated',
      required: true,
      options: [],
      request: {
        path: '/geo?type=routes',
        resultPath: 'items',
      },
    },
    {
      name: 'asset',
      label: 'simulations.fields.asset.label',
      placeholder: 'simulations.fields.asset.placeholder',
      type: 'list',
      help: 'simulations.fields.asset.help', //'Select the assets that will be simulated',
      required: true,
      options: [],
      request: {
        path: '/search',
        resultPath: 'searchResults',
      },
    },
  ],
]

function SimulationStepForm({ handleCancel, editId }: GenericFormProps) {
  //const [wip, setWip] = React.useState<StepsType>(new Object() as StepsType)
  const [loading, setLoading] = React.useState(false)
  const [wip, setWip] = React.useState<any>({
    0: {
      name: '',
      duration: 0,
      interval: 0,
    },
    1: {
      route: undefined,
      asset: undefined,
    },
  })
  useEffect(() => {
    if (editId) {
      const fetchSimulation = async () => {
        try {
          const response = await getSimulation(editId)
          const { name, duration, interval, route, assetIds } = response
          setWip({
            0: {
              name,
              duration,
              interval,
            },
            1: {
              route,
              asset: assetIds[0],
            },
          })
        } catch (error) {
          console.log('error', error)
        }
      }
      fetchSimulation()
    }
  }, [editId])

  const [formErrors, setFormErrors] = React.useState<any>({})
  const [currentStep, setCurrentStep] = React.useState(0)
  const handleChange = useCallback(
    (_e: any, prop: any) => {
      setWip((prevState: any) => ({
        ...prevState,
        [currentStep]: {
          ...prevState[currentStep],
          [prop.name]: prop.type === 'number' ? Number(prop.value) : prop.value,
        },
      }))
    },
    [currentStep]
  )

  const handleError = useCallback((errors: any) => {
    setFormErrors(errors)
  }, [])

  const handleFormSave = useCallback(async () => {
    if (Object.keys(formErrors).length !== 0) return
    if (currentStep + 1 < stepsItems.length) setCurrentStep(currentStep + 1)
    else {
      const data = Object.keys(wip).reduce((acc: any, key: string) => {
        return { ...acc, ...wip[key] }
      }, {})
      try {
        setLoading(true)
        const { data: res } = await axios.get(`${URL_BASE}/geo?ids=${data.route}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('id_token'),
            'Content-Type': 'application/json',
          },
        })
        const coords = JSON.parse(res.items[0].geometry.coordinates)
        const routes = coords.map((route: any) => ({
          lat: route[1],
          lng: route[0],
        }))
        const response = await createDeviceType({
          name: data.name,
          payload: routes,
          topic: '/',
        })
        await createSimulation({
          ...data,
          devices: [{ name: response.name, typeId: response.typeId, amount: 1 }],
        })
        setLoading(false)
        handleCancel()
      } catch (error) {
        console.log('error', error)
      }
    }
  }, [currentStep, formErrors, wip, handleCancel])

  const { t } = useTranslation()

  return (
    <ModalTemplate
      open={true}
      size="large"
      header={<Span>{`${editId ? t('toEdit') : t('toAdd')} ${t('Simulation')}`}</Span>}
      saveContent={currentStep + 1 === stepsItems.length ? 'Salvar' : 'Próximo'}
      onSave={handleFormSave}
      onCancel={handleCancel}
      loading={loading}
      onClose={handleCancel}
    >
      <Steps items={stepsItems} currentStep={currentStep} onClick={(step: number) => setCurrentStep(step)} />
      <Form style={{ marginTop: '5vh', marginBottom: '6.6vh' }}>
        <FormContainer
          fields={fields[currentStep]}
          formStates={wip[currentStep]}
          formErrors={formErrors}
          handleChange={handleChange}
          onErrors={handleError}
          schema={stepsSchema[currentStep]}
        />
      </Form>
    </ModalTemplate>
  )
}

export default SimulationStepForm
