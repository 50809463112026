import React, { ChangeEvent, useState } from 'react'
import { Storage } from 'aws-amplify'
import { Input } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'
import { Progress } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'

interface VideoUploadProps {
  onUpload?: (ev: ChangeEvent<HTMLInputElement>, url: string) => void
  onError?: (err: any) => void
}

export const VideoUpload = ({ onUpload, onError }: VideoUploadProps) => {
  const [progressLevel, setProgress] = useState<number>(0)
  const tenant = useSelector((state: RootStore) => state.login.empresa)

  const handleUpload = async (ev: ChangeEvent<HTMLInputElement>) => {
    try {
      setProgress(0)
      const file = ev.target.files?.[0]
      if (file) {
        const result = (await Storage.put(tenant + '/videos/' + uuidv4() + file.name, file, {
          contentType: 'video/mp4',
          progressCallback: (progress: any) => {
            setProgress(Math.round((progress.loaded / progress.total) * 100))
          },
        })) as { key: string }
        if (onUpload) {
          onUpload(ev, result.key)
        }
      }
    } catch (err) {
      setProgress(0)
      if (onError) onError(err)
    }
  }

  const renderProgress = () => {
    if (progressLevel === 0) {
      return <Input type="file" onChange={handleUpload} accept="video/mp4,video/x-m4v,video/*" />
    }
    return <Progress percent={progressLevel} progress success={progressLevel === 100} />
  }

  return <div style={{ display: 'flex', flexDirection: 'column', minWidth: 300 }}>{renderProgress()}</div>
}
