import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { setDataLabelingsProp } from 'Store/actions/dataLabelings-actions'
import { VideoUpload } from 'Components/Video/VideoUpload'
import { Icon } from 'semantic-ui-react'

const DataLabelingsVideoUpload = () => {
  const dispatch = useDispatch()
  const url = useSelector((state: RootStore) => state.dataLabelings.wip?.videoUrlKey)

  const onUpload = (ev: React.ChangeEvent<HTMLInputElement>, url: string) => {
    dispatch(setDataLabelingsProp(`wip.videoUrlKey`, url))
  }

  if (url)
    return (
      <div>
        {url}
        <Icon name="trash" onClick={() => dispatch(setDataLabelingsProp(`wip.videoUrlKey`, ''))} color="red" />
      </div>
    )

  return <VideoUpload onUpload={onUpload} />
}

export default DataLabelingsVideoUpload
