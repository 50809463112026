import React, { useMemo } from 'react'
import m from 'moment'
import { useTable, useSortBy, useGlobalFilter, useRowSelect, useGroupBy, useExpanded, TableOptions } from 'react-table'
import { Table, Icon, HeaderProps } from 'semantic-ui-react'
import styled from 'styled-components'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { useTranslation } from 'react-i18next'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: { indeterminate: boolean; rest: object }, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>(null)
    const resolvedRef = (ref || defaultRef) as React.MutableRefObject<HTMLInputElement>

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

const TableHeaderCell = styled(Table.HeaderCell)`
  position: sticky;
  top: -1px;
  z-index: 3;
`

interface CustomTableOptions<D extends object> extends TableOptions<D> {
  autoResetExpanded?: boolean
}

const DriverTable = () => {
  const { t } = useTranslation()

  const driver = useObjSelector((state) => state.dataLabelings.driver)
  // const columns: any = GenericColumns({
  //   columns: ['PDT', 'TCT', 'meanDuration', 'meanDessaturation'],
  //   hideDefaultColumns: true,
  // })

  const columns = useMemo(() => {
    return [
      {
        Header: t('PDT'),
        accessor: 'PDT',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: any) => `${value} ${t('PDT')}(s)`,
        Cell: ({ value }: any) => value ?? '',
        align: 'left',
        disableGroupBy: true,
      },
      {
        Header: t('TCT') + ' (s)',
        accessor: 'TCT',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: any) => `${value} ${t('TCT')}(s)`,
        Cell: ({ value }: any) => value ?? '',
      },
      {
        Header: t('meanDuration'),
        accessor: 'meanDuration',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: any) => `${value} ${t('meanDuration')}(s)`,
        Cell: ({ value }: any) => value ?? '',
        disableGroupBy: true,
      },
      {
        Header: t('meanDessaturation'),
        accessor: 'meanDessaturation',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: any) => `${value} ${t('meanDessaturation')}(s)`,
        Cell: ({ value }: any) => parseInt(value ?? 0),
        disableGroupBy: true,
        sortType: 'basic',
      },
      {
        Header: t('steps'),
        accessor: 'steps',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: any) => `${value} ${t('steps')}(s)`,
        Cell: ({ value }: any) => {
          return value
        },
        disableGroupBy: true,
        sortType: 'basic',
      },
    ]
  }, [t])

  const data = useMemo(() => {
    return driver
  }, [driver])

  // const [selectedRowIds, setCurrentSelectedRowIds] = useState({ 0: true })

  const allTableProps: any = useTable(
    {
      columns,
      data,
      autoResetExpanded: false,
      autoResetSelectedRows: true,
      initialState: {
        selectedRowIds: { 0: true },
        sortBy: [{ desc: false, id: 'PDT' }],
      },
    } as CustomTableOptions<{ [key: string]: any }>,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: (useRowSelect: { [key: string]: any }) => (
            <div>
              <IndeterminateCheckbox {...useRowSelect.getToggleAllRowsSelectedProps({ title: t('SelectAllRows') })} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: { [key: string]: any }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps({ title: t('SelectRow') })} />
            </div>
          ),
          // aggregate:'count',Aggregated:({value})=>`${value} selecionados`, canGroupBy: true
        },
        ...columns,
      ])
    }
  )

  // useEffect(() => {
  //   // console.log('selectedRowIds', selectedRowIds)
  //   // console.log('allTableProps.state.selectedRowIds', allTableProps.state.selectedRowIds)
  //   const diff = _.omit(allTableProps.state.selectedRowIds, _.keys(selectedRowIds))
  //   // console.log('test', diff)
  //   setCurrentSelectedRowIds(allTableProps.state.selectedRowIds)
  //   // console.log({ key: Object.keys(diff)[0], value: Object.values(diff)[0] })
  //   if (Object.keys(diff)[0]) allTableProps.toggleRowSelected(parseInt(Object.keys(diff)[0]), Object.values(diff)[0])
  // }, [allTableProps.state.selectedRowIds, selectedRowIds])

  // console.log('allTableProps', allTableProps)

  return (
    <div style={{ width: '5vw' }}>
      <Table
        striped
        color={'blue'}
        singleLine
        {...allTableProps.getTableProps()}
        compact
        size="small"
        textAlign="center"
      >
        <Table.Header>
          {
            // Loop over the header rows
            allTableProps.headerGroups.map((headerGroup: HeaderProps) => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column: { [key: string]: any }) => (
                    <TableHeaderCell
                      textAlign={column?.align || 'left'}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {
                        // Render the headecolumn.id.startsWith('MediaTgTot')
                        column.render('Header')
                      }
                      {column.isSorted ? column.isSortedDesc ? <Icon name="sort down" /> : <Icon name="sort up" /> : ''}
                      {column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <Icon
                          {...column.getGroupByToggleProps()}
                          name={column.isGrouped ? 'level down' : 'level up'}
                          rotated="clockwise"
                          style={{ marginLeft: '4px' }}
                        />
                      ) : null}
                    </TableHeaderCell>
                  ))
                }
              </Table.Row>
            ))
          }
        </Table.Header>
        {/* Apply the table body props */}
        <Table.Body {...allTableProps.getTableBodyProps()}>
          {
            // Loop over the table rows
            allTableProps.rows.map((row: { [key: string]: any }) => {
              // Prepare the row for display
              allTableProps.prepareRow(row)
              return (
                // Apply the row props
                <Table.Row {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell: { [key: string]: any }) => {
                      // Apply the cell props
                      return (
                        <Table.Cell
                          textAlign={cell?.column?.align || 'center'}
                          verticalAlign="middle"
                          {...cell.getCellProps()}
                        >
                          {
                            // Render the cell contents
                            cell.isGrouped ? (
                              <>
                                <Icon
                                  name={row.isExpanded ? 'minus square outline' : 'plus square outline'}
                                  {...row.getToggleRowExpandedProps()}
                                />
                                {cell.render('Cell')} ({row.subRows.length})
                              </>
                            ) : cell.isAggregated ? (
                              cell.render('Aggregated')
                            ) : cell.isPlaceholder ? null : (
                              cell.render('Cell')
                            )
                          }
                        </Table.Cell>
                      )
                    })
                  }
                </Table.Row>
              )
            })
          }
        </Table.Body>
      </Table>
    </div>
  )
}

interface RowType {
  time: m.Moment
}

export default DriverTable
