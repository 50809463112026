import React, { useRef, useState, useEffect } from 'react'
import { Image, Dropdown, Dimmer, Loader } from 'semantic-ui-react'
import styled from 'styled-components'
import useImageUrl from 'Utils/hooks/useImageUrl'
import { useSelector } from 'react-redux'
import { Storage } from 'aws-amplify'
import { createImageFromInitials } from './ImageInitials'

//import PropTypes from 'prop-types';

const StyledImage = styled(Image)`
  position: static;
`

const opacity = { opacity: 1 }

const AvatarDimmer = ({ isLoading }) => (
  <Dimmer active={isLoading}>
    <Loader size="small" />
  </Dimmer>
)

const AvatarImage = ({ url, setOpen, isLoading, attributes }) => {
  return url ? (
    <StyledImage
      circular
      src={url}
      dimmer={<AvatarDimmer isLoading={isLoading} />}
      {...attributes}
      onClick={() => setOpen((open) => !open)}
    />
  ) : (
    <Loader active inline="centered" />
  )
}

const Avatar = ({
  assetId,
  editable = false,
  onSelectedFile,
  size = 'tiny',
  isLoading = false,
  assetName,
  ...attributes
}) => {
  const fileInput = useRef(null)
  const [objectUrl, setObjectUrl] = useState(null)
  const [open, setOpen] = useState(false)
  const tenant = useSelector((state) => state.login.empresa)
  const { signedURL } = useImageUrl(`${tenant}/images/avatar/${assetId}.png`, {
    fallbackUrl: createImageFromInitials(200, assetName, '#4b72b2'),
  })
  const URL = isLoading ? objectUrl : signedURL
  const handleEdit = () => {
    fileInput.current && fileInput.current.click()
  }

  const handleDelete = async () => {
    await Storage.remove(`${tenant}/images/avatar/${assetId}.png`)
    fileInput.current.value = ''
    revokeUrl()
    setObjectUrl(null)
    setOpen(false)
  }
  const revokeUrl = () => objectUrl && window.URL.revokeObjectURL(objectUrl)
  const onSelectFile = async (ev) => {
    const file = ev.target.files && ev.target.files[0]
    if (!file) return
    if (!/^image\//.test(file.type)) {
      alert('Imagem inválida')
      return
    }
    revokeUrl()
    await Storage.put(`${tenant}/images/avatar/${assetId}.png`, file, {
      contentType: 'image/png', // contentType is optional
    })
    const oURL = window.URL.createObjectURL(file)
    if (onSelectedFile) onSelectedFile(file)
    setObjectUrl(oURL)
    setOpen(false)
  }
  useEffect(() => () => revokeUrl())

  return (
    <Dropdown
      trigger={<AvatarImage url={URL} size={size} setOpen={setOpen} isLoading={isLoading} {...attributes} />}
      //options={options}
      open={open}
      onBlur={() => setOpen(false)}
      icon={null}
      style={opacity}
      disabled={!editable}
    >
      <Dropdown.Menu>
        <input
          type="file"
          onChange={onSelectFile}
          accept="image/gif,image/jpeg,image/png"
          style={{ display: 'none' }}
          ref={fileInput}
        />
        <Dropdown.Item icon="upload" onClick={handleEdit} />
        <Dropdown.Item icon="trash" onClick={handleDelete} />
      </Dropdown.Menu>
    </Dropdown>
  )
}

/* Avatar.defaultProps = {
    showEditButton: false,
    onEdit: null,
    size: 'big',
 editButton: defaultEditButton, 
}; */

export default Avatar
