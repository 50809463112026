import * as z from 'zod'

const moduleSchema = z.object({
  code: z.string().max(13, 'form.error.max').optional().nullable(),
  label: z.string().max(60, 'form.error.max').optional().nullable(),
  profile: z.string().optional().nullable(),
})

export type StepOne = z.infer<typeof moduleSchema>

export default moduleSchema
