import React, { useState } from 'react'
//import {useSelector} from 'react-redux';
import styled from 'styled-components'
import { /*Grid, Icon,*/ Input } from 'semantic-ui-react'

const InputStyledAlone = styled(Input)`
  width: 180px;
`
//const InputStyled = styled(Input)`width: 120px;`;

const InputStyledLeft = styled(Input)`
  max-width: 75px;
  margin-top: 5px;
  margin-right: 1px;
`
const InputStyledRight = styled(Input)`
  max-width: 65px;
  margin-top: 5px;
  margin-right: 1px;
`

export const PopupInfoEditInput = (props) => {
  const [val, setVal] = useState(props.value)
  const handleChange = (e, { value }) => {
    setVal(value)
  }

  return props.icon ? (
    <InputStyledAlone
      name={props.name}
      size={props.size || 'small'}
      icon={props.icon}
      label={props.label}
      iconPosition="left"
      placeholder={props.example || 'Ex:Sensor Temperatura 1'}
      value={val}
      onChange={handleChange}
    />
  ) : props.label === 'Unidade' ? (
    <InputStyledAlone
      name={props.name}
      size={props.size || 'small'}
      icon={props.icon}
      label={props.label}
      iconPosition="left"
      placeholder={props.example || 'Ex:Sensor Temperatura 1'}
      value={val}
      onChange={handleChange}
    />
  ) : props.left ? (
    <InputStyledLeft
      key={props.key}
      name={props.name}
      type={props.type || 'number'}
      label={props.label}
      size={props.size || 'small'}
      placeholder={props.example || 'Ex:100'}
      value={val}
      min={0}
      onChange={handleChange}
    />
  ) : (
    <InputStyledRight
      key={props.key}
      name={props.name}
      type={props.type || 'number'}
      label={props.label}
      size={props.size || 'small'}
      placeholder={props.example || 'Ex:100'}
      value={val}
      min={0}
      onChange={handleChange}
    />
  )
}

export default PopupInfoEditInput
