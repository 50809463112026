import DropdownField from 'Components/forms/generic/DropdownField'
import InputField from 'Components/forms/generic/InputField'
import React, { useMemo } from 'react'
import { Form } from 'semantic-ui-react'
import useStoreSelector from 'Utils/hooks/useStoreSelector'

interface Props {
  profileId: string
  setState: Function
  id: string
  value: string | number | undefined
}

const CustomFieldFilter: React.FC<Props> = ({ profileId, setState, value, id }) => {
  const customFields = useStoreSelector((state) => state.profiles.byId[profileId]?.customFields ?? [])

  const selectedField = useMemo(() => customFields.find((cf) => cf.id === id), [customFields, id])

  const listOptions = useMemo(
    () => selectedField?.options?.map((opt) => ({ key: opt, text: opt, value: opt })) ?? [],
    [selectedField]
  )

  const options = useMemo(() => {
    return customFields.reduce((acc, field) => {
      if (!['dateTime', 'date', 'images', 'section', 'checkList'].includes(field.type)) {
        acc.push({
          key: field.id,
          text: field.label,
          value: field.id,
        })
      }
      return acc
    }, [] as { key: string; text: string; value: string }[])
  }, [customFields])

  return (
    <Form.Group widths={'equal'}>
      <DropdownField
        label="Campo personalizado"
        options={options}
        value={id}
        onChange={(_, { value }) => {
          setState({ id: value, value: undefined })
        }}
      />
      {(selectedField?.type === 'text' || selectedField?.type === 'textArea') && (
        <InputField
          label="Valor"
          value={value}
          onChange={(_, { value }) => {
            setState({ id: selectedField.id, value })
          }}
        />
      )}
      {(selectedField?.type === 'list' || selectedField?.type === 'multiple') && (
        <DropdownField
          label="Valor"
          value={value}
          options={listOptions}
          onChange={(_, { value }) => {
            setState({ id: selectedField.id, value })
          }}
        />
      )}
      {selectedField?.type === 'boolean' && (
        <DropdownField
          label="Valor"
          value={value}
          options={[
            { key: 'y', text: 'Sim', value: true },
            { key: 'n', text: 'Não', value: false },
          ]}
          onChange={(_, { value }) => {
            setState({ id: selectedField.id, value })
          }}
        />
      )}
    </Form.Group>
  )
}

export default CustomFieldFilter
