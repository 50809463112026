/**
 * Flattens a nested object into a single level object with keys representing
 * the path to the original keys separated by underscores.
 *
 * @param {Object} params - The parameters object.
 * @param {Record<string, any>} params.obj - The object to be flattened.
 * @param {string} [params.prefix=''] - The prefix for keys (used internally during recursion).
 * @param {Record<string, any>} [params.res={}] - The result object (used internally during recursion).
 * @returns {Record<string, any>} - The flattened object.
 */
export default function flattenObject({
  obj,
  prefix = '',
  res = {},
  hiddenFields = [],
}: {
  obj: any
  prefix?: string
  res?: Record<string, any>
  hiddenFields?: string[]
}): Record<string, any> {
  for (let key in obj) {
    if (hiddenFields.includes(key)) {
      continue
    }
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
      flattenObject({ obj: obj[key], prefix: `${prefix}${key}.`, res, hiddenFields })
    } else {
      res[`${prefix}${key}`] = obj[key]
    }
  }
  return res
}
