/*  Author: Bruno Melo / Luís Mourão
	Type: Smart
	Description: 
	TODO: - Criar componente dumb grid com o conteúdo do return(render)
*/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/zh-tw'
import 'moment/locale/pt-br'
import { handleExportHist } from './csvHistory'
import { throttle } from 'throttle-debounce'
import { saveHeatmap, fetchHeatMap } from 'Store/actions/heatmap-action'
import { filterTime } from 'Store/actions/filters-action'
import { sliderChange } from 'Store/actions/history-action'
import { setTrue, setFalse, toggle } from 'Store/actions/toggle-action'
import { showConfirm, hideConfirm } from 'Store/actions/system-action'
import { withTranslation } from 'react-i18next'
import { HistoryBarGrid } from './HistoryBarGrid.jsx'

class HistoryBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      csvBool: false,
      sliderMin: this.props.min,
      sliderMax: this.props.max,
      warning: false,
      loading: false,
    }
    this.updateActionThrottled = throttle(750, this.onArrowDown)
  }

  componentDidUpdate(prevProps) {
    const shouldShowHeatMap = this.props.showHeatMap && this.props.histFetched && !prevProps.histFetched
    if (shouldShowHeatMap) {
      this.showHeatMap(undefined, 'HeatMapInert')
    }
    if (prevProps.history.success !== false && this.props.history.success === false)
      alert(this.props.t('HistorySliderAlert'))
  }

  handleSave = (dateRange) => {
    this.props.filterTime(dateRange)
    //Teste se o horário é maior que 1 dia
    dateRange.end.diff(dateRange.start) > 86400000
      ? this.props.setTrue('HistMoreThan1Day')
      : this.props.setFalse('HistMoreThan1Day')
    //Deveria ser historyBar né?
  }
  showModal = ({ text, parametros }) => {
    this.props.setFalse('loadingScreen')
    this.props.showConfirm({
      content: text,
      onCancel: () => {
        this.props.hideConfirm()
      },
      onConfirm: () => {
        this.props.hideConfirm()
        this.props.setTrue('loadingScreen')
      },
    })
  }

  loadDataHeatmap = () => {
    const { fetchHeatMap, t } = this.props
    this.props.setFalse('showHistoryCalendar')
    if (!fetchHeatMap()) alert(t('HistorySliderAlert'))
  }

  emptyHeatmap = () => {
    this.props.hmPoints && this.props.saveHeatmap([]) //Setar o history vazio
  }

  onArrowDown = (e) => {
    switch (e) {
      case 37:
        if (this.props.max > 0) {
          this.props.sliderChange({ min: this.props.min, max: this.props.max - 1 })
        }
        break
      case 39:
        if (this.props.max < this.props.historySize - 1) {
          this.props.sliderChange({ min: this.props.min, max: this.props.max + 1 })
        }
        break
      default:
        return null
    }
  }

  onKeyDown = (e) => {
    if (e.keyCode === 37 || e.keyCode === 39) this.updateActionThrottled(e.keyCode)
  }

  refDiv = (ref) => {
    this.divRef = ref
  }

  onChangeHistData = () => {
    if (!this.props.raw) this.props.setTrue('showRawHist')
    else this.props.setFalse('showRawHist')
  }

  csvClick = () => {
    this.setState({ csvBool: true })
  }

  sliderLocalParentChange = (newState) => {
    this.setState({ ...newState })
  }
  onClickXCalendar = () => {
    this.props.setFalse('showHistoryCalendar')
  }

  render() {
    //alert(this.changeColor())
    const {
      history,
      raw,
      routeByProximity,
      showCalendar,
      heatMap,
      perfil,
      t,
      projects,
      start,
      end,
      showHeatMap,
      heatMapTotal,
    } = this.props
    const { allIds: hist, byId: histById } = history
    const { csvBool, sliderMin, sliderMax } = this.state
    const calendarIcon = [
      {
        title: 'HistorySliderHist',
        icon: 'calendar alternate',
        color: showCalendar ? 'blue' : '#4b72b2',
        size: showCalendar ? null : 'small',
      },
    ]
    const exportHist = csvBool ? handleExportHist(hist, histById) : [[]]
    const histInfoProps = {
      loadingScreen: this.props.history.loading,
      min: sliderMin,
      max: sliderMax,
      showCalendar,
      start,
      end,
      hist: history.allIds,
      heatMap,
      heatMapTotal: heatMapTotal,
      histById: history.byId,
    }

    const histIconProps = {
      calendarIcon,
      exportHist,
      perfil,
      onChangeHistData: this.onChangeHistData,
      csvClick: this.csvClick,
      csvBool,
      raw,
      routeByProximity,
      showHeatMap,
    }

    const sliderProps = { sliderLocalParentChange: this.sliderLocalParentChange, showSlider: !showHeatMap }
    const calendarProps = {
      projects,
      handleSave: this.handleSave,
      start,
      end,
      showCalendar,
      showHeatMap,
      onClickXCalendar: this.onClickXCalendar,
      warning: this.state.warning,
      loadingButton: this.state.loadingButton,
    }

    const noMoviment = calendarProps?.projects
      ? !calendarProps.projects.every((each) => each.company !== 'Ambev')
      : false
    const showIcons = (histInfoProps?.hist?.length > 0 || histInfoProps?.heatMapTotal > 0) && noMoviment

    return (
      <div ref={this.refDiv} onKeyDown={this.onKeyDown} tabIndex="0" className="historyBox">
        <HistoryBarGrid
          t={t}
          histInfoProps={histInfoProps}
          histIconProps={histIconProps}
          sliderProps={sliderProps}
          calendarProps={calendarProps}
          showIcons={showIcons}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects,
  min: state.history.slider.min,
  max: state.history.slider.max,
  start: state.filters.timeStart,
  end: state.filters.timeEnd,
  historySize: state.history.filteredSize,
  filters: state.filters,
  histFetched: state.toggle.histFetched,
  searchValue: state.filters.searchValue,
  searchField: state.filters.searchField,
  filterResult: state.filters.result,
  perfil: state.login.perfil,
  empresa: state.login.empresa,
  history: state.history,
  raw: state.toggle.showRawHist,
  routeByProximity: state.toggle.showRouteByProximity,
  heatMap: state.toggle.showHeatMap,
  heatMapTotal: state.heatmap.points.length,
  showCalendar: state.toggle.showHistoryCalendar,
  showHeatMap: state.toggle.showHeatMap,
})

const mapDispatchToProps = {
  setFalse,
  setTrue,
  toggle,
  sliderChange,
  saveHeatmap,
  showConfirm,
  hideConfirm,
  filterTime,
  fetchHeatMap,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(HistoryBar))
