import React from 'react'
import { TableColumn } from '../types'
import { TFunction } from 'i18next'
// import { Checkbox } from 'semantic-ui-react'
import { DateTime } from 'Utils/reactTable/components/DateTime'
//import { threeColumns } from 'Utils/threeColumns'

export const NewsColumns = (t: TFunction) => {
  return [
    {
      Header: 'ID',
      accessor: 'id',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} unique ids`,
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: 'Título',
      accessor: 'title',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} ids`,
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
    {
      Header: 'Visualizações',
      accessor: 'views',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'tipos'}`,
    },
    {
      Header: 'Clicks',
      accessor: 'clicks',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'títulos'}`,
    },
    {
      Header: 'Criado em',
      accessor: 'datetime',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} hour`,
      Cell: ({ value }) => <DateTime value={value} />,
    },
    {
      Header: 'Criado por',
      accessor: 'createdBy',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} login`,
      Cell: ({ value }) => <span>{value ? value : 'ADM'}</span>,
    },
  ] as TableColumn[]
}
