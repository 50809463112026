import React from 'react'
import { TableColumn } from '../types'
import { TFunction } from 'i18next'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import TableSpan from 'Components/SidebarMenu/TableSpan'
import { CellValue } from 'interfaces/cellValue'

export const ReportsColumns = (t: TFunction, dispatch: any, match: any) => {
  // Commenting because of the download button.
  // const prepareForDate = (value: string) => {
  //   dispatch({
  //     type: 'SELECT_DATE_REPORTS_OPEN',
  //     payload: value,
  //   })
  // }
  return [
    {
      Header: t('ReportName'),
      accessor: 'label',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
    {
      Header: t('Description'),
      accessor: 'description',
    },
    {
      Header: t('Actions'),
      accessor: 'resource',
      Cell: ({ value }) => {
        return (
          <>
            <Link to={match.path === '/app/report/:id' ? `${value}` : `report/${value}`}>
              <Icon name="eye" title={t('PreviewButtonTitle')} size="large" />
            </Link>
            {/* 
            Commenting for now. Let's see if Claúdio complains or notices anything different.
            <Icon
              color="green"
              name="download"
              title={t('DownloadButtonTitle')}
              style={{ cursor: 'pointer' }}
              onClick={() => prepareForDate(value)}
            /> */}
          </>
        )
      },
    },
  ] as TableColumn[]
}
const padTime = (num: number) => {
  return num.toString().padStart(2, '0')
}

const msToHoursMinutesSecs = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  seconds = seconds % 60
  minutes = minutes % 60
  return `${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}`
}

export const ReportsColumnsCustom = (t: TFunction, cols: any = [], reportType: string, history: any) => {
  return cols.map((v: any, id: number) => {
    return {
      id: `col${id}`,
      Header: t(v),
      accessor: (row: Record<string, CellValue<number>>) => {
        return row[`col${id}`]?.baseValue
      },
      sortType: 'basic',
      aggregate:
        v !== 'Duração'
          ? 'count'
          : (agg: any) => {
              return agg.reduce((prev: string, current: CellValue<number>) => {
                const currrentDuration = current?.baseValue ?? current
                const durationSum = moment.duration(prev).add(moment.duration(currrentDuration))
                return msToHoursMinutesSecs(durationSum.asMilliseconds())
              }, '00:00:00')
            },
      Cell: (props: any) => {
        let value: any

        if (props.row.groupByID === `col${id}`) {
          value = props.row?.subRows?.[0].original?.[props.row.groupByID]?.displayValue ?? props.value
        } else {
          value = props.row?.original?.[`col${id}`]?.displayValue ?? props.value
        }
        if (props.column.id === 'col0' && reportType.includes('avgTimeReport') && v === 'Código')
          return (
            <TableSpan
              value={value}
              width="90px"
              isLink
              onMouseDown={(event: any) => {
                const baseValuePath = props.row?.original?.[`col${id}`]?.baseValue
                const path = `/app/report/${reportType}/${baseValuePath}`

                switch (event.button) {
                  case 0: // Botão esquerdo do mouse
                    if (value && baseValuePath) {
                      history.push(path)
                    }
                    break
                  case 1: // Botão do meio do mouse
                    event.preventDefault()
                    if (baseValuePath) {
                      window.open(path, '_blank')
                    }
                    break
                  default:
                    // Lidar com outros botões se necessário
                    break
                }
              }}
              padding="0px"
            />
          )
        else return <TableSpan value={value} width="400px" padding="0px" />
      },
    }
  }) as TableColumn[]
}
