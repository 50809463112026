import React, { useMemo } from 'react'
import { Form, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { GeometryUtil } from 'leaflet'
import InputField from 'Components/forms/generic/InputField'
import { useDispatch, useSelector } from 'react-redux'
import { setModulesProps } from 'Store/actions/modules-action'

export const StatsBarFormGeoArea = ({ geometry }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const zIndex = useSelector(
    (state) => state.modules.wip.properties?.zIndex ?? state.modules.current.properties?.zIndex ?? ''
  )

  const coords = useMemo(() => {
    if (geometry.mRadius) {
      return [{ lat: geometry.coordinates[1], lng: geometry.coordinates[0], radius: geometry.mRadius }]
    } else {
      return geometry.coordinates[0].reduce((acc, curr) => {
        acc.push({ lat: curr[1], lng: curr[0] })
        return acc
      }, [])
    }
  }, [geometry])

  const mArea = useMemo(() => {
    if (coords[0].radius) {
      return Math.round(Math.PI * Math.pow(coords[0].radius, 2)) + ' m²'
    }
    let area = Math.round(0.99 * GeometryUtil.geodesicArea(coords))
    if (area > 999999) {
      area = Math.round(area / 1000000)

      if (area > 999999) {
        area = area.toExponential(1) + ' km²'
      } else {
        area = area + ' km²'
      }
    } else {
      area = area + ' m²'
    }
    return area
  }, [coords])

  return (
    <Form
      style={{
        paddingLeft: '10px',

        paddingRight: '20px',
        bottom: '5px',
      }}
    >
      <InputField
        label={t('zIndex')}
        value={zIndex}
        onChange={(_, { value }) => {
          const valueFormat = value ? Number(value) : ''
          dispatch(setModulesProps([['wip.properties.zIndex', valueFormat]]))
        }}
        inputType={'number'}
        placeholder={'Selecione a Elevação do perímetro'}
      />
      <Form.Field
        id="form-area"
        control={Input}
        label={t('Area')}
        placeholder={t('Area')}
        coord={coords}
        value={mArea ?? 'missing coords to calculate area'}
      />
    </Form>
  )
}
export default StatsBarFormGeoArea
