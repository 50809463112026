import React from 'react'
import { TableColumn } from '../types'
import { TFunction } from 'i18next'
import { DateTime } from 'Utils/reactTable/components/DateTime'
import { Checkbox } from 'semantic-ui-react'
import { updateSimulation } from 'Apis/simulation'
// import { Checkbox } from 'semantic-ui-react'
//import { threeColumns } from 'Utils/threeColumns'

export const SimulationsColumns = (t: TFunction, fetchAction: () => void) => {
  return [
    {
      Header: 'id',
      accessor: 'id',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} ids`,
    },
    {
      Header: 'Nome',
      accessor: 'name',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
    {
      Header: 'Estágio',
      accessor: 'stage',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'estágios'}`,
      Cell: ({ value }) => t(`simulations.stages.${value}`),
    },
    {
      Header: 'Execuções',
      accessor: 'runs',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'execuções'}`,
    },
    {
      Header: 'Última execução',
      accessor: 'lastRun',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'últimas execuções'}`,
      Cell: ({ value }) => (value ? <DateTime value={value} /> : '-'),
    },
    {
      Header: 'Criado em',
      accessor: 'createdAt',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} hour`,
      Cell: ({ value }) => <DateTime value={value} />,
    },
    {
      Header: t('Actions'),
      accessor: 'status',
      Cell: ({ value, row }) => {
        return (
          <Checkbox
            toggle
            name="toggle off"
            title={t('PreviewButtonTitle')}
            checked={value === 'running'}
            disabled={value === 'stopping'}
            onChange={async () => {
              if (value === 'running') {
                await updateSimulation({ action: 'stop', simulations: [row.original] })
              } else {
                await updateSimulation({ action: 'start', simulations: [row.original] })
              }
              fetchAction()
            }}
          />
        )
      },
    },
  ] as TableColumn[]
}
