import React, { useState, useMemo } from 'react'
import { Icon, Card, Statistic, Label, Grid, List, Image } from 'semantic-ui-react'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { useLeaflet } from 'react-leaflet'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setCurrentHole } from 'Store/actions/history-action'

const HolesCard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { map } = useLeaflet()
  const histHoles = useObjSelector((state) => state.history.holes.items)
  const percentiles = useObjSelector((state) => state.history.holes.percentiles)

  const histHolesLen = useMemo(() => histHoles.length, [histHoles])

  const [details, setDetails] = useState(false)

  return (
    <Card
      color="blue"
      style={{
        minWidth: '70vw',
        top: '45px',
        zIndex: '900',
        backgroundColor: 'rgb(255,255,255,0.6)',
      }}
    >
      <Card.Content>
        <Statistic size="mini" floated="right" color={'black'}>
          <Statistic.Value>
            <Icon name={'warning sign'} /> {histHolesLen}
          </Statistic.Value>
          <Statistic.Label>Buracos</Statistic.Label>
        </Statistic>
        <Card.Header>
          Informações no Período
          {histHolesLen > 0 && (
            <Icon
              title={details ? 'Clique para ocultar detalhes' : 'Clique para mostrar mais detalhes'}
              onClick={() => {
                setDetails(!details)
              }}
              style={{ cursor: 'pointer' }}
              name={details ? 'caret up' : 'caret down'}
              color="black"
            />
          )}
        </Card.Header>
        {details && histHolesLen > 0 && (
          <Card.Description style={{ display: 'flex', width: '100%' }}>
            <Grid style={{ width: '100%' }}>
              <Grid.Row style={{ padding: '7px' }}>
                <Grid.Column floated="left" width={8} textAlign="center">
                  <Card.Header>Buracos mais identificados</Card.Header>
                </Grid.Column>
                <Grid.Column floated="right" width={8} textAlign="center">
                  <Card.Header>Escala de Cores por Detecções</Card.Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ height: '50px', padding: '7px' }}>
                <Grid.Column floated="left" width={8} textAlign="center">
                  <List horizontal divided>
                    {histHoles
                      .sort((a, b) => b.holesCount - a.holesCount)
                      .map((hole, i) => {
                        if (i < 3) {
                          return (
                            <List.Item
                              key={hole.geohash}
                              onClick={() => {
                                map.getZoom() > 15 ? map.flyTo(hole.coords, 20) : map.setView(hole.coords, 20)
                                dispatch(setCurrentHole({ geohash: hole.geohash }))
                              }}
                            >
                              <Image>
                                <Label circular size="large" color={hole.color}>
                                  {hole.geohash.substring(5, 8)}
                                </Label>
                              </Image>
                              <List.Content>
                                <List.Header>{hole.holesCount}</List.Header>
                                Detecções
                              </List.Content>
                            </List.Item>
                          )
                        } else return null
                      })}
                  </List>
                </Grid.Column>
                <Grid.Column floated="right" width={5} style={{ left: '20px', bottom: '3px' }}>
                  <Statistic.Group size="mini" style={{ float: 'right' }}>
                    {percentiles.map((p) => (
                      <Statistic key={p.value}>
                        <Statistic.Value>
                          <Label circular color={p.color}>
                            {`> ${p.value}`}
                          </Label>
                        </Statistic.Value>
                        <Statistic.Label>{t(p.color)}</Statistic.Label>
                      </Statistic>
                    ))}
                  </Statistic.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Description>
        )}
      </Card.Content>
    </Card>
  )
}

export default HolesCard
