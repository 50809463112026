import React, { useState } from 'react'
import { Statistic, Dropdown } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

function MainStatistics() {
  const { t } = useTranslation()

  const [metrics, setMetrics] = useState({
    ciclo: [],
    saturação: ['média'],
    dessaturação: ['média', 'max'],
  })

  const cards = useObjSelector((state) => state.dataLabelings.cards)

  if (!cards?.['card#0']) return null

  function onItemClick(key, item) {
    if (!metrics[key]?.includes(item)) {
      setMetrics({ ...metrics, [key]: metrics[key].concat(item) })
    } else {
      setMetrics({ ...metrics, [key]: metrics[key].filter((e) => e !== item) })
    }
  }

  const StatisticGroup = () => (
    <div>
      <Statistic.Group>
        {Object.entries(metrics).map((eachMetric, metricsIndex, allMetrics) => {
          const key = eachMetric[0]
          const list = eachMetric[1]
          return list.map((metric, listIndex) => {
            const keyData = cards['card#0'].body.find((e) => e.source === key && e.metric === metric)
            if (keyData && !(keyData.value === null || keyData.value === undefined)) {
              const label = `${key} ${metric}`
              const value = keyData.value
              return (
                <Statistic
                  style={
                    listIndex === list.length - 1 && metricsIndex === allMetrics.length - 1
                      ? { marginLeft: '34px' }
                      : null
                  }
                >
                  <Statistic.Value>{value + 'S'}</Statistic.Value>
                  <Statistic.Label>{t(label)}</Statistic.Label>
                </Statistic>
              )
            } else return null
          })
        })}
      </Statistic.Group>
    </div>
  )

  const headerOptions = [...new Set(cards['card#0'].body.map((e) => e.source))]
  return (
    <>
      <StatisticGroup />
      <Dropdown multiple icon="filter">
        <Dropdown.Menu>
          {headerOptions.map((eachHeader, i) => {
            return (
              <>
                <Dropdown.Header icon="tags" content={eachHeader} />
                {cards['card#0'].body
                  .filter((e) => e.source === eachHeader)
                  .map((eachField) => (
                    <Dropdown.Item
                      icon={metrics[eachHeader]?.includes(eachField.metric) ? 'check' : null}
                      content={t(eachField.name)}
                      onClick={() => onItemClick(eachHeader, eachField.metric)}
                    />
                  ))}
                {i < headerOptions.length - 1 && <Dropdown.Divider />}
              </>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default MainStatistics
