export default function peopleReducer(state = {}, { type, payload }) {
  switch (type) {
    case 'DELETE_PEOPLE_GROUPS':
      let newById = state.byId
      if (payload.ids)
        payload.ids.forEach((id, i) => {
          if (state.byId[id]) newById[id].groups = []
        })
      return { ...state, byId: newById }

    case 'INSERT_PEOPLE_ACTION_START':
    case 'UPDATE_PEOPLE_ACTION_START':
    case 'DELETE_PEOPLE_ACTION_START':
      return { ...state, cudLoading: true }

    case 'INSERT_PEOPLE_ACTION_SUCCESS':
    case 'UPDATE_PEOPLE_ACTION_SUCCESS':
    case 'DELETE_PEOPLE_ACTION_SUCCESS':
      return { ...state, cudLoading: false, cudSuccess: true }

    case 'INSERT_PEOPLE_ACTION_FAILURE':
    case 'UPDATE_PEOPLE_ACTION_FAILURE':
    case 'DELETE_PEOPLE_ACTION_FAILURE':
      return { ...state, cudLoading: false, cudSuccess: false, error: payload }

    case 'CLEAR_PEOPLE_STATE':
      return { ...state, cudLoading: undefined, hasError: undefined, cudSuccess: undefined }

    default:
      return state
  }
}
