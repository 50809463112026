import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'

type Props = {
  onSave: () => void
  message: string
  confirmAction?: 'delete'
  loading?: boolean
}

const ConfirmModal: React.FC<Props> = ({ confirmAction, onSave, message, loading }) => {
  const { t } = useTranslation()

  return (
    <ModalTemplate
      open={true}
      loading={loading}
      header={confirmAction === 'delete' ? t('DeleteAction') : t('SaveAction')}
      saveContent={confirmAction === 'delete' ? 'Excluir' : 'Salvar'}
      saveColor={confirmAction === 'delete' ? 'red' : 'blue'}
      saveIcon={confirmAction === 'delete' ? 'trash' : 'check'}
      onSave={onSave}
      contentPXMargin={30}
    >
      {message}
    </ModalTemplate>
  )
}

export default ConfirmModal
