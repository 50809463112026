import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Input, Grid } from 'semantic-ui-react'

class TimePicker extends React.Component {
  static propTypes = {
    onChangeStart: PropTypes.func.isRequired,
    onChangeEnd: PropTypes.func.isRequired,

    start: PropTypes.string,
    end: PropTypes.string,

    disabled: PropTypes.bool,
  }

  render() {
    const { start, end, disabled, onChangeStart, onChangeEnd } = this.props

    return (
      <Fragment>
        <Grid.Column width={4}>
          <Input
            transparent
            size="mini"
            type="time"
            className="rdrDateDisplayItems_"
            data-test-id="startTime"
            disabled={disabled}
            value={start}
            onChange={onChangeStart}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Input
            transparent
            type="time"
            size="mini"
            className="rdrDateDisplayItems_"
            data-test-id="endTime"
            disabled={disabled}
            value={end}
            onChange={onChangeEnd}
          />
        </Grid.Column>
      </Fragment>
    )
  }
}

export default TimePicker
