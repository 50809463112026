import React, { useMemo } from 'react'
import ReactTable from 'Utils/reactTable'
// import { RootStore } from 'Store/initialStore'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { deleteActivities, setActivitiesProp, setActivityWIP } from 'Store/actions/activities-action'
import useFetchData from 'Utils/hooks/useFetchData'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { RootStore } from 'Store/initialStore'
import { useRouteMatch, Link /*, Redirect */ } from 'react-router-dom'
import { Breadcrumb, Modal, Header } from 'semantic-ui-react'
import {
  LabelList,
  ReferenceLine,
  Bar,
  BarChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts'
import { lightColors } from 'Utils/colorsFormat'
import { GenericColumns } from 'Utils/reactTable/components/GenericColumns'

const tableName = 'activities'
const lowerCaseStyle = {
  textTransform: 'none',
}
const BreadcrumbMensurations = (props: any) => (
  <Breadcrumb size={'big'}>
    {props.home && (
      <Breadcrumb.Section>
        <Link to={'/app/mensurations'}>{props.home}</Link>
      </Breadcrumb.Section>
    )}
    {props.home && <Breadcrumb.Divider icon="right chevron" />}
    {props.middle && (
      <Breadcrumb.Section active style={props.lowercase ? lowerCaseStyle : null}>
        <Link to={'#'}>{props.middle}</Link>
      </Breadcrumb.Section>
    )}
    <Breadcrumb.Divider active icon="right chevron" />
    <Breadcrumb.Section style={props.lowercase ? lowerCaseStyle : null}>
      <span>Yamazumi Antes</span>
    </Breadcrumb.Section>
    {/*<Breadcrumb.Divider active icon="right chevron" />
    <Breadcrumb.Section style={props.lowercase ? lowerCaseStyle : null}>
      <Link to={'#'}>Yamazumi Depois</Link>
    </Breadcrumb.Section>*/}
  </Breadcrumb>
)

const ActivitiesContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let match = useRouteMatch()
  const id = match?.params?.id

  useFetchData({ independentFetches: [{ fetchAction: 'activities' }, { fetchAction: 'mensurations' }] })

  const allActivitiesIds = useObjSelector((state: RootStore) => state.activities.allIds)
  const activitiesById = useObjSelector((state: RootStore) => state.activities.byId)
  const loading = useSelector((state: RootStore) => state.activities.loading)

  // const mensurationsById = useObjSelector((state: RootStore) => state.mensurations.byId)
  // const allMensurationsIds = useObjSelector((state: RootStore) =>
  //   state.mensurations.allIds.filter((i) => mensurationsById[i]?.activityId === id)
  // )

  const data = useMemo(() => {
    return allActivitiesIds.map((id: string) => {
      return { ...activitiesById[id] }
    })
  }, [allActivitiesIds, activitiesById])

  /* if(id)console.log('Mensurations', allMensurationsIds, mensurationsById)
  if(id)console.log('Activities',activitiesById[id])*/

  const pageData = [
    {
      name: 'Fiorino',
      uv: 19,
      pv: 34,
      amt: 31,
      uv2: 14,
      pv2: 47,
      amt2: 31,
    },
    {
      name: 'Strada',
      uv: 12,
      pv: 37,
      uv2: 12,
      pv2: 38,
    },
    {
      name: 'Strada CD(s/ desembarcação)',
      uv2: 8,
      pv2: 17,
      amt2: 40,
    },
    {
      name: 'Strada CD(c/ desembarcação)',
      uv: 21,
      pv: 48,
      amt: 17,
      uv2: 12,
      pv2: 13,
      amt2: 35,
      iv2: 23,
    },
  ]

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, value } = props
    const radius = 10

    return (
      <g>
        <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
        <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      </g>
    )
  }

  return id ? (
    <>
      <Modal.Header style={{ padding: '14px', height: '10vh', marginTop: '13px' }}>
        <Header style={{ textTransform: 'capitalize', position: 'relative', top: '25%' }}>
          <BreadcrumbMensurations home={t('mensurations')} middle={activitiesById?.[id]?.name} lowercase />
        </Header>
      </Modal.Header>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={pageData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar name={'Fase 1 - 1º Turno'} stackId="manha" dataKey="pv" fill={lightColors('pink')} label>
            <LabelList dataKey="name" content={renderCustomizedLabel} />
          </Bar>
          <Bar
            name={'Fase 2 - 1º Turno'}
            stackId="manha"
            dataKey="uv"
            fill={lightColors('green')}
            label={{ fontSize: 20 }}
          />
          <Bar
            name={'Fase 3 - 1º Turno'}
            stackId="manha"
            dataKey="amt"
            fill={lightColors('yellow')}
            label={{ fontSize: 20 }}
          />
          <Bar
            name={'Fase 4 - 1º Turno'}
            stackId="manha"
            dataKey="iv"
            fill={lightColors('green')}
            label={{ fontSize: 20 }}
          />
          <Bar
            name={'Fase 1 - 2º Turno'}
            dataKey="pv2"
            stackId="b"
            fill={lightColors('pink')}
            label={{ fontSize: 20 }}
          />
          <Bar
            name={'Fase 2 - 2º Turno'}
            dataKey="uv2"
            stackId="b"
            fill={lightColors('green')}
            label={{ fontSize: 20 }}
          />
          <Bar
            name={'Fase 3 - 2º Turno'}
            dataKey="amt2"
            stackId="b"
            fill={lightColors('yellow')}
            label={{ fontSize: 20 }}
          />
          <Bar
            name={'Fase 4 - 2º Turno'}
            dataKey="iv2"
            stackId="b"
            fill={lightColors('green')}
            label={{ fontSize: 20 }}
          />
          <ReferenceLine y={80} label={'TCT'} stroke="red" strokeWidth="2" />
          <ReferenceLine y={95} label={'TT'} stroke="gray" strokeWidth="2" />k
        </BarChart>
      </ResponsiveContainer>
      ;
    </>
  ) : (
    <ReactTable
      table={tableName}
      tableLabel={t('activities')}
      columns={GenericColumns({ columns: ['code', 'name', 'operationUnit', 'section'] })}
      data={data}
      showPlaceholders={loading}
      deleteAction={(ids: any) => dispatch(deleteActivities(ids))}
      newItemClick={() => {
        dispatch(setActivitiesProp(`form.open`, true))
        dispatch(setActivityWIP())
      }}
      onEditClick={(id: string) => {
        dispatch(setActivitiesProp(`form.open`, true))
        dispatch(setActivityWIP(id))
      }}
    />
  )
}

export default ActivitiesContent
