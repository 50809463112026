import { API } from 'aws-amplify'
import { createChangeLog } from 'graphql/mutations'
import { listChangeLogsByTenantEntityId } from 'graphql/queries'

export const setChangeLogsProps = (payload, source) => {
  return {
    type: 'SET_CHANGE_LOGS_PROPS',
    payload,
    source,
  }
}

export const fetchChangeLogs =
  ({ token = null, entityId, entity, currentItem }) =>
  async (dispatch, getState) => {
    const tenant = getState().login.empresa
    let changeLogInput
    try {
      dispatch({ type: 'FETCH_CHANGE_LOGS_START', tenant, entityId })
      const response = await API.graphql({
        query: listChangeLogsByTenantEntityId,
        variables: {
          tenant,
          entityId: {
            eq: entityId,
          },
          nextToken: token,
          limit: 10000,
        },
      })
      let payload = response.data.listChangeLogsByTenantEntityId

      if (payload?.items?.length === 0) {
        changeLogInput = {
          tenant,
          entity,
          entityId,
          log: JSON.stringify(currentItem),
          updatedBy: currentItem.updatedBy || currentItem.createdBy || 'system',
        }

        const saveRes = await API.graphql({
          query: createChangeLog,
          variables: {
            input: changeLogInput,
          },
        })

        payload.items = Object.values(saveRes.data)
      }

      dispatch({
        type: 'FETCH_CHANGE_LOGS_SUCCESS',
        payload,
      })
      if (payload.nextToken) await dispatch(fetchChangeLogs({ token: payload.nextToken }))
      return payload
    } catch (err) {
      console.error('error fetching changeLogs', { err, changeLogInput, currentItem })
    }
  }

export const setCurrentChangeLog = ({ id = '', payload = undefined }) => {
  return { type: 'SET_CURRENT_CHANGE_LOG', id, payload }
}

export const clearChangeLogs = () => {
  return { type: 'CLEAR_CHANGE_LOGS' }
}
