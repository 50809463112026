import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  reportDatesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 8,
  },

  reportIntervalDateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  totalLinesContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 63,
  },
  reportIssuanceDateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  labelInterval: {
    width: 60,
    marginRight: -20,
  },
  labelTotalLines: {
    width: 110,
    marginRight: -25,
  },
  labelIssuance: {
    width: 80,
    marginRight: -12,
  },
})

const ReportInterval = ({ intervalDate }) => (
  <View style={styles.reportIntervalDateContainer}>
    <Text style={styles.labelInterval}>Período:</Text>
    <Text>{intervalDate}</Text>
  </View>
)

const ReportIssuance = ({ transDate }) => (
  <View style={styles.reportIssuanceDateContainer}>
    <Text style={styles.labelIssuance}>Data Emissão:</Text>
    <Text>{transDate}</Text>
  </View>
)

const TotalItems = ({ totalItems }) => {
  return (
    <View style={styles.totalLinesContainer}>
      <Text style={styles.labelTotalLines}>Total de Registros:</Text>
      <Text>{totalItems}</Text>
    </View>
  )
}

const ReportDates = ({ transDate, intervalDate, totalItems }) => (
  <View style={styles.reportDatesContainer}>
    <ReportInterval intervalDate={intervalDate} />
    <TotalItems totalItems={totalItems} />
    <ReportIssuance transDate={transDate} />
  </View>
)

export default ReportDates
