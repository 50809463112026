import React from 'react'
import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'
import IconPopup from 'Utils/components/IconPopup'
import PopupInfoTime from './PopupInfoTime'
/*  Author: Bruno Melo e Luís Mourão
    Type: dumb
	Description: Render buttons to config the asset;				 
  To do list: -				
*/

const PopupBottomButton = styled(Grid.Column)`
  height: 23px;
  right: ${(props) => (props.active ? '5px' : '')};
`
const PopupBottomContainer = styled(Grid)`
  height: 30px;
  color: black;
`

export const PopupBottomButtons = (Props) => (
  <PopupBottomContainer columns={'equal'} padded={true} stretched={false} verticalAlign="middle">
    {Props.icons.map((d, i) => (
      <PopupBottomButton key={'PopupBottomButtonCol' + d.title}>
        {!d.active ? (
          <IconPopup
            key={'PopupBottomButton' + d.title}
            name={d.icone}
            onClick={(e) => d.click(e)}
            title={d.title}
            disabled={d.disabled}
            className="PopupBottomButtons"
            onMouseOver={(e) => (e.target.className = e.target.className + ' large')}
            onMouseOut={(e) => (e.target.className = e.target.className.replace('large', ''))}
          />
        ) : (
          <IconPopup
            key={'PopupBottomButton' + d.title}
            title={d.titleactive ? d.titleactive : d.title}
            name={d.iconeactive ? d.iconeactive : d.icone}
            onClick={(e) => d.clickactive(e)}
            className="PopupBottomButtonsSelected"
            size="large"
          />
        )}
      </PopupBottomButton>
    ))}
    <PopupInfoTime assetId={Props.assetId} mode={Props.mode} />
  </PopupBottomContainer>
)
export default PopupBottomButtons
