import { CustomField } from 'API'
import produce from 'immer'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Header, HeaderContent, HeaderSubheader, Input } from 'semantic-ui-react'
import { Icon } from 'semantic-ui-react'
import { setProfilesProps } from 'Store/actions/profiles-action'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

interface Props {
  label?: string
  index: number
}

const ProfileCustomSection: React.FC<Props> = ({ index }: Props) => {
  const dispatch = useDispatch()

  const customFields: Partial<CustomField>[] = useObjSelector(
    (state) => state.profiles.wip.customFields || state.profiles.current?.customFields || []
  )

  const id = customFields?.[index]?.id ?? ''

  const label = customFields?.[index]?.label ?? ''

  const [isEditing, setIsEditing] = useState(false)

  const isExpanded = useSelector((state: RootStore) => state.profiles.form.sectionState[id]?.isExpanded)

  const sectionCount = useMemo(() => {
    return customFields.filter((field) => field.sectionId === id)?.length ?? 0
  }, [customFields, id])

  const handleLabelClick = () => {
    setIsEditing(true)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCustomField = produce(customFields, (newValue) => {
      newValue[index] = { ...newValue[index], label: event.target.value }
    })

    dispatch(setProfilesProps([[`wip.customFields`, newCustomField]]))
  }

  const handleBlur = () => {
    setIsEditing(false)
  }

  const handleCollapse = () => {
    const newCustomFields = customFields.map((field, i) => {
      if (field.sectionId === id && i < index) {
        return { ...field, sectionId: null }
      }
      if (!field.sectionId && i > index && field.type !== 'section') {
        return { ...field, sectionId: id }
      }
      return field
    })
    dispatch(
      setProfilesProps([
        [`wip.customFields`, newCustomFields],
        [`form.sectionState.${id}.isExpanded`, !isExpanded],
      ])
    )
  }

  const inputWidth = `${(label?.length ?? 1) - 1}ch`

  return (
    <Divider horizontal>
      <Header as="h3">
        <Icon name={isExpanded ? 'angle down' : 'angle right'} onClick={handleCollapse} style={{ cursor: 'pointer' }} />
        <HeaderContent>
          {isEditing ? (
            <Input
              value={label}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onKeyPress={(e: any) => {
                return e.key === 'Enter' && handleBlur()
              }}
              transparent
              autoFocus
              style={{ width: inputWidth, minWidth: '7ch' }} // Adjust width based on content length
            />
          ) : (
            <span onClick={handleLabelClick} style={{ cursor: 'text', marginLeft: '5px' }}>
              {label}
            </span>
          )}
          <HeaderSubheader>{sectionCount} campos</HeaderSubheader>
        </HeaderContent>
      </Header>
    </Divider>
  )
}

export default ProfileCustomSection
