import React, { Fragment, useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalHeader from 'Utils/modals/ModalHeader'
import { Button, Grid, Header, Modal } from 'semantic-ui-react'
import m from 'moment'
import { useTranslation } from 'react-i18next'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from '../../../hooks/useObjSelector'
import {
  saveDataLabelings,
  setDataLabelingsProp,
  setStartPhaseLabel,
  setEndPhaseLabel,
  calcWipCycles,
} from 'Store/actions/dataLabelings-actions'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Brush,
} from 'recharts'
import DataLabelingsMeasuresDropdown from './DataLabelingsMeasuresDropdown'
import moment from 'moment'
import { COMPLEMENTARY_CHART_COLORS } from 'Utils/complementaryColors'
import { MotionDataPoint } from 'interfaces/dataLabelings'
import NoDataMessage from 'Utils/components/NoDataMessage'
import { useVideoPlayer } from 'Components/Video/useVideoPlayer'
import { VideoPlayer } from 'Components/Video/VideoPlayer'
import useFetchData from 'Utils/hooks/useFetchData'

const DataLabelings = (): JSX.Element => {
  useFetchData({ independentFetches: [{ fetchAction: 'assets' }, { fetchAction: 'dataLabelingMotionData' }] })

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const videoElement = useRef(null)

  const label = t('lblline')
  const motionData = useObjSelector((state: RootStore) => state.dataLabelings.motionData || {})
  const motionDataDomainMin = useObjSelector((state: RootStore) => state.dataLabelings.motionDataDomainMin)
  const motionDataDomainMax = useObjSelector((state: RootStore) => state.dataLabelings.motionDataDomainMax)
  const labelings = useObjSelector((state: RootStore) => state.dataLabelings.wip?.labelings || [])
  const selectedMeasures = useObjSelector((state: RootStore) => state.dataLabelings.selectedMeasures[0])
  const phases = useObjSelector((state: RootStore) => state.dataLabelings.wip?.phases || [])
  const startIndex = useSelector((state: RootStore) => state.dataLabelings.startIndex ?? 0)
  const endIndex = useSelector((state: RootStore) => state.dataLabelings.endIndex ?? 0)
  const videoUrlKey = useSelector((state: RootStore) => state.dataLabelings.wip?.videoUrlKey)
  const startTime = useSelector((state: RootStore) => state.dataLabelings.wip?.startTime)
  const currentStoreVideoTime = useSelector((state: RootStore) => state.dataLabelings.currentVideoTime)
  const assets = useObjSelector((state: RootStore) => state.dataLabelings.wip?.assets ?? [])
  const leftAssetName = useSelector((state: RootStore) => (assets?.[0] && state.assets.byId?.[assets?.[0]]?.name) ?? '')
  const rightAssetName = useSelector(
    (state: RootStore) => (assets?.[0] && state.assets.byId?.[assets?.[1] ?? 0]?.name) ?? ''
  )
  const activityName = useObjSelector((state: RootStore) => state.dataLabelings.wip?.activity?.name)

  const labelsTableSetSortBy = useSelector((state: RootStore) => state.dataLabelings.labelsTableSetSortBy)

  // const [minDomainState, setMinDomainState] = useState(0)
  // const [maxDomainState, setMaxDomainState] = useState(0)

  const motionDataEntries = useMemo(() => Object.entries(motionData), [motionData])
  const { playerState, togglePlay, toggleMute, setVideoCurrentTime, ...handleProps } = useVideoPlayer(videoElement)
  const currentVideoTime = playerState?.currentVideoTime

  useEffect(() => {
    if (currentStoreVideoTime && startTime) {
      const videoTime = (new Date(currentStoreVideoTime).getTime() - new Date(startTime).getTime()) / 1000
      setVideoCurrentTime(videoTime)
    }
  }, [startTime, currentStoreVideoTime, setVideoCurrentTime])

  const currentTakeTime = useMemo(() => {
    if (startTime) {
      const time = new Date(startTime).getTime() + currentVideoTime * 1000
      return time
    }
    return null
  }, [startTime, currentVideoTime])

  const setPhaseLabel = (phase: number | null, stage: 'start' | 'end' = 'start') => {
    if (stage === 'start') {
      dispatch(setStartPhaseLabel(phase))
    } else {
      dispatch(setEndPhaseLabel(phase))
    }
    dispatch(calcWipCycles())
  }

  const exportLabels = useCallback(() => {
    // Inicializando com cabeçalho do array.
    let dataArray = [
      ['sep=;'],
      ['aYMean', 'aXMean', 'aZMean', 'aXSpread', 'aYSpread', 'aZSpread', 'timestamp', 'vehicle', 'hand', 'phaseIndex'],
    ]

    // Iterando através de cada smartband em motionData.
    Object.keys(motionData).forEach((smartband) => {
      const hand = smartband === leftAssetName ? 'left' : smartband === rightAssetName ? 'right' : 'machine'
      motionData[smartband].forEach((data: any) => {
        // Convertendo o timestamp para o formato de data ISO.
        const dateTime = new Date(data.timestamp)

        // Encontrando a fase correspondente ao timestamp.
        const phaseLabel = labelings.find(
          (label) => new Date(label.startTime) <= dateTime && dateTime <= new Date(label.endTime)
        ) || { phaseIndex: -1, vehicle: '' }

        // Adicionando linha ao array.
        dataArray.push([
          data.aYMean,
          data.aXMean,
          data.aZMean,
          data.aXSpread,
          data.aYSpread,
          data.aZSpread,
          dateTime.toISOString(),
          phaseLabel.vehicle,
          hand,
          phaseLabel.phaseIndex + 1,
        ])
      })
    })
    return dataArray
  }, [motionData, labelings, leftAssetName, rightAssetName])

  return (
    <>
      <ModalHeader
        name={`${label || t('motion')} (${activityName})`}
        menuButtons={['toggle fullscreen', 'save', 'labelings', 'export']}
        noFilter
        showButtonBar={false}
        onSave={() => {
          labelsTableSetSortBy && labelsTableSetSortBy([{ desc: false, id: 'startTime' }])
          dispatch(calcWipCycles())
          dispatch(saveDataLabelings({}))
        }}
        onLabelingsClick={() => {
          dispatch(setDataLabelingsProp(`showLabelingContent`, false))
        }}
        exportAction={exportLabels}
      />
      <Grid fluid centered padded>
        {videoUrlKey && (
          <VideoPlayer
            videoUrlKey={videoUrlKey}
            videoRef={videoElement}
            playerState={playerState}
            togglePlay={togglePlay}
            toggleMute={toggleMute}
            {...handleProps}
          />
        )}
      </Grid>
      <Grid centered>
        <Header as="h3">{m(currentTakeTime).format('DD/MM/YY HH:mm:ss')}</Header>
      </Grid>
      <Grid centered>
        <Button
          size="massive"
          onClick={() => setPhaseLabel(currentTakeTime)}
          style={{ width: '150px', margin: '10px' }}
        >
          Iniciar
        </Button>
        <Button
          onClick={() => {
            setPhaseLabel(currentTakeTime, 'end')
            setPhaseLabel(currentTakeTime)
          }}
          disabled={!!labelings.at(-1)?.endTime}
          color="blue"
          size="massive"
          style={{ width: '300px', margin: '10px' }}
        >
          Encerrar+Iniciar
        </Button>
        <Button
          onClick={() => setPhaseLabel(currentTakeTime, 'end')}
          size="massive"
          style={{ width: '150px', margin: '10px' }}
        >
          Encerrar
        </Button>
      </Grid>
      {/* Gráfico com medidas do acelerômetro */}
      <br />
      <br />
      <Modal.Content
        style={{
          height: '100%',
          border: 'none',
          boxShadow: 'none',
          width: '100%',
          overflow: 'auto',
          paddingTop: '15px',
        }}
      >
        {motionDataEntries.length === 0 ? (
          <NoDataMessage body="checkTime" />
        ) : (
          motionDataEntries.map((entrie, entrieIndex) => {
            const assetName = entrie[0]
            const assetMotionData: MotionDataPoint[] = Array.isArray(entrie[1]) ? entrie[1] : []
            return (
              <Fragment key={assetName}>
                {entrieIndex === 1 && <br />}
                <Grid style={{ marginLeft: '60px' }}>
                  <Grid.Column width={8} floated="left">
                    {entrieIndex === 0 && <DataLabelingsMeasuresDropdown />}
                  </Grid.Column>
                  <Grid.Column width={7} floated="right" style={{ top: '8px' }}>
                    <Header as="h5" textAlign="right">{`${
                      assetName === leftAssetName ? 'Esquerda' : assetName === rightAssetName ? 'Direita' : 'Máquina'
                    } - ${assetName}`}</Header>
                  </Grid.Column>
                </Grid>
                <div style={{ height: '34vh' }}>
                  <ResponsiveContainer>
                    <LineChart
                      key={Math.round(playerState?.currentVideoTime)}
                      data={assetMotionData}
                      syncId="anyId"
                      syncMethod={(items: any, { activeLabel }: { activeLabel: number }) => {
                        const closestValue = items.reduce(
                          (acc: { difference: number; index: number }, { value }: { value: number }, index: number) => {
                            const diff = Math.abs(activeLabel - value)
                            if (diff < acc.difference) {
                              return { difference: diff, index }
                            } else {
                              return acc
                            }
                          },
                          { difference: Infinity, index: 0 }
                        )
                        return closestValue.index
                      }}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                      onClick={(data) => {
                        if (data?.activeLabel) {
                          dispatch(setDataLabelingsProp(`openSideBar`, true))
                          setPhaseLabel(data.activeLabel as any)
                        }
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="timestamp"
                        minTickGap={20}
                        tickFormatter={(value) => {
                          return m(value).format('DD/MM HH:mm:ss')
                        }}
                        type="number"
                        domain={[
                          (dataMin: number) => {
                            if (entrieIndex === 1) {
                              if (startIndex) return startIndex
                              else {
                                return motionDataDomainMin
                              }
                            } else {
                              if (startIndex !== dataMin) dispatch(setDataLabelingsProp('startIndex', dataMin))
                              return dataMin
                            }
                          },
                          (dataMax: number) => {
                            if (entrieIndex === 1) {
                              if (endIndex) return endIndex
                              else {
                                return motionDataDomainMax
                              }
                            } else {
                              if (endIndex !== dataMax) dispatch(setDataLabelingsProp('endIndex', dataMax))
                              return dataMax
                            }
                          },
                        ]}
                        allowDataOverflow={true}
                      />
                      <YAxis domain={[-6000, 6000]} />
                      <Tooltip
                        labelFormatter={(value) => {
                          return m(value).format('DD/MM HH:mm:ss')
                        }}
                      />
                      <Legend verticalAlign="top" height={36} formatter={(value) => t(String(value))} />
                      {labelings.map((eachLabel) => {
                        return (
                          <Fragment key={eachLabel.startTime as any}>
                            <ReferenceLine
                              x={moment(eachLabel.startTime).valueOf()}
                              stroke={COMPLEMENTARY_CHART_COLORS[phases?.[eachLabel.phaseIndex]?.color ?? 0]}
                              strokeDasharray="5 5"
                            />
                            {eachLabel.endTime && (
                              <ReferenceLine
                                x={moment(eachLabel.endTime).valueOf()}
                                stroke={COMPLEMENTARY_CHART_COLORS[phases?.[eachLabel.phaseIndex]?.color ?? 0]}
                                strokeDasharray="5 5"
                              />
                            )}
                            <ReferenceLine
                              x={1684267260383 + playerState?.currentVideoTime}
                              stroke={COMPLEMENTARY_CHART_COLORS['black']}
                              strokeDasharray="5 5"
                            />
                          </Fragment>
                        )
                      })}
                      {selectedMeasures.map((eachMeasure) => {
                        return (
                          <Line
                            key={eachMeasure.name}
                            type="monotone"
                            dataKey={eachMeasure.name}
                            stroke={eachMeasure.color}
                            fill={eachMeasure.color}
                            activeDot={{ r: 8 }}
                            connectNulls={true}
                            isAnimationActive={false}
                          />
                        )
                      })}
                      {entrieIndex === 0 && (
                        <Brush
                          data={assetMotionData}
                          dataKey={'timestamp'}
                          height={25}
                          stroke="#4b72b2"
                          startIndex={playerState?.currentVideoTime ? Math.round(playerState?.currentVideoTime) : 0}
                          endIndex={playerState?.currentVideoTime ? Math.round(playerState?.currentVideoTime) + 60 : 60}
                          tickFormatter={(value) => {
                            return m(value).format('HH:mm:ss')
                          }}
                        />
                      )}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Fragment>
            )
          })
        )}
      </Modal.Content>
    </>
  )
}

export default DataLabelings
