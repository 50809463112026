import React, { useEffect } from 'react'
import { Divider, Form, StrictStepGroupProps } from 'semantic-ui-react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { RootStore } from 'Store/initialStore'
import { useDispatch, useSelector } from 'react-redux'
import Steps from 'Utils/components/Steps'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import validateForm from 'Utils/validateForm'
import { ZodRawShape, z } from 'zod'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { setDataLabelingWIP } from 'Store/actions/dataLabelings-actions'
import { get, isNil } from 'lodash'
import mergeObjects from 'Utils/mergeObjects'

const Span = styled.span`
  text-transform: capitalize !important;
`

const FormStyled = styled(Form)`
  margin-top: 4.5vh;
  margin-bottom: 6.6vh;
`

interface Value {
  fields?: string[]
  format?: (obj: any) => string
}

interface Props {
  entity: 'dataLabelings' | 'mensurations' | 'activities' | 'reports' | 'profiles' | 'betaDashboards' // Add new entities here
  schemas: z.ZodObject<ZodRawShape>[]
  forms: React.ReactNode[]
  values: Value[]
  widths?: StrictStepGroupProps['widths']
  setProps: Function
  onSave: Function
  onClose?: Function
  fixedHeight?: string
  saveContent?: string
  size?: 'fullscreen' | 'large' | 'small'
  saveOnAnyStep?: boolean
  formatValue?: Function
  wipPath?: string
  currentPath?: string
}

function StepForm({
  entity,
  schemas,
  forms,
  setProps,
  values,
  onSave,
  onClose,
  widths,
  fixedHeight = '88vh',
  saveContent = 'Save',
  size = 'fullscreen',
  saveOnAnyStep,
  formatValue,
  wipPath,
  currentPath,
}: Props) {
  const dispatch = useDispatch()
  const wip = useObjSelector((state: RootStore) => (wipPath ? get(state, wipPath) ?? {} : state[entity].wip))
  const current = useObjSelector((state: RootStore) => (currentPath ? get(state, currentPath) : state[entity].current))
  const stepsItems = useObjSelector((state: RootStore) => state[entity].form.steps.items)
  const currentStep = useSelector((state: RootStore) => state[entity].form.steps.current)
  const errors = useObjSelector((state: RootStore) => state[entity].form.errors)
  const loading = useSelector((state: RootStore) => state[entity].form.loading)
  const open = useSelector((state: RootStore) => state[entity].form.open)
  const isEditing = useSelector((state: RootStore) => !!state[entity].form?.isEditing || !!state[entity].wip?.id)

  useEffect(() => {
    if (!open && typeof onClose === 'function') {
      onClose()
    }
  }, [open, onClose])

  useEffect(() => {
    const handleValidationValues = (value: any): any => {
      if (formatValue) {
        return formatValue(value)
      }
      return value
    }

    validateForm({
      values: handleValidationValues(isEditing ? mergeObjects(current, wip) : wip),
      schema: schemas[currentStep],
      onError: (errors) => {
        const updates = [
          [`form.errors[${currentStep}]`, errors],
          ...(!isEditing ? [[`form.steps.items[${currentStep}].completed`, false]] : []),
          ...(currentStep + 1 < stepsItems.length ? [[`form.steps.items[${currentStep + 1}].disabled`, true]] : []),
        ]
        dispatch(setProps(updates))
      },
      onSuccess: () => {
        const updates = [
          [`form.errors[${currentStep}]`, {}],
          ...(currentStep + 1 < stepsItems.length ? [[`form.steps.items[${currentStep + 1}].disabled`, false]] : []),
        ]
        dispatch(setProps(updates))
      },
    })
  }, [wip, currentStep, stepsItems, dispatch, schemas, setProps, isEditing, current, formatValue])

  function setCurrentStep(next: number) {
    // Voltando para algum passo anterior
    if (next < currentStep) {
      dispatch(setProps([['form.steps.current', next]]))
    } else {
      const currentStepHasErros = Object.keys(errors?.[currentStep] ?? {})?.length > 0
      if (currentStepHasErros) {
        dispatch(setProps([[`form.steps.items[${currentStep}].showFeedback`, true]]))
      } else {
        const formatedValues = values?.[currentStep]?.fields
          ? values?.[currentStep]?.format
            ? //@ts-ignore
              values[currentStep].format(
                values[currentStep].fields?.reduce((acc: { [x: string]: any }, current: string) => {
                  //@ts-ignore
                  acc[current] = wip[current]
                  return acc
                }, {})
              )
            : values[currentStep].fields?.reduce((acc: string, current: string, index: number) => {
                //@ts-ignore
                if (isNil(wip)) return acc
                acc = index ? `${acc} - ${t(wip[current])}` : t(wip[current])
                return acc
              }, '')
          : stepsItems?.[currentStep]?.description
        dispatch(
          setProps([
            ['form.steps.current', next],
            [
              `form.steps.items[${currentStep}]`,
              {
                ...stepsItems[currentStep],
                completed: !isEditing,
                value: formatedValues,
              },
            ],
          ])
        )
      }
    }
  }
  const { t } = useTranslation()

  return (
    <ModalTemplate
      onClose={() => dispatch(setProps([[`form.open`, false]]))}
      open={open}
      size={size}
      header={<Span>{`${!isEditing ? t('toAdd') : t('toEdit')} ${t(entity)}`}</Span>}
      saveContent={saveOnAnyStep || currentStep + 1 === stepsItems.length ? t(saveContent) : t('Next')}
      onSave={() => {
        if (!saveOnAnyStep && currentStep + 1 < stepsItems.length) setCurrentStep(currentStep + 1)
        else {
          if (Object.keys(errors?.[currentStep] || {}).length > 0) {
            dispatch(setProps([[`form.steps.items[${currentStep}].showFeedback`, true]]))
          } else onSave()
        }
      }}
      onCancel={() => {
        dispatch(setProps([[`form.open`, false]]))
        dispatch(setDataLabelingWIP())
      }}
      loading={loading}
      fixedHeight={fixedHeight}
      scrolling
    >
      {stepsItems?.length > 1 && (
        <>
          <Steps
            items={stepsItems}
            currentStep={currentStep}
            onClick={(step: number) => {
              setCurrentStep(step)
            }}
            isEditing={isEditing}
            widths={widths}
          />
          <Divider />
        </>
      )}
      <FormStyled>{forms[currentStep]}</FormStyled>
    </ModalTemplate>
  )
}

export default StepForm
