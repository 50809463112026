import React, { useCallback } from 'react'
import { Input, InputProps } from 'semantic-ui-react'
import moment from 'moment'

const DateTimeLocalInput = ({ value, id, onChange, ...props }: InputProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e, { value: new Date(e.target.value).toISOString(), id })
      }
    },
    [onChange, id]
  )

  return (
    <Input
      required
      fluid
      type="datetime-local"
      id="end"
      name="date-end"
      value={value && moment(value).format('YYYY-MM-DDTHH:mm:ss')}
      min={'2022-01-01'}
      onChange={handleChange}
      step="1"
    />
  )
}

export default DateTimeLocalInput
