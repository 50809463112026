import React, { memo } from 'react'
import { Feed, Message } from 'semantic-ui-react'
import m from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { colorFormat } from 'Utils/colorsFormat'
import { setCurrentNotification, stopEmergency } from 'Store/actions/notification-action'
import { feedbackMessage } from 'Store/actions/notification-action'
import styled from 'styled-components'
import { ItemDetails } from 'Components/Sidebar/Contents/Notification/ItemDetails'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import NotificationDetailsModal from 'Components/Sidebar/Contents/Notification/NotificationDetailsModal'
import NotificationFeedbackModal from 'Components/Sidebar/Contents/Notification/NotificationFeedbackModal'
import TableSpan from 'Components/SidebarMenu/TableSpan'

import DropdownNotificationItemExtra from './DropdownNotificationItemExtra'

function DropdownNotificationItem({ notificationId }) {
  const dispatch = useDispatch()

  const notification = useObjSelector((state) => state.notification.byId[notificationId])
  const assetLat = useSelector((state) => state.measures.byId[notification.assetId]?.info?.coords?.lat)
  const assetLng = useSelector((state) => state.measures.byId[notification.assetId]?.info?.coords?.lng)
  const refMap = useSelector((state) => state.login.refMap)
  const login = useSelector((state) => state.login.email)
  const currentTab = useSelector((state) => state.tables.current.tab)
  const assetNFC = useSelector((state) => state.assets.byId[notification.assetId]?.info?.cardId)
  const assetName = useSelector((state) => state.assets.byId[notification.assetId]?.name)
  const isInMap = currentTab === 'map'

  const sendFeedback = ({ notificationId, assetId, state, scenarioType, details }) => {
    const currentTime = new Date().toISOString()
    let input = {
      id: notificationId,
      [state + 'By']: login,
      [state + 'At']: currentTime,
    }
    if (state === 'confirmed' && notificationId) dispatch(stopEmergency())
    let params = {
      msgId: notificationId,
      source: notification[notificationId]?.source,
      [state === 'confirmed' ? 'clicked' : 'closed']: currentTime,
      assetIds: [assetId],
      title: state === 'confirmed' ? 'Pedido de ajuda recebido!' : 'Atendimento concluído!',
      content: state === 'confirmed' ? 'A ajuda está a caminho.' : `Hora de Conclusão ${currentTime}`,
      level: 3,
      data: {
        level: 3,
        scenarioType,
        details,
      },
    }
    dispatch(feedbackMessage(params, input))
  }

  const fitNotificationBounds = () => {
    dispatch(setCurrentNotification(notificationId))
    if (!notification.modulename && isInMap) {
      if (assetLat && assetLng) refMap.setView([assetLat, assetLng])
    }
  }

  const assetId = notification.assetId
  const help = notification?.type === 'help'
  const emergency = ['evacuation', 'seek_shelter', 'emergency'].includes(notification?.type)
  const momentTime = m(notification.time)
  const styleMessage = notification.closedAt ? { opacity: '0.7' } : {}
  const contrastColor = '#002fb5'

  //Gambiarra para resolver personalizações entre tenants. Necessário repensar isso no futuro
  let assetNameLink
  let splitMsg = []
  let messageStart = ''
  let messageEnd = ''
  if (assetNFC && notification.message.includes(`Usuário ${assetNFC}`)) {
    assetNameLink = `Usuário ${assetNFC}`
  }
  if (!assetNameLink && assetId && notification.message.includes(`Usuário ${assetId}`)) {
    assetNameLink = `Usuário ${assetId}`
  }
  if (assetNameLink) {
    splitMsg = notification.message.split(assetNameLink)
    messageStart = splitMsg[0]
    messageEnd = splitMsg[1]
  }

  return (
    <Message
      key={notification.time}
      size="small"
      style={{
        ...styleMessage,
        backgroundColor: colorFormat(notification.profile?.bgColor || 'red'),
        marginTop: '4px',
      }}
      onClick={() => fitNotificationBounds(notification)}
    >
      <Feed size="large">
        <Feed.Event>
          <FeedLabel
            icon={{
              name: notification.profile?.icon ? notification.profile?.icon?.replace('_', ' ') : 'warning',
              circular: true,
              style: { color: notification.profile?.txtColor || 'black' },
            }}
          />
          <Feed.Content>
            <Feed.Summary style={{ color: notification.profile?.txtColor || 'black' }}>
              <TableSpan value={notification.title} width="75%" padding="0px"></TableSpan>
              <Feed.Date style={{ float: 'right', color: notification.profile?.txtColor || 'black' }}>
                {momentTime.fromNow()}
              </Feed.Date>
            </Feed.Summary>
            <Feed.Extra text style={{ color: notification.profile?.txtColor || 'black', display: 'inline-flex' }}>
              {assetNameLink ? (
                <div>
                  <span style={{ whiteSpace: 'break-spaces' }}>{`${messageStart}`}</span>
                  <ItemDetails
                    notificationId={notification.message}
                    dateRef={momentTime}
                    assetId={assetId}
                    assetName={assetName}
                  />
                  <span style={{ whiteSpace: 'break-spaces' }}>{`${messageEnd}`}</span>
                </div>
              ) : (
                <>
                  <TableSpan value={notification.message} width="450" padding="0px"></TableSpan>
                </>
              )}
            </Feed.Extra>

            {help && (
              <Feed.Extra>
                {!notification.confirmedAt ? (
                  notification.loadingState ? (
                    <span>Carregando...</span>
                  ) : (
                    <span
                      style={{ color: contrastColor, fontStyle: 'italic', cursor: 'pointer' }}
                      onClick={() =>
                        sendFeedback({ notificationId, assetId: notification.assetId, state: 'confirmed' })
                      }
                    >
                      {'Enviar Confirmação de Ajuda'}
                    </span>
                  )
                ) : !notification.closedAt ? (
                  <div style={{ display: 'flex' }}>
                    <TableSpan
                      value={`Confirmação enviada por ${notification.confirmedBy} |`}
                      width="80%"
                      padding="0px"
                    ></TableSpan>
                    {notification.loadingState ? (
                      <span>{'  Carregando...'}</span>
                    ) : (
                      <NotificationFeedbackModal
                        sendFeedback={sendFeedback}
                        notificationId={notificationId}
                        assetId={notification.assetId}
                      />
                    )}
                  </div>
                ) : (
                  <span>
                    Atendimento Concluído em {m(notification.closedAt).format('DD/MM à HH:mm').replace('à', 'às')}
                  </span>
                )}
              </Feed.Extra>
            )}

            {/* Componente que mostra o "concluir" ou "finalizado em" das emergências  */}
            {emergency && (
              <DropdownNotificationItemExtra
                notificationId={notification.id}
                closedAt={notification.closedAt}
                type={notification.type}
                fenceId={notification.location?.[0]}
                contrastColor={contrastColor}
                loadingState={notification.loadingState}
              />
            )}

            <Feed.Meta style={{ display: 'block' }}>
              <Feed.Like style={{ color: notification.profile?.txtColor || 'black' }}>
                {help
                  ? 'Solicitado em ' + momentTime.format('DD/MM à HH:mm').replace('à', 'às')
                  : momentTime.format('DD/MM à HH:mm').replace('à', 'às')}
              </Feed.Like>
              <div style={{ float: 'right' }}>
                {help || emergency ? (
                  <NotificationDetailsModal notificationId={notification.id} help={help} />
                ) : (
                  notification?.ruleId?.split('#')?.[1] || ''
                )}
              </div>
            </Feed.Meta>
          </Feed.Content>
        </Feed.Event>
      </Feed>
    </Message>
  )
}

const FeedLabel = styled(Feed.Label)`
  padding-top: 18px;
`

export default memo(DropdownNotificationItem)
