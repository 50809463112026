import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import fitBounds from 'Utils/fitBounds'
import { RootStore } from 'Store/initialStore'

interface SideFormSearchActionProps {
  id: string
  moduleType: 'fences' | 'refs' | 'routes'
}

const SideFormSearchAction: React.FC<SideFormSearchActionProps> = ({ id, moduleType }) => {
  const { t } = useTranslation()
  const coords = useSelector((state: RootStore) => state.modules[moduleType]?.byId[id]?.geometry?.coordinates)
  const refMap = useSelector((state: RootStore) => state.login.refMap)

  const handleClick = () => {
    if (coords && refMap) fitBounds(moduleType, coords, refMap)
  }

  return (
    <Button
      icon="crosshairs"
      circular
      title={t('searchCenter')}
      style={{
        cursor: 'pointer',
      }}
      onClick={handleClick}
    />
  )
}

export default SideFormSearchAction
