import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import PopupBottomButtons from './PopupBottomButtons'
import { saveFollowMeNow, saveFollowMeStore } from 'Store/actions/login-action'
import { filterMarkers, filterTime } from 'Store/actions/filters-action'
import { setTrue, setFalse } from 'Store/actions/toggle-action'
import { changeHistType } from 'Store/actions/history-action'
import { fetchCategoryMeasures } from 'Store/actions/measures-action'
class PopupBottom extends Component {
  constructor(props) {
    super(props)
    this.state = {
      history: this.props.showHistSlider,
    }
  }

  componentDidMount() {
    const { assetId, followMe } = this.props
    if (followMe) this.setState({ follow: followMe.includes(parseInt(assetId)) })
  }

  saveFollowings = () => {
    const { saveFollowMeNow, email, assetId, saveFollowMeStore } = this.props
    this.setState({ follow: true })
    saveFollowMeStore(assetId)
    saveFollowMeNow(assetId, email)
  }

  removeFollowings = () => {
    const { saveFollowMeNow, email, saveFollowMeStore } = this.props
    this.setState({ follow: false })
    saveFollowMeStore('')
    saveFollowMeNow('', email)
  }
  singleHeatmap = () => {
    // const { filterMarkers, filterTime, setTrue, assetname, infoTime } = this.props
    // //const {history} = this.state;
    // setTrue(['showHeatMap'])
    // filterMarkers(assetname)
    // filterTime({
    //   //solução p/ iniciar no 00:00 sem o moment
    //   start: moment(infoTime).set({ h: '00', m: '00' }).utc().format(),
    //   end: infoTime,
    // })
    // this.setState({ history: true })
    const { setTrue, changeHistType, filterMarkers, assetname } = this.props
    setTrue('showHistoryCalendar')
    changeHistType('heatmap')
    filterMarkers(assetname)
  }

  singleHistory = () => {
    const { filterMarkers, filterTime, setTrue, assetname, infoTime, changeHistType } = this.props
    changeHistType('antpath')
    setTrue(['showHistBar', 'showHistoryCalendar'])
    filterMarkers(assetname)
    filterTime({
      //solução p/ iniciar no 00:00 sem o moment
      start: moment(infoTime).set({ h: '00', m: '00' }).utc().format(),
      end: infoTime,
    })
    this.setState({ history: true })
    /*
    else {
        notificationState.addNotification({
            title: 'Por favor!',
            message:
                'Encerre o histórico atual para filtrar um novo histórico!',
            status: NotificationStatus.WARNING
        })
    }*/
  }

  mountBottomButtons = () => {
    const { t, infoTime, startHist, assetId, loadingCategoryMeasures, fetchCategoryMeasures, currentCategory } =
      this.props
    let canHist = false
    if (infoTime) canHist = new Date(startHist).getTime() > new Date(infoTime).getTime()
    let buttons = []
    // buttons.push({
    //   title: t('PopupSignalsFollow'),
    //   click: this.saveFollowings,
    //   icone: 'map marker',
    //   active: this.state.follow,
    //   titleactive: t('PopupSignalsFollowActive'),
    //   clickactive: this.removeFollowings,
    //   iconeactive: 'map marker alternate',
    // })
    buttons.push({
      title: t('NavButtonsHistory'),
      disabled: canHist,
      click: this.singleHistory,
      icone: 'history',
      active: this.state.history,
      titleactive: t('NavButtonsHistory'),
      clickactive: this.singleHistory,
      iconeactive: 'history',
    })
    buttons.push({
      title: t('Heatmap'),
      disabled: canHist,
      click: this.singleHeatmap,
      icone: 'fire',
      active: this.state.history,
      titleactive: t('NavButtonsHistory'),
      clickactive: this.singleHeatmap,
      iconeactive: 'fire',
    })
    buttons.push({
      title: t('refresh'),
      disabled: loadingCategoryMeasures || !currentCategory,
      click: () => fetchCategoryMeasures(assetId, currentCategory, true),
      icone: 'refresh',
    })
    return buttons
  }

  render() {
    const { infoTime, assetId, mode } = this.props
    let bottomButtons = this.mountBottomButtons()
    let bottomProps = { icons: bottomButtons }
    return <PopupBottomButtons {...bottomProps} infoTime={infoTime} assetId={assetId} mode={mode} />
  }
}

const mapStateToProps = (state, props) => ({
  followMe: state.login.followMe,
  email: state.login.email,
  startHist: state.projects?.[0]?.start,
  infoTime: state.measures.byId[props.assetId]?.info?.time,
  currentCategory: state.measures.byId[props.assetId]?.currentCategory,
  loadingCategoryMeasures: state.measures.loadingCategoryMeasures,
  assetname: state.assets.byId[props.assetId]?.name,
})

const mapDispatchToProps = {
  filterMarkers,
  setTrue,
  setFalse,
  filterTime,
  saveFollowMeNow,
  saveFollowMeStore,
  fetchCategoryMeasures,
  changeHistType,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PopupBottom))
