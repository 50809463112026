import { listReportsByTenant } from 'graphql/queries'
import { API } from 'aws-amplify'
import { createReport, deleteReport, updateReport } from 'graphql/mutations'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'
import axios from 'axios'
import { REPORT_URL, urlBase } from 'Apis/rest'
import { onChangeReport, onDeleteReport } from 'graphql/subscriptions'
import _ from 'lodash'
import getFormattedTime from 'Utils/getFormatedTime'

// import CATEGORIES from 'Utils/categoriesMock'

export const setReportsProp = (prop, value) => ({
  type: 'SET_REPORTS_PROP',
  prop,
  value,
})

export const setReportsProps = (payload, source) => {
  return {
    type: 'SET_REPORTS_PROPS',
    payload,
    source,
  }
}

export const fetchReports = (token) => async (dispatch, getState) => {
  const { empresa: tenant, email } = getState().login

  try {
    if (!token) dispatch({ type: 'FETCH_REPORTS_START', tenant })
    const response = await API.graphql({
      query: listReportsByTenant,
      variables: { tenant, nextToken: token },
    })
    const { items, nextToken } = response.data.listReportsByTenant
    dispatch({
      type: 'FETCH_REPORTS_SUCCESS',
      payload: items,
      email,
    })
    if (nextToken) await dispatch(fetchReports(nextToken))
  } catch (err) {
    console.error('error fetching reports', err)
    dispatch({
      type: 'FETCH_REPORTS_FAILURE',
      err,
    })

    //O erro pode ocorrer em apenas poucos itens do total. Essa lógica faz com que os itens que não tiverem erros apareçam
    const items = err?.data?.listReportsByTenant?.items || []
    const nextToken = err?.data?.listReportsByTenant?.nextToken

    dispatch({
      type: 'FETCH_REPORTS_SUCCESS',
      payload: items.filter((report) => report?.id),
      email,
    })

    if (nextToken) await dispatch(fetchReports(nextToken))
  }
}

export const getReportByCode = (code) => async (dispatch, getState) => {
  const { empresa: tenant, email } = getState().login

  const variables = { tenant, code: { eq: code } }

  try {
    dispatch({ type: 'GET_REPORT_START', variables })
    const response = await API.graphql({
      query: listReportsByTenant,
      variables,
    })
    const { items } = response.data.listReportsByTenant
    dispatch({
      type: 'GET_REPORT_SUCCESS',
      payload: items,
      email,
    })
    dispatch(fetchReportData())
  } catch (err) {
    console.error('error getting report', { err, variables: { tenant, code } })
    dispatch({
      type: 'GET_REPORT_FAILURE',
      err,
    })
  }
}

export const setCurrentReport = ({ id = '', payload = undefined }) => {
  return { type: 'SET_CURRENT_REPORT', id, payload }
}

export const setCurrentReportByWIP = () => {
  return { type: 'SET_CURRENT_WIP_REPORT' }
}

export const setCurrentCodeReport = (reportCode) => (dispatch, getState) => {
  const reports = getState().reports.byId
  const report = Object.values(reports).find((e) => e.code === reportCode)
  if (report?.id) dispatch({ type: 'SET_CURRENT_REPORT', id: report.id })
}

export const mutateReports = () => async (dispatch, getState) => {
  const { empresa, email } = getState().login
  const { filters, ...fields } = getState().reports.wip
  const { id, filters: currentFilters } = getState().reports.current

  const mutation = id ? 'update' : 'create'
  const { createdAt, updatedAt, startTime, endTime, ...rest } = fields
  let input = {
    ...(id && { id }),
    ...rest,
    tenant: empresa,
    ...(mutation === 'create' ? { createdBy: email } : {}),
    createForm: 'platform',
    updatedBy: email,
    updateForm: 'platform',
    ...(startTime ? { startTime: getFormattedTime(startTime) } : {}),
    ...(endTime ? { endTime: getFormattedTime(endTime) } : {}),
    filters: JSON.stringify({ ...currentFilters, ...filters }),
  }

  dispatch({ type: 'SAVE_REPORTS_START', input, mutation })

  try {
    const query = mutation === 'update' ? updateReport : createReport
    const { data } = await API.graphql({ query, variables: { input } })
    dispatch({
      type: 'SAVE_REPORTS_SUCCESS',
      payload: data?.[mutation === 'update' ? 'updateReport' : 'createReport'],
      email,
    })
    notifySuccess()
  } catch (error) {
    console.error({ error, input })
    dispatch({ type: 'SAVE_REPORTS_FAILURE', input, mutation })
    notifyError()
  }
}

export const clearReportForm = () => {
  return { type: 'CLEAR_REPORT_FORM' }
}

export const fetchReportsCategories = () => async (dispatch, getState) => {
  const jwt = localStorage.getItem('id_token')
  const loginProfile = getState().login.perfil
  let res
  try {
    dispatch({ type: 'FETCH_REPORTS_CATEGORIES_START', payload: {}, params: { type: 'categories' } })
    res = await axios
      .get(`${urlBase}/assets/measures?type=categories`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    //const res = CATEGORIES
    dispatch({ type: 'FETCH_REPORTS_CATEGORIES_SUCCESS', payload: { categories: res, loginProfile } })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORTS_CATEGORIES_FAILURE', payload: err })
  }
}

export const fetchReportsCategoryMeasures = (category) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({ type: 'FETCH_REPORTS_MEASURES_START', category })
    res = await axios
      .get(`${urlBase}/assets/measures?category=${category}&type=measuresNames`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORTS_MEASURES_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORTS_MEASURES_FAILURE', payload: err })
  }
}

export const fetchReportData = () => async (dispatch, getState) => {
  const scope = getState().reports.current?.scope
  if (!scope) {
    dispatch({
      type: 'FETCH_REPORTS_MEASURES_HISTORY_SUCCESS',
      payload: [],
    })
    return
  }
  switch (scope) {
    case 'measures':
      dispatch(fetchReportsMeasures())
      break
    case 'rules':
      dispatch(fetchReportRuleMeasures())
      break
    case 'references':
      dispatch(fetchReportRefsResult())
      break
    default:
      break
  }
}

export const fetchReportsMeasures = () => async (dispatch, getState) => {
  const jwt = localStorage.getItem('id_token')

  const current = getState().reports.current
  const wip = getState().reports.wip

  const report = _.mergeWith({}, current, wip, (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      return srcValue
    }
  })

  const { assetProp, assets: reportAssets } = report.filters
  const assetsById = getState().assets.byId

  const assets =
    assetProp === 'type'
      ? Object.values(assetsById).reduce((acc, asset) => {
          if (reportAssets.includes(asset?.info?.type)) {
            acc.push(`${asset.id}`)
          }
          return acc
        }, [])
      : reportAssets

  const startTime = getFormattedTime(report.startTime)
  const endTime = getFormattedTime(report.endTime)
  const category = report.filters.category
  const measures = report.filters.measures
  const fport = report.filters.fport
  const devices = report.filters.devices

  let payload
  let path

  if (typeof category === 'string' || (Array.isArray(category) && category.length === 1)) {
    path = 'fetchTS'
    payload = {
      devices: devices?.length > 0 ? devices : undefined,
      assets: assets?.length > 0 ? assets : undefined,
      category,
      measures,
      startTime,
      endTime,
    }
  } else {
    path = 'getMeasures'
    payload = {
      devices: devices?.length > 0 ? devices : undefined,
      assetIds: assets?.length > 0 ? assets : undefined,
      categories: category,
      fport,
      startTime,
      endTime,
    }
  }

  dispatch({ type: 'FETCH_REPORTS_MEASURES_HISTORY_START', payload })

  try {
    const response = await axios.post(`${REPORT_URL}/${path}`, payload, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })

    if (response.data?.errorType?.includes('ResponseSizeTooLarge')) {
      throw new Error('ResponseSizeTooLarge')
    }

    dispatch({
      type: 'FETCH_REPORTS_MEASURES_HISTORY_SUCCESS',
      payload: response.data,
    })
  } catch (err) {
    console.error(err)
    dispatch({
      type: 'FETCH_REPORTS_MEASURES_HISTORY_FAILURE',
      payload: err.message || err,
    })
    if (err?.response?.data?.message === 'Endpoint request timed out')
      notifyError({ message: 'Tempo limite de consulta excedido. Tente outro intervalo de tempo' })
    else notifyError()
  }
}

export const fetchReportRuleMeasures = () => async (dispatch, getState) => {
  const jwt = localStorage.getItem('id_token')

  const current = getState().reports.current
  const wip = getState().reports.wip

  const report = _.merge({}, current, wip)

  const { selectedAssets, selectedRules, fences } = report.filters

  const startTime = getFormattedTime(report.startTime)
  const endTime = getFormattedTime(report.endTime)

  const payload = {
    selectedAssets,
    selectedRules,
    fences,
    startTime,
    endTime,
  }

  dispatch({ type: 'FETCH_REPORTS_MEASURES_HISTORY_START', payload })

  try {
    const response = await axios.post(`${REPORT_URL}/ruleTriggers`, payload, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })

    if (response.data?.errorType?.includes('ResponseSizeTooLarge')) {
      throw new Error('ResponseSizeTooLarge')
    }

    dispatch({
      type: 'FETCH_REPORTS_MEASURES_HISTORY_SUCCESS',
      payload: response.data,
    })
  } catch (err) {
    console.error(err)
    dispatch({
      type: 'FETCH_REPORTS_MEASURES_HISTORY_FAILURE',
      payload: err.message || err,
    })
    if (err?.response?.data?.message === 'Endpoint request timed out')
      notifyError({ message: 'Tempo limite de consulta excedido. Tente outro intervalo de tempo' })
    else notifyError()
  }
}

export const deleteReports = (ids) => async (dispatch, getState) => {
  dispatch({ type: 'DELETE_REPORTS_START', payload: { ids } })

  const results = []

  for (const id of ids) {
    try {
      await API.graphql({
        query: deleteReport,
        variables: {
          input: {
            id,
          },
        },
      })
      results.push({ status: 'success', id })
    } catch (err) {
      console.error('error:', err)
      results.push({ status: 'failure', id })
    }
  }

  const successes = results.filter((result) => result.status === 'success').map((result) => result.id)
  if (successes.length > 0) {
    dispatch({ type: 'DELETE_REPORTS_SUCCESS', payload: { ids: successes } })
    notifySuccess()
  }

  const failures = results.filter((result) => result.status === 'failure').map((result) => result.id)
  if (failures.length > 0) {
    dispatch({ type: 'DELETE_REPORTS_FAILURE', payload: { ids: failures } })
    notifyError()
  }
}

export const subscribeReport = (type) => (dispatch, getState) => {
  const isDelete = type === 'delete'
  const tenant = getState().login.empresa
  return API.graphql({
    query: isDelete ? onDeleteReport : onChangeReport,
    variables: {
      tenant,
    },
  }).subscribe({
    next: ({ provider, value }) => {
      const payload = value.data?.[isDelete ? 'onDeleteReport' : 'onChangeReport']
      isDelete
        ? dispatch({ type: 'DELETE_REPORT_SUCCESS', payload })
        : dispatch({ type: 'SAVE_REPORT_SUCCESS', payload })
    },
    error: (error) => console.warn('error', error),
  })
}

// Essa ação foi criada para formatar o payload de entrada do endpoint do contexto refs
export const fetchReportRefsResult = (path, payload) => async (dispatch, getState) => {
  const current = getState().reports.current
  const wip = getState().reports.wip

  const report = _.merge({}, current, wip)

  const payload = {
    startTime: report.startTime,
    endTime: report.endTime,
    profileId: report.filters.profileId,
  }

  dispatch(fetchReportResult('fetchGeoModules', payload))
}

export const fetchReportResult = (path, payload) => async (dispatch, getState) => {
  const jwt = localStorage.getItem('id_token')

  dispatch({ type: 'FETCH_REPORTS_MEASURES_HISTORY_START', payload })

  try {
    const response = await axios.post(`${REPORT_URL}/${path}`, payload, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })

    if (response.data?.errorType?.includes('ResponseSizeTooLarge')) {
      throw new Error('ResponseSizeTooLarge')
    }

    dispatch({
      type: 'FETCH_REPORTS_MEASURES_HISTORY_SUCCESS',
      payload: response.data,
    })
  } catch (err) {
    console.error(err)
    dispatch({
      type: 'FETCH_REPORTS_MEASURES_HISTORY_FAILURE',
      payload: err.message || err,
    })
    if (err?.response?.data?.message === 'Endpoint request timed out')
      notifyError({ message: 'Tempo limite de consulta excedido. Tente outro intervalo de tempo' })
    else notifyError()
  }
}
