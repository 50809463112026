import React, { useEffect, useMemo } from 'react'
import ReactTable from 'Utils/reactTable'
import { RootStore } from 'Store/initialStore'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useFetchData from 'Utils/hooks/useFetchData'
import { GenericColumns } from 'Utils/reactTable/components/GenericColumns'
import { deleteReports, fetchReportsCategories, setCurrentReport, setReportsProp } from 'Store/actions/reports-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import m from 'moment'
import TableSpan from 'Components/SidebarMenu/TableSpan'
import { useHistory } from 'react-router-dom'
import { reportsItems, saveReportsItems } from 'interfaces/reports'
import convertTSRelativeTime from 'Utils/convertTSRelativeTime'

const ReportsContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  useFetchData({ independentFetches: [{ fetchAction: 'reportsBeta' }] })

  const allReportsIds = useObjSelector((state: RootStore) => state.reports.allIds)
  const reportsById = useObjSelector((state: RootStore) => state.reports.byId)
  const loading = useSelector((state: RootStore) => state.reports.loading)
  const categoryOptions = useObjSelector((state) => state.reports.categoryOptions)

  useEffect(() => {
    if (!categoryOptions || categoryOptions.length === 0) dispatch(fetchReportsCategories())
  }, [categoryOptions, dispatch])

  const columns = GenericColumns({
    columns: [
      {
        accessor: 'code',
        Cell: ({ value }: any) => {
          return (
            <TableSpan
              value={value}
              width="90px"
              isLink
              onClick={() => {
                value && history.push(`/app/betaReports/${value}`)
              }}
              padding="0px"
            />
          )
        },
      },
      'label',
      'description',
      {
        accessor: 'scope',
        Cell: ({ value }: any) => t(value),
      },
      {
        accessor: 'startTime',
        Cell: ({ value }: any) => {
          if (value.includes('ago')) {
            return convertTSRelativeTime(value)
          }
          return <span>{m(value).format('DD/MM/YY HH:mm:ss')}</span>
        },
      },
      {
        accessor: 'endTime',
        Cell: ({ value }: any) => {
          if (value.includes('now')) {
            return t('now')
          }
          if (value.includes('ago')) {
            return convertTSRelativeTime(value)
          }
          return <span>{m(value).format('DD/MM/YY HH:mm:ss')}</span>
        },
      },
    ],
  })

  const data = useMemo(() => {
    return allReportsIds.map((reportId) => reportsById[reportId])
  }, [allReportsIds, reportsById])

  return (
    <ReactTable
      table={'betaReports'}
      columns={columns}
      data={data}
      newItemClick={() => {
        dispatch(setReportsProp(`wip`, {}))
        dispatch(setReportsProp(`current`, {}))
        dispatch(setReportsProp(`form.steps.items`, reportsItems))
        dispatch(setReportsProp(`form.open`, true))
        dispatch(setReportsProp(`formType`, 'filter'))
      }}
      onEditClick={(id: string) => {
        dispatch(setCurrentReport({ id }))
        dispatch(setReportsProp(`form.steps.items`, saveReportsItems))
        dispatch(setReportsProp(`formType`, 'save'))
        dispatch(setReportsProp(`form.open`, true))
      }}
      showPlaceholders={loading}
      menuButtons={['new', 'edit', 'delete', 'columns', 'export']}
      deleteAction={({ ids }: { ids: string[] }) => {
        dispatch(deleteReports(ids))
      }}
      initialSortBy={{ desc: false, id: 'code' }}
    />
  )
}

export default ReportsContent
