import React from 'react'
import { Icon, Menu, Dropdown } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

function MenuTableDropdown({ icon = '', disabled, id, children, loading = false, type, table }) {
  const { t } = useTranslation()

  return (
    <Dropdown
      item
      style={{ padding: '0px', minWidth: '4em' }}
      key={'BtnTbLeft' + icon}
      disabled={loading ? true : disabled}
      trigger={
        <Menu.Item
          name={t(id)}
          style={{
            padding: '4px',
            minWidth: '4em',
            width: '60px',
          }}
        >
          <Icon
            name={loading ? 'refresh' : icon}
            title={t('btn' + icon.split(' ')[0])}
            style={{ marginBottom: '2px !important' }}
            loading={loading}
          />
          {t('lbl' + icon.split(' ')[0])}
        </Menu.Item>
      }
    >
      {children}
    </Dropdown>
  )
}

export default MenuTableDropdown
