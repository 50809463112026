import TableSpan from 'Components/SidebarMenu/TableSpan'
import { lightColors } from 'Utils/colorsFormat'
import React from 'react'
import {
  useTable,
  useSortBy,
  /* useGlobalFilter,*/ useRowSelect,
  useGroupBy,
  useExpanded,
  TableOptions,
} from 'react-table'
import { Table, Icon, HeaderProps, Label } from 'semantic-ui-react'
import styled from 'styled-components'

interface CustomTableOptions<D extends object> extends TableOptions<D> {
  autoResetExpanded?: boolean
}

interface CarObj {
  carName: string
  phases: string[]
}

const TableHeaderCell = styled(Table.HeaderCell)`
  position: sticky;
  top: -1px;
  z-index: 3;
`

const StyledLabel = styled(Label)`
  background-color: ${(props) => {
    const light = lightColors(props.value)
    return light
  }} !important;
  border-color: ${(props) => lightColors(props.value)} !important;
  color: #fff !important;
`

export const DataLabelingMensurationTable = ({
  dataMensurations,
  uniqueCycles,
}: {
  dataMensurations: any
  uniqueCycles: any
}) => {
  const columns = React.useMemo((): any[] => {
    const cyclesColumns = () =>
      uniqueCycles?.map((cycle: number) => ({
        Header: cycle,
        accessor: 'cycle' + cycle,
        Aggregated: ({ value }: { value: string }) => `${value}`,
      }))
    return [
      {
        Header: 'Op',
        accessor: 'index',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: { value: string }) => `${value}`,
        //@ts-ignore
        Cell: ({ value = '', row }) => {
          return (
            <StyledLabel circular size="tiny" value={row?.original?.color}>
              {value === 'Tot' ? 'T' : `${parseInt(value) + 1}`}
            </StyledLabel>
          )
        },
      },
      {
        Header: 'Operação',
        accessor: 'name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: { value: string }) => `${value}`,
        Cell: ({ value }: { value: string }) => {
          return <TableSpan value={value} width="200px" />
        },
      },
      ...cyclesColumns(),
      {
        Header: 'Min',
        accessor: 'min',
        aggregate: 'sum',
        Aggregated: ({ value }: { value: number }) => `${value}`,
      },
      {
        Header: 'MinWeighed',
        accessor: 'minWeighed',
        aggregate: 'sum',
        Aggregated: ({ value }: { value: number }) => `${value}`,
        align: 'center',
      },
      {
        Header: 'Max',
        accessor: 'max',
        aggregate: 'sum',
        Aggregated: ({ value }: { value: number }) => `${value}`,
      },
      {
        Header: 'V%',
        accessor: 'vpercent',
        aggregate: 'sum',
        Aggregated: ({ value }: { value: number }) => `${value}`,
      },
    ]
  }, [uniqueCycles])

  const data = dataMensurations

  const allTableProps: any = useTable(
    {
      columns,
      data,
      autoResetExpanded: false,
    } as CustomTableOptions<{ [key: string]: any }>,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect
  )

  return (
    <>
      <Table striped color={'blue'} singleLine {...allTableProps.getTableProps()} compact size="small">
        <Table.Header>
          {
            // Loop over the header rows
            allTableProps.headerGroups?.map((headerGroup: HeaderProps) => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers?.map((column: { [key: string]: any }) => (
                    <TableHeaderCell
                      textAlign={column?.align || 'left'}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {
                        // Render the headecolumn.id.startsWith('MediaTgTot')
                        column.render('Header')
                      }
                      {column.isSorted ? column.isSortedDesc ? <Icon name="sort down" /> : <Icon name="sort up" /> : ''}
                      {column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <Icon
                          {...column.getGroupByToggleProps()}
                          name={column.isGrouped ? 'level down' : 'level up'}
                          rotated="clockwise"
                          style={{ marginLeft: '4px' }}
                        />
                      ) : null}
                    </TableHeaderCell>
                  ))
                }
              </Table.Row>
            ))
          }
        </Table.Header>
        {/* Apply the table body props */}
        <Table.Body {...allTableProps.getTableBodyProps()}>
          {
            // Loop over the table rows
            allTableProps.rows?.map((row: { [key: string]: any }) => {
              // Prepare the row for display
              allTableProps.prepareRow(row)
              return (
                // Apply the row props
                <Table.Row {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells?.map((cell: { [key: string]: any }) => {
                      // Apply the cell props
                      return (
                        <Table.Cell
                          textAlign={cell?.column?.align || 'left'}
                          verticalAlign="middle"
                          {...cell.getCellProps()}
                        >
                          {
                            // Render the cell contents
                            cell.isGrouped ? (
                              <>
                                <Icon
                                  name={row.isExpanded ? 'minus square outline' : 'plus square outline'}
                                  {...row.getToggleRowExpandedProps()}
                                />
                                {cell.render('Cell')} ({row.subRows.length})
                              </>
                            ) : cell.isAggregated ? (
                              cell.render('Aggregated')
                            ) : cell.isPlaceholder ? null : (
                              cell.render('Cell')
                            )
                          }
                        </Table.Cell>
                      )
                    })
                  }
                </Table.Row>
              )
            })
          }
        </Table.Body>
      </Table>
    </>
  )
}

export default DataLabelingMensurationTable
