import TableSpan from 'Components/SidebarMenu/TableSpan'
import { FormStepItems } from 'interfaces/formStepItems'
import React, { Fragment } from 'react'
import { Step, Icon, StrictStepGroupProps } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

interface Item extends FormStepItems {}

interface Props {
  items: Item[]
  currentStep: number
  onClick: Function
  isEditing?: boolean
  widths?: StrictStepGroupProps['widths']
}

function Steps({ items = [], currentStep = 0, onClick = () => null, isEditing = false, widths = 4 }: Props) {
  const { t } = useTranslation()

  return (
    <Step.Group widths={widths} size="tiny">
      {items.map((step, i) => {
        const completed = isEditing ? false : step.completed
        const disabled = isEditing ? false : step.disabled
        return (
          <Fragment key={step.id}>
            <Step active={i === currentStep} disabled={disabled} onClick={() => onClick(i)} completed={completed}>
              <Icon name={step.icon} loading={false} disabled={disabled} />
              <Step.Content>
                <Step.Title>{t(step.title)}</Step.Title>
                <Step.Description>
                  {completed ? <TableSpan value={step.value ?? ''} width="200px" /> : t(step.description)}
                </Step.Description>
              </Step.Content>
            </Step>
          </Fragment>
        )
      })}
    </Step.Group>
  )
}

export default Steps
