import { urlBase } from 'Apis/rest'
import axios from 'axios'
/*global localStorage*/

export const saveHeatmap = (data) => ({
  type: 'saveHeatmap',
  payload: data,
})

export const saveHeatmapFilter = (data) => ({
  type: 'saveHeatmapFilter',
  payload: data,
})

export const fetchHeatMap = () => async (dispatch, getState) => {
  const jwt = localStorage.getItem('id_token')

  let start = getState().filters.timeStart
  let end = getState().filters.timeEnd

  start = start ? new Date(start).toISOString() : null
  end = end ? new Date(end).toISOString() : null

  let searchText = getState().filters.searchText
  let filter = searchText ? getState().filters.result : []

  const assetsIds = filter.map((each) => each.id)

  dispatch({ type: 'SET_TRUE', payload: 'showHeatMap' })
  let res
  try {
    dispatch({ type: 'FETCH_HEATMAP_START', payload: { start, end, assetsIds } })
    dispatch({ type: 'SET_TRUE', payload: 'loadingScreen' })
    res = await axios
      .get(`${urlBase}/assets/measures?startTime=${start}&endTime=${end}&assetId=${assetsIds}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({
      type: 'FETCH_HEATMAP_SUCCESS',
      payload: res,
    })
  } catch (err) {
    console.error(err)
  }
  dispatch({ type: 'SET_FALSE', payload: ['loadingScreen', 'showHistoryCalendar'] })
}
