/////////////////////////////////////////
// this is all just for the upload button
// copying from TenantLogo.jsx

export const uploadImage = (data) => {
  const fd = new FormData()
  // this is the actual image data
  fd.append('File', data)

  return fd
}

export const handleSelect = (ev) => {
  const file = ev.target.files && ev.target.files[0]
  if (!file) return
  if (!/^image\//.test(file.type)) {
    alert('Imagem inválida')
    return
  }
  //uploadImage(file)
  return file
}
export const handleEdit = (fileRef) => {
  fileRef.current && fileRef.current.click()
}
