import React from 'react'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
var cor = 'rgb(121, 228, 0)'
const rawIconPopup = (Props) => <Icon {...Props} />

export const IconPopup = styled(rawIconPopup)`
  transition: 0.3s ease height;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    color: ${cor};
  }
  &.PopupUpButtonsSelected {
    transition: 0.3s ease height;
    color: ${cor};
  }
  &.PopupBottomButtonsSelected {
    color: ${cor};
  }
`
export default IconPopup
