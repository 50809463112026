import React, { useState } from 'react'
import { Segment, Modal, Header, Dropdown, Button } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ModalFilter from 'Utils/modals/ModalFilter.js'
import MenuTableIcons from 'Utils/reactTable/components/MenuTableIcons.jsx'
import styled from 'styled-components'

const ButtonBar = styled(Button)`
  padding: 0 !important;
  margin: 0;
  position: absolute;
  top: 0px;
  height: 5px !important;
  width: 98vw;
  z-index: 1;
`

const ModalHeader = ({
  dashboard = null,
  dashboardOptions = [],
  exportAction,
  insightActions = { setCarModel: () => null, setTime: () => null },
  insightActionsValues = {},
  // hiddenButtons,
  name,
  noFilter,
  fetchAction = () => null,
  menuButtons,
  showButtonBar = true,
  onSave,
  onLabelingsClick = () => {},
  menuTableIconsOptions = [],
  buttonsProps = {},
}) => {
  const empresa = useSelector((state) => state.login.empresa)
  const dashboardId = useSelector((state) => state.dashboards.currentId)
  const { t } = useTranslation()
  const [show, setShow] = useState(true)

  return (
    <>
      {showButtonBar && (
        <ButtonBar
          title={show ? 'Ocultar Cabeçalho' : 'Mostrar Cabeçalho'}
          icon={show ? 'angle up' : 'angle down'}
          onClick={() => setShow(!show)}
        />
      )}
      {show && (
        <Modal.Header style={{ padding: '0' }}>
          <Segment.Group horizontal style={{ border: 'none', boxShadow: 'none', padding: '0' }}>
            <Segment>
              <Header style={{ textTransform: 'capitalize' }}>
                {dashboard ? (
                  <Dropdown
                    disabled={empresa === 'Stellantis'}
                    selection
                    options={dashboardOptions}
                    value={dashboardId}
                  />
                ) : (
                  <span style={{ marginTop: '13px', display: 'block' }}>{t(name)}</span>
                )}
              </Header>
            </Segment>
            {!noFilter && <ModalFilter />}

            <MenuTableIcons
              dashboard={dashboard}
              t={t}
              exportAction={exportAction}
              insightActions={insightActions}
              insightActionsValues={insightActionsValues}
              refresh={true}
              // hiddenButtons={hiddenButtons}
              fetchAction={fetchAction}
              menuButtons={menuButtons}
              table={name}
              onSave={onSave}
              onLabelingsClick={onLabelingsClick}
              options={menuTableIconsOptions}
              buttonsProps={buttonsProps}
            />
          </Segment.Group>
        </Modal.Header>
      )}
    </>
  )
}

export default ModalHeader
