import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const borderColor = '#B2BEB5'
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#B2BEB5',
    backgroundColor: '#A9A9A9',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  rate: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
})

const ReportTableHeader = ({ columns, headerStyles }) => (
  <View style={styles.container}>
    {columns.map((column) => (
      <Text key={column} style={headerStyles[column]}>
        {column}
      </Text>
    ))}
  </View>
)

export default ReportTableHeader
