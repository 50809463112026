import { NotificationProfile } from 'API'

type Sound = {
  playing: boolean
  src: string
  permanent: boolean
  duration?: number
}

function formatSound(profile: NotificationProfile): Sound {
  let sound: Sound = {
    playing: true,
    permanent: true,
    src: './sos.mp3',
  }

  const sounds = {
    alert: './alert-sound-effect.mp3',
    alarm: './alarm.mp3',
    sos: './sos.mp3',
  }

  if (profile.sound && profile.sound !== 'none') {
    if (profile.screenTime !== 'permanent') {
      sound.duration = convertScreenTimeToNumber(profile.screenTime)
      sound.permanent = false
    }
    sound.src = sounds[profile.sound as keyof typeof sounds] ?? './sos.mp3'
  }

  return sound
}

export function convertScreenTimeToNumber(input: string): number {
  const match = input.match(/^_(\d+)s$/)
  return match ? parseInt(match[1], 10) : NaN
}

export default formatSound
