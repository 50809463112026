import { DropdownFieldChangeEvent } from 'interfaces/DropdownFieldTypes'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownProps, Form, SemanticWIDTHS } from 'semantic-ui-react'

export interface DropdownFieldProps {
  label: string
  onChange: DropdownFieldChangeEvent
  options: (DropdownProps | string)[]
  value?: (string | number) | (string | number)[]
  //Opcionais
  clearable?: boolean
  disabled?: boolean
  error?: boolean[]
  key?: string
  loading?: boolean
  multiple?: boolean
  name?: string
  noResultsMessage?: string
  placeholder?: string
  required?: boolean
  search?: boolean
  showFeedback?: boolean
  sort?: boolean
  width?: SemanticWIDTHS
  upward?: boolean
  lazyLoad?: boolean
  allowAdditions?: boolean
  additionLabel?: string
}

const DropdownField: React.FC<DropdownFieldProps> = ({
  key,
  sort = true,
  clearable = true,
  search = true,
  upward = false,
  lazyLoad = true,
  ...fields
}) => {
  const { t } = useTranslation()

  const options = useMemo(() => {
    const mappedOptions = fields.options?.map((e: any) => {
      if (typeof e === 'string') {
        const tText = t(e)
        const formatText = tText.charAt(0).toUpperCase() + tText.slice(1)
        return { key: e, value: e, text: formatText }
      }
      return { ...e, text: e.text ? t(e.text) : e.text }
    })

    if (sort && mappedOptions) {
      mappedOptions.sort((a: { text: string; value: string }, b: { text: string; value: string }) => {
        if (a.value === '*') return 1
        if (b.value === '*') return -1
        return a.text.localeCompare(b.text)
      })
    }

    return mappedOptions
  }, [fields.options, t, sort])

  return (
    <Form.Field
      // key={field.loading ? 'loading' + key : key}
      name={fields.name}
      width={fields.width}
      required={fields.required}
      error={fields.showFeedback && !!fields.error?.[0]}
      disabled={fields.disabled}
    >
      {/* <Transition transitionOnMount={true} animation="fade right" duration="1700"> */}
      <label>{t(fields.label)}</label>
      {/* </Transition> */}
      <Dropdown
        selection
        fluid
        placeholder={fields.loading ? 'Carregando opções, aguarde...' : fields.placeholder}
        onChange={fields.onChange}
        options={options}
        noResultsMessage={fields.loading ? 'Carregando opções, aguarde...' : fields.noResultsMessage}
        loading={fields.loading}
        lazyLoad={lazyLoad}
        value={fields.value}
        multiple={fields.loading ? true : fields.multiple}
        clearable={clearable}
        search={search}
        upward={upward}
        selectOnBlur={false}
        allowAdditions={fields.allowAdditions}
        additionLabel={fields.additionLabel}
      />
    </Form.Field>
  )
}

export default DropdownField
