/*  Author: Felipe Pegado
    Type: reducer
    Description: reducer from alarms store
    To do list:
*/

import initialStore from '../initialStore'
import { notificationState, NotificationStatus } from 'Utils/notifications'

const showSuccess = () =>
  notificationState.addNotification({
    title: 'Sucesso',
    message: 'Item criado com sucesso',
    status: NotificationStatus.SUCCESS,
  })

const showError = () =>
  notificationState.addNotification({
    title: 'Erro ao criar item',
    message: 'Aconteceu um problema, tente novamente mais tarde',
    status: NotificationStatus.ERROR,
  })

export default function messagesReducer(state = initialStore.messages, { type, id, payload }) {
  switch (type) {
    case 'FETCH_MESSAGES_SUCCESS':
      const byId = payload.reduce((dataById, item) => ({ ...dataById, [item.id]: item }), {})
      return { byId, allIds: Object.keys(byId) }
    case 'FETCH_MESSAGES_FAILURE':
      return state
    case 'CREATE_MESSAGE_START':
      return { ...state, loading: true }
    case 'CREATE_MESSAGE_SUCCESS':
      showSuccess()
      return {
        ...state,
        byId: { ...state.byId, [payload.id]: payload },
        allIds: [...state.allIds, payload.id],
        loading: false,
        success: true,
      }
    case 'CREATE_MESSAGE_FAILURE':
      showError()
      return { ...state, hasError: true, loading: false, success: false }
    case 'UPDATE_MESSAGE_START':
      return { ...state, loading: true }
    case 'UPDATE_MESSAGE_SUCCESS':
      showSuccess()
      return { ...state, byId: { ...state.byId, [id]: payload }, loading: false, success: true }
    case 'DELETE_MESSAGE_SUCCESS':
      return { ...state, allIds: state.allIds.filter((i) => !id.includes(i)), loading: false, success: true }
    case 'UPDATE_MESSAGE_FAILURE':
      showError()
      return { ...state, hasError: true, loading: false, success: false }
    case 'CLEAR_MESSAGE_STATE':
      return { ...state, loading: undefined, hasError: undefined, success: undefined }
    default:
      return state
  }
}
