import initialStore from 'Store/initialStore'
import set from 'immutable-set'
import produce from 'immer'
import { omit } from 'lodash'

export default function activitiesReducer(state = initialStore.activities, { type, payload, prop, value, id }) {
  switch (type) {
    case 'FETCH_ACTIVITIES_START':
      return { ...state, loading: true }
    case 'FETCH_ACTIVITIES_SUCCESS':
      return produce(state, (newState) => {
        if (!payload?.items) return state
        const { items } = payload
        newState.allIds = items.map((item) => item.id)
        newState.byId = items.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
        newState.loading = false
      })
    case 'FETCH_ACTIVITIES_FAILURE':
      return { ...state, loading: false }

    case 'SET_ACTIVITIES_PROP':
      return set(state, prop, value)

    case 'SET_ACTIVITIES_PROPS':
      return activitiesReducer(state, payload)

    case 'MUTATE_ACTIVITY_START':
      return produce(state, (newState) => {
        newState.form.loading = true
      })

    case 'MUTATE_ACTIVITY_SUCCESS':
      return produce(state, (newState) => {
        if (!payload?.id) return state
        if (!newState.allIds.includes(payload.id)) newState.allIds.push(payload.id)
        newState.byId[payload.id] = payload
        newState.form.loading = false
        newState.form.open = false
      })

    case 'MUTATE_ACTIVITY_FAILURE':
      return produce(state, (newState) => {
        newState.form.loading = false
      })

    case 'SET_ACTIVITY_WIP':
      return produce(state, (newState) => {
        newState.wip = newState.byId[id] || {}
      })

    case 'DELETE_ACTIVITIES_START':
      return { ...state, loading: true }

    case 'DELETE_ACTIVITIES_SUCCESS':
      if (!id) return state
      return produce(state, (newState) => {
        newState.allIds = newState.allIds.filter((each) => each !== id)
        newState.byId = omit(newState.byId, [id])
        newState.loading = false
      })

    case 'DELETE_ACTIVITIES_FAILURE':
      return { ...state, loading: false }

    case 'CLEAR_ACTIVITY_FORM':
      return produce(state, (newState) => {
        newState.form.steps.items = newState.form.steps.items.map((item, index) => {
          return { ...item, disabled: !(index === 0), completed: false, showFeedback: false }
        })
        newState.form.steps.current = 0
      })

    default:
      return state
  }
}
