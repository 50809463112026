import React, { useState } from 'react'
import { FeatureGroup } from 'react-leaflet'
import { EditControl as Edit } from 'Libs/react-leaflet-draw/src/index'
import { useDispatch, useSelector } from 'react-redux'
//import { useTranslation } from 'react-i18next'
import {
  setCurrentModule,
  insertStarted,
  initiateModuleUpdate,
  initiateModuleInsert,
  insertStopped,
} from 'Store/actions/modules-action'
import { setTrue } from 'Store/actions/toggle-action'
import fitBounds from 'Utils/fitBounds'
import { EditingModule } from './EditingModule'
import RenderGeoModules from './RenderGeoModules'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import confirmClose from 'Components/sideForm/utils/confirmClose'
import useEntitySelector from 'Utils/hooks/useEntitySelector'

const getModuleField = (data: any) => {
  let moduleField: 'fences' | 'routes' | 'refs'
  const geoData = data.layer.toGeoJSON()
  switch (geoData.geometry.type) {
    case 'Point':
      moduleField = data.layer._mRadius ? 'fences' : 'refs'
      break
    case 'LineString':
      moduleField = 'routes'
      break
    default:
      // Polygon | MultiPolygon
      moduleField = 'fences'
      break
  }
  return moduleField
}

const controls = {
  edit: {
    remove: false,
    edit: false,
  },
  draw: {
    showRadius: true,
    marker: true,
    circle: {
      //showRadius: true,
      metric: ['km', 'm'],
    },
    rectangle: {
      //showArea: true,
      metric: ['ha'],
    },
    polygon: {
      showLength: true,
      metric: ['km', 'm'],
    },
    circlemarker: false,
    polyline: {
      showLength: true,
      metric: ['km', 'm'],
    },
  },
}

interface GeoFeaturesProps {
  refMap: any
  show: boolean
  statsbarId: string
}

const GeoFeatures = ({ refMap, show, statsbarId }: GeoFeaturesProps) => {
  const dispatch = useDispatch()
  const modules = useObjSelector((state: RootStore) => state.modules)
  const wip = useSelector((state: RootStore) => state.modules.wip)
  const type = useEntitySelector('modules', 'type')
  const [dataOnClick, setDataOnClick] = useState()

  // Esse evento é acionado ao criar ou clicar em um módulo
  const geoEvent = (data: any, id?: string) => {
    const moduleField = getModuleField(data)
    if (!wip.id || confirmClose({ wip, dispatch, type: moduleField })) {
      if (data.type === 'click') {
        dispatch(initiateModuleUpdate({ id, type: moduleField }))
      } else {
        dispatch(initiateModuleInsert({ type: moduleField }))
      }
      dispatch(setCurrentModule(id, moduleField))
      dispatch(setTrue('showStatsBar'))
      setDataOnClick(data)
    } else {
      dispatch(insertStopped({ moduleType: moduleField }))
      return null // temporário para não abrir o sideForm de módulo
    }
    return moduleField
  }

  const onGeoCreated = (data: any) => {
    const moduleField = geoEvent(data, wip.id)
    if (!moduleField) {
      // Processo foi cancelado para não perder sideForm com alterações
      refMap.removeLayer(data.layer)
      return
    }
    let geojson = data.layer.toGeoJSON()

    fitBounds(moduleField, geojson.geometry.coordinates, refMap)
    if (data.layer._mRadius) geojson.geometry.mRadius = data.layer._mRadius

    dispatch(
      insertStarted({
        moduleType: moduleField,
        leafletId: data.layer._leaflet_id,
        layerType: data.layerType,
        refCoords: data.layer._latlng,
        geoEventStatus: 'Created',
        geoJson: geojson,
      })
    )
    refMap.removeLayer(data.layer)
  }

  return (
    <FeatureGroup>
      <RenderGeoModules modules={modules} geoEvent={geoEvent} />
      {show && (
        <>
          {type && ['routes', 'fences'].includes(type) && modules[type].geoWip?.editMode && (
            <EditingModule moduleType={type} editId={statsbarId} dataOnClick={dataOnClick} refMap={refMap} />
          )}
          <Edit position="topright" onCreated={onGeoCreated} edit={controls.edit} draw={controls.draw}></Edit>
        </>
      )}
    </FeatureGroup>
  )
}

export default GeoFeatures
