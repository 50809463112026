/*  
  Author: Luís Mourão
  Type: Reducer
  
  Description: 
    - 
    - 
  TODO: 
*/

import initialStore from 'Store/initialStore'

export default function locationHistReducer(state = initialStore.locationHist, { type, payload }) {
  switch (type) {
    case 'FETCH_LOCATION_HIST_SUCCESS':
      return payload
    default:
      return state
  }
}
