import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, Label, SemanticWIDTHS } from 'semantic-ui-react'
import moment from 'moment'

export interface InputFieldProps {
  label: string
  onChange: (
    event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement | HTMLElement, Event>,
    data: any
  ) => void
  value?: string | number
  disabled?: boolean
  error?: string[]
  inputType?: string
  key?: string
  max?: number
  maxLength?: number
  min?: number
  name?: string
  placeholder?: string
  required?: boolean
  showFeedback?: boolean
  width?: SemanticWIDTHS
  id?: string
}

const InputField: React.FC<InputFieldProps> = ({ key, placeholder = '', ...fields }) => {
  const { t } = useTranslation()

  return (
    <Form.Field
      key={key}
      required={fields.required}
      error={fields.showFeedback && !!fields.error?.[0]}
      width={fields.width}
    >
      <label>{t(fields.label)}</label>
      <Input
        id={fields.id}
        name={fields.name}
        placeholder={t(placeholder)}
        value={
          (fields.inputType === 'datetime-local'
            ? fields.value && moment(fields.value).format('YYYY-MM-DDTHH:mm:ss')
            : fields.value) ?? ''
        }
        onChange={fields.onChange}
        fluid={true}
        type={fields.inputType}
        maxLength={fields.maxLength}
        min={fields.min}
        max={fields.max}
        disabled={fields.disabled}
      />
      {fields.showFeedback && fields.error && fields.error[0] !== 'Required' && (
        <Label color="red" pointing>
          {/* @ts-ignore */}
          {t(fields.error[0])}
        </Label>
      )}
    </Form.Field>
  )
}

export default InputField
