import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Dropdown, Icon, Menu, SemanticICONS } from 'semantic-ui-react'
import {
  addNewDashboardPanel,
  mutateBetaDashboard,
  openDashboardForm,
  setBetaDashboardProps,
} from 'Store/actions/betaDashboards-action'
import styled, { css, keyframes } from 'styled-components'
import useStoreSelector from 'Utils/hooks/useStoreSelector'

const StyledSpan = styled.span`
  min-width: 10px;
  max-width: 67px;
  display: inline-block;
  padding: 0px 5px 10px 5px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

// Define a animação pulse
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.382);
  }
  100% {
    transform: scale(1);
  }
`

// Cria um styled Icon que aplica a animação condicionalmente
const PulsingIcon = styled(Icon)<{ animation?: string }>`
  margin-bottom: 2px !important; // Mantém o estilo existente
  ${({ animation }) =>
    animation === 'pulsing' &&
    css`
      // Usando a função css
      animation: ${pulseAnimation} 1s infinite; // Aplica a animação se pulsing for verdadeiro
    `}
`

type DashboardsButtonsProps = {}

const DashboardsMenuButtons: React.FC<DashboardsButtonsProps> = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const addAnimationOn = useStoreSelector((state) => state.betaDashboards.addAnimationOn)

  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const preBuiltButtons = useMemo(() => {
    return [
      {
        id: 'new',
        icon: 'add',
        disabled: false,
        title: 'Novo',
        label: 'add',
        options: [
          {
            id: 'panel',
            text: 'Painel',
            t: true,
            icon: 'clipboard list',
            disabled: addAnimationOn,
            action: () => {
              dispatch(addNewDashboardPanel())
              // Move scroll to the bottom of the dashboard content
              // Aguarda um pequeno tempo para garantir que o novo conteúdo foi adicionado ao DOM
              setTimeout(() => {
                const container = document.querySelector('.dash-content-container')
                if (container) {
                  container.scrollTop = container.scrollHeight
                }
              }, 200)
            },
          },
          {
            id: 'lineChart',
            text: 'Dashboard',
            t: true,
            icon: 'chart line',
            // selected: currentTableView === 'lineChart',
            action: () => {
              dispatch(openDashboardForm({ isEditing: false }))
            },
          },
        ],
      },
      {
        id: 'refresh',
        icon: 'refresh',
        title: 'Recarregar',
        label: 'Recarregar painéis do dashboard',
        options: [
          {
            id: 'now',
            text: 'Agora',
            action: () => {
              dispatch(setBetaDashboardProps([['refreshedAt', new Date().toISOString()]]))

              // Clear any existing interval when "Agora" is selected
              if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
              }
            },
          },
          {
            id: '1min',
            text: '1 minuto',
            //icon: 'check',
            disabled: true,
            action: () => {
              //Iniciando atualização

              // Prevent multiple intervals
              if (intervalRef.current) {
                //Intervalo já está ativo
                return
              }

              // Dispatch immediately and then every recorrence
              dispatch(setBetaDashboardProps([['refreshedAt', new Date().toISOString()]]))
              intervalRef.current = setInterval(() => {
                dispatch(setBetaDashboardProps([['refreshedAt', new Date().toISOString()]]))
                //Dashboard atualizado automaticamente
              }, 60000) // 1 minuto
            },
          },
          {
            id: 'off',
            text: 'Desligado',
            disabled: true,
            icon: null,
            action: () => {
              //Cancelando atualizações automáticas
              if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
                //Atualizações automáticas canceladas
              } else {
                //Nenhum intervalo ativo para cancelar
              }
            },
          },
        ],
      },
      {
        id: 'save',
        icon: 'save',
        action: () => {
          dispatch(mutateBetaDashboard())
        },
        disabled: false,
      },
      {
        id: 'edit',
        icon: 'edit',
        action: () => {
          dispatch(openDashboardForm({ isEditing: true }))
        },
      },
      {
        id: 'delete',
        icon: 'delete',
        action: () => {
          dispatch(
            setBetaDashboardProps([
              ['form.open', true],
              ['form.type', 'delete'],
            ])
          )
        },
        disabled: false,
      },
    ]
  }, [dispatch, addAnimationOn])

  return (
    <Menu icon="labeled" size="mini" style={{ height: '44px', width: `${preBuiltButtons.length * 60}px` }}>
      {preBuiltButtons.map((btn) => {
        if (btn.options) {
          return (
            <Dropdown
              key={btn.id}
              item
              style={{ padding: '0px', minWidth: '4em' }}
              disabled={btn.disabled}
              trigger={
                <Menu.Item
                  name={t(btn.label)}
                  style={{
                    padding: '4px',
                    minWidth: '4em',
                    width: '60px',
                  }}
                >
                  <PulsingIcon
                    name={btn.icon as SemanticICONS}
                    title={t(btn.label || 'btn' + btn.icon.split(' ')[0])}
                    style={{ marginBottom: '2px !important' }}
                    animation={addAnimationOn && btn.id === 'new' ? 'pulsing' : undefined}
                  />
                  {btn.title}
                </Menu.Item>
              }
            >
              <Dropdown.Menu style={{ maxHeight: '400px', overflow: 'auto' }}>
                {btn.options.map((o) => {
                  const { action, ...itemProps } = o
                  return (
                    <Dropdown.Item
                      {...itemProps}
                      key={o.id}
                      icon={o.icon}
                      text={o.text}
                      disabled={o.disabled}
                      onClick={() => {
                        o.action()
                      }}
                    />
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          )
        } else {
          const title = t('btn' + btn.icon.split(' ')[0])
          const label = t(btn.label ?? 'lbl' + btn.icon.split(' ')[0])
          return (
            <Menu.Item
              name={btn.id}
              key={btn.id}
              disabled={btn.disabled}
              onClick={btn.action}
              title={title}
              style={{ padding: '4px', minWidth: '4em', width: '60px' }}
            >
              <Icon name={btn.icon as SemanticICONS} title={title} style={{ marginBottom: '2px !important' }} />
              <StyledSpan style={{ fontSize: label.length > 8 ? 'smaller' : 'x-small' }}>{label}</StyledSpan>
            </Menu.Item>
          )
        }
      })}
    </Menu>
  )
}

export default DashboardsMenuButtons
