import * as z from 'zod'

const stepOneSchema = z.object({
  id: z.string().optional(),
  activityId: z.string(),
  shift: z.string().min(1, 'form.error.min').max(30, 'form.error.max'),
  description: z.string().optional().nullable(),
  taktTime: z.number().optional().nullable(),
  targetCycleTime: z.number().optional().nullable(),
})

export type StepOne = z.infer<typeof stepOneSchema>

export default stepOneSchema
