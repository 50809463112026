import React, { useEffect } from 'react'
//import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import ReactTable from 'Utils/reactTable'
import { RootStore } from 'Store/initialStore'
import { NotificationsColumns } from './columns'
import { useTranslation } from 'react-i18next'
import { fetchNotificationByDateRange } from 'Store/actions/tables-action'
import { convertRelativeDateToAbsolute, RelativeDate } from 'Utils/date'
import { saveColumnsPreference } from 'Store/actions/login-action'

const tableName = 'notifications'
const TIME_RANGE_DEFAULT = RelativeDate.TODAY

export const NotificationsContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const byId = useSelector((state: RootStore) => state.tables.byId.notifications.byId, shallowEqual)
  const allIds = useSelector((state: RootStore) => state.tables.byId.notifications.allIds, shallowEqual)
  const loading = useSelector((state: RootStore) => state.tables.byId.notifications.loading, shallowEqual)

  useEffect(() => {
    dispatch(fetchNotificationByDateRange(...Object.values(convertRelativeDateToAbsolute(TIME_RANGE_DEFAULT))))
  }, [dispatch])

  const fetchAction = (relativeDate: RelativeDate) => {
    const { start, end } = convertRelativeDateToAbsolute(relativeDate)
    dispatch(fetchNotificationByDateRange(start, end))
  }

  const hiddenColumns = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns
  )

  const groupedValuesColumns = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = React.useMemo(() => NotificationsColumns(t), [t])

  const rows = allIds

  const data = React.useMemo(() => rows.map((id: string) => byId[id]), [rows, byId])

  return (
    <ReactTable
      table={tableName}
      tableLabel={t('notifications')}
      hiddenColumns={hiddenColumns}
      columns={columns}
      data={data}
      // menuButtons={['export', 'columns', 'calendar']}
      fetchAction={fetchAction}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      initialSortBy={{ desc: false, id: 'label' }}
      showPlaceholders={loading}
    />
  )
}
