import React from 'react'
import { Tooltip, Legend, PieChart, Pie, Cell } from 'recharts'

export const PeoplePieGraph = ({
  data,
  color = 'blue',
  colors = ['#1C9BDB', '#FFBD33', '#E24445'],
  width = 400,
  height = 400,
}) => {
  const dataUsed = data || [
    { name: 'Online', value: 40 },
    { name: 'Sem Rede', value: 3 },
    { name: 'Logout Manual', value: 1 },
  ]

  const COLORS = colors ? colors : ['#4C5762', color]

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, /*percent, index,*/ value } = props
    //console.log('props', props)
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: 'small', fontWeight: '600' }}
      >
        {
          //`${(percent * 100).toFixed(0)}%`}
          value
        }
      </text>
    )
  }

  return (
    <div style={{ margin: '0 auto', width: '400px', textAlign: 'center', border: '1px #cecece solid' }}>
      <h2>Pessoas na planta</h2>
      <PieChart nameKey="name" width={width} height={height}>
        <Pie
          data={dataUsed}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={height / 3}
          fill="#8884d8"
          dataKey="value"
          nameKey="name"
          minAngle={15}
        >
          {dataUsed.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry.color ? entry.color : COLORS[index % COLORS.length]} />
          })}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </div>
  )
}
