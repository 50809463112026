import { isEqual } from 'lodash'
import { MapControl, withLeaflet } from 'react-leaflet'
// @ts-ignore
import 'leaflet-draw' // eslint-disable-line
import leaflet, { Map, Control } from 'leaflet'

const eventHandlers: Record<string, string> = {
  onEdited: 'draw:edited',
  onDrawStart: 'draw:drawstart',
  onDrawStop: 'draw:drawstop',
  onDrawVertex: 'draw:drawvertex',
  onEditStart: 'draw:editstart',
  onEditMove: 'draw:editmove',
  onEditResize: 'draw:editresize',
  onEditVertex: 'draw:editvertex',
  onEditStop: 'draw:editstop',
  onDeleted: 'draw:deleted',
  onDeleteStart: 'draw:deletestart',
  onDeleteStop: 'draw:deletestop',
}

interface EditControlProps {
  onCreated?: (e: any) => void
  onMounted?: (element: any) => void
  draw?: {
    polyline: any | boolean
    polygon: any | boolean
    rectangle: any | boolean
    circle: any | boolean
    marker: any | boolean
  }
  edit?: {
    edit: any | boolean
    remove: any | boolean
    poly?: any | boolean
    allowIntersection?: boolean
  }
  position?: 'topright' | 'topleft' | 'bottomright' | 'bottomleft'
  leaflet: {
    map: Map
    layerContainer: {
      addLayer: (layer: any) => void
      removeLayer: (layer: any) => void
    }
  }
}

class EditControl extends MapControl<EditControlProps> {
  createLeafletElement(props: EditControlProps) {
    return createDrawElement(props)
  }

  onDrawCreate = (e: any) => {
    const { onCreated } = this.props
    this.props.leaflet?.layerContainer.addLayer(e.layer)
    onCreated && onCreated(e)
  }

  componentDidMount() {
    // @ts-ignore
    super.componentDidMount()
    const { map } = this.props.leaflet
    const { onMounted } = this.props

    for (const key in eventHandlers) {
      if (this.props[key]) {
        map.on(eventHandlers[key], this.props[key])
      }
    }
    // @ts-ignore
    leaflet.Draw.Polyline.prototype._onTouch = leaflet.Util.falseFn
    // @ts-ignore
    map.on(leaflet.Draw.Event.CREATED, this.onDrawCreate)

    onMounted && onMounted(this.leafletElement)
  }

  componentWillUnmount() {
    // @ts-ignore
    super.componentWillUnmount()
    const { map } = this.props.leaflet
    // @ts-ignore
    map.off(leaflet.Draw.Event.CREATED, this.onDrawCreate)

    for (const key in eventHandlers) {
      if (this.props[key]) {
        map.off(eventHandlers[key], this.props[key])
      }
    }
  }

  componentDidUpdate(prevProps: EditControlProps) {
    // super updates positions if thats all that changed so call this first
    // @ts-ignore
    super.componentDidUpdate(prevProps)

    if (isEqual(this.props.draw, prevProps.draw) || this.props.position !== prevProps.position) {
      return false
    }

    const { map } = this.props.leaflet
    // @ts-ignore
    this.leafletElement.remove(map)
    this.leafletElement = createDrawElement(this.props)
    this.leafletElement.addTo(map)

    return null
  }
}

function createDrawElement(props: EditControlProps) {
  const { layerContainer } = props.leaflet
  const { draw, edit, position } = props
  const options: any = {
    edit: {
      ...(edit || {}),
      featureGroup: layerContainer,
    },
  }

  if (draw) {
    options.draw = { ...(draw || {}) }
  }

  if (position) {
    options.position = position
  }
  // @ts-ignore
  return new Control.Draw(options)
}

export default withLeaflet(EditControl)
