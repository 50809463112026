import React from 'react'
import { Form, Input, Label } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { setMensurationsProp } from 'Store/actions/mensurations-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import FormGroup from '../FormGroup'
import { useTranslation } from 'react-i18next'

const MensurationsIdForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const name = useSelector((state: RootStore) => state.mensurations.wip?.name)
  const shift = useSelector((state: RootStore) => state.mensurations.wip?.shift)
  const description = useSelector((state: RootStore) => state.mensurations.wip?.description)

  const errors = useObjSelector((state: RootStore) => state.mensurations.form?.errors?.[0] ?? {})
  const showFeedback = useSelector((state: RootStore) => state.mensurations.form?.steps.items[0].showFeedback)

  return (
    <>
      <FormGroup widths="equal">
        <Form.Field inline required error={showFeedback && !!errors?.name}>
          <label>Nome</label>
          <Input
            name="name"
            fluid
            placeholder="Nomeie a nova entidade"
            value={name}
            onChange={(e, { name, value }) => {
              dispatch(setMensurationsProp(`wip.${name}`, value))
            }}
          />
          {/* @ts-ignore */}
          {showFeedback && errors?.name?.[0] && errors?.name?.[0] !== 'Required' && (
            <Label color="red" pointing>
              {/* @ts-ignore */}
              {t(errors?.name?.[0])}
            </Label>
          )}
        </Form.Field>
        <Form.Field inline required error={showFeedback && !!errors?.shift}>
          <label>Turno</label>
          <Input
            name="shift"
            fluid
            placeholder="Indique o turno"
            value={shift}
            onChange={(e, { name, value }) => {
              dispatch(setMensurationsProp(`wip.${name}`, value))
            }}
          />
          {/* @ts-ignore */}
          {showFeedback && errors?.shift?.[0] && errors?.shift?.[0] !== 'Required' && (
            <Label color="red" pointing>
              {/* @ts-ignore */}
              {t(errors?.shift?.[0])}
            </Label>
          )}
        </Form.Field>
      </FormGroup>
      <Form.Field required={false}>
        <label>{t('description')}</label>
        <Form.TextArea
          name={'description'}
          fluid
          placeholder="Descreva a nova mensuração"
          value={description}
          onChange={(e, { name, value }) => {
            dispatch(setMensurationsProp(`wip.${name}`, value))
          }}
        />
      </Form.Field>
    </>
  )
}

export default MensurationsIdForm
