import React, { useEffect, useState } from 'react'
import ReactTable from 'Utils/reactTable'
import { ReportsColumns, ReportsColumnsCustom } from './columns'
import { useTranslation } from 'react-i18next'
import SelectDateModal from './SelectDateModal'
import { RootStore } from 'Store/initialStore'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch, Redirect, useHistory } from 'react-router-dom'
import useFetchData from 'Utils/hooks/useFetchData'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { setFalse, setTrue } from 'Store/actions/toggle-action'
import { CellValue } from 'interfaces/cellValue'
import { fetchAreaAbandonmentReport, setTableProp } from 'Store/actions/tables-action'
import BreadcrumbReports from './BreadcrumbReports'
import useStoreSelector from 'Utils/hooks/useStoreSelector'

const ReportsContent = () => {
  const [loading, setLoading] = useState(true)
  useFetchData({
    independentFetches: [{ fetchAction: 'reports' }, { fetchAction: 'profiles' }, { fetchAction: 'geoModules' }],
    finishAction: () => setLoading(false),
  })

  const reportsAllIds = useObjSelector((state: RootStore) => state.modules?.reports?.allIds || [])
  const reportsByIds = useObjSelector((state: RootStore) => state.modules?.reports?.byId || {})

  const reportsIds = reportsAllIds
    .filter((id: string) => {
      return !reportsByIds[id]?.info.scope
    })
    .map((idR: any) => reportsByIds[idR]?.info.id)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  let match = useRouteMatch()
  const id = match?.params?.id
  const code = match?.params?.code

  const [currentSort, setCurrentSort] = React.useState(code ? 'col2' : 'label')

  const showDate = useSelector((state: RootStore) => state.tables.byId.reports.showDateModal)

  const cols = useObjSelector((state: RootStore) => state.tables.byId.reports.reportColumns || [])
  const rows = useObjSelector((state: RootStore) => state.tables.byId.reports.reportPayload || [])
  const reportType = useSelector((state: RootStore) => state.tables.byId.reports.reportType || '')
  const storeCode = useSelector((state: RootStore) => state.tables.byId.reports.code || '')
  const reportViewForm = useObjSelector((state: RootStore) => state.tables.byId.reports.reportViewForm || '')
  const openPDFModal = useSelector((state: RootStore) => state.toggle.openPDFModal)
  const showTableColumnsModal = useSelector((state: RootStore) => state.toggle.showTableColumnsModal)
  const fetchLoading = useSelector((state: RootStore) => state.tables.byId.reports.fetchLoading)

  const tenant = useStoreSelector((state) => state.login.empresa)

  useEffect(() => {
    // check if got data and clear it
    if (!id && reportViewForm) {
      dispatch({
        type: 'FETCH_REPORT_CLEAR_STATE',
      })
    }
    if (id && !showDate && reportType !== id && !code) {
      // show modal
      dispatch({
        type: 'SELECT_DATE_REPORTS_OPEN',
        payload: id,
        id: 'preview',
      })
    }
  }, [reportViewForm, showDate, id, reportType, dispatch, code, storeCode])

  useEffect(() => {
    if (code && code !== storeCode) {
      dispatch(setTableProp(`byId.reports.code`, code))
      const payload = { id: code }
      dispatch(
        fetchAreaAbandonmentReport({
          endpoint: ['Raizen', 'RaizenTest'].includes(tenant) ? 'areaAbandonmentReport2' : 'areaAbandonmentReport',
          payload,
        })
      )
    }
  }, [code, storeCode, dispatch, tenant])

  const history = useHistory()

  const columns = React.useMemo(
    () => (id ? ReportsColumnsCustom(t, cols, reportType, history) : ReportsColumns(t, dispatch, match)),
    [t, cols, id, dispatch, match, reportType, history]
  )
  const data = id
    ? rows
        .filter((v: any, i: number) => i !== 0)
        .map((v: any) => {
          let row: Record<string, CellValue> = {}
          v.forEach((vi: any, j: number) => {
            const valueTpye = typeof vi
            if (Array.isArray(vi)) row['col' + j] = { displayValue: vi.join(', '), baseValue: vi.join(', ') }
            if (valueTpye === 'object' && !vi?.displayValue) return
            if (vi?.displayValue) {
              row['col' + j] = vi
            } else {
              row['col' + j] = { displayValue: valueTpye === 'number' ? parseFloat(vi?.toFixed(0)) : vi, baseValue: vi }
            }
          })
          return row
        })
    : reportsIds.map((id) => ({
        label: t(id + '_label'),
        description: t(id + '_description'),
        resource: id,
        preview: id,
      }))
  // preset the first column to be group when using that cloned report
  const [currentGroup, setCurrentGroup] = useState(id === 'presenceByFencesGrouped' ? ['col0'] : [''])
  if (id && reportsIds.length > 0 && !reportsIds.find((e) => e === id)) return <Redirect to="/app/reports" />

  const tableLabel = (
    <BreadcrumbReports
      home={id ? t('Reports') : undefined}
      current={t(id ? id + '_label' : 'Reports')}
      lowercase={!!id}
      code={code}
      reportType={reportType}
    />
  )

  return (
    <>
      <ReactTable
        table={'reports'}
        tableLabel={tableLabel}
        columns={columns}
        data={data}
        initialSortBy={{ desc: false, id: 'label' }}
        menuButtons={code ? ['export', 'columns'] : id ? ['export', 'filter', 'columns'] : []}
        setCurrentSort={setCurrentSort}
        currentSort={currentSort}
        currentGroup={currentGroup}
        setCurrentGroup={setCurrentGroup}
        showPlaceholders={loading || fetchLoading}
        columnsOpenProp={showTableColumnsModal}
      />
      {openPDFModal && (
        <ModalTemplate
          open={openPDFModal}
          onClose={() => {
            dispatch(setFalse('openPDFModal'))
          }}
          header="Exportar PDF"
          cancelContent="Cancelar"
          saveContent="Colunas"
          onSave={() => {
            dispatch(setTrue('showTableColumnsModal'))
            dispatch(setFalse('openPDFModal'))
          }}
          children={<p>Selecione no máximo 10 colunas para exportar o PDF</p>}
          saveIcon="columns"
          size="small"
          onCancel={() => dispatch(setFalse('openPDFModal'))}
        />
      )}
      {showDate && <SelectDateModal open={showDate} />}
    </>
  )
}

export default ReportsContent
