import React from 'react'
import { Segment, Icon } from 'semantic-ui-react'

export const StatsBarFormHistoryPagination = ({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageCount,
  pageOptionsLength,
}) => {
  return (
    <Segment
      floated="right"
      style={{ marginTop: '0px', maxWidth: '200px', border: 'none', right: '15px', textAlign: 'right' }}
    >
      <Icon
        name="angle double left"
        title="ir para primeiro"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        style={{ cursor: 'pointer' }}
      />{' '}
      <Icon
        name="angle left"
        title="ir para anterior"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        style={{ cursor: 'pointer' }}
      />{' '}
      <Icon
        name="angle right"
        title="ir para próximo"
        onClick={() => nextPage()}
        disabled={!canNextPage}
        style={{ cursor: 'pointer' }}
      />{' '}
      <Icon
        name="angle double right"
        title="ir para último"
        onClick={() => gotoPage(pageOptionsLength - 1)}
        disabled={!canNextPage}
        style={{ cursor: 'pointer' }}
      />{' '}
      <span>
        <strong>
          <span title="página atual">{pageIndex + 1}</span> /<span title="total de páginas">{pageOptionsLength} </span>
        </strong>{' '}
      </span>
    </Segment>
  )
}

export default StatsBarFormHistoryPagination
