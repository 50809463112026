import React, { Component } from 'react'
import { connect } from 'react-redux'
import './header.css'
import HistoryBar from './HistoryBar'
import NavBar from './NavBar'
import './header.css'
//import AppFallback from 'Utils/ErrorBoundary/Fallbacks/AppFallback'
import withWindowDimensions from 'Utils/hocs/withWindowDimensions.jsx'
import NavBarTab from './NavBar/NavBarTab'

/*  Author: Bruno Melo
    Type: Supposed to be dumb (currently smart)
  Description:  a group of introductory or navigational aids.
  TODO: - Tirar o método de handleRerender e toda lógica envolvida.
      - Tirar acesso a store
      - Migrar o css para o padrão style
      - Funcionar independente da rota
*/

class Header extends Component {
  render() {
    const { perfil, historyType, currentTab } = this.props
    return (
      <div id="header">
        {currentTab === 'map' ? <NavBar {...this.props} /> : <NavBarTab {...this.props} />}
        {historyType && currentTab === 'map' && <HistoryBar perfil={perfil} />}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  histSize: state.history.allIds.length,
  historyType: state.history.type,
  perfil: state.login.perfil,
  company: state.login.empresa,
  hasLogo: state.projects?.[0]?.info?.logo,
  currentTab: state.tables.current.tab,
})

export default withWindowDimensions(connect(mapStateToProps)(Header))
