import initialStore from 'Store/initialStore'
import set from 'immutable-set'
import produce from 'immer'
import { keyBy, omit, union } from 'lodash'
import parseProfile from 'Utils/parseProfile'
import { setStoreProps } from './reducerUtils'
import { profiles } from 'interfaces/profiles'

export default function profilesReducer(state = initialStore.profiles, { type, payload, prop, value, id }) {
  switch (type) {
    case 'FETCH_PROFILES_START':
      return { ...state, loading: true }
    case 'FETCH_PROFILES_SUCCESS':
      return produce(state, (newState) => {
        const { items = [], nextToken } = payload || {}
        newState.loading = Boolean(nextToken)
        if (!items.length) {
          return newState
        }

        newState.allIds = union(
          state.allIds,
          items.map(({ id }) => id)
        )
        newState.byId = {
          ...state.byId,
          ...keyBy(items.map(parseProfile), 'id'),
        }
        newState.loading = Boolean(nextToken)
      })

    case 'FETCH_PROFILES_FAILURE':
      return { ...state, loading: false }

    case 'SET_PROFILES_PROP':
      return set(state, prop, value)

    case 'SET_PROFILES_PROPS':
      return setStoreProps(state, payload)

    case 'SAVE_PROFILE_START':
      return produce(state, (newState) => {
        newState.form.loading = true
      })

    case 'SAVE_PROFILE_SUCCESS':
      return produce(state, (newState) => {
        if (!payload?.id) return state
        if (!newState.allIds.includes(payload.id)) newState.allIds.push(payload.id)
        newState.byId[payload.id] = payload
        newState.current = null
        newState.wip = {}
        newState.form = profiles.form // init value of form
      })

    case 'SAVE_PROFILE_FAILURE':
      return produce(state, (newState) => {
        newState.form.loading = false
      })

    case 'SET_PROFILE_WIP':
      return produce(state, (newState) => {
        newState.wip = newState.byId[id] || {}
      })

    case 'DELETE_PROFILES_START':
      return { ...state, loading: true }

    case 'DELETE_PROFILES_SUCCESS':
      if (!id) return state
      return produce(state, (newState) => {
        newState.allIds = newState.allIds.filter((each) => each !== id)
        newState.byId = omit(newState.byId, [id])
        newState.loading = false
      })

    case 'DELETE_PROFILES_FAILURE':
      return { ...state, loading: false }

    case 'CLEAR_PROFILE_FORM':
      return produce(state, (newState) => {
        newState.form = profiles.form // init value of form
        newState.wip = {}
      })

    case 'SET_CURRENT_PROFILE':
      return produce(state, (newState) => {
        newState.current = payload ?? newState.byId[id] ?? {}
        newState.wip = { code: newState.current.code, id: newState.current.id }
      })

    case 'DELETE_PROFILE_SUCCESS':
      return produce(state, (newState) => {
        newState.allIds = newState.allIds.filter((each) => each !== payload.id)
      })

    default:
      return state
  }
}
