import React from 'react'
import InnerMap from '../InnerMap'
import ImageOverlayRotated from 'Components/Map/MapTools/ImageOverlayRotated'

export const ToggleIndoor = ({ inner, zoom, indoor, bearing, center }) => {
  if (inner) return <InnerMap bearing={bearing} center={center} zoom={zoom} indoor={indoor} />
  else if (zoom > 14) {
    return <ImageOverlayRotated indoor={indoor} zoom={zoom} />
  } else return null
}
