/*  
  Author: Luís Mourão
  Type: Component
  
  Description: 
    - 
    - 
  TODO: 
*/

import React, { useState } from 'react'
import { Card, Grid, Header, Icon, Divider } from 'semantic-ui-react'
import { useSelector, shallowEqual } from 'react-redux'
import CardByRule from 'Components/SidebarMenu/Contents/Dashboard/CardByRule.js'

const possibleColors = [
  'orange',
  'blue',
  'red',
  'yellow',
  'green',
  'teal',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'olive',
  'black',
]

const RulesCards = () => {
  const rules = useSelector((state) => state.rules, shallowEqual)
  const dashboards = useSelector((state) => state.dashboards, shallowEqual)
  const search = useSelector((state) => state.dashboards.filter)
  const timeAgo = useSelector((state) => state.dashboards.timeAgo)

  const [showCards, setShowCards] = useState(true)

  const coloredRules = rules.allIds
    .map((ruleId, i) => {
      let mainInfo = Object.values(dashboards?.byId?.rules?.byTime?.[ruleId]?.timeWindows || [])
      mainInfo = mainInfo?.length ? mainInfo : [0]
      return {
        ruleId: ruleId,
        name: rules.byId?.[ruleId].name,
        description: rules.byId?.[ruleId]?.description,
        color: possibleColors[i % possibleColors.length],
        mainInfo: mainInfo?.reduce((a, b) => a + b) || 0,
      }
    })
    .sort((a, b) => parseInt(b.mainInfo) - parseInt(a.mainInfo))

  let filteredRules = search
    ? coloredRules.filter((rule) => rule.name.toLowerCase().includes(search.toLowerCase()))
    : coloredRules

  return (
    <>
      <Grid columns="equal">
        <Grid.Column verticalAlign="bottom" style={{ cursor: 'pointer' }}>
          <Header as="h3" onClick={() => setShowCards(!showCards)}>
            <Icon name={showCards ? 'angle down' : 'angle right'} size="mini" />
            {`Cards - ${timeAgo === '1d' ? 'Último Dia' : 'Última Semana'}`}
          </Header>
        </Grid.Column>
        <Grid.Column width="13">
          <Divider horizontal>
            <Header as="h4">
              <Icon name="vcard" />
            </Header>
          </Divider>
        </Grid.Column>
      </Grid>
      <br />
      <br />
      <br />
      {showCards && (
        <Card.Group centered>
          {filteredRules.map((rule, i) => (
            <CardByRule
              key={rule.ruleId + rule.color}
              ruleId={rule.ruleId}
              ruleName={rule.name}
              ruleDescription={rule.description}
              color={rule.color}
              mainInfo={rule.mainInfo}
            />
          ))}
        </Card.Group>
      )}
      <br />
      <br />
    </>
  )
}

export default RulesCards
