import React, { Component } from 'react'
import GeoFeatures from './GeoFeatures'
import Control from 'react-leaflet-control'
import { withTranslation } from 'react-i18next'
import ErrorBoundary from 'Utils/ErrorBoundary/ErrorBoundary'
import MapButton from 'Utils/components/MapButton'
import { toggle } from 'Store/actions/toggle-action'
import { connect } from 'react-redux'
import { canAccess } from 'Utils/rbac'
import { Icon } from 'semantic-ui-react'

class MapTools extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toolButtonTitle: props.t('DrawTool'),
      errorBoundaryKey: 0,
    }
  }

  showToolsButton = () => {
    this.props.toggle('fencesEditionMode')
    this.setState({ show: !this.state.show })
  }

  handleRerender = () => {
    if (this.state.errorBoundaryKey < 5)
      this.setState((prevState) => ({
        errorBoundaryKey: prevState.errorBoundaryKey + 1,
      }))
  }

  render() {
    const { t, scope, fencesEditionMode } = this.props

    return (
      <div className="divFenceButton">
        <Control position={'topright'} style={{ top: '90px' }}>
          {canAccess({ actions: 'WRITE', roles: scope }) && (
            <MapButton position="topright" onClick={this.showToolsButton} title={t('Ativar Modo de Edição')}>
              <Icon name="edit" style={{ paddingLeft: '3px', paddingTop: '1px' }} />
            </MapButton>
          )}
        </Control>
        <ErrorBoundary key={this.state.errorBoundaryKey} onError={this.handleRerender}>
          <GeoFeatures showToolsButton={this.showToolsButton} show={fencesEditionMode} refMap={this.props.refMap} />
        </ErrorBoundary>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  fencesEditionMode: state.toggle.fencesEditionMode,
})
const mapDispatchToProps = {
  toggle: toggle,
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MapTools))
