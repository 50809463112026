import React, { useState, useEffect } from 'react'
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts'
import { useSelector } from 'react-redux'
import { colors, lightColors, semanticColors } from 'Utils/colorsFormat'

export const GraphPie = ({ data = [] }) => {
  const RADIAN = Math.PI / 180

  const [currentData, setCurrentData] = useState([])

  const tenant = useSelector((state) => state.login.empresa)

  useEffect(() => {
    const checkColor = (color) =>
      ['Tiplam', 'PhygitallDevs'].includes(tenant) ? lightColors(color) : semanticColors(color)
    if (data.length > 0) {
      setCurrentData(data)
      let firstHalf
      let dataFormatted = data
      dataFormatted = dataFormatted
        .sort((a, b) => b.value - a.value || a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        .map((rd, i) => ({ name: rd.name, value: parseInt(rd.value), fill: checkColor(colors[i % 7]) }))
      if (dataFormatted.length > 8) {
        firstHalf = dataFormatted.splice(0, 7)
        let others = dataFormatted.splice(0, dataFormatted.length)
        let othersTotal = others.reduce((total, { value }) => parseInt(value) + total, 0)
        firstHalf.push({ name: 'Outros', value: othersTotal, fill: checkColor('gray') })
      }
      setCurrentData(firstHalf || dataFormatted)
    }
  }, [data, tenant, setCurrentData])
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill={'#555'}
        fontWeight={600}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: '18px' }}
      >
        {`${value?.toLocaleString('pt-br')}`}
      </text>
    )
  }

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          labelLine={false}
          label={renderCustomizedLabel}
          data={currentData}
          cx="50%"
          cy="50%"
          outerRadius={'100%'}
          dataKey="value"
          startAngle={90}
          endAngle={450}
          minAngle={15}
        />
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.fill} />
        ))}
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default GraphPie
