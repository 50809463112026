import { round } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as LoadingLogo } from 'Resources/Icons/loading-logo.svg'
import { Progress } from 'semantic-ui-react'

export const LoadingScreen = ({ carregando, text = 'Loading', text1, text2 }) => {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (!carregando) {
      setVisible(false)
      return
    }
    const timer = setTimeout(() => {
      setVisible(true)
    }, 20000)
    return () => clearTimeout(timer)
  }, [visible, carregando])

  const loadingProgress = useSelector((state) => state.system.loadingProgress)
  const loadingPercent = round(loadingProgress * 100, 2)

  return (
    carregando && (
      <div id="telaCarregamento" className="telaCarregamento">
        <div className="logoTelaCarregamento">
          <LoadingLogo />
          <span>{text}</span>
          {loadingPercent > 0 && (
            <Progress active percent={loadingPercent} progress success={loadingPercent === 100} indicating />
          )}
          <br />
          {visible && !loadingProgress && (
            <div className="timeout">
              <p style={{ margin: '0 0 0 12px' }}>{text1}?</p>
              <p>{text2}</p>
            </div>
          )}
        </div>
      </div>
    )
  )
}
