import React from 'react'
import { Image, StyleSheet } from '@react-pdf/renderer'
import ReportTitle from './ReportTitle'
import Separator from './Separator'

const styles = StyleSheet.create({
  logoRight: {
    width: 120,
    height: 30,
    position: 'absolute',
    top: 35,
    right: -53,
  },
  logoLeft: {
    width: 70,
    height: 40,
    position: 'absolute',
    top: 35,
    left: 30,
  },
})

const ReportHeader = ({ title, subTitle }) => {
  const logoUrl = localStorage.getItem('reportLogo')
  return (
    <>
      <Image style={styles.logoLeft} src={logoUrl} />
      <Image style={styles.logoRight} src="/images/Phygitall.png" />
      <ReportTitle title={title} subTitle={subTitle} />
      <Separator />
    </>
  )
}

export default ReportHeader
