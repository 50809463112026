import React, { memo } from 'react'
import { Icon, Card } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
//import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { fetchInteractions } from 'Store/actions/interactions-action'

// const menuButtons = ['columns', 'export', 'refresh']
export const InteractionsContent = () => {
  const { t } = useTranslation()
  const tableName = 'interactions'
  const table = useSelector((state) => state[tableName])
  const dispatch = useDispatch()
  const fetchAction = () => {
    dispatch(fetchInteractions())
  }
  const hiddenColumns = useSelector((state) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns)
  const columns = React.useMemo(
    () => [
      {
        Header: t('User'),
        accessor: 'assetName',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('users')}`,
      },
      {
        Header: t('InteractTo'),
        accessor: 'interact',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('users')}`,
      },
      {
        Header: t('Local'),
        accessor: 'location',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('locations')}`,
      },
      {
        Header: t('When'),
        accessor: 'time',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} valor(es)`,
        /*Cell: ({ value }) =>
          `${m(value).subtract(3, 'hours').format('LL') + ' ' + m(value).subtract(3, 'hours').format('LTS')}`,*/
      },
      {
        Header: t('Duration') + ` (${t('abreviatedMinutes')})`,
        accessor: 'duration',
        aggregate: 'sum',
        Aggregated: ({ value }) => `${value} ${t('minutes')}`,
      },
    ],
    [t]
  )

  let rows = table.allIds.map((id) => {
    return { interact: table.byId[id].macAssetName, ...table.byId[id] }
  })

  const data = React.useMemo(() => rows, [rows])

  const Cards = ({ cardHeader, cardMeta, cardDesc, cardTime }) => (
    <Card.Group>
      <Card color="teal">
        <Card.Content>
          {/*<Image
                circular
                floated='right'
                size='mini'
                src='https://live.staticflickr.com/7234/7338523218_b58d5e1b51_b.jpg'
              />*/}
          <Card.Header>{cardHeader}</Card.Header>
          <Card.Meta>{t('interactions')}</Card.Meta>
          <Card.Description>{'Total de interações'}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Icon name="clock" />
          {cardTime}
        </Card.Content>
      </Card>
    </Card.Group>
  )

  return (
    <ReactTable
      table={tableName}
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
      Cards={Cards}
      showPlaceholders={table.fetchTime ? false : true}
      // menuButtons={menuButtons}
      fetchAction={fetchAction}
    />
  )
}

export default memo(InteractionsContent, (props, nextProps) => {
  return !nextProps.autoUpdate
})
