import React, { useEffect } from 'react'
import { Storage } from '@aws-amplify/storage'

interface UseImageUrlProps {
  fallbackUrl?: string
  validateObjectExistence?: boolean
  // Se true, não tenta buscar a imagem do S3 e usa o key como URL direto
  preview?: boolean
}

export const FALLBACK_IMAGE_URL = 'https://react.semantic-ui.com/images/wireframe/square-image.png'

const useImageUrl = (key?: string, { fallbackUrl, validateObjectExistence, preview }: UseImageUrlProps = {}) => {
  const [signedURL, setImageUrl] = React.useState<string | null>(null)
  const [loadingImage, setLoadingImage] = React.useState<boolean>(false)

  useEffect(() => {
    if (preview || !key) {
      setImageUrl(key ?? fallbackUrl ?? FALLBACK_IMAGE_URL)
      return
    }
    const getUrl = async () => {
      try {
        setLoadingImage(true)
        const formatKey = key.replace('public/', '')
        const url = (await Storage.get(formatKey, {
          validateObjectExistence,
        })) as string
        setImageUrl(url)
      } catch (err) {
        console.error(err)
        setImageUrl(fallbackUrl ?? FALLBACK_IMAGE_URL)
      } finally {
        setLoadingImage(false)
      }
    }
    getUrl()
  }, [key, fallbackUrl, validateObjectExistence, preview])

  return { signedURL, loadingImage, setImageUrl }
}

export default useImageUrl
