import React, { useCallback } from 'react'
import { Grid, Input, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import ItemDetails from 'Components/Sidebar/Contents/Notification/ItemDetails'
import { useDispatch } from 'react-redux'
import { updateAsset } from 'Store/actions/assets-action'
import { useSelector } from 'react-redux'
import { AssetNameSpan } from 'Components/Sidebar/Contents/Notification/ItemDetails'
import { useCanAccess } from 'Utils/rbac'

/*  Author: Bruno Melo / Luís Mourão
    Type: Dumb
	Description: Span able to be editable			 
	To do list: 
    - Remove tenant conditional
				
*/
const IconStyled = styled(Icon)`
  float: right;
  visibility: ${(props) => props.display};
  margin-right: 6px;
  cursor: pointer;
`
const InputStyled = styled(Input)`
    width: 90%, margin-right: 2px
`
const pointerStyle = { cursor: 'pointer' }

const SpanEditable = ({ assetName, assetId, chars }) => {
  const dispatch = useDispatch()
  const canAccess = useCanAccess('WRITE')
  const [state, setState] = React.useState(false)
  const [value, setValue] = React.useState(assetName)
  const currentTenant = useSelector((state) => state.tenants.current)
  const [display, setDisplay] = React.useState('hidden')
  const canEdit = canAccess && !currentTenant.features?.includes('anonymize')
  const handleChange = useCallback((e, t) => {
    setValue(t.value)
  }, [])

  const handleClickConfirm = useCallback(() => {
    if (value === '') setValue(assetName)
    if (value !== assetName) {
      dispatch(updateAsset(assetId, { name: value }))
    }
    setState(false)
  }, [dispatch, assetId, assetName, value])

  const handleClickEdit = useCallback(() => {
    setState(true)
  }, [])

  const handleMouseOver = useCallback(() => {
    setDisplay('visible')
  }, [])

  const handleMouseOut = useCallback(() => {
    setDisplay('hidden')
  }, [])

  return (
    <Grid.Row
      textAlign="left"
      id={'GridPopupTitle'}
      style={{ width: '100%', height: '28px', marginLeft: '5px' }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {state ? (
        <>
          <InputStyled
            id={'Input_' + value}
            placeholder={'Digite novo nome'}
            value={value}
            size="mini"
            onChange={handleChange}
          />
          <Icon name="check" color="green" onClick={handleClickConfirm} style={pointerStyle} />
        </>
      ) : (
        <>
          {currentTenant.features?.includes('anonymize') ? (
            <ItemDetails id={assetId} assetId={assetId} assetName={value} spanStyle="popup" chars={chars} />
          ) : (
            <AssetNameSpan title={value} alt={value} style={pointerStyle}>
              {value}
            </AssetNameSpan>
          )}
          {canEdit && (
            <IconStyled
              name="edit"
              color="grey"
              onClick={handleClickEdit}
              display={display}
              title="Clique para editar"
            />
          )}
        </>
      )}
    </Grid.Row>
  )
}

export default SpanEditable
