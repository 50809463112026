import React, { useMemo } from 'react'
import ReactTable from 'Utils/reactTable'
import { RootStore } from 'Store/initialStore'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useFetchData from 'Utils/hooks/useFetchData'
import { GenericColumns } from 'Utils/reactTable/components/GenericColumns'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import TableSpan from 'Components/SidebarMenu/TableSpan'
import { deleteProfiles, setCurrentProfile, setProfilesProps } from 'Store/actions/profiles-action'
import { profileSteps } from 'interfaces/profiles'
import { SvgIcon } from 'Utils/components/SvgIcon.jsx'
import ClickableLink from 'Utils/components/ClickableLink'
import moment from 'moment'
import { setChangeLogsProps } from 'Store/actions/changeLogs-action'
import { setTrue } from 'Store/actions/toggle-action'
import ChangeLogs from 'Components/modals/ChangeLogs'

const ProfilesContent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useFetchData({ independentFetches: [{ fetchAction: 'profiles' }] })

  const allProfileIds = useObjSelector((state: RootStore) => state.profiles.allIds)
  const profilesById = useObjSelector((state: RootStore) => state.profiles.byId)
  const loading = useSelector((state: RootStore) => state.profiles.loading)
  const loginProfile = useSelector((state: RootStore) => state.login.perfil)
  const showChangeLogs = useSelector((state: RootStore) => state.toggle.showChangeLogs)
  const current = useObjSelector((state) => state.profiles.current)

  //Esse useEffect é para facilitar a implementação de algo novo no form.
  // useEffect(() => {
  //   if (allProfileIds.length > 0) {
  //     dispatch(setCurrentProfile({ id: '83692ccd-d541-4d85-afa8-0f59aadc2dcb' }))
  //     dispatch(
  //       setProfilesProps([
  //         ['form.steps.items', profileSteps],
  //         ['form.open', true],
  //       ])
  //     )
  //   }
  // }, [dispatch, allProfileIds])

  const columns = GenericColumns({
    columns: [
      ...(loginProfile === 'DEV' ? ['id'] : []),
      'code',
      {
        accessor: 'icon',
        align: 'center',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: any) => `${value} ${value === '1' ? t('Assignment') : t('Assignments')}`,
        Cell: (props: any) => {
          const getSourceValue = (row: any) => {
            if (props.row.groupByID === `icon`) {
              return props.row?.subRows?.[0].original ?? props.value
            }
            return row.original
          }

          const { icon, color } = getSourceValue(props.row)

          return <SvgIcon color={color} name={icon} />
        },
      },
      'name',
      {
        accessor: 'description',
        Cell: ({ value }: any) => <TableSpan value={value} padding="0px" />,
      },
      {
        accessor: 'type',
        Header: t('Type'),
        Cell: ({ value }: any) => t(value),
      },

      {
        accessor: 'customFields',
        aggregate: 'uniqueCount',
        Cell: ({ value = '' }: { value: any[] | string }) => {
          return Array.isArray(value) ? value.length : 0
        },
        sortType: (rowA: any, rowB: any, columnId: string) => {
          const aLength = Array.isArray(rowA.values[columnId]) ? rowA.values[columnId].length : 0
          const bLength = Array.isArray(rowB.values[columnId]) ? rowB.values[columnId].length : 0
          return aLength - bLength
        },
        align: 'center',
      },

      {
        accessor: 'updatedAt',
        Header: t('updatedAt'),
        aggregate: 'uniqueCount',
        Aggregated: ({ value }: any) => {
          return `${value} Data(s)`
        },
        Cell: ({
          value,
          row: {
            original: { id },
          },
        }: any) => {
          return (
            <ClickableLink
              title={moment(value).format('lll')}
              onClick={() => {
                dispatch(setCurrentProfile({ id }))
                dispatch(setChangeLogsProps([['entityId', id]]))
                dispatch(setTrue('showChangeLogs'))
              }}
            >
              {moment(value).fromNow()}
            </ClickableLink>
          )
        },
      },
    ],
  })

  const data = useMemo(() => {
    return allProfileIds.map((profileId) => profilesById[profileId])
  }, [allProfileIds, profilesById])

  return (
    <>
      <ReactTable
        table={'types'}
        columns={columns}
        data={data}
        newItemClick={() => {
          dispatch(
            setProfilesProps([
              ['current', {}],
              ['form.steps.items', profileSteps],
              ['form.open', true],
            ])
          )
        }}
        onEditClick={(id: string) => {
          dispatch(setCurrentProfile({ id }))
          dispatch(
            setProfilesProps([
              ['form.steps.items', profileSteps],
              ['form.open', true],
            ])
          )
        }}
        showPlaceholders={loading}
        menuButtons={['new', 'edit', 'delete', 'columns', 'export']}
        deleteAction={({ ids }: { ids: string[] }) => {
          dispatch(deleteProfiles({ ids }))
        }}
        initialSortBy={{ desc: true, id: 'updatedAt' }}
      />
      {showChangeLogs && <ChangeLogs entity="profile" currentItem={current} />}
    </>
  )
}

export default ProfilesContent
