import validCoords from 'Utils/validCoords'

export default function heatmapReducer(
  state = {
    byId: {},
    allIds: [],
    current: [],
    filter: [],
    hmPoints: [],
    points: [],
  },
  { type, id, payload }
) {
  let lat
  let lng
  let allIds = []
  let byId = {}
  let current
  let hmPoints = []
  switch (type) {
    case 'saveHeatmap':
      if (Array.isArray(payload) && payload.length > 0) {
        payload.reverse().forEach((e, i) => {
          if (validCoords(e[0], e[1])) {
            lat = e[0]
            lng = e[1]
            allIds.push(lat + '_' + lng + '_' + e[3])
            byId[lat + '_' + lng + '_' + e[3]] = { lat, lng, intensity: e[2], action: e[3] }
            hmPoints.push(e)
          }
        })
      }
      return { byId, allIds, current, hmPoints }
    case 'saveHeatmapFilter':
      //console.log('Antes: payload',payload,'byId',state.byId,'Pontos',state.hmPoints)
      if (Array.isArray(payload))
        if (payload.length > 0) {
          let newPayload = Object.values(state.byId)
            .filter((e) => payload.includes(e.action))
            .map((d) => {
              return [d.lat, d.lng, d.intensity, d.action]
            })
          hmPoints = newPayload
        } else {
          hmPoints = Object.values(state.byId).map((d) => {
            return [d.lat, d.lng, d.intensity, d.action]
          })
        }
      let filter = payload
      //console.log('Depois: hmPoints',hmPoints,'filter antes',state.filter,'filter depois',filter)
      let newstate = { hmPoints, filter }
      return { ...state, ...newstate }

    case 'FETCH_HEATMAP_SUCCESS':
      return { ...state, points: payload }

    default:
      return state
  }
}
