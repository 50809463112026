import React, { useEffect, useState } from 'react'
//import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import ReactTable from 'Utils/reactTable'
//import { fetchRules, clearRules, subscribeRuleChange, subscribeRuleDelete } from 'Store/actions/rules-action'
import { RootStore } from 'Store/initialStore'
import { MessageColumns } from './columns'
import { deleteMessage, fetchMessages } from 'Store/actions/messages-action'
import { useTranslation } from 'react-i18next'
import { saveColumnsPreference } from 'Store/actions/login-action'
import useFetchData from 'Utils/hooks/useFetchData'

const tableName = 'messages'
const tableLabel = [
  {
    key: 'messages',
    text: 'Mensagens',
    value: 'messages',
  },
  {
    key: 'news',
    text: 'Novidades',
    value: 'messages/news',
  },
]

export const MessagesContent = () => {
  const [loading, setLoading] = useState(true)

  useFetchData({
    independentFetches: [{ fetchAction: 'messages' }],
    finishAction: () => setLoading(false),
  })

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const byId = useSelector((state: RootStore) => state.messages.byId, shallowEqual)
  const allIds = useSelector((state: RootStore) => state.messages.allIds, shallowEqual)

  const fetchAction = async () => {
    setLoading(true)
    dispatch(fetchMessages())
    setLoading(false)
  }

  const deleteAction = (ids: { ids: string[] }) => dispatch(deleteMessage(ids))

  // let hiddenColumns = ['status', 'extra', 'updatedBy', 'updatedFrom', 'createdBy', 'createdFrom']
  const hiddenColumns = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns
  )
  const groupedValuesColumns = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = React.useMemo(() => MessageColumns(t), [t])

  const rows = allIds

  const data = React.useMemo(() => rows.map((id) => byId[id]), [rows, byId])

  return (
    <ReactTable
      table={tableName}
      tableLabel={tableLabel}
      hiddenColumns={hiddenColumns}
      columns={columns}
      data={data}
      fetchAction={fetchAction}
      deleteAction={deleteAction}
      initialSortBy={{ desc: false, id: 'label' }}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      showPlaceholders={loading}
    />
  )
}
