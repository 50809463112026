import React from 'react'
import Control from 'react-leaflet-control'

const MapButton = ({ position, title, children, onClick }) => (
  <Control position={position} className="leaflet-bar">
    {/* eslint-disable-next-line */}
    <a title={title} href="#" className="leaflet-button" onClick={onClick}>
      {children}
    </a>
  </Control>
)

export default MapButton
