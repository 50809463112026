import Auth from 'Libs/Auth'
import { useDispatch, useSelector } from 'react-redux'
import { useMsal } from '@azure/msal-react'
import { saveLoginPreferences } from 'Store/actions/login-action'
import { RootStore } from 'Store/initialStore'
import { IPublicClientApplication } from '@azure/msal-browser'

function handleLogoutMsal(instance: IPublicClientApplication) {
  instance
    .logoutRedirect({
      postLogoutRedirectUri: 'https://vli.phygitall.com.br/',
    })
    .catch((e) => {
      console.error(e)
    })
}

export const useSignOut = () => {
  const authMethod = useSelector((state: RootStore) => state.login.authMethod)
  const { instance } = useMsal()
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(saveLoginPreferences())
    if (authMethod === 'auth0') {
      const auth = new Auth()
      const { logout } = auth
      logout()
      return
    }
    if (authMethod === 'msal') {
      handleLogoutMsal(instance)
      return
    }
    console.error('Auth method not implemented')
  }

  return handleLogout
}
