import React, { useEffect, useMemo } from 'react'
import { Image, Grid, Menu, Header, HeaderContent, HeaderSubheader } from 'semantic-ui-react'
import styled from 'styled-components'
import m from 'moment'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { clearChangeLogs, fetchChangeLogs, setCurrentChangeLog } from 'Store/actions/changeLogs-action'
import { setFalse } from 'Store/actions/toggle-action'
import ChangeLogTable from 'Utils/ChangeLogTable'
import flattenObject from 'Utils/flattenObject'
import logDenormalization from 'Utils/logDenormalization'
import { GeoModule } from 'interfaces/modules'

interface Props {
  entity?: string
  currentItem?: any
}

export const ChangeLogs: React.FC<Props> = ({ entity = '', currentItem }) => {
  const dispatch = useDispatch()

  const hiddenFields = useMemo(() => {
    return getHiddenFields(entity)
  }, [entity])

  const changeLogIds = useSelector((state: RootStore) => state.changeLogs.allIds)
  const changeLogsById = useSelector((state: RootStore) => state.changeLogs.byId)
  const current = useSelector((state: RootStore) => state.changeLogs.current)
  const entityId = useSelector((state: RootStore) => state.changeLogs.entityId)
  const loading = useSelector((state: RootStore) => state.changeLogs.loading)

  useEffect(() => {
    if (entityId) {
      dispatch(fetchChangeLogs({ entityId, entity, currentItem }))
    }
  }, [dispatch, entityId, currentItem, entity])

  const flattenCurrentLog = useMemo(
    () => flattenObject({ obj: logDenormalization(current?.log as GeoModule), hiddenFields }),
    [current, hiddenFields]
  )

  const flattenPrevLog = useMemo(() => {
    if (!current?.id) {
      return {}
    }
    const prevIndex = changeLogIds.indexOf(current?.id) + 1
    const prevId = changeLogIds[prevIndex]
    if (!prevId) {
      return {}
    }
    const prevLog = changeLogsById[prevId].log
    return flattenObject({ obj: logDenormalization(prevLog as GeoModule), hiddenFields })
  }, [current, changeLogIds, changeLogsById, hiddenFields])

  return (
    <ModalTemplate
      open
      header="Logs de Alterações"
      fixedHeight="80vh"
      bottom={'95px'}
      onClose={() => {
        dispatch(setFalse('showChangeLogs'))
        dispatch(clearChangeLogs())
      }}
      loadingContent={loading}
      noData={changeLogIds.length < 1}
      saveButton={false}
      cancelIcon="reply"
      cancelContent="Voltar"
    >
      <StyledGrid style={{ height: '107%' }}>
        <Grid.Column
          style={{
            width: '20%',
            overflowY: 'auto',
            maxHeight: '100%',
            scrollbarWidth: 'none', // Para navegadores baseados em Firefox
          }}
        >
          <Menu vertical pointing secondary fluid style={{ height: '100%' }}>
            {changeLogIds.map((id, index) => {
              const log = changeLogsById[id].log
              const imgLabel = log.updatedBy || log.createdBy || 'sem informação'

              return (
                <StyledMenuItem
                  key={index}
                  name="Principal"
                  value="main"
                  active={id === current?.id}
                  onClick={() => {
                    dispatch(setCurrentChangeLog({ id }))
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Image
                      title={log.updatedBy || log.createdBy || 'sem informação'}
                      circular
                      src={
                        'https://i1.wp.com/cdn.auth0.com/avatars/' +
                        (imgLabel?.[0]?.toLowerCase() || 'si') +
                        '.png?ssl=1'
                      }
                      size="mini"
                      verticalAlign="middle"
                      style={{ float: 'left', marginRight: '5px' }}
                    />
                    <Header as="h5" style={{ marginTop: '0px' }}>
                      <HeaderContent>
                        {log.updatedAt > log.createdAt ? 'Atualizado' : 'Criado'}
                        <HeaderSubheader as={'h6'}>{m(log.updatedAt).fromNow()}</HeaderSubheader>
                      </HeaderContent>
                    </Header>
                  </div>
                </StyledMenuItem>
              )
            })}
          </Menu>
        </Grid.Column>
        <RightGridColumn style={{ width: '80%', overflowY: 'auto', maxHeight: '100%' }}>
          <ChangeLogTable previousObject={flattenPrevLog} currentObject={flattenCurrentLog} />
        </RightGridColumn>
      </StyledGrid>
    </ModalTemplate>
  )
}

const StyledMenuItem = styled(Menu.Item)`
  text-align: left;
`

const RightGridColumn = styled(Grid.Column)`
  padding: 1.1% 3% 3% 3% !important;
  height: 100%;
  width: 80%;
  overflow-y: auto;
  max-height: 100%;
`

const StyledGrid = styled(Grid)`
  height: 100%;
`

const getHiddenFields = (entity: string) => {
  const generalHiddenFields = [
    'profileInfo',
    'history',
    'defaultInvisible',
    'loadPreference',
    'mRadius',
    'id',
    'state',
    'url',
    'tenant',
    'sourceId',
    'notificationProfile',
    'targetId',
  ]

  const entityHiddenFields: Record<string, string[]> = {
    geoModule: ['type'],
    rule: ['action'],
  }

  return [...generalHiddenFields, ...(entityHiddenFields[entity] || [])]
}

export default ChangeLogs
