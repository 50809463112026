import { Dashboard as APIDashboard, ScopesList } from '../API'
import { FormStepItems, StoreForm } from './formStepItems'

// Definição do tipo GridItem
export type GridItem = {
  x: number
  y: number
  w: number
  h: number
}

// Definição do tipo Panel
export type Panel = {
  id: string
  label?: string
  filters?: any
  layout: GridItem
  type?: string
  description?: string
  scope?: ScopesList
  startTime?: string
  endTime?: string
}

export type Dashboard = Omit<APIDashboard, 'panels'> & {
  panels: Record<string, Panel>
}

export type WipDashboard = Partial<Dashboard>

export type PanelInfo = {
  result?: any
  borderColor?: 'green' | 'red' | 'grey' | 'gray'
  loading?: boolean
}

export type PanelsInfo = {
  byId: Record<string, PanelInfo>
}

export type Dashboards = {
  allIds: string[]
  byId: Record<string, Dashboard>
  current?: Dashboard
  wip: WipDashboard
  form: StoreForm
  panelsInfo: PanelsInfo
  panelIdForm?: string
  loadedAt?: string
  refreshedAt?: string
  openSideBar: boolean
  loading: boolean
  loadingItem: boolean
  addAnimationOn?: boolean
}

export const dashboardItems: FormStepItems[] = [
  {
    id: 'identification',
    icon: 'info',
    title: 'Identificação',
    description: 'Identifique o dashboard',
    showFeedback: false,
    disabled: false,
  },
]

export const dashboards: Dashboards = {
  allIds: [],
  byId: {},
  current: undefined,
  wip: {},
  panelsInfo: {
    byId: {},
  },
  form: {
    steps: {
      current: 0,
      items: dashboardItems,
    },
    open: false,
    loading: false,
    isEditing: false,
  },
  openSideBar: true,
  loading: false,
  loadingItem: true,
}
