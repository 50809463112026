import React, { useCallback, useMemo } from 'react'
import StepForm from '../StepForm'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import createStepOneSchema from './schemas/stepId'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { clearProfileForm, mutateProfile, setProfilesProps } from 'Store/actions/profiles-action'
import ProfilesStepOneForm from './ProfilesStepOneForm'
import StepTwoSchema from './schemas/StepTwoSchema'
import ProfilesStepTwoForm from './ProfilesStepTwoForm'

function ProfilesForm() {
  const dispatch = useDispatch()

  const profileAllIds = useObjSelector((state) => state.profiles.allIds)
  const profilesById = useObjSelector((state) => state.profiles.byId)

  const fieldsToBeRemoved = useObjSelector((state) => state.profiles.form.fieldsToBeRemoved)

  const currentId = useSelector((state: RootStore) => state.profiles.current?.id)

  const uniqueCodes = useMemo(() => {
    return [...new Set(profileAllIds.map((id) => profilesById[id].code))]
  }, [profileAllIds, profilesById])

  const uniqueNames = useMemo(() => {
    return [...new Set(profileAllIds.map((id) => profilesById[id].name))]
  }, [profileAllIds, profilesById])

  const stepOneSchema = createStepOneSchema(uniqueCodes, uniqueNames, !!currentId)

  const formatValue = useCallback(
    (value) => {
      const res = { ...value }
      if (fieldsToBeRemoved.length > 0) {
        res.customFields =
          value.customFields?.length > 0
            ? value.customFields.filter((e: any) => !fieldsToBeRemoved.includes(e.id))
            : undefined
      }
      return res
    },
    [fieldsToBeRemoved]
  )

  return (
    <StepForm
      entity="profiles"
      schemas={[stepOneSchema, StepTwoSchema]}
      forms={[<ProfilesStepOneForm />, <ProfilesStepTwoForm />]}
      setProps={setProfilesProps}
      values={[{ fields: ['name'] }, {}]}
      onSave={() => {
        dispatch(mutateProfile())
      }}
      size="fullscreen"
      formatValue={formatValue}
      onClose={() => {
        dispatch(clearProfileForm())
        dispatch(setProfilesProps([[`form.isEditing`, false]]))
      }}
    />
  )
}
export default ProfilesForm
