import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import 'Store/actions/dashboards-action'
import { CardHeader } from './PeopleBoardContent'
import { PeopleBoard } from './PeopleBoard'
import { Card, Segment, Modal, Header } from 'semantic-ui-react'
import Loading from 'Utils/components/Loading'
import useFetchData from 'Utils/hooks/useFetchData'
import { fetchActiveAssets } from 'Store/actions/assets-action'
import { useTranslation } from 'react-i18next'

export const PeopleBoardScreen = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchActiveAssets())
    const id = setInterval(() => {
      dispatch(fetchActiveAssets())
    }, 15000)
    return () => clearInterval(id)
  }, [dispatch])

  const [loading, setLoading] = useState(true)
  useFetchData({
    independentFetches: [
      {
        fetchAction: 'assets',
      },
      {
        fetchAction: 'profiles',
      },
    ],
    finishAction: () => setLoading(false),
  })

  // get the 'brigadista' id
  const firefighterTypeId = useSelector(
    (state) => state.profiles.allIds.filter((id) => state.profiles.byId[id].name === 'Brigadista')[0]
  )

  // get everybody's id's
  const allPeopleIds = useSelector((state) => state.assets.allIds, shallowEqual)

  // get all info
  const allPeople = useSelector((state) => state.assets.byId, shallowEqual)

  // get every id which is 'online'
  const presentPeopleIds = useSelector((state) => state.assets.allActivePeopleIds, shallowEqual)

  // filter the firefighters and only get those who are online
  const firefighters = allPeopleIds
    .filter(
      (idf) =>
        allPeople[idf]?.info?.type === firefighterTypeId && presentPeopleIds?.find((pid) => pid.toString() === idf)
    )
    .map((id) => {
      return {
        id,
        name: allPeople[id]?.name,
        cardId: allPeople[id]?.info?.cardId,
      }
    })

  return (
    <>
      <Modal.Content style={{ padding: '0' }}>
        {loading ? (
          <div style={{ marginTop: '5vh' }}>
            <Loading />
          </div>
        ) : (
          <Segment style={{ border: 'none', boxShadow: 'none', padding: '0', height: '90vh', overflowX: 'hidden' }}>
            <Header textAlign="center" size="large" style={{ marginTop: '14px' }}>
              {t(`tabs.firefighter`)}
            </Header>
            <Card.Group centered textAlign="center">
              {firefighters.length > 0 ? (
                firefighters.map((eachFireFighter) => (
                  <PeopleBoard
                    key={eachFireFighter.id}
                    assetId={eachFireFighter.id}
                    name={eachFireFighter.name}
                    cardId={eachFireFighter.cardId}
                  />
                ))
              ) : (
                <div>
                  <CardHeader>
                    <Segment style={{ marginTop: '20vh' }}> {'Nenhum brigadista presente.'} </Segment>
                  </CardHeader>
                </div>
              )}
            </Card.Group>
          </Segment>
        )}
      </Modal.Content>
    </>
  )
}
