import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { showConfirm, hideConfirm } from 'Store/actions/system-action'
import { setFalse } from 'Store/actions/toggle-action'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

interface Props {
  id: string
  label?: string | null
  moduleType: 'fences' | 'refs' | 'routes'
}

const SideFormDebugAction: FC<Props> = ({ id, label, moduleType }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const geojson = useObjSelector((state: RootStore) => state.modules?.[moduleType]?.byId[id])

  const handleClick = () => {
    const data = {
      content: (
        <pre>
          <code>{JSON.stringify(geojson, null, 2)}</code>
        </pre>
      ),
      onConfirm: () => {
        dispatch(hideConfirm())
        dispatch(setFalse('showStatsBar'))
      },
      onCancel: () => dispatch(hideConfirm()),
      confirmHeader: label,
    }
    dispatch(showConfirm(data))
  }
  return (
    <Button
      circular
      icon="bug"
      title={t('debug')}
      style={{
        cursor: 'pointer',
      }}
      onClick={handleClick}
    />
  )
}
export default SideFormDebugAction
