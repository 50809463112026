import React, { useMemo } from 'react'
import { Dropdown, Form, Transition } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { setDataLabelingsWIPAssets } from 'Store/actions/dataLabelings-actions'
import { RootStore } from 'Store/initialStore'
import useFetchData from 'Utils/hooks/useFetchData'
import { useTranslation } from 'react-i18next'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

const DataLabelingsAssetsForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useFetchData({
    independentFetches: [{ fetchAction: 'assets' }, { fetchAction: 'profiles' }],
  })

  const assetsAllIds = useObjSelector((state: RootStore) => state.assets.allIds)
  const assetsById = useObjSelector((state: RootStore) => state.assets.byId)
  const assetsLoading = useSelector((state: RootStore) => state.assets.loading)
  const labelAssets = useSelector((state: RootStore) => state.dataLabelings.wip?.assets)
  const currentStep = useSelector((state: RootStore) => state.dataLabelings.form?.steps?.current)
  const assetsError = useSelector((state: RootStore) => state.dataLabelings.form?.errors?.[currentStep]?.assets)
  const showFeedback = useSelector(
    (state: RootStore) => state.dataLabelings.form?.steps.items[currentStep].showFeedback
  )

  const profiles = useObjSelector((state: RootStore) => state.profiles.byId)

  const collectAssetTypes = useMemo(() => {
    return Object.values(profiles).reduce((acc: any, curr) => {
      if (curr?.type === 'assets' && curr.name?.includes('Coleta')) {
        acc = [...acc, curr.id]
      }
      return acc
    }, [])
  }, [profiles])

  const assetOptions = useMemo(() => {
    return assetsAllIds.reduce((acc: any, assetId: string) => {
      if (collectAssetTypes.includes(assetsById[assetId]?.info?.type) || labelAssets?.includes(assetId)) {
        acc = [...acc, { key: assetId, value: assetId, text: assetsById[assetId]?.name }]
      }
      return acc
    }, [])
  }, [assetsAllIds, collectAssetTypes, assetsById, labelAssets])

  const locations = ['leftHand', 'rightHand', 'machine']

  return (
    <Form.Group widths="equal">
      {locations.map((eachLocation, i) => (
        <Form.Field inline required={i < 2} error={showFeedback && !!assetsError}>
          <Transition transitionOnMount={true} animation="fade down" duration="2300">
            <label>{t(eachLocation)}</label>
          </Transition>
          <Dropdown
            selection
            fluid
            placeholder={assetsLoading ? 'Carregando opções, aguarde...' : 'Selecionar Dispositivo'}
            onChange={(e, { value }) => dispatch(setDataLabelingsWIPAssets({ id: value, index: i }))}
            value={labelAssets?.[i] ?? undefined}
            options={assetOptions}
            noResultsMessage={
              assetsLoading ? 'Carregando opções, aguarde...' : 'Nenhum resultado encontrado no filtro selecionado.'
            }
            loading={assetsLoading}
            clearable
          />
        </Form.Field>
      ))}
    </Form.Group>
  )
}

export default DataLabelingsAssetsForm
