import React from 'react'
import { Column } from './reactTable/components/GenericColumns'
import { JSONTree } from 'react-json-tree'
import { Button, Icon } from 'semantic-ui-react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { JSON_TREE_THEME } from './constants'
import { CellValue } from 'interfaces/cellValue'
import moment from 'moment'
import TableSpan from 'Components/SidebarMenu/TableSpan'

const formatReportColumns = (scope: string, columns: (string | Column)[], fencesById: any, t: any) => {
  return columns.map((column, index) => {
    if (scope === 'rules') {
      if (column === 'name') return { accessor: 'name', Header: 'ruleName' }
    }
    if (scope === 'measures' && column === 'measures') {
      return {
        accessor: 'measures',
        Header: 'Medidas',
        Cell: ({ value }: any) => {
          return (
            <div
              style={{
                position: 'relative',
                minWidth: '180px', // Largura mínima da célula
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                bottom: '3px',
              }}
            >
              <JSONTree
                data={value}
                theme={JSON_TREE_THEME}
                invertTheme={true}
                keyPath={['measures']}
                shouldExpandNodeInitially={() => false}
              />
              <CopyToClipboard text={JSON.stringify(value, null, 2)}>
                <Button
                  icon
                  style={{
                    position: 'absolute',
                    top: 13,
                    right: '30%',
                    background: 'transparent',
                    padding: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Icon name="copy" />
                </Button>
              </CopyToClipboard>
            </div>
          )
        },
      }
    }
    if (typeof column === 'object') {
      return column
    } else {
      return {
        id: column,
        Header: t(column),
        accessor: (row: Record<string, CellValue<number>>) => {
          const accessor = typeof column === 'string' && (row[column]?.baseValue || row[column])
          return accessor
        },
        sortType: 'basic',
        aggregate:
          column !== 'Duração'
            ? 'uniqueCount'
            : (agg: any) => {
                return agg.reduce((prev: string, current: CellValue<number>) => {
                  const currrentDuration = current?.baseValue ?? current
                  const durationSum = moment.duration(prev).add(moment.duration(currrentDuration))
                  return msToHoursMinutesSecs(durationSum.asMilliseconds())
                }, '00:00:00')
              },
        Aggregated: ({ value }: { value: string }) => `${value} ${t(column)} (s)`,
        Cell: (props: any) => {
          let value: any
          if (props.row.groupByID === column) {
            value = props.row?.subRows?.[0].original?.[props.row.groupByID]?.displayValue ?? props.value
          } else {
            const originalValue = props.row?.original?.[column as string]
            value = originalValue?.displayValue ?? originalValue
          }

          return <TableSpan value={value} width="500px" padding="0px" />
        },
      } as Column
    }
  })
}

const padTime = (num: number) => {
  return num.toString().padStart(2, '0')
}

const msToHoursMinutesSecs = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  seconds = seconds % 60
  minutes = minutes % 60
  return `${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}`
}

export default formatReportColumns
