import React, { useCallback } from 'react'
import StepForm from '../StepForm'
import stepOneSchema from './schemas/stepOne'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearReportForm,
  fetchReportData,
  setCurrentReportByWIP,
  setReportsProp,
  setReportsProps,
} from 'Store/actions/reports-action'
import ReportsStepOneForm from './ReportsStepOneForm'
import ReportsStepTwoForm from './ReportsStepTwoForm'
import ReportsStepThreeForm from './ReportsStepThreeForm'
import { useHistory } from 'react-router-dom'
import createStepTwoSchema from './schemas/stepTwo'
import { RootStore } from 'Store/initialStore'
import ReportsIdForm from './ReportsIdForm'
import createStepThreeSchema from './schemas/stepThree'

function ReportsForm() {
  const dispatch = useDispatch()
  const history = useHistory()

  const formType = useSelector((state: RootStore) => state.reports.formType)

  const isEditing = useSelector((state: RootStore) => !!state.reports.form?.isEditing)

  const timeProp = useSelector((state: RootStore) => state.reports.wip.filters?.timeProp as string)
  const scope = useSelector((state: RootStore) => state.reports.wip.scope ?? state.reports.current?.scope)
  const isDevUser = useSelector((state: RootStore) => state.login.perfil === 'DEV')

  const stepTwoSchema = createStepTwoSchema((scope as 'measures') || 'rules', isDevUser)
  const stepThreeSchema = createStepThreeSchema(timeProp)

  const onClose = useCallback(() => {
    dispatch(clearReportForm())
    dispatch(setReportsProp(`form.isEditing`, false))
  }, [dispatch])

  return formType === 'filter' ? (
    <StepForm
      entity="reports"
      schemas={[stepOneSchema, stepTwoSchema, stepThreeSchema]}
      forms={[<ReportsStepOneForm />, <ReportsStepTwoForm />, <ReportsStepThreeForm />]}
      setProps={setReportsProps}
      values={[{ fields: ['scope'] }, {}]}
      onSave={() => {
        dispatch(setReportsProp(`form.open`, false))
        if (isEditing) {
          dispatch(fetchReportData())
        } else {
          dispatch(setCurrentReportByWIP())
          history.push(`/app/betaReports/new`)
        }
      }}
      onClose={onClose}
      saveContent="load"
      size="fullscreen"
    />
  ) : (
    <ReportsIdForm />
  )
}

export default ReportsForm
