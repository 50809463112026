import React from 'react'
import { Icon, Image, Card } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedCard } from 'Store/actions/dashboards-action'

const CardByRule = ({ image = 'clock', color = 'blue', ruleId, ruleName, ruleDescription, mainInfo }) => {
  const dispatch = useDispatch()

  const selected = useSelector((state) => state.dashboards.byId['rules'].selected)

  return (
    <Card color={color} key={'card' + ruleId} onClick={() => dispatch(setSelectedCard('rules', ruleId))}>
      <Card.Content>
        {image ? (
          image.startsWith('http') ? (
            <Image circular floated="right" size="tiny" src={image} />
          ) : (
            <Icon circular inverted color={color} size="large" style={{ float: 'right' }} name={image} />
          )
        ) : null}
        <Card.Header>{mainInfo}</Card.Header>
        <Card.Meta>Total de Ocorrências</Card.Meta>
        <Card.Description>{ruleDescription}</Card.Description>
      </Card.Content>
      <Card.Content extra style={{ height: '55px', paddingTop: '18px' }}>
        <span
          title={ruleName}
          style={{
            maxWidth: '85%',
            display: 'inline-block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {ruleName}
        </span>
        <Icon
          name={selected.includes(ruleId) ? 'check square outline' : 'square outline'}
          size="large"
          style={{ float: 'right', color: selected.includes(ruleId) ? 'green' : null, marginRight: '12px' }}
        />
      </Card.Content>
    </Card>
  )
}

export default CardByRule
