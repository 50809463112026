import { ToastContainer, toast, Slide, ToastOptions } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'
// import { isMobile } from 'react-device-detect'
import { RootStore } from '../../Store/initialStore'
import React from 'react'
import { Header, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

type SystemToastProps = {
  isMobile: boolean
}

interface MyToastOptions extends ToastOptions {
  msg: string
}

const SystemToast = ({ isMobile = false }: SystemToastProps) => {
  const isDevUser = useSelector((state: RootStore) => state.login.perfil === 'DEV')

  return (
    <ToastContainer
      style={
        isMobile
          ? { width: '90vw', top: '7vh', left: '2.5vh' }
          : { right: '3vw', marginTop: '4vh', width: '33vw', zIndex: 999999999 }
      }
      transition={Slide}
      limit={5}
      newestOnTop
      pauseOnFocusLoss={!isDevUser}
      theme="colored"
      icon={false}
    />
  )
}

const ToastContentComponent = ({ type, message }: { type: 'success' | 'info' | 'error'; message: string }) => {
  const { t } = useTranslation()
  return (
    <Header as="h3">
      <Icon
        name={type === 'success' ? 'check circle' : type === 'error' ? 'attention' : 'info circle'}
        color={type === 'success' ? 'green' : type === 'error' ? 'red' : 'blue'}
        size="small"
      />
      <Header.Content>
        {t('systemToast.' + type + '.title')}
        <Header.Subheader>{t(message)}</Header.Subheader>
      </Header.Content>
    </Header>
  )
}

const ToastContent = (type: 'success' | 'info' | 'error', message: string): JSX.Element => {
  return <ToastContentComponent type={type} message={message} />
}

const SUCCESS_MSG = 'systemToast.success.message'
const ERROR_MSG = 'systemToast.error.message'

export const notifySuccess = ({ position = 'bottom-right', theme = 'light' }: ToastOptions = {}) => {
  toast.success(ToastContent('success', SUCCESS_MSG), { position, theme })
}

export const notifyInfo = ({ position = 'bottom-right', theme = 'light', msg }: MyToastOptions) => {
  toast.info(ToastContent('info', msg), { position, theme })
}

interface ErrorProps extends ToastOptions {
  message?: string
}

export const notifyError = ({ position = 'bottom-right', theme = 'light', message = ERROR_MSG }: ErrorProps = {}) => {
  toast.error(ToastContent('error', message), { position, theme })
}

export const notifyPromise = (prom: Promise<unknown> | (() => Promise<unknown>)) => {
  toast.promise(
    prom,
    {
      pending: 'systemToast.pending.title',
      success: SUCCESS_MSG,
      error: ERROR_MSG,
    },
    { position: 'bottom-right', theme: 'light' }
  )
}

export default SystemToast
