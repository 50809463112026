import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SemanticCOLORS, SemanticICONS, Button, Icon } from 'semantic-ui-react'
import { insertImageData /*updateImageData*/ } from 'Store/actions/upload-action'
import { RootStore } from 'Store/initialStore'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { handleEdit, handleSelect } from './ImageUpload'
import { setFalse } from 'Store/actions/toggle-action'

const UploadModal = () => {
  const open: boolean = useSelector((state: RootStore) => state.toggle.uploadModal)
  const dispatch = useDispatch()

  const file = React.useRef(null)

  // this is kinda stupid
  const [buttonProps, setButtonProps] = useState({
    active: false,
    color: 'blue' as SemanticCOLORS,
    label: 'Upload',
    icon: 'upload' as SemanticICONS,
  })

  const [mainData, setMainData] = useState({
    name: 'mainData',
    code: 'XXX',
    description: 'this is a test',
    order: 0,
    corner0: { lat: 0, lng: 0 },
    corner1: { lat: 0, lng: 0 },
    corner2: { lat: 0, lng: 0 },
    corner3: { lat: 0, lng: 0 },
    url: '',
    image: new File(['foo'], 'no_input_image.png', { type: 'image/png' }),
  })

  const handleChange = (ev: any) => {
    // data is here
    const imageFile = handleSelect(ev)

    setMainData({ ...mainData, image: imageFile })

    setButtonProps({
      active: true,
      color: 'green',
      label: 'Upload ✓',
      icon: 'repeat',
    })
  }

  const handleClick = () => {
    handleEdit(file)
  }

  const handleCancel = () => {
    dispatch(setFalse('uploadModal'))
  }

  const handleSave = () => {
    //   let params = {
    //     id: '0999999999',
    //     corners: [mainData.corner0, mainData.corner1, mainData.corner2, mainData.corner3],
    //     description: mainData.description,
    //     image: mainData.image,
    //     name: mainData.name,
    //     order: mainData.order,
    //     uniqueID: mainData.code,
    //     url: mainData.url,
    //   }
    //   dispatch(updateImageData(params, 'units'))

    let data = {
      name: mainData.name,
      type: 4,
      info: {
        url: mainData.url,
        code: mainData.code,
        corners: [mainData.corner0, mainData.corner1, mainData.corner2, mainData.corner3],
        properties: { label: mainData.name },
        image: mainData.image,
        order: mainData.order,
        // for lord's sake please review this
        //properties: { label: fields.name, url: fields.url, uniqueID: fields.uniqueID },
      },
    }
    dispatch(insertImageData(data, 'units'))
  }

  return (
    <ModalTemplate
      open={open}
      header={<span>Upload Modal</span>}
      onSave={handleSave}
      onCancel={handleCancel}
      onClose={handleCancel}
    >
      <label>{buttonProps.label}</label>
      <input type="file" hidden={true} onChange={(e) => handleChange(e)} ref={file} />

      <Button fluid primary toggle active={buttonProps.active} color={buttonProps.color} onClick={handleClick}>
        <Button.Content visible>
          <Icon name={buttonProps.icon} />
        </Button.Content>
      </Button>
    </ModalTemplate>
  )
}

export default UploadModal
