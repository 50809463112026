import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDashboardStellantis } from 'Store/actions/dashboardStellantis-action'
import { changeCurrentDashboard } from 'Store/actions/dashboards-action'
import ModalHeader from 'Utils/modals/ModalHeader'
import Loading from 'Utils/components/Loading'
import MainCards from 'Utils/reactTable/components/MainCards'
import MainStatistics from 'Utils/reactTable/components/MainStatistics'
import { Segment, Modal, Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useFetchData from 'Utils/hooks/useFetchData'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import {
  calcDataLabelingsMetrics,
  calcDataLabelingsCards,
  setSelectedActivity,
  setSelectedVehicle,
} from 'Store/actions/dataLabelings-actions'
import PredictBarChart from './PredictBarChart'
import { VEHICLES_OPTIONS } from 'Utils/stellantisConsts'

const ChartGrid = styled('div')`;
  margin-right: 14px;
  margin-top:15px;
  height 280px;
`

const StatisticsGrid = styled(Grid)`
  margin-top: 35px !important;
  margin-bottom: 60px !important;
`

const emptyArray = []

export const StellantisDashboardsContent = ({ currentSelected, windowWidth = 2000 }) => {
  useFetchData({ independentFetches: [{ fetchAction: 'dataLabelings' }] })

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dashboards = useSelector((state) => state.dashboards)
  const tenant = useSelector((state) => state.tenants.current)
  const dashboard = 'Dashboard'
  /*Informações dos filtros*/
  const [time, setTime] = useState(['turno1', 'turno2'])
  const [carModel, setCarModel] = useState('')

  /*Sobre pegar as informações da Store sobre os dashInfos*/
  const loading = useSelector((state) => !state.toggle.showDashboard)

  const dataLabelingsIds = useObjSelector((state) => state.dataLabelings.allIds)
  const aggByGroupedCycle = useObjSelector((state) => state.dataLabelings.aggByGroupedCycle)
  const activitiesOptions = useObjSelector((state) => state.dataLabelings.activitiesOptions)
  const selectedActivity = useObjSelector((state) => state.dataLabelings.selectedActivity)
  const selectedVehicle = useObjSelector((state) => state.dataLabelings.selectedVehicle)
  const phaseCards = useObjSelector((state) => state.dataLabelings.phaseCards)

  useEffect(() => {
    dispatch(calcDataLabelingsMetrics())
    dispatch(calcDataLabelingsCards())
  }, [dataLabelingsIds, dispatch])

  useEffect(() => {
    dispatch(fetchDashboardStellantis({ time: 'today' }))
  }, [dispatch])

  let dashboardOptions = emptyArray

  dashboardOptions = [
    {
      key: 'activity',
      text: dashboards.byId['activity'].name,
      value: 'activity',
      onClick: (a, { value }) => dispatch(changeCurrentDashboard(value)),
    },
  ]

  const insightActions = { setCarModel, setTime }
  const insightActionsValues = { time, carModel }

  let exportAction

  let filtdashboardOptions = emptyArray
  filtdashboardOptions = dashboardOptions.filter((f) =>
    !tenant?.features?.includes('people_dashboard') ? f.text !== 'Pessoas' : true
  )

  const handleSave = () => {
    alert('aqui eu salvo')
  }

  let menuButtons = ['toggle fullscreen', 'pdt', 'shift', 'calendar']

  return !loading ? (
    <>
      <ModalHeader
        dashboard={tenant?.name !== 'Stellantis' && dashboard}
        dashboardOptions={filtdashboardOptions.sort((a, b) =>
          a.text.localeCompare(b.text, 'pt-br', { ignorePunctuation: true })
        )}
        name={selectedActivity.text}
        exportAction={exportAction}
        insightActions={insightActions}
        insightActionsValues={insightActionsValues}
        onSave={handleSave}
        menuButtons={menuButtons}
        menuTableIconsOptions={
          activitiesOptions?.map((e) => ({
            ...e,
            action: () => {
              dispatch(setSelectedActivity(e.key))
              dispatch(calcDataLabelingsCards())
            },
          })) || []
        }
        buttonsProps={{
          stellantisCars: {
            options: [{ key: 'all', text: 'Todos Veículos', value: 'all' }, ...VEHICLES_OPTIONS].map((e) => ({
              ...e,
              selected: e.key === selectedVehicle,
              action: () => {
                dispatch(setSelectedVehicle(e.key))
                dispatch(calcDataLabelingsMetrics())
                dispatch(calcDataLabelingsCards())
              },
            })),
          },
          pdt: {
            options:
              activitiesOptions?.map((e) => ({
                ...e,
                action: () => {
                  dispatch(setSelectedActivity(e.key))
                  dispatch(calcDataLabelingsCards())
                },
              })) || [],
          },
        }}
      />
      <Modal.Content style={{ padding: '0', height: 'auto', minHeight: '76vh', overflowY: 'scroll' }}>
        <Segment style={{ border: 'none', boxShadow: 'none' }}>
          <>
            <ChartGrid>
              {/* <CardLineChart
                  dashboard={dashboard}
                  pieDataAll={emptyArray}
                  currentCard={currentCard}
                  filters={insightActionsValues}
                  currentSelected={currentSelected}
                /> */}
              <PredictBarChart data={aggByGroupedCycle?.[selectedActivity.key]} showPhases={true} />
            </ChartGrid>
            <StatisticsGrid centered>
              <MainStatistics aggData={aggByGroupedCycle?.[selectedActivity.key]} />
            </StatisticsGrid>
            <MainCards dashboard={dashboard} phaseCards={phaseCards} />
          </>
        </Segment>
      </Modal.Content>
    </>
  ) : (
    <div style={{ marginTop: '35vh' }}>
      <Loading />
      <h4 style={{ textAlign: 'center' }}>{t('loading_icon_message')}</h4>
    </div>
  )
}
