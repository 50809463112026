import './App.css'

import './Resources/Icons/iconLibrary'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  // Button,
  /* Confirm,*/ Modal,
  Sidebar,
} from 'semantic-ui-react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import Header from 'Components/Header'
import { SideBarLeft } from 'Components/SidebarMenu'
import RenderMap from 'Components/Map/RenderMap'
import Footer from 'Components/Footer'
import FileUpload from 'Utils/FileUpload'
import { LoadingScreen } from 'Utils/LoadingScreen'
// import { Toasts, NotificationStatus, notificationState } from 'Utils/notifications'
import { setFalse } from 'Store/actions/toggle-action'
import getSafe from 'Utils/getSafe.js'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import SideForm from 'Components/sideForm/SideForm'
import { Route, Switch, Redirect } from 'react-router-dom'
import { TabWrapper } from 'RoutesTables/TabWrapper'
import withWindowDimensions from 'Utils/hocs/withWindowDimensions'
import OfflineBanner from 'Components/Header/OfflineBanner'
import { SmartTVInteraction } from 'Components/Utilities/SmartTVInteraction'
import SystemToast from 'Utils/components/SystemToasts'
import { VideoExampleView } from 'Views/VideoExampleView'
import ReportsItemContent from 'Components/SidebarMenu/Contents/betaReports/ReportsItemContent'
import DataLabelingCronology from 'Components/SidebarMenu/Contents/DataLabelings/DataLabelingCronology'
import ReactHowler from 'react-howler'
import { handleScreenTransformation } from 'Utils/handleScreenTransformation'
import { setNotificationProps, stopSound } from 'Store/actions/notification-action'

const Container = styled.div`
  position: relative;
  bottom: auto;
  width: 100%;
  top: 0 !important;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
`
const SidebarContainer = styled(Sidebar.Pushable)`
  overflow: hidden;
`

class App extends PureComponent {
  static defaultProps = {
    text: 'loading',
  }

  onKeyDown(e) {
    const key = getSafe(() => e.keyCode, null)
    if (key === 27) {
      if (this.props.inner) this.props.setFalse('showInnerMap')
    }
  }

  render() {
    const {
      login,
      empresa,
      center,
      carregando,
      dispatch,
      openConfirm,
      text,
      modal,
      confirmContent,
      onConfirm,
      onCancelConfirm,
      confirmHeader,
      t,
      sideBarLeft,
      tabIds,
      offline,
      isMobileSized,
      showStatsBar,
      currentTab,
      windowHeight,
      sound,
      routesPanelsById,
    } = this.props

    const PusherStyle = {
      height: '86vh',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      placeContent: 'center',
      backgroundColor: 'white', //default do semanticui é #cce2ff
      paddingLeft: ['map', 'firefighter'].includes(currentTab) ? '0' : '1.2vw',
    }

    const { mapSize, transformSize } = handleScreenTransformation({
      sideBarLeft,
      isMobileSized,
      showStatsBar,
    })

    return (
      <FileUpload
        login={login}
        empresa={empresa}
        center={[center.lat, center.lng]}
        dispatch={dispatch}
        refMap={this.props.refMap}
      >
        {offline && <OfflineBanner />}
        <SmartTVInteraction />
        <div id="outer-container" style={{ zIndex: '2998' }}>
          <main id="page-wrap">
            <div className="container-fluid" onKeyDown={(e) => this.onKeyDown(e)}>
              <LoadingScreen
                carregando={carregando}
                text={t(text)}
                text1={t('Takingtoolong')}
                text2={t('Checkyourconnection')}
              />
              <ModalTemplate
                header={confirmHeader}
                open={openConfirm}
                onClose={onCancelConfirm}
                onSave={onConfirm}
                onCancel={onCancelConfirm}
              >
                {confirmContent}
              </ModalTemplate>
              <Modal open={modal} content={'teste'} />
              <ReactHowler
                src={sound.src ?? './sos.mp3'}
                playing={sound.playing}
                loop={true}
                preload={false}
                onPlay={() => {
                  const sound = this.props.sound
                  if (!sound.permanent && !sound.isStopingScheduled) {
                    this.props.setNotificationProps([['sound.isStopingScheduled', true]])
                    setTimeout(
                      () => {
                        this.props.stopSound()
                      },
                      sound.duration ? sound.duration * 1000 : 30000
                    )
                  }
                }}
              />
              <Header refMap={this.props.refMap} profile={this.props.profile} />
              <Container id="ContainerMainContent">
                <SidebarContainer>
                  <SideBarLeft isMobile={this.props.isMobileSized} />
                  <SideForm windowHeight={windowHeight} isMobile={this.props.isMobileSized} sideBarLeft={sideBarLeft} />
                  <Sidebar.Pusher
                    style={{
                      width: mapSize + 'vw',
                      transform: `translate(${transformSize}vw, 0vh)`,
                      ...PusherStyle,
                    }}
                  >
                    <Switch>
                      <Route
                        exact
                        path={'/app/report/:id'}
                        key={'report'}
                        render={(props) => {
                          return <TabWrapper style={{ height: '100%' }} path={'reports'} />
                        }}
                      />
                      <Route
                        exact
                        path={'/app/report/:id/:code'}
                        key={'reportCode'}
                        render={(props) => {
                          return <TabWrapper style={{ height: '100%' }} path={'reports'} />
                        }}
                      />
                      <Route
                        exact
                        path={'/app/mensuration/:id'}
                        key={'mensuration'}
                        render={(props) => {
                          return <TabWrapper style={{ height: '100%' }} path={'mensurations'} />
                        }}
                      />
                      <Route
                        exact
                        path={'/app/dashmensuration'}
                        key={'mensuration'}
                        render={(props) => {
                          return <TabWrapper style={{ height: '100%' }} path={'dashmensuration'} />
                        }}
                      />
                      <Route
                        exact
                        path={'/app/activity/:id'}
                        key={'activity'}
                        render={(props) => {
                          return <TabWrapper style={{ height: '100%' }} path={'activities'} />
                        }}
                      />
                      <Route
                        exact
                        path={'/app/betaReports/:code'}
                        key={'betaReports'}
                        render={(props) => {
                          return <ReportsItemContent />
                        }}
                      />
                      <Route
                        exact
                        path={'/app/dataLabelings/chronology/:ids'}
                        key={'dataLabelings/chronology'}
                        render={() => {
                          return <DataLabelingCronology />
                        }}
                      />

                      {tabIds.map((tabPath) => {
                        const path = routesPanelsById[tabPath].path
                        const formatPath = path ?? tabPath
                        return (
                          <Route
                            path={'/app/' + formatPath}
                            key={tabPath}
                            render={(props) => {
                              return <TabWrapper style={{ height: '100%' }} path={tabPath} />
                            }}
                          />
                        )
                      })}
                      <Route exact path="/app" component={RenderMap} />
                      <Route exact path="/app/video/upload" component={VideoExampleView} />
                      <Route path="*">
                        <Redirect to="/app" />
                      </Route>
                    </Switch>
                  </Sidebar.Pusher>
                </SidebarContainer>
              </Container>
              {/* <Toasts
                colors={{
                  [NotificationStatus.ERROR]: '#DC3545',
                  [NotificationStatus.SUCCESS]: '#5da700',
                  [NotificationStatus.NOTICE]: '#40A9F3',
                  [NotificationStatus.WARNING]: '#FFD399',
                }}
                state={notificationState}
                Button={Button}
              /> */}
              <SystemToast isMobile={this.props.isMobileSized} />
              <Footer />
            </div>
          </main>
        </div>
      </FileUpload>
    )
  }
}

const mapStateToProps = (state) => ({
  openConfirm: state.system.confirm.open,
  confirmContent: state.system.confirm.content,
  onCancelConfirm: state.system.confirm.onCancel,
  onConfirm: state.system.confirm.onConfirm,
  confirmHeader: state.system.confirm.confirmHeader,
  carregando: state.toggle.loadingScreen,
  login: state.login.email,
  center: state.login.preferences.center,
  empresa: state.login.empresa,
  refMap: state.login.refMap,
  inner: state.toggle.showInnerMap,
  sideBarLeft: state.toggle.sideBarLeft,
  routesPanelsById: state.login.preferences.routesPanels.byId,
  tabIds: state.login.preferences.routesPanels.allIds,
  offline: state.toggle.offline,
  showStatsBar: state.toggle.showStatsBar,
  currentTab: state.tables.current.tab,
  sound: state.notification.sound,
})

const mapDispatchToProps = {
  setFalse: setFalse,
  stopSound: stopSound,
  setNotificationProps: setNotificationProps,
}

App.propTypes = {
  carregando: PropTypes.bool,
  center: PropTypes.object,
  confirmacao: PropTypes.bool,
  empresa: PropTypes.string,
  login: PropTypes.string,
  refMap: PropTypes.object,
  text: PropTypes.string,
}
export default withWindowDimensions(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App)))
