// @ts-nocheck
import React from 'react'
import {
  CriticalityContent,
  DashboardContent,
  DevicesContent,
  FencesContent,
  PeopleBoardScreen,
  InteractionsContent,
  MessagesContent,
  SimulationsContent,
  PhasesContent,
  RefsContent,
  UnitsContent,
  NewsContent,
  AccessControl,
} from 'Components/SidebarMenu/Contents'
import PeopleContent from 'Components/SidebarMenu/Contents/People'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PreferencesModal from 'Components/SidebarMenu/PreferencesModal'
import AlarmsRulesContent from 'Components/SidebarMenu/Contents/AlarmRules'
import MeasuresHistContent from 'Components/SidebarMenu/Contents/MeasuresHist'
import AssociationsContent from 'Components/SidebarMenu/Contents/Associations'
import AssetsContent from 'Components/SidebarMenu/Contents/Assets'
import { setFalse } from 'Store/actions/toggle-action'
import { setTableProp } from 'Store/actions/tables-action'
import { MotionContent } from 'Components/SidebarMenu/Contents/Motion'
import MeasuresHistTemplateContent from 'Components/SidebarMenu/Contents/MeasuresHistTemplate'
import ReportsContent from 'Components/SidebarMenu/Contents/Reports'
//import CycleTimeMeasurementContent from 'Components/SidebarMenu/Contents/CycleTimeMeasurement'
//import { DashboardManageAndSecurity } from 'Components/SidebarMenu/Contents/DashboardManageAndSecurity/ManageAndSecurity'
import { NotificationsContent } from 'Components/SidebarMenu/Contents/Notifications'
import { RootStore } from '../Store/initialStore'
import { DataLabelingsContent } from '../Components/SidebarMenu/Contents/DataLabelings/DataLabelingsContent'
import ActivitiesContent from 'Components/SidebarMenu/Contents/activities/ActivitiesContent'
//import MensurationsContent from 'Components/SidebarMenu/Contents/mensurations/MensurationsContent'
import ErrorBoundary from 'Utils/ErrorBoundary/ErrorBoundary'
import { StellantisDashboardsContent } from 'Components/SidebarMenu/Contents/StellantisDashboards/StellantisDashboardsContent'
import BetaReportsContent from 'Components/SidebarMenu/Contents/betaReports/ReportsContent'
import { StellantisDashboardsContentDashMensuration } from 'Components/SidebarMenu/Contents/StellantisDashboards/StellantisDashboardsContentDashMensuration'
import { Redirect, Route, Switch } from 'react-router-dom'
import { DriverDashboard } from 'Components/SidebarMenu/Contents/StellantisDashboards/DriverDashboard'
import ProfilesContent from 'Components/SidebarMenu/Contents/profiles/ProfilesContent'
import Dashboards from 'Components/dashboards/Dashboards'

export const TabWrapper = (args: any) => {
  const path = args.path
  const tabType = useSelector((state: RootStore) => state.tables.byId[path]?.type)
  const currentTenant = useSelector((state) => state.tenants.current)
  const tenant = currentTenant.name
  const features = currentTenant.features

  const DictOfFetchAndComponent = {
    dashmensuration: {
      component: <StellantisDashboardsContentDashMensuration currentSelected="dashboard" />,
    },
    people: {
      component: <PeopleContent />,
    },
    types: {
      component: <ProfilesContent />,
    },
    interactions: {
      component: <InteractionsContent />,
    },
    assets: {
      component: <AssetsContent />,
    },
    associations: {
      component: <AssociationsContent />,
    },
    devices: {
      component: <DevicesContent />,
    },
    firefighter: {
      component: <PeopleBoardScreen />,
    },
    measuresHist: {
      component: <MeasuresHistContent />,
    },
    fences: {
      component: <FencesContent />,
    },
    locationHist: {
      component: '',
    },
    units: {
      component: <UnitsContent />,
    },
    refs: {
      component: <RefsContent />,
    },
    messages: {
      component: (
        <Switch>
          <Route exact path="/app/messages" component={MessagesContent} />
          <Route path="/app/messages/news" component={NewsContent} />
        </Switch>
      ),
    },
    accessControl: {
      component: <AccessControl />,
      isEnabledForThisTenant: features?.includes('access_control'),
    },
    simulations: {
      component: <SimulationsContent />,
    },
    notificationProfiles: {
      component: <CriticalityContent />,
    },
    notifications: {
      component: <NotificationsContent />,
    },
    rules: {
      component: <AlarmsRulesContent />,
    },
    phases: {
      component: <PhasesContent />,
    },
    dataLabelings: {
      component: (
        <ErrorBoundary>
          <DataLabelingsContent />
        </ErrorBoundary>
      ),
    },
    insights: {
      component:
        tenant === 'Stellantis' ? (
          <StellantisDashboardsContent currentSelected="dashboard" />
        ) : (
          <DashboardContent currentSelected="dashboard" />
        ),
    },
    preferences: {
      component: <PreferencesModal />,
    },
    motionChart: {
      component: <MotionContent />,
    },
    /*manualYamazumi: {
      component: <DashboardContent currentSelected="saturação" />,
    },
    automaticYamazumi: {
      component: <DashboardContent currentSelected="shift" />,
    },
    cycleTimeMeasurement: {
      component: <CycleTimeMeasurementContent />,
    },*/
    reports: {
      component: <ReportsContent />,
    },
    betaReports: {
      component: <BetaReportsContent />,
    },
    dashboards: {
      component: <Dashboards />,
    },
    /*,
    dashboard: {
      component: <DashboardManageAndSecurity windowWidth={args.windowWidth} />,
    }*/
    activities: {
      component: <ActivitiesContent />,
    } /*,
    mensurations: {
      component: <MensurationsContent />,
    },*/,
    driver: {
      component: <DriverDashboard currentSelected="dashboard" />,
    },
  }

  const dispatch = useDispatch()

  // TODO: properly type this
  let tabComponent: any

  // disgusting; should check for 'created' type
  try {
    tabComponent = DictOfFetchAndComponent[path].component
    if (DictOfFetchAndComponent[path].isEnabledForThisTenant === false) {
      tabComponent = <Redirect to="/app" />
    }
  } catch {
    // find "/app/" 's index in the string of the current url
    // and use that as the fallback name
    const currentURL: string = window.location.href
    // if regexIndex is 0, something has really gone wrong
    const regexIndex: number = currentURL.match(/\/app\//)?.index || 0
    const postIndex: number = regexIndex + '/app/'.length
    const tabPath: string = currentURL.substring(postIndex)

    const finalTab = path || tabPath
    tabComponent = tabType === 'created' && <MeasuresHistTemplateContent tableName={finalTab} />
  }

  // main data
  useEffect(() => {
    //hide statsbar
    dispatch(setFalse('showStatsBar'))
    // hide most navbar icons
    dispatch(setFalse('displayingMap'))
    // highlight selected tab
    dispatch(setTableProp('current.tab', path))
  }, [dispatch, path])

  return tabComponent
}
