import React, { useState, useEffect, useMemo, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'Utils/reactTable/components/DateTime'
import { List, Divider, Image, Modal, Button, Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import ItemDetailsField from 'Components/Sidebar/Contents/Notification/ItemDetailsField'
import StatsBarFormHistoryPagination from 'Components/sideForm/StatsBarFormHistoryPagination'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { reverse } from 'lodash'
import withWindowDimensions from 'Utils/hocs/withWindowDimensions'

const ListStyled = styled(List)`
  overflow-y: auto;
`

const ListContentStyled = styled(List.Content)`
  text-align: left;
`

const ListHeaderStyled = styled(List.Header)`
  font-weight: 400px;
  color: black;
  &:hover {
    text-decoration: underline;
    color: blue;
    background-color: white;
  }
`
const ListDescriptionStyled = styled(List.Description)`
  color: black;
  &:hover {
    background-color: white;
  }
`

const DivInfo = styled.div`
  margin: 30px;
  width: 800px;
`
const FormStatsBarHistory = ({ id, moduleType, windowHeight }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [changes, setChanges] = useState([])
  const [changeId, setChangeId] = useState(0)

  const initialFields = ['label', 'code', 'geometry', 'profile', 'properties']

  const moduleStr = useSelector((state) => JSON.stringify(state.modules[moduleType].byId[id]))

  const rawChanges = useMemo(() => {
    const module = JSON.parse(moduleStr)
    return reverse([
      {
        updatedAt: module.createdAt,
        updatedBy: module.createdBy,
        fields: JSON.stringify(
          initialFields.reduce((agg, current) => {
            return { ...agg, [current]: { newValue: module[current] } }
          }, {})
        ),
      },
      ...module.history,
    ])
  }, [moduleStr, initialFields])

  const moduleProfile = useObjSelector((state) => state.profiles?.byId)

  useEffect(() => {
    if (currentId !== id) {
      setCurrentId(id)
    }
  }, [id, dispatch, moduleType, currentId])

  const firstKey = (values) => {
    if (!values) return
    let keys = Object.keys(values)
    let res = t(keys[0])
    if (keys.length > 1) res = res + ', ...'
    return ' - ' + res
  }

  const formatFileds = (i, fieldName) => {
    return (
      <ul>
        {Object.entries(JSON.parse(rawChanges?.[i]?.fields)).map((eachField, l) => {
          const field = eachField[0]
          const fieldValue = eachField[1]?.[fieldName]
          return (
            <li key={l}>
              {`${t(field)}: ${
                (['geometry', 'properties'].includes(field)
                  ? JSON.stringify(fieldValue)
                  : field === 'profile'
                  ? moduleProfile[fieldValue]?.name
                  : fieldValue) || ''
              }`}
            </li>
          )
        })}
      </ul>
    )
  }

  const handleClick = (i) => {
    setChangeId(i)
    setChanges([
      {
        field: 'closedAt',
        label: 'Realizada em',
        type: 'time',
        value: rawChanges?.[i]?.updatedAt,
      },
      {
        field: 'closedBy',
        label: 'Realizada por',
        type: 'text',
        value: rawChanges?.[i]?.updatedBy,
      },
      {
        field: 'valor original',
        label: 'Valor(es) antigo(s)',
        type: 'text',
        value: formatFileds(i, 'oldValue'),
      },
      {
        field: 'valor novo',
        label: 'Valor(es) novo(s)',
        type: 'text',
        value: formatFileds(i, 'newValue'),
      },
    ])
    setOpen(true)
  }

  return (
    <>
      <ListStyled divided relaxed style={{ height: `${windowHeight - 424}px` }}>
        {rawChanges?.map((r, i) => {
          const operation = i === rawChanges.length - 1 ? 'INSERT' : 'UPDATE'
          return (
            <Fragment key={i}>
              <List.Item key={`modHist${i}`}>
                <ListContentStyled>
                  <Image
                    title={r?.updatedBy || 'sem informação'}
                    circular
                    src={
                      'https://i1.wp.com/cdn.auth0.com/avatars/' +
                      (r?.updatedBy?.[0]?.toLowerCase() || 'si') +
                      '.png?ssl=1'
                    }
                    size="mini"
                    verticalAlign="middle"
                    style={{ float: 'left', marginRight: '5px' }}
                  />
                  <ListHeaderStyled as="a" title={t('clickToSeeMoreDetails')} value={i} onClick={() => handleClick(i)}>
                    {t('past' + operation)}
                    {operation === 'UPDATE' && firstKey(r.newvalues)}
                  </ListHeaderStyled>
                  <ListDescriptionStyled as="a">
                    <br />
                    <DateTime value={r.updatedAt} />
                  </ListDescriptionStyled>
                </ListContentStyled>
              </List.Item>
              <Divider />
            </Fragment>
          )
        })}
      </ListStyled>
      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => handleClick()}
          /*trigger={<Icon name="external" size="small" style={{ cursor: 'pointer' }} />}*/
        >
          <Modal.Header
            style={{
              paddingBottom: '10px',
              paddingTop: '10px',
              paddingRight: '0px',
              paddingLeft: '30px',
            }}
          >
            <Grid celled style={{ margin: '0', boxShadow: 'none' }}>
              <Grid.Row style={{ boxShadow: 'none' }}>
                <Grid.Column width={12} style={{ boxShadow: 'none' }}>
                  <h3 style={{ margin: '0' }}>Detalhes da Atualização</h3>
                  <h4 style={{ marginTop: '2px', fontColor: 'green' }}>
                    Por {changes[1].value} <DateTime value={changes[0].value} />
                  </h4>
                </Grid.Column>
                <Grid.Column width={4} style={{ boxShadow: 'none' }}>
                  <StatsBarFormHistoryPagination
                    gotoPage={handleClick}
                    previousPage={() => handleClick(changeId - 1)}
                    nextPage={() => handleClick(changeId + 1)}
                    canPreviousPage={changeId > 0}
                    canNextPage={changeId < rawChanges?.length - 1}
                    pageIndex={changeId}
                    pageOptionsLength={rawChanges?.length}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Header>
          <Modal.Content>
            <DivInfo>
              <Grid columns={'equal'}>
                <Grid.Column width={6} style={{ overflow: 'auto' }}>
                  <List>
                    <ItemDetailsField key={2} li={changes[2]} value={changes[2].value} />
                  </List>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={7} style={{ overflow: 'auto' }}>
                  <List>
                    <ItemDetailsField key={3} li={changes[3]} value={changes[3].value} />
                  </List>
                </Grid.Column>
              </Grid>
            </DivInfo>
          </Modal.Content>
          <Modal.Actions>
            <Button content="OK" labelPosition="right" icon="checkmark" onClick={() => setOpen(false)} positive />
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}
export default withWindowDimensions(FormStatsBarHistory)
