import React, { memo /*, useState*/ } from 'react'
import { Segment, Sidebar } from 'semantic-ui-react'

import DataLabelingSideTable from 'Utils/reactTable/modals/dataLabelings/DataLabelingSideTable'

interface Props {
  visible: boolean
}

const SideBarRight = ({ visible = false }: Props) => {
  return (
    <Sidebar
      as={Segment}
      animation="push"
      direction="right"
      visible={visible}
      style={{ width: 'auto', overflowY: 'visible', paddingTop: '0px' }}
    >
      <DataLabelingSideTable />
    </Sidebar>
  )
}
export default memo(SideBarRight)
