import React, { memo, useState, useEffect } from 'react'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { useSelector } from 'react-redux'
import { FeatureGroup, Pane, Polygon, GeoJSON, Circle, Tooltip } from 'react-leaflet'
import styled from 'styled-components'
import { semanticColors, lightColors } from 'Utils/colorsFormat'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

const PolygonStyle = styled(Polygon)`
  ${(props) => {
    return ['Tiplam', 'TiplamTest'].includes(props.empresa) ? 'cursor: grab !important' : ''
  }};
`

export const RenderFence = ({ id, click }) => {
  const moduleType = 'fences'
  const zIndex = useSelector((state) => state.modules[moduleType]?.byId[id]?.properties?.zIndex)
  const typeId = useSelector((state) => state.modules[moduleType]?.byId[id]?.profile)
  const typeDataColor = useSelector((state) => state.profiles?.byId[typeId]?.color)
  const geometry = useObjSelector((state) => state.modules[moduleType]?.byId[id]?.geometry)

  const typeGeometry = geometry?.type

  const coordinates = geometry?.coordinates
  const label = useSelector((state) => state.modules[moduleType]?.byId[id]?.label) || ''
  const color = useSelector((state) => state.modules[moduleType]?.byId[id]?.properties?.color)
  const currentId = useSelector((state) => state.modules[moduleType]?.current?.id)
  const inner = useSelector((state) => state.toggle.showInnerMap)
  const currentInner = useSelector((state) => state.indoor.current?.inner)
  const empresa = useSelector((state) => state.login.empresa)
  const fencesEditionMode = useSelector((state) => state.toggle.fencesEditionMode)

  let coords = []
  if (typeGeometry === 'Polygon')
    coords = coordinates[0].map((e) =>
      inner ? innerPosition([e[1], e[0]], 'array', currentInner?.center, currentInner?.bearing) : [e[1], e[0]]
    )
  if (typeGeometry === 'Point')
    coords = inner
      ? innerPosition([coordinates[1], coordinates[0]], 'array', currentInner?.center, currentInner?.bearing)
      : [coordinates[1], coordinates[0]]

  const statsbarId = useSelector((state) => state?.system?.statsbar?.id)
  const editionMode = useSelector((state) => state.modules[moduleType]?.geoWip?.editMode)
  const [permanentState, setPermanentState] = useState(false)
  const checkColor = (color) => ([].includes(empresa) ? lightColors(color) : semanticColors(color))
  useEffect(() => {
    if (statsbarId === id) {
      setPermanentState(true)
    }
  }, [id, statsbarId, editionMode])

  const state = useSelector((state) => state.modules[moduleType]?.byId[id]?.state)
  const fillOpacity = (id === currentId ? 0.5 : 0.2) * (state === 'closed' ? 0.4 : 1)
  const opacity = state === 'closed' ? 0.4 : 1
  const preferences = useSelector((state) => state.login.preferences[moduleType][id])

  const paneZIndex = Number(zIndex) ? 220 + Number(zIndex) : 220

  return (
    (preferences || state !== 'closed') && (
      <FeatureGroup
        key={id}
        onClick={(data) => {
          if (!fencesEditionMode && empresa === 'Tiplam') return
          click(data, id)
        }}
        clickable={true}
        onmouseover={() => setPermanentState(true)}
        onmouseout={() => id !== statsbarId && setPermanentState(false)}

        //onmouseout={() => (id !== statsbarId) && setPermanentState(false)}
      >
        <Pane name={'pane#' + id} style={{ zIndex: paneZIndex }}>
          {typeGeometry === 'Polygon' && (
            <PolygonStyle
              fillOpacity={fillOpacity}
              opacity={opacity}
              empresa={empresa}
              key={id + fencesEditionMode}
              weight={1}
              interactive
              positions={coords}
              color={typeDataColor ? typeDataColor : color ? checkColor(color) : checkColor('blue')}
            >
              <Pane name={'panett#' + id} style={{ zIndex: paneZIndex + 1 }}>
                <Tooltip key={label} permanent={id === statsbarId || permanentState} direction="bottom">
                  {label}
                </Tooltip>
              </Pane>
            </PolygonStyle>
          )}
          {typeGeometry === 'MultiPolygon' && (
            <GeoJSON
              fillOpacity={fillOpacity}
              opacity={opacity}
              key={id + fencesEditionMode}
              data={geometry}
              interactive={empresa !== 'Tiplam' || fencesEditionMode}
              color={typeDataColor ? typeDataColor : color ? checkColor(color) : checkColor('blue')}
            >
              <Pane name={'panett#' + id} style={{ zIndex: zIndex + 1 || 221 }}>
                <Tooltip permanent={permanentState} direction="bottom">
                  {label}
                </Tooltip>
              </Pane>
            </GeoJSON>
          )}
          {typeGeometry === 'Point' && (
            <Circle
              fillOpacity={fillOpacity}
              opacity={opacity}
              key={id + fencesEditionMode}
              radius={geometry.mRadius}
              interactive={fencesEditionMode}
              center={coords}
              color={typeDataColor ? typeDataColor : color ? checkColor(color) : checkColor('blue')}
            >
              <Pane name={'panett#' + id} style={{ zIndex: zIndex + 1 || 221 }}>
                <Tooltip permanent={permanentState} direction="bottom">
                  {label}
                </Tooltip>
              </Pane>
            </Circle>
          )}
        </Pane>
      </FeatureGroup>
    )
  )
}

export default memo(RenderFence)
