import * as z from 'zod'

// Função que retorna um schema com validações que requerem acesso à lista de valores existentes
const createStepFourSchema = (type: 'kpi' | 'donut') => {
  const schemas = {
    kpi: z.object({
      filters: z.object({
        agg: z.string(),
      }),
    }),
    donut: z.object({
      filters: z.object({
        agg: z.string(),
      }),
    }),
    bars: z.object({
      filters: z.object({
        agg: z.string(),
      }),
    }),
  }

  return schemas[type] ?? schemas.kpi
}

export default createStepFourSchema
