import { saveAs } from 'file-saver'

export const exportToCsv = (data: any, filename: string) => {
  // Define the CSV content with quotes around each value
  let csvContent = data
    ?.map((rowArray: any) =>
      rowArray
        .map((value: any) =>
          value
            ? `"${String(value)
                .replace(/"/g, `'`)
                .replace(/\r?\n|\r/g, '')
                .replace(/;/g, '|')}"`
            : '""'
        )
        .join(';')
    )
    .join('\r\n')

  // Create a Blob object with UTF-8 encoding
  const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' })

  // Save the CSV file
  saveAs(blob, `${filename}.csv` || 'output.csv')
}
