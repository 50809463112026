import * as z from 'zod'

// Função que retorna um schema com validações que requerem acesso à lista de valores existentes
const createStepOneSchema = (uniqueCodes: string[], uniqueLabels: string[], currentId: boolean) => {
  return z.object({
    code: z
      .string()
      .min(1, 'form.error.min')
      .max(8, 'form.error.max')
      .optional()
      .nullable()
      .refine((code) => currentId || !uniqueCodes.some((c) => c.toLowerCase() === code?.toLowerCase()), {
        message: 'Esse código já está sendo usado em outro relatório',
      }),
    label: z
      .string()
      .min(1, 'form.error.min')
      .max(60, 'form.error.max')
      .refine((label) => currentId || !uniqueLabels.includes(label), {
        message: 'Esse nome já está sendo usado em outro relatório',
      }),
    description: z.string().optional().nullable(),
  })
}

export default createStepOneSchema
