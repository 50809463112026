import { FormStepItems, StoreForm } from 'interfaces/formStepItems'
import { Report as APIReport } from 'API'
import { DropdownProps } from 'semantic-ui-react'

export interface Report extends Omit<APIReport, 'filters'> {
  filters: Record<string, unknown>
}

export interface WipReport extends Partial<Report> {}

export interface Reports {
  allIds: string[]
  byId: Record<string, Report>
  current: Report | null
  wip: WipReport
  form: StoreForm
  openSideBar: boolean
  selectedReportsIds: string[]
  loading: boolean
  loadingCategories: boolean
  categoryOptions: DropdownProps[]
  loadingMeasures: boolean
  result: Record<string, unknown>[]
  selectedAssetProp: string
  measuresByCategory: MeasuresByCategory
  loadingItem: boolean
  formType: 'filter' | 'edit' | 'save'
}

export interface MeasuresByCategory {
  [key: string]: {
    measures?: string[]
    loading: boolean
  }
}

export const reportsItems: FormStepItems[] = [
  {
    id: 'context',
    icon: 'info',
    title: 'Contexto',
    description: 'Selecione o Contexto',
    showFeedback: false,
    disabled: false,
  },
  {
    id: 'filters',
    icon: 'filter',
    title: 'Filtros',
    description: 'Selecione o(s) filtro(s)',
    showFeedback: false,
    disabled: true,
  },
  {
    id: 'time',
    icon: 'calendar',
    title: 'Intervalo',
    description: 'Selecione o Intervalo de Tempo',
    showFeedback: false,
    disabled: true,
  },
]

export const saveReportsItems: FormStepItems[] = [
  {
    id: 'id',
    icon: 'info',
    title: 'Identificação',
    description: 'Identifique o relatório',
    showFeedback: false,
    disabled: false,
  },
]

export const reports: Reports = {
  allIds: [],
  byId: {},
  current: null,
  wip: {},
  form: {
    steps: {
      current: 0,
      items: reportsItems,
    },
    open: false,
    loading: false,
    isEditing: false,
  },
  openSideBar: true,
  selectedReportsIds: [],
  loading: false,
  loadingCategories: false,
  categoryOptions: [],
  loadingMeasures: false,
  result: [],
  selectedAssetProp: '',
  measuresByCategory: {},
  loadingItem: true,
  formType: 'filter',
}
