import * as React from 'react'

import { NotificationStatus } from '../state'

//import { ReactComponent as ErrorIcon } from 'Resources/Icons/error.svg'
//import { ReactComponent as SuccessIcon } from 'Resources/Icons/success.svg'
//import { ReactComponent as WarningIcon } from 'Resources/Icons/warning.svg'
//import { ReactComponent as InfoIcon } from 'Resources/Icons/info.svg'
import { StyledCrossIcon } from './styled'
import { useTranslation } from 'react-i18next'

const getColor = (colors, status) => colors[status]

/* const getIcon = (status) => {
  switch (status) {
    case NotificationStatus.ERROR:
      return ErrorIcon
    case NotificationStatus.WARNING:
      return WarningIcon
    case NotificationStatus.SUCCESS:
      return SuccessIcon
    case NotificationStatus.NOTICE:
      return InfoIcon
    default:
      return InfoIcon
  }
} */

const getNotificationTitle = (notification) => {
  if (notification.title) {
    return notification.title
  }
  if (notification.status === NotificationStatus.SUCCESS) {
    return 'SuccessTitle'
  }
  return notification.message
}

const getNotificationMessage = (notification) => {
  if (notification.message) {
    return notification.message
  }
  if (notification.status === NotificationStatus.SUCCESS) {
    return 'SuccessMessage'
  }
  return ''
}

export const Toast = ({ toast, removeToast, getRef, colors, Button }) => {
  //const Icon = getIcon(toast.notification.status)
  const { t } = useTranslation()
  return (
    <div
      ref={getRef}
      style={{
        display: 'flex',
        backgroundColor: '#fff',
        color: 'rgba(0,0,0, 0.85)',
        borderRadius: 3,
        position: 'relative',
        xIndex: 1001,
        width: 450,
        overflow: 'hidden',
        marginBottom: '0.5rem',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: getColor(colors, toast?.notification?.status),
          width: 4,
        }}
      />
      <div style={{ display: 'flex', padding: '.75rem 1rem', width: '100%' }}>
        <div
          style={{
            color: getColor(colors, toast.notification.status),
            width: 32,
            fontSize: '1.25rem',
            lineHeight: toast.notification.message && toast.notification.title ? 1.4 : undefined,
            verticalAlign: 'middle',
          }}
        >
          {/*  <Icon /> */}
        </div>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '100%' }}>
              <div
                style={{
                  fontSize: '.975rem',
                  fontWeight: 'bold',
                  color: '#000000',
                  fontFamily: 'Poppins, sans-serif',
                  marginBottom: toast.notification.title && toast.notification.message ? '.5rem' : 0,
                  lineHeight: 1.6,
                }}
              >
                {t(getNotificationTitle(toast.notification))}
              </div>

              {toast.notification.title && (
                <div
                  style={{
                    color: 'rgba(0, 0, 0, 0.7)',
                    fontSize: '.9rem',
                  }}
                >
                  {t(getNotificationMessage(toast.notification))}
                </div>
              )}
            </div>

            <div style={{ width: 24 }}>
              <StyledCrossIcon onClick={() => removeToast(toast.id)} />
            </div>
          </div>

          <div>
            {toast.notification.actions && (
              <div
                style={{
                  display: 'flex',
                  fontSize: '.875rem',
                }}
              >
                {toast.notification.actions.primary && toast.notification.actions.primary[0] && (
                  <Button
                    primary
                    size="mini"
                    onClick={() => {
                      removeToast(toast.id)
                      toast.notification.actions.primary[0].run()
                    }}
                    style={{
                      marginTop: '1rem',
                      marginRight: '0.75rem',
                      lineHeight: 1,
                    }}
                  >
                    {toast.notification.actions.primary[0].label}
                  </Button>
                )}

                {toast.notification.actions.secondary && toast.notification.actions.secondary[0] && (
                  <Button
                    secondary
                    small
                    onClick={() => {
                      toast.notification.actions.secondary[0].run()
                    }}
                    style={{
                      marginTop: '1rem',
                      marginLeft: '0.5rem',
                      lineHeight: 1,
                    }}
                  >
                    {toast.notification.actions.secondary[0].label}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
