import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    marginTop: 24,
  },
  reportTitle: {
    color: '#000',
    fontSize: 16,
    textAlign: 'center',
  },
  reportSubTitle: {
    color: '#000',
    fontSize: 11,
    textAlign: 'center',
    marginTop: 5,
  },
})

const ReportTitle = ({ title, subTitle }) => (
  <>
    <View>
      <Text style={styles.reportTitle}>{title}</Text>
    </View>
    <View>
      <Text style={styles.reportSubTitle}>{subTitle}</Text>
    </View>
  </>
)

export default ReportTitle
