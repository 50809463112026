import React from 'react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { useSelector, useDispatch } from 'react-redux'
import { setTableProp, fetchMeasuresHistory, setShowModal } from 'Store/actions/tables-action'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'
import MeasuresHistFilterHeader from 'Utils/reactTable/modals/measuresHist/MeasuresHistFilterHeader'
import MeasuresHistAssetsDropdown from 'Utils/reactTable/modals/measuresHist/MeasuresHistAssetsDropdown'
import MeasuresHistTimeDropdown from 'Utils/reactTable/modals/measuresHist/MeasuresHistTimeDropdown'
import MeasuresHistCategoriesDropdown from 'Utils/reactTable/modals/measuresHist/MeasuresHistCategoriesDropdown'
import MeasuresHistMeasuresDropdown from 'Utils/reactTable/modals/measuresHist/MeasuresHistMeasuresDropdown'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

const FormField = styled(Form.Field)`
  margin-bottom: 18px !important;
`

function MeasuresHistFilter() {
  const dispatch = useDispatch()

  let currentTableId = useSelector((state) => state.tables.current.tab)
  if (!currentTableId || currentTableId === 'map') currentTableId = 'measuresHist'
  const showModal = useSelector((state) => state.tables.byId.measuresHist.showModal)
  const currentFilterStep = useSelector((state) => state.tables.byId[currentTableId]?.currentFilterStep || 0)
  const filterStep = useObjSelector((state) => state.tables.byId[currentTableId]?.filters?.[currentFilterStep] || {})
  const selectedRelativeTime = useSelector((state) => state.tables.byId[currentTableId].selectedRelativeTime)
  const selectedAbsoluteStartTime = useSelector((state) => state.tables.byId[currentTableId].selectedAbsoluteStartTime)
  const selectedAbsoluteEndTime = useSelector((state) => state.tables.byId[currentTableId].selectedAbsoluteEndTime)
  const showAbsoluteTime = selectedRelativeTime?.includes('absoluteTime')
  const selectedAsset = useSelector((state) => state.tables.byId[currentTableId].selectedAsset)
  const selectedCategory = useSelector((state) => state.tables.byId[currentTableId].selectedCategory)
  const selectedMeasures = useSelector((state) => state.tables.byId[currentTableId].selectedMeasures)

  function filterRightAction() {
    let showNextStep = false
    if (
      (currentFilterStep === 0 && selectedRelativeTime.length > 0 && !showAbsoluteTime) ||
      (currentFilterStep === 0 &&
        selectedRelativeTime.length > 0 &&
        showAbsoluteTime &&
        selectedAbsoluteStartTime &&
        selectedAbsoluteEndTime) ||
      (currentFilterStep === 1 && selectedAsset.length > 0) ||
      (currentFilterStep === 2 && selectedCategory.length > 0)
    ) {
      dispatch(setTableProp(`byId.${currentTableId}.currentFilterStep`, currentFilterStep + 1))
      showNextStep = true
    }
    if (currentFilterStep === 3 && selectedMeasures.length > 0) {
      showNextStep = true
      dispatch(fetchMeasuresHistory(false, currentTableId))
      dispatch(setShowModal(`measuresHist`, false))
    }
    if (!showNextStep) {
      dispatch(setTableProp(`byId.${currentTableId}.filterStepError`, currentFilterStep))
    }
  }

  return (
    <ModalTemplate
      onClose={() => dispatch(setShowModal(`measuresHist`, false))}
      open={showModal}
      size="large"
      header={<MeasuresHistFilterHeader />}
      saveContent={currentFilterStep === 3 ? 'Carregar' : 'Próximo'}
      onSave={() => {
        filterRightAction()
      }}
      onCancel={() => dispatch(setShowModal(`measuresHist`, false))}
      fixedHeight="25vh"
    >
      <Form style={{ marginTop: '5vh', marginBottom: '6.6vh' }}>
        {filterStep.id === 'relativeTime' && (
          <FormField inline required error={false}>
            <MeasuresHistTimeDropdown />
          </FormField>
        )}
        {filterStep.id === 'asset' && (
          <FormField inline required error={false}>
            <MeasuresHistAssetsDropdown />
          </FormField>
        )}
        {filterStep.id === 'category' && (
          <FormField inline required error={false}>
            <label>Categoria</label>
            <MeasuresHistCategoriesDropdown />
          </FormField>
        )}
        {filterStep.id === 'measure' && (
          <FormField inline required error={false}>
            <label>Medidas</label>
            <MeasuresHistMeasuresDropdown />
          </FormField>
        )}
      </Form>
    </ModalTemplate>
  )
}

export default MeasuresHistFilter
