import React from 'react'
import RenderFence from 'Components/Map/MapModules/RenderFence.js'
import RenderCreationFence from 'Components/Map/MapModules/RenderCreationFence.js'
import { useSelector } from 'react-redux'

export const MapFences = ({ ids, click }) => {
  const moduleType = 'fences'
  const geoWip = useSelector((state) => state.modules[moduleType].geoWip)
  const preferences = useSelector((state) => state.login.preferences[moduleType])

  return (
    <>
      {geoWip?.geoJson && <RenderCreationFence key={'CreatingFences'} wip={geoWip} click={click} />}
      {ids
        ?.filter((f) => !preferences[f]?.invisible)
        .map((id) => {
          let moduleId = id?.toString()
          if (!moduleId) {
            console.error('Module without id!')
            return null
          }
          return <RenderFence key={moduleId} id={moduleId} click={click} />
        })}
    </>
  )
}
