import React from 'react'
import { Segment } from 'semantic-ui-react'
import { useCanAccess } from 'Utils/rbac'
import SideFormSearchAction from './SideFormSearchAction'
import SideFormDeleteAction from './SideFormDeleteAction'
import SideFormStateAction from './SideFormStateAction'
import SideFormEditAction from './SideFormEditAction'
import SideFormHideAction from './SideFormHideAction'
import useEntitySelector from 'Utils/hooks/useEntitySelector'
import SideFormDebugAction from './SideFormDebugAction'

interface StatsBarActionsProps {
  id: string
  moduleType: 'fences' | 'refs' | 'routes'
}

const SideFormActions: React.FC<StatsBarActionsProps> = ({ id, moduleType }) => {
  const canAccess = useCanAccess('WRITE')
  const devAccess = useCanAccess('DEBUG')

  const label = useEntitySelector('modules', 'label')
  const state = useEntitySelector('modules', 'state')

  return (
    <Segment style={{ width: '100%', bottom: '64px', position: 'absolute', border: 'none', alignItems: 'left' }}>
      <SideFormSearchAction moduleType={moduleType} id={id} />
      {canAccess && state !== 'closed' && <SideFormEditAction id={id} moduleType={moduleType} />}
      <SideFormHideAction id={id} moduleType={moduleType} />
      {canAccess && state !== 'closed' && <SideFormDeleteAction id={id} moduleType={moduleType} label={label} />}
      {canAccess && id && <SideFormStateAction id={id} moduleType={moduleType} label={label} state={state} />}
      {devAccess && <SideFormDebugAction id={id} label={label} moduleType={moduleType} />}
    </Segment>
  )
}

export default SideFormActions
