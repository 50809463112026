import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { updateWiP } from 'Store/actions/modules-action'
import { setFalse, setTrue } from 'Store/actions/toggle-action'
import { RootStore } from 'Store/initialStore'

interface SideFormEditActionProps {
  id: string
  moduleType: 'fences' | 'refs' | 'routes'
}

const SideFormEditAction: FC<SideFormEditActionProps> = ({ id, moduleType }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const editMode = useSelector((state: RootStore) => state.modules?.[moduleType]?.geoWip?.editMode)
  const wipId = useSelector((state: RootStore) => state.modules?.[moduleType]?.geoWip?.id)
  const active = editMode && wipId === id

  const handleClick = () => {
    dispatch(active ? setFalse('fencesEditionMode') : setTrue('fencesEditionMode'))
    dispatch(updateWiP({ moduleType, changes: { id, editMode: !active } }))
  }

  return (
    <Button
      circular
      icon="edit"
      title={t('lbledit')}
      style={{
        backgroundColor: active ? '#1678c2' : undefined,
        cursor: 'pointer',
      }}
      onClick={handleClick}
    />
  )
}

export default SideFormEditAction
