import React, { Component } from 'react'
import ReleaseNotes from 'Utils/modals/ReleaseNotes.js'
//import packageInfo  from "../../../package.json";

import './footer.css'
/*  Author: Bruno Melo
    Type: Dumb
    Description: Show version; year of version; phygitall corporation mark; marks page end;
    To do list: -Transformar num componente função com extensão jsx;
                -Acrescentar CSS deste componente aqui dentro;
                -Trocar o ano pra propriedade;
                -Trocar a versão da plataforma pra propriedade;
                -Conectar a versão da plataforma a store;
*/
export default class Footer extends Component {
  state = { openModal: false }

  render() {
    let year = new Date().getFullYear()
    return (
      <footer id="FooterContainer">
        <span>
          © {year}{' '}
          <a href="https://www.phygitall.com.br" target="_blank" rel="noopener noreferrer">
            Phygitall{' '}
          </a>
          <ReleaseNotes />
        </span>
      </footer>
    )
  }
}
