/*  
  Author: Luís Mourão
  Type: Reducer
  
  Description: 
    - Medidas dos Ativos.
    - Atualizado também em tempo real com a chegada de novas coordenadas
    - Medidas do Popup carregadas a partir do clique nos ícones do popup
	
  TODO: 
    - 
*/

import initialStore from 'Store/initialStore'
import produce from 'immer'
import moment from 'moment'

export default function measures(state = initialStore.measures, { type, assetId, payload, category, assets, id }) {
  const splitType = type.split('#')[0]

  switch (splitType) {
    case 'FETCH_ASSETS_LOCATION_SUCCESS':
      return produce(state, (newState) => {
        const filteredPayload = payload.filter((eachMeasure) => assets[eachMeasure.assetId])
        filteredPayload.forEach(
          (eachAsset) =>
            (newState.byId[eachAsset.assetId] = {
              deviceId: eachAsset.deviceId,
              info: {
                coords: {
                  lat: eachAsset.lat,
                  lng: eachAsset.lng,
                  time: eachAsset.coords?.time,
                },
                fencesLocation: eachAsset.fencesLocation ? JSON.parse(eachAsset.fencesLocation) : null,
                time: eachAsset.time,
              },
            })
        )

        newState.allIds = Object.keys(newState.byId).map((assetId) => assetId)

        newState.timeActiveAssets = newState.allIds.reduce((agg, assetId) => {
          const currentAsset = newState.byId[assetId]
          const currentAssetTime = currentAsset?.info?.time

          const diffTime = currentAssetTime ? moment(currentAssetTime).diff(moment()) / 1000 : undefined

          if (diffTime > -1800) {
            return [...agg, assetId]
          }
          return agg
        }, [])
      })

    case 'NEW_ASSET_LOCATION':
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.assetId]: {
            info: {
              ...state.byId[payload.assetId]?.info,
              coords: { lat: payload.lat, lng: payload.lng, time: payload.coords?.time },
              locationAnalytics: payload.location ? JSON.parse(payload.location) : null,
              motionChart: payload.motion ? JSON.parse(payload.motion) : null,
              time: payload.time,
            },
            deviceId: payload.deviceId,
          },
        },
        timeActiveAssets: state.timeActiveAssets.includes(payload.assetId)
          ? state.timeActiveAssets
          : [...state.timeActiveAssets, payload.assetId],
      }

    case 'FETCH_ACTIVE_ASSETS':
      return produce(state, (newState) => {
        newState.timeActiveAssets = state.allIds.reduce((agg, assetId) => {
          const currentAsset = state.byId[assetId]
          const currentAssetTime = currentAsset?.info?.time

          const diffTime = currentAssetTime ? moment(currentAssetTime).diff(moment()) / 1000 : undefined

          if (diffTime > -180) {
            return [...agg, assetId]
          }
          return agg
        }, [])
      })

    case 'FETCH_CATEGORIES_START':
      return { ...state, loadingCategories: true, currentAsset: assetId }

    case 'FETCH_CATEGORIES_SUCCESS':
      return produce(state, (newState) => {
        const oldInfo = state.byId[assetId].info
        newState.byId[assetId].info = { ...payload, coords: oldInfo.coords }
        if (!state.byId[assetId].info.time) newState.byId[assetId].info.time = oldInfo.coords.time
        newState.loadingCategories = false
      })

    case 'FETCH_CATEGORIES_FAILURE':
      return { ...state, loadingCategories: false }

    case 'FETCH_CATEGORY_MEASURES_START':
      return produce(state, (newState) => {
        if (state.byId[assetId]) newState.byId[assetId].currentCategory = category
        newState.loadingCategoryMeasures = category !== 'coords' && payload.load
      })

    case 'FETCH_CATEGORY_MEASURES_SUCCESS':
      return produce(state, (newState) => {
        newState.byId[assetId].info[category] = payload[category]
        newState.loadingCategoryMeasures = false
      })

    case 'FETCH_ASSET_COORDS_START':
      return { ...state, loadingAssetCoords: true }

    case 'FETCH_ASSET_COORDS_SUCCESS':
      return produce(state, (newState) => {
        if (payload.lat) {
          newState.byId[assetId] = {
            info: {
              coords: payload,
              time: payload.time,
            },
          }
          newState.loadingAssetCoords = false
        }
      })

    case 'SET_CURRENT_ASSET':
      return { ...state, current: { ...state.current, ...state.byId[id] } }

    default:
      return state
  }
}
