import React, { useState, lazy, Suspense, useCallback } from 'react'
import { Dropdown, Icon, Grid, Popup /*, Label */ } from 'semantic-ui-react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

import Loading from 'Utils/components/Loading'
import { useSelector } from 'react-redux'

const DropdownEmergencyContent = lazy(() => import('./DropdownEmergencyContent.js'))

const DropdownStyled = styled(Dropdown)`
  color: #4b72b2;
  pointer-events: auto;
`

export default function DropdownEmergency() {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const showEmergencyModal = useSelector((state) => state.toggle.showEmergencyModal)

  const handleEmergencyModal = useCallback(() => {
    if (!showEmergencyModal) {
      setOpen(false)
    }
  }, [showEmergencyModal])

  return (
    <DropdownStyled
      trigger={
        <Popup
          trigger={<Icon name={'bullhorn'} size="large" onClick={() => setOpen(!open)} />}
          content={t('emergency')}
          position="bottom center"
          size="tiny"
          inverted
        />
      }
      icon={null}
      pointing="top right"
      open={open}
      onBlur={handleEmergencyModal}
    >
      <Dropdown.Menu>
        <Suspense
          fallback={
            <Container padded>
              <Loading />
            </Container>
          }
        >
          {open && <DropdownEmergencyContent />}
        </Suspense>
      </Dropdown.Menu>
    </DropdownStyled>
  )
}

const Container = styled(Grid)`
  width: 400px;
  height: 100px;
  padding-top: 8px !important;
`
