import React from 'react'
import { Form, Input, Select } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useCanAccess } from 'Utils/rbac'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import StatsBarFormCustomFields from './StatsBarFormCustomFields'
import useEntitySelector from 'Utils/hooks/useEntitySelector'
import { useDispatch } from 'react-redux'
import { setModulesProps } from 'Store/actions/modules-action'
import withWindowDimensions from 'Utils/hocs/withWindowDimensions'

const StatsBarFormMain = ({ windowHeight = 0 }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const profileIds = useObjSelector((state) => state.profiles.allIds)
  const profilesById = useObjSelector((state) => state.profiles.byId)

  const type = useEntitySelector('modules', 'type')
  const code = useEntitySelector('modules', 'code')
  const label = useEntitySelector('modules', 'label')
  const state = useEntitySelector('modules', 'state')
  const profileId = useEntitySelector('modules', 'profile')

  const isClosed = state === 'closed'

  const errors = useObjSelector((state) => state.modules.form?.errors)

  const options = profileIds
    .filter((id) => profilesById[id].type === type)
    .map((id) => ({
      key: id,
      text: profilesById[id].name,
      value: id.toString(),
      title: profilesById[id].description || profilesById[id].name,
    }))
    .sort((a, b) => (a.text && b.text ? a.text.toLowerCase().localeCompare(b.text.toLowerCase()) : 1))
  const canAccess = useCanAccess('WRITE')

  return (
    <Form
      id="mainSideForm"
      style={{
        paddingLeft: '10px',
        overflowY: 'auto',
        height: `${windowHeight - 424}px`,
        paddingRight: '20px',
        bottom: '5px',
      }}
    >
      <Form.Group>
        <Form.Field
          readOnly={!canAccess || isClosed}
          id="code"
          control={Input}
          label={t('ID')}
          placeholder={t('ID')}
          width="6"
          onChange={(_, { value, id }) => dispatch(setModulesProps([[`wip.${id}`, value]]))}
          value={code ?? ''}
        />

        <Form.Field
          readOnly={!canAccess || isClosed}
          id="label"
          control={Input}
          label={t('Name')}
          placeholder={t('Name')}
          width="10"
          value={label ?? ''}
          onChange={(_, { value, id }) => dispatch(setModulesProps([[`wip.${id}`, value]]))}
          error={!!errors?.label}
        />
      </Form.Group>

      <Form.Field
        required={false}
        disabled={!canAccess || isClosed}
        control={!canAccess ? Input : Select}
        options={options}
        label={{ children: 'Atribuição', htmlFor: 'form-select-control-gender' }}
        placeholder={t('SelectType')}
        id={'profile'}
        value={profileId}
        search
        searchInput={{ id: 'form-type-search' }}
        width="16"
        onChange={(_, { value, id }) => dispatch(setModulesProps([[`wip.${id}`, value ?? '']]))}
        clearable={!(!canAccess || isClosed)}
      />
      {profileId && <StatsBarFormCustomFields />}
    </Form>
  )
}
export default withWindowDimensions(StatsBarFormMain)
