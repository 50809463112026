export default function modelsReducer(state = [], { type, payload }) {
  switch (type) {
    case 'FETCH_MODELS_SUCCESS':
      if (payload === null) {
        console.error('Error in models initial store')
        return []
      }
      if (!payload[0]) {
        console.warn('No models in store!')
        return []
      }
      if (!payload[0].models) {
        console.warn("model's store empty!")
        return []
      }
      return payload[0].models
    default:
      return state
  }
}
