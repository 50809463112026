import React, { KeyboardEvent } from 'react'
import { Button, Modal, Dropdown, TextArea, Form } from 'semantic-ui-react'

interface NotificationFeedbackModalProps {
  sendFeedback: (params: {
    notificationId: string
    assetId: string
    state: 'confirmed' | 'closed'
    details: string
    scenarioType: string
  }) => void
  assetId: string
  notificationId: string
}

const FEEDBACK_TEXT = {
  HELPER: 'Por favor, indique o tipo de emergência prestada',
}

const NotificationFeedbackModal = ({ sendFeedback, assetId, notificationId }: NotificationFeedbackModalProps) => {
  const [open, setOpen] = React.useState(false)
  const [emergencyType, setEmergencyType] = React.useState('')
  const [details, setDetails] = React.useState('')

  const handleEmergencyType = (e: any, { value }: any) => {
    setEmergencyType(value)
  }

  const handleFeedback = () => {
    sendFeedback({
      notificationId,
      assetId,
      state: 'closed',
      details,
      scenarioType: emergencyType,
    })
    setOpen(false)
  }

  const handleDetailsChange = (e: any) => {
    setDetails(e.target.value)
  }

  return (
    <Modal
      open={open}
      size="tiny"
      trigger={
        <div style={{ marginLeft: '3px' }}>
          <span style={{ color: '#002fb5', fontStyle: 'italic', cursor: 'pointer' }} onClick={() => setOpen(true)}>
            {'Concluir'}
          </span>
        </div>
      }
    >
      <Modal.Header>Conclusão de Atendimento</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field required>
              <label>{FEEDBACK_TEXT.HELPER}</label>
              <Dropdown
                onChange={handleEmergencyType}
                placeholder="Tipo de Emergência"
                value={emergencyType}
                fluid
                selection
                options={[
                  { key: '1', text: 'Real', value: 'real' },
                  { key: '2', text: 'Simulado', value: 'simulado' },
                  { key: '3', text: 'Alarme Falso', value: 'alarme falso' },
                ]}
              />
            </Form.Field>
            <Form.Field required>
              <label>Detalhes do ocorrido</label>
              <TextArea
                placeholder="Descreva o ocorrido"
                onChange={handleDetailsChange}
                value={details}
                onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === ' ') {
                    ;(e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value + ' '
                    e.stopPropagation()
                  }
                }}
              />
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancelar" labelPosition="right" onClick={() => setOpen(false)} />
        <Button
          content="Concluir"
          labelPosition="right"
          icon="checkmark"
          onClick={handleFeedback}
          positive
          disabled={!(emergencyType && details)}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default NotificationFeedbackModal
