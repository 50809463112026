import React from 'react'
import ReactTable from 'Utils/reactTable'
import { AccessColumns } from './columns'
import { useTranslation } from 'react-i18next'
import useAsync from 'Utils/hooks/useAsync'
import { URL_BASE } from '../../../../Apis/rest'
import { RelativeDate } from 'Utils/date'

const tableName = 'accessControl'

async function fetchAccessControl(relativeDate = RelativeDate.TODAY) {
  return fetch(`${URL_BASE}/access-control?relativeDate=${relativeDate}`).then((res) => res.json())
}

export const AccessControl = () => {
  const { execute, value, status /* , error  */ } = useAsync(fetchAccessControl, true)

  const { t } = useTranslation()

  const [currentSort, setCurrentSort] = React.useState('label')

  const columns = React.useMemo(() => AccessColumns(t), [t])

  const menuButtons = ['refresh']

  return (
    <ReactTable
      table={tableName}
      columns={columns}
      data={value ?? []}
      fetchAction={execute}
      setCurrentSort={setCurrentSort}
      currentSort={currentSort}
      showPlaceholders={status === 'pending'}
      menuButtons={menuButtons}
    />
  )
}
