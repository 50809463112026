import * as z from 'zod'

export const stepsSchema = {
  0: z.object({
    name: z.string().min(3, 'error.len.min'),
    duration: z.number().min(1, 'error.min').max(604800, 'error.max'),
    interval: z.number().min(1, 'error.min').max(604800, 'error.max'),
  }),
  1: z.object({
    route: z.string(),
    asset: z.string(),
  }),
}

/* export type StepOne = z.infer<typeof stepsSchema['stepOne']>
export type StepsType = z.infer<typeof stepsSchema> */

export default stepsSchema

//export default {}
