import { Component } from 'react'
import L from 'leaflet'

class MeasureTool extends Component {
  languageDecod(lang) {
    let lan
    switch (lang) {
      case 'es':
        lan = 'es'
        break
      case 'fr':
        lan = 'fr'
        break
      case 'pt':
        lan = 'pt_BR'
        break
      case 'pt-BR':
        lan = 'pt_BR'
        break
      case 'zh':
        lan = 'cn'
        break
      case 'en':
        lan = 'en'
        break
      default:
        lan = 'pt_BR'
        break
    }
    return lan
  }

  state = {
    controlState: this.props.measureControl,
  }

  componentDidMount() {
    const { refMap, options, lang } = this.props
    require(`leaflet-measure/dist/leaflet-measure.${this.languageDecod(lang)}.js`)
    var measureControl = L.control.measure({ ...options })
    measureControl.addTo(refMap)
    this.setState({ controlState: measureControl })
  }

  componentWillUnmount() {
    if (this.state.controlState._map !== null) {
      this.state.controlState._startMeasure()
      this.state.controlState._finishMeasure()
    }
    this.state.controlState.remove()
  }

  render() {
    return null
  }
}

export default MeasureTool
