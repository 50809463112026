/*  Author: Luís Mourão
    Type: Dumb
	Description: Formatação e posicionamente da msg de histórico
	TODO: 
*/

import React from 'react'
import m from 'moment'
import { useTranslation } from 'react-i18next'
import HistInfoDisplay from './HistInfoDisplay'
import { getMinTime } from 'Utils/getSliderTime.js'
import { useSelector, shallowEqual } from 'react-redux'

export const HistoryInfo = ({ loadingScreen, heatMap, start, end, heatMapTotal, onClick }) => {
  const { t } = useTranslation()

  const min = useSelector((state) => state.history.slider.filteredMin)
  const max = useSelector((state) => state.history.slider.filteredMax)
  const startTime = useSelector((state) => state.history.startTime)
  const endTime = useSelector((state) => state.history.endTime)

  const timeStart = useSelector((state) => state.filters.timeStart)
  const timeEnd = useSelector((state) => state.filters.timeEnd)

  const histAllIds = useSelector((state) => state.history.allIds, shallowEqual)
  const histById = useSelector((state) => state.history.byId, shallowEqual)
  const showHistoryCalendar = useSelector((state) => state.toggle.showHistoryCalendar)

  //const histHoles = useSelector((state) => state.history.holes.items.length > 0)

  const histType = useSelector((state) => state.history.type)

  const minTime = getMinTime(histAllIds, histById, min)
  const maxTime = getMinTime(histAllIds, histById, max)

  const formatDate = (t, f) => {
    return m(t).local().format(f)
  }

  let firstElement, secondElement
  if (['hole'].includes(histType)) {
    firstElement = formatDate(timeStart, 'lll')
    secondElement = formatDate(timeEnd, 'lll')
  } else {
    if ((heatMap && !start && !end) || (!heatMap && !minTime && !maxTime && showHistoryCalendar)) {
      firstElement = t('HistorySliderSelect')
    } else if (loadingScreen) {
      firstElement = t('HistoryCharging')
    } else if (histAllIds.length === 0 && !heatMap) {
      firstElement = `${formatDate(startTime, 'lll')} / ${formatDate(endTime, 'lll')}`
    } else if (histAllIds.length && !heatMap) {
      firstElement = formatDate(minTime, 'lll')
      secondElement = formatDate(maxTime, 'lll')
    } else if (minTime === maxTime && !heatMap) {
      firstElement = formatDate(minTime, 'lll')
    } else if (heatMap && heatMapTotal) {
      firstElement = formatDate(start, 'lll')
      secondElement = formatDate(end, 'lll')
    } else if (heatMap && heatMapTotal === 0) {
      firstElement = `${t('HistorySliderNoData')} ${(formatDate(start), 'lll')} / ${(formatDate(end), 'lll')}`
    } else if (start === end) {
      firstElement = formatDate(start, 'lll')
    } else {
      firstElement = formatDate(start, 'lll')
      secondElement = formatDate(end, 'lll')
    }
  }
  return <HistInfoDisplay firstElement={firstElement} secondElement={secondElement} onClick={onClick} />
}
