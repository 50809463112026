import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Icon, Label, Placeholder, Image } from 'semantic-ui-react'
import styled from 'styled-components'

const CardMeta = styled('div')`
  padding-top: 5px;
  padding-bottom: 10px;
  text-align: center;
`

const CardPlaceholder = styled(Placeholder)`
  width: 200px;
  height: 200px;
`

const CardPhoto = styled(Image)`
  width: 200px;
  max-height: 200px;
`

const CardImage = styled('div')`
  vertical-align: bottom;
  padding: 2px;
  display: table-cell;
`

export const CardHeader = styled('div')`
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  font-weight: bold;
`
const ImageStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '15px',
  paddingTop: '12px',
}

interface PeopleBoardContentProps {
  assetId: string
  name: string
  imageUri: string
  fenceNames?: string[]
  loadingImage: boolean
}

const PeopleBoardContent = ({ assetId, name, imageUri, fenceNames, loadingImage }: PeopleBoardContentProps) => {
  const { t } = useTranslation()
  return (
    <Card id={assetId} color={'green'} style={ImageStyle}>
      <CardImage>
        {loadingImage ? (
          <CardPlaceholder>
            <Placeholder.Image />
          </CardPlaceholder>
        ) : (
          <CardPhoto src={imageUri} circular />
        )}
      </CardImage>

      <CardHeader>
        <Card.Header> {name} </Card.Header>
      </CardHeader>

      <CardMeta>
        <Card.Meta>
          {fenceNames && (
            <Label color="green">
              {fenceNames?.length ? (fenceNames.length > 1 ? fenceNames.join(', ') : fenceNames) : t('none')}{' '}
              <Icon name="building" />
            </Label>
          )}
        </Card.Meta>
      </CardMeta>
    </Card>
  )
}

export default PeopleBoardContent
