import React from 'react'
import { Document, StyleSheet } from '@react-pdf/renderer'
import PDFPage from './PDFPage'
import calculatePercentile from 'Utils/calculatePercentile'
import { max } from 'lodash'

const ReportInPDF = ({ data }) => {
  /** iterate through all items
   * for each column, find the largest string elements
   * sum all the largest sizes
   * set a percentage for each column based on the proportion of each largest element
   * this function assumes that every row will have the same style
   */
  const generateStyles = (items, columns) => {
    const borderColor = '#E5E4E2'
    if (!items[0]) {
      return
    }

    const maxAllowedSize = 25
    const colsPercentileSizes = columns.reduce((acc, col) => {
      let colSizes = items.map((item) => {
        const itemSize = item[col]
          ? typeof item[col] === 'object'
            ? JSON.stringify(item[col]).length
            : item[col].toString().length
          : 0
        return itemSize
      })
      const colNameSplitSize = col.split(/[\s/]+/)[0].length + 1
      let colPercentile = calculatePercentile(colSizes, 61.8)
      if (colPercentile === 0 && max(colSizes) > 100) {
        colPercentile = maxAllowedSize
      }

      acc[col] = Math.min(Math.max(colPercentile, colNameSplitSize), maxAllowedSize)
      return acc
    }, {})

    const sumColsSize = Object.values(colsPercentileSizes).reduce((sum, size) => sum + size, 0)

    const headerColsStyle = {}
    const rowColsStyle = {}
    let entryCounter = 0
    let percentageSumCounter = 0

    for (const [key, value] of Object.entries(colsPercentileSizes)) {
      entryCounter++
      const isLastEntry = entryCounter === columns.length
      let percentage = Math.round((value / sumColsSize) * 100)

      // Adjust the last percentage to ensure total sums up to 100
      if (isLastEntry) {
        percentage = 100 - percentageSumCounter
      }

      percentageSumCounter += percentage

      headerColsStyle[key] = {
        width: percentage + '%',
        ...(entryCounter < columns.length && {
          borderRightColor: borderColor,
          borderRightWidth: 1,
        }),
      }

      rowColsStyle[key] = {
        width: percentage + '%',
        textAlign: 'center',
        //paddingLeft: 2,
        ...(entryCounter < columns.length && {
          borderRightColor: borderColor,
          borderRightWidth: 1,
        }),
      }
    }

    const styles = {
      headerStyles: StyleSheet.create({
        container: {
          flexDirection: 'row',
          borderBottomColor: '#bff0fd',
          backgroundColor: '#bff0fd',
          alignItems: 'center',
          height: 20,
          textAlign: 'center',
          fontStyle: 'bold',
          flexGrow: 1,
        },
        ...headerColsStyle,
      }),
      rowStyles: StyleSheet.create({
        row: {
          flexDirection: 'row',
          borderBottomColor: borderColor,
          borderBottomWidth: 1,
          alignItems: 'center',
          minHeight: 20,
          paddingBottom: 5,
          fontStyle: 'bold',
          paddingRight: 1,
        },
        ...rowColsStyle,
      }),
    }

    return styles
  }

  const columns = Object.keys(data.items[0] || {})
  // there is always a 'key' that should not be rendered
  const keyIndex = columns.indexOf('key')
  if (keyIndex > -1) {
    columns.splice(keyIndex, 1)
  }

  const allStyles = generateStyles(data.items, columns)

  //const uuid = localStorage.getItem('uuid')
  const reportTitle = localStorage.getItem('reportTitle')
  const reportSubTitle = localStorage.getItem('reportSubTitle')
  const intervalDate = localStorage.getItem('reportInterval')

  // Split the data into chunks based on the row height and page height
  // const pageHeight = document.documentElement.clientHeight
  // const pageHeight = 595
  // const rowHeight = 50
  // const chunkSize = Math.floor(pageHeight / rowHeight)
  const chunkSize = 20
  const dataChunks = []
  for (let i = 0; i < data.items.length; i += chunkSize) {
    dataChunks.push(data.items.slice(i, i + chunkSize))
  }

  return (
    <Document title={reportSubTitle}>
      {dataChunks.map((pageData, i) => (
        <PDFPage
          key={i}
          title={reportTitle}
          subtitle={reportSubTitle}
          transDate={data.transDate}
          intervalDate={intervalDate}
          items={pageData}
          allStyles={allStyles}
          pageNumber={i + 1}
          totalPages={dataChunks.length}
          columns={columns}
          totalItems={data.items.length}
        />
      ))}
    </Document>
  )
}

export default ReportInPDF
