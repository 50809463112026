import { HexColors } from 'interfaces/dataLabelings'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Icon, Header, Grid, Segment, Label, Dropdown } from 'semantic-ui-react'
import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import FormGroup from '../FormGroup'
import { useTranslation } from 'react-i18next'
import { setDataLabelingsProp } from 'Store/actions/dataLabelings-actions'
import { VEHICLES_OPTIONS } from 'Utils/stellantisConsts'

interface Props {
  entity: 'dataLabelings' | 'mensurations'
  onRemovePhase: Function
  onChangePhase: Function
}

const ActivitiesPhasesForm = ({ entity, onRemovePhase = () => null, onChangePhase }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentStep = useSelector((state: RootStore) => state?.[entity].form.steps.current)
  const phases = useObjSelector((state: RootStore) => state[entity].wip?.phases || [])
  const phasesFormError = useObjSelector((state: RootStore) => state[entity].form?.errors?.[currentStep]?.phases)
  const showFeedback = useSelector((state: RootStore) => state[entity].form?.steps.items[currentStep].showFeedback)

  const dataLabelingsIds = useObjSelector((state: RootStore) => state.dataLabelings.allIds)
  const dataLabelingsById = useObjSelector((state: RootStore) => state.dataLabelings.byId)

  const activityId = useSelector((state: RootStore) => state.dataLabelings.wip.activityId)
  const wipId = useSelector((state: RootStore) => state.dataLabelings.wip.id)

  const lastPhases = useMemo(() => {
    if (!wipId) {
      let currentCreatedAt = ''
      return dataLabelingsIds.reduce((acc: any, eachId) => {
        const dataLabeling = dataLabelingsById[eachId]
        if (dataLabeling.activityId === activityId) {
          if (dataLabeling.createdAt > currentCreatedAt) {
            acc = dataLabeling.phases
          }
        }
        return acc
      }, [])
    }
    return []
  }, [dataLabelingsIds, dataLabelingsById, activityId, wipId])

  const TICON = ['Ab', 'Af', 'Hc', 'Pb', 'Pc', 'Ptmin']

  const ticonOptions = TICON.map((ticonItem) => ({
    key: ticonItem,
    text: ticonItem,
    value: ticonItem,
  }))

  useEffect(() => {
    const newPhase = phases?.length === 0 ? (lastPhases.length > 0 ? lastPhases : [{ index: 0, name: '' }]) : undefined
    if (newPhase) {
      dispatch(setDataLabelingsProp(`wip.phases`, newPhase))
    }
  }, [phases, lastPhases, dispatch])

  return (
    <>
      {phases?.map((phase: any, index: number) => {
        return (
          <>
            <Grid padded="horizontally">
              <Grid.Row>
                <Grid.Column width={12} verticalAlign="middle">
                  <Header as="h4" textAlign="left">{`Fase ${index + 1}`}</Header>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  <Icon
                    name="trash"
                    onClick={() => {
                      onRemovePhase(index)
                    }}
                    circular
                    disabled={index === 0 && phases.length === 1}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Segment style={{ paddingBottom: '18px' }}>
              <>
                <Form.Field inline required error={showFeedback && !phase?.name}>
                  <label>{t('trigger')}</label>
                  <Form.Input
                    name="name"
                    fluid
                    placeholder="Escreva o gatilho da fase"
                    value={phase?.name}
                    onChange={(e, { name: field, value }) => {
                      onChangePhase({ index, field, value })
                    }}
                  />
                </Form.Field>
                <FormGroup widths="equal">
                  <Form.Field inline>
                    <label>{t('code')}</label>
                    <Input
                      name="code"
                      fluid
                      placeholder="Digite o código"
                      value={phase?.code}
                      onChange={(e, { name: field, value }) => {
                        onChangePhase({ index, field, value })
                      }}
                    />
                  </Form.Field>
                  <Form.Field inline required error={showFeedback && !phase?.color}>
                    <label>{t('color')}</label>
                    <Dropdown
                      placeholder="Selecione uma cor"
                      fluid
                      selection
                      value={phase?.color}
                      options={Object.values(HexColors).map((color) => ({
                        key: color,
                        text: t(color),
                        value: color,
                      }))}
                      onChange={(e, { value }) => onChangePhase({ index, field: 'color', value })}
                    />
                  </Form.Field>
                </FormGroup>
                <FormGroup widths="equal">
                  <Form.Field inline>
                    <label>{`${t('vehicle')}(s)`}</label>
                    <Dropdown
                      placeholder="Selecione o(s) veículo(s)"
                      fluid
                      selection
                      value={phase?.vehicle?.map((each: any) => each.name)}
                      options={VEHICLES_OPTIONS}
                      onChange={(e, { value }) => {
                        if (typeof value === 'object') {
                          onChangePhase({
                            index,
                            field: 'vehicle',
                            value: value.map((each) => ({ name: each })),
                          })
                        }
                      }}
                      multiple
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <label>{`${t('motion')}(s)`}</label>
                    <Dropdown
                      placeholder="Selecione o(s) movimento(s)"
                      fluid
                      selection
                      value={phase?.ticon?.map((each: any) => each.name)}
                      options={ticonOptions.map((opt) => {
                        const ticonItem = phase?.ticon?.find((each: any) => each.name === opt.value)
                        return {
                          ...opt,
                          text: ticonItem?.qty && ticonItem.qty > 1 ? `${opt.text} ${ticonItem.qty}x` : `${opt.text}`,
                        }
                      })}
                      onChange={(e, { value }) => {
                        if (typeof value === 'object') {
                          onChangePhase({
                            index,
                            field: 'ticon',
                            value: value.map((each) => {
                              const currentPhaseTicon = phase?.ticon?.find((e: any) => e.name === each)
                              return { name: each, qty: currentPhaseTicon?.qty ?? 1 }
                            }),
                          })
                        }
                      }}
                      multiple
                      onLabelClick={(_, { value = '' }) => {
                        onChangePhase({
                          index,
                          field: 'ticon',
                          value: phase?.ticon?.map((each: any) => {
                            if (each.name === value) return { ...each, qty: (each.qty ?? 0) + 1 }
                            else return each
                          }),
                        })
                      }}
                    />
                  </Form.Field>
                </FormGroup>
                <Form.Field inline>
                  <label>{t('description')}</label>
                  <Form.TextArea
                    name="description"
                    fluid
                    placeholder="Descreva a fase"
                    value={phase?.description || ''}
                    onChange={(e, { name: field, value }) => {
                      onChangePhase({ index, field, value })
                    }}
                  />
                </Form.Field>
              </>
            </Segment>
            <br />
          </>
        )
      })}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Icon
          name={'plus'}
          color={'grey'}
          circular
          title={'Adicionar Fase'}
          onClick={() => onChangePhase({ index: phases?.length, field: 'description', value: '' })}
          style={{ cursor: 'pointer' }}
        />
      </div>
      {showFeedback && phasesFormError && (
        <Label color="red">{`Preencha os campos obrigatórios destacados com  (*)`}</Label>
      )}
    </>
  )
}

export default ActivitiesPhasesForm
