import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import monitorReducersEnhancer from './enhancers/monitorReducers'
import loggerMiddleware from './middleware/logger'
import rootReducer from './reducers/allreducers'
import initialStore from './initialStore'
import setupDynamicMiddleware from './middleware/dynamic'

/*  Author: Bruno Melo
    Type: Const Store
    Description: Join all reducers, actions, middlewares and enhancers;
                 Starts websocket communication;
                 Get the info from database through websocket;
    To do list: -Possibilidade de pegar a informação pela cache
*/

const INFO_DEBUG = 'Comente esse campo na configureStore para debugar'

export default function configureStore() {
  const middlewares = [loggerMiddleware, thunkMiddleware, setupDynamicMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [middlewareEnhancer, monitorReducersEnhancer]

  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    enhancers.push(
      window.__REDUX_DEVTOOLS_EXTENSION__({
        //actionSanitizer,
        stateSanitizer: (state) => {
          return {
            ...state,
            assets: state.assets
              ? {
                  ...state.assets,
                  byId: 'check data in assets.current',
                  associations: 'Develop page association',
                  allIds: `${state.assets.allIds?.length} assetsIds`,
                  allActiveIds: `${state.assets.allActiveIds?.length} allActiveIds`,
                  allActivePeopleIds: `${state.assets.allActivePeopleIds?.length} allActivePeopleIds`,
                  allActiveAppIds: `${state.assets.allActiveAppIds?.length} allActiveAppIds`,
                }
              : state.assets,
            login: state.login
              ? {
                  ...state.login,
                  refMap: 'Referência do Mapa',
                  preferences: state.login.preferences
                    ? {
                        ...state.login.preferences,
                        fences: state.login.preferences.fences
                          ? `${Object.values(state.login.preferences.fences).length} items. `
                          : state.login.preferences.fences,
                        refs: state.login.preferences.refs
                          ? `${Object.values(state.login.preferences.refs).length} items. `
                          : state.login.preferences.refs,
                        routes: state.login.preferences.routes
                          ? `${Object.values(state.login.preferences.routes).length} items. `
                          : state.login.preferences.routes,
                        routesPanels: state.login.preferences.routesPanels
                          ? {
                              ...state.login.preferences.routesPanels,
                              byId: 'check data in routesPanels.current',
                              allIds: `${state.login.preferences.routesPanels.allIds.length} routesPanelsIds`,
                            }
                          : state.login.preferences.routes,
                      }
                    : state.login.preferences,
                }
              : state.login,
            modules: state.modules
              ? {
                  ...state.modules,
                  fences: state.modules.fences
                    ? {
                        ...state.modules.fences,
                        byId: 'check data in current field',
                        allIds: `${state.modules.fences.allIds.length} fencesIds`,
                      }
                    : state.modules.fences,
                  refs: state.modules.refs
                    ? {
                        ...state.modules.refs,
                        byId: 'check data in current field',
                        allIds: `${state.modules.refs.allIds.length} refsIds`,
                      }
                    : state.modules.refs,
                  routes: state.modules.routes
                    ? {
                        ...state.modules.routes,
                        byId: 'check data in current field',
                        allIds: `${state.modules.routes.allIds.length} routesIds`,
                      }
                    : state.modules.routes,
                  profiles: state.profiles
                    ? {
                        ...state.profiles,
                        byId: 'check data in current field',
                        allIds: `${state.profiles.allIds.length} profilesIds`,
                      }
                    : state.profiles,
                  units: state.modules.units
                    ? {
                        ...state.modules.units,
                        byId: 'check data in current field',
                        allIds: `${state.modules.units.allIds.length} unitsIds`,
                      }
                    : state.modules.units,
                }
              : state.modules,
            measures: state.measures
              ? {
                  ...state.measures,
                  byId: 'check data in measures.current',
                  allIds: `${state.measures.allIds.length} assetsIds`,
                }
              : state.measures,
            notification: state.notification
              ? {
                  ...state.notification,
                  byId: 'check data in notification.current',
                  allIds: `${state.notification.allIds.length} notificationsIds`,
                }
              : state.state.notification,
            devices: state.devices
              ? {
                  ...state.devices,
                  byId: 'check data in devices.current',
                  allIds: `${state.devices.allIds.length} devicesIds`,
                }
              : state.devices,
            tables: state.tables
              ? {
                  ...state.tables,
                  byId: 'check data in tables.current',
                  allIds: `${state.tables.allIds.length} tablesIds`,
                }
              : state.tables,
            profiles: state.profiles
              ? {
                  ...state.profiles,
                  byId: 'check data in current field',
                  allIds: `${state.profiles.allIds.length} profilesIds`,
                }
              : state.profiles,
            cards: state.cards ? `${state.cards?.allIds?.length} cards` : state.cards,
            dashboards: state.dashboards ? INFO_DEBUG : state.dashboards,
            filters: state.filters ? { ...state.filters, result: INFO_DEBUG } : state.filters,
            indoor: state.indoor ? INFO_DEBUG : state.indoor,
          }
        },
        //trace: true,
        //traceLimit: 30,
      })
    )
  const composedEnhancers = compose(...enhancers)
  // Valor que será carregado na INIT da Store
  //Carrega os dados iniciais da Local Storage
  //let localStore = loadState();
  //Se for mesmo usuário usa a local Storage;
  /*if(localStore!==undefined&&localStore.login.email===login) initialState = localStore;
else*/
  //Pega dados básicos do Login
  //Cria a Store
  const store = createStore(rootReducer, initialStore, composedEnhancers)
  return store
}

export const store = configureStore()
