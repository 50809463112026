import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setReportsProp } from 'Store/actions/reports-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { contextOptions } from 'Utils/ReportsFilters'
import { RootStore } from 'Store/initialStore'
import DropdownStoreField from '../generic/DropdownStoreField'

function ReportsStepOneForm() {
  const dispatch = useDispatch()

  const currentId = useSelector((state: RootStore) => state.reports.current?.id)

  const step = useObjSelector((state) => state.reports.form.steps?.items[0])

  const errors = useObjSelector((state) => state.reports.form.errors?.[0])

  return (
    <DropdownStoreField
      entity="reports"
      label="Contexto"
      name="scope"
      placeholder="Selecione o contexto do relatório"
      value={'scope'}
      options={contextOptions}
      onChange={(_, { value }) => {
        dispatch(setReportsProp('wip.scope', value))
        if (value === 'measures') {
          dispatch(setReportsProp('wip.filters', {}))
        }
      }}
      required={true}
      showFeedback={step.showFeedback}
      error={errors?.scope}
      disabled={!!currentId}
    />
  )
}

export default ReportsStepOneForm
