/*  Author: Felipe Pegado
    Type: Smart
	Description: 
	TODO: 
*/

import React from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import HeatMapItem from './HeatMapItem'
import { debounce } from 'throttle-debounce'
import { notificationState, NotificationStatus } from 'Utils/notifications'
import { useTranslation } from 'react-i18next'
import { saveHeatmapFilter } from 'Store/actions/heatmap-action'
import { useDispatch, useSelector } from 'react-redux'
import { setFalse } from 'Store/actions/toggle-action'

const HeatMapButtons = (props) => {
  //const [currentFilter, setCurrentFilter] = useState('General');
  const { t } = useTranslation()
  const option = useSelector((state) => state.toggle)
  const dispatch = useDispatch()
  const showNotification = () => {
    notificationState.addNotification({
      title: t('HeatMapZoomLocked'),
      message: t('HeatMapWarning'),
      status: NotificationStatus.WARNING,
      sticky: false,
      actions: {
        primary: [
          {
            run: () => {
              dispatch(setFalse('showHeatMap'))
            },
            label: t('TurnOff'),
          },
        ],
      },
    })
  }
  const showNotificationOnce = debounce(13000, true, showNotification)
  const showHeatMap = (id) => {
    if (!props[id]) {
      switch (id) {
        case 'HeatMapInert':
          dispatch(saveHeatmapFilter([0]))
          break
        case 'HeatMapWalking':
          dispatch(saveHeatmapFilter([1]))
          break
        case 'HeatMapUnsafe':
          dispatch(saveHeatmapFilter([3, 4, 5, 6]))
          break
        default:
          dispatch(saveHeatmapFilter([]))
      }
      //setCurrentFilter(id)
      showNotificationOnce()
    }
  }
  const gridIcons = [
    { id: 'General', title: t('General'), icon: 'fire', color: '#4b72b2', size: option.heatMap ? null : 'small' },
    {
      id: 'HeatMapInert',
      title: t('HeatMapInert'),
      icon: 'fire',
      color: option.heatMap && option.HeatMapInert ? 'green' : '#4b72b2',
      size: option.heatMap && option.HeatMapInert ? null : 'small',
    },
    {
      id: 'HeatMapWalking',
      title: t('HeatMapWalking'),
      icon: 'fire',
      color: option.heatMap && option.HeatMapWalking ? 'orange' : '#4b72b2',
      size: option.heatMap && option.HeatMapWalking ? null : 'small',
    },
    {
      id: 'HeatMapUnsafe',
      title: t('HeatMapUnsafe'),
      icon: 'fire',
      color: option.heatMap && option.HeatMapUnsafe ? 'red' : '#4b72b2',
      size: option.heatMap && option.HeatMapUnsafe ? null : 'small',
    },
    //,{ id: 'HeatMapHeights', title: t('HeatMapHeights'), icon: 'fire', color: (heatMap && HeatMapHeights) ? 'purple' : '#4b72b2', size: (heatMap && HeatMapHeights) ? null : 'small' }
  ]
  const heatMapIcon = [{ title: 'HeatMap', icon: 'fire', color: 'red', size: option.heatMap ? null : 'small' }]

  return (
    <Dropdown
      trigger={heatMapIcon.map((d, i) => (
        <Icon
          key={d.title}
          title={t(d.title)}
          size={d.size}
          link
          name={d.icon ? d.icon : 'info'}
          style={{ color: d.color }}
        />
      ))}
      pointing="top right"
      icon={null}
    >
      <Dropdown.Menu>
        {gridIcons.map((e) => (
          <HeatMapItem
            id={e.id}
            size={e.size}
            color={e.color}
            icon={e.icon ? e.icon : e}
            text={e.title}
            key={e.title}
            onClick={showHeatMap}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default HeatMapButtons
