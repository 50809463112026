import React, { useMemo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Menu, Form, Input, Radio, TextArea, Checkbox, Select } from 'semantic-ui-react'
import styled from 'styled-components'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
// import Beta from 'Utils/beta'
import MeetingPointDropdown from 'Components/dropdowns/MeetingPointDropdown'

const FormNow = styled(Form)`
  --border: 1px red solid !important;
  height: 330px;
  width: 93%;
  box-shadow: 0px 0px !important;
  --background-color: #f5f5f5 !important;
  margin: 0 !important;
  padding: 8px !important;
`

const StyledMenu = styled(Menu)`
  display: flex !important;
  flex-direction: column !important;
  min-height: 100% !important; /* Ou qualquer requisito de altura para seu caso de uso */
`
const StyledMenuItem = styled(Menu.Item)`
  text-align: left;
  padding-right: 25% !important;
`

const RightGridColumn = styled(Grid.Column)`
  padding-left: 5% !important;
`

const StyledGrid = styled(Grid)`
  height: 100%;
`

const FormField = styled(Form.Field)`
  margin-bottom: 2.5vh !important;
`

const FormRadioGroup = styled(Form.Group)`
  margin-bottom: 3.5vh !important;
`

const DashboardPanelConfig = ({ changes, setChanges, setStrChanges }) => {
  const { t } = useTranslation()
  const [activeItem, setActiveItem] = useState('main')
  const [name, setName] = useState(changes.label)
  const [source, setSource] = useState(changes.resource)
  const [description, setDescription] = useState(changes.description)
  const [graphType, setGraphType] = useState(changes.type)
  const [time, setTime] = useState(changes.time || 'rtm')
  const [timeRelative, setTimeRelative] = useState(changes.timeRelative || '1d')
  const [startTime, setStartTime] = useState(changes.startTime)
  const [endTime, setEndTime] = useState(changes.endTime)
  const [endTimeNow, setEndTimeNow] = useState(changes.endTimeNow)
  const [panelType, setPanelType] = useState(changes.panelType)
  const [nCards, setNCards] = useState(changes.nCards || 1)
  const [colCards, setColCards] = useState(changes.colCards || 1)
  //const [cards, setCards] = useState(changes.cards || [])
  const [kpiFormat, setKpiFormat] = useState(changes.kpiFormat || 'number')
  const [meta, setMeta] = useState(changes.meta)
  const [metaComp, setMetaComp] = useState(changes.metaComp)
  const [aggregation, setAggregation] = useState(changes.custom?.aggregation || 'max')
  const [grouping, setGrouping] = useState(changes.custom?.grouping || 'auto')
  const [perimeters, setPerimeters] = useState(changes.perimeters || [])

  const reportAllIds = useSelector((state) => state.modules.reports.allIds)
  const reportById = useSelector((state) => state.modules.reports.byId)

  let reportOptions = reportAllIds
    .filter((f) => reportById[f].info.scope)
    .map((rId) => ({ key: rId, text: reportById[rId]?.info?.text?.trim(), value: rId }))
    .sort((a, b) => (a.text && b.text ? a.text.toLowerCase().localeCompare(b.text.toLowerCase()) : 1))

  //reportOptions.unshift({ keys: 'kpis', text: "KPI's", value: 'kpis' })
  const debouncedChangeHandler = useMemo(
    () =>
      debounce((changes, name, value) => {
        setChanges({ ...changes, [name[0]]: value[0], [name[1]]: value[1] })
      }, 300),
    [setChanges]
  )

  const handleChange = (e, { name, value, checked }) => {
    let defaultName
    switch (name) {
      case 'label':
        setName(value)
        break
      case 'resource':
        defaultName = value === 'kpis' ? "[Beta] KPI's" : reportOptions.filter((op) => op.key === value)[0].text
        setSource(value)
        setName(defaultName)
        break
      case 'description':
        setDescription(value)
        break
      case 'type':
        setGraphType(value)
        break
      case 'time':
        setTime(value)
        break
      case 'timeRelative':
        setTimeRelative(value)
        break
      case 'startTime':
        setStartTime(value)
        break
      case 'endTime':
        setEndTime(value)
        break
      case 'endTimeNow':
        setEndTimeNow(checked)
        break
      case 'panelType':
        setPanelType(value)
        break
      case 'nCards':
        setNCards(value < 0 ? 1 : value)
        break
      case 'colCards':
        setColCards(value < 0 ? 1 : value > 6 ? 6 : value)
        break
      case 'kpiFormat':
        setKpiFormat(value)
        break
      case 'meta':
        setMeta(value)
        break
      case 'metaComp':
        setMetaComp(value)
        break
      case 'aggregation':
        setAggregation(value)
        break
      case 'grouping':
        setGrouping(value)
        break
      default:
        if (name.startsWith('card')) console.log(name, value)
        console.error('Missing treatment to ' + name)
    }
    if (name === 'resource') debouncedChangeHandler(changes, [name, 'label'], [value, defaultName])
    else if (name === 'endTimeNow') debouncedChangeHandler(changes, [name], [checked])
    else if (name === 'aggregation' || name === 'grouping') {
      debouncedChangeHandler(changes, ['custom'], [{ ...changes.custom, [name]: value }])
    } else {
      debouncedChangeHandler(changes, [name], [value])
    }
  }
  const handleItemClick = (e, { value }) => {
    setActiveItem(value)
  }

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  useEffect(() => {
    if (source === '30261' && !['line'].includes(graphType)) {
      setGraphType('line')
      setChanges({ ...changes, type: 'line' })
    }
  }, [source, setChanges, changes, graphType])

  const graphOptions = useMemo(
    () => [
      { label: 'Área', value: 'area', condition: changes.resource !== '30261' },
      { label: 'Barra', value: 'bars', condition: changes.resource !== '30261' },
      { label: 'Linha', value: 'line' },
      { label: 'Pizza', value: 'pie', condition: changes.resource !== '30261' },
      { label: 'Radar', value: 'radar', condition: changes.resource !== '30261' },
      { label: 'Rosca', value: 'donut', condition: changes.resource !== '30261' },
    ],
    [changes.resource]
  )

  const mainForm = () => {
    return (
      <FormNow>
        <FormRadioGroup inline>
          <label>Tipo de Painel</label>
          <Form.Field
            control={Radio}
            name={'panelType'}
            label="Gráfico"
            value="graph"
            checked={!panelType?.startsWith('kpi')}
            onChange={handleChange}
          />
          <Form.Field
            control={Radio}
            name={'panelType'}
            label="Indicador"
            value="kpi"
            checked={panelType === 'kpi'}
            onChange={handleChange}
          />
        </FormRadioGroup>
        <FormField
          control={Select}
          label="Relatório Fonte"
          placeholder="Relatório Fonte"
          value={source}
          search
          onChange={handleChange}
          name={'resource'}
          options={reportOptions}
        />
        <FormField
          control={Input}
          name={'label'}
          label="Título do Painel"
          placeholder={'Título do Painel'}
          value={name}
          onChange={handleChange}
        />

        {!panelType?.startsWith('kpi') && (
          <FormRadioGroup inline>
            <label>Tipo de Gráfico</label>
            {graphOptions.map(
              ({ label, value, condition }) =>
                condition !== false && (
                  <Form.Field
                    key={value}
                    control={Radio}
                    name="type"
                    label={label}
                    value={value}
                    checked={graphType === value}
                    onChange={handleChange}
                  />
                )
            )}
          </FormRadioGroup>
        )}
        <FormField
          control={TextArea}
          name={'description'}
          value={description}
          onChange={handleChange}
          label="Descrição do Painel"
          placeholder="Informativo para o usuário do dashboard..."
        />
        {panelType === 'kpi' && (
          <Form.Group widths="16">
            <FormField
              control={Select}
              label="Formato"
              placeholder="Relatório Fonte"
              value={kpiFormat}
              search
              onChange={handleChange}
              name={'kpiFormat'}
              options={[
                { text: t('FormatCoin'), key: 'Format1', value: 'coin' },
                { text: t('FormatNumber'), key: 'Format2', value: 'number' },
                { text: t('FormatPercent'), key: 'Format3', value: 'percent' },
                { text: 'PPM: parte por milhão', key: 'Formatppm', value: 'ppm' },
                { text: 'Temp: Celsius', key: 'Format7', value: 'tCelsius' },
                { text: 'Temp: Fahrenheit', key: 'Format8', value: 'tFahr' },
                { text: 'Temp: Kelvin', key: 'Format9', value: 'tKelvin' },
                { text: t('FormatDays'), key: 'Format3.5', value: 'tdays' },
                { text: t('FormatHours'), key: 'Format4', value: 'thours' },
                { text: t('FormatMinutes'), key: 'Format5', value: 'tminutes' },
                { text: t('FormatSeconds'), key: 'Format6', value: 'tseconds' },
                { text: t('FormatTime'), key: 'Format7.1', value: 'ttime' },
                { text: 'Tensão (V)', key: 'FormatV', value: 'tension' },
                { text: 'Corrente Elétrica (A)', key: 'FormatA', value: 'current' },
              ]}
              width={6}
            />
            <FormField
              control={Input}
              name={'meta'}
              label="Meta"
              type="number"
              value={meta}
              onChange={handleChange}
              width={4}
            />
            <FormField
              control={Select}
              label="Critério"
              placeholder="Critério de Comparação"
              value={metaComp}
              search
              onChange={handleChange}
              name={'metaComp'}
              options={[
                { text: t('Different'), key: 'Format1', value: '<>' },
                { text: t('Equal'), key: 'Format2', value: '=' },
                { text: t('Greater'), key: 'Format3', value: '>' },
                { text: t('GreaterOrEqual'), key: 'Format4', value: '>=' },
                { text: t('Lesser'), key: 'Format5', value: '<' },
                { text: t('LesserOrEqual'), key: 'Format6', value: '<=' },
              ]}
              width={6}
            />
          </Form.Group>
        )}
      </FormNow>
    )
  }
  const kpisForm = () => {
    return (
      <FormNow>
        <Form.Group inline>
          <label>Tipo de Painel</label>
          {changes.resource !== '30261' && (
            <Form.Field
              control={Radio}
              name={'panelType'}
              label="Gráfico"
              value="graph"
              checked={panelType !== 'kpis'}
              onChange={handleChange}
            />
          )}
          <Form.Field
            control={Radio}
            name={'panelType'}
            label="KPI's"
            value="kpis"
            checked={panelType === 'kpis'}
            onChange={handleChange}
          />
        </Form.Group>
        <br />
        <Form.Group widths="16">
          <FormField
            control={Input}
            name={'label'}
            label="Título do Painel"
            placeholder={'Título do Painel'}
            value={name}
            onChange={handleChange}
            width="8"
          />
          <FormField
            control={Input}
            name={'nCards'}
            label="Número de Cards"
            type="number"
            min={0}
            value={nCards}
            onChange={handleChange}
            width="4"
          />
          <FormField
            control={Input}
            name={'colCards'}
            value={colCards}
            type="number"
            min={1}
            onChange={handleChange}
            label="Colunas"
            width="4"
          />
        </Form.Group>
        <br />
        <Form.Group widths="equal">
          <FormField
            fluid
            control={TextArea}
            name={'description'}
            label="Descrição"
            placeholder={'Descrição do Card'}
            value={description}
            onChange={handleChange}
          />
        </Form.Group>
        {/*<div style={{ overflow: 'auto', height: '185px' }}>
          {Array.from({ length: nCards }).map((n, i) => (
            <Segment>
              <span>Detalhes do card {i + 1}</span>
              <Icon name="trash" />
            </Segment>
          ))}
          </div>*/}
      </FormNow>
    )
  }

  const customForm = () => {
    return (
      <>
        {/* <Beta type="beta">Beta</Beta> */}

        <FormNow>
          <Form.Group widths="equal" inline>
            <label>Agregação</label>
            <Form.Field
              control={Radio}
              name="aggregation"
              label="Mínimo"
              value="min"
              checked={aggregation === 'min'}
              onChange={handleChange}
            />
            <Form.Field
              control={Radio}
              name="aggregation"
              label="Média"
              value="avg"
              checked={aggregation === 'avg'}
              onChange={handleChange}
            />
            <Form.Field
              control={Radio}
              name="aggregation"
              label="Máximo"
              value="max"
              checked={aggregation === 'max'}
              onChange={handleChange}
            />
            {changes.resource !== '30261' && (
              <>
                <Form.Field
                  control={Radio}
                  name="aggregation"
                  label="Contagem"
                  value="count"
                  checked={aggregation === 'count'}
                  onChange={handleChange}
                />
                <Form.Field
                  control={Radio}
                  name="aggregation"
                  label="Soma"
                  value="sum"
                  checked={aggregation === 'sum'}
                  onChange={handleChange}
                />
                <Form.Field
                  control={Radio}
                  name="aggregation"
                  label="Nulos"
                  value="null"
                  checked={aggregation === 'null'}
                  onChange={handleChange}
                />
              </>
            )}
          </Form.Group>
          {!panelType?.startsWith('kpi') && ['line', 'bars'].includes(graphType) && (
            <Form.Group inline>
              <label>Escala de tempo</label>
              <Form.Field
                control={Radio}
                name="grouping"
                label="Auto"
                value="auto"
                checked={grouping === 'auto'}
                onChange={handleChange}
              />
              <Form.Field
                control={Radio}
                name="grouping"
                label="Hora"
                value="hour"
                checked={grouping === 'hour'}
                onChange={handleChange}
              />
              {changes.resource !== '30261' && (
                <>
                  <Form.Field
                    control={Radio}
                    name="grouping"
                    label="Dia"
                    value="day"
                    checked={grouping === 'day'}
                    onChange={handleChange}
                  />
                  <Form.Field
                    control={Radio}
                    name="grouping"
                    label="Mês"
                    value="month"
                    checked={grouping === 'month'}
                    onChange={handleChange}
                  />
                  <Form.Field
                    control={Radio}
                    name="grouping"
                    label="Ano"
                    value="year"
                    checked={grouping === 'year'}
                    onChange={handleChange}
                  />
                </>
              )}
            </Form.Group>
          )}
        </FormNow>
      </>
    )
  }

  const filtersForm = () => {
    return (
      <FormNow>
        {
          <Form.Group inline>
            <label>Período</label>
            {changes.resource !== '30261' && (
              <Form.Field
                control={Radio}
                name={'time'}
                label="Tempo Real"
                value="rtm"
                checked={time === 'rtm'}
                onChange={handleChange}
              />
            )}
            <Form.Field
              control={Radio}
              name={'time'}
              label="Tempo Relativo"
              value="rel"
              checked={time === 'rel'}
              onChange={handleChange}
            />
            <Form.Field
              control={Radio}
              name={'time'}
              label="Período Definido"
              value="abs"
              checked={time === 'abs'}
              onChange={handleChange}
            />
          </Form.Group>
        }
        {time === 'rel' && (
          <FormRadioGroup inline>
            <label>Filtro de Tempo</label>
            <Form.Field
              control={Radio}
              name={'timeRelative'}
              label="Última hora"
              value="1h"
              checked={timeRelative === '1h'}
              onChange={handleChange}
            />
            <Form.Field
              control={Radio}
              name={'timeRelative'}
              label="Últimas 24h"
              value="1d"
              checked={timeRelative === '1d'}
              onChange={handleChange}
            />
            <Form.Field
              control={Radio}
              name={'timeRelative'}
              label="Últimos 7 dias"
              value="7d"
              checked={timeRelative === '7d'}
              onChange={handleChange}
            />
            <Form.Field
              control={Radio}
              name={'timeRelative'}
              label="Últimos 30 dias"
              value="30d"
              checked={timeRelative === '30d'}
              onChange={handleChange}
            />
          </FormRadioGroup>
        )}
        {time === 'abs' && (
          <FormRadioGroup inline>
            <label>Filtro de Tempo</label>
            <Form.Field
              control={Input}
              type={'datetime-local'}
              name={'startTime'}
              label="Início"
              value={startTime}
              onChange={handleChange}
            />
            <Form.Field
              control={Input}
              name={'endTime'}
              type={'datetime-local'}
              label={endTimeNow ? 'Fim (usando até agora)' : 'Fim'}
              value={endTime}
              onChange={handleChange}
              disabled={endTimeNow}
            />
            <Form.Field
              name={'endTimeNow'}
              control={Checkbox}
              label="Agora"
              checked={endTimeNow}
              onChange={handleChange}
            />
          </FormRadioGroup>
        )}
        {changes.resource === '30261' && (
          <FormField>
            <label>{t('tabs.fences')}</label>
            <MeetingPointDropdown
              onChange={(value) => {
                setPerimeters(value)
                setChanges({ ...changes, perimeters: value })
              }}
              placeholder="Selecione o(s) Perímetro(s)"
              value={perimeters}
            />
          </FormField>
        )}
      </FormNow>
    )
  }

  const activeItemContent = (activeItem) => {
    if (activeItem === 'main') return panelType === 'kpis' ? kpisForm() : mainForm()
    if (activeItem === 'filters') return filtersForm()
    if (activeItem.startsWith('card')) return cardsForm()
    if (activeItem === 'custom') return customForm()
  }
  const cardsForm = () => {
    return (
      <FormNow>
        <Form.Group widths="equal">
          <FormField
            control={Select}
            label="Relatório Fonte"
            placeholder="Relatório Fonte"
            value={source}
            search
            onChange={handleChange}
            name={'resource'}
            options={reportOptions}
          />
          <FormField
            control={Input}
            name={'label'}
            label="Título do Card"
            placeholder={'Título do Card'}
            value={name}
            onChange={handleChange}
          />
        </Form.Group>
        <br />
        <FormField
          control={TextArea}
          name={'description'}
          value={description}
          onChange={handleChange}
          label="Descrição do Card"
          placeholder="Informativo para o usuário do dashboard..."
        />
        <br />
        <Form.Group widths="16">
          <FormField
            width="6"
            control={Select}
            label="Tipo de Dado"
            placeholder="Tipo de Dado"
            //value={source}
            search
            //onChange={handleChange}
            name={'resource'}
            options={[
              { key: 'date', value: 'date', text: 'Data' },
              { key: 'datetime-local', value: 'datetime-local', text: 'Data/Hora' },
              { key: 'time', value: 'time', text: 'Hora' },
              { key: 'number', value: 'number', text: 'Número' },
              { key: 'text', value: 'text', text: 'Texto' },
            ]}
          />
          <FormField
            control={Select}
            width="6"
            name={'label'}
            label="Comparador"
            placeholder={'Comparador'}
            options={[
              { key: 'greaterorequal', value: 'greaterorequal', text: 'Maior ou Igual' },
              { key: 'greater', value: 'greater', text: 'Maior' },
              { key: 'equal', value: 'equal', text: 'Igual' },
              { key: 'lesser', value: 'lesser', text: 'Menor' },
              { key: 'lesserorequal', value: 'lesserorequal', text: 'Menor ou Igual' },
              { key: 'diff', value: 'diff', text: 'Diferente' },
            ]}
            //value={name}
            //onChange={handleChange}
          />
          <FormField
            control={Input}
            width="4"
            name={'label'}
            label="Meta"
            placeholder={'Meta'}
            type={'number'}
            //value={name}
            //onChange={handleChange}
          />
        </Form.Group>
        {/*}
        <FormField
          control={Checkbox}
          onChange={handleChange}
          label="Posição fixa"
          name={'staticMode'}
          value={staticMode}
        />*/}
      </FormNow>
    )
  }
  return (
    <StyledGrid>
      <Grid.Column width={2}>
        <StyledMenu fluid vertical pointing secondary>
          <StyledMenuItem name="Principal" value="main" active={activeItem === 'main'} onClick={handleItemClick} />
          <StyledMenuItem name="Filtros" value="filters" active={activeItem === 'filters'} onClick={handleItemClick} />
          {panelType === 'kpis' &&
            Array.from({ length: nCards }).map((n, i) => {
              const cardName = 'card' + (i + 1)
              return (
                <StyledMenuItem
                  key={cardName}
                  name={cardName}
                  value={cardName}
                  active={activeItem === cardName}
                  onClick={handleItemClick}
                />
              )
            })}
          <StyledMenuItem
            fitted={'vertically'}
            name="Avançado"
            value="custom"
            active={activeItem === 'custom'}
            onClick={handleItemClick}
          />
        </StyledMenu>
      </Grid.Column>
      <RightGridColumn stretched width={14}>
        {activeItemContent(activeItem)}
      </RightGridColumn>
    </StyledGrid>
  )
}

export default DashboardPanelConfig
