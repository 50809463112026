import React, { useMemo /*, useEffect*/ } from 'react'
import { debounce } from 'lodash'
import { Segment, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
//import { IMEInput } from 'Utils/imeInput'

export const GlobalFilter = ({
  setGlobalFilter,
  value,
  setValue,
}: {
  setGlobalFilter: (filterValue: string) => void
  value: string
  setValue: (value: string) => void
}) => {
  const debouncedChangeHandler = useMemo(
    () => debounce((value: any) => setGlobalFilter(value || undefined), 500),
    [setGlobalFilter]
  )
  const { t } = useTranslation()
  return (
    <Segment style={{ minWidth: '175px', border: 'none' }}>
      {/*<IMEInput
        externalVal={value}
        externalOnChange={(e: string) => {
          setValue(e)
          debouncedChangeHandler(e)
        }}
        externalIcon="search"
      />*/}
      <Input
        fluid={false}
        name={'search'}
        autoFocus={false}
        value={value}
        icon={'search'}
        iconPosition={'left'}
        size="large"
        onChange={(e: React.ChangeEvent<HTMLInputElement>, { value }) => {
          setValue(value)
          debouncedChangeHandler(value)
        }}
        placeholder={t('Search')}
        style={{ width: '17vw', minWidth: '130px' }}
        disabled={false}
      />
    </Segment>
  )
}
export default GlobalFilter
