import { urlBase, URL_BASE } from 'Apis/rest'
import { API } from 'aws-amplify'
import axios from 'axios'
import { createDataLabeling, deleteDataLabeling, updateDataLabeling } from 'graphql/mutations'
import { listDataLabeling } from 'graphql/queries'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'
import { Storage } from 'aws-amplify'
import { omit } from 'lodash'

export const setDataLabelingsProp = (prop, value) => ({
  type: 'SET_DATA_LABELINGS_PROP',
  prop,
  value,
})

export const setDataLabelingsProps = (payload, source) => {
  return {
    type: 'SET_DATA_LABELINGS_PROPS',
    payload,
    source,
  }
}

export const setDataLabelingsWIPAssets = (payload) => ({
  type: 'SET_DATA_LABELINGS_WIP_ASSETS',
  payload,
})

export const saveDataLabelings =
  ({ feedback = true }) =>
  async (dispatch, getState) => {
    const { empresa, email } = getState().login
    const { activity, ...fields } = getState().dataLabelings.wip
    const mutation = fields.id ? 'update' : 'create'
    const { createdAt, updatedAt, startTime, endTime, ...rest } = fields
    let input = {
      ...rest,
      tenant: empresa,
      [mutation === 'create' ? 'createdBy' : 'updatedBy']: email,
      ...(startTime && { startTime: new Date(startTime).toISOString() }),
      ...(endTime && { endTime: new Date(endTime).toISOString() }),
    }

    input = omit(input, ['__typename'])

    input.phases = input.phases.map((e, i) => {
      return omit(e, ['__typename'])
    })

    if (input.labelings) {
      input.labelings = input.labelings.map((e, i) => {
        return omit(e, ['__typename'])
      })
    }

    input.phases.forEach((e, i) => {
      if (input.phases[i].vehicle) input.phases[i].vehicle = input.phases[i].vehicle.map((f) => omit(f, ['__typename']))
    })

    dispatch({ type: 'SAVE_DATA_LABELINGS_START', input, mutation })

    try {
      const query = mutation === 'update' ? updateDataLabeling : createDataLabeling
      const { data } = await API.graphql({ query, variables: { input } })
      dispatch({
        type: 'SAVE_DATA_LABELINGS_SUCCESS',
        payload: data?.[mutation === 'update' ? 'updateDataLabeling' : 'createDataLabeling'],
      })
      feedback && notifySuccess()
    } catch (error) {
      console.error('error:', error)
      dispatch({ type: 'SAVE_DATA_LABELING_FAILURE', input, mutation })
      feedback && notifyError()
    }
  }

export const fetchDataLabelings = (token) => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  try {
    if (!token) dispatch({ type: 'FETCH_DATA_LABELINGS_START', tenant })
    const response = await API.graphql({
      query: listDataLabeling,
      variables: { tenant, nextToken: token },
    })
    const { items, nextToken } = response.data.listDataLabeling
    dispatch({
      type: 'FETCH_DATA_LABELINGS_SUCCESS',
      payload: items,
    })
    if (nextToken) await dispatch(fetchDataLabelings(nextToken))
  } catch (err) {
    console.error('error fetching dataLabelings', err)
  }
}

export const fetchMotionData = () => async (dispatch, getState) => {
  const jwt = localStorage.getItem('id_token')
  const wip = getState().dataLabelings.wip

  const params = {
    type: 'historyByTime',
    startTime: wip?.startTime ?? '2023-05-16T20:00:00.000Z',
    endTime: wip?.endTime ?? '2023-05-16T20:30:00.000Z',
    assetId: wip.assets?.join(),
    category: 'motion',
  }

  const config = {
    headers: { Authorization: `Bearer ${jwt}` },
    params,
  }

  const url = `${urlBase}/assets/measures`

  dispatch({
    type: 'FETCH_MOTION_DATA_START',
    payload: params,
  })

  dispatch({ type: 'SET_TRUE', payload: 'loadingScreen' })

  try {
    const { data } = await axios.get(url, config)

    if (data?.errorType?.includes('ResponseSizeTooLarge')) {
      throw new Error('Response size too large')
    }

    dispatch({
      type: 'FETCH_MOTION_DATA_SUCCESS',
      payload: data?.result,
    })

    dispatch({ type: 'SET_FALSE', payload: 'loadingScreen' })
  } catch (err) {
    console.error(err)

    dispatch({
      type: 'FETCH_MOTION_DATA_FAILURE',
      payload: err,
    })
  }
}

/**
  @deprecated Use setStartPhaseLabel or setEndPhaseLabel instead
*/
export const setNewPhaseLabel = (payload) => ({
  type: 'SET_NEW_PHASE_LABEL',
  payload,
})

export const setStartPhaseLabel = (payload) => ({
  type: 'SET_START_PHASE_LABEL',
  payload,
})

export const setEndPhaseLabel = (payload) => ({
  type: 'SET_END_PHASE_LABEL',
  payload,
})

export const mutateDataLabelingPhase = (payload) => ({
  type: 'MUTATE_DATA_LABELING_PHASE',
  payload,
})

export const removeDataLabelingPhase = (payload) => ({
  type: 'REMOVE_DATA_LABELING_PHASE',
  payload,
})

export const removeDataLabeling = (payload) => ({
  type: 'REMOVE_DATA_LABELING',
  payload,
})

export const setDataLabelingWIP = (id) => {
  return { type: 'SET_DATA_LABELING_WIP', id }
}

export const setDataLabelingIDs = (ids) => ({ type: 'SET_IDS', payload: ids })

export const deleteDataLabelings = (ids) => async (dispatch, getState) => {
  for (const id of ids) {
    try {
      dispatch({ type: 'DELETE_DATA_LABELINGS_START', id })
      await API.graphql({
        query: deleteDataLabeling,
        variables: {
          input: {
            id,
          },
        },
      })
      try {
        const { videoUrlKey } = getState().dataLabelings.wip
        await Storage.remove(videoUrlKey)
      } catch (err) {
        console.error('error removing video from S3', err)
      }
      dispatch({ type: 'DELETE_DATA_LABELINGS_SUCCESS', id })
      notifySuccess()
    } catch (err) {
      dispatch({ type: 'DELETE_DATA_LABELINGS_FAILURE', id })
      console.error('error:', err)
      notifyError()
    }
  }
}

export const clearDataLabelingForm = () => {
  return { type: 'CLEAR_DATA_LABELING_FORM' }
}

export const calcDataLabelingsMetrics = () => {
  return { type: 'CALC_DATA_LABELINGS_METRICS' }
}

export const setSelectedActivity = (payload) => {
  return { type: 'SET_SELECTED_ACTIVITY', payload }
}

export const calcDataLabelingsCards = () => {
  return { type: 'CALC_DATA_LABELINGS_CARDS' }
}

export const setSelectedVehicle = (payload) => {
  return { type: 'SET_SELECTED_VEHICLE', payload }
}

export const calcPredictLabelingsByDay = () => {
  return { type: 'CALC_PREDICT_LABELINGS_BY_DAY' }
}

export const calcWipCycles = () => {
  return { type: 'CALC_WIP_CYCLES' }
}

export const initLabelsTableSetSortBy = (payload) => {
  return { type: 'INIT_LABELS_TABLE_SET_SORT_BY', payload }
}

export const fetchSteps = (id) => async (dispatch, getState) => {
  const labeling = getState().dataLabelings.byId[id]

  const { assets, startTime, endTime, labelings: labels } = labeling

  const jwt = localStorage.getItem('id_token')

  const params = {
    type: 'historyByTime',
    startTime: startTime,
    endTime: endTime,
    assetId: assets?.join(),
    category: 'motion',
    measure_name: 'steps',
  }

  const config = {
    headers: { Authorization: `Bearer ${jwt}` },
    params,
  }

  const url = `${urlBase}/assets/measures`

  dispatch({
    type: 'FETCH_STEPS_START',
    payload: params,
  })

  dispatch({ type: 'SET_TRUE', payload: 'loadingScreen' })

  try {
    const { data } = await axios.get(url, config)

    if (data?.errorType?.includes('ResponseSizeTooLarge')) {
      throw new Error('Response size too large')
    }

    dispatch({
      type: 'FETCH_STEPS_SUCCESS',
      payload: { result: data?.result, labels },
    })

    dispatch({ type: 'SET_FALSE', payload: 'loadingScreen' })
  } catch (err) {
    console.error(err)
    dispatch({
      type: 'FETCH_STEPS_FAILURE',
      payload: err,
    })
  }
}

export const fetchCronologyData =
  (selectedDataLabelingsIds, currentCar, currentShift, t) => async (dispatch, getState) => {
    const dataLabelingsById = getState().dataLabelings.byId

    let activities = [...new Set(selectedDataLabelingsIds.map((id) => dataLabelingsById[id].activity?.name))]
    let userConfirmed
    if (activities.length > 1) {
      userConfirmed = window.confirm(t('SelectMoreThan1Activity') + activities)
    }

    if (activities.length <= 1 || userConfirmed) {
      const jwt = localStorage.getItem('id_token')
      dispatch({ type: 'FETCH_CRONOLOGY_DATA_START' })
      dispatch({ type: 'SET_TRUE', payload: 'loadingScreen' })
      try {
        const res = await axios.get(
          `${URL_BASE}/labelings?ids=${selectedDataLabelingsIds}&vehicle=${currentCar}&shift=${currentShift}`,
          {
            headers: {
              Authorization: 'Bearer ' + jwt,
            },
          }
        )
        dispatch({ type: 'FETCH_CRONOLOGY_DATA_SUCCESS', payload: res.data })
        dispatch({ type: 'SET_FALSE', payload: 'loadingScreen' })
      } catch (err) {
        console.error(err)
        dispatch({ type: 'SET_FALSE', payload: 'loadingScreen' })
      }
    }
  }
