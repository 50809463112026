import React, { useState, useMemo, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { InputTemplate } from 'Utils/components/InputTemplate.js'
import { updateDynamicData } from 'Store/actions/modules-action'
import { updateAsset } from 'Store/actions/assets-action'
import { fetchAssets, deleteAssets, insertAssets, saveGroup, deleteGroup } from 'Store/actions/assets-action'
import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { saveColumnsPreference } from 'Store/actions/login-action'
import { SvgIcon } from 'Utils/components/SvgIcon.jsx'
import useFetchData from 'Utils/hooks/useFetchData'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { lastSeenColumn } from 'Utils/lastSeenColumn'

const AssetsContent = () => {
  const [loading, setLoading] = useState(true)
  useFetchData({
    independentFetches: [{ fetchAction: 'profiles' }],
    dependentFetches: [{ fetchAction: 'assets' }, { fetchAction: 'measures' }],
    finishAction: () => setLoading(false),
  })

  const table = 'assets'
  const { t } = useTranslation()

  const assetsAllIds = useObjSelector((state) => state.assets.allIds)
  const assetsById = useObjSelector((state) => state.assets.byId)
  const cudLoading = useSelector((state) => state.assets.cudLoading)
  const cudSuccess = useSelector((state) => state.assets.cudSuccess)

  const assetTypes = useObjSelector((state) => state.profiles)
  const measuresById = useObjSelector((state) => state.measures.byId)

  const types = assetTypes.allIds
    .filter((tp) => assetTypes.byId[tp].type === 'assets')
    .map((t) => ({
      key: t,
      value: t,
      text: assetTypes.byId[t].name,
      color: assetTypes.byId[t].color,
      iconsvg: assetTypes.byId[t].icon,
    }))

  const dispatch = useDispatch()
  const fetchAction = async () => {
    setLoading(true)
    await dispatch(fetchAssets())
    setLoading(false)
  }
  const insertAction = (body) => {
    dispatch(insertAssets(body))
  }
  const deleteAction = (ids) => {
    dispatch(deleteAssets(ids.ids))
  }

  const updateAction = (id, body) => {
    dispatch(updateAsset(id, body))
  }

  const groupAction = (g, s, a) => dispatch(saveGroup(g, s, a))
  const deleteGroupAction = (ids) => deleteGroup(ids)
  const hiddenColumns = useObjSelector((state) => state.login.preferences.routesPanels.byId[table].hiddenColumns)

  const groupedValuesColumns = useObjSelector(
    (state) => state.login.preferences.routesPanels.byId[table].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(table, false, currentGroup))
  }, [currentGroup, dispatch])

  const noType = '<<' + t('noAssignment') + '>>'

  const columns = React.useMemo(() => {
    const typeColor = (id) => types.filter((f) => f.value === id)[0]?.color
    const typeIcon = (id) => types.filter((f) => f.value === id)[0]?.iconsvg
    const allColumnsAvailable = [
      {
        Header: t('uniqueID'),
        accessor: 'id',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} id`,
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return value
        },
      },
      {
        Header: t('code'),
        accessor: 'info.code',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} code`,
        Cell: (props) => (
          <InputTemplate
            value={props.value}
            id={props?.cell?.row?.original?.id}
            action={(id, field, value) => {
              dispatch(updateDynamicData({ id: id, field: field, value: value }, 'assets'))
            }}
            field="info.code"
            table={table}
          />
        ),
      },
      {
        Header: t('Name'),
        accessor: 'name',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} nomes`,
        Cell: (props) => (
          <InputTemplate
            value={props.value}
            id={props?.cell?.row?.original?.id}
            action={(id, field, value) => {
              dispatch(updateAsset(id, { [field]: value }))
            }}
            field="name"
            table={table}
          />
        ),
      },
      lastSeenColumn(t),
      {
        Header: t('Assignment'),
        accessor: 'type',
        align: 'center',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${value === 1 ? t('Assignment') : t('Assignments')}`,
        Cell: (props) => {
          return (
            <>
              <SvgIcon
                title={'Clique no nome para editá-lo'}
                color={typeColor(props.value)}
                name={typeIcon(props.value)}
                compact
              />
              <br />
              <InputTemplate
                options={types}
                value={props.value || noType}
                id={props?.cell?.row?.original?.id}
                action={(id, field, value) => {
                  dispatch(updateAsset(id, { [field]: value }))
                }}
                field="type"
                table={table}
              />
            </>
          )
        },
      },
      {
        Header: t('Groups'),
        accessor: 'groupsText',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} grupos`,
      },
      {
        Header: t('Sector'),
        accessor: 'info.sector',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} setores`,
      },
      {
        Header: t('Last device used'), // 'Último Dispositivo Utilizado'
        accessor: 'serialNumber',
        icon: 'cog',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} dispositivos`,
      },
      {
        Header: t('createdBy'),
        accessor: 'info.createdBy',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} logins`,
        disableGlobalFilter: true,
      },
      {
        Header: t('createdAt'),
        accessor: 'info.createdAt',
        Cell: ({ value }) => (value ? m(value).fromNow() : t('Never')),
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} horários`,
        disableGlobalFilter: true,
      },
    ]
    return allColumnsAvailable /*.filter((c) =>
    ['id', 'name', ...(tenantColumns || columnsDefault)].includes(c.accessor))*/
  }, [t, dispatch, types, noType /*, tenantColumns, columnsDefault*/])

  const rows = assetsAllIds.filter((id) => ![2, 6, 8, 12, 14, 16, 17].includes(assetsById[id]?.idType))

  const data = useMemo(
    () =>
      rows.map((id) => {
        let asset = assetsById[id]
        let typeColor = types?.filter((f) => f.key === asset?.info?.type)?.[0]?.color || 'green'
        let typeIcon = types?.filter((f) => f.key === asset?.info?.type)?.[0]?.iconsvg || 'worker'
        let typetext = types?.filter((f) => f.key === asset?.info?.type)?.[0]?.text || ''

        const lastSeen = measuresById[id]?.info?.time

        return {
          id: asset?.id,
          name: asset?.name,
          groups: asset?.groups,
          groupsText: asset?.groups?.sort()?.toString(),
          info: asset?.info,
          type: asset?.info?.type,
          typetext: typetext,
          typeIcon: typeIcon,
          typeColor: typeColor,
          lastSeen,
        }
      }),
    [rows, assetsById, types, measuresById]
  )
  const typeName = React.useCallback(
    (id) => {
      return assetTypes?.byId?.[id]?.name || ' '
    },
    [assetTypes]
  )
  return (
    <ReactTable
      table={table}
      initialSortBy={{ desc: true, id: 'lastSeen' }}
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
      insertAction={insertAction}
      updateAction={updateAction}
      fetchAction={fetchAction}
      deleteAction={deleteAction}
      groupAction={groupAction}
      deleteGroupAction={deleteGroupAction}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      cudLoading={cudLoading}
      cudSuccess={cudSuccess}
      typeName={typeName}
      showPlaceholders={loading}
    />
  )
}

export default memo(AssetsContent, (props, nextProps) => {
  return !nextProps.autoUpdate
})
