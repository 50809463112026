import moment from 'moment'
import { TFunction } from 'i18next'
export default function formatValue(value: any, t: TFunction): string {
  if (typeof value === 'boolean') {
    return value ? 'Sim' : 'Não'
  }

  if (Array.isArray(value)) {
    return value.join(', ')
  }

  if (typeof value === 'number') {
    return value.toString()
  }

  if (value && typeof value === 'string' && value.length > 8 && moment(value, undefined, true).isValid()) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss')
  }

  if (typeof value === 'string') {
    return t(value)
  }

  if (value === null || value === undefined) {
    return ''
  }

  return 'Tipo não suportado'
}
