import React, { useState, useRef } from 'react'
import { Button, Header, Image, Modal, SegmentGroup, Segment, Label, Dimmer } from 'semantic-ui-react'
import { useReactToPrint } from 'react-to-print'
import styled from 'styled-components'
import { ReactComponent as LoadingLogo } from 'Resources/Icons/loading-logo.svg'
import { useDispatch } from 'react-redux'
import { setFalse } from 'Store/actions/toggle-action'
import ModalCloseIcon from 'Utils/reactTable/components/ModalCloseIcon'
import { useTranslation } from 'react-i18next'

const BoldSpan = styled.span`
  font-weight: bold;
`

const StyledHeader = styled(Header)`
  margin: 0px !important;
`

const MockDetails: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const contentToPrint = useRef(null)

  const [loading, setLoading] = useState(false)
  const [contentMargin, setContentMargin] = useState('0px')

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: 'Print This Document',
    onAfterPrint: () => {
      setContentMargin('0px')
      setLoading(false)
    },
    removeAfterPrint: true,
  })

  return (
    <Modal
      open
      onClose={() => {
        dispatch(setFalse('showModuleDetails'))
      }}
      closeIcon={
        <ModalCloseIcon
          action={() => {
            dispatch(setFalse('showModuleDetails'))
          }}
        />
      }
    >
      <Dimmer active={loading}>
        <div className="logoTelaCarregamento">
          <LoadingLogo />
        </div>
      </Dimmer>
      <Modal.Content>
        <div ref={contentToPrint} style={{ margin: contentMargin }}>
          <span
            style={{ fontSize: '12px' }}
          >{`MOD-000290 - Registro de Notificação e Interdição - Rev. 02 - Data: 29/07/2016`}</span>
          <SegmentGroup>
            <Segment
              size="large"
              textAlign="center"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Image
                src="https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/Tiplam/miniLogo.png"
                size="tiny"
              />

              <StyledHeader size="tiny">{'Registro de Notificação e Interdição'}</StyledHeader>
              <Label circular color={'yellow'} size="large">
                {'137'}
              </Label>
            </Segment>

            <SegmentGroup horizontal>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Data: </BoldSpan>
                {'07/05/2024'}
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Hora: </BoldSpan>
                {'12:20'}
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Local:</BoldSpan>
                {'TR 3340-17 AZ 05'}
              </Segment>
            </SegmentGroup>
            <SegmentGroup horizontal>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>De: </BoldSpan>
                {'Luciano Petrone'}
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Gerência: </BoldSpan>Saúde Segurança e MA
              </Segment>
            </SegmentGroup>
            <SegmentGroup horizontal>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Para: </BoldSpan>
                {'Julian Bernardino'}
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Gerência: </BoldSpan>
                {'Exportação'}
              </Segment>
            </SegmentGroup>

            <Segment style={{ flex: 1 }}>
              <BoldSpan>Área / Equipamento / Atividade: </BoldSpan>
              {'Armazém 05 / TR 3340-17 AZ 05'}
            </Segment>

            <Segment>
              Esta área / equipamento / atividade fica interditada por não atender as exigências mínimas de segurança,
              colocando em risco a integridade física dos empregados, conforme a Portaria3214/78.
            </Segment>

            <Segment secondary textAlign="center">
              <BoldSpan>Descrição da condição de risco</BoldSpan>
            </Segment>
            <Segment>
              Identificado nos túneis da TR 3340-17 que há excesso de açúcar no piso, presença de animais com peçonha
              (abelhas) e edificação do túnel apresentando fissuras vazando produto (açúcar ) para o interior do túnel
            </Segment>

            <Segment secondary textAlign="center">
              <BoldSpan>
                Atendidas as recomendações abaixo, o Emitente deverá ser comunicado. Após correção, o campo “Liberação
                de área” deve ser preenchido pelo Responsável pela área
              </BoldSpan>
            </Segment>
            <Segment>
              <BoldSpan>Recomendações:</BoldSpan>
              <p />
              <p>1. Bloquear temporariamente o armazém e esgotar todo o produto para limpeza e avaliação estrutural;</p>
              <p>2. Avaliar tecnicamente as estruturas do túnel e da fundação com equipe especializada;</p>
              <p>3. Após escoamento do produto açúcar no armazém, iniciar a limpeza dos túneis;</p>
              {/* <p>4. Manter interditado até o aval técnico da equipe especializada.</p> */}
            </Segment>

            <SegmentGroup horizontal>
              <Segment secondary textAlign="center" style={{ flex: 1 }}>
                <BoldSpan>Assinatura Responsável pela área interditada / Testemunha:</BoldSpan>
              </Segment>
              <Segment secondary textAlign="center" style={{ flex: 1 }}>
                <BoldSpan>Assinatura do Emitente:</BoldSpan>
              </Segment>
            </SegmentGroup>
            <SegmentGroup horizontal>
              <Segment style={{ flex: 1, height: '50px' }}></Segment>
              <Segment style={{ flex: 1, height: '50px' }}></Segment>
            </SegmentGroup>

            <Segment secondary textAlign="center">
              <BoldSpan>Providências Tomadas:</BoldSpan>
            </Segment>

            <Segment textAlign="center">
              <Image.Group size="small" style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Image
                  src="https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/Tiplam/refs/17168248021794d887d50-1c40-11ef-9ad2-0558afcd7b7d.jpeg"
                  style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                />
                <Image
                  src="https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/Tiplam/refs/1713383387080a28a5010-fcf3-11ee-99b9-93ced4b949f1.jpeg"
                  style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                />
                <Image
                  src="https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/Tiplam/refs/171649318733433c73480-193c-11ef-9b49-2d7b9c07b5cf.jpeg"
                  style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                />
                <Image
                  src="https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/Tiplam/refs/1715276910478563a0e90-0e2c-11ef-8ce1-5994c844cc1e.jpeg"
                  style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                />
              </Image.Group>
            </Segment>
            <Segment>Bloqueado o acesso instalando a sinalização e isolamento.</Segment>
            <Segment>
              <BoldSpan>Observação(es):</BoldSpan>
            </Segment>
            <Segment secondary textAlign="center">
              <BoldSpan>Liberação da área.</BoldSpan>
            </Segment>
            <SegmentGroup horizontal>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Nome:</BoldSpan>
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Assinatura:</BoldSpan>
              </Segment>
            </SegmentGroup>
            <SegmentGroup horizontal>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Gerência: </BoldSpan>
              </Segment>
              <Segment style={{ flex: 1 }}>
                <BoldSpan>Data: </BoldSpan>
              </Segment>
            </SegmentGroup>
          </SegmentGroup>
        </div>
      </Modal.Content>

      <Modal.Actions style={{ height: '65px' }}>
        <Button
          onClick={() => dispatch(setFalse('showModuleDetails'))}
          style={{ float: 'left', minWidth: '120px' }}
          icon="close"
          content={t('Close')}
        />

        <Button
          onClick={() => {
            setLoading(true)
            setContentMargin('15px')
            setTimeout(() => {
              handlePrint()
            }, 100)
          }}
          icon="print"
          content={t('print')}
          style={{ backgroundColor: '#3498db', color: 'white' }}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default MockDetails
