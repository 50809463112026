import axios from 'axios'

export const INITIAL_STORE = 'IndoorsInitialStore'
export const GEODADOS = 'geoData'
export const GEOIndoor = 'saveGeoIndoor'

export const updateIndoorServer = (id, valor, campo) => {
  let campovalor = ''
  switch (campo) {
    case 'Nome':
      campovalor = { nome: valor }
      break
    case 'Cantos':
      campovalor = { info: `'{"corners": [${valor}]}'` }
      break
    default:
      console.log('Campo não preparado para atualização, mudar na actions da Store')
  }
  return {
    type: 'server/atualiza/modulo',
    fields: campovalor,
    where: { id_modulo: id },
  }
}
export const updateIndoor = (id, valor, campo) => {
  return {
    type: 'atualizaIndoor',
    id: id,
    payload: { valor: valor, campo: campo },
  }
}

export const deleteIndoor = (item) => {
  const { url, id } = item
  return {
    type: 'deleta imagem',
    payload: { id, url },
  }
}
export const selectIndoor = (id) => {
  return {
    type: 'seleciona imagem',
    payload: { id },
  }
}
export const fetchIndoor = () => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const res = await axios.get('/indoor', {
    //fix:indoor
    headers: {
      Authorization: 'Bearer ' + jwt,
    },
  })
  dispatch({ type: 'indoorInitialStore', payload: res.data })
}

export const setCurrentIndoor = (id, payload) => (dispatch, getState) => {
  dispatch({
    type: 'SET_CURRENT_INDOOR',
    id,
    payload,
    company: getState().login.empresa,
  })
}
