import axios from 'axios'

const urlBase = 'https://1yccc8vzc6.execute-api.us-east-2.amazonaws.com'

// me from the past was kinda pissed, not gonna lie.
// if you're all gonna blame me, at least make me revisit this SO post
// https://stackoverflow.com/questions/26667820/upload-a-base64-encoded-image-using-formdata
// and this one
// https://stackoverflow.com/questions/6150289/how-can-i-convert-an-image-into-base64-string-using-javascript
// and this one too
// https://stackoverflow.com/questions/53971162/image-file-upload-to-aws-lambda-using-aws-api-gateway
// feck it this one too
// https://stackoverflow.com/questions/67484579/problem-with-async-await-with-filereader-in-javascript

// https://simon-schraeder.de/posts/filereader-async/
function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}

export const insertImageData = (data, type) => async (dispatch) => {
  const jsonObject = {}
  const gotImage = data.info.image.name !== 'no_input_image.png'

  if (gotImage) {
    // encode image file as base64 string
    let encodedString = await readFileAsync(data.info.image)
    jsonObject.base64ImageFile = encodedString
    jsonObject.fileName = data.info.image.name
    jsonObject.imageCategory = 'indoor'
  }

  dispatch({ type: 'CREATE_MODULE_REQUEST', payload: { moduleTypes: type } })
  const jwt = localStorage.getItem('id_token')
  try {
    if (gotImage) {
      const resFile = await axios.post(urlBase + '/v0/images/', jsonObject, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          'Content-Type': 'image/image', //should probably check this again some other time
        },
      })
      data.info.url = resFile.data.body
    }

    const res = await axios.post('/core/v0/modules/' + type, data, {
      //fix:indoor
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
      },
    })
    dispatch({ type: 'CREATE_MODULE_SUCCESSFULL', payload: res.data })
  } catch (err) {
    dispatch({ type: 'CREATE_MODULE_FAILURE', payload: err })
  }
}

export const updateImageData = (params, type, action, auth) => async (dispatch) => {
  const jsonObject = {}
  const gotImage = params.image.name !== 'no_input_image.png'

  if (gotImage) {
    // encode image file as base64 string
    let encodedString = await readFileAsync(params.image)
    jsonObject.base64ImageFile = encodedString
    jsonObject.fileName = params.image.name
    jsonObject.imageCategory = 'indoor'
    jsonObject.url = params.url
  }

  try {
    const jwt = auth || localStorage.getItem('id_token')

    if (gotImage) {
      const resFile = await axios.put(urlBase + '/v0/images/', jsonObject, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          'Content-Type': 'image/image', //should probably check this again some other time
        },
      })
      params.url = resFile.data.body
    }

    const res = await axios.put(action || `/core/v0/modules/${type}/${params.id}`, params, {
      //fix:indoor
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
      },
    })
    dispatch({ type: params.response || 'DYNAMIC_UPDATE_SUCCESS', payload: res?.data, id: res?.data?.id })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'DYNAMIC_UPDATE_FAILURE', payload: params })
  }
}

export const deleteDynamicData = (params, moduleType, auth) => async (dispatch) => {
  try {
    const jwt = auth || localStorage.getItem('id_token')
    axios
      .delete(urlBase + `/v0/modules/${moduleType}/?ids=${params.ids.toString()}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then(
        dispatch({
          type: params.response || 'DYNAMIC_DELETE_SUCCESS',
          payload: { ...params, moduleType: moduleType },
          id: params.ids,
        })
      )
  } catch (err) {
    console.error(err)
    dispatch({ type: 'DYNAMIC_DELETE_FAILURE', payload: { params: params, err: err } })
  }
}
