export default function projectsReducer(state = [], { type, id, payload }) {
  let newState = ''
  switch (type) {
    case 'projectsInitialStore':
      if (payload === null) {
        console.error('Error in project initial store')
        return []
      }
      if (!payload[0]) {
        console.warn('No project in store!')
        return []
      }
      if (!payload[0].projects) {
        //console.warn("project's store empty!")
        return []
      }
      return payload[0].projects

    case 'projectNew':
      newState = Object.values(state)
      newState.unshift(payload)
      return { ...newState }

    case 'atualizaTipoAtivo':
      newState = Object.assign({}, state)
      Object.entries(newState).forEach((data) => {
        if (id === data[1].id) {
          //console.log('Paypay', payload);
          switch (payload.campo) {
            case 'Velmax':
              newState[data[0]].info.velmax = payload.valor
              break
            default:
              console.error('Erro no projects reducer, atualiza Ativo sem campo preparado para atualizar')
          }
        }
      })
      return Object.assign({}, state, newState)

    case 'updateprojectNew':
      newState = Object.assign({}, state)
      Object.entries(newState).forEach((data) => {
        if (payload.id === data[1].id) {
          newState[data[0]].info.velmax = payload.valor
          return newState
        }
      })
      return Object.assign({}, state, newState)

    case 'deletaTipoAtivos':
      newState = state
      Object.entries(newState).forEach((data) => {
        if (payload.indexOf(data[1].id) !== -1) delete newState[data[0]]
      })
      return { ...newState }

    case 'updatedeleteprojectNew':
      newState = Object.assign({}, state)
      Object.entries(newState).forEach((data) => {
        if (payload.id === data[1].id) delete newState[data[0]]
      })
      return Object.assign({}, newState)
    default:
      return state
  }
}
