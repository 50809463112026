import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Grid, Icon, Loader } from 'semantic-ui-react'
import PopupInfoEdit from './PopupInfoEdit.js'
import { useSelector, useDispatch } from 'react-redux'
import { infoField } from 'Components/Map/PopupMarker/jsonGroup'
import { useTranslation } from 'react-i18next'
import { fetchCategoryMeasures } from 'Store/actions/measures-action'
import { fetchHistCategory } from 'Store/actions/history-action'

/*  Author: Bruno Melo
    Type: dumb
	Description: Render all fields selected by the user;				 
  To do list: -				
*/

const InfoField = styled(Grid.Row)`
  min-height: 19px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`
const InfoContainer = styled(Grid.Column)`
  margin-right: 1px;
  padding-top: 8px !important;
  height: 120px;
  width: 269px;
  overflow-x: auto;
  overflow-y: auto;
  text-transform: none;
  float: left;
`
const PopupMiddle = styled(Grid.Row)`
  width: 238px;
`

const PopupInfoFields = ({ assetId, mode }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const deviceId = useSelector((state) => state.measures.byId[assetId]?.deviceId)
  const currentCategory = useSelector((state) => state[mode].byId[assetId]?.currentCategory)
  const loadingCategoryMeasures = useSelector((state) => state[mode].loadingCategoryMeasures)
  const categoryMeasuresStr = useSelector((state) =>
    JSON.stringify(state[mode].byId[assetId]?.info?.[currentCategory] || {})
  )
  const currentTime = useSelector((state) => state[mode].byId[assetId]?.info?.time)
  const categoryMeasures = JSON.parse(categoryMeasuresStr)

  let infoFields = categoryMeasures && infoField(categoryMeasures, t, currentCategory)

  useEffect(() => {
    if (currentCategory && !categoryMeasures.time && !loadingCategoryMeasures) {
      mode === 'measures'
        ? dispatch(fetchCategoryMeasures(assetId, currentCategory, !categoryMeasures[currentCategory]?.time))
        : dispatch(fetchHistCategory(assetId, currentCategory, currentTime, !categoryMeasures[currentCategory]?.time))
    }
  })

  return (
    <PopupMiddle>
      <InfoContainer floated="left" width={11}>
        {infoFields && !loadingCategoryMeasures ? (
          infoFields
            .filter((d) => d && !['time', 'Hora', 'hora', 'tempo'].includes(d?.campo))
            .sort((a, b) => b.campo?.localeCompare(a.campo))
            .map((d, i) => {
              return (
                <Grid key={'infoFieldsfrag' + d.campo} columns={2}>
                  {d.buttons ? (
                    <PopupInfoEdit data={d} channel={i} sensor={deviceId} />
                  ) : (
                    <InfoField title={d.title} key={'infoFieldstitle' + d.campo}>
                      <Grid.Column width={14}>
                        <span>{'   '}</span>
                        {d.icon ? <Icon title={d.campo} name={d.icon} /> : <span>{d.campo}</span>}
                        <span>{' : '}</span>
                        <span>{d.valor}</span>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Icon
                          floated="right"
                          title={d.buttons?.title}
                          name={d.buttons?.name}
                          onClick={d.buttons?.action}
                        />
                      </Grid.Column>
                    </InfoField>
                  )}
                </Grid>
              )
            })
        ) : (
          <>
            <br />
            <Loader active inline="centered" />
          </>
        )}
      </InfoContainer>
    </PopupMiddle>
  )
}
export default PopupInfoFields
