import React, { useEffect, useMemo } from 'react'
import {
  Divider,
  Dropdown,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownMenu,
  Segment,
} from 'semantic-ui-react'
import styled from 'styled-components'
import DashboardForm from './DashboardForm'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDashboards, setBetaDashboardProps } from 'Store/actions/betaDashboards-action'
import useFetchData from 'Utils/hooks/useFetchData'
import { RootStore } from 'Store/initialStore'
import { useHistory, useRouteMatch } from 'react-router-dom'
import DashboardContent from './DashboardContent'
import { maxBy } from 'lodash'
import DashboardsMenuButtons from './DashboardsMenuButtons'
import ConfirmModal from 'Utils/components/ConfirmModal'
import ErrorBoundary from 'Utils/ErrorBoundary/ErrorBoundary'

const DashContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-right: 20px;
`

const DashMenu = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 50px 0px 20px 0px;
`

const DashContentContainer = styled.main`
  flex: 1;
  padding: 20px 20px 25px 0px;
  min-height: 76vh;
  height: auto;
  overflow-y: scroll;
  overflow-x: scroll;
  width: 98.9vw;
  max-width: 98.9vw;
  background-color: #fafafa;
  margin-bottom: 2.5%;
`

const LeftItem = styled.div`
  // Estilos específicos para o item esquerdo
`

const RightItem = styled.div`
  // Estilos específicos para o item direito
`

const StyledSegment = styled(Segment)`
  float: left;
  margin-top: 0;
  border: none;
  padding: 0 !important;
`

const StyledDropdown = styled(Dropdown)`
  width: 15vw; /* Altere para o tamanho desejado */
  font-size: 16px; /* Altere para o tamanho da fonte desejado */
  .text {
    padding: 10px; /* Altere o padding conforme necessário */
  }
`

const FullWidthDivider = styled(Divider)`
  width: 100vw !important;
  margin-left: calc(-50vw + 50%) !important;
  opacity: 0.8;
`

const Dashboards = () => {
  const dispatch = useDispatch()

  useFetchData({
    independentFetches: [{ fetchAction: 'dashboards' }],
  })

  const history = useHistory()
  const match = useRouteMatch()
  const pathCode = match.params.code

  const dashboardIds = useSelector((state: RootStore) => state.betaDashboards.allIds)
  const dashboardsById = useSelector((state: RootStore) => state.betaDashboards.byId)

  const currentId = useSelector((state: RootStore) => state.betaDashboards.current?.id)

  const openDashForm = useSelector(
    (state: RootStore) => state.betaDashboards.form.open && state.betaDashboards.form.type === 'save'
  )
  const openDeleteForm = useSelector(
    (state: RootStore) => state.betaDashboards.form.open && state.betaDashboards.form.type === 'delete'
  )

  const loading = useSelector((state: RootStore) => state.betaDashboards.loading)
  const loadedAt = useSelector((state: RootStore) => state.betaDashboards.loadedAt)

  // Adquire o valor de id para o pathCode
  const pathId = useMemo(() => {
    if (pathCode) {
      return dashboardIds.find((id) => dashboardsById[id].code === pathCode)
    } else {
      return null
    }
  }, [pathCode, dashboardIds, dashboardsById])

  // Encaminha para o último dashboard que foi atualizado caso o pathCode esteja vazio ou sem dashboard definido
  useEffect(() => {
    if (!pathId && loadedAt) {
      const latestDashboardId = maxBy(dashboardIds, (id) => dashboardsById[id].updatedAt)
      if (latestDashboardId) {
        const defaultPath = `/app/dashboards/${dashboardsById[latestDashboardId].code}`
        dispatch(setBetaDashboardProps([[`current`, dashboardsById[latestDashboardId]]]))
        history.push(defaultPath)
      } else {
        dispatch(setBetaDashboardProps([[`addAnimationOn`, true]]))
        const defaultPath = '/app/dashboards'
        history.push(defaultPath)
      }
    }
  }, [pathId, dashboardIds, dashboardsById, history, dispatch, loadedAt])

  // Altera o current caso o id do pathCode(pathId) tenha mudado
  useEffect(() => {
    if (pathId && pathId !== currentId && !openDashForm) {
      dispatch(setBetaDashboardProps([[`current`, dashboardsById[pathId]]]))
      dispatch(setBetaDashboardProps([[`wip`, {}]]))
    }
  }, [pathId, currentId, dashboardsById, dispatch, openDashForm])

  const dashboardOptions = dashboardIds
    .map((id) => {
      const dashboard = dashboardsById[id]
      return { key: dashboard.id, text: dashboard.label, value: dashboard.code }
    })
    .sort((a, b) => a.text.localeCompare(b.text))

  return (
    <ErrorBoundary>
      <DashContainer>
        <DashMenu>
          <LeftItem>
            <StyledDropdown
              text={dashboardOptions.find((opt) => opt.value === pathCode)?.text || pathCode}
              floating
              labeled
              button
              icon="chart bar"
              className="icon"
              value={pathCode}
            >
              <DropdownMenu>
                <DropdownHeader>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    Dashboards
                    <span style={{ fontSize: 'smaller', color: '#999' }}>Painéis</span>
                  </div>
                </DropdownHeader>
                <DropdownDivider />
                {dashboardOptions.map((opt) => {
                  const selected = opt.value === pathCode
                  return (
                    <DropdownItem
                      {...opt}
                      description={Object.keys(dashboardsById[opt.key].panels ?? {}).length}
                      selected={selected}
                      onClick={() => {
                        dispatch(
                          setBetaDashboardProps([
                            [`current`, dashboardsById[opt.key]],
                            [`wip`, {}],
                          ])
                        )

                        history.push(`/app/dashboards/${opt.value}`)
                      }}
                    />
                  )
                })}
              </DropdownMenu>
            </StyledDropdown>
          </LeftItem>
          <RightItem>
            <StyledSegment floated="left" style={{ marginTop: '0px', border: 'none' }}>
              <DashboardsMenuButtons />
            </StyledSegment>
          </RightItem>
        </DashMenu>
        <FullWidthDivider fitted />
        <DashContentContainer className="dash-content-container">
          {currentId && <DashboardContent />}
        </DashContentContainer>
      </DashContainer>
      {openDashForm && <DashboardForm />}
      {openDeleteForm && (
        <ConfirmModal
          message="Atenção: Ao remover esse Dashboard, todos os painéis associados serão apagados permanentemente. Essa ação não pode ser desfeita."
          confirmAction="delete"
          onSave={() => dispatch(deleteDashboards([currentId]))}
          loading={loading}
        />
      )}
    </ErrorBoundary>
  )
}

export default Dashboards
