const fitBounds = (type, coords, refMap) => {
  let auxCoords
  if (!coords) {
    console.error(`Fitbounds do ${type} without coords: ${coords}`)
    return
  }
  let options
  switch (type) {
    case 'fences':
      let typeCoords = coords[0]
      if (coords.length === 2) {
        typeCoords = [coords]
        options = { maxZoom: 18 }
      }
      auxCoords = typeCoords.map((e) => [e[1], e[0]])
      break
    case 'routes':
      auxCoords = coords.map((e) => [e[1], e[0]])
      break
    case 'refs':
      auxCoords = [[coords[1], coords[0]]]
      break
    default:
      auxCoords = coords
      break
  }
  if (auxCoords && auxCoords[0] && refMap) {
    refMap.flyToBounds(auxCoords, options)
  }
}
export default fitBounds
