import * as z from 'zod'

const ticon = z.object({
  name: z.string().optional(),
  code: z.string().optional().nullable(),
  qty: z.number().optional(),
})

const vehicle = z.object({
  name: z.string().optional(),
})

const Phase = z.object({
  index: z.number(),
  name: z.string().nonempty(),
  description: z.string().optional().nullable(),
  color: z.string(),
  code: z.string().optional().nullable(),
  vehicle: z.array(vehicle).optional().nullable(),
  ticon: z.array(ticon).optional().nullable(),
})

const stepFiveSchema = z.object({
  phases: z.array(Phase),
})

export type StepFive = z.infer<typeof stepFiveSchema>
export type Phase = z.infer<typeof Phase>

export default stepFiveSchema
