import axios from 'axios'
import { urlBase } from './rest'

export const fetchSimulations = async () => {
  const token = localStorage.getItem('id_token')
  const simulations = await axios.get(urlBase + '/simulations?op=list', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  const byId = simulations.data.reduce((acc, simulation) => {
    simulation.id = simulation.simId
    simulation.status = simulation.stage
    acc[simulation.simId] = simulation
    return acc
  }, {})
  return { byId, allIds: simulations.data.map((simulation) => simulation.simId) }
}

export const getSimulation = async (simId) => {
  const simulation = await axios.get(urlBase + '/simulations/' + simId)
  return simulation.data
}

export const deleteSimulation = async (simId) => {
  const simulation = await axios.delete(urlBase + '/simulations/' + simId)
  return simulation
}

export const createSimulation = async (simulation) => {
  const token = localStorage.getItem('id_token')
  const response = await axios.post(urlBase + '/simulations', simulation, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
  return response.data
}

export const updateSimulation = async (simulation) => {
  const response = await axios.put(urlBase + '/simulations', simulation)
  return response.data
}

export const createDeviceType = async (deviceType) => {
  const response = await axios.post(urlBase + '/devicetypes', deviceType)
  return response.data
}
