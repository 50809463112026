import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import React, { useEffect } from 'react'
import { useTable, useSortBy, useGlobalFilter, useRowSelect, useGroupBy, useExpanded, TableOptions } from 'react-table'
import { Table, Icon, HeaderProps } from 'semantic-ui-react'
import styled from 'styled-components'
import DataLabelingsSideTableColumns from './DataLabelingsSideTableColumns'
import { initLabelsTableSetSortBy, calcWipCycles } from 'Store/actions/dataLabelings-actions'
import { useDispatch } from 'react-redux'
const TableHeaderCell = styled(Table.HeaderCell)`
  position: sticky;
  top: -1px;
  z-index: 3;
`

interface CustomTableOptions<D extends object> extends TableOptions<D> {
  autoResetExpanded?: boolean
}

const DataLabelingSideTable = () => {
  const dispatch = useDispatch()

  const labelings = useObjSelector((state: RootStore) => state.dataLabelings.wip?.labelings || [])

  useEffect(() => {
    dispatch(calcWipCycles())
  }, [dispatch])

  const phases = useObjSelector((state: RootStore) => state.dataLabelings.wip?.phases || [])

  const columns = React.useMemo(() => {
    return DataLabelingsSideTableColumns({ phases })
  }, [phases])

  const data = React.useMemo((): any[] => {
    return labelings.map((label, index) => ({ ...label, labelIndex: index }))
  }, [labelings])

  const allTableProps: any = useTable(
    {
      columns,
      data,
      autoResetExpanded: false,
      initialState: {
        sortBy: [{ desc: false, id: 'startTime' }],
      },
    } as CustomTableOptions<{ [key: string]: any }>,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect
  )

  useEffect(() => {
    dispatch(initLabelsTableSetSortBy(allTableProps.setSortBy))
  }, [allTableProps.setSortBy, dispatch])

  return (
    <div style={{ marginTop: '15px' }}>
      <Table striped color={'blue'} singleLine {...allTableProps.getTableProps()} compact size="small">
        <Table.Header>
          {
            // Loop over the header rows
            allTableProps.headerGroups.map((headerGroup: HeaderProps) => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column: { [key: string]: any }) => (
                    <TableHeaderCell
                      textAlign={column?.align || 'left'}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {
                        // Render the headecolumn.id.startsWith('MediaTgTot')
                        column.render('Header')
                      }
                      {column.isSorted ? column.isSortedDesc ? <Icon name="sort down" /> : <Icon name="sort up" /> : ''}
                      {column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <Icon
                          {...column.getGroupByToggleProps()}
                          name={column.isGrouped ? 'level down' : 'level up'}
                          rotated="clockwise"
                          style={{ marginLeft: '4px' }}
                        />
                      ) : null}
                    </TableHeaderCell>
                  ))
                }
              </Table.Row>
            ))
          }
        </Table.Header>
        {/* Apply the table body props */}
        <Table.Body {...allTableProps.getTableBodyProps()}>
          {
            // Loop over the table rows
            allTableProps.rows.map((row: { [key: string]: any }) => {
              // Prepare the row for display
              allTableProps.prepareRow(row)
              return (
                // Apply the row props
                <Table.Row {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell: { [key: string]: any }) => {
                      // Apply the cell props
                      return (
                        <Table.Cell
                          textAlign={cell?.column?.align || 'left'}
                          verticalAlign="middle"
                          {...cell.getCellProps()}
                        >
                          {
                            // Render the cell contents
                            cell.isGrouped ? (
                              <>
                                <Icon
                                  name={row.isExpanded ? 'minus square outline' : 'plus square outline'}
                                  {...row.getToggleRowExpandedProps()}
                                />
                                {cell.render('Cell')} ({row.subRows.length})
                              </>
                            ) : cell.isAggregated ? (
                              cell.render('Aggregated')
                            ) : cell.isPlaceholder ? null : (
                              cell.render('Cell')
                            )
                          }
                        </Table.Cell>
                      )
                    })
                  }
                </Table.Row>
              )
            })
          }
        </Table.Body>
      </Table>
    </div>
  )
}

export default DataLabelingSideTable
