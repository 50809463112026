import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { setDataLabelingsProp } from 'Store/actions/dataLabelings-actions'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const DataLabelingsTimeForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const startTime = useSelector((state: RootStore) => state.dataLabelings.wip?.startTime)
  const endTime = useSelector((state: RootStore) => state.dataLabelings.wip?.endTime)
  const currentStep = useSelector((state: RootStore) => state.dataLabelings.form?.steps?.current)
  const startTimeError = useSelector(
    (state: RootStore) => state.dataLabelings.form?.errors?.[currentStep]?.startTime?.[0]
  )
  const endTimeError = useSelector((state: RootStore) => state.dataLabelings.form?.errors?.[currentStep]?.endTime?.[0])
  const showFeedback = useSelector(
    (state: RootStore) => state.dataLabelings.form?.steps.items[currentStep].showFeedback
  )
  const handleChangeAbsTimeStart = (e: any) => {
    dispatch(setDataLabelingsProp(`wip.startTime`, moment(e.target.value).toISOString()))
  }
  const handleChangeAbsTimeEnd = (e: any) => {
    dispatch(setDataLabelingsProp(`wip.endTime`, moment(e.target.value).toISOString()))
  }
  return (
    <Form.Group widths="equal">
      <Form.Field inline required error={showFeedback && !!startTimeError} width="4">
        <label>{t('start')}</label>

        <Input
          required
          fluid
          type="datetime-local"
          id="start"
          name="date-start"
          onChange={handleChangeAbsTimeStart}
          value={startTime && moment(startTime).format('YYYY-MM-DDTHH:mm:ss')}
          min={'2022-01-01'}
          max={moment().format('YYYY-MM-DDTHH:mm:ss')}
          step="1"
        />
      </Form.Field>
      <Form.Field inline required error={showFeedback && !!endTimeError} width="4">
        <label>{t('end')}</label>

        <Input
          required
          fluid
          type="datetime-local"
          id="end"
          name="date-end"
          value={endTime && moment(endTime).format('YYYY-MM-DDTHH:mm:ss')}
          min={'2022-01-01'}
          max={moment().format('YYYY-MM-DDTHH:mm:ss')}
          onChange={handleChangeAbsTimeEnd}
          step="1"
        />
      </Form.Field>
    </Form.Group>
  )
}

export default DataLabelingsTimeForm
