import DropdownField from 'Components/forms/generic/DropdownField'
import EntityDropdownField from 'Components/forms/generic/EntityDropdownField'
import React from 'react'
import { FiltersType } from 'schemas/filters'
import { Form, DropdownProps } from 'semantic-ui-react'

/**
 * Props for GenericAssetsPropsFieldsWithProps component.
 */
export type AssetPropFiltersType = {
  /**
   * Function to update properties.
   * @param field - The field name to update.
   * @param value - The value to assign to the field.
   */
  setProp: (field: string, value: any) => void

  /**
   * Selected sssets (IDs, profileIds, etc).
   */
  selectedAssets: FiltersType

  /**
   * Whether form validation feedback is shown.
   */
  showFeedback: boolean

  /**
   * Indicates if fields are required.
   */
  required?: boolean
}

/**
 * A generic component that renders fields for selecting asset properties and their values.
 *
 * @param props - Component props.
 * @returns The rendered component.
 */
const AssetPropFilters: React.FC<AssetPropFiltersType> = ({
  setProp,
  selectedAssets,
  showFeedback,
  required = true,
}) => {
  const assetField = selectedAssets?.[0]?.field ?? ''
  const assetValues = selectedAssets?.[0]?.values ?? []

  const handlePropChange = (e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    setProp('wip.filters.selectedAssets', [{ field: value }])
  }

  const handleValueChange = (value: any) => {
    if (!value || value?.length === 0) {
      setProp('wip.filters.selectedAssets', [])
    } else {
      setProp('wip.filters.selectedAssets', [{ field: assetField, values: value }])
    }
  }

  return (
    <Form.Group widths="equal">
      <DropdownField
        label="Ativo/Pessoa"
        value={assetField}
        placeholder={'Selecionar Propriedade'}
        name="assetProp"
        onChange={handlePropChange}
        options={[
          {
            key: 'Atribuição',
            value: 'type',
            text: 'Atribuição',
          },
          {
            key: 'name',
            value: 'name',
            text: 'Nome',
          },
        ]}
        noResultsMessage={'Nenhum resultado encontrado no filtro selecionado.'}
        showFeedback={showFeedback}
      />
      {assetField === 'name' && (
        <EntityDropdownField
          label="Pessoa/Ativo"
          entity="assets"
          value={assetValues}
          name="assets"
          placeholder="Selecionar Nome(s)"
          onChange={handleValueChange}
          multiple
          required={required}
        />
      )}
      {assetField === 'type' && (
        <EntityDropdownField
          label="Atribuição"
          entity="profiles"
          value={assetValues}
          name="profiles"
          placeholder="Selecionar Atribuição(s)"
          onChange={handleValueChange}
          multiple
          required={required}
          filter={(value) => value.type === 'people' || value.type === 'assets'}
        />
      )}
    </Form.Group>
  )
}

export default AssetPropFilters
