import L from 'leaflet'
import { GeoJSON, Pane } from 'react-leaflet'
import React, { Fragment } from 'react'
import ReactDistortableImageOverlay from 'Libs/ImageOverlay/index'
//import {openModal} from '../Utilities/FileUpload';
import './ImageOverlay.css'
import { selectIndoor, updateIndoorServer, deleteIndoor, updateIndoor } from 'Store/actions/indoor-action'
import { connect } from 'react-redux'
import axios from 'axios'
import { URL_BASE } from 'Apis/rest'

const backfence = (element) => {
  const custom = element.info.fence
  if (custom) return custom
  return [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [element.info.corners[0].lng, element.info.corners[0].lat],
            [element.info.corners[1].lng, element.info.corners[1].lat],
            [element.info.corners[3].lng, element.info.corners[3].lat],
            [element.info.corners[2].lng, element.info.corners[2].lat],
          ],
        ],
      },
    },
  ]
}

class ImageOverlayRotated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editMode: 'none',
      currentID: 0,
      showButtons: false,
    }
  }

  onCornersUpdate = async (corners, id) => {
    await axios.put(
      URL_BASE + '/indoor/' + id,
      {
        corners: [
          { lat: corners[0].lat, lng: corners[0].lng },
          { lat: corners[1].lat, lng: corners[1].lng },
          { lat: corners[2].lat, lng: corners[2].lng },
          { lat: corners[3].lat, lng: corners[3].lng },
        ],
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('id_token'),
        },
      }
    )
  }
  onDelete = (item) => {
    this.props.deleteIndoor(item)
  }
  onSelect = (id) => {
    this.props.selectIndoor(id)
  }

  render() {
    const indoor = this.props.indoor ? (this.props.indoor.hasOwnProperty('items') ? this.props.indoor.items : []) : []
    return (
      <Fragment>
        {indoor.length > 0 &&
          this.props.zoom > 13 &&
          indoor
            .filter((e) => !this.props.indoorPreferences[e.id]?.invisible)
            .map((element, i) => {
              if (this.props.zoom < element.info.zoomLevel || element.info.displayOnInnerMapOnly) return null
              return (
                <Fragment key={element.id}>
                  <Pane name={'background' + element.id} style={{ zIndex: 201 }}>
                    <GeoJSON
                      key={'p' + element.id}
                      color={element.info?.properties?.color || '#FFFBF0'}
                      fillOpacity={1}
                      data={backfence(element)}
                    />
                  </Pane>
                  <ReactDistortableImageOverlay
                    key={element.id}
                    id={element.id}
                    opacity={0.7}
                    onCornersUpdated={(corners) => this.onCornersUpdate(corners, element.id)}
                    onWellKnownTextUpdated={(wkt) => null}
                    onDelete={this.onDelete}
                    onSelection={this.onSelect}
                    keymapper={false}
                    suppressToolbar={false}
                    mode="lock"
                    url={element.info.url}
                    corners={[
                      new L.latLng([element.info.corners[0].lat, element.info.corners[0].lng]),
                      new L.latLng([element.info.corners[1].lat, element.info.corners[1].lng]),
                      new L.latLng([element.info.corners[2].lat, element.info.corners[2].lng]),
                      new L.latLng([element.info.corners[3].lat, element.info.corners[3].lng]),
                    ]}
                  />
                </Fragment>
              )
            })}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  indoor: state.indoor,
  refMap: state.login.refMap,
  indoorPreferences: state.login.preferences.indoor,
  updateIndoor: state.toggle.updateIndoor,
  inner: state.toggle.showInnerMap,
  perfil: state.login.perfil,
})

export default connect(mapStateToProps, { selectIndoor, updateIndoorServer, deleteIndoor, updateIndoor })(
  ImageOverlayRotated
)
