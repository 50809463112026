import React from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { get } from 'lodash'
import InputField, { InputFieldProps } from './InputField'

interface InputStoreFieldProps extends InputFieldProps {
  entity: string
}

const InputStoreField: React.FC<InputStoreFieldProps> = ({ value, ...fields }) => {
  const storeValue = useSelector((state: RootStore) => {
    const wipValue = get(state, `${fields.entity}.wip.${value}`)
    const currentValue = get(state, `${fields.entity}.current.${value}`)

    return wipValue ?? currentValue
  })

  return <InputField value={storeValue as unknown as string | number} {...fields} />
}

export default InputStoreField
