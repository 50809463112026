import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Segment, Dropdown, DropdownProps, DropdownItemProps, Form, Divider } from 'semantic-ui-react'
import { RootStore } from 'Store/initialStore'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import {
  // fetchUsersByFences,
  fetchLogoutsByTime,
  fetchDevicesByTime,
  fetchFenceRulesByTime,
  fetchFirefightersByTime,
  // fetchPeopleTypeByTime,
  // fetchRulesTypeByTime,
  //fetchPresenceByFences,
  fetchReports,
  fetchUsersByFences,
  //fetchNearMissesByPerimeter,
  fetchReportData,
  fetchAppRuleTriggers,
  fetchEmergencies,
  fetchReportByEndpoint,
} from 'Store/actions/tables-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import exportReport from 'Utils/exportReport'
import m from 'moment'
import { clearRules, fetchRules } from 'Store/actions/rules-action'
import { fetchEmergency } from 'Store/actions/notification-action'
import { useHistory } from 'react-router-dom'
import useQuery from 'Utils/hooks/useQuery'
import { PresenceHistoryByPerimeter } from './forms/schema'
import AssetsPropsFields from 'Components/forms/generic/AssetsPropsFields'
import EntityDropdownField from 'Components/forms/generic/EntityDropdownField'

const SelectDateModal = ({ open }: { open: boolean }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const reportType = useSelector((state: RootStore) => state.tables.byId.reports.reportType)
  const reportViewForm = useSelector((state: RootStore) => state.tables.byId.reports.reportViewForm)

  // control relative time; toggle green color
  const query = useQuery()
  const relativeTimeString = query.get('relativeTime') || '1d'
  const startTimeString = query.get('startTime') || ''
  const endTimeString = query.get('endTime') || ''
  const relativeContent = (query.get('mode') ?? 'relative') === 'relative'
  const perimeterTypeID = query.get('fenceId') || ''
  // this can probably be coded in an easier way (anyway, shamelessly stolen from SO)
  const getCurrentDate = () => {
    let d = new Date()
    const offset = d.getTimezoneOffset()
    d = new Date(d.getTime() - offset * 60 * 1000)
    return d.toISOString().split('T')[0]
  }

  const fetchSuccess = useSelector((state: RootStore) => state.tables.byId.reports.fetchSuccess)

  // for the fetchRules??
  const tenant = useSelector((state: RootStore) => state.login.empresa)

  // used when fetching data AFTER selecting the date
  const loadingRequest = useSelector((state: RootStore) => state.tables.byId.reports.fetchLoading)

  // access report data fetched from the back-end
  const reportData = useObjSelector((state: RootStore) => state.tables.byId.reports.reportPayload)

  // for the dropdown
  const peopleTypesAllIds = useObjSelector((state) => state.profiles.allIds)
  const peopleTypesById = useObjSelector((state) => state.profiles.byId)
  const [peopleTypeID, setPeopleTypeID] = useState('0')

  const rulesTypesAllIds = useObjSelector((state: RootStore) => state.rules.allIds)
  const rulesTypesById = useObjSelector((state: RootStore) => state.rules.byId)
  const [, /* rulesTypeID */ setRulesTypeID] = useState('0')

  const perimetersTypesAllIds = useObjSelector((state: RootStore) => state.modules.fences.allIds)
  const perimetersTypesById = useObjSelector((state: RootStore) => state.modules.fences.byId)

  const [refTypeID, setRefTypeID] = useState('')

  const [assetId, setAssetId] = useState('')
  const [profileId, setProfileId] = useState('')

  const updateQuery = (key: string, value: string) => {
    query.set(key, value)
    history.replace({ search: query.toString() })
  }

  const handleDate = () => {
    const timeStampParams = relativeContent
      ? { relativeTime: relativeTimeString }
      : {
          startTime: `${startTimeString.split('T')[0]} 00:00:00`,
          endTime: `${endTimeString.split('T')[0] || new Date().toISOString().split('T')[0]} 23:59:59`,
        }

    const params = {
      time: startTimeString ? 'abs' : 'rel',
      ...timeStampParams,
    }

    dispatch({
      type: 'FETCH_REPORT_START',
      payload: params,
    })
    const fetchArgument = {
      grouping: 'day',
      assetId,
      fenceId: perimeterTypeID,
      refTypeId: refTypeID,
      profileId: peopleTypeID,
      ...timeStampParams,
    }

    // TODO: refactor this switch into something more readable and less monkey brain
    switch (reportType) {
      case 'usersByFences':
        dispatch(fetchUsersByFences(fetchArgument, relativeContent))
        // dispatch(changeCurrentDashboardTime(relativeTimeString || '1d'))
        // dispatch(fetchDashboard('fences', null, true))
        break
      case 'logoutsByTime':
        dispatch(fetchLogoutsByTime(fetchArgument, relativeContent))
        break
      case 'devicesByTime':
        dispatch(fetchDevicesByTime(fetchArgument, relativeContent))
        break
      case 'fenceRulesByTime':
        dispatch(fetchFenceRulesByTime(fetchArgument, relativeContent))
        break
      // 8 - Relatório de Usuários por Atribuição
      case 'firefightersByTime':
        dispatch(fetchFirefightersByTime(fetchArgument, relativeContent))
        break
      case 'peopleTypeByTime':
        dispatch(fetchReports(fetchArgument, relativeContent, reportType, peopleTypeID))
        break
      case 'presenceByFencesGrouped': // this is that cloned (bodged) report
      case 'presenceByFences': {
        let payload: PresenceHistoryByPerimeter = {
          assetId: assetId,
          assetProfileId: profileId,
          fenceId: fetchArgument.fenceId,
          startTime: relativeContent ? `ago(${relativeTimeString})` : m(startTimeString).toISOString() || '',
          endTime: relativeContent ? `now()` : m(endTimeString).toISOString() || '',
        }

        dispatch(
          fetchReportByEndpoint({
            endpoint: 'presenceHistoryByPerimeter',
            payload,
          })
        )
        break
      }

      case 'accidentsByTime':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'geomodulesByTime',
            filterType: 'type',
            filters: '{"type": "refs","profile": "28118","refFieldTime":"a5922828-b77c-470d-bc66-64535d16c087"}',
          })
        )
        break
      case 'nearMissesByTime':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'geomodulesByTime',
            filterType: 'type',
            filters: '{"type": "refs","profile": "28119","refFieldTime":"62a5fc57-aa80-4b5d-b74d-db777baef4e4"}',
          })
        )
        break
      case 'interditionsByTime':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'geomodulesByTime',
            filterType: 'type',
            filters: '{"profile": "28112","refFieldTime":"data"}',
          })
        )
        break
      case 'notificationsByTime':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'geomodulesByTime',
            filterType: 'type',
            filters: '{"type": "refs","profile": "29969","refFieldTime":"bf53b26a-574f-42bf-878c-290f57a33737"}',
          })
        )
        break
      case 'speedingViolationByTime':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'geomodulesByTime',
            filterType: 'type',
            filters: '{"type": "refs","profile": "29769","refFieldTime":"b44c122a-99b9-41dc-a9ec-20bccddce970"}',
          })
        )
        break
      case 'lifeViolationsByTime':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'geomodulesByTime',
            filterType: 'type',
            filters: '{"type": "refs","profile": "28594","refFieldTime":"1531ff90-ff7e-48fe-8759-0707d19edad4"}',
          })
        )
        break
      case 'EPIRulesByTime':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'EPIRulesByTime',
          })
        )
        break
      case 'userRulesByTime':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'userRulesByTime',
          })
        )
        break
      case 'rulesTypeByTime':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'rules',
          })
        )
        break
      case 'referencesByTimeNewMoove':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'geomodulesByTime',
            filterType: 'type',
            filters: '{"type": "refs","profile": "30025","refFieldTime":"70da95fe-2af3-4336-b01d-5cac1be4da7a"}',
          })
        )
        break
      case 'mobReportBuracometro':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'mobReports',
            filterType: 'holesByRoutes',
            filters: '{}',
          })
        )
        break
      case 'mobReport6':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'mobReports',
            filterType: 'mockReport6',
            filters: '{}',
          })
        )
        break
      case 'Emergencies':
        dispatch(fetchEmergency())
        break

      case 'deviceTypeRulesByTime':
        dispatch(fetchAppRuleTriggers(fetchArgument))
        break

      case 'emergenciesByTime':
        dispatch(fetchEmergencies(fetchArgument))
        break

      case 'cameraStatus':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'geomodulesByTime',
            filterType: 'type',
            filters: '{"type": "refs","profile": "30286"}',
          })
        )
        break

      case 'RDOS':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'geomodulesByTime',
            filterType: 'type',
            filters: '{"type": "refs","profile": "30285"}',
          })
        )
        break

      case 'fenceBreaking':
        dispatch(
          fetchReportData({
            ...params,
            scope: 'geomodulesByTime',
            filterType: 'type',
            filters: '{"type": "refs","profile": "30276"}',
          })
        )
        break

      case 'avgTimeReport1': {
        const payload = {
          startTime: params.time === 'rel' ? `ago(${params.relativeTime})` : params.startTime,
          endTime: params.time === 'rel' ? `now()` : params.endTime,
          originType: 'simulated',
        }
        dispatch(
          fetchReportByEndpoint({
            endpoint: ['Raizen', 'RaizenTest'].includes(tenant) ? 'avgTimeReport2' : 'avgTimeReport',
            payload,
          })
        )
        break
      }
      case 'avgTimeReport2': {
        const payload = {
          startTime: params.time === 'rel' ? `ago(${params.relativeTime})` : params.startTime,
          endTime: params.time === 'rel' ? `now()` : params.endTime,
          originType: 'real',
        }
        dispatch(
          fetchReportByEndpoint({
            endpoint: ['Raizen', 'RaizenTest'].includes(tenant) ? 'avgTimeReport2' : 'avgTimeReport',
            payload,
          })
        )
        break
      }
      default:
        dispatch(fetchReports(fetchArgument, relativeContent, reportType))
        break
    }
  }

  const handleCancel = () => {
    // clear date selection
    // close the modal
    dispatch({
      type: 'SELECT_DATE_REPORTS_CLOSE',
    })
  }

  const handleClose = () => {
    // close the modal
    dispatch({
      type: 'SELECT_DATE_REPORTS_CLOSE',
    })
  }

  const handlePeopleTypeIDChange = (_e: any, data: DropdownProps) => {
    if (typeof data.value !== 'undefined') setPeopleTypeID(data.value.toString())
  }

  const handleRulesTypeIDChange = (_e: any, data: DropdownProps) => {
    if (typeof data.value !== 'undefined') setRulesTypeID(data.value.toString())
  }

  const handlePerimeterTypeIDChange = (_e: any, data: DropdownProps) => {
    if (typeof data.value !== 'undefined') {
      updateQuery('fenceId', data.value.toString())
    }
  }

  const handleRefTypeIDChange = (_e: any, data: DropdownProps) => {
    if (typeof data.value !== 'undefined') setRefTypeID(data.value.toString())
  }

  /* const handleSeverityLevelChange = (_e: any, data: DropdownProps) => {
     if (typeof data.value !== 'undefined') setSeverityLevel(data.value.toString())
   }*/

  const nonRelativeTimeContent = (
    <Segment.Group>
      <Segment>{t('selectDate_initial')}</Segment>
      <Segment>
        <Input
          onChange={(e, d) => {
            updateQuery('startTime', d.value)
          }}
        >
          <input
            type="datetime-local"
            id="start"
            name="date-start"
            value={startTimeString}
            min={'2022-01-01'}
            max={getCurrentDate()}
          />
        </Input>
      </Segment>

      <Segment>{t('selectDate_final')}</Segment>
      <Segment>
        <Input
          onChange={(e, d) => {
            updateQuery('endTime', d.value)
          }}
        >
          <input
            type="datetime-local"
            id="start"
            name="date-start"
            value={endTimeString}
            min={'2022-01-01'}
            max={getCurrentDate()}
          />
        </Input>
      </Segment>
    </Segment.Group>
  )

  const relativeTimeContent = (
    <Segment compact>
      <Button
        primary={relativeTimeString === '1h'}
        onClick={() => {
          updateQuery('relativeTime', '1h')
        }}
      >
        {' '}
        {t('1h')}
      </Button>
      <Button
        primary={relativeTimeString === '1d'}
        onClick={() => {
          updateQuery('relativeTime', '1d')
        }}
      >
        {t('1_day')}
      </Button>
      <Button
        primary={relativeTimeString === '7d'}
        onClick={() => {
          updateQuery('relativeTime', '7d')
        }}
      >
        {t('7_days')}
      </Button>
      {reportType === 'presenceByFences' ? (
        <Button
          primary={relativeTimeString === '21d'}
          onClick={() => {
            updateQuery('relativeTime', '21d')
          }}
        >
          {t('21_days')}
        </Button>
      ) : (
        <Button
          primary={relativeTimeString === '30d'}
          onClick={() => {
            updateQuery('relativeTime', '30d')
          }}
        >
          {t('30_days')}
        </Button>
      )}
    </Segment>
  )

  useEffect(
    function fetchRulesOnLoad() {
      if (tenant) {
        dispatch(fetchRules())
      }
      return () => {
        dispatch(clearRules())
      }
    },
    [tenant, dispatch]
  )

  let isFirstRender = useRef(true)

  useEffect(() => {
    // Skip first render

    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    // check if got data and export it

    if (fetchSuccess) {
      if (reportViewForm) {
        dispatch({
          type: 'SELECT_DATE_REPORTS_CLOSE',
        })
      } else {
        exportReport(reportData, `Relatório_${t(reportType)}_${m().format('YYYYMMDD')}`, t)
        // try to clear the flags and not FREAKING REDOWNLOAD THE REPORT
        dispatch({
          type: 'FETCH_REPORT_CLEAR_STATE',
        })
      }
    }
  }, [fetchSuccess, reportData, reportType, reportViewForm, t, dispatch])

  return (
    <ModalTemplate
      open={open}
      onClose={handleClose}
      header={<span>{t('selectDate_header')}</span>}
      loading={loadingRequest}
      saveContent={reportViewForm ? t('generateReport') : t('Download')}
      onSave={handleDate}
      cancelContent={t('Cancel')}
      onCancel={handleCancel}
    >
      {reportType === 'mobReportBuracometro' ? (
        <h3>Relatório sem filtro disponível no momento</h3>
      ) : (
        <>
          <Form>
            {shouldRenderFilter(reportType, ['presenceByFences', 'presenceByFencesGrouped']) && (
              <AssetsPropsFields
                selectedAssets={assetId}
                selectedProfiles={profileId}
                setSelectedAssets={setAssetId}
                setSelectedProfiles={setProfileId}
                multiple={false}
              />
            )}
            {shouldRenderFilter(reportType, ['presenceByFences', 'presenceByFencesGrouped']) && (
              <EntityDropdownField
                label={t('select_date_modal_nearMisses_perimeter_placeholder')}
                entity="fences"
                module
                value={perimeterTypeID}
                name="fences"
                placeholder="Selecione o(s) Perímetro(s)"
                onChange={(value: any) => {
                  if (typeof value !== 'undefined') {
                    updateQuery('fenceId', value.toString())
                  }
                }}
              />
            )}
            {shouldRenderFilter(reportType, ['firefightersByTime']) && (
              <Form.Field>
                <label>{t('select_date_modal_person_type_placeholder')}</label>
                <Dropdown
                  search
                  selection
                  options={[
                    ...peopleTypesAllIds
                      .filter((tp) => peopleTypesById[tp]?.type === 'people')
                      .map((e) => ({
                        key: peopleTypesById[e]?.id?.toString(),
                        text: peopleTypesById[e]?.name,
                        value: peopleTypesById[e]?.id?.toString(),
                      }))
                      .sort(sortTextByNames),
                  ]}
                  onChange={handlePeopleTypeIDChange}
                />
              </Form.Field>
            )}
            {shouldRenderFilter(reportType, ['peopleTypeByTime']) && (
              <Form.Field>
                <label>{t('select_date_modal_person_type_placeholder')}</label>
                <Dropdown
                  search
                  selection
                  options={[
                    ...peopleTypesAllIds
                      .filter((tp) => peopleTypesById[tp]?.type === 'people')
                      .map((e) => ({
                        key: peopleTypesById[e]?.id?.toString(),
                        text: peopleTypesById[e]?.name,
                        value: peopleTypesById[e]?.id?.toString(),
                      }))
                      .sort(sortTextByNames),
                  ]}
                  onChange={handlePeopleTypeIDChange}
                />
              </Form.Field>
            )}
            {shouldRenderFilter(reportType, ['rulesTypeByTime']) && (
              <Form.Field>
                <label>{t('select_date_modal_rule_type_placeholder')}</label>
                <Dropdown
                  search
                  selection
                  options={[
                    ...rulesTypesAllIds
                      .map((e) => ({
                        key: rulesTypesById[e]?.id?.toString(),
                        text: rulesTypesById[e]?.name,
                        value: rulesTypesById[e]?.id?.toString(),
                      }))
                      .sort(sortTextByNames),
                  ]}
                  onChange={handleRulesTypeIDChange}
                />
              </Form.Field>
            )}
            {shouldRenderFilter(reportType, ['referencesByTime']) && (
              <Form.Field>
                <label>{t('Assignment')}</label>
                <Dropdown
                  search
                  selection
                  clearable
                  options={[
                    ...peopleTypesAllIds
                      .filter((tp) => peopleTypesById[tp]?.type === 'refs')
                      .map((e) => ({
                        key: peopleTypesById[e]?.id?.toString(),
                        text: peopleTypesById[e]?.name,
                        value: peopleTypesById[e]?.id?.toString(),
                      }))
                      .sort(sortTextByNames),
                  ]}
                  onChange={handleRefTypeIDChange}
                />
              </Form.Field>
            )}
            {shouldRenderFilter(reportType, [
              'referencesByTime',
              'notificationsByTime',
              'accidentsByTime',
              'nearMissesByTime',
              'interditionsByTime',
              'lifeViolationsByTime',
            ]) && (
              <Form.Field>
                <label>{t('select_date_modal_nearMisses_perimeter_placeholder')}</label>
                <Dropdown
                  search
                  selection
                  clearable
                  options={[
                    ...perimetersTypesAllIds
                      .map((e) => ({
                        key: perimetersTypesById[e]?.id?.toString(),
                        text: perimetersTypesById[e]?.label,
                        value: perimetersTypesById[e]?.id?.toString(),
                      }))
                      .sort(sortTextByNames),
                  ]}
                  value={perimeterTypeID}
                  onChange={handlePerimeterTypeIDChange}
                />
              </Form.Field>
            )}

            <br />
            <Divider />
            <br />

            <Button.Group>
              <Button
                primary={relativeContent}
                onClick={() => {
                  updateQuery('mode', 'relative')
                }}
              >
                {t('selectDate_relativeTime')}
              </Button>
              <Button
                primary={!relativeContent}
                onClick={() => {
                  updateQuery('mode', 'absolute')
                }}
              >
                {t('selectDate_nonRelativeTime')}
              </Button>
            </Button.Group>
          </Form>
          <br />

          {relativeContent ? relativeTimeContent : nonRelativeTimeContent}
        </>
      )}
    </ModalTemplate>
  )
}

export default SelectDateModal

const sortTextByNames = (a: DropdownItemProps, b: DropdownItemProps) => {
  if (a.text && b.text) {
    if (typeof a.text !== 'string' || typeof b.text !== 'string') {
      console.error('sortTextByNames: a.text or b.text is not a string')
      return 1
    }
    return a.text.toLowerCase().localeCompare(b.text.toLowerCase())
  }
  return 1
}

const shouldRenderFilter = (filter: string, filters: string[]) => {
  if (filters.length === 0) {
    return true
  }
  return filters.includes(filter)
}
