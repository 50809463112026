/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://tpgezrrq3na6pj5ck7lzs262nu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ucvzvxcn2jhgxnzjtdpu6zwxc4",
    "aws_cognito_identity_pool_id": "us-east-2:def93d2f-f7ef-4663-93c3-b70b950bb807",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_zAr6AVicE",
    "aws_user_pools_web_client_id": "2jtph4jpbcghhm5bi1hp1a2lbn",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplify-storage151741-phygitall",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
