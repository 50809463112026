import { RootStore } from 'Store/initialStore'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import moment from 'moment'
import React, { useMemo } from 'react'
// import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ReferenceLine,
  LabelList,
  //   LabelList,
} from 'recharts'
import { Label } from 'semantic-ui-react'
// import { lightColors } from 'Utils/colorsFormat'

interface YamazumiBarChartProps {
  data: any[]
  driver: boolean
}

function TurnLabel() {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3px', marginLeft: '55px' }}
    >
      <Label>{'Ciclos'}</Label>
    </div>
  )
}
const valueAccessor =
  (attribute: string) =>
  ({ payload }: { payload: { attribute: string } }) => {
    return payload[attribute] || ''
  }

const DriverBarChart: React.FC<YamazumiBarChartProps> = ({ data, driver = false }) => {
  const lastActivityDataLabeling = useObjSelector((state: RootStore) => state.dataLabelings.lastActivityDataLabeling)

  const formatData = useMemo(
    () =>
      data?.map((e, i) => {
        let dessaturation = (lastActivityDataLabeling?.targetCycleTime || 65) - e.duration
        dessaturation = dessaturation < 0 ? 0 : dessaturation
        return {
          ...e,
          index: i + 1,
          dessaturation,
        }
      }),
    [data, lastActivityDataLabeling]
  )

  const selectedChart = useSelector((state: RootStore) => state.dashboardStellantis.charts.selected)

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={formatData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="minStartTime"
          tickFormatter={(value) => {
            return moment(value).format('DD/MM HH:mm:ss')
          }}
        />
        <YAxis unit="s" />
        <Tooltip
          formatter={(value, name) => {
            const res = `${value}s`
            return [res, name]
          }}
          labelFormatter={(_, props) => {
            const time = moment(props[0]?.payload?.minStartTime).format('DD/MM HH:mm:ss')
            const res = `Ciclo ${props[0]?.payload?.index} - ${time} - ${props[0]?.payload?.vehicle}`
            return [res]
          }}
        />
        <Legend content={TurnLabel} />

        {!driver && selectedChart === 'ciclo' ? (
          <>
            <Bar dataKey="phase#1" stackId={'cycle'} fill="#B0DCF2">
              <LabelList valueAccessor={valueAccessor('phase#1')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#2" stackId={'cycle'} fill="#8DD994">
              <LabelList valueAccessor={valueAccessor('phase#2')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#3" stackId={'cycle'} fill="#F4B9B9">
              <LabelList valueAccessor={valueAccessor('phase#3')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#4" stackId={'cycle'} fill="#FFC18F">
              <LabelList valueAccessor={valueAccessor('phase#4')} style={{ backgroundColor: 'white' }} />
            </Bar>
            <Bar dataKey="phase#5" stackId={'cycle'} fill="#FFDA8F">
              <LabelList valueAccessor={valueAccessor('phase#5')} style={{ backgroundColor: 'white' }} />
            </Bar>
          </>
        ) : (
          <>
            <Bar dataKey="duration" stackId={'cycle'} fill="rgb(102, 175, 233)">
              <LabelList valueAccessor={valueAccessor('duration')} style={{ backgroundColor: 'black' }} />
            </Bar>
            <Bar dataKey="dessaturation" stackId={'cycle'} fill="rgb(196, 2, 51)">
              {/* <LabelList
                valueAccessor={valueAccessor('dessaturation')}
                style={{
                  backgroundColor: 'red',
                  fontWeight: 'bold',
                  // color: 'red !important',
                  // fontSize: '24px',
                  textTransform: 'uppercase',
                  textShadow: '3px 3px 0px rgba(0,0,0,0.2)',
                }}
              /> */}
            </Bar>
          </>
        )}

        <ReferenceLine y={lastActivityDataLabeling?.taktTime || 77} label="TT" stroke="red" />
        <ReferenceLine
          y={lastActivityDataLabeling?.targetCycleTime || 65}
          label="TCT"
          stroke="blue"
          strokeDasharray="3 3"
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default DriverBarChart
