/*  
  Author: Luís Mourão / Felipe Pegado
  Description: Dropdown de Notificações			 
  To do list:
    - Separar em componentes menores onde cada item do Dropdown é um componente isolado
*/
/*global IntersectionObserver*/

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Dropdown, Popup, Icon, Grid } from 'semantic-ui-react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Loading from 'Utils/components/Loading'
import styled from 'styled-components'
import { fetchNotifications } from 'Store/actions/notification-action'

import { toggle, setFalse } from 'Store/actions/toggle-action'
import DropdownNotificationItem from './DropdownNotificationItem'

const DropdownNotification = () => {
  const { t } = useTranslation()

  const isFetching = useSelector((state) => state.notification.isFetching)
  const pageFetching = useSelector((state) => state.notification.pageFetching)
  const initialFetch = useSelector((state) => state.notification.initialFetch)
  const allNotifications = useSelector((state) => state.notification.allIds || [], shallowEqual)
  const showDropdownNotification = useSelector((state) => state.toggle.showDropdownNotification)
  //const activeColor = 'red'

  const dispatch = useDispatch()

  const [page, setPage] = useState(0)
  const [visible, setVisible] = useState(false)

  const loader = useRef(null)

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0]
      if (target.isIntersecting) {
        setPage((prev) => {
          dispatch(fetchNotifications())
          return prev + 1
        })
      }
    },
    [dispatch]
  )

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    }
    const observer = new IntersectionObserver(handleObserver, option)
    if (loader.current) observer.observe(loader.current)
  }, [handleObserver])

  useEffect(() => {
    if (initialFetch && visible) {
      // dispatch(fetchMessages())
      dispatch(fetchNotifications())
    }
  }, [initialFetch, visible, dispatch])

  return (
    // <Dropdown
    //   open={showDropdownNotification}
    //   closeOnChange={false}
    //   closeOnBlur={false}
    //   trigger={
    //     <Popup
    //       trigger={
    //         <Icon
    //           onClick={() => dispatch(toggle('showDropdownNotification'))}
    //           name="alarm"
    //           size="large"
    //           style={{ color: '#4b72b2' }}
    //         />
    //       }
    //       content={t('NavButtonsNotification')}
    //       position="bottom center"
    //       size="tiny"
    //       inverted
    //     />
    //   }
    //   pointing="top right"
    //   icon={
    //     /*
    //     allNotifications.length
    //       ? {
    //           children: (
    //             <Label
    //               circular
    //               size="mini"
    //               color={activeColor}
    //               style={{ marginTop: '-10px', float: 'left', marginLeft: '-10px' }}
    //             >
    //               {allActiveNotifications.length}
    //             </Label>
    //           ),
    //         }
    //       : */ null
    //   }
    //   onOpen={() => setVisible(true)}
    // ></Dropdown>

    <Dropdown
      onBlur={() => dispatch(setFalse('showDropdownNotification'))}
      open={showDropdownNotification}
      // closeOnChange={true}
      // closeOnBlur={true}
      trigger={
        <Popup
          trigger={
            <Icon
              onClick={() => dispatch(toggle('showDropdownNotification'))}
              name="alarm"
              size="large"
              style={{ color: '#4b72b2' }}
            />
          }
          content={t('NavButtonsNotification')}
          position="bottom center"
          size="tiny"
          inverted
        />
      }
      pointing="top right"
      icon={
        //   // {
        //   //   children: (
        //   //     <Label circular size="mini" color="green" floating>
        //   //       1
        //   //     </Label>
        //   //   ),
        //   // }
        //   /*
        //   allNotifications.length
        //     ? {
        //         children: (
        //           <Label
        //             circular
        //             size="mini"
        //             color={activeColor}
        //             style={{ marginTop: '-10px', float: 'left', marginLeft: '-10px' }}
        //           >
        //             {allActiveNotifications.length}
        //           </Label>
        //         ),
        //       }
        //     : */
        null
      }
      onOpen={() => setVisible(true)}
    >
      <DropdownMenu>
        {isFetching ? (
          <Container padded>
            <Loading />
          </Container>
        ) : allNotifications.length ? (
          allNotifications.map((id) => {
            return <DropdownNotificationItem notificationId={id} key={id} />
          })
        ) : (
          <ImgContainer>
            <img
              src="https://cdn.dribbble.com/users/721524/screenshots/4102371/no_notifisations.png"
              height="100%"
              width="100%"
              alt=""
            />
            <Text>{t('NoNotification')}</Text>
          </ImgContainer>
        )}
        <ContainerPageFetching page={page}>{pageFetching && <Loading size="small" />}</ContainerPageFetching>
        <div ref={loader} style={{ height: '2px' }} />
      </DropdownMenu>
    </Dropdown>
  )
}

const Container = styled(Grid)`
  width: 500px;
  height: 50px;
  padding-top: 8px !important;
`

const ContainerPageFetching = styled(Grid)`
  height: 50px;
  padding-top: 16px !important;
`

const DropdownMenu = styled(Dropdown.Menu)`
  background-color: orange;
  overflow: auto;
  max-height: 89vh;
  max-width: 650px;
  min-width: 450px !important;
  top: 24px !important;
  overflow-x: hidden;
`
const Text = styled.a`
  color: black;
  text-weight: bold;
`

const ImgContainer = styled(Grid)`
  height: 300px;
  width: 400px;
`

export default DropdownNotification
