import React, { FC, useState, CSSProperties, useEffect } from 'react'
import { Loader, Image, ImageProps } from 'semantic-ui-react'

type ImageWithLoaderProps = ImageProps & {
  fallbackSrc?: string
  loadingUrl?: boolean
  loaderStyle?: CSSProperties
  imageStyle?: CSSProperties
}
const ImageWithLoader: FC<ImageWithLoaderProps> = ({
  src,
  fallbackSrc = 'https://react.semantic-ui.com/images/wireframe/square-image.png',
  size = 'mini',
  avatar = false,
  loadingUrl = false,
  loaderStyle = { fontSize: 'small' },
  imageStyle = {},
  alt = 'Image',
  ...rest
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false)
  const [currentSrc, setCurrentSrc] = useState<string>(src)

  useEffect(() => {
    if (src) {
      setCurrentSrc(src)
    } else {
      setCurrentSrc(fallbackSrc)
    }
  }, [src, fallbackSrc])

  /**
   * Handles the image load event, hiding the loader.
   */
  const handleImageLoad = () => {
    setIsImageLoaded(true)
  }

  /**
   * Handles the image error event, setting the source to the fallback image.
   */
  const handleImageError = () => {
    if (currentSrc !== fallbackSrc) {
      setCurrentSrc(fallbackSrc)
      setIsImageLoaded(true)
    } else {
      // Optionally handle fallback image load failure
      setIsImageLoaded(true)
    }
  }

  return (
    <>
      {!isImageLoaded && <Loader active inline style={{ ...loaderStyle, marginLeft: '5px' }} />}
      {!loadingUrl && (
        <Image
          avatar={avatar}
          src={currentSrc}
          size={size}
          onError={handleImageError}
          onLoad={handleImageLoad}
          style={{
            display: isImageLoaded ? undefined : 'none',
            cursor: 'pointer',
            ...imageStyle,
          }}
          {...rest}
        />
      )}
    </>
  )
}

export default ImageWithLoader
