import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, /*Icon,*/ Grid } from 'semantic-ui-react'
import styled from 'styled-components'

export const AssetItem = ({ clickItem, assetDetails, assetId, iconItem }) => {
  const name = assetDetails?.name
  return (
    <Dropdown.Item value={assetId} key={assetId}>
      <DropdownGridItem key={assetId} id="DropdownGridItem">
        <DropdownGridName width={14} onClick={() => clickItem(assetId)} title={name} key={assetId}>
          {iconItem ? (
            <>
              {iconItem} {name}
            </>
          ) : (
            name
          )}
        </DropdownGridName>
      </DropdownGridItem>
    </Dropdown.Item>
  )
}

AssetItem.propTypes = {
  clickItem: PropTypes.func.isRequired,
  assetName: PropTypes.string,
  assetId: PropTypes.string.isRequired,
}

const DropdownGridItem = styled(Grid)`
  width: 425px;
  border-radius: 5px;
`

export const DropdownGridName = styled(Grid.Column)`
  padding: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 14px;
  padding-right: 0;
`
