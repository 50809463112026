import pt from 'date-fns/locale/pt'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import cn from 'date-fns/locale/zh-TW'
//console.log('Json do pt = ',pt)
pt.options.weekStartsOn = 0
const languages = { pt: pt, en: en, es: es, fr: fr, zh: cn }

export default languages
