import { FILTER_MARKERS, FILTER_TIME } from 'Store/actions/filters-action'
import moment from 'moment'

export default function filtersReducer(
  state = {
    searchValue: '',
    searchField: '',
    searchText: '',
    timeslider: {
      min: 0,
      max: 0,
      total: 1,
    },
    search: [],
  },
  { type, payload, assets }
) {
  switch (type) {
    case 'FETCH_ASSETS_LOCATION_SUCCESS':
      if (state.result.length === 0) {
        let result
        const filteredPayload = payload.filter((eachMeasure) => assets[eachMeasure.assetId])
        result = filteredPayload.map((eachResult) => ({ id: eachResult.assetId }))
        return { ...state, result }
      } else return state

    case FILTER_MARKERS:
      return {
        ...state,
        searchValue: payload.value,
        searchField: payload.field ? payload.field : '',
      }
    case FILTER_TIME:
      const start = moment(payload.start).format()
      const end = moment(payload.end).format()
      return {
        ...state,
        ...{ timeEnd: moment(end)?.toISOString(), timeStart: moment(start)?.toISOString() },
      }
    default:
      return state
    case 'SET_SEARCH':
      const search = [{ id: payload.key, type: payload.type, bounds: payload.bounds }]
      return { ...state, search }
    case 'SET_FILTER':
      return { ...state, ...payload }
    // case 'ADD_NOTIFICATIONNew':
    //   return { ...state, searchText: '', searchValue: '' }
  }
}
