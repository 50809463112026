import { TOUCH_NOTIFICATION, FETCH_NOTIFICATION_COUNT } from '../actions/notification-action'
import { union } from 'lodash'
import initialStore from 'Store/initialStore'
import { toast } from 'react-toastify'
import ToastItem from 'Utils/notifications/components/ToastItem.js'
import React from 'react'
import moment from 'moment'
import produce from 'immer'
import formatSound, { convertScreenTimeToNumber } from 'Utils/formatSound'
import _ from 'lodash'
import { notifyError } from 'Utils/components/SystemToasts'
import { setStoreProps } from './reducerUtils'

const initialState = initialStore.notificationProfiles

export default function notificationReducer(state = initialState, { type, payload, login }) {
  switch (type) {
    case 'SET_NOTIFICATION_PROPS':
      return setStoreProps(state, payload)
    case 'FETCH_NOTIFICATION_START':
      return {
        ...state,
        isFetching: payload === 0 ? true : false,
        pageFetching: payload > 0 ? true : false,
        currentPage: payload,
      }

    case 'FETCH_NOTIFICATION_SUCCESS':
      return produce(state, (newState) => {
        newState.byId = {
          ...newState.byId,
          ...payload.items.reduce(
            (res, notification) => ({
              ...res,
              [notification.id]: notification,
            }),
            {}
          ),
        }

        newState.allIds = union(
          newState.allIds,
          payload.items.map((notification) => notification.id)
        )

        newState.emergencyNotificationByFenceId = newState.allIds.reduce((agg, currentId) => {
          const current = newState.byId[currentId]
          if (
            !current.closedAt &&
            current.location?.[0] &&
            ['evacuation', 'seek_shelter', 'emergency'].includes(current.type) &&
            moment().diff(moment(current.time), 'days') < 2
          ) {
            return {
              ...agg,
              [current.location[0]]: { ...agg[current.location[0]], [current.type]: current },
            }
          }
          return agg
        }, {})

        const lastNotificationIdWithSound = newState.allIds.find((notificationId) => {
          const notification = newState.byId[notificationId]
          if (
            ['help', 'seek_shelter', 'emergency', 'evacuation'].includes(notification.type) &&
            moment().diff(moment(notification.time), 'days') < 2 &&
            !notification?.closedAt &&
            notification.profile?.sound &&
            notification.profile?.sound !== 'none' &&
            notification.profile?.screenTime !== 'noScreen'
          ) {
            return true
          }
          return false
        })

        if (lastNotificationIdWithSound) {
          const notificationWithSound = newState.byId[lastNotificationIdWithSound]
          newState.sound = formatSound(notificationWithSound.profile)
        }

        newState.assetsInEmergency = newState.allIds.reduce((prev, id) => {
          const notification = newState.byId[id]
          if (notification?.type === 'help' && !notification?.closedAt) {
            const assetId = notification?.assetId
            return prev.includes(assetId) ? prev : [...prev, assetId]
          }
          return prev
        }, [])

        newState.isFetching = false
        newState.pageFetching = false
        newState.initialFetch = false
        newState.allActiveNotifications = newState.allIds.filter((id) => !newState.byId[id]?.closedAt)
        newState.nextToken = payload.nextToken
        newState.onGoingEmergency = newState.allIds.some(
          (id) => newState.byId[id].type === 'help' && !newState.byId[id].closedAt
        )
      })

    case 'FETCH_NOTIFICATION_FAILURE':
      return { ...state, success: false, isFetching: false }

    case TOUCH_NOTIFICATION:
      const notification = state.byId[payload.msgId]
      const readArray = notification.readBy.includes(payload.email)
        ? notification.readBy
        : [...notification.readBy, payload.email]
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.msgId]: {
            ...notification,
            readBy: readArray,
          },
        },
      }
    case FETCH_NOTIFICATION_COUNT:
      return { ...state, ...payload }
    case 'CHANGE_NOTIFICATION_PAGE':
      return { ...state, currentPage: payload }

    case 'NEW_NOTIFICATION': {
      return produce(state, (newState) => {
        const profile = _.defaults(payload.profile, {
          screenTime: '_5s',
          icon: 'warning',
          txtColor: 'black',
        })

        if (profile.screenTime !== 'noScreen' && !payload.closedAt) {
          const { bgColor, screenTime } = profile
          const options = {
            autoClose: screenTime === 'permanent' ? false : convertScreenTimeToNumber(screenTime) * 1000,
            icon: false,
          }
          const toastMap = {
            blue: toast.info,
            green: toast.success,
            yellow: toast.warning,
            default: toast.error,
          }

          if (payload.type !== 'help' || !payload.confirmedAt) {
            ;(toastMap[bgColor] || toastMap.default)(<ToastItem profile={profile} payload={payload} />, options)
          }

          if (
            !payload.confirmedAt &&
            !payload.closedAt &&
            payload.profile?.sound &&
            payload.profile?.sound !== 'none' &&
            payload.profile?.screenTime !== 'noScreen'
          ) {
            newState.sound = formatSound(profile)
          }
        }

        newState.byId = { ...state.byId, [payload.id]: payload }
        newState.allIds = _.chain(newState.byId).values().orderBy(['time'], ['desc']).map('id').value()

        newState.assetsInEmergency = _.chain(newState.byId)
          .values()
          .filter((notification) => notification.type === 'help' && !notification.closedAt)
          .map('assetId')
          .uniq()
          .value()

        if (!newState.assetsInEmergency.length && state.assetsInEmergency.length) {
          newState.sound.playing = false
        }

        const locationKey = _.first(payload.location)
        newState.emergencyNotificationByFenceId = locationKey
          ? {
              ...state.emergencyNotificationByFenceId,
              [locationKey]: {
                ...state.emergencyNotificationByFenceId[locationKey],
                [payload.type]:
                  !payload.closedAt && _.includes(['evacuation', 'seek_shelter', 'emergency'], payload.type)
                    ? payload
                    : null,
              },
            }
          : state.emergencyNotificationByFenceId

        if (
          ['seek_shelter', 'emergency', 'evacuation'].includes(payload.type) &&
          _.every(newState.emergencyNotificationByFenceId, (byFence) => _.every(byFence, _.isNil))
        ) {
          newState.sound.playing = false
        }

        newState.onGoingEmergency = payload.type === 'help' && !payload.closedAt
      })
    }

    case 'NOTIFICATION_FEEDBACK_START': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], loadingState: true },
        },
      }
    }

    case 'NOTIFICATION_FEEDBACK_ERROR':
      notifyError({
        message: 'Não foi possível enviar a confirmação, tente novamente mais tarde',
        title: 'Erro ao enviar confirmação',
      })
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], loadingState: false },
        },
      }

    case 'MUTATE_NOTIFICATION_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...payload, loadingState: false },
        },
      }
    }

    case 'EMERGENCY_STOP':
      return {
        ...state,
        onGoingEmergency: false,
        sound: { playing: false },
      }

    case 'SOUND_STOP':
      return {
        ...state,
        sound: { playing: false },
      }

    case 'EMERGENCY_START': {
      return {
        ...state,
        onGoingEmergency: true,
      }
    }

    case 'SET_EMERGENCY_BOUNDS':
      return {
        ...state,
        onGoingEmergency: !state.onGoingEmergency,
      }

    case 'NOTIFICATION_BROADCAST_START':
      return {
        ...state,
        emergencyLoading: true,
      }

    case 'NOTIFICATION_BROADCAST_SUCCESS':
      return {
        ...state,
        emergencyLoading: false,
      }

    case 'NOTIFICATION_BROADCAST_ERROR':
      return {
        ...state,
        emergencyLoading: false,
      }

    case 'SET_CURRENT_NOTIFICATION':
      return {
        ...state,
        current: state.byId[payload.id],
      }
    default:
      return state
  }
}
/*** Selectors ***/

export const getNewNotifications = (state) => {
  const { newIds, byId } = state
  return newIds.sort((a, b) => b - a).map((id) => byId[id])
}

export const getIsFetchingNotifications = (state) => (state.isFetching ? state.isFetching : false)
export const getNextPage = (state) => state.pagination.nextPage
export const getTotalPages = (state) => state.pagination.totalPages
export const getCurrentPage = (state) => state.pagination.currentPage
