import { PhaseLabel } from 'API'

/*
case 1: No label with cycle. Calculate all cycles
case 2: New label. Calculate just one label cycle
case 3: Change cycle by table. Nothing to do
*/

const calcDataLabelsCycles = (labels: PhaseLabel[]) => {
  const sortedLabels = [...labels].sort((a, b) => a.startTime.localeCompare(b.startTime))

  let prevItem: PhaseLabel
  let prevCyclePhases: PhaseLabel['phaseIndex'][] = []

  const newLabels = sortedLabels?.map((item, index) => {
    if (index > 0 && (prevCyclePhases.includes(item.phaseIndex) || item.vehicle !== prevItem.vehicle)) {
      const res = { ...item, cycle: (prevItem?.cycle || 0) + 1 }
      prevCyclePhases = [item.phaseIndex]
      prevItem = res
      return res
    }

    const res = { ...item, cycle: prevItem?.cycle || 1 }
    prevItem = res
    prevCyclePhases = [...prevCyclePhases, item.phaseIndex]
    return res
  })

  return newLabels
}

export default calcDataLabelsCycles
