import React, { useEffect } from 'react'
import { Form, Input, Label, Dropdown, DropdownItemProps } from 'semantic-ui-react'
import FormGroup from 'Components/forms/FormGroup'
import { useTranslation } from 'react-i18next'
import { URL_BASE } from 'Apis/rest'
import axios from 'axios'

type FormRequest = {
  path: string
  resultPath: string
}

type FormFields = {
  name: string
  label: string
  placeholder: string
  required: boolean
  type: string
  options?: any[]
  request?: FormRequest
}

interface FormContainerProps {
  fields: FormFields[]
  formStates: { [key: string]: any }
  formErrors: any
  onErrors: (errors: any) => void
  handleChange: (event: any, data: any) => void
  schema: any
  request?: FormRequest
}
interface FormInputProps {
  type: string
  placeholder: string
  name: string
  value: any
  onChange: (event: any, data: any) => void
  options?: DropdownItemProps[]
  request?: FormRequest
}

interface FormFieldProps {
  field: FormFields
  formStates: { [key: string]: any }
  formErrors: any
  handleChange: any
}

interface DynamicDropdownProps {
  placeholder: string
  name: string
  value: string
  onChange: (event: any, data: any) => void
  options: DropdownItemProps[]
  request?: FormRequest
}

const DynamicDropdown = ({ placeholder, name, value, onChange, options, request }: DynamicDropdownProps) => {
  const [dynamicOptions, setDynamicOptions] = React.useState<DropdownItemProps[]>(options)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const { path, resultPath }: FormRequest = request || { path: '', resultPath: '' }
  const { t } = useTranslation()

  useEffect(() => {
    const fetchOptions = async () => {
      const token = localStorage.getItem('id_token')
      setLoading(true)
      const { data } = await axios.get(URL_BASE + path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          query: searchQuery,
        },
      })
      setLoading(false)
      setDynamicOptions(
        data[resultPath].map((item: any) => ({
          key: item.id,
          text: item.name || item.label,
          value: item.id,
        }))
      )
    }
    if (searchQuery) fetchOptions()
  }, [path, resultPath, searchQuery])

  const handleSearchChange = (_e: any, data: any) => {
    const { searchQuery } = data
    setSearchQuery(searchQuery)
  }

  return (
    <Dropdown
      placeholder={placeholder}
      name={name}
      loading={loading}
      selection
      search
      onSearchChange={handleSearchChange}
      onChange={onChange}
      options={dynamicOptions}
      value={value}
      noResultsMessage={searchQuery ? t('common.noResults') : t('common.startTyping')}
    />
  )
}

const FormInput = ({ type, placeholder, name, value, onChange, options = [], request }: FormInputProps) => {
  if (type === 'list') {
    return (
      <DynamicDropdown
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        options={options}
        request={request}
        value={value}
      />
    )
  }
  return <Input placeholder={placeholder} name={name} value={value || ''} onChange={onChange} type={type} />
}

const FormField = ({ field, formStates, formErrors, handleChange }: FormFieldProps) => {
  const { t } = useTranslation()
  return (
    <Form.Field required error={!!formErrors?.[field.name]} key={field.name}>
      <label>{t(field.label)}</label>
      <FormInput
        type={field.type}
        placeholder={t(field.placeholder)}
        name={field.name}
        value={formStates?.[field.name]}
        onChange={handleChange}
        options={field.options}
        request={field.request}
      />
      {typeof formErrors?.[field.name]?._errors?.[0] === 'string' && (
        <Label color="red" pointing>
          {t(formErrors?.[field.name]?._errors?.[0])}
        </Label>
      )}
    </Form.Field>
  )
}

const FormContainer = ({ fields, formStates, formErrors, onErrors, handleChange, schema }: FormContainerProps) => {
  useEffect(() => {
    const result = schema.safeParse(formStates)
    if (!result.success) {
      const { _errors, ...errors } = result.error.format()
      if (onErrors) onErrors(errors)
    } else {
      if (onErrors) onErrors({})
    }
  }, [formStates, schema, onErrors])

  return (
    <FormGroup widths="equal">
      {fields.map((field) => (
        <FormField
          field={field}
          formStates={formStates}
          formErrors={formErrors}
          handleChange={handleChange}
          key={field.name}
        />
      ))}
    </FormGroup>
  )
}

export default FormContainer
