import { Profile } from 'API'

export const parseProfile = (payload: Profile) => {
  return {
    ...payload,
    properties: typeof payload.properties === 'string' ? JSON.parse(payload.properties) : payload.properties,
  }
}

export default parseProfile
