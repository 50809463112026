import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'semantic-ui-react'

const shouldShow = new RegExp(/(AFT).*?(AmazonWebAppPlatform)/).test(window.navigator?.userAgent)

export const SmartTVInteraction = () => {
  const [open, setOpen] = useState(true)
  const { t } = useTranslation()
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  if (!shouldShow) return null

  return (
    <Modal open={open} size="tiny">
      <Modal.Header>{t('tv_interaction_header')}</Modal.Header>

      <Modal.Content>
        <Modal.Description style={{ whiteSpace: 'pre-wrap' }}>{t('tv_interaction_description')}</Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button color="blue" onClick={handleClose} style={{ margin: 'auto' }}>
          {t('tv_interaction_action')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
