import React, { useMemo, useCallback } from 'react'
import { Grid } from 'semantic-ui-react'
import { semanticColors } from 'Utils/colorsFormat'

/*min-height: 20px;padding-left: 10px;padding-top: 2px;height: 20%;*/

const GraphKPI = ({ data = [], layoutStats, meta, kpiFormat, setGood, resource }) => {
  const total = (data, kpiFormat) => {
    switch (kpiFormat) {
      case 'tdays':
        return (data?.reduce((total, { value }) => parseFloat(value) + total, 0) / 86400)?.toFixed(1)
      case 'thours':
        return (data?.reduce((total, { value }) => parseFloat(value) + total, 0) / 3600)?.toFixed(1)
      case 'tminutes':
        return (data?.reduce((total, { value }) => parseFloat(value) + total, 0) / 60)?.toFixed(1)
      case 'ttime':
        return data?.reduce((total, { value }) => parseFloat(value) + total, 0)
      default:
        return data?.reduce((total, { value }) => parseFloat(value) + total, 0)
    }
  }

  const formatTime = (totalSeconds) => {
    let hours = Math.floor(totalSeconds / 3600)
      .toString()
      .padStart(2, '0')
    let minutes = Math.floor((totalSeconds / 60) % 60)
      .toString()
      .padStart(2, '0')
    let seconds = Math.floor(totalSeconds % 60)
      .toString()
      .padStart(2, '0')
    return hours + ':' + minutes + ':' + seconds
  }
  const value = parseFloat(meta?.val ? meta.val : 0)
  const positiveColor = semanticColors('green')
  const negativeColor = semanticColors('red')

  const checkMeta = useCallback(
    (total) => {
      switch (meta.comp) {
        case '=':
          setGood(total === value)
          return total === value ? positiveColor : negativeColor
        case '<>':
          setGood(total !== value)
          return total !== value ? positiveColor : negativeColor
        case '>=':
          setGood(total >= value)
          return total >= value ? positiveColor : negativeColor
        case '>':
          setGood(total > value)
          return total > value ? positiveColor : negativeColor
        case '<':
          setGood(total < value)
          return total < value ? positiveColor : negativeColor
        case '<=':
          setGood(total <= value)
          return total <= value ? positiveColor : negativeColor
        default:
          setGood(null)
          return '#666'
      }
    },
    [meta.comp, value, positiveColor, negativeColor, setGood]
  )

  const checkFormat = (n) => {
    switch (kpiFormat) {
      case 'tCelsius':
        return n?.toLocaleString('pt-br') + '°C'
      case 'tFahr':
        return n?.toLocaleString('pt-br') + '°F'
      case 'tKelvin':
        return n?.toLocaleString('pt-br') + 'K'
      case 'number':
        return n?.toLocaleString('pt-br')
      case 'percent':
        return n?.toLocaleString('pt-br') + '%'
      case 'ppm':
        return n?.toLocaleString('pt-br') + 'ppm'
      case 'coin':
        return 'R$ ' + n?.toFixed(2).toLocaleString('pt-br')
      case 'tdays':
        return n?.toLocaleString('pt-br') + 'd'
      case 'thours':
        return n?.toLocaleString('pt-br') + 'h'
      case 'tminutes':
        return n?.toLocaleString('pt-br') + 'min'
      case 'tseconds':
        return n?.toLocaleString('pt-br') + 's'
      case 'tension':
        return n?.toLocaleString('pt-br') + 'V'
      case 'current':
        return n?.toLocaleString('pt-br') + 'A'

      case 'ttime':
        return formatTime(n)?.toLocaleString('pt-br')
      default:
        return n?.toLocaleString('pt-br')
    }
  }

  const color = useMemo(() => {
    if (!meta) return '#666'

    let baseValue
    if (resource === '30261') {
      baseValue = data?.[0]?.value?.baseValue
      if (baseValue >= 0) {
        return checkMeta(baseValue / 60000)
      } else {
        setGood(null)
        return '#666'
      }
    } else {
      baseValue = total(data, kpiFormat)
      return checkMeta(baseValue)
    }
  }, [meta, resource, data, kpiFormat, checkMeta, setGood])

  return (
    <>
      <Grid style={{ height: '100%' }}>
        <Grid.Row
          stretched
          centered
          verticalAlign="middle"
          style={{
            height: '80%',
            fontWeight: '700',
            fontSize: `${30 + (layoutStats.h - 2) * 10}px`,
            paddingTop: `${meta ? 30 + (layoutStats.h - 2) * 10 : 45 + (layoutStats.h - 2) * 10}px`,
          }}
        >
          <span
            style={{
              color,
            }}
          >
            {resource === '30261' ? data?.[0]?.value?.displayValue ?? 'Sem Dados' : checkFormat(total(data, kpiFormat))}
          </span>
        </Grid.Row>
        {meta && (
          <Grid.Row style={{ height: '20%', paddingLeft: '12px', paddingTop: '2px', minHeight: '20px', color: '#666' }}>
            <span style={{ fontWeight: '700', marginRight: '5px' }}>Meta ({meta.comp}):</span>
            {checkFormat(parseFloat(meta.val))}
          </Grid.Row>
        )}
      </Grid>
    </>
  )
}

export default GraphKPI
