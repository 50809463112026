let newState

export default function reftypesReducer(state = { byId: {}, current: {}, allIds: [] }, { type, id, payload }) {
  switch (type) {
    case 'reftypesInitialStore':
      if (payload === null) {
        console.error('Error in reftypes initial store')
        return state
      }
      if (!payload[0]) {
        console.warn('No reftypes in store!')
        return state
      }
      if (!payload[0].reftypes) {
        console.warn("reftype's store empty!")
        return state
      }
      return payload[0].reftypes

    case 'reftypeNew':
      newState = Object.values(state)
      newState.unshift(payload)
      return { ...newState }

    case 'updatereftypeNew':
      newState = Object.assign({}, state)
      Object.entries(newState).forEach((data) => {
        if (payload.id === data[1].id) {
          newState[data[0]].name = payload.name
          newState[data[0]].info = payload.info
          return newState
        }
      })
      return Object.assign({}, state, newState)

    case 'updatedeletereftypeNew':
      newState = Object.assign({}, state)
      Object.entries(newState).forEach((data) => {
        if (payload.id === data[1].id) delete newState[data[0]]
      })
      return Object.assign({}, newState)
    default:
      return state
  }
}
