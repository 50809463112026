import React, { useCallback } from 'react'
import StepForm from '../StepForm'

import useFetchData from 'Utils/hooks/useFetchData'
import { useDispatch } from 'react-redux'
import {
  clearDataLabelingForm,
  mutateDataLabelingPhase,
  removeDataLabelingPhase,
  saveDataLabelings,
  setDataLabelingsProps,
} from 'Store/actions/dataLabelings-actions'
import steps from './schemas/steps'
import DataLabelingsIdForm from './DataLabelingsIdForm'
import DataLabelingsTimeForm from './DataLabelingsTimeForm'
import DataLabelingsAssetsForm from './DataLabelingsAssetsForm'
import DataLabelingsVideoUpload from './DataLabelingsVideoUpload'
import ActivitiesPhasesForm from '../activities/ActivitiesPhasesForm'

function DataLabelingForm() {
  const dispatch = useDispatch()
  useFetchData({ independentFetches: [{ fetchAction: 'activities' }] })

  return (
    <StepForm
      entity="dataLabelings"
      widths={5}
      schemas={steps}
      forms={[
        <DataLabelingsIdForm />,
        <DataLabelingsVideoUpload />,
        <DataLabelingsTimeForm />,
        <DataLabelingsAssetsForm />,
        <ActivitiesPhasesForm
          entity="dataLabelings"
          onRemovePhase={useCallback((index: number) => dispatch(removeDataLabelingPhase(index)), [dispatch])}
          onChangePhase={useCallback(
            ({ index, field, value }: Record<string, string | number>) =>
              dispatch(mutateDataLabelingPhase({ index, field, value })),
            [dispatch]
          )}
        />,
      ]}
      setProps={setDataLabelingsProps}
      values={[{ fields: ['name'] }, {}]}
      onSave={() => dispatch(saveDataLabelings({}))}
      onClose={() => dispatch(clearDataLabelingForm())}
    />
  )
}

export default DataLabelingForm
