import axios from 'axios'
import { URL_BASE } from 'Apis/rest'

export const upgradeSwk = (data: any) => {
  const jwt = localStorage.getItem('id_token')
  return axios.post(URL_BASE + '/labelings/upgrade-swk', data, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}
