import React from 'react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setAutoUpdate } from 'Store/actions/tables-action'
import { fetchAssets } from 'Store/actions/assets-action'
//import { List } from 'semantic-ui-react'

export const ConfirmReactTable = ({
  table,
  confirmOpen,
  setConfirmOpen,
  t,
  message,
  confirmAction,
  ids,
  labels,
  deleteAction,
  deleteGroupAction,
  setConfirmText,
  setConfirmAction,
  cudLoading,
  cudSuccess,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (cudSuccess) {
      // make sure to refetch data to display the newly created item
      dispatch(fetchAssets())
      if (table === 'assets') {
        dispatch({ type: 'CLEAR_ASSET_STATE' })
      }
      if (table === 'people') {
        dispatch({ type: 'CLEAR_PEOPLE_STATE' })
      }
      setConfirmOpen(false)
    }
    return () => {
      setConfirmText('')
      setConfirmAction('')
    }
  }, [cudSuccess, dispatch, setConfirmOpen, table, setConfirmText, setConfirmAction])

  const handleConfirm = () => {
    //We have to make it backwards compatible until loading is implemented on all tables
    if (!['people', 'assets'].includes(table)) {
      setConfirmOpen(false)
    }
    dispatch(setAutoUpdate(table, true))
    setConfirmText(t('loading'))
    switch (confirmAction) {
      case 'delete':
        deleteAction({ ids: ids })
        break
      case 'deleteGroup':
        deleteGroupAction({ ids: ids })
        break
      default:
        console.log('Action not defined: ', confirmAction)
    }
  }

  const handleCancel = () => {
    setConfirmText('')
    setConfirmAction('')
    setConfirmOpen(false)
  }

  return (
    <ModalTemplate
      onClose={() => setConfirmOpen(false)}
      open={confirmOpen}
      loading={cudLoading}
      size="large"
      header={confirmAction === 'delete' ? t('DeleteAction') : t('SaveAction')}
      saveContent={confirmAction === 'delete' ? 'Excluir' : 'Salvar'}
      saveColor={confirmAction === 'delete' ? 'red' : 'blue'}
      saveIcon={confirmAction === 'delete' ? 'trash' : 'check'}
      onSave={handleConfirm}
      onCancel={() => handleCancel(false)}
      contentPXMargin={30}
    >
      <p>{message}</p>
      <p>{labels.join(', ')}</p>
    </ModalTemplate>
  )
}

export default ConfirmReactTable
