import axios, { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { Content, IData } from './onboarding/VerificationContent'
import Base64 from 'crypto-js/enc-base64'
import AES from 'crypto-js/aes'
import ECB from 'crypto-js/mode-ecb'
import Utf8 from 'crypto-js/enc-utf8'

//type TParams = { data: string; key: string }
const URL = 'https://el82eh0qm2.execute-api.us-east-2.amazonaws.com/onboarding/guests/confirm-token'

export const OnboardingGuests = ({ location }: RouteComponentProps) => {
  const [success, setSuccess] = useState<boolean>()
  const [approved, setApproved] = useState<boolean>()
  const [isLoadingApprove, setLoadingApprove] = useState(false)
  const [isLoadingReject, setLoadingReject] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [data, setData] = useState<IData>()
  const [error, setError] = useState(false)

  useEffect(() => {
    const decryptData = () => {
      try {
        const params = new URLSearchParams(location.search)
        const data = params.get('data') || ''
        const key = Base64.parse(params.get('key') || '')
        const bytes = AES.decrypt(data, key, {
          mode: ECB,
        })
        const result = JSON.parse(bytes.toString(Utf8))
        setData({
          ...result,
          name: result.userName,
          email: result.userEmail,
          beginning: toDate(result.startDate),
          expiration: toDate(result.endDate),
        })
      } catch (err) {
        setError(true)
      }
    }
    decryptData()
  }, [location.search])

  const handleResponse = async (hasApproved: boolean) => {
    try {
      const params = new URLSearchParams(location.search)
      hasApproved ? setLoadingApprove(true) : setLoadingReject(true)
      setApproved(hasApproved)
      await axios.post(URL, {
        userName: data?.name,
        hasApproved,
        key: params.get('key'),
        expiration: data?.expiration,
        beginning: data?.beginning,
        tenantCode: data?.tenantCode,
      })
      setSuccess(true)
    } catch (err) {
      const errors = err as Error | AxiosError<string>
      setSuccess(false)
      hasApproved ? setLoadingApprove(false) : setLoadingReject(false)
      if (!axios.isAxiosError(errors)) {
        setErrorMsg(errors.message)
        return
      }
      // @ts-ignore
      setErrorMsg(errors?.response?.data || 'Erro desconhecido')
    }
  }
  return (
    <Grid textAlign="center" style={{ height: '100vh', background: '#f7f7f7' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 500 }}>
        <Content
          data={data}
          handleClick={handleResponse}
          success={success}
          error={error}
          isLoadingApprove={isLoadingApprove}
          isLoadingReject={isLoadingReject}
          approved={approved}
          errorMsg={errorMsg}
        />
      </Grid.Column>
    </Grid>
  )
}

function toDate(dateStr: string): Number {
  if (dateStr) {
    var parts = dateStr.split('/')
    return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0])).getTime() + 10800000
  } else {
    return 5000000000000
  }
}
