import axios from 'axios'
import { DocumentNode } from 'graphql'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'
import { URL_BASE } from './rest'
import awsmobile from 'aws-exports'
import { omitBy } from 'lodash'

interface Params {
  entity: string
  dispatch: Function
  input: any
  query: string | DocumentNode
  extraAction: Function
}

export const mutateEntity = async ({ entity, dispatch, input, query, extraAction }: Params) => {
  const entityType = convertString(entity)

  const sanitizedInput = omitBy(input, (value) => value === null || value === undefined || value === '')

  dispatch({ type: `SAVE_${entityType}_START`, input: sanitizedInput })

  try {
    const res = await axios.post(
      URL_BASE + '/modules',
      {
        entity,
        input: sanitizedInput,
        query,
      },
      {
        headers: {
          'x-api-key': awsmobile.aws_appsync_apiKey,
        },
      }
    )
    dispatch({
      type: `SAVE_${entityType}_SUCCESS`,
      payload: res?.data?.payload,
    })
    extraAction?.()
    notifySuccess()
  } catch (err) {
    console.error('Mutation error:', { err, input })
    notifyError()
    dispatch({
      type: `SAVE_${entityType}_ERROR`,
      payload: err,
    })
    return // Exit the function if the initial mutation fails
  }
}

function convertString(input: string): string {
  return input.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase()
}
