import React from 'react'
import { Popup } from 'react-leaflet'

const PopupFallback = () => (
  <Popup>
    <span>Ocorreu um erro! Estamos trabalhando para consertá-lo, por favor tente novamente mais tarde.</span>
  </Popup>
)

export default PopupFallback
