import EntityDropdownField from 'Components/forms/generic/EntityDropdownField'
import React from 'react'
import { Form } from 'semantic-ui-react'
import AssetPropFilters from './AssetPropFilters'
import useRuleFilters, { UseRuleFiltersOptions } from '../hooks/useRuleFilters'

type RuleFiltersType = UseRuleFiltersOptions & {
  /** Action to set a property's value */
  setProp: (field: string, value: any) => void
}

/**
 * ReportsStepTwoFormComponent - Form component for handling reports step two
 *
 * @param props Component properties
 * @returns JSX.Element
 */
const RuleFilters: React.FC<RuleFiltersType> = ({
  setProp,
  selectAssets,
  selectShowFeedback,
  selectRules,
  selectFences,
  selectErrors,
}) => {
  const { showFeedback, selectedAssets, selectedRules, selectedErrors, selectedFences } = useRuleFilters({
    selectAssets,
    selectShowFeedback,
    selectRules,
    selectFences,
    selectErrors,
  })

  return (
    <Form>
      <AssetPropFilters selectedAssets={selectedAssets} showFeedback={showFeedback} setProp={setProp} />
      <EntityDropdownField
        entity="rules"
        label="Regras"
        placeholder="Selecione as regras"
        value={selectedRules}
        onChange={(value: string) => setProp('wip.filters.selectedRules', value)}
        showFeedback={showFeedback}
        error={selectedErrors?.filters?.selectedRules}
        prefix="id"
        multiple
      />
      <EntityDropdownField
        entity="fences"
        label="Perímetros"
        name="filters.fences"
        placeholder="Selecione os perímetros"
        value={selectedFences}
        onChange={(value: string) => setProp('wip.filters.fences', value)}
        showFeedback={showFeedback}
        error={selectedErrors?.filters?.fences}
        prefix="id"
        module
        multiple
      />
    </Form>
  )
}

export default RuleFilters
