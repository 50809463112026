import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDashboardStellantis } from 'Store/actions/dashboardStellantis-action'
import { changeCurrentDashboard } from 'Store/actions/dashboards-action'
import ModalHeader from 'Utils/modals/ModalHeader'
import Loading from 'Utils/components/Loading'
import MainCards from 'Utils/reactTable/components/MainCards'
import MainStatistics from 'Utils/reactTable/components/MainStatistics'
//import Cards from 'Utils/reactTable/components/Cards.js'
import { Segment, Modal, Grid, Card } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useFetchData from 'Utils/hooks/useFetchData'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import {
  calcDataLabelingsMetrics,
  calcDataLabelingsCards,
  setSelectedActivity,
  setSelectedVehicle,
} from 'Store/actions/dataLabelings-actions'
import YamazumiBarChart from './YamazumiBarChart'
import { VEHICLES_OPTIONS } from 'Utils/stellantisConsts'
import { DataLabelingMensurationTable } from 'Components/SidebarMenu/Contents/DataLabelings/DataLabelingMensurationTable'

const ChartGrid = styled('div')`;
  margin-right: 14px;
  margin-top:15px;
  height 280px;
`

const StatisticsGrid = styled(Grid)`
  margin-top: 35px !important;
  margin-bottom: 60px !important;
`

const emptyArray = []

export const StellantisDashboardsContentDashMensuration = ({ currentSelected, windowWidth = 2000 }) => {
  useFetchData({ independentFetches: [{ fetchAction: 'dataLabelings' }] })

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dashboards = useSelector((state) => state.dashboards)
  const tenant = useSelector((state) => state.tenants.current)
  const dashboard = 'Dashboard'
  /*Informações dos filtros*/
  const [time, setTime] = useState(['turno1', 'turno2'])
  const [carModel, setCarModel] = useState('')

  /*Sobre pegar as informações da Store sobre os dashInfos*/
  const loading = useSelector((state) => !state.toggle.showDashboard)

  const dataLabelingsIds = useObjSelector((state) => state.dataLabelings.allIds)
  const aggByGroupedCycle = useObjSelector((state) => state.dataLabelings.aggByGroupedCycle)
  const activitiesOptions = useObjSelector((state) => state.dataLabelings.activitiesOptions)
  const selectedActivity = useObjSelector((state) => state.dataLabelings.selectedActivity)
  const selectedVehicle = useObjSelector((state) => state.dataLabelings.selectedVehicle)
  const phaseCards = useObjSelector((state) => state.dataLabelings.phaseCards)

  useEffect(() => {
    dispatch(calcDataLabelingsMetrics())
    dispatch(calcDataLabelingsCards())
  }, [dataLabelingsIds, dispatch])

  useEffect(() => {
    dispatch(fetchDashboardStellantis({ time: 'today' }))
  }, [dispatch])

  let dashboardOptions = emptyArray

  dashboardOptions = [
    {
      key: 'activity',
      text: dashboards.byId['activity'].name,
      value: 'activity',
      onClick: (a, { value }) => dispatch(changeCurrentDashboard(value)),
    },
  ]

  const insightActions = { setCarModel, setTime }
  const insightActionsValues = { time, carModel }

  let exportAction

  let filtdashboardOptions = emptyArray
  filtdashboardOptions = dashboardOptions.filter((f) =>
    !tenant?.features?.includes('people_dashboard') ? f.text !== 'Pessoas' : true
  )

  const handleSave = () => {
    alert('aqui eu salvo')
  }

  let menuButtons = ['toggle fullscreen', 'pdt', 'stellantisCars']

  let mensurationData = [
    {
      index: 0,
      name: 'Leitura macrocav, fixação Cabo massa farol e fixação suporte ABS',
      color: 'blue',
      cycle1: 36,
      cycle2: 28,
      cycle3: 28,
      cycle4: 32,
      cycle5: 29,
      cycle6: 28,
      cycle7: 30,
      cycle8: 45,
      cycle10: 34,
      cycle11: 31,
      cycle12: 28,
      cycle13: 29,
      cycle14: 36,
      cycle15: 40,
      cycle16: 35,
      cycle17: 36,
      cycle18: 27,
      cycle19: 31,
      cycle20: 33,
      cycle21: 26,
      cycle22: 37,
      cycle23: 35,
      cycle24: 30,
      cycle25: 38,
      cycle26: 37,
      cycle27: 29,
      cycle28: 42,
      min: 28,
      max: 45,
      vpercent: 61,
      minWeighed: 28,
    },
    {
      index: 1,
      name: 'Fixação cabo massa assoalho',
      color: 'green',
      cycle1: 12,
      cycle2: 10,
      cycle3: 12,
      cycle4: 12,
      cycle5: 10,
      cycle6: 10,
      cycle7: 10,
      cycle8: 11,
      cycle9: 11,
      cycle10: 11,
      cycle11: 14,
      cycle12: 11,
      cycle13: 13,
      cycle14: 9,
      cycle15: 10,
      cycle16: 12,
      cycle17: 15,
      cycle18: 10,
      cycle19: 8,
      cycle20: 14,
      cycle21: 12,
      cycle22: 19,
      cycle23: 10,
      cycle24: 11,
      cycle25: 12,
      cycle26: 10,
      cycle27: 11,
      cycle28: 11,
      min: 10,
      max: 19,
      vpercent: 90,
      minWeighed: 12,
    },
    {
      index: 2,
      name: 'Movimentar para retirada de suporte e spoiler',
      color: 'pink',
      cycle1: 18,
      cycle2: 21,
      cycle3: 19,
      cycle4: 23,
      cycle5: 24,
      cycle6: 19,
      cycle7: 22,
      cycle8: 23,
      cycle9: 15,
      cycle10: 18,
      cycle11: 17,
      cycle12: 27,
      cycle13: 27,
      cycle14: 23,
      cycle15: 21,
      cycle16: 23,
      cycle17: 22,
      cycle18: 21,
      cycle19: 29,
      cycle20: 19,
      cycle21: 19,
      cycle22: 18,
      cycle23: 17,
      cycle24: 21,
      cycle25: 24,
      cycle27: 20,
      cycle28: 22,
      min: 17,
      max: 29,
      vpercent: 71,
      minWeighed: 17,
    },
    {
      index: 3,
      name: 'Aplicação da fita adesiva aluminizada na estrutura do spoiler',
      color: 'red',
    },
    {
      index: 'Tot',
      color: 'blue',
      name: 'Total',
      cycle1: 66,
      cycle2: 59,
      cycle3: 59,
      cycle4: 67,
      cycle5: 63,
      cycle6: 57,
      cycle7: 62,
      cycle8: 79,
      cycle10: 63,
      cycle11: 62,
      cycle12: 66,
      cycle13: 69,
      cycle14: 68,
      cycle15: 71,
      cycle16: 70,
      cycle17: 73,
      cycle18: 58,
      cycle19: 68,
      cycle20: 66,
      cycle21: 57,
      cycle22: 74,
      cycle23: 62,
      cycle24: 62,
      cycle25: 74,
      cycle26: 47,
      cycle27: 60,
      cycle28: 75,
      cycle9: 26,
      min: '57/55',
      max: 93,
      minWeighed: 57,
    },
  ]

  let uniqueCycles = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
  ]

  return !loading ? (
    <>
      <ModalHeader
        dashboard={tenant?.name !== 'Stellantis' && dashboard}
        dashboardOptions={filtdashboardOptions.sort((a, b) =>
          a.text.localeCompare(b.text, 'pt-br', { ignorePunctuation: true })
        )}
        name={'Mensuração > ' + selectedActivity.text}
        exportAction={exportAction}
        insightActions={insightActions}
        insightActionsValues={insightActionsValues}
        onSave={handleSave}
        menuButtons={menuButtons}
        menuTableIconsOptions={
          activitiesOptions?.map((e) => ({
            ...e,
            action: () => {
              dispatch(setSelectedActivity(e.key))
              dispatch(calcDataLabelingsCards())
            },
          })) || []
        }
        buttonsProps={{
          stellantisCars: {
            options: [{ key: 'all', text: 'Todos Veículos', value: 'all' }, ...VEHICLES_OPTIONS].map((e) => ({
              ...e,
              selected: e.key === selectedVehicle,
              action: () => {
                dispatch(setSelectedVehicle(e.key))
                dispatch(calcDataLabelingsMetrics())
                dispatch(calcDataLabelingsCards())
              },
            })),
          },
          pdt: {
            options:
              activitiesOptions?.map((e) => ({
                ...e,
                action: () => {
                  dispatch(setSelectedActivity(e.key))
                  dispatch(calcDataLabelingsCards())
                },
              })) || [],
          },
        }}
      />
      <Modal.Content style={{ padding: '0', height: 'auto', minHeight: '76vh', overflowY: 'scroll' }}>
        <Segment style={{ border: 'none', boxShadow: 'none' }}>
          <>
            <ChartGrid>
              {/* <CardLineChart
                  dashboard={dashboard}
                  pieDataAll={emptyArray}
                  currentCard={currentCard}
                  filters={insightActionsValues}
                  currentSelected={currentSelected}
                /> */}
              <YamazumiBarChart data={aggByGroupedCycle?.[selectedActivity.key]} showPhases={true} />
            </ChartGrid>
            <StatisticsGrid centered>
              <MainStatistics aggData={aggByGroupedCycle?.[selectedActivity.key]} />
            </StatisticsGrid>
            <MainCards dashboard={dashboard} phaseCards={phaseCards} />
          </>

          <Card
            key="RTCards"
            color="purple"
            // setCurrentCard={setCurrentCard}
          >
            <DataLabelingMensurationTable dataMensurations={mensurationData} uniqueCycles={uniqueCycles} />
          </Card>
        </Segment>
      </Modal.Content>
    </>
  ) : (
    <div style={{ marginTop: '35vh' }}>
      <Loading />
      <h4 style={{ textAlign: 'center' }}>{t('loading_icon_message')}</h4>
    </div>
  )
}
