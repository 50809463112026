/*  
  Author: Luís Mourão
  Type: Action
  
  Description: 
    - 
  
  TODO: 
    - 
*/

import axios from 'axios'
import { API } from 'aws-amplify'
import { onChangeAssetLocation } from 'graphql/subscriptions'
import { listAssetsLocation } from 'graphql/queries'
import { urlBase } from 'Apis/rest'
// import { setFalse } from 'Store/actions/toggle-action'

export const fetchAssetsLocation =
  (nextToken = null) =>
  async (dispatch, getState) => {
    const tenant = getState().login.empresa
    const assets = getState().assets.byId
    try {
      dispatch({ type: 'FETCH_ASSETS_LOCATION_START', tenant })
      const response = await API.graphql({
        query: listAssetsLocation,
        variables: { tenant, limit: 10000, nextToken },
      })
      const { items, nextToken: newNextToken } = response.data.listAssetsLocation

      if (newNextToken) {
        await dispatch(fetchAssetsLocation(newNextToken))
      }

      dispatch({
        type: 'FETCH_ASSETS_LOCATION_SUCCESS',
        payload: items,
        assets,
      })
    } catch (err) {
      console.error('error fetching measures', err)
      dispatch({ type: 'FETCH_ASSETS_LOCATION_FAILURE', error: err.message })
    }
  }

export const subscribeAssetLocationChange =
  (retrying = 2000) =>
  (dispatch, getState) => {
    const tenant = getState().login.empresa
    return API.graphql({
      query: onChangeAssetLocation,
      variables: {
        tenant,
      },
    }).subscribe({
      next: ({ provider, value }) => {
        const payload = value.data.onChangeAssetLocation
        dispatch({ type: 'NEW_ASSET_LOCATION#' + payload.assetId, payload, id: payload.assetId })
      },
      error: (error) => {
        console.error('subscription error', error)
        setTimeout(() => {
          dispatch(subscribeAssetLocationChange(retrying))
        }, retrying)
      },
    })
  }

export const fetchCategories = (assetId) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({ type: 'FETCH_CATEGORIES_START', payload: {}, params: { type: 'categories' }, assetId })
    res = await axios
      .get(`${urlBase}/assets/measures?type=categories&assetId=${assetId}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_CATEGORIES_SUCCESS', assetId, payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_CATEGORIES_FAILURE', payload: err })
  }
}

export const fetchCategoryMeasures = (assetId, category, load) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const time = new Date().toISOString().replace('T', ' ')
  let res
  try {
    dispatch({ type: 'FETCH_CATEGORY_MEASURES_START', assetId, category, time, payload: { load } })
    if (load) {
      res = await axios
        .get(`${urlBase}/assets/measures?time=${time}&assetId=${assetId}&category=${category}`, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((resp) => resp.data.result)
      dispatch({ type: 'FETCH_CATEGORY_MEASURES_SUCCESS', assetId, payload: res, category })
    }
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_CATEGORY_MEASURES_FAILURE', payload: err })
  }
}

export const fetchAssetCoords = (assetId) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({ type: 'FETCH_ASSET_COORDS_START', assetId })
    res = await axios
      .get(`${urlBase}/assets/measures?assetId=${assetId}&type=${'lastCoords'}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_ASSET_COORDS_SUCCESS', assetId, payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_ASSET_COORDS_FAILURE', payload: err })
  }
}

export const fetchAssetsByMeasureTime = (timeAgo) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({ type: 'FETCH_ASSETS_BY_MEASURES_TIME_START', timeAgo })
    res = await axios
      .get(`${urlBase}/assets/measures?timeAgo=${timeAgo}&type=assetsByTimeAgo`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_ASSETS_BY_MEASURES_TIME_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_ASSETS_BY_MEASURES_TIME_FAILURE', payload: err })
  }
}

export const fetchAssetsMeasuresByAbsoluteTime = (startTime, endTime) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({ type: 'FETCH_ASSETS_BY_MEASURES_TIME_START' })
    res = await axios
      .get(`${urlBase}/assets/measures?startTime=${startTime}&endTime=${endTime}&type=assetsByTimeAgo`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_ASSETS_BY_MEASURES_TIME_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_ASSETS_BY_MEASURES_TIME_FAILURE', payload: err })
  }
}

// export const setCurrentCategory = (category) => {
//   return {
//     type: 'SET_CURRENT_CATEGORY',
//     category,
//   }
// }

// export const setCurrent = (assetId) => {
//   return {
//     type: 'SET_CURRENT',
//     assetId,
//   }
// }

// export const fetchInitialAssetsMeasures = (data) => async (dispatch, getState) => {
//   const assets = getState().assets.allIds
//   dispatch({ type: 'INITIAL_ASSETS_MEASURES', payload: data, assets: assets })
// }

// export const newAssetMeasures = (data) => async (dispatch) => {
//   dispatch({ type: 'NEW_ASSET_LOCATION#' + data.assetId, payload: data })
// }

// export const fetchAssetLocation = (assetId) => async (dispatch) => {
//   const jwt = localStorage.getItem('id_token')
//   const time = new Date().toISOString().replace('T', ' ')
//   const category = 'coords'
//   let res
//   try {
//     dispatch({ type: 'FETCH_CATEGORY_START', payload: { params: { assetId, category, time } } })
//     res = await axios
//       .get(`https://1yccc8vzc6.execute-api.us-east-2.amazonaws.com/v0/assets/measures?time=${time}&assetId=${assetId}&category=${category}`, {
//         headers: {
//           Authorization: 'Bearer ' + jwt,
//         },
//       })
//       .then((resp) => resp.data.result)
//     if (res?.coords) {
//       dispatch({ type: 'FETCH_CATEGORY_SUCCESS', assetId, payload: res, category: category })
//       dispatch({ type: 'SET_TRUE', payload: 'showFitBounds' })
//     } else {
//       console.error(`Asset ${assetId} without coords`)
//     }
//   } catch (err) {
//     console.error(err)
//     dispatch({ type: 'FETCH_CATEGORY_FAILURE', payload: err })
//   }
//   dispatch({ type: 'SET_FALSE', payload: 'loadingFilter' })
// }
