import React from 'react'
import { Form, DropdownProps } from 'semantic-ui-react'
import DropdownStoreField from './DropdownStoreField'
import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import EntityDropdownField from './EntityDropdownField'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

interface GenericAssetsPropsFieldsProps {
  setProps: (field: string, value: any) => void
  required?: boolean
}

const GenericAssetsPropsFields: React.FC<GenericAssetsPropsFieldsProps> = ({ setProps, required = true }) => {
  const selectedAssetProp = useSelector(
    (state: RootStore) => state.reports.wip.filters?.assetProp ?? state.reports.current?.filters?.assetProp
  )
  const assets = useObjSelector(
    (state) => (state.reports.wip.filters?.assets as string[]) || state.reports.current?.filters?.assets
  )

  const showFeedback = useSelector((state: RootStore) => state.reports.form?.steps.items[1].showFeedback)

  const handleChange = (e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    setProps('wip.filters.assetProp', value)
    setProps('wip.filters.assets', [])
  }

  return (
    <Form.Group widths="equal">
      <DropdownStoreField
        entity="reports"
        label="Ativo/Pessoa"
        value={'filters.assetProp'}
        placeholder={'Selecionar Propriedade'}
        name="assetProp"
        onChange={handleChange}
        options={[
          {
            key: 'Atribuição',
            value: 'type',
            text: 'Atribuição',
          },
          {
            key: 'name',
            value: 'name',
            text: 'Nome',
          },
        ]}
        noResultsMessage={'Nenhum resultado encontrado no filtro selecionado.'}
        showFeedback={showFeedback}
      />
      {selectedAssetProp === 'name' && (
        <EntityDropdownField
          label="Pessoa/Ativo"
          entity="assets"
          value={assets}
          name="assets"
          placeholder="Selecionar Nome(s)"
          onChange={(value: any) => setProps('wip.filters.assets', value)}
          multiple
          // required
        />
      )}
      {selectedAssetProp === 'type' && (
        <EntityDropdownField
          label="Atribuição"
          entity="profiles"
          value={assets}
          name="profiles"
          placeholder="Selecionar Atribuição(s)"
          onChange={(value: any) => {
            setProps('wip.filters.assets', value)
          }}
          multiple
        />
      )}
    </Form.Group>
  )
}

export default GenericAssetsPropsFields
