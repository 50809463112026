import React, { Component, Fragment } from 'react'
import { Range } from 'rc-slider'
import { Grid } from 'semantic-ui-react'
import { sliderChange } from 'Store/actions/history-action'
import { connect } from 'react-redux'
import 'rc-slider/assets/index.css'

class TimeSlider extends Component {
  state = {
    sliderMin: this.props.min,
    sliderMax: this.props.max,
    sliderTotal: this.props.total,
  }

  componentDidUpdate(prevProps) {
    let newState = {}
    if (prevProps.min !== this.props.min) newState.sliderMin = this.props.min
    if (prevProps.max !== this.props.max) newState.sliderMax = this.props.max
    if (prevProps.total !== this.props.total) newState.sliderTotal = this.props.total
    if (Object.keys(newState).length > 0 && this.props.total > 0) {
      this.props.sliderLocalParentChange({ ...newState })
      this.setState({ ...newState })
    }
  }

  sliderGlobalChange(v) {
    this.props.sliderChange({ min: v[0], max: v[1] })
    // total: v[2] ? v[2] : this.props.total // Isso realmente é necessário??
  }

  sliderLocalChange(v) {
    const newState = { sliderMin: v[0], sliderMax: v[1], sliderTotal: v[2] ? v[2] : this.props.total }
    this.props.sliderLocalParentChange({ ...newState })
    this.setState({ ...newState })
  }

  render() {
    const { sliderMin, sliderMax, sliderTotal } = this.state
    const { min, max, perfil } = this.props

    return (
      <Fragment>
        <Grid.Column textAlign="center" verticalAlign="top" width={1}>
          {perfil === 'DEV' && (
            <span style={{ fontSize: '11px', float: 'left', marginTop: '-4px', marginLeft: '6px' }}>{min}</span>
          )}
        </Grid.Column>
        <Grid.Column width={14}>
          <Range
            disabled={this.props.heatMap}
            min={0}
            max={sliderTotal - 1}
            defaultValue={[min, max]}
            value={[min >= sliderTotal ? sliderTotal : sliderMin, min >= sliderTotal ? sliderTotal : sliderMax]}
            onAfterChange={(v) => this.sliderGlobalChange(v)}
            onChange={(v) => this.sliderLocalChange(v)}
          />
        </Grid.Column>
        <Grid.Column textAlign="center" verticalAlign="top" width={1}>
          {perfil === 'DEV' && (
            <span style={{ fontSize: '11px', float: 'right', marginTop: '-4px', marginRight: '6px' }}>{max}</span>
          )}
        </Grid.Column>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  min: state.history.slider.min,
  max: state.history.slider.max,
  total: state.history.filteredSize,
  perfil: state.login.perfil,
})

const mapDispatchToProps = {
  sliderChange: sliderChange,
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlider)
