/*  Author: Felipe Pegado
    Type: Dumb
	Description: Heatmap button item
	TODO: 
*/

import React from 'react'
import { Dropdown } from 'semantic-ui-react'

const HeatMapItem = (props) => {
  const handleClick = () => {
    props.onClick(props.id)
  }
  return (
    <Dropdown.Item
      size={props.size}
      icon={props.icon ? props.icon : props}
      text={props.text}
      color={props.color}
      key={props.title}
      onClick={handleClick}
    />
  )
}

export default HeatMapItem
