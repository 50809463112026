import React from 'react'
//import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import useAsync from 'Utils/hooks/useAsync'
import { sendTokenEmail } from 'Apis/sendTokenEmail'

interface SendTokenEmailProps {
  assetId: string
  email: string | null
}

const styles = {
  link: {
    background: 'none',
    border: 'none',
    color: '#0088cc',
    cursor: 'pointer',
  },
}

const SendTokenEmail = ({ assetId, email }: SendTokenEmailProps) => {
  const { t } = useTranslation()
  const { execute, status } = useAsync(() => sendTokenEmail(assetId), false)
  if (!email) return <div>{t('peopleTable.cell.sendTokenEmailNoEmail')}</div>
  if (status === 'pending') return <div>{t('peopleTable.cell.sendTokenEmailPending')}</div>
  if (status === 'error') return <div>{t('peopleTable.cell.sendTokenEmailError')}</div>
  if (status === 'success') return <div>{t('peopleTable.cell.sendTokenEmailSuccess')}</div>
  return (
    <span onClick={execute} style={styles.link}>
      {t('peopleTable.cell.sendTokenEmail')}
    </span>
  )
}

export default SendTokenEmail
