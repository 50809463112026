import { SAVE_CENTER_ZOOM } from 'Store/actions/login-action.js'
import { logError } from 'Utils/analytics'
import initialStore from 'Store/initialStore'
import _ from 'lodash'
import set from 'immutable-set'
import produce from 'immer'

const initialState = initialStore.login

export default function profileReducer(
  state = initialState,
  { type, payload, id, features, prop, value, lastFetchTime }
) {
  if (payload === null) {
    logError('Profile nulo', payload)
    return state
  }

  // Features presentes em apenas alguns tenants
  const specificPanelsFeatures = [
    'interactions',
    'firefighter',
    'locationHist',
    'labelingTool',
    'logouts',
    'phases',
    'motionChart',
    'manualYamazumi',
    'automaticYamazumi',
    'cycleTimeMeasurement',
    'activities',
    'dataLabelings',
    'simulations',
    'driver',
  ]

  switch (type) {
    case 'fetchModule':
      return { ...state, beacons: payload }
    case 'saveLanguages':
      return { ...state, ...payload }
    case 'saveFavorites':
      return { ...state, ...payload }
    case 'saveLoginInfo':
      return { ...state, ...payload }
    case 'saveFollowMe':
      return { ...state, followMe: payload.id }
    case 'removeFollowMe':
      return { ...state, followMe: '' }
    case SAVE_CENTER_ZOOM:
      return { ...state, ...payload }

    case 'updateloginNew':
      if (state.email === id) {
        return { ...state, ...payload.info }
      }
      return state

    case 'LOAD_LOGIN':
      return {
        ...state,
        empresa: payload.empresa?.trim(),
        email: payload.email,
        perfil: payload.perfil,
        section: payload.section,
        authMethod: payload.auth,
      }
    case 'saveRefMap':
      state.refMap = payload.refMap
      return state

    case 'SET_LOGIN_CONNECTION':
      return { ...state, connection: payload }

    case 'FETCH_LOGIN_START':
      return { ...state, loading: true }

    case 'FETCH_LOGIN_PREFERENCES_SUCCESS':
      if (!payload) return state
      else {
        let preferences = _.mergeWith(
          {},
          state.preferences,
          {
            ...payload,
            routesPanels: {
              byId: payload.routesPanels
                ? payload.routesPanels?.reduce((a, c) => ({ ...a, [c.id]: c }), {})
                : state.preferences.routesPanels.byId,
            },
          },
          (a, b) => {
            return b === null ? a : undefined
          }
        )
        preferences.routesPanels.allIds = _.union(
          Object.values(payload.routesPanels || []).map((e) => e.id),
          Object.values(preferences.routesPanels?.byId || []).map((e) => e.id)
        ).reduce((a, v, i) => {
          if (!specificPanelsFeatures.includes(v) || features.includes(v)) return a.concat(v)
          else return a
        }, [])
        preferences.indoor = preferences.indoor?.reduce((a, v) => ({ ...a, [v.id]: v }), {})
        preferences.fences = preferences.fences?.reduce((a, v) => ({ ...a, [v.id]: v }), {})
        preferences.refs = preferences.refs?.reduce((a, v) => ({ ...a, [v.id]: v }), {})
        preferences.routes = preferences.routes?.reduce((a, v) => ({ ...a, [v.id]: v }), {})
        return { ...state, preferences, loading: false }
      }
    case 'FETCH_LOGIN_PREFERENCES_FAILURE':
      return produce(state, (newState) => {
        newState.preferences.routesPanels.allIds = state.preferences.routesPanels.allIds.filter(
          (e) => !specificPanelsFeatures.includes(e) || features.includes(e)
        )
        newState.loading = false
      })

    case 'SAVE_LOGIN_MAP_LAYERS':
      return { ...state, preferences: { ...state.preferences, mapLayers: payload } }

    case 'TOGGLE_DASHBOARD_STATIC_MODE':
      let currentStaticMode = state.preferences.routesPanels.byId['dashboard'].staticMode
      let newState = {
        ...state,
        preferences: {
          ...state.preferences,
          routesPanels: {
            ...state.preferences.routesPanels,
            byId: {
              ...state.preferences.routesPanels.byId,
              dashboard: {
                ...state.preferences.routesPanels.byId.dashboard,
                staticMode: !currentStaticMode,
              },
            },
          },
        },
      }
      return newState

    case 'CHANGE_LOGIN_PREFERENCES':
      let fieldPreferences = state.preferences[payload.field]
      if (fieldPreferences) fieldPreferences = { ...fieldPreferences, ...payload.preferences }
      else fieldPreferences = { ...payload.preferences }
      const preferences = { ...state.preferences, [payload.field]: fieldPreferences }
      return { ...state, preferences }

    case 'CHANGE_DASHBOARD_PANEL_PREFERENCES':
      let dashboardPanelPreferences = state.preferences.routesPanels.byId['dashboard'].panelsById[payload.id]
      if (dashboardPanelPreferences)
        dashboardPanelPreferences = { ...dashboardPanelPreferences, ...payload.preferences }
      else dashboardPanelPreferences = { ...payload.preferences }
      const dPanelsId = {
        ...state.preferences.routesPanels.byId.dashboard.panelsById,
        [payload.id]: dashboardPanelPreferences,
      }
      const dPanels = { ...state.preferences.routesPanels.byId.dashboard, panelsById: dPanelsId }
      const dashs = { ...state.preferences.routesPanels.byId, dashboard: dPanels }
      const rPanels = { ...state.preferences.routesPanels, byId: dashs }
      const prefs = { ...state.preferences, routesPanels: rPanels }
      return { ...state, preferences: prefs }

    case 'CHANGE_DASHBOARD_LAYOUT':
      return produce(state, (newState) => {
        newState.preferences.routesPanels.byId['dashboard'].layout = payload
      })

    case 'CHANGE_DASHBOARD_TO_DEFAULT':
      return {
        ...state,
        preferences: {
          ...state.preferences,
          routesPanels: {
            ...state.preferences.routesPanels,
            byId: {
              ...state.preferences.routesPanels.byId,
              dashboard: {
                ...state.preferences.routesPanels.byId.dashboard,
                ...payload,
              },
            },
          },
        },
      }

    case 'UPDATE_COLUMNS_DISPLAY':
      // idk do something and set login.preferences.routesPanels.rules.hiddenColumns ?

      return produce(state, (newState) => {
        if (!payload) return state
        if (!state.preferences?.routesPanels?.byId?.[payload.tableName]) {
          console.error('Error on login Reducer: UPDATE_COLUMNS_DISPLAY')
          return state
        }
        newState.preferences.routesPanels.byId[payload.tableName].hiddenColumns = payload.columnsPreference
      })

    case 'UPDATE_REPORTS_COLUMNS_DISPLAY':
      // idk do something and set login.preferences.routesPanels.rules.hiddenColumns ?

      return produce(state, (newState) => {
        if (!payload) return state
        if (
          !state.preferences?.routesPanels?.byId?.reports ||
          !state.preferences?.routesPanels?.byId?.reports?.reportsById ||
          !state.preferences?.routesPanels?.byId?.reports?.reportsAllIds
        ) {
          console.error('Error on login Reducer: UPDATE_REPORTS_COLUMNS_DISPLAY')
          return state
        }
        if (!newState.preferences.routesPanels.byId.reports.reportsById[payload.tableName]) {
          newState.preferences.routesPanels.byId.reports.reportsAllIds.push(payload.tableName)
          newState.preferences.routesPanels.byId.reports.reportsById[payload.tableName] = {}
        }
        newState.preferences.routesPanels.byId.reports.reportsById[payload.tableName].hiddenColumns =
          payload.columnsPreference
      })

    case 'UPDATE_GROUPED_ROWS':
      // set login.preferences.routesPanels.rules.groupedValuesColumns ?

      return produce(state, (newState) => {
        if (!payload) return state
        if (!state.preferences?.routesPanels?.byId?.[payload.tableName]) {
          console.error('Error on login Reducer: UPDATE_GROUPED_ROWS')
          return state
        }
        newState.preferences.routesPanels.byId[payload.tableName].groupedValuesColumns = payload.groupedValuesColumns
      })

    case 'UPDATE_REPORTS_GROUPED_ROWS':
      // idk do something and set login.preferences.routesPanels.rules.hiddenColumns ?

      return produce(state, (newState) => {
        if (!payload) return state
        if (
          !state.preferences?.routesPanels?.byId?.reports ||
          !state.preferences?.routesPanels?.byId?.reports?.reportsById ||
          !state.preferences?.routesPanels?.byId?.reports?.reportsAllIds
        ) {
          console.error('Error on login Reducer: UPDATE_REPORTS_GROUPED_ROWS')
          return state
        }
        if (!newState.preferences.routesPanels.byId.reports.reportsById[payload.tableName]) {
          newState.preferences.routesPanels.byId.reports.reportsAllIds.push(payload.tableName)
          newState.preferences.routesPanels.byId.reports.reportsById[payload.tableName] = {}
        }
        newState.preferences.routesPanels.byId.reports.reportsById[payload.tableName].groupedValuesColumns =
          payload.groupedValuesColumns
      })

    case 'SET_LOGIN_PROP':
      return set(state, prop, value)

    case 'SET_NEW_ROUTE_PANEL': {
      const auxState = set(state, 'preferences.routesPanels.byId', {
        ...state.preferences.routesPanels.byId,
        [value.id]: value,
      })
      if (state.preferences.routesPanels.allIds.includes(value.id)) return auxState
      else return set(auxState, 'preferences.routesPanels.allIds', [value.id, ...state.preferences.routesPanels.allIds])
    }

    case 'DELETE_ROUTE_PANEL': {
      return set(
        state,
        'preferences.routesPanels.allIds',
        state.preferences.routesPanels.allIds.filter((id) => id !== payload.id)
      )
    }

    case 'UPDATE_TAB_ACTION_START':
    case 'DELETE_TAB_ACTION_START':
      return { ...state, cudLoading: true }

    case 'UPDATE_TAB_ACTION_SUCCESS':
    case 'DELETE_TAB_ACTION_SUCCESS':
      return { ...state, cudLoading: false, cudSuccess: true }

    case 'UPDATE_TAB_ACTION_FAILURE':
    case 'DELETE_TAB_ACTION_FAILURE':
      return { ...state, cudLoading: false, cudSuccess: false, error: payload }

    case 'CLEAR_TAB_STATE':
      return { ...state, cudLoading: undefined, hasError: undefined, cudSuccess: undefined }

    case 'FETCH_GEO_MODULES_SUCCESS':
      if (!lastFetchTime) {
        return produce(state, (newState) => {
          newState.preferences.fences = Object.values(state.preferences.fences).reduce((acc, pref) => {
            acc[pref.id] = {
              ...pref,
              invisible: pref.invisible
                ? pref.invisible
                : payload.find((module) => module.id === pref.id)?.defaultInvisible ?? false,
            }
            return acc
          }, {})
        })
      } else return state

    default:
      return state
  }
}
