import moment from 'moment'
export const headers = [
  { label: 'Hora do BD', key: 'hora_bd' },
  /*{ label: "Hora_LS", key: "hora_ls" },*/
  { label: 'Hora do Sensor', key: 'hora_se' },
  { label: 'Nome do ativo', key: 'nome_ativo' },
  /*{ label: "Nome_Sensor", key: "nome_sensor" },*/
  { label: 'Nome do Responsável', key: 'resp' },
  { label: 'Potência LoRa', key: 'potencia' },
  { label: 'Latitude', key: 'lat' },
  { label: 'Longitude', key: 'lng' },
  { label: 'Velocidade GNSS (Km/h)', key: 'vel' },
  { label: 'Última TAG RFID Vista', key: 'rfid' },
  { label: 'Última horário da TAG RFID', key: 'rfid_tempo' },
  { label: 'Temperatura Caixa', key: 'temp' },
  { label: 'Distância ao Gateway', key: 'distGateway' },
]
export const handleExportHist = (allIds, byId, assets) =>
  allIds
    .map((v) =>
      Object.values(byId[v]).map((value) => {
        return assets[v]
          ? {
              nome_ativo: assets[v].name,
              hora_bd: moment(value.hora).format('lll'),
              potencia: value.info.lora.potencia_lora,
              lat: value.info.coords.lat ? value.info.coords.lat : value.info.lat,
              lng: value.info.coords.lng ? value.info.coords.lng : value.info.lng,
              temperature: value.info.gauge ? value.info.gauge.temperature : value.info.temperature,
              pressure: value.info.gauge && value.info.gauge.pressure,
              action_name: value.info.motion && value.info.motion.actionname,
            }
          : {}
      })
    )
    .reduce((prev, next) => prev.concat(next))
