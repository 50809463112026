import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { Input, Form, /* Dropdown,  Divider, Header,*/ Segment } from 'semantic-ui-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//import schema from 'graphql/schema.json'
import { /* useDispatch,*/ useDispatch, useSelector, shallowEqual } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { insertDynamicData, updateDynamicData } from 'Store/actions/modules-action'
import { GenericFormProps } from './types/GenericFormProps'

type ErrorForm = {
  name: boolean
  url: boolean
}

// copying this from TypesForm.jsx
type FieldsState = {
  name: string
  description: string
  code: string
  order: number
  corner0: { lat: number; lng: number }
  corner1: { lat: number; lng: number }
  corner2: { lat: number; lng: number }
  corner3: { lat: number; lng: number }
  url: string
}
const UnitsForm: React.FC<GenericFormProps> = ({
  handleCancel,
  addOpen,
  table,
  tableLabel,
  confirmAction,
  handleSave,
  setAddOpen,
  editId,
}) => {
  const { byId } = useSelector((state: RootStore) => state.modules.units, shallowEqual)
  //// const tenant =useSelector((state) => state.login.empresa)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  /* Função que salva as informação no banco dependendo se insert ou update*/
  const handleChange = (fields: FieldsState, name: string, value: string) => {
    if (confirmAction === 'insert' && setFields) setFields({ ...fields, [name]: value })
    if (confirmAction === 'update' && setFields) {
      setFields({ ...fields, [name]: value })
      setChanges({ ...changes, [name]: value })
    }
  }

  const [changes, setChanges] = useState({})

  useEffect(() => {
    if (confirmAction === 'update') {
      setFields({
        name: byId[editId]?.info?.label || '',
        code: byId[editId]?.info?.code || '',
        description: byId[editId]?.info?.description || '',
        order: byId[editId]?.info?.order || 0,
        corner0: byId[editId]?.info?.corners?.[0],
        corner1: byId[editId]?.info?.corners?.[1],
        corner2: byId[editId]?.info?.corners?.[2],
        corner3: byId[editId]?.info?.corners?.[3],
        url: byId[editId]?.info?.url || '',
      })
    }
  }, [confirmAction, byId, editId])

  // maybe it doesn't have to contain '<FieldsState>' ?
  const [fields, setFields] = useState<FieldsState>({
    name: '',
    code: '',
    description: '',
    order: 0,
    corner0: { lat: 0, lng: 0 },
    corner1: { lat: 0, lng: 0 },
    corner2: { lat: 0, lng: 0 },
    corner3: { lat: 0, lng: 0 },
    url: '',
  })
  // const [formObj, setFormObj] = useState({})
  const [errorObj, setErrorObj] = useState<ErrorForm>({ name: false, url: false })

  function checkForm({ name, url }: { name: string; url: string }) {
    if (!name || !url) {
      setErrorObj({ ...errorObj, name: !name, url: !url })
      return
    }
    if (confirmAction === 'update') {
      let params = {
        id: editId,
        corners: [fields.corner0, fields.corner1, fields.corner2, fields.corner3],
        description: fields.description,
        name: fields.name,
        order: fields.order,
        code: fields.code,
        url: fields.url,
      }
      dispatch(updateDynamicData(params, 'units'))
    } else {
      let data = {
        name: fields.name,
        type: 4,
        info: {
          url: fields.url,
          code: fields.code,
          corners: [fields.corner0, fields.corner1, fields.corner2, fields.corner3],
          properties: { label: fields.name, url: fields.url, code: fields.code },
        },
      }
      dispatch(insertDynamicData(data, 'units'))
    }
    setAddOpen(false)
  }

  let latStyle = { marginRight: '16px' }
  return (
    <ModalTemplate
      onClose={handleCancel}
      open={addOpen}
      size="small"
      header={
        confirmAction === 'insert' ? (
          <span>{`Adicionar ${t(tableLabel)}`}</span>
        ) : (
          <span>{`Editar ${t(tableLabel)}`}</span>
        )
      }
      onSave={() => checkForm(fields)}
      onCancel={handleCancel}
    >
      <Form>
        <Form.Group>
          <Form.Field width="10" required={true} error={errorObj.name}>
            <label>{'Nome'}</label>
            <Input
              name={'name'}
              fluid
              value={fields.name}
              onChange={(e, { name, value }) => setFields({ ...fields, [name]: value })}
            />
          </Form.Field>
          <Form.Field required={false} width={6}>
            <label>{t('code')}</label>
            <Input
              name={'code'}
              fluid
              value={fields.code}
              min="0"
              onChange={(e, { name, value }) => handleChange(fields, name, value.slice(0, 8))}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field required={false}>
          <label>{'Descrição'}</label>
          <Input
            name={'description'}
            fluid
            value={fields.description}
            onChange={(e, { name, value }) => setFields({ ...fields, [name]: value })}
          />
        </Form.Field>
        <Form.Field required={false}>
          <label>{'Ordem'}</label>
          <Input
            name={'order'}
            fluid
            type="number"
            min="0"
            value={fields.order}
            onChange={(e, { name, value }) => setFields({ ...fields, [name]: value.slice(0, 4) })}
          />
        </Form.Field>
        <Form.Field required={true} error={errorObj.url}>
          <label>{'URL'}</label>
          <Input
            name={'url'}
            fluid
            value={fields.url}
            onChange={(e, { name, value }) => setFields({ ...fields, [name]: value })}
          />
        </Form.Field>
        {/* <br />
        <Divider horizontal>
          <Header as="h4">Cantos</Header>
        </Divider>
        {
          //<br />*/}
        <Segment.Group horizontal>
          {[0, 1].map((i) => (
            <Segment key={'CornerSegment' + i}>
              {'Vértice ' + i}
              <Form.Field required={true} inline>
                <label style={latStyle}>lat</label>
                <Input
                  name={'corner' + i}
                  type="number"
                  value={fields['corner' + i]?.lat}
                  onChange={(e, { name, value }) => {
                    setFields({ ...fields, [name]: { lat: Number(value) % 90, lng: fields[name].lng } })
                  }}
                />
              </Form.Field>
              <Form.Field required={true} inline>
                <label>lng</label>
                <Input
                  name={'corner' + i}
                  type="number"
                  value={fields['corner' + i]?.lng}
                  onChange={(e, { name, value }) => {
                    setFields({ ...fields, [name]: { lat: fields[name].lat, lng: Number(value) % 90 } })
                  }}
                />
              </Form.Field>
            </Segment>
          ))}
        </Segment.Group>
        <Segment.Group horizontal>
          {[2, 3].map((i) => (
            <Segment key={'CornerSegment' + i}>
              {'Vértice ' + i}
              <Form.Field required={true} inline>
                <label style={latStyle}>{'lat'}</label>
                <Input
                  name={'corner' + i}
                  type="number"
                  value={fields['corner' + i]?.lat}
                  onChange={(e, { name, value }) => {
                    setFields({ ...fields, [name]: { lat: Number(value) % 90, lng: fields[name].lng } })
                  }}
                />
              </Form.Field>
              <Form.Field required={true} inline>
                <label>{'lng'}</label>
                <Input
                  name={'corner' + i}
                  type="number"
                  value={fields['corner' + i]?.lng}
                  onChange={(e, { name, value }) => {
                    setFields({ ...fields, [name]: { lat: fields[name].lat, lng: Number(value) % 90 } })
                  }}
                />
              </Form.Field>
            </Segment>
          ))}
        </Segment.Group>
      </Form>
    </ModalTemplate>
  )
}

export default UnitsForm
