import { urlBase } from './rest'
import axios from 'axios'

export const getPeopleHist = async (peopleType: string, timeInterval: string) => {
  let peopleTypeCode
  switch (peopleType.toLowerCase()) {
    case 'brigadista':
      peopleTypeCode = 7611
      break
    default:
      peopleTypeCode = 0
  }

  try {
    const body = { timeInterval: timeInterval, peopleType: peopleTypeCode }
    const jwt = localStorage.getItem('id_token')
    const headers = {
      headers: { Authorization: 'Bearer ' + jwt },
    }
    const result = await axios.post(urlBase + '/reports/attendance', body, headers)
    return result.data.body
  } catch (err) {
    console.error(err)
    return null
  }
}
