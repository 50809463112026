/*  Author: Luís Mourão
    Type: Smart
	Description: History Parent			 
    To do list: 
    - Retirar assets do connect e receber informação de cor direto no histórico (Sem repetir informação)
*/

import React, { memo } from 'react'
import { FeatureGroup } from 'react-leaflet'
import HistMarker from './HistMarker.js'
import HistAntpath from './HistAntpath.js'
import HistDiscrete from './HistDiscrete.js'
import { useSelector } from 'react-redux'

const HistRender = () => {
  const histType = useSelector((state) => state.history.type)

  return (
    <FeatureGroup key="HistRender">
      <HistMarker />
      {histType === 'antpath' && <HistAntpath />}
      {histType === 'discrete' && <HistDiscrete />}
    </FeatureGroup>
  )
}

export default memo(HistRender)
