import React, { PureComponent } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  // ReferenceLine,
  ResponsiveContainer,
} from 'recharts'

const data = [
  {
    name: 'Ago',
    APP: 4000,
    SB: 2400,
    amt: 2400,
  },
  {
    name: 'Set',
    APP: 3000,
    SB: 1398,
    amt: 2210,
  },
  {
    name: 'Out',
    APP: 2000,
    SB: 9800,
    amt: 2290,
  },
  {
    name: 'Nov',
    APP: 2780,
    SB: 3908,
    amt: 2000,
  },
  {
    name: 'Dez',
    APP: 1890,
    SB: 4800,
    amt: 2181,
  },
  {
    name: 'Jan',
    APP: 2390,
    SB: 3800,
    amt: 2500,
  },
]

export default class ExampleLineChat extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/line-chart-width-xaxis-padding-sujqi'

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={700} height={800} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="SB" stroke="#ED7D31" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="APP" stroke="#4472C4" />
        </LineChart>
      </ResponsiveContainer>
    )
  }
}
