import React from 'react'
// import { useSelector } from 'react-redux'
import { Label, Card, List } from 'semantic-ui-react'

const LEGEND_TEXT = 'Classificação de Riscos por Perímetros:'

export const CardRisksLegend = ({ isMobileSized }) => {
  return (
    <Card
      color="blue"
      style={{
        zIndex: '900',
        bottom: '4vh',
        position: 'absolute',
        height: isMobileSized ? '75px' : '55px',
        width: isMobileSized ? '500px' : '607px',
        backgroundColor: 'rgb(255,255,255,0.6)',
      }}
    >
      <Card.Content>
        {isMobileSized && <Card.Header>{LEGEND_TEXT}</Card.Header>}
        <Card.Description>
          <List horizontal>
            <List.Item>{isMobileSized ? '' : LEGEND_TEXT}</List.Item>
            <List.Item>
              <Label color="green">Baixo</Label>
            </List.Item>
            <List.Item>
              <Label color="yellow">Médio</Label>
            </List.Item>
            <List.Item>
              <Label color="orange">Alto</Label>
            </List.Item>
            <List.Item>
              <Label color="red">Muito Alto</Label>
            </List.Item>
            {/*<List.Item>Demais Cores: Indefinido </List.Item>*/}
          </List>
        </Card.Description>
      </Card.Content>
    </Card>
  )
}
export default CardRisksLegend
