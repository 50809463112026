import initialStore from 'Store/initialStore'
import { setStoreProps } from './reducerUtils'
import produce from 'immer'
import { v4 } from 'uuid'
import parseDashboard from 'Utils/parseDashboard'
import { omit, set } from 'lodash'
import mergeObjects from 'Utils/mergeObjects'

export default function betaDashboardsReducer(state = initialStore.betaDashboards, { type, payload, email }) {
  const initialPanel = (panelIndex) => {
    const newId = v4()

    return {
      id: newId,
      label: `Painel ${panelIndex}`,
      layout: {
        x: 0,
        y: 99999, // puts it at the bottom
        w: 3,
        h: 6,
      },
    }
  }

  switch (type) {
    case 'SET_BETA_DASHBOARD_PROPS':
      return setStoreProps(state, payload)

    case 'FETCH_BETA_DASHBOARDS_START':
      return produce(state, (newState) => {
        newState.loading = true
      })

    case 'FETCH_BETA_DASHBOARDS_SUCCESS':
      return produce(state, (newState) => {
        newState.byId = payload.reduce((acc, dashboard) => {
          if (dashboard?.access === 'PUBLIC' || email === dashboard.createdBy) {
            acc[dashboard.id] = parseDashboard(dashboard)
          }
          return acc
        }, {})
        newState.allIds = Object.keys(newState.byId)
        newState.loading = false
        newState.loadedAt = new Date().toISOString()
      })

    case 'FETCH_BETA_DASHBOARDS_FAILURE':
      return produce(state, (newState) => {
        newState.loading = false
      })

    case 'SAVE_BETA_DASHBOARDS_SUCCESS':
      if (!payload?.id) return state
      return produce(state, (newState) => {
        if (!newState.allIds.includes(payload.id)) newState.allIds.push(payload.id)
        const parsedPayload = parseDashboard(payload)
        newState.byId[payload.id] = parsedPayload
        newState.current = {
          ...parsedPayload,
          panels: mergeObjects(state.current.panels, parsedPayload.panels),
        }
        newState.form.loading = false
        newState.form.open = false
        newState.wip = {}
        newState.addAnimationOn = false
      })

    case 'SAVE_BETA_DASHBOARD_SUCCESS':
      if (payload?.access !== 'PUBLIC') return state
      return produce(state, (newState) => {
        newState.byId[payload.id] = parseDashboard(payload)
        if (!state.allIds.includes(payload.id)) newState.allIds.push(payload.id)
        newState.addAnimationOn = false
      })

    case 'ADD_NEW_DASHBOARD_PANEL':
      return produce(state, (newState) => {
        if (!state.wip.panels) {
          // Isso pode ser necessário pois todos os layouts de painéis podem alterar ao incluir um painel novo
          newState.wip.panels = newState.current.panels // Initialize panels if it doesn't exist
        }
        const newPanelIndex =
          Math.max(Object.keys(state.current.panels || {}).length, Object.keys(state.wip.panels || {}).length) + 1

        const newPanel = initialPanel(newPanelIndex)

        newState.wip.panels[newPanel.id] = newPanel
      })

    case 'OPEN_PANEL_FORM':
      return produce(state, (newState) => {
        if (state.current.panels[payload.panelId]?.filters) {
          newState.form.isEditing = true
        } else {
          newState.form.isEditing = false
        }
        newState.form.open = true
        newState.form.type = 'filter'
        newState.panelIdForm = payload.panelId
        newState.form.steps.items = [
          {
            id: 'identification',
            icon: 'info',
            title: 'Identificação',
            description: 'Identifique o Painel',
            showFeedback: false,
            disabled: false,
          },
          {
            id: 'filters',
            icon: 'filter',
            title: 'Filtros',
            description: 'Selecione os filtros',
            showFeedback: false,
            disabled: false,
          },
          {
            id: 'time',
            icon: 'calendar',
            title: 'Intervalo',
            description: 'Selecione o Intervalo de Tempo',
            showFeedback: false,
            disabled: true,
          },
          {
            id: 'advanced',
            icon: 'setting',
            title: 'Avançado',
            description: 'Configuração do painel',
            showFeedback: false,
            disabled: true,
          },
        ]
      })

    case 'OPEN_DASHBOARD_FORM':
      return produce(state, (newState) => {
        newState.form.open = true
        newState.form.type = 'save'
        newState.form.isEditing = payload.isEditing
        if (!payload.isEditing) {
          newState.wip = {}
          newState.current = {}
        }
        newState.form.steps.items = [
          {
            id: 'identification',
            icon: 'info',
            title: 'Identificação',
            description: 'Identifique o dashboard',
            showFeedback: false,
            disabled: false,
          },
        ]
      })

    case 'FETCH_PANEL_RESULT_START':
      return produce(state, (newState) => {
        set(
          newState.panelsInfo.byId,
          `${payload.panelId}.label`,
          state.wip.panels?.[payload.panelId]?.label ?? state.current.panels?.[payload.panelId]?.label
        )
        set(newState.panelsInfo.byId, `${payload.panelId}.loading`, true)
      })

    case 'FETCH_PANEL_RESULT_SUCCESS':
      return produce(state, (newState) => {
        set(newState.panelsInfo.byId, `${payload.panelId}.result`, payload.data)
        set(newState.panelsInfo.byId, `${payload.panelId}.loading`, false)
      })

    case 'FETCH_PANEL_RESULT_FAILURE':
      return produce(state, (newState) => {
        set(newState.panelsInfo.byId, `${payload.panelId}.loading`, false)
      })

    case 'DELETE_DASHBOARD_PANEL':
      return produce(state, (newState) => {
        set(newState.wip, `panels.${payload.panelId}`, null)
      })

    case 'DELETE_DASHBOARD_START':
      return { ...state, loading: true }

    case 'DELETE_DASHBOARD_SUCCESS':
      if (!payload.ids) return state
      return produce(state, (newState) => {
        newState.allIds = newState.allIds.filter((each) => !payload.ids.includes(each))
        newState.byId = omit(newState.byId, [payload.ids])
        newState.loading = false
        newState.form.type = null
        newState.form.open = false
      })

    case 'DELETE_DASHBOARD_FAILURE':
      return { ...state, loading: false }

    case 'SET_PANEL_PROP':
      return produce(state, (newState) => {
        if (!state.wip.panels) {
          newState.wip.panels = {}
        }

        if (!newState.wip.panels[payload.panelId]) {
          newState.wip.panels[payload.panelId] = {
            id: state.current.panels[payload.panelId]?.id,
          }
        }

        set(
          newState.wip.panels[payload.panelId],
          payload.field,
          // Essa lógica existe para definir valores iniciais diferentes quando for alimentar o painel como um todo ou um filtro específico
          payload.value ?? (!payload.field ? { id: null, value: null } : null)
        )
      })

    default:
      return state
  }
}
