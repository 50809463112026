/*  Author: Bruno Melo
    Type: Smart
	Description: Map layers control, configuration and data;
    To do list: -
*/

import ReactLeafletGoogleLayer from './google'
import React from 'react'
import { connect } from 'react-redux'
import { TileLayer, LayersControl, FeatureGroup } from 'react-leaflet'
import { withTranslation } from 'react-i18next'
import HeatmapLayer from 'react-leaflet-heatmap-layer'
import getSafe from 'Utils/getSafe.js'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import {
  CardAssetsTotal,
  CardLifeTotal,
  CardRisksLegend,
  MapMethods,
  CardDevices,
  CardRefsTotal,
  CardFencesTotal,
  CardRoutesTotal,
} from 'Utils/components'
import { saveLoginMapLayer } from 'Store/actions/login-action'
import withWindowDimensions from 'Utils/hocs/withWindowDimensions'
import HolesCard from 'Utils/components/HolesCard'
import StretchesCard from 'Utils/components/StretchesCard'
//import styled from 'styled-components'

const { BaseLayer } = LayersControl

class MapLayersControl extends React.Component {
  state = {
    lan: '',
    fitBounds: false,
    showCard1Desc: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.i18n && props.i18n.language !== state.lan) {
      return {
        lan: props.i18n.language,
      }
    } else return null
  }

  componentDidMount(props) {
    const map = this.props.refMap
    map.on('overlayadd', (e) => {
      switch (e.name) {
        case 'Total de Ativos':
          this.props.saveLoginMapLayer('assetsCount', true)
          break
        case 'Total de Perímetros':
          this.props.saveLoginMapLayer('fencesCount', true)
          break
        case 'Total de Referências':
          this.props.saveLoginMapLayer('refsCount', true)
          break
        case 'Total de Rotas':
          this.props.saveLoginMapLayer('routesCount', true)
          break
        case 'Total de Vidas':
          this.props.saveLoginMapLayer('peopleCount', true)
          break
        case 'Total de Dispositivos':
          this.props.saveLoginMapLayer('devicesCount', true)
          break
        default:
          this.props.saveLoginMapLayer('fencesRisk', true)
          break
      }
      return
    })
    map.on('overlayremove', (e) => {
      switch (e.name) {
        case 'Total de Ativos':
          this.props.saveLoginMapLayer('assetsCount', false)
          break
        case 'Total de Perímetros':
          this.props.saveLoginMapLayer('fencesCount', false)
          break
        case 'Total de Referências':
          this.props.saveLoginMapLayer('refsCount', false)
          break
        case 'Total de Rotas':
          this.props.saveLoginMapLayer('routesCount', false)
          break
        case 'Total de Vidas':
          this.props.saveLoginMapLayer('peopleCount', false)
          break
        case 'Total de Dispositivos':
          this.props.saveLoginMapLayer('devicesCount', false)
          break
        default:
          this.props.saveLoginMapLayer('fencesRisk', false)
          break
      }
      return
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.hmPoints.length > 0 && prevProps.hmPoints.length === 0) {
      this.setState({ fitBounds: true })
    }
  }

  render() {
    const {
      t,
      showInnerMap,
      hasIndoor,
      hmPoints,
      currentInner,
      histType,
      loginMapLayers,
      isMobileSized,
      histHoles,
      histHolesLines,
    } = this.props
    let hmPoints2 =
      this.props.showInnerMap && hmPoints
        ? hmPoints.map((e) => {
            let posVirada = innerPosition(e, 'array', currentInner?.center, currentInner?.bearing)
            return [posVirada[0], posVirada[1], e[2], e[3]]
          })
        : hmPoints

    return (
      <LayersControl position="bottomright">
        {(!hasIndoor || !showInnerMap) && (
          <BaseLayer checked={!showInnerMap} name={'Satélite' /*t('TileLayerSatStr')*/}>
            <ReactLeafletGoogleLayer
              // #googleApi #googleToken #googleKey
              googleMapsLoaderConf={{ KEY: 'AIzaSyBCMCvfz5SLCl5RV4Kko235D6TWocLh6TE', VERSION: 'quarterly' }}
              type="satellite"
              maxZoom={21}
            />
          </BaseLayer>
        )}
        {/*(!hasIndoor || !showInnerMap) && (
          <BaseLayer checked={!showInnerMap} name="Google">
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{ KEY: 'AIzaSyDTQg6rdvOKLMvR_bpGNQqNnOfVB7hfy3M' }}
              type={'satellite'}
            />
          </BaseLayer>
        )*/}
        {(!hasIndoor || !showInnerMap) && (
          <BaseLayer name={'Satélite 2' /*t('TileLayerSat')*/}>
            <TileLayer
              // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
              maxZoom={40}
              tileSize={512}
              zoomOffset={-1}
              id="mapbox/satellite-v9"
              accessToken="pk.eyJ1IjoiYmxhcnUiLCJhIjoiY2pjYzA1ZW1vMGFrbjJ4bmR2MjN5NzNiZyJ9.MLwDe_Kz7ZE3sCc6cYbPNA"
            />
          </BaseLayer>
        )}
        {/*(!hasIndoor || !showInnerMap) && (
          <BaseLayer name={t('TileLayerStr')}>
            <TileLayer
              //attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
              maxZoom={40}
              tileSize={512}
              zoomOffset={-1}
              id="mapbox/streets-v11"
              accessToken="pk.eyJ1IjoiYmxhcnUiLCJhIjoiY2pjYzA1ZW1vMGFrbjJ4bmR2MjN5NzNiZyJ9.MLwDe_Kz7ZE3sCc6cYbPNA"
            />
          </BaseLayer>
        )*/}
        {(!hasIndoor || !showInnerMap) && (
          <BaseLayer name={'Mapa' /*t('TileLayerOutdoors')*/}>
            <TileLayer
              // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
              maxZoom={40}
              tileSize={512}
              zoomOffset={-1}
              id="mapbox/outdoors-v11"
              accessToken="pk.eyJ1IjoiYmxhcnUiLCJhIjoiY2pjYzA1ZW1vMGFrbjJ4bmR2MjN5NzNiZyJ9.MLwDe_Kz7ZE3sCc6cYbPNA"
            />
          </BaseLayer>
        )}
        {(!hasIndoor || !showInnerMap) && (
          <BaseLayer name={'Mapa ' + t('TileLayerLight')}>
            <TileLayer
              // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
              maxZoom={40}
              tileSize={512}
              zoomOffset={-1}
              id="mapbox/light-v10"
              accessToken="pk.eyJ1IjoiYmxhcnUiLCJhIjoiY2pjYzA1ZW1vMGFrbjJ4bmR2MjN5NzNiZyJ9.MLwDe_Kz7ZE3sCc6cYbPNA"
            />
          </BaseLayer>
        )}
        {(!hasIndoor || !showInnerMap) && (
          <BaseLayer name={'Mapa ' + t('TileLayerDark')}>
            <TileLayer
              //attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
              maxZoom={40}
              tileSize={512}
              zoomOffset={-1}
              id="mapbox/dark-v10"
              accessToken="pk.eyJ1IjoiYmxhcnUiLCJhIjoiY2pjYzA1ZW1vMGFrbjJ4bmR2MjN5NzNiZyJ9.MLwDe_Kz7ZE3sCc6cYbPNA"
            />
          </BaseLayer>
        )}
        {hasIndoor && showInnerMap && (
          <BaseLayer name="Planta" checked={showInnerMap}>
            <TileLayer
              noWrap
              //attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              // url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
              url="https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/indoor/solid-color-image.png"
              maxZoom={23}
              minZoom={15}
              // bounds={[[-23.326711, -46.397887], [-23.327191, -46.396719]]}
            />
          </BaseLayer>
        )}
        {hmPoints2 && histType === 'heatmap' && (
          <FeatureGroup key="heatMap fg" color="purple">
            <HeatmapLayer
              points={hmPoints2}
              minOpacity={0.1}
              maxOpacity={0.3}
              radius={30}
              blur={40}
              max={3}
              maxZoom={17}
              longitudeExtractor={(m) => m.lng}
              latitudeExtractor={(m) => m.lat}
              intensityExtractor={() => 1}
            />
            {this.state.fitBounds && (
              <MapMethods type="heatmap" fit={hmPoints2} cb={() => this.setState({ fitBounds: false })} />
            )}
          </FeatureGroup>
        )}

        {['Tiplam', 'TiplamTest'].includes(this.props.empresa) && (
          <LayersControl.Overlay name={`Risco de perímetros`} checked={loginMapLayers?.includes('fencesRisk')}>
            <FeatureGroup>
              {loginMapLayers?.includes('fencesRisk') && <CardRisksLegend isMobileSized={isMobileSized} />}
            </FeatureGroup>
          </LayersControl.Overlay>
        )}

        <LayersControl.Overlay name="Total de Ativos" checked={loginMapLayers?.includes('assetsCount')}>
          <FeatureGroup>
            {!isMobileSized && loginMapLayers?.includes('assetsCount') && <CardAssetsTotal />}
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Total de Referências" checked={loginMapLayers?.includes('refsCount')}>
          <FeatureGroup>{!isMobileSized && loginMapLayers?.includes('refsCount') && <CardRefsTotal />}</FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Total de Perímetros" checked={loginMapLayers?.includes('fencesCount')}>
          <FeatureGroup>
            {!isMobileSized && loginMapLayers?.includes('fencesCount') && <CardFencesTotal />}
          </FeatureGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Total de Rotas" checked={loginMapLayers?.includes('routesCount')}>
          <FeatureGroup>
            {!isMobileSized && loginMapLayers?.includes('routesCount') && <CardRoutesTotal />}
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Total de Vidas" checked={loginMapLayers?.includes('peopleCount')}>
          <FeatureGroup>{!isMobileSized && loginMapLayers?.includes('peopleCount') && <CardLifeTotal />}</FeatureGroup>
        </LayersControl.Overlay>

        {['Tiplam', 'TiplamTest'].includes(this.props.empresa) && (
          <LayersControl.Overlay name="Total de Dispositivos" checked={loginMapLayers?.includes('devicesCount')}>
            <FeatureGroup>
              {!isMobileSized && loginMapLayers?.includes('devicesCount') && (
                <CardDevices otherCard={loginMapLayers?.includes('peopleCount')} company={this.props.empresa} />
              )}
            </FeatureGroup>
          </LayersControl.Overlay>
        )}
        {histHoles.length > 0 && (
          <LayersControl.Overlay name="Total de Buracos" checked>
            <FeatureGroup>
              <HolesCard />
            </FeatureGroup>
          </LayersControl.Overlay>
        )}
        {histHolesLines && histType === 'holesLines' && (
          <LayersControl.Overlay name="Total de Trechos" checked>
            <FeatureGroup>
              <StretchesCard />
            </FeatureGroup>
          </LayersControl.Overlay>
        )}
      </LayersControl>
    )
    //}
    //else return null
  }
}
const mapStateToProps = (state) => ({
  hmPoints: state.heatmap.points,
  showInnerMap: state.toggle.showInnerMap,
  hasIndoor: getSafe(() => state.indoor.items.length > 0, false),
  currentInner: state.indoor.current?.inner,
  empresa: state.login.empresa,
  refMap: state.login.refMap,
  histType: state.history.type,
  loginMapLayers: state.login.preferences.mapLayers,
  histHoles: state.history.holes.items,
  histHolesLines: state.history?.holesLines ? true : false,
})

const mapDispatchToProps = {
  saveLoginMapLayer,
}

export default withWindowDimensions(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MapLayersControl)))
//withLeaflet(withTranslation()(connect(mapStateToProps)(MapLayersControl)))
