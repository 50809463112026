import React from 'react'
import { Segment, Modal, Header, Image } from 'semantic-ui-react'

import { useTranslation } from 'react-i18next'
//import ModalFilter from 'Utils/modals/ModalFilter.js'
import MenuTableIcons from 'Utils/reactTable/components/MenuTableIcons.jsx'
import ModalClock from 'Utils/components/ModalClock.jsx'
import styled from 'styled-components'
import 'Components/Header/header.css'
import { getPeopleHist } from 'Apis/csvFromQuery'
/*import { exportFirefightersReport } from 'Store/actions/assets-action'
import { m } from 'moment'*/

const ModalHeaderFirefighter = ({
  dashboard,
  dashboardLabel,
  insightActions,
  insightActionsValues,
  fullscreenMode,
}) => {
  //const empresa = useSelector((state) => state.login.empresa)
  //const dashboardId = useSelector((state) => state.dashboards.currentId)
  const vliLogo = 'https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/VLIPAV.png'
  const { t } = useTranslation()
  const Logo = styled('div')`
    padding-right: 70px;
    padding-left: 70px;
    text-align: center;
    min-height: 45px;
    min-width: 201px;
    max-height: ${fullscreenMode ? '2vh' : '100px'};
    max-width: ${fullscreenMode ? 'auto' : '446px'};
    position: relative;
    top: -12px;
    right: -24px;
  `

  const FSHeader = styled(Modal.Header)`
    border-bottom: 1px solid #c8ced3;
    background: #fff;
    display: block;
  `

  return (
    <FSHeader style={{ padding: '0' }}>
      <Segment.Group horizontal style={{ margin: '0', border: 'none', boxShadow: 'none', padding: '0' }}>
        <Segment style={{ maxWidth: '43.5vw' }}>
          <Header as="h2">{dashboardLabel ? dashboardLabel : t('')}</Header>
        </Segment>

        {fullscreenMode && (
          <Logo>
            <Image src={vliLogo} size="medium" verticalAlign="middle" />
          </Logo>
        )}

        {
          <MenuTableIcons
            dashboard={dashboard}
            t={t}
            table={'firefighter'}
            exportAction={async () => getPeopleHist('brigadista', 'month')}
            insightActions={insightActions}
            insightActionsValues={insightActionsValues}
            refresh={true}
          />
        }

        {<ModalClock fullscreenMode={fullscreenMode} />}
      </Segment.Group>
    </FSHeader>
  )
}

export default ModalHeaderFirefighter
