import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { ReactComponent as RV } from '../images/RV-icon.svg'
import { ReactComponent as Worker } from '../images/worker.svg'
import { ReactComponent as Fireman } from '../images/fireman.svg'
import { ReactComponent as Car } from '../images/car.svg'
import { ReactComponent as Driver } from '../images/driver.svg'
import { ReactComponent as DriverLocomotive } from '../images/driverLocomotive.svg'
import { ReactComponent as Authority } from '../images/authority.svg'
import { ReactComponent as Authority2 } from '../images/authority2.svg'
import { ReactComponent as Authority3 } from '../images/authority3.svg'
import { ReactComponent as Fireman2 } from '../images/fireman-2.svg'
import { ReactComponent as Mariner } from '../images/mariner.svg'
import { ReactComponent as Mariner2 } from '../images/mariner-2.svg'
import { ReactComponent as Trucker } from '../images/trucker.svg'
import { ReactComponent as Visitor } from '../images/visitor.svg'
import { ReactComponent as Visitor2 } from '../images/visitor-2.svg'
import { ReactComponent as Workervli } from '../images/workervli.svg'
import { ReactComponent as Device } from '../images/device.svg'
import { ReactComponent as DeviceFull } from '../images/subtitles_on_use.svg'
import { ReactComponent as DeviceUnknown } from '../images/subtitles_unkonw.svg'
import { ReactComponent as DeviceCharging } from '../images/subtitles_charging.svg'
import { ReactComponent as DeviceDischarge } from '../images/subtitles_charged.svg'
import { ReactComponent as Totem } from '../images/totem.svg'
import { semanticColors, lightColors } from 'Utils/colorsFormat'
import {
  FaBullseye,
  FaBusAlt,
  FaCircle,
  FaShuttleVan,
  FaFireExtinguisher,
  FaToolbox,
  FaTruck,
  FaTruckMoving,
  FaUtensils,
  FaCarCrash,
  FaIdBadge,
  FaRegIdBadge,
  FaMotorcycle,
} from 'react-icons/fa'
import {
  AiOutlineToTop,
  AiFillFile,
  AiOutlineSafety,
  AiOutlineWarning,
  AiOutlineExperiment,
  AiFillTool,
  AiFillStar,
  AiFillAlert,
  AiFillApi,
  AiFillBank,
  AiFillBell,
  AiFillBulb,
  AiFillCar,
  AiFillCamera,
  AiFillFlag,
  AiFillHome,
  AiFillLock,
  AiFillMedicineBox,
  AiFillReconciliation,
  AiFillShop,
  AiFillThunderbolt,
} from 'react-icons/ai'
import { GoDeviceMobile } from 'react-icons/go'
import { HiNoSymbol } from 'react-icons/hi2'
import {
  TbBusStop,
  TbDeviceWatchStats2,
  TbDeviceWatchStats,
  TbFall,
  TbLadder,
  TbLetterA,
  TbLetterB,
  TbLetterC,
  TbLetterD,
  TbLetterE,
  TbLetterF,
  TbLetterG,
  TbLetterH,
  TbLetterI,
  TbLetterJ,
  TbLetterK,
  TbLetterL,
  TbLetterM,
  TbLetterN,
  TbLetterO,
  TbLetterP,
  TbLetterQ,
  TbLetterR,
  TbLetterS,
  TbLetterT,
  TbLetterU,
  TbLetterV,
  TbLetterW,
  TbLetterX,
  TbLetterY,
  TbLetterZ,
} from 'react-icons/tb'
import {
  MdDeviceUnknown,
  MdSpeed,
  MdBadge,
  MdOutlineBadge,
  MdQrCode2,
  MdOutlineDeviceThermostat,
  MdCellTower,
  MdOutlineOnDeviceTraining,
  MdForklift,
  MdDoNotDisturbAlt,
  MdDone,
} from 'react-icons/md'
import { GrGateway } from 'react-icons/gr'
import {
  GiAerialSignal,
  GiFalling,
  GiInterdiction,
  GiForklift,
  GiTruck,
  GiTrafficLightsReadyToGo,
  GiEntryDoor,
  GiExitDoor,
  GiSpeedometer,
  Gi3DStairs,
  GiHoleLadder,
  GiTurnstile,
  GiImpactPoint,
} from 'react-icons/gi'
import { IoTrainSharp, IoTrainOutline, IoWarning } from 'react-icons/io5'
import { BsDiscFill, BsDisc } from 'react-icons/bs'
import { BiDisc } from 'react-icons/bi'

export const SvgIcon = ({ name, color, paddingBottom = '10px', height = '37.5px', width = '27.5px' }) => {
  const tenant = useSelector((state) => state.login.empresa, shallowEqual)
  const checkColor = (color) => ([].includes(tenant) ? lightColors(color) : semanticColors(color))
  let suiColor = checkColor(color)

  const result = () => {
    const styleDefault = {
      paddingBottom: paddingBottom || '10px',
      maxHeight: height || '37.5px',
      maxWidth: width || '27.5px',
      minHeight: 17,
      fill: suiColor,
    }
    switch (name) {
      case 'RV':
        return <RV fill={suiColor} style={styleDefault} />
      case 'worker':
        return <Worker fill={suiColor} style={styleDefault} />
      case 'fireman':
        return <Fireman fill={suiColor} style={styleDefault} />
      case 'car':
        return <Car fill={suiColor} style={styleDefault} />
      case 'authority':
        return <Authority fill={suiColor} style={styleDefault} />
      case 'authority2':
        return <Authority2 fill={suiColor} style={styleDefault} />
      case 'authority3':
        return <Authority3 fill={suiColor} style={styleDefault} />
      case 'driver':
        return <Driver fill={suiColor} style={styleDefault} />
      case 'driverLocomotive':
        return <DriverLocomotive fill={suiColor} style={styleDefault} />
      case 'fireman2':
        return <Fireman2 fill={suiColor} style={styleDefault} />
      case 'mariner':
        return <Mariner fill={suiColor} style={styleDefault} />
      case 'mariner2':
        return <Mariner2 fill={suiColor} style={styleDefault} />
      case 'trucker':
        return <Trucker fill={suiColor} style={styleDefault} />
      case 'visitor':
        return <Visitor fill={suiColor} style={styleDefault} />
      case 'visitor2':
        return <Visitor2 fill={suiColor} style={styleDefault} />
      case 'workervli':
        return <Workervli fill={suiColor} style={styleDefault} />
      case 'device':
        return <Device fill={suiColor} style={styleDefault} />
      case 'device-full':
        return <DeviceFull fill={suiColor} style={styleDefault} />
      case 'device-unknown':
        return <DeviceUnknown fill={suiColor} style={styleDefault} />
      case 'device-charging':
        return <DeviceCharging fill={suiColor} style={styleDefault} />
      case 'device-discharge':
        return <DeviceDischarge fill={suiColor} style={styleDefault} />
      case 'totem':
        return <Totem fill={suiColor} style={styleDefault} />
      case 'AiOutlineToTop':
        return <AiOutlineToTop size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillFile':
        return <AiFillFile size={22} fill={suiColor} stroke={suiColor} />
      case 'AiOutlineSafety':
        return <AiOutlineSafety size={22} fill={suiColor} stroke={suiColor} />
      case 'AiOutlineWarning':
        return <AiOutlineWarning size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillAlert':
        return <AiFillAlert size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillApi':
        return <AiFillApi size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillBank':
        return <AiFillBank size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillBell':
        return <AiFillBell size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillBulb':
        return <AiFillBulb size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillCar':
        return <AiFillCar size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillCamera':
        return <AiFillCamera size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillFlag':
        return <AiFillFlag size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillHome':
        return <AiFillHome size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillLock':
        return <AiFillLock size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillMedicineBox':
        return <AiFillMedicineBox size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillReconciliation':
        return <AiFillReconciliation size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillShop':
        return <AiFillShop size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillThunderbolt':
        return <AiFillThunderbolt size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillStar':
        return <AiFillStar size={22} fill={suiColor} stroke={suiColor} />
      case 'AiFillTool':
        return <AiFillTool size={22} fill={suiColor} stroke={suiColor} />
      case 'AiOutlineExperiment':
        return <AiOutlineExperiment size={22} fill={suiColor} stroke={suiColor} />
      case 'bullseye':
        return <FaBullseye size={22} fill={suiColor} stroke={suiColor} />
      case 'FaBullseye':
        return <FaBullseye size={22} fill={suiColor} stroke={suiColor} />
      case 'FaBusAlt':
        return <FaBusAlt size={22} fill={suiColor} stroke={suiColor} />
      case 'FaCircle':
        return <FaCircle size={22} fill={suiColor} stroke={suiColor} />
      case 'FaShuttleVan':
        return <FaShuttleVan size={22} fill={suiColor} stroke={suiColor} />
      case 'FaFireExtinguisher':
        return <FaFireExtinguisher size={22} fill={suiColor} stroke={suiColor} />
      case 'FaToolbox':
        return <FaToolbox size={22} fill={suiColor} stroke={suiColor} />
      case 'FaTruck':
        return <FaTruck size={22} fill={suiColor} stroke={suiColor} />
      case 'FaTruckMoving':
        return <FaTruckMoving size={22} fill={suiColor} stroke={suiColor} />
      case 'FaUtensils':
        return <FaUtensils size={22} fill={suiColor} stroke={suiColor} />
      case 'FaCarCrash':
        return <FaCarCrash size={22} fill={suiColor} stroke={suiColor} />
      case 'FaIdBadge':
        return <FaIdBadge size={22} fill={suiColor} stroke={suiColor} />
      case 'FaRegIdBadge':
        return <FaRegIdBadge size={22} fill={suiColor} stroke={suiColor} />
      case 'FaMotorcycle':
        return <FaMotorcycle size={22} fill={suiColor} stroke={suiColor} />
      case 'GoDeviceMobile':
        return <GoDeviceMobile size={22} fill={suiColor} stroke={suiColor} />
      case 'TbBusStop':
        return <TbBusStop size={22} fill={suiColor} stroke={suiColor} />
      case 'TbDeviceWatchStats2':
        return <TbDeviceWatchStats2 size={22} fill={suiColor} stroke={suiColor} />
      case 'TbDeviceWatchStats':
        return <TbDeviceWatchStats size={22} fill={suiColor} stroke={suiColor} />
      case 'TbFall':
        return <TbFall size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLadder':
        return <TbLadder size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterA':
        return <TbLetterA size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterB':
        return <TbLetterB size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterC':
        return <TbLetterC size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterD':
        return <TbLetterD size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterE':
        return <TbLetterE size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterF':
        return <TbLetterF size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterG':
        return <TbLetterG size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterH':
        return <TbLetterH size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterI':
        return <TbLetterI size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterJ':
        return <TbLetterJ size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterK':
        return <TbLetterK size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterL':
        return <TbLetterL size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterM':
        return <TbLetterM size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterN':
        return <TbLetterN size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterO':
        return <TbLetterO size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterP':
        return <TbLetterP size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterQ':
        return <TbLetterQ size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterR':
        return <TbLetterR size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterS':
        return <TbLetterS size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterT':
        return <TbLetterT size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterU':
        return <TbLetterU size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterV':
        return <TbLetterV size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterW':
        return <TbLetterW size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterX':
        return <TbLetterX size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterY':
        return <TbLetterY size={22} fill={suiColor} stroke={suiColor} />
      case 'TbLetterZ':
        return <TbLetterZ size={22} fill={suiColor} stroke={suiColor} />
      case 'MdDeviceUnknown':
        return <MdDeviceUnknown size={22} fill={suiColor} stroke={suiColor} />
      case 'MdSpeed':
        return <MdSpeed size={22} fill={suiColor} stroke={suiColor} />
      case 'MdBadge':
        return <MdBadge size={22} fill={suiColor} stroke={suiColor} />
      case 'MdOutlineBadge':
        return <MdOutlineBadge size={22} fill={suiColor} stroke={suiColor} />
      case 'MdQrCode2':
        return <MdQrCode2 size={22} fill={suiColor} stroke={suiColor} />
      case 'MdOutlineDeviceThermostat':
        return <MdOutlineDeviceThermostat size={22} fill={suiColor} stroke={suiColor} />
      case 'MdCellTower':
        return <MdCellTower size={22} fill={suiColor} stroke={suiColor} />
      case 'MdOutlineOnDeviceTraining':
        return <MdOutlineOnDeviceTraining size={22} fill={suiColor} stroke={suiColor} />
      case 'MdForklift':
        return <MdForklift size={22} fill={suiColor} stroke={suiColor} />
      case 'MdDoNotDisturbAlt':
        return <MdDoNotDisturbAlt size={22} fill={suiColor} stroke={suiColor} />
      case 'MdDone':
        return <MdDone size={22} fill={suiColor} stroke={suiColor} />
      case 'BsDiscFill':
        return <BsDiscFill size={22} fill={suiColor} stroke={suiColor} />
      case 'BsDisc':
        return <BsDisc size={22} fill={suiColor} stroke={suiColor} />
      case 'BiDisc':
        return <BiDisc size={22} fill={suiColor} stroke={suiColor} />
      case 'GrGateway':
        return <GrGateway size={22} fill={suiColor} stroke={suiColor} />
      case 'GiAerialSignal':
        return <GiAerialSignal size={22} fill={suiColor} stroke={suiColor} />
      case 'GiFalling':
        return <GiFalling size={22} fill={suiColor} stroke={suiColor} />
      case 'GiInterdiction':
        return <GiInterdiction size={22} fill={suiColor} stroke={suiColor} />
      case 'GiForklift':
        return <GiForklift size={22} fill={suiColor} stroke={suiColor} />
      case 'GiTruck':
        return <GiTruck size={22} fill={suiColor} stroke={suiColor} />
      case 'GiTrafficLightsReadyToGo':
        return <GiTrafficLightsReadyToGo size={22} fill={suiColor} stroke={suiColor} />
      case 'GiEntryDoor':
        return <GiEntryDoor size={22} fill={suiColor} stroke={suiColor} />
      case 'GiExitDoor':
        return <GiExitDoor size={22} fill={suiColor} stroke={suiColor} />
      case 'GiSpeedometer':
        return <GiSpeedometer size={22} fill={suiColor} stroke={suiColor} />
      case 'Gi3DStairs':
        return <Gi3DStairs size={22} fill={suiColor} stroke={suiColor} />
      case 'GiHoleLadder':
        return <GiHoleLadder size={22} fill={suiColor} stroke={suiColor} />
      case 'GiTurnstile':
        return <GiTurnstile size={22} fill={suiColor} stroke={suiColor} />
      case 'GiImpactPoint':
        return <GiImpactPoint size={22} fill={suiColor} stroke={suiColor} />
      case 'IoTrainSharp':
        return <IoTrainSharp size={22} fill={suiColor} stroke={suiColor} />
      case 'IoTrainOutline':
        return <IoTrainOutline size={22} fill={suiColor} stroke={suiColor} />
      case 'IoWarning':
        return <IoWarning size={22} fill={suiColor} stroke={suiColor} />
      case 'HiNoSymbol':
        return <HiNoSymbol size={22} fill={suiColor} stroke={suiColor} />
      default:
        return <FaCircle size={22} fill={suiColor} stroke={suiColor} />
    }
  }

  return result()
}

export default SvgIcon
