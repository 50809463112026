import React from 'react'
import { Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux' // Adjust the import according to your project structure
import { useTranslation } from 'react-i18next'
import { changeLoginPreferences } from 'Store/actions/login-action'

interface SideFormHideActionProps {
  id: string
  moduleType: string
}

const SideFormHideAction: React.FC<SideFormHideActionProps> = ({ id, moduleType }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const invisible = useSelector((state: any) => state.login.preferences[moduleType]?.[id]?.invisible)

  const handleClick = () => {
    dispatch(
      changeLoginPreferences({
        field: moduleType,
        preferences: { [id]: { id, invisible: !invisible } },
      })
    )
  }

  return (
    <Button
      circular
      icon={!invisible ? 'eye' : 'eye slash'}
      title={t(!invisible ? 'EyesHide' : 'EyesShow')}
      style={{
        cursor: 'pointer',
      }}
      onClick={handleClick}
    />
  )
}

export default SideFormHideAction
