import React, { PureComponent } from 'react'
import { Input, Form, Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import dataGroup from './dataGroup'
import NotificationProfilesForm from './NotificationProfilesForm'
import RulesForm from './rule/RulesForm'
import UnitsForm from './UnitsForm'
import AssetsForm from './AssetsForm'
import SimulationStepForm from './simulations/SimulationStepForm'
import NewsForm from './NewsForm'
import { useTranslation } from 'react-i18next'
import MessagesForm from './MessagesForm'
import DeviceProvisionForm from './DeviceProvisionForm'
import DataLabelingsForm from '../../../Components/forms/dataLabelings/DataLabelingForm'
import ActivitiesForm from '../../../Components/forms/activities/ActivitiesForm'
import MensurationForm from 'Components/forms/mensurations/MensurationsForm'
import ReportsForm from 'Components/forms/reports/ReportsForm'
import ProfilesForm from 'Components/forms/profiles/ProfilesForm'

const GenericForm = ({ handleCancel, handleSave, handleChange, addOpen, context, table, confirmAction }) => {
  const { t } = useTranslation()
  return (
    <ModalTemplate
      onClose={handleCancel}
      open={addOpen}
      size="small"
      header={confirmAction === 'insert' ? `Adicionar ${t(table)}` : <span>{`Editar ${t(table)}`}</span>}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Form>
        {dataGroup[context]?.[0].fields.map(
          (p) =>
            p && (
              <Form.Field key={'FF' + p} inline required>
                <label>{t(p)}</label>
                {dataGroup[context]?.[0]?.options?.[p] ? (
                  <Dropdown
                    placeholder=""
                    fluid
                    search
                    selection
                    options={dataGroup[context]?.[0]?.options?.[p]}
                    onChange={(e, { value }) => handleChange(p, value)}
                  />
                ) : (
                  <Input name={p} fluid onChange={(e, { name, value }) => handleChange(name, value)} />
                )}
              </Form.Field>
            )
        )}
      </Form>
    </ModalTemplate>
  )
}

class NewItemRT extends PureComponent {
  state = dataGroup?.[this.props.context]?.[0]?.initState || {
    id: '',
    name: '',
    description: '',
    type: '',
    minutes: 0,
    sourceAssetId: null,
    moduleId: null,
    scope: null,
    checkForm: false,
    criticality: null,
    message: '{{ação}} de Usuário {{crachá}} no(a) {{cerca.nome}}',
    status: 'on',
    createdBy: '',
    createdAt: '',
  }

  handleSave = async () => {
    const { context } = this.props
    if (context === 'rules') this.setState({ checkForm: true })
  }

  handleCancel = () => {
    this.clearState()
    this.props.setAddOpen(false)
  }

  handleChange = (field, value) => {
    this.setState({ [field]: value })
  }

  handleUpdate(id) {
    let fields = [
      'index',
      'name',
      'type',
      'description',
      'sourceAssetId',
      'scope',
      'moduleId',
      'action',
      'sendTo',
      'criticality',
      'message',
      'status',
      'createdBy',
      'createdAt',
    ]
    let value = ''
    fields.forEach((field) => {
      value = this.props.rules.byId?.[id]?.[field]
      this.setState({ [field]: value })
    })
    this.props.setNewClick(false)
  }

  clearState() {
    this.setState({ checkForm: false })
    let fields = [
      'index',
      'name',
      'type',
      'description',
      'sourceAssetId',
      'scope',
      'moduleId',
      'action',
      'sendTo',
      'criticality',
      'message',
      'status',
      'createdBy',
      'createdAt',
    ]
    let value = ''
    fields.forEach((field) => {
      this.setState({ [field]: value })
    })
    this.props.setNewClick(false)
  }

  handleSubmit = () => {
    const { name, email } = this.state

    this.setState({ submittedName: name, submittedEmail: email })
  }

  componentDidUpdate(prevProps, prevState) {
    const { ids, newClick, confirmAction, insertAction } = this.props
    if (confirmAction === 'update' && newClick) this.handleUpdate(ids[0])
    if (this.state.checkForm) {
      if (
        Object.entries(this.state).filter(
          (e) => (e[1] === null || e[1] === '') && ['index', 'name', 'type', 'scope'].includes(e[0])
        ).length === 0
      ) {
        if (this.props.context !== 'rules') insertAction()
        this.clearState()
        this.props.setAddOpen(false)
      }
    }
  }

  render() {
    const { addOpen, table, confirmAction, ids, context, tableLabel } = this.props

    if (!addOpen) {
      return null
    }

    const formProps = {
      handleCancel: this.handleCancel,
      handleSave: this.handleSave,
      handleChange: this.handleChange,
      addOpen,
      setAddOpen: this.props.setAddOpen,
      context,
      confirmAction,
      table,
      tableLabel,
      editId: ids?.[0] || '',
    }

    switch (context) {
      case 'simulations':
        return <SimulationStepForm {...formProps} />
      case 'news':
        return <NewsForm {...formProps} />
      case 'messages':
        return <MessagesForm {...formProps} />
      case 'notificationProfiles':
        return <NotificationProfilesForm {...formProps} />
      case 'units':
        return <UnitsForm {...formProps} />
      case 'devices':
        return <DeviceProvisionForm {...formProps} ids={ids} />
      case 'assets':
      case 'people':
        return (
          <AssetsForm {...formProps} insertAction={this.props.insertAction} updateAction={this.props.updateAction} />
        )
      case 'types':
        return <ProfilesForm />
      case 'dataLabelings':
        return <DataLabelingsForm />
      case 'rules':
        return (
          <RulesForm
            addOpen={addOpen}
            confirmAction={confirmAction}
            handleCancel={this.handleCancel}
            handleChange={this.handleChange}
            editId={ids?.[0]}
          />
        )
      case 'activities':
        return <ActivitiesForm />

      case 'mensurations':
        return <MensurationForm />

      case 'betaReports':
        return <ReportsForm />

      default:
        return <GenericForm {...formProps} />
    }
  }
}

const mapStateToProps = (state) => ({
  login: state.login.email,
  rules: state.rules,
})

export default connect(mapStateToProps)(NewItemRT)
