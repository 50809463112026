import React from 'react'
import { Form, Input, Label } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { setActivitiesProp } from 'Store/actions/activities-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import FormGroup from '../FormGroup'
import { useTranslation } from 'react-i18next'

const ActivitiesIdentificationForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentStep = useSelector((state: RootStore) => state.activities.form?.steps?.current)
  const name = useSelector((state: RootStore) => state.activities.wip?.name)
  const code = useSelector((state: RootStore) => state.activities.wip?.code)
  const description = useSelector((state: RootStore) => state.activities.wip?.description)

  const formErrors = useObjSelector((state: RootStore) => state.activities.form?.errors?.[currentStep])
  const showFeedback = useSelector((state: RootStore) => state.activities.form?.steps.items[currentStep].showFeedback)

  return (
    <>
      <FormGroup widths="equal">
        <Form.Field inline error={showFeedback && !!formErrors?.code}>
          <label>Código</label>
          <Input
            name="code"
            fluid
            placeholder="Digite o código do PDT"
            value={code}
            onChange={(e, { name, value }) => {
              dispatch(setActivitiesProp(`wip.${name}`, value))
            }}
          />
          {showFeedback && formErrors?.code?.[0] && formErrors?.code?.[0] !== 'Required' && (
            <Label color="red" pointing>
              {/* @ts-ignore */}
              {t(formErrors?.code?.[0])}
            </Label>
          )}
        </Form.Field>
        <Form.Field inline required error={showFeedback && !!formErrors?.name}>
          <label>Nome</label>
          <Input
            name="name"
            fluid
            placeholder="Nomeie a nova entidade"
            value={name}
            onChange={(e, { name, value }) => {
              dispatch(setActivitiesProp(`wip.${name}`, value))
            }}
          />
          {showFeedback && formErrors?.name?.[0] && formErrors?.name?.[0] !== 'Required' && (
            <Label color="red" pointing>
              {/* @ts-ignore */}
              {t(formErrors?.name?.[0])}
            </Label>
          )}
        </Form.Field>
      </FormGroup>
      <Form.Field required={false}>
        <label>{t('description')}</label>
        <Form.TextArea
          name={'description'}
          fluid
          placeholder="Descreva a nova atividade"
          value={description ?? ''}
          onChange={(e, { name, value }) => {
            dispatch(setActivitiesProp(`wip.${name}`, value))
          }}
        />
      </Form.Field>
    </>
  )
}

export default ActivitiesIdentificationForm
