import React, { useState, useMemo } from 'react'
import { Icon, Card, Grid, Header, Divider } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedCard } from 'Store/actions/dashboards-action'

const FencesDashboard = () => {
  const dispatch = useDispatch()

  const [showCards, setShowCards] = useState(true)

  const allActivePeopleIdsStr = useSelector((state) => JSON.stringify(state.assets.allActivePeopleIds))
  const measuresStr = useSelector((state) => JSON.stringify(state.measures.byId))
  const fencesStr = useSelector((state) => JSON.stringify(state.modules.fences))
  const dashboardFilter = useSelector((state) => state.dashboards.filter)
  const selected = useSelector((state) => state.dashboards.byId['fences'].selected)
  const assetTypesStr = useSelector((state) => JSON.stringify(state.profiles))

  const groupState = useMemo(() => {
    const measures = JSON.parse(measuresStr)
    const fences = JSON.parse(fencesStr)
    const allActivePeopleIds = JSON.parse(allActivePeopleIdsStr)
    const assetTypes = JSON.parse(assetTypesStr)

    let fenceInfo = {}
    fences.allIds.forEach((fenceId) => {
      const eachFence = fences.byId[fenceId]
      fenceInfo[fenceId] = {
        name: eachFence?.label,
        count: 0,
        color: assetTypes.byId[eachFence.profile]?.color || 'blue',
        id: fenceId,
      }
    })
    Object.entries(measures)
      .filter((e) => {
        return allActivePeopleIds.includes(e[0])
      })
      .forEach((each) => {
        const locationAnalytics = each[1]?.info?.fencesLocation
        if (locationAnalytics) {
          Object.keys(locationAnalytics).forEach((eachLoc) => {
            if (fenceInfo[eachLoc]) {
              fenceInfo[eachLoc].count += 1
            }
          })
        }
      })
    return fenceInfo
  }, [measuresStr, fencesStr, allActivePeopleIdsStr, assetTypesStr])

  return (
    <>
      <Grid columns="equal">
        <Grid.Column verticalAlign="bottom" style={{ cursor: 'pointer' }}>
          <Header as="h3" onClick={() => setShowCards(!showCards)}>
            <Icon name={showCards ? 'angle down' : 'angle right'} size="mini" />
            Cards - Tempo Real
          </Header>
        </Grid.Column>
        <Grid.Column width="13">
          <Divider horizontal>
            <Header as="h4">
              <Icon name="vcard" />
            </Header>
          </Divider>
        </Grid.Column>
      </Grid>
      <br />
      <br />
      <br />
      {showCards && (
        <Card.Group>
          {Object.values(groupState)
            .filter((e) => e.name.toLowerCase().includes(dashboardFilter.toLowerCase()))
            .sort((a, b) => (b.count === a.count ? a.name.localeCompare(b.name) : b.count - a.count))
            .map((group) => (
              <Card key={group.id} centered onClick={() => dispatch(setSelectedCard('fences', group.id))}>
                <Card.Content style={{ height: '78px !important' }}>
                  <Icon circular inverted color={group.color} size="large" style={{ float: 'right' }} name={'clock'} />
                  <Card.Header>{group.count}</Card.Header>
                  <Card.Meta>Pessoa(s) no Perímetro</Card.Meta>
                  {/* <Card.Description>{group.name}</Card.Description> */}
                </Card.Content>

                <Card.Content extra style={{ height: '55px', paddingTop: '18px' }}>
                  <span
                    title={group.name}
                    style={{
                      maxWidth: '85%',
                      display: 'inline-block',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {group.name}
                  </span>
                  <Icon
                    name={selected.includes(group.id) ? 'check square outline' : 'square outline'}
                    size="large"
                    style={{ float: 'right', color: selected.includes(group.id) ? 'green' : null, marginRight: '12px' }}
                  />
                </Card.Content>
              </Card>
            ))}
        </Card.Group>
      )}
    </>
  )
}

export default FencesDashboard
