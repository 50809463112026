import React from 'react'
import { useSelector } from 'react-redux'
import { DropdownProps, Form } from 'semantic-ui-react'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { RootStore } from 'Store/initialStore'
import InputStoreField from 'Components/forms/generic/InputStoreField'
import CustomTimeFields from './CustomTimeFields'
import DropdownField from 'Components/forms/generic/DropdownField'

/*
Casos de uso:
 - atalho relativo
 - absoluto
*/

const options = [
  {
    key: '1h',
    value: 'ago(1h)',
    text: 'Última Hora',
  },
  {
    key: '24h',
    value: 'ago(24h)',
    text: 'Últimas 24 Horas',
  },
  {
    key: '7d',
    value: 'ago(7d)',
    text: 'Última Semana',
  },
  {
    key: 'custom',
    value: 'custom',
    text: 'Personalizado',
  },
]

export interface TimePropsFieldsInterface {
  entity: 'betaDashboards'
  setProp: Function
  panelId: string
}

function TimeFields({ panelId, setProp, entity }: TimePropsFieldsInterface) {
  const errors = useObjSelector((state) => state[entity].form.errors?.[2])

  const timeProp = useSelector(
    (state: RootStore) =>
      state.betaDashboards.wip.panels?.[panelId]?.filters?.timeProp ??
      state.betaDashboards.current?.panels?.[panelId]?.filters?.timeProp
  )

  const showFeedback = useObjSelector((state: RootStore) => state[entity].form?.steps.items[2].showFeedback)

  const handleTimePropChange = (value: string) => {
    setProp(`filters.timeProp`, value)
    if (value.includes('ago')) {
      setProp(`startTime`, value)
      setProp(`endTime`, 'now()')
    }
  }

  return (
    <>
      <DropdownField
        label="Intervalo de Tempo"
        value={timeProp}
        name="timeProp"
        placeholder="Selecione um intervalo de tempo"
        onChange={(_, { value }: DropdownProps) => {
          handleTimePropChange(value as string)
        }}
        options={options}
        required
        sort={false}
        error={errors?.filters}
        showFeedback={showFeedback}
        clearable={false}
      />

      <br />
      {timeProp === 'absoluteTime' && (
        <Form.Group widths="equal">
          <InputStoreField
            entity={entity}
            label="Início"
            name="startTime"
            placeholder="Defina o início"
            value={'startTime'}
            onChange={(_, { name, value }) => setProp(`${name}`, value)}
            inputType="datetime-local"
            required={true}
            showFeedback={showFeedback}
            error={errors?.startTime}
          />
          <InputStoreField
            entity={entity}
            label="Fim"
            name="endTime"
            placeholder="Defina o fim"
            value={'endTime'}
            onChange={(_, { name, value }) => setProp(`${name}`, value)}
            inputType="datetime-local"
            required={true}
            showFeedback={showFeedback}
            error={errors?.endTime}
          />
        </Form.Group>
      )}
      {timeProp === 'custom' && (
        <>
          <CustomTimeFields prefix="start" setProp={setProp} panelId={panelId} />
          <br />
          <br />
          <CustomTimeFields prefix="end" setProp={setProp} panelId={panelId} />
        </>
      )}
    </>
  )
}

export default TimeFields
