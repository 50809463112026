import { urlBase } from 'Apis/rest'
import axios from 'axios'

export const showConfirm = (data) => ({
  type: 'SHOW_CONFIRM',
  payload: { ...data, open: true },
})

export const hideConfirm = () => ({
  type: 'HIDE_CONFIRM',
  payload: { open: false },
})

export const setCurrentStatsBar = (id, table, type) => ({
  type: 'SET_CURRENT_STATS_BAR',
  id: id,
  payload: {
    id: id,
    table: table,
    type: type,
  },
})

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}

export const uploadImage = async (data, type) => {
  const jwt = localStorage.getItem('id_token')
  const headers = {
    headers: {
      Authorization: 'Bearer ' + jwt,
    },
  }
  let encodedString = await readFileAsync(data)

  const body = {
    fileName: data.name,
    imageCategory: type,
    base64ImageFile: encodedString.replace(/^data:image.+;base64,/, ''),
  }

  try {
    const result = await axios.put(urlBase + `/images`, body, headers).then((res) => {
      return JSON.parse(res.data.body)
    })
    return result
  } catch (err) {
    console.error(err)
    alert('Erro ao fazer o upload da imagem ')
    return ''
  }
}

export const removeImage = async (fileName, type) => {
  const jwt = localStorage.getItem('id_token')
  const headers = {
    headers: { Authorization: 'Bearer ' + jwt },
  }
  try {
    await axios.delete(urlBase + `/images?fileName=${fileName}&imageCategory=${type}`, headers).then((res) => {
      // console.log('res', res)
      //dispatch({ type: 'FETCH_ASSETS', payload: res.data })
    })
  } catch (err) {
    console.error(err)
    alert('Erro ao remover a imagem ')
  }
}
