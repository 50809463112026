import { setDataLabelingsProp } from 'Store/actions/dataLabelings-actions'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import React from 'react'
import { Dropdown, DropdownProps } from 'semantic-ui-react'
import { RootStore } from '../../../../Store/initialStore'
import { useDispatch } from 'react-redux'
import { isArray } from 'lodash'
import { useTranslation } from 'react-i18next'

interface Option {
  key: string
  text: string
  value: string
}

const options: Option[] = [
  { key: 'aXMean', text: 'aXMean', value: JSON.stringify({ name: 'aXMean', color: '#b24b8e' }) },
  { key: 'aYMean', text: 'aYMean', value: JSON.stringify({ name: 'aYMean', color: '#4b72b2' }) },
  { key: 'aZMean', text: 'aZMean', value: JSON.stringify({ name: 'aZMean', color: '#4bb258' }) },
  { key: 'aXSpread', text: 'aXSpread', value: JSON.stringify({ name: 'aXSpread', color: '#724bb2' }) },
  { key: 'aYSpread', text: 'aYSpread', value: JSON.stringify({ name: 'aYSpread', color: '#b2584b' }) },
  { key: 'aZSpread', text: 'aZSpread', value: JSON.stringify({ name: 'aZSpread', color: '#b2724b' }) },
]

const DataLabelingsMeasuresDropdown: React.FC = () => {
  const selectedMeasures = useObjSelector((state: RootStore) => state.dataLabelings.selectedMeasures[0])
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleChange = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data

    if (value && isArray(value)) {
      dispatch(setDataLabelingsProp('selectedMeasures', [value.map((e) => JSON.parse(e.toString()))]))
    }
  }

  return (
    <Dropdown
      placeholder={t('selectTheMeasures')}
      multiple
      selection
      options={options}
      value={selectedMeasures.map((e) => JSON.stringify(e))}
      onChange={handleChange}
    />
  )
}

export default DataLabelingsMeasuresDropdown
