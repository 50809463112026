import React from 'react'
import { Page, StyleSheet } from '@react-pdf/renderer'
import ReportEmission from './ReportEmission'
import ReportFooter from './ReportFooter'
import ReportHeader from './ReportHeader'
import ReportItemsTable from './ReportItemsTable'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 35,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 45,
    lineHeight: 0.8,
    flexWrap: 'wrap',
  },
  // align logo on top right
  logo: {
    width: 120,
    height: 30,
    position: 'absolute',
    top: 35,
    right: 0,
  },
})

const PDFPage = ({
  title,
  subtitle,
  transDate,
  items,
  allStyles,
  columns,
  pageNumber,
  totalPages,
  intervalDate,
  totalItems,
}) => {
  return (
    <Page size="A4" style={styles.page} orientation="landscape">
      <ReportHeader title={title} subTitle={subtitle} />
      <ReportEmission
        transDate={transDate}
        intervalDate={intervalDate}
        totalLines={items.length}
        totalItems={totalItems}
      />
      <ReportItemsTable items={items} allStyles={allStyles} columns={columns} />
      <ReportFooter pageNumber={pageNumber} totalPages={totalPages} />
    </Page>
  )
}

export default PDFPage
