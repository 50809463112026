import React, { useState } from 'react'
import { Dropdown, Grid, Input, Icon /*, Label */ } from 'semantic-ui-react'
import { AssetItem } from './AssetItem'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { regExpEscape } from 'Utils/escape'
import { useSelector } from 'react-redux'

/*
 Front-end revisao 07/2022 by Thiago Porfirio
 Erros encontrados input, botão ocultar todos, tamanho dos geral dos cotainers.
 Consertado somente o input, outros erros consertar na tarefa de re-criar e padronizar o dropdown, diminuir o maximo de css possivel.
*/

export const DropdownGridHeader = styled(Grid)`
  width: 400px;
  padding-left: 10px;
  margin: 0px !important;
`

const DropdownGridInput = styled(Grid)`
  width: 217px;
  padding-left: 10px;
`

export const DropdownHeaderCol = styled(Grid.Column)`
  padding: 10px !important;
  right: 13px;
`

export const DropdownHeader = styled(Dropdown.Header)`
  font-size: 1.33em;
  font-weight: 800;
`

const DropdownDivider = styled(Dropdown.Divider)`
  // margin-bottom: 10px !important;
  // margin-left: 8px !important;
  // margin-right: 8px !important;
  // margin-top: 15px !important;
  margin: 12px 8px !important;
`
const DropdownMenu = styled(Dropdown.Menu)`
  border-top-width: 0px !important;
`

const StyledInput = styled(Input)``

const AssetIcon = ({ assetId }) => {
  const time = useSelector((state) => {
    const assetInfo = state.measures.byId[assetId]?.info
    const stateTime = assetInfo?.time
    if (!stateTime && assetInfo) {
      console.warn(`Warning: time is undefined or missing in asset info`, assetInfo)
    }
    return stateTime
  })

  const color = getStatusColor(time)
  return <Icon name="circle" color={color} size="small" />
}

export const AssetContainer = ({
  activeAssetsStr,
  assetArrayStr,
  assetsStr,
  assetsTitle,
  clickItem,
  clickFav,
  favorites = [],
  type,
}) => {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')

  const assetArray = JSON.parse(assetArrayStr)
  const activeAssets = JSON.parse(activeAssetsStr)
  const assets = JSON.parse(assetsStr)

  if (assetArray.length === 0) return null

  return (
    <>
      <DropdownGridInput className="header">
        <StyledInput
          icon
          iconPosition="left"
          placeholder={t(assetsTitle || type) + '...'}
          onChange={(e, d) => setSearchValue(d.value)}
          size="large"
          onKeyUp={(e) => {
            if (e.key === ' ') {
              e.target.value = e.target.value + ' '
              e.stopPropagation()
            }
          }}
        >
          <Icon name="user" />
          <input />
        </StyledInput>
      </DropdownGridInput>

      <DropdownDivider />

      <DropdownMenu scrolling>
        {activeAssets.length === 0 && (
          <Dropdown.Item key="noActive" disabled>
            {t('NoActiveAssets')}
          </Dropdown.Item>
        )}
        {activeAssets
          .filter((data) => assets[data] && assets[data].name.search(new RegExp(regExpEscape(searchValue), 'i')) !== -1)
          .sort((prev, next) => assets[prev].name.localeCompare(assets[next].name))
          .map((assetId) => (
            <AssetItem
              key={'active' + assetId.toString()}
              favState={favorites.includes(assetId)}
              clickItem={clickItem}
              clickFav={clickFav}
              iconItem={<AssetIcon assetId={assetId} />}
              assetDetails={assets[assetId]}
              assetId={assetId.toString()}
            />
          ))}
      </DropdownMenu>
    </>
  )
}

const getStatusColor = (time) => {
  if (time === undefined) return 'grey'
  const color = new Date(time).getTime() > Date.now() - 1000 * 60 * 5 ? 'green' : 'yellow'
  return color
}
