import React, { useMemo } from 'react'
//import { useTranslation } from 'react-i18next'
import ReactTable from 'Utils/reactTable'
import { NewsColumns } from './columns'
import { useTranslation } from 'react-i18next'
import useAsync from 'Utils/hooks/useAsync'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'
import { fetchNews, deleteNews } from 'Apis/news'

const tableName = 'news'
const tableLabel = [
  {
    key: 'news',
    text: 'Novidades',
    value: 'messages/news',
  },
  {
    key: 'messages',
    text: 'Mensagens',
    value: 'messages',
  },
]

const menuButtons = ['new', 'edit', 'delete', 'refresh']

export const NewsContent = () => {
  const { value, execute, status /* , error  */ } = useAsync(fetchNews, true)

  const deleteAction = async ({ ids }: { ids: string[] }) => {
    try {
      await deleteNews(ids[0])
      execute()
      notifySuccess()
    } catch (e) {
      notifyError()
      console.error(e)
    }
  }

  const { t } = useTranslation()
  const [currentSort, setCurrentSort] = React.useState('label')

  // let hiddenColumns = ['status', 'extra', 'updatedBy', 'updatedFrom', 'createdBy', 'createdFrom']

  const columns = React.useMemo(() => NewsColumns(t), [t])

  const rows = value?.allIds || []
  const byId = value?.byId || {}

  const data = useMemo(() => rows.map((id: string) => byId[id]), [rows, byId])
  return (
    <ReactTable
      table={tableName}
      tableLabel={tableLabel}
      columns={columns}
      data={data}
      fetchAction={execute}
      deleteAction={deleteAction}
      setCurrentSort={setCurrentSort}
      currentSort={currentSort}
      showPlaceholders={status === 'pending'}
      menuButtons={menuButtons}
    />
  )
}
