/*  Author: Luís Mourão
    Type: reducer
    Description: reducer from rules store
    To do list:
*/

import m from 'moment'
import produce from 'immer'

export default function modulesReducer(state = {}, { type, id, payload, assets }) {
  switch (type) {
    case 'FETCH_INTERACTIONS':
      //console.log('Resultado das interações', payload)
      if (payload) {
        return produce(state, (newState) => {
          let time = new Date()
          newState.fetchTime = time.toISOString()
          let epoch
          let idFormed
          payload.forEach((interact) => {
            epoch = new Date(interact.time).getTime()
            //console.log('Epoch', epoch)
            idFormed = interact.assetId + '#' + epoch
            interact.location = interact.location || 'Shop Floor'
            interact.time =
              m(interact.time).subtract(3, 'hours').format('LL') +
              ' ' +
              m(interact.time).subtract(3, 'hours').format('LTS')
            newState.byId[idFormed] = interact
            newState.allIds.push(idFormed)
          })
        })
      } else return state

    case 'FETCH_INTERACTIONS_START':
      return produce(state, (newState) => {
        newState.fetchTime = null
        newState.allIds = []
      })

    default:
      return state
  }
}
