import * as z from 'zod'

const StepThreeSchema = z.object({
  startTime: z.string(),
  endTime: z.string(),
})

export type StepThree = z.infer<typeof StepThreeSchema>

export default StepThreeSchema
