import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'
import { Popup } from 'semantic-ui-react'

const ONE_MINUTE_IN_MS = 60000

class TimeSince extends Component {
  static propTypes = {
    date: PropTypes.any.isRequired,
  }

  state = {
    relative: '',
    fromNowPeriod: false,
  }

  static getDerivedStateFromProps(props) {
    return {
      relative: moment(props.date).fromNow(),
    }
  }

  componentDidMount() {
    this.setRelativeDateTicker()
  }

  componentWillUnmount() {
    if (this.ticker) {
      window.clearTimeout(this.ticker)
      this.ticker = null
    }
  }

  setRelativeDateTicker = () => {
    const { date, fromNowDuring } = this.props
    const fromNowPeriod = Boolean(fromNowDuring) && -moment(date).diff(moment()) > fromNowDuring
    if (fromNowPeriod) {
      this.setState({
        fromNowPeriod,
      })
      return
    }
    this.ticker = window.setTimeout(() => {
      this.setState({
        relative: moment(this.props.date).fromNow(),
        fromNowPeriod,
      })
      this.setRelativeDateTicker()
    }, ONE_MINUTE_IN_MS)
  }

  render() {
    const { date, fromNowDuring, className, ...props } = this.props
    const dateObj = getDateObj(date)
    if (!dateObj) return null
    const format = 'lll'
    return (
      <Popup
        trigger={
          <time dateTime={dateObj.toISOString()} className={className} {...props}>
            {this.state.fromNowPeriod ? moment(date).format(format) : this.state.relative}
          </time>
        }
        content={moment(dateObj).format(format)}
        position="bottom left"
        size="tiny"
        inverted
        disabled={this.state.fromNowPeriod}
      />
    )
  }
}

export default TimeSince

function getDateObj(date) {
  if (isString(date) || isNumber(date)) {
    date = new Date(date)
  }
  return date
}
