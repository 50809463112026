import { ChangeLog } from 'API'

export const parseChangeLog = (payload: ChangeLog) => {
  return {
    ...payload,
    log: typeof payload.log === 'string' ? JSON.parse(payload.log) : payload.log,
  }
}

export default parseChangeLog
