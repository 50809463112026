import React, { useCallback } from 'react'
import MensurationsIdForm from './MensurationsIdForm'
import {
  clearMensurationForm,
  mutateMensuration,
  mutateMensurationPhase,
  removeMensurationPhase,
  setMensurationsProps,
} from 'Store/actions/mensurations-action'
import MensurationsInfoForm from './MensurationsInfoForm'
import StepForm from '../StepForm'
import stepOneSchema from './schemas/stepOne'
import stepTwoSchema from './schemas/stepTwo'
import stepThreeSchema from './schemas/stepThree'
import useFetchData from 'Utils/hooks/useFetchData'
import ActivitiesPhasesForm from '../activities/ActivitiesPhasesForm'
import { useDispatch } from 'react-redux'

function MensurationForm() {
  const dispatch = useDispatch()
  useFetchData({ independentFetches: [{ fetchAction: 'activities' }] })

  return (
    <StepForm
      entity="mensurations"
      schemas={[stepOneSchema, stepTwoSchema, stepThreeSchema]}
      forms={[
        <MensurationsIdForm />,
        <MensurationsInfoForm />,
        <ActivitiesPhasesForm
          entity="mensurations"
          onRemovePhase={useCallback((index: number) => dispatch(removeMensurationPhase(index)), [dispatch])}
          onChangePhase={useCallback(
            ({ index, field, value }: Record<string, string | number>) =>
              dispatch(mutateMensurationPhase({ index, field, value })),
            [dispatch]
          )}
        />,
      ]}
      setProps={setMensurationsProps}
      values={[{ fields: ['name'] }, {}]}
      onSave={() => dispatch(mutateMensuration())}
      onClose={() => dispatch(clearMensurationForm())}
    />
  )
}

export default MensurationForm
