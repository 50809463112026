import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Marker, FeatureGroup, Tooltip } from 'react-leaflet'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { icones } from 'Resources/Icons/iconsLeaflet'
import { setTrue } from 'Store/actions/toggle-action'
import { updateWiP } from 'Store/actions/modules-action'

/*  Author: Bruno Melo
    Type: Smart
	  Description: Render Marker from References
    To do list: 
    - 			
*/

export const RenderCreationMarker = ({ wip, color = 'red' }) => {
  const lat = wip?.geoJson?.geometry?.coordinates[1]
  const lng = wip?.geoJson?.geometry?.coordinates[0]
  let geoJson = wip?.geoJson
  const tooltipText = wip['form-name']
  const typeId = wip['form-type']
  const profile = useSelector((state) => state.profiles?.byId[typeId])
  const dispatch = useDispatch()
  const inner = useSelector((state) => state.toggle.showInnerMap)
  const currentInner = useSelector((state) => state.indoor.current?.inner)
  const tenant = useSelector((state) => state.login.empresa)

  const markerClick = () => {
    dispatch(setTrue('showStatsBar'))
    //alert('Aqui eu mudo para o modo de edição ')
  }

  const dragEnd = (data) => {
    geoJson.geometry.coordinates[1] = data.target._latlng.lat
    geoJson.geometry.coordinates[0] = data.target._latlng.lng
    dispatch(
      updateWiP({
        moduleType: 'refs',
        geoJson: geoJson,
      })
    )
  }

  //const canAccess = useCanAccess('WRITE')

  let position = inner
    ? innerPosition([lng, lat], 'ref', currentInner?.center, currentInner?.bearing)
    : { lat: lat, lng: lng }

  return (
    <FeatureGroup key={'refFeatureGroupCreationMode'} onClick={() => markerClick()}>
      <Marker
        key={'RenderCreationMarker'}
        position={position}
        icon={icones({
          color: profile?.color ? profile.color : 'blue',
          icon: profile?.icon ? profile.icon : null,
          tenant: tenant,
        })}
        draggable={true}
        ondragend={dragEnd}
      >
        {tooltipText && (
          <Tooltip permanent direction="bottom">
            {tooltipText}
          </Tooltip>
        )}
      </Marker>
    </FeatureGroup>
  )
}

export default memo(RenderCreationMarker)
