import React, { Component } from 'react'
import { Map, ZoomControl } from 'react-leaflet'
import { connect } from 'react-redux'
import MapLayersControl from './MapLayersControl'
import MapButton from 'Utils/components/MapButton'
import './Map.css'
import RTMarkers from './MapMarkers/RTMarkers'
import MapTools from './MapTools'
import { saveRefMap } from 'Store/actions/login-action'
import ErrorBoundary from 'Utils/ErrorBoundary/ErrorBoundary'
import { withTranslation } from 'react-i18next'
import { setTrue, setFalse } from 'Store/actions/toggle-action'
import getSafe from 'Utils/getSafe.js'
import { ReactComponent as FitView } from 'Resources/Icons/centralize.svg'
import { ReactComponent as FullScreenIcon } from 'Resources/Icons/fullscreen.svg'
import { ReactComponent as ExitFullScreenIcon } from 'Resources/Icons/exitFullscreen.svg'
import { ReactComponent as EnterIndoorIcon } from 'Resources/Icons/opened-filled-door.svg'
import { toggleFullScreen, isFullScreen } from 'Utils/fullscreen'
import PropTypes from 'prop-types'
import { ToggleIndoor } from './Utils/Toggles'
import HistRender from './MapHist/HistRender'
import L from 'leaflet'
import MapMethods from 'Utils/components/MapMethods'
import { measureOptions } from 'Utils/MapUtils'
import HistHoles from './MapHist/HistHoles'
import HistHolesLines from './MapHist/HistHolesLines'
import HistorySteps from 'Utils/reactTable/modals/historySteps/HistorySteps.js'

/*  Author: Bruno Melo
    Type: Smart
  Description: Info Provider for the Map Components
    To do list: -
*/
class Maps extends Component {
  constructor(props) {
    super(props)
    this.refMap = React.createRef()
    this.state = {
      errorBoundaryKey: 0,
      lan: '',
      isFullScreen: false,
      center: [0, 0],
      zoom: 0,
      innerMaxBounds: [
        [-90, -180],
        [90, 180],
      ],
      showInner: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.i18n && props.i18n.language !== state.lan) {
      return {
        lan: props.i18n.language,
      }
    } else return null
  }

  componentDidUpdate(prevProps, prevState) {
    const { indoor, refMap } = this.props

    if (!this.state.searchBool) this.setState({ searchBool: true })
    if (prevState.lan !== this.state.lan) this.setState({ searchBool: false })
    if (prevProps.zoom !== this.props.zoom) this.forceUpdate()

    if (this.props.refMap && this.state.zoom < 14 && prevState.zoom >= 14) {
      try {
        Object.values(this.props.refMap._layers).forEach((i) => {
          if (i._corners) this.props.refMap.removeLayer(i)
        })
      } catch (e) {
        console.error('Maps: ', e)
      }
    }

    if (prevProps.indoor.current.id !== indoor.current.id) {
      let corners = getSafe(() => indoor.current.inner.corners, null, 'object', 'No indoor current inner')
      if (corners) {
        let bounds = L.latLngBounds(corners)
        const offset = 0.001
        this.setState({
          innerMaxBounds: [
            [bounds._northEast.lat + offset, bounds._northEast.lng + offset],
            [bounds._southWest.lat - offset, bounds._southWest.lng - offset],
          ],
          showInner: false,
        })
      }
    }

    if (this.props.inner && !this.state.showInner) {
      let cornersFit = getSafe(
        () => refMap.fitBounds(indoor.current.inner.corners),
        null,
        null,
        'No indoor curent inner'
      )
      // L.polygon(this.props.indoor.current.inner.corners, { color: 'red' }).addTo(this.props.refMap); // for debug
      if (cornersFit) {
        refMap.setMinZoom(16)
        this.setState({ showInner: true })
      }
    }

    if (prevProps.inner && !this.props.inner) {
      refMap.setMinZoom(5)
      this.setState({
        showInner: false,
        innerMaxBounds: [
          [-90, -180],
          [90, 180],
        ],
      })
    }

    // Isso tenta garantir que o mapa apareça assim que a plataforma inicialize (Evita o mapa cinza não carregado)
    if (prevProps.center.lat !== this.props.center.lat) {
      refMap.setZoom(refMap.getZoom() - 1)
    }
  }

  componentDidMount() {
    const refMap = this.refMap.current.leafletElement
    // https://stackoverflow.com/questions/21405189/leaflet-map-shows-up-grey
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'))
    }, 1000)
    refMap.flyTo(this.props.center, this.props.zoom, {
      duration: 4, // duração da animação em segundos
    })
    this.props.saveRefMap(refMap)
    document.addEventListener('fullscreenchange', this.handleFullScreen)
  }

  componentWillUnmount() {
    // Make sure to remove the DOM listener when the component is unmounted.
    this.props.saveRefMap(null)
    document.removeEventListener('fullscreenchange', this.handleFullScreen)
  }

  handleFullScreen = () => {
    let bool = isFullScreen()
    this.setState({ isFullScreen: bool })
  }

  onViewportChanged = (viewport) => {
    let currentZoom = viewport.zoom
    let actualCenter = viewport.center
    this.setState({ center: actualCenter, zoom: currentZoom })
  }

  handleRerender = () => {
    console.log("Something went really wrong, let's try to fix it by rerendering the map")
    if (this.state.errorBoundaryKey < 5)
      this.setState((prevState) => ({
        errorBoundaryKey: prevState.errorBoundaryKey + 1,
      }))
  }

  handleClick = (e) => {
    alert(e.latlng)
  }

  handleExitInnerMap = () => {
    this.props.refMap.setZoom(20)
    // até refatorar tudo, só carregando a pagina de novo para nao dar erro.
    window.location.reload()
  }

  render() {
    const { inner, indoor, showHeatMap, histType, i18n, showHistory, showHistorySteps } = this.props
    let c, z
    if (this.props.center && this.props.center.length === 0) {
      c = [0, 0]
      z = 2
    } else {
      c = getSafe(() => this.props.center, [0, 0])
      z = this.props.zoom ? this.props.zoom : 16
    }

    const hasIndoor = indoor && indoor.items && indoor.items.length > 0
    return (
      <ErrorBoundary key={this.state.errorBoundaryKey} onError={this.handleRerender}>
        <Map
          ref={this.refMap}
          id="map"
          center={c}
          zoom={z - 2}
          animate={true}
          onViewportChanged={this.onViewportChanged}
          dragging={this.props.drag}
          maxZoom={this.state.showInner ? 23 : 21}
          minZoom={5}
          maxBounds={this.state.innerMaxBounds}
          zoomControl={false}
          preferCanvas={false}
          scrollWheelZoom={!showHeatMap}
          //onClick={this.handleClick}
        >
          <MapLayersControl />
          <ZoomControl position="bottomright" />
          {hasIndoor && (
            <ToggleIndoor
              bearing={this.props.indoor.current?.inner?.bearing}
              center={this.props.indoor.current?.inner?.center}
              inner={this.state.showInner}
              indoor={indoor?.items ?? []}
              zoom={getSafe(() => this.props.refMap.getZoom(), undefined)}
            />
          )}
          <MapTools refMap={this.props.refMap} scope={this.props.perfil} />
          <MapButton position="bottomright" onClick={() => toggleFullScreen()} title={this.props.t('ScreenFull')}>
            {this.state.isFullScreen ? (
              <ExitFullScreenIcon style={{ height: '15px', width: '16px', verticalAlign: 'middle' }} />
            ) : (
              <FullScreenIcon style={{ height: '15px', width: '16px', verticalAlign: 'middle' }} />
            )}
          </MapButton>
          <MapMethods type="measure" options={measureOptions} lang={i18n.language} />
          {hasIndoor && inner && (
            <MapButton position="bottomright" onClick={this.handleExitInnerMap} title={this.props.t('ExistInnerMap')}>
              <EnterIndoorIcon style={{ height: '15px', width: '15px', verticalAlign: 'middle' }} />
            </MapButton>
          )}
          {hasIndoor && inner && (
            <MapButton
              position="bottomright"
              title="Centralizar no Mapa"
              onClick={() => {
                getSafe(() => this.props.refMap.fitBounds(indoor.current.inner.corners))
              }}
            >
              <FitView style={{ height: '15px', width: '16px', verticalAlign: 'middle' }} />
            </MapButton>
          )}
          {this.props.showAssets && !histType && <RTMarkers />}
          {showHistory && ['discrete', 'antpath'].includes(histType) && <HistRender />}
          {showHistory && histType && histType === 'hole' && <HistHoles zoom={this.state.zoom} />}
          {showHistory && histType && histType === 'holesLines' && <HistHolesLines zoom={this.state.zoom} />}
          {showHistorySteps && <HistorySteps />}
        </Map>
      </ErrorBoundary>
    )
  }
}

Maps.propTypes = {
  refMap: PropTypes.object,
  i18n: PropTypes.object,
  drag: PropTypes.bool,
  saveRefMap: PropTypes.func,
  zoom: PropTypes.number.isRequired,
  indoor: PropTypes.object,
}

const mapStateToProps = (state) => ({
  drag: state.toggle.mapDragging,
  histType: state.history.type,
  showHistory: state.history.success,
  inner: state.toggle.showInnerMap,
  showHeatMap: state.toggle.showHeatMap,
  perfil: state.login.perfil,
  center: state.login.preferences.center,
  zoom: state.login.preferences.zoom,
  indoor: state.indoor,
  refMap: state.login.refMap,
  showAssets: state.assets.success,
  showHistorySteps: state.toggle.showHistorySteps,
})

const mapDispatchToProps = {
  setTrue: setTrue,
  setFalse: setFalse,
  saveRefMap: saveRefMap,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Maps))
