import React from 'react'
import styled from 'styled-components'
import { Menu } from 'semantic-ui-react'
import SvgIcon from 'Utils/components/SvgIcon'

interface LegendMenuItemProps {
  name: string
  abbreviation: string
  count: number
  color: string
  icon?: string
}

const StyledMenuItem = styled(Menu.Item)`
  background-color: rgba(255, 255, 255, 0.01) !important;
  width: 5vw !important;
`

const IconContainer = styled.div`
  height: 30.5px;
  width: 30.5px;
`

const LegendMenuItem: React.FC<LegendMenuItemProps> = ({ name, abbreviation, count, color, icon }) => {
  return (
    <StyledMenuItem title={name}>
      <IconContainer>
        <SvgIcon color={color || 'gray'} name={icon || 'FaCircle'} />
      </IconContainer>
      {abbreviation + ': ' + count.toString().padStart(3, '0')}
    </StyledMenuItem>
  )
}

export default LegendMenuItem
