import React from 'react'
import { TableColumn } from '../types'
import { TFunction } from 'i18next'
// import { Checkbox } from 'semantic-ui-react'
import { DateTime } from 'Utils/reactTable/components/DateTime'
//import { threeColumns } from 'Utils/threeColumns'

export const AccessColumns = (t: TFunction) => {
  return [
    {
      Header: 'ID',
      accessor: 'id',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} ids`,
      Cell: (c) => c.value.split('-')[0],
    },
    {
      Header: 'Nome',
      accessor: 'userName',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
    },
    {
      Header: 'Atribuição',
      accessor: 'userType',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
    },
    {
      Header: 'CPF',
      accessor: 'cpf',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
    {
      Header: 'Status',
      accessor: 'status',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} adicionais`,
    },
    {
      Header: 'ID da máscara',
      accessor: 'maskId',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} adicionais`,
    },
    {
      Header: 'NFC',
      accessor: 'cardId',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} adicionais`,
    },
    {
      Header: 'Portaria',
      accessor: 'branch',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} adicionais`,
    },
    {
      Header: 'Data de Inicio',
      accessor: 'startDate',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
    },
    {
      Header: 'Data de fim',
      accessor: 'endDate',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
    },
    {
      Header: 'Anfitrião',
      accessor: 'hostName',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
    },
    {
      Header: 'Lugar',
      accessor: 'place',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
    },
    {
      Header: 'Motivo',
      accessor: 'reason',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
    },
    {
      Header: 'Modelo do veículo',
      accessor: 'modelAuto',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
    },
    {
      Header: 'Placa',
      accessor: 'licensePlate',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
    },
    {
      Header: 'Cor do veículo',
      accessor: 'color',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
    },
    {
      Header: 'Liberação Veículo',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
      Cell: ({ row: { original } }) => (
        <a
          href={`https://vli.phygitall.com.br/external/information/${original.id}?form=vehicles-v1`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Imprimir
        </a>
      ),
    },
    {
      Header: 'Liberação Ferramenta',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
      Cell: ({ row: { original } }) => (
        <a
          href={`https://vli.phygitall.com.br/external/information/${original.id}?form=tools-v1`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Imprimir
        </a>
      ),
    },
    {
      Header: 'Solicitados às',
      accessor: 'requestedAt',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
      Cell: ({ value }) => <DateTime value={value} />,
    },
    {
      Header: 'Aprovação Anfitrião',
      accessor: 'approvedAt',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
      Cell: ({ value }) => <DateTime value={value} />,
    },
    {
      Header: 'Aprovação Portaria',
      accessor: 'admittedAt',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
      Cell: ({ value }) => <DateTime value={value} />,
    },
    {
      Header: 'Recusado às',
      accessor: 'rejectedAt',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
      Cell: ({ value }) => <DateTime value={value} />,
    },
    {
      Header: 'Justificativa de recusa',
      accessor: 'justification',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value}`,
    },
    //@ts-ignore
    // ...threeColumns(t),
  ] as TableColumn[]
}
