import m from 'moment'
import 'moment/locale/pt-br'
export const LIMITE_HISTORICO = 880

export const colorsList = ['blue', 'black', 'green', 'red', 'orange', 'yellow', 'pink', 'violet', 'white']
export const pathColorList = ['blue', 'red', 'green', 'orange', 'purple', 'darkblue']

export const colourMappings = {
  yellow: 'Amarelo',
  blue: 'Azul',
  darkblue: 'Azul Escuro',
  orange: 'Laranja',
  black: 'Preto',
  white: 'Branco',
  pink: 'Rosa',
  violet: 'Roxo',
  green: 'Verde',
  darkgreen: 'Verde Escuro',
  red: 'Vermelho',
}

export const configs3 = {
  bucketName: 'plataforma-phygitall',
  dirName: 'images/indoor',
  region: 'us-east-2',
  accessKeyId: 'AKIA2X6CK2PALKPL2TMH',
  secretAccessKey: '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',
}

/*export const formatatempo = (tempo, lingua = 'pt-BR', cidade = 'America/Sao_Paulo') => {
  let tempoformatado;
  let hoje;
  let tempoaux;
  if (typeof tempo === 'undefined') return '';
  try {
    hoje = new Date().toLocaleTimeString(lingua, { timeZone: cidade, day: 'numeric', month: 'numeric', year: 'numeric' });
    tempoformatado = new Date(Date.parse(tempo)).toLocaleTimeString(lingua, { timeZone: cidade, day: 'numeric', month: 'numeric', year: 'numeric' });
  } catch (e) {//Tem browsers que não tem toLocaleTimeString, dá erro Range Error, essa é uma solução temporária
    let auxontem = hoje.split('/');
    let ontem = auxontem[0] - 1 + '/' + auxontem[1] + '/' + auxontem[2]
    let auxt = tempo.split('T');
    let auxt2 = auxt[0].split('-');
    let auxt3 = auxt[1].split('.');
    let auxt4 = auxt3[0].split(':');
    let dia = auxt2[2] + '/' + auxt2[1] + '/' + auxt2[0];
    dia = dia === hoje ? 'Hoje,' : dia;
    dia = dia === ontem ? 'Ontem,' : dia;
    tempoformatado = dia + ' ' + (auxt4[0] - 2) + ':' + auxt4[1] + ':' + auxt4[2];//Mudar quando acabar o horário de verão (-2 pra -3)
  }
  if (hoje.slice(0, 10) === tempoformatado.slice(0, 10)) {
    tempoaux = tempoformatado.split(' ');
    tempoformatado = 'Hoje, ' + tempoaux[1];
  }
  return tempoformatado;
}*/

export const formatatempo = (tempo, comMilisegundos, lan) => {
  if (lan) {
    switch (lan) {
      case 'zh':
        m.locale('zh-tw')
        break
      case 'pt':
        m.locale('pt-br')
        break
      default:
        m.locale(lan)
        break
    }
  }
  //console.log("tempoo", m(tempo))
  if (!m(tempo).isValid) return 'tempo invalido'
  //let tempoformatado = m(tempo)
  let hoje = m()
  /*if(m(tempo).isBefore(hoje.subtract(1,'year'),'year'))
  return m(tempo).format('DD/MMM/YYYY HH:mm')
  else{*/
  if (comMilisegundos)
    if (m(tempo).isBefore(hoje.subtract(6, 'days'), 'day'))
      return m(tempo).format('DD/MMM HH:mm') + m(tempo).format(':ss.') + m(tempo).millisecond()
    else return m(tempo).calendar('') + m(tempo).format(':ss.') + m(tempo).millisecond()
  else if (m(tempo).isBefore(hoje.subtract(1, 'days'), 'day')) return m(tempo).format('DD/MMM HH:mm')
  else return m(tempo).calendar('')
  //}
}

export const tempoLocal = (tempo) => {
  if (!m(tempo).isValid) return 'tempo invalido'
  return m(tempo).format('L LTS')
}
