import React from 'react'
import { Form, Input, Label } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { setActivitiesProp } from 'Store/actions/activities-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import FormGroup from '../FormGroup'

const ActivitiesLocationForm = () => {
  const dispatch = useDispatch()

  const operationUnit = useSelector((state: RootStore) => state.activities.wip?.operationUnit)
  const currentStep = useSelector((state: RootStore) => state.activities.form?.steps?.current)
  const operationUnitError = useObjSelector(
    (state: RootStore) => state.activities?.form?.errors?.[currentStep]?.operationUnit?.[0]
  )
  const section = useSelector((state: RootStore) => state.activities.wip?.section)
  const sectionError = useObjSelector((state: RootStore) => state.activities.form?.errors?.[currentStep]?.section?.[0])

  const showFeedback = useSelector((state: RootStore) => state.activities.form?.steps.items[currentStep].showFeedback)

  return (
    <>
      <FormGroup widths="equal">
        <Form.Field inline required error={showFeedback && !!operationUnitError}>
          <label>Unidade Operativa</label>
          <Input
            name="operationUnit"
            fluid
            placeholder=""
            value={operationUnit}
            onChange={(e, { name, value }) => {
              dispatch(setActivitiesProp(`wip.${name}`, value))
            }}
          />
          {showFeedback && typeof operationUnitError === 'string' && (
            <Label color="red" pointing>
              {operationUnitError}
            </Label>
          )}
        </Form.Field>
        <Form.Field inline required error={showFeedback && !!sectionError}>
          <label>Trecho</label>
          <Input
            name="section"
            fluid
            placeholder=""
            value={section}
            onChange={(e, { name, value }) => {
              dispatch(setActivitiesProp(`wip.${name}`, value))
            }}
          />
          {showFeedback && typeof sectionError === 'string' && (
            <Label color="red" pointing>
              {sectionError}
            </Label>
          )}
        </Form.Field>
      </FormGroup>
    </>
  )
}

export default ActivitiesLocationForm
