import stepOne, { StepOne } from './stepOne'
import stepTwo, { StepTwo } from './stepTwo'
import stepThree, { StepThree } from './stepThree'
import stepFour, { StepFour } from './stepFour'
import stepFive, { StepFive } from './stepFive'

const steps = [stepOne, stepTwo, stepThree, stepFour, stepFive]

export type Steps = StepOne & StepTwo & StepThree & StepFour & StepFive

export default steps
