import React from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'
import { get } from 'lodash'
import TextAreaField, { TextAreaFieldProps } from './TextAreaField'

interface TextAreaStoreFieldProps extends TextAreaFieldProps {
  entity: string
}

const TextAreaStoreField: React.FC<TextAreaStoreFieldProps> = ({ value, ...fields }) => {
  const storeValue = useSelector(
    (state: RootStore) => get(state, `${fields.entity}.wip.${value}`) ?? get(state, `${fields.entity}.current.${value}`)
  )

  return <TextAreaField value={storeValue} {...fields} />
}

export default TextAreaStoreField
