import { orderBy } from 'lodash'
import * as React from 'react'
import { DateTime } from 'Utils/reactTable/components/DateTime'

export function lastSeenColumn(t: Function): object {
  return {
    Header: t('LastSeen'),
    accessor: 'lastSeen',
    aggregate: (values: any) => {
      if (Array.isArray(values) && values.length > 0) {
        const filteredValues = values.filter((e: unknown) => e)
        const max = orderBy(filteredValues, undefined, ['desc'])[0]
        return max
      } else return '-'
    },
    Aggregated: ({ value }: any) => (value ? <DateTime value={value} /> : 'Nunca Visto'),
    Cell: ({ value }: any) => {
      return value ? <DateTime value={value} /> : 'Nunca Visto'
    },
  }
}
