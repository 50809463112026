export const CHART_COLORS: string[] = [
  '#4bb258', // verde escuro
  '#4b72b2', // azul escuro
  '#b24b8e', // rosa escuro
  '#724bb2', // roxo escuro
  '#b2584b', // laranja escuro
  '#b2724b', // amarelo escuro
]

export const COMPLEMENTARY_CHART_COLORS: Record<string, string> = {
  blue: '#003366', // azul marinho
  green: '#2E8B57', // verde marinho
  grey: '#696969', // cinza escuro
  orange: '#ff7f50', // laranja salmão
  red: '#ff4d4d', // vermelho claro
  yellow: '#B28E4B', // amarelo escuro
  purple: '#724BB2', // roxo escuro
  pink: '#B24B72', // rosa claro
}
