/*  Author: Luís Mourão/ Felipe Pegado
    Type: Smart
  Description: 
  TODO: - Criar componente dumb grid com o conteúdo do return(render)
*/

import React, { Component } from 'react'
import { saveFavoriteNow } from 'Store/actions/login-action'
import { filterMarkers, setFilter } from 'Store/actions/filters-action'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { setTrue, setFalse, toggle } from 'Store/actions/toggle-action'
import { AssetContainer } from './AssetContainer'
import styled from 'styled-components'
import { Dropdown, Label, Popup, Icon } from 'semantic-ui-react'
import { toggleAsset } from 'Store/actions/assets-action'

const DropdownStyled = styled(Dropdown)`
  color: #4b72b2;
  opacity: 1;
  z-index: 210 !important;
  pointer-events: auto;
`

class DropdownPeople extends Component {
  state = {
    validFavorites: [],
    validAssets: [],
    searchHide: false,
  }

  itemClick = (id) => {
    const assets = JSON.parse(this.props.assetsStr)
    let name = assets?.[id]?.name
    if (name) {
      this.props.filterMarkers(name)
      this.props.setFilter(name, [{ id, type: 'assets' }])
      this.props.setTrue('showFitBounds')
    }
  }

  favClick = (id) => {
    const { saveFavoriteNow, favorites } = this.props
    const newFavorites = favorites.filter((f) => {
      return f.toString() !== id.toString()
    })
    saveFavoriteNow(newFavorites)
  }

  assetClick = (id) => {
    const { saveFavoriteNow, favorites } = this.props
    if (favorites.includes(id)) {
      this.favClick(id)
      return
    }
    const newFavorites = [...favorites, id]
    saveFavoriteNow(newFavorites)
  }

  hideSearch(assetArray) {
    const { searchHide } = this.state
    this.props.toggleAsset(!searchHide, assetArray)
    this.setState({ searchHide: !searchHide })
  }

  render() {
    const { favorites, assetsStr, assetsIdsStr, assetsActiveIdsStr } = this.props
    const assetsIds = JSON.parse(assetsIdsStr)
    const assets = JSON.parse(assetsStr)
    const assetsActiveIds = JSON.parse(assetsActiveIdsStr)
    const { searchHide } = this.state
    const activeAssets = assetsActiveIds.filter((a) => assets[a]?.idType === 2)
    const filteredIds = assetsIds.filter((a) => assets[a]?.idType === 2)

    return (
      <DropdownStyled
        className="AssetNot"
        icon={{
          children: (
            <Label circular size="mini" color="green" floating>
              {activeAssets.length ? activeAssets.length : 0}
            </Label>
          ),
        }}
        trigger={
          <Popup
            inverted
            size="tiny"
            content={this.props.t('People')}
            trigger={
              <Icon
                name="user circle"
                size="large"
                style={{ color: '#4b72b2' }}
                onClick={() => this.setState({ open: !this.state.open })}
              />
            }
            icon={{
              children: (
                <Label circular size="mini" color="green" floating>
                  {activeAssets.length ? activeAssets.length : 0}
                </Label>
              ),
            }}
          />
        }
        onBlur={() => this.setState({ open: false })}
        open={this.state.open}
        pointing="top right"
      >
        <Dropdown.Menu>
          <AssetContainer
            favorites={favorites}
            assetArrayStr={JSON.stringify(filteredIds)}
            activeAssetsStr={JSON.stringify(activeAssets)}
            clickItem={this.itemClick}
            clickFav={this.assetClick}
            assetsStr={assetsStr}
            assetsTitle={this.props.assetsTitle}
            hideSearch={(assetArray) => this.hideSearch(assetArray)}
            searchHide={searchHide}
            type="Pessoas"
          />
        </Dropdown.Menu>
      </DropdownStyled>
    )
  }
}

const mapStateToProps = (state) => ({
  assetsStr: JSON.stringify(state.assets.byId),
  assetsIdsStr: JSON.stringify(state.assets.allIds) || [],
  assetsActiveIdsStr: JSON.stringify(state.assets.allActivePeopleIds) || [],
  favorites: state.login.favorites,
  refMap: state.login.refMap,
  assetsTitle: state.projects[0]?.info?.customized?.assetsTitle,
  perfil: state.login.perfil,
  inner: state.toggle.showInnerMap,
})

const mapDispatchToProps = {
  setTrue,
  setFalse,
  saveFavoriteNow,
  filterMarkers,
  toggle,
  toggleAsset,
  setFilter,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DropdownPeople))
