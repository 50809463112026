import axios, { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { Content, IData } from './VerificationContent'
import { ACCESS_CONTROL_BASE_URL } from './utils'

type TParams = { hash: string }

export const OnboardingApprove = ({ match }: RouteComponentProps<TParams>) => {
  const [success, setSuccess] = useState<boolean>()
  const [approved, setApproved] = useState<boolean>()
  const [isLoadingApprove, setLoadingApprove] = useState(false)
  const [isLoadingReject, setLoadingReject] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [data, setData] = useState<IData>()
  const [error, setError] = useState(false)
  const token = new URLSearchParams(window.location.search).get('t') ?? ''

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${ACCESS_CONTROL_BASE_URL}/onboarding/guest/verification/${match.params.hash}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
          }
        )
        setData(result.data)
      } catch (err) {
        setError(true)
      }
    }
    fetchData()
  }, [match.params.hash, token])
  const handleResponse = async (hasApproved: boolean, justification: string | null) => {
    try {
      hasApproved ? setLoadingApprove(true) : setLoadingReject(true)
      setApproved(hasApproved)
      await axios.post(
        ACCESS_CONTROL_BASE_URL + `/onboarding/${data?.form ?? 'guest'}/approval/${match.params.hash}`,
        {
          hasApproved,
          justification,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      )
      setSuccess(true)
    } catch (err) {
      const errors = err as Error | AxiosError<string>
      setSuccess(false)
      hasApproved ? setLoadingApprove(false) : setLoadingReject(false)
      if (!axios.isAxiosError(errors)) {
        setErrorMsg(errors.message)
        return
      }
      // @ts-ignore
      setErrorMsg(errors?.response?.data || 'Erro desconhecido')
    }
  }
  if (!match.params.hash) return null
  return (
    <Grid textAlign="center" style={{ height: '100vh', background: '#f7f7f7' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 600 }}>
        <Content
          data={data}
          handleClick={handleResponse}
          success={success}
          error={error}
          isLoadingApprove={isLoadingApprove}
          isLoadingReject={isLoadingReject}
          approved={approved}
          errorMsg={errorMsg}
        />
      </Grid.Column>
    </Grid>
  )
}
