import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { Segment, Icon, Menu, Dropdown } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDashboardStellantis } from 'Store/actions/dashboardStellantis-action'
import { fetchDashboard, changeCurrentDashboardTime } from 'Store/actions/dashboards-action'
import { fetchAssets } from 'Store/actions/assets-action'
//import { exportReport } from 'Utils/exportReport'
import { setShowModal, setTableProp } from 'Store/actions/tables-action'
import m from 'moment'
import { toggleFullScreen, isFullScreen } from 'Utils/fullscreen'
import MenuTableDropdown from 'Utils/reactTable/components/MenuTableDropdown'
import MeasuresHistFilter from 'Utils/reactTable/modals/measuresHist/MeasuresHistFilter'
import ConfirmPanelSave from 'Utils/reactTable/modals/measuresHist/ConfirmPanelSave'
import { RelativeDate } from 'Utils/date'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
//import { exportPDF } from 'Utils/exportPDF'
import initialStore from 'Store/initialStore'
import { changeDashboardToDefault, saveLoginPreferences, toggleDashboardStaticMode } from 'Store/actions/login-action'
import _, { debounce } from 'lodash'
import { showConfirm, hideConfirm } from 'Store/actions/system-action'
import { Link, useHistory } from 'react-router-dom'
import { exportToCsv } from 'Utils/exportCSV'
import { URL_BASE } from 'Apis/rest'
import { fetchSteps, setDataLabelingIDs } from 'Store/actions/dataLabelings-actions'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'
import styled from 'styled-components'
import { setFalse, setTrue } from 'Store/actions/toggle-action'
import formatPDF from 'Utils/formatPDF'
import { setCurrentModule } from 'Store/actions/modules-action'
import { canAccess } from 'Utils/rbac'

export const MenuTableIcons = ({
  setAddOpen,
  setConfirmOpen,
  setColumnsOpen,
  setGroupsOpen,
  setGroupsAction,
  t,
  noSelectedRows,
  setConfirmText,
  setConfirmAction,
  refresh,
  fetchAction,
  setNewClick,
  edit,
  deleteBtn,
  insightActions,
  insightActionsValues,
  exportAction,
  dashboard,
  table,
  typeName,
  menuButtons = [],
  newItemClick,
  onEditClick,
  selectedRowIds = [],
  onSave,
  onLabelsClick,
  onLabelingsClick,
  buttonsProps = {},
  setCurrentSelectedRowIds,
  setProp,
  onFilterClick,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const empresa = useSelector((state) => state.login.empresa)
  const logoUrl = useSelector((state) => state?.tenants?.current?.info?.miniLogo)

  const perfil = useSelector((state) => state.login.perfil)

  const dashboardTimeAgo = useSelector((state) => state.dashboards.timeAgo)
  const dashBoardConfig = useSelector((state) => state.login.preferences.routesPanels.byId['dashboard'])
  const [dashSaveLoading, setDashSaveLoading] = useState(false)
  const currentTab = useSelector((state) => state.tables.current.tab)

  const loading = useSelector((state) => state.tables.byId[table]?.loading)

  const menuButtonsState = useObjSelector((state) => {
    return state.tables.byId[table]?.menuButtons || menuButtons
  })

  let visibleButtons = [...menuButtonsState]

  const selectedMeasures = useSelector((state) => state.tables.byId[currentTab]?.selectedMeasures) || []
  const currentTableView = useSelector((state) => state.tables.byId[currentTab]?.view)

  const [calendarTime, setCalendarTime] = useState(dashboard ? dashboardTimeAgo : '1d')

  const reports = useSelector((state) => state.tables.byId.reports)
  const reportData = useSelector((state) => state.tables.byId.reports.reportPayload)

  const staticMode = useSelector((state) => state.login?.preferences?.routesPanels?.byId?.['dashboard']?.staticMode)

  const preferencesColumns = useObjSelector((state) =>
    table === 'reports'
      ? state.login.preferences.routesPanels.byId.reports?.reportsById?.[reports.reportType]?.hiddenColumns
      : state.login.preferences.routesPanels.byId?.[table]?.hiddenColumns
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (calendarTime === '1d' && dashboard === 'Dashboard') {
        empresa === 'Stellantis'
          ? dispatch(fetchDashboardStellantis({ time: 'today', noLoading: true }))
          : dispatch(fetchDashboard('rules'))
      }
    }, 300000)
    return () => clearInterval(interval)
  }, [calendarTime, dashboard, empresa, dispatch])

  const handleClick = (value) => {
    let newValues = insightActionsValues.time
    if (newValues.includes(value)) {
      newValues = newValues.filter((f) => f !== value)
      insightActions.setTime(newValues)
    } else {
      newValues.push(value)
      newValues.sort((a, b) => a.localeCompare(b))
      newValues = newValues.filter((f) => 1 === 2 / 2)

      insightActions.setTime(newValues)
    }
  }

  const debouncedChangeHandler = useMemo(() => debounce((layout) => dispatch(saveLoginPreferences()), 3000), [dispatch])

  // this array stores each button's properties
  // ideally a component that uses menuTableIcons must necessarily feed a list of buttons which is a subset of this array (or the entire thing)
  // use it to fill in the necessary props to build the graphical artifact
  let preBuiltButtons = [
    //
    {
      id: 'new',
      icon: 'add',
      action: () => {
        setConfirmAction('insert')
        setNewClick(true)
        setAddOpen(true)
        if (newItemClick) newItemClick()
      },
      disabled: false,
    },
    //
    {
      id: 'edit',
      icon: 'edit',
      action: () => {
        setConfirmText('Tem certeza que deseja atualizar estes dados?')
        setConfirmAction('update')
        setNewClick(true)
        setAddOpen(true)
        dispatch(setTrue('deviceEditMode'))
        //Para deselecionar o item
        if (setCurrentSelectedRowIds) {
          setCurrentSelectedRowIds({})
        }
        if (onEditClick) {
          onEditClick(selectedRowIds[0])
        }
      },
      disabled: edit,
    },
    //
    {
      id: 'provision',
      icon: noSelectedRows ? 'location arrow' : 'fighter jet',
      action: () => {
        setConfirmAction('insert')
        setNewClick(true)
        setAddOpen(true)
        dispatch(setFalse('deviceEditMode'))
      },
      disabled: false,
    },
    //
    {
      id: 'groups',
      icon: 'star',
      action: () => setConfirmOpen(true),
      disabled: noSelectedRows,
      addNew: true,
    },
    //
    {
      id: 'rules',
      icon: 'bell',
      action: () => setConfirmOpen(true),
      disabled: edit,
      addNew: true,
    },
    //
    {
      id: 'columns',
      icon: 'columns',
      action: () => setColumnsOpen(true),
      disabled: false,
    },
    //
    {
      id: 'delete',
      icon: 'delete',
      action: () => {
        setConfirmText('Tem certeza que deseja excluir os itens selecionados?')
        setConfirmAction('delete')
        setConfirmOpen(true)
        if (setCurrentSelectedRowIds) {
          setCurrentSelectedRowIds({})
        }
      },
      disabled: noSelectedRows || !deleteBtn,
    },
    //
    {
      id: 'pdt',
      icon: 'square',
      options: buttonsProps.pdt?.options,
    },
    {
      id: 'stellantisCars',
      icon: 'car',
      options: buttonsProps.stellantisCars?.options,
    },
    {
      id: 'calendar',
      icon: 'calendar alternate outline',
      options: [
        {
          id: RelativeDate.TODAY,
          icon: 'calendar alternate outline',
          text: t('1_day'),
          action: () => {
            setCalendarTime(RelativeDate.TODAY)
            fetchAction(RelativeDate.TODAY)
          },
          active: calendarTime === RelativeDate.TODAY,
        },
        {
          id: RelativeDate.LAST_WEEK,
          icon: 'calendar alternate outline',
          text: t('7_days'),
          action: () => {
            setCalendarTime(RelativeDate.LAST_WEEK)
            fetchAction(RelativeDate.LAST_WEEK)
          },
          active: calendarTime === RelativeDate.LAST_WEEK,
        },
        {
          id: RelativeDate.LAST_MONTH,
          icon: 'calendar alternate outline',
          text: t('30_days'),
          action: () => {
            setCalendarTime(RelativeDate.LAST_MONTH)
            fetchAction(RelativeDate.LAST_MONTH)
          },
          active: calendarTime === RelativeDate.LAST_MONTH,
        },
        {
          id: RelativeDate.LAST_QUARTER,
          icon: 'calendar alternate outline',
          text: t('90_days'),
          action: () => {
            setCalendarTime(RelativeDate.LAST_QUARTER)
            fetchAction(RelativeDate.LAST_QUARTER)
          },
          active: calendarTime === RelativeDate.LAST_QUARTER,
        },
      ],
    },
    // DEAL WITH THIS '&&'
    {
      id: 'histFilter',
      icon: 'filter',
      action: () => {
        if (!onFilterClick) dispatch(setShowModal('measuresHist', true))
        else {
          onFilterClick()
        }
      },
    },
    // DEAL WITH THIS '&&'
    {
      id: 'filter',
      icon: 'filter',
      action: () =>
        dispatch({
          type: 'SELECT_DATE_REPORTS_REOPEN',
        }),
    },
    //
    {
      id: 'import',
      icon: 'upload',
      action: () => {
        fetchAction()
      },
      disabled: true,
    },
    // DEAL WITH THIS '&&'
    {
      id: 'time',
      icon: 'calendar',
      options: [
        {
          id: 'realTime',
          text: 'Tempo Real',
          t: true,
          action: () => {
            if (dashboard === 'Perímetros') {
              dispatch(changeCurrentDashboardTime('realTime'))
              setCalendarTime('realTime')
            }
          },
          disabled: dashboard !== 'Perímetros' ? true : false,
        },
        {
          id: '1d',
          text: 'Last Day',
          t: true,
          action: () => {
            if (empresa === 'Stellantis') dispatch(fetchDashboardStellantis({ time: 'today' }))
            else {
              dispatch(changeCurrentDashboardTime('1d'))
              if (dashboard === 'Regras') dispatch(fetchDashboard('rules'))
              if (dashboard === 'Perímetros') dispatch(fetchDashboard('fences'))
            }
          },
        },
        {
          id: '7d',
          text: 'Last Week',
          t: true,
          action: () => {
            if (empresa === 'Stellantis') dispatch(fetchDashboardStellantis({ time: 'lastWeek' }))
            else {
              dispatch(changeCurrentDashboardTime('7d'))
              if (dashboard === 'Regras') dispatch(fetchDashboard('rules'))
              if (dashboard === 'Perímetros') dispatch(fetchDashboard('fences'))
            }
            setCalendarTime('7d')
          },
          disabled: false,
          selected: empresa === 'Stellantis' ? true : false,
        },
        {
          id: '15',
          text: 'Last Month',
          t: true,
          action: () => {
            if (empresa === 'Stellantis') dispatch(fetchDashboardStellantis({ time: 'lastMonth' }))
            else {
              dispatch(changeCurrentDashboardTime('30d'))
              if (dashboard === 'Regras') dispatch(fetchDashboard('rules'))
              if (dashboard === 'Perímetros') dispatch(fetchDashboard('fences'))
            }
            setCalendarTime('30d')
          },
          disabled: false,
          selected: empresa === 'Stellantis' ? true : false,
        },
      ],
      addNew: true,
    },
    // DEAL WITH THIS '&&'
    {
      id: 'shift',
      icon: 'time',
      action: () => setConfirmOpen(true),
      disabled: false,
      options: [
        {
          id: 'morning',
          icon: insightActionsValues?.time?.includes('turno1') ? 'check' : '',
          text: t('shift') + ' 1',
          t: true,
          action: () => handleClick('turno1'),
        },
        {
          id: 'afternoon',
          icon: insightActionsValues?.time?.includes('turno2') ? 'check' : '',
          text: t('shift') + ' 2',
          t: true,
          action: () => handleClick('turno2'),
        },
      ],
      addNew: true,
    },
    // DEAL WITH THIS '&&'
    {
      id: 'viewOptions',
      icon: 'binoculars',
      label: 'view',
      options: [
        {
          id: 'table',
          text: 'Tabela',
          t: true,
          icon: 'table',
          selected: !currentTableView || currentTableView === 'table',
          action: () => {
            dispatch(setTableProp(`byId.${table}.view`, 'table'))
          },
        },
        {
          id: 'lineChart',
          text: 'Gráfico de linha',
          t: true,
          icon: 'chart line',
          selected: currentTableView === 'lineChart',
          action: () => {
            dispatch(setTableProp(`byId.${table}.view`, 'lineChart'))
          },
        },
        {
          id: 'pieChart',
          text: 'Gráfico de Pizza (em breve)',
          t: true,
          icon: 'pie chart',
          selected: currentTableView === 'pieChart',
          action: () => {
            dispatch(setTableProp(`byId.${table}.view`, 'pieChart'))
          },
          disabled: true,
        },
      ],
    },
    // this one maybe should be used in all tabs
    {
      id: 'refresh',
      icon: 'refresh',
      action: () => {
        if (dashboard === 'Dashboard') {
          dispatch(fetchDashboardStellantis({ time: 'today' }))
          setCalendarTime('1d')
        } else {
          if (fetchAction) {
            fetchAction()
          } else {
            if (dashboard === 'Regras') dispatch(fetchDashboard('rules'))
            if (dashboard === 'Perímetros') dispatch(fetchDashboard('fences'))
            if (dashboard === 'Pessoas') dispatch(fetchAssets())
          }
        }
      },
      disabled: !refresh,
    },
    //
    {
      id: 'toggle fullscreen',
      icon: 'expand arrows alternate',
      action: () => {
        if (!isFullScreen()) {
          toggleFullScreen()
        } else {
          toggleFullScreen()
        }
      },
      disabled: false,
    },
    // Pin Button
    {
      id: 'pin',
      icon: staticMode ? 'move' : 'pin',
      action: () => {
        dispatch(toggleDashboardStaticMode())
      },
      disabled: false,
    },
    {
      id: 'save',
      icon: 'save',
      action: () => {
        if (onSave) {
          onSave()
        }
      },
      disabled: false,
    },
    {
      id: 'saveDefault',
      icon: 'save',
      action: async () => {
        setDashSaveLoading(true)
        const response = await fetch(URL_BASE + '/dashboard/default/' + empresa, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ dashboard: JSON.stringify(dashBoardConfig) }),
        })
        setDashSaveLoading(false)
        if (!response.ok) {
          notifyError()
          return
        }
        notifySuccess()
      },
      disabled: dashSaveLoading,
    },
    {
      id: 'saveMeasuresHist',
      icon: 'save',
      action: () => {
        dispatch(setTableProp(`byId.measuresHist.showSaveModal`, true))
        dispatch(setShowModal('measuresHist', true))
      },
      disabled: typeof saveAction !== 'function' && selectedMeasures.length === 0,
    },
    // this button might be deleted; not used in any current tab as far as I am concerned
    {
      id: 'fullscreen',
      icon: 'external square alternate',
      action: () => {
        if (dashboard === 'firefighter') {
          // open a new window with the dashboard
          window.open(
            '/firefighters',
            '_blank',
            'toolbar=no,scrollbars=no,resizable=yes,top=0,left=0,width=1280,height=720'
          )
        }
      },
      disabled: false,
    },

    {
      id: 'aiModel',
      icon: 'code',
      disabled: true,
    },
    // this button is to restore default settings from initial store
    {
      id: 'default',
      icon: 'redo',
      //iconGroup: 'cog',
      action: () => {
        if (dashboard === 'Dashboard') {
          let data = {
            content: (
              <div style={{ height: 'auto', fontSize: '1.2em', padding: '10px' }}>
                {' '}
                <span>
                  {
                    'Tem certeza que deseja restaurar a configuração desse Dashboard para a padrão? (Atenção: todas as mudanças serão perdidas!)'
                  }
                </span>
              </div>
            ),
            onConfirm: async () => {
              let dashboard = initialStore.dashboards.byId.dashboard.defaultLayout
              const response = await fetch(URL_BASE + '/dashboard/default/' + empresa)
              if (response.ok) dashboard = JSON.parse((await response.json()).dashboard)
              dispatch(changeDashboardToDefault(dashboard))
              debouncedChangeHandler()
              dispatch(hideConfirm())
              dispatch(saveLoginPreferences()) //.then(() => window.location.reload())
            },
            onCancel: () => dispatch(hideConfirm()),
            confirmHeader: 'Restaurar configuração para a padrão',
          }
          dispatch(showConfirm(data))
        }
      },
      disabled: false,
    },
    {
      id: 'aiModel',
      icon: 'code',
      disabled: true,
    },
    // what is this one?
    {
      id: 'carModel',
      icon: 'truck',
      action: () => setConfirmOpen(true),
      disabled: false,
      options: [
        { id: 'all', text: 'All', t: true, action: () => insightActions.setCarModel('') },
        { id: 'argo', text: 'Argo', t: true, action: () => insightActions.setCarModel('argo') },
        {
          id: 'fiorino',
          text: 'Fiorino',
          t: true,
          action: () => insightActions.setCarModel('fiorino'),
          disabled: true,
        },
        {
          id: 'grand Siena',
          text: 'Grand Siena',
          t: true,
          action: () => insightActions.setCarModel('grand Siena'),
          disabled: true,
        },
        { id: 'mobi', text: 'Mobi', t: true, action: () => insightActions.setCarModel('mobi'), disabled: true },
        { id: 'strada', text: 'Strada', t: true, action: () => insightActions.setCarModel('strada'), disabled: true },
        { id: 'toro', text: 'Toro', t: true, action: () => insightActions.setCarModel('toro'), disabled: true },
      ],
      addNew: true,
    },
    {
      id: 'labels',
      icon: 'line graph',
      action: () => {
        if (onLabelsClick) {
          onLabelsClick(selectedRowIds[0])
        }
      },
      disabled: edit,
    },
    {
      id: 'labelings',
      icon: 'table',
      label: 'labelings',
      action: () => {
        if (onLabelingsClick) {
          onLabelingsClick()
        }
      },
    },
    {
      id: 'resume',
      icon: 'file',
      label: 'resume',
      action: () => {
        dispatch(fetchSteps(selectedRowIds[0]))
        dispatch(setDataLabelingIDs(selectedRowIds))
        history.push(`/app/dataLabelings/chronology/${selectedRowIds.join(',')}`)
      },
      disabled: selectedRowIds.length === 0,
    },
    {
      id: 'mensurations',
      icon: 'stopwatch',
      label: 'Mensuration',
      action: () => {
        //window.open('//localhost:3000/app/insights', '_blank')
        dispatch(setDataLabelingIDs(selectedRowIds))
      },
    },
    {
      id: 'driver',
      icon: 'bullseye',
      label: 'driver',
      action: () => {
        history.push(`/app/driver`)
      },
    },
    // this one is used in basically all tabs
    perfil !== 'OPE' && {
      id: 'export',
      icon: 'download',
      options:
        table === 'reports'
          ? [
              {
                id: 'csv',
                icon: 'file text',
                text: 'CSV',
                action: async () => {
                  const exportData = typeof exportAction === 'function' ? await exportAction() : []

                  const fileName = table
                    ? // ? `${t('Reports')}_${t(table)[0].toUpperCase() + t(table).substr(1)}_${m().format('YYYYMMDD')}`
                      `Relatório ${t(reports.reportType + '_label')} - ${m().format('YYYYMMDD')}` // ex: Relatório 10 - Violação Regras pela Vida - 20240415
                    : null

                  exportToCsv(exportData, fileName)
                  //exportReport(exportData, table ? `Relatório_${t(table)}_${m().format('YYYYMMDD')}` : null, t, typeName, m)
                },
              },
              {
                id: 'pdf',
                icon: 'file pdf',
                text: 'PDF',
                disabled: dashboard ? true : false,
                action: () => {
                  const uuid = btoa(table)
                  const openPDF = formatPDF({
                    table,
                    reportData,
                    logoUrl,
                    reports,
                    empresa,
                    preferencesColumns,
                    uuid,
                    t,
                  })
                  openPDF ? window.open(`/pdfviewer?id=${uuid}`, '_blank') : dispatch(setTrue('openPDFModal'))
                },
              },
            ]
          : [
              {
                id: 'csv',
                icon: 'file text',
                text: 'CSV',
                action: async () => {
                  const exportData = typeof exportAction === 'function' ? await exportAction() : []
                  const fileName = table
                    ? `${t('Reports')}_${t(table)[0].toUpperCase() + t(table).substr(1)}_${m().format('YYYYMMDD')}`
                    : null

                  exportToCsv(exportData, fileName)
                  //exportReport(exportData, table ? `Relatório_${t(table)}_${m().format('YYYYMMDD')}` : null, t, typeName, m)
                },
              },
            ],
      disabled: false,
    },
    {
      id: 'details',
      icon: 'list alternate',
      disabled: _.uniq(selectedRowIds).length !== 1,
      action: () => {
        dispatch(setCurrentModule(selectedRowIds[0], table))
        dispatch(setTrue('showModuleDetails'))
      },
    },
  ]
  // remove buttons only for these profiles
  if (!canAccess({ roles: perfil, actions: ['WRITE', 'DELETE'], resource: table })) {
    visibleButtons = visibleButtons.filter(
      (e) => e !== 'new' && e !== 'edit' && e !== 'groups' && e !== 'rules' && e !== 'delete'
    )
  }

  // only allow provision button at the devices tab
  if (perfil !== 'DEV' || currentTab !== 'devices') {
    visibleButtons = visibleButtons.filter((e) => e !== 'provision')
  }

  const buttons = preBuiltButtons.filter((b) => visibleButtons.includes(b.id))

  if (buttons?.length === 0) return null

  const menuItems = buttons.map((btn) => {
    let res

    // I believe that a switch statement is more readable than a series of ternary operators
    switch (btn.id) {
      case 'rules':
        res = (
          <MenuTableDropdown {...btn} key={btn.id}>
            <Dropdown.Menu>
              <Dropdown.Item
                key="rulesEdit"
                icon="edit"
                text={t('AssociateRules')}
                disabled={false}
                onClick={() => {
                  setGroupsOpen(true)
                  setGroupsAction('updateRules')
                }}
              />
            </Dropdown.Menu>
          </MenuTableDropdown>
        )
        break
      case 'groups':
        res = (
          <MenuTableDropdown {...btn} key={btn.id}>
            <Dropdown.Menu>
              <Dropdown.Item
                key="groupCreate"
                icon="add"
                text="Criar Grupo"
                onClick={() => {
                  setGroupsOpen(true)
                  setGroupsAction('insert')
                }}
              />
              <Dropdown.Item
                key="groupEdit"
                icon="edit"
                text="Editar/Excluir Grupos"
                disabled={false}
                onClick={() => {
                  setGroupsOpen(true)
                  setGroupsAction('update')
                }}
              />
            </Dropdown.Menu>
          </MenuTableDropdown>
        )
        break
      case 'calendar':
      case 'pdt':
      case 'stellantisCars':
      case 'export':
      case 'time':
      case 'viewOptions':
      case 'shift':
      case 'carModel':
        res = (
          <Dropdown
            key={btn.id}
            item
            style={{ padding: '0px', minWidth: '4em' }}
            disabled={btn.disabled}
            multiple
            onChange={(e, i) => handleClick(e, i)}
            trigger={
              <Menu.Item
                name={t(btn)}
                style={{
                  padding: '4px',
                  minWidth: '4em',
                  width: '60px',
                }}
              >
                <Icon
                  name={btn.icon}
                  title={t(btn.label || 'btn' + btn.icon.split(' ')[0])}
                  style={{ marginBottom: '2px !important' }}
                />
                {t('lbl' + btn.icon.split(' ')[0])}
              </Menu.Item>
            }
          >
            <Dropdown.Menu style={{ maxHeight: '400px', overflow: 'auto' }}>
              {btn.options
                .filter((opt) => !(empresa === 'Stellantis' && opt.id === 'realTime'))
                .sort((a, b) => {
                  if (a?.text) return a.text.localeCompare(b.text)
                  return true
                })
                .map((o) => {
                  const { action, ...itemProps } = o
                  return (
                    <Dropdown.Item
                      {...itemProps}
                      key={o.id ?? o.key}
                      icon={o.id === calendarTime ? 'check' : o.icon}
                      text={o.text}
                      selected={o.selected}
                      disabled={o.disabled || false}
                      onClick={() => {
                        typeof action === 'function' && action(o.id)
                      }}
                    />
                  )
                })}
            </Dropdown.Menu>
          </Dropdown>
        )
        break
      case 'histFilter':
        res = (
          <Fragment key={btn.id}>
            <Menu.Item
              name={t(btn)}
              key={btn.id}
              disabled={btn.disabled}
              onClick={btn.action}
              style={{ padding: '4px', minWidth: '4em', width: '60px' }}
            >
              <Icon
                name={btn.icon}
                loading={loading && btn.id === 'refresh'}
                title={t('btn' + btn.icon.split(' ')[0])}
                style={{ marginBottom: '2px !important' }}
              />
              <span style={{ fontSize: 'x-small' }}>{t('lbl' + btn.icon.split(' ')[0])}</span>
            </Menu.Item>
            <MeasuresHistFilter />
          </Fragment>
        )
        break
      case 'mensurations':
        const title = t('btn' + btn.icon.split(' ')[0])
        const label = t(btn.label ?? 'lbl' + btn.icon.split(' ')[0])
        res = (
          <Link
            key={'mensuration'} //
            to={`/app/dashmensuration`}
            target="_blank"
          >
            <Menu.Item
              name={btn.id}
              key={btn.id}
              disabled={btn.disabled}
              onClick={btn.action}
              title={title}
              style={{ padding: '4px', minWidth: '4em', width: '60px' }}
            >
              {btn.iconGroup ? (
                <div style={{ borderBottom: '4px white solid !important' }}>
                  <Icon.Group size="big">
                    <Icon name={btn.iconGroup} title={title} />
                    <Icon name={btn.icon} corner />
                  </Icon.Group>
                </div>
              ) : (
                <Icon
                  name={btn.icon}
                  loading={loading && btn.id === 'refresh'}
                  title={title}
                  style={{ marginBottom: '2px !important' }}
                />
              )}
              <span style={{ fontSize: label.length > 8 ? 'smaller' : 'x-small' }}>{t(label)}</span>
            </Menu.Item>
          </Link>
        )
        break
      case 'saveMeasuresHist':
      case 'save':
        res = (
          <Fragment key={btn.id}>
            <Menu.Item
              key={btn.id}
              disabled={btn.disabled}
              onClick={btn.action}
              style={{ padding: '4px', minWidth: '4em', width: '60px' }}
            >
              <Icon
                name={btn.icon}
                loading={loading && btn.id === 'refresh'}
                title={t('btn' + btn.icon.split(' ')[0])}
                style={{ marginBottom: '2px !important' }}
              />
              <span style={{ fontSize: 'x-small' }}>{t('lbl' + btn.icon.split(' ')[0])}</span>
            </Menu.Item>
            <ConfirmPanelSave />
          </Fragment>
        )
        break
      default: {
        const title = t('btn' + btn.icon.split(' ')[0])
        const label = t(btn.label ?? 'lbl' + btn.icon.split(' ')[0])
        res = (
          <Fragment key={btn.id}>
            <Menu.Item
              name={btn.id}
              key={btn.id}
              disabled={btn.disabled}
              onClick={btn.action}
              title={title}
              style={{ padding: '4px', minWidth: '4em', width: '60px' }}
            >
              {btn.iconGroup ? (
                <div style={{ borderBottom: '4px white solid !important' }}>
                  <Icon.Group size="big">
                    <Icon name={btn.iconGroup} title={title} />
                    <Icon name={btn.icon} corner />
                  </Icon.Group>
                </div>
              ) : (
                <Icon
                  name={btn.icon}
                  loading={loading && btn.id === 'refresh'}
                  title={title}
                  style={{ marginBottom: '2px !important' }}
                />
              )}
              <StyledSpan style={{ fontSize: label.length > 8 ? 'smaller' : 'x-small' }}>{label}</StyledSpan>
            </Menu.Item>
          </Fragment>
        )
      }
    }

    return res
  })

  return (
    <Segment floated="left" style={{ marginTop: '0px', border: 'none', padding: '0px !important' }}>
      <Menu icon="labeled" size="mini" style={{ height: '44px', width: `${buttons.length * 60}px` }}>
        {menuItems}
      </Menu>
    </Segment>
  )
}

const StyledSpan = styled.span`
  min-width: 10px;
  max-width: 67px;
  display: inline-block;
  padding: 0px 5px 10px 5px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default MenuTableIcons
