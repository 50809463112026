/*  Author: Luís Mourão
    Type: action
    Description: action from modules store
    To do list: Incluir Update
*/
/*global localStorage*/
import { urlBase, URL_BASE } from 'Apis/rest'
import axios from 'axios'
import { createGeoModule, updateGeoModule, deleteGeoModule } from 'graphql/mutations'
import { omit } from 'lodash'
import { API } from 'aws-amplify'
import { listGeoModulesByTenant } from 'graphql/queries'
import { onChangeGeoModule, onDeleteGeoModule } from 'graphql/subscriptions'
import { notifyError, notifySuccess } from 'Utils/components/SystemToasts'
import { setFalse, setTrue } from './toggle-action'
import { mutateEntity } from 'Apis/mutateEntity'
import generateRandomCode from 'Utils/generateRandomCode'
import produce from 'immer'

export const setModulesProps = (payload) => {
  return {
    type: 'SET_MODULES_PROPS',
    payload,
  }
}

export const fetchModules = (type, token) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  if (!token) dispatch({ type: 'FETCH_MODULES_START', payload: type })
  await axios
    .get(URL_BASE + '/modules/all', {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
      params: {
        nextToken: token,
        type,
      },
    })
    .then((resp) => {
      const { data } = resp
      dispatch({ type: 'FETCH_MODULES', payload: data.modules, moduleType: type })
      dispatch({
        type: 'indoorInitialStore',
        payload: [{ indoor: data.modules?.filter((m) => m.moduleType === 'units') }],
      })
      if (data.nextToken) dispatch(fetchModules(type, data.nextToken))
    })
}

export const saveGroup = (table, groupName, selected, actionType) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const body = { groupName, selected, moduleType: table, associate: actionType !== 'delete' }
  const headers = { headers: { Authorization: 'Bearer ' + jwt } }
  axios
    .post(urlBase + `/modules/${table}/groups`, body, headers)
    .then(dispatch({ type: 'REMOVE_MODULE_GROUPS', payload: { moduleType: table, groupName, selected } }))
}

export const setCurrentModule = (id, payload) => ({
  type: 'SET_CURRENT_MODULE',
  id,
  payload,
})

export const insertDynamicData = (data, type) => async (dispatch) => {
  dispatch({ type: 'CREATE_MODULE_REQUEST', payload: { moduleType: type } })
  const jwt = localStorage.getItem('id_token')
  const body = data
  try {
    const res = await axios.post(URL_BASE + '/assignment?type=' + type, body, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({ type: 'CREATE_MODULE_SUCCESSFULL', payload: { data: res.data, type } })
    notifySuccess()
  } catch (err) {
    dispatch({ type: 'CREATE_MODULE_FAILURE', payload: err })
    notifyError()
  }
}

export const updateDynamicData = (params, type, action, auth) => async (dispatch) => {
  dispatch({ type: 'CREATE_MODULE_REQUEST', payload: { moduleType: type } })
  try {
    const jwt = auth || localStorage.getItem('id_token')
    const body = params
    const res = await axios.put(action || urlBase + '/modules/' + type, body, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({
      type: params.response || 'DYNAMIC_UPDATE_SUCCESS',
      payload: { data: JSON.parse(res?.data?.body), type },
    })
    notifySuccess()
  } catch (err) {
    console.error(err)
    dispatch({ type: 'DYNAMIC_UPDATE_FAILURE', payload: params })
    notifyError()
  }
}

export const updateModule = (params, type, action, auth) => async (dispatch) => {
  dispatch({ type: 'UPDATE_MODULE_START', payload: { moduleType: type } })
  try {
    const jwt = auth || localStorage.getItem('id_token')
    const body = params
    const res = await axios.put(action || urlBase + '/modules/' + type, body, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({
      type: 'UPDATE_MODULE',
      payload: { data: JSON.parse(res?.data?.body), moduleType: type },
      id: params.id,
    })
    notifySuccess()
  } catch (err) {
    console.error(err)
    dispatch({ type: 'DYNAMIC_UPDATE_FAILURE', payload: params })
    notifyError()
  }
}

export const deleteDynamicData = (params, moduleType, auth) => async (dispatch) => {
  try {
    const jwt = auth || localStorage.getItem('id_token')
    const response = await axios.delete(urlBase + `/modules/${moduleType}/?ids=${params.ids.toString()}`, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({
      type: params.response || 'DYNAMIC_DELETE_SUCCESS',
      payload: { ...params, moduleType: moduleType, response },
      id: params.ids,
    })
    notifySuccess()
  } catch (err) {
    console.error(err)
    dispatch({ type: 'DYNAMIC_DELETE_FAILURE', payload: { params: params, err: err } })
    notifyError()
  }
}

export const updateWiP = (params) => ({
  type: 'MODULE_UPDATE_WIP',
  payload: params,
})

export const clearWiP = (params) => ({
  type: 'MODULE_CLEAR_WIP',
  payload: params,
})

export const fetchGeoModules =
  (token, data = []) =>
  async (dispatch, getState) => {
    const tenant = getState().login.empresa
    try {
      if (!token) dispatch({ type: 'FETCH_GEO_MODULES_START', tenant })
      const response = await API.graphql({
        query: listGeoModulesByTenant,
        variables: { tenant, nextToken: token },
      })
      const { items, nextToken } = response.data.listGeoModulesByTenant
      data = [...data, ...items]
      if (nextToken) {
        return dispatch(fetchGeoModules(nextToken, data))
      } else {
        dispatch({
          type: 'FETCH_GEO_MODULES_SUCCESS',
          payload: data,
        })
      }
    } catch (err) {
      dispatch({ type: 'FETCH_GEO_MODULES_FAILURE', payload: err })
      console.error('error fetching geoModules', err)
    }
  }

//apenas um geomódulo que foi tornado visível
export const fetchGeoModulesById = async (id) => {
  const jwt = localStorage.getItem('id_token')
  try {
    const response = await axios.get(URL_BASE + `/geo?ids=${id}`, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    return response.data.items[0]
  } catch (err) {
    console.error('error fetching geoModules', err)
  }
}

export const fetchGeoModulesByIds =
  ({ ids = [], data = [], nextToken, getCoords = false, loadingScreen = false }) =>
  async (dispatch, getState) => {
    const jwt = localStorage.getItem('id_token')
    const lastFetchTime = getState().modules.lastFetchTime
    try {
      let paramIds = ids
      // Set paramIds just in initialFetch by preferences values
      if (getCoords) {
        const { fences, refs, routes } = getState().modules
        const preferences = getState().login.preferences

        paramIds = [
          ...fences.allIds.filter((id) => !preferences.fences[id]?.invisible && !fences.byId[id]?.defaultInvisible),
          ...routes.allIds.filter((id) => !preferences.routes[id]?.invisible && !routes.byId[id]?.defaultInvisible),
          ...refs.allIds.filter((id) => !preferences.refs[id]?.invisible && !refs.byId[id]?.defaultInvisible),
        ]
      }

      dispatch({ type: 'FETCH_GEO_MODULES_START' })
      loadingScreen && dispatch(setTrue('loadingScreen'))
      const response = await axios.post(
        URL_BASE + `/geo`,
        {
          ids: paramIds?.length > 0 ? paramIds.slice(0, 99) : undefined,
          nextToken,
        },
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        }
      )

      const items = [...data, ...response.data.items]

      // Se houver mais de 199 ids, ou se houver nextToken, faz uma nova requisição com os próximos ids
      if (response.data.nextToken || paramIds.length > 99) {
        return dispatch(
          fetchGeoModulesByIds({
            ids: paramIds?.slice(99),
            data: items,
            nextToken: response.data.nextToken,
            loadingScreen,
          })
        )
      } else {
        dispatch({
          type: 'FETCH_GEO_MODULES_SUCCESS',
          payload: items,
          lastFetchTime,
        })
      }
    } catch (err) {
      console.error('error fetching geoModules', err)
      dispatch({ type: 'FETCH_GEO_MODULES_FAILURE', payload: err })
    }
    loadingScreen && dispatch(setFalse('loadingScreen'))
  }
export const saveGeoModule =
  ({ mutation, fields, type }) =>
  async (dispatch, getState) => {
    const tenant = getState().login.empresa
    const email = getState().login.email
    const current = getState().modules.current

    let input = {
      ...fields,
      ...(mutation === 'create' ? { createdBy: email, createForm: 'platform' } : {}),
      ...(mutation === 'update' ? { updateForm: 'platform' } : {}),
      updatedBy: email,
      tenant,
      history: [],
    }

    if (fields?.properties) {
      input.properties = JSON.stringify({ ...current?.properties, ...fields.properties })
    }

    if (fields?.geometry) {
      input.geometry = {
        ...fields.geometry,
        coordinates: JSON.stringify(fields.geometry.coordinates),
      }
    }

    if (!fields?.code) {
      input.code = generateRandomCode({})
    }

    input = omit(input, ['createdAt', 'updatedAt'])

    await mutateEntity({
      entity: 'GeoModule',
      dispatch,
      input,
      query: mutation === 'update' ? updateGeoModule : createGeoModule,
    })
  }

export const deleteGeoModules =
  ({ type, ids }) =>
  async (dispatch) => {
    for (const id of ids) {
      try {
        dispatch({ type: 'DELETE_GEO_MODULE_START', payload: { type, id } })
        await API.graphql({
          query: deleteGeoModule,
          variables: {
            input: {
              id,
            },
          },
        })
        dispatch({ type: 'DELETE_GEO_MODULE_SUCCESS', payload: { type, id } })
        notifySuccess()
      } catch (err) {
        console.error('error:', err)
        notifyError()
      }
    }
  }

export const subscribeGeoModule = (type) => (dispatch, getState) => {
  const isDelete = type === 'delete'
  const tenant = getState().login.empresa
  return API.graphql({
    query: isDelete ? onDeleteGeoModule : onChangeGeoModule,
    variables: {
      tenant,
    },
  }).subscribe({
    next: ({ provider, value }) => {
      const payload = value.data?.[isDelete ? 'onDeleteGeoModule' : 'onChangeGeoModule']
      isDelete
        ? dispatch({ type: 'DELETE_GEO_MODULE_SUCCESS', payload })
        : dispatch({ type: 'SAVE_GEO_MODULE_SUCCESS', payload })
    },
    error: (error) => console.warn('error', error),
  })
}

export const insertStarted = (params) => {
  return {
    type: 'MODULE_INSERT_STARTED',
    payload: params,
  }
}

export const insertStopped = (params) => {
  return {
    type: 'MODULE_INSERT_STOPPED',
    payload: params,
  }
}

export const initiateModuleInsert = (payload) => {
  return {
    type: 'INIT_MODULE_INSERT',
    payload,
  }
}

export const initiateModuleUpdate = (payload) => {
  return {
    type: 'INIT_MODULE_UPDATE',
    payload,
  }
}

export const saveGeoModuleCFImage =
  ({ fieldId, newValue }) =>
  async (dispatch, getState) => {
    const tenant = getState().login.empresa
    const email = getState().login.email
    const current = getState().modules.current

    const properties = produce(current?.properties || {}, (draft) => {
      if (!draft.customFields) {
        draft.customFields = {}
      }
      draft.customFields[fieldId] = newValue
    })

    let input = {
      id: current.id,
      properties: JSON.stringify(properties),
      updateForm: 'platform',
      updatedBy: email,
      tenant,
    }

    await mutateEntity({
      entity: 'GeoModule',
      dispatch,
      input,
      query: updateGeoModule,
      extraAction: () => dispatch({ type: 'SET_GEO_MODULE_PROPERTIES', payload: { properties } }),
    })
  }

export const saveGeoModuleImage =
  ({ newValue }) =>
  async (dispatch, getState) => {
    const tenant = getState().login.empresa
    const email = getState().login.email
    const current = getState().modules.current

    const properties = produce(current?.properties || {}, (draft) => {
      draft.url = newValue
    })

    let input = {
      id: current.id,
      properties: JSON.stringify(properties),
      updateForm: 'platform',
      updatedBy: email,
      tenant,
    }

    await mutateEntity({
      entity: 'GeoModule',
      dispatch,
      input,
      query: updateGeoModule,
      extraAction: () => dispatch({ type: 'SET_GEO_MODULE_PROPERTIES', payload: { properties } }),
    })
  }
