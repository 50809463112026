import { combineReducers } from 'redux'
import assets from './assets-reducer'
import cards from './cards-reducer'
import filters from './filters-reducer'
import history from './history-reducer'
import indoor from './indoor-reducer'
import login from './login-reducer'
import notification from './notification-reducer'
import people from './people-reducer'
import toggle from './toggle-reducer'
import devices from './devices-reducer'
import signals from './signals-reducer'
import models from './models-reducer'
import reftypes from './reftypes-reducer'
import projects from './projects-reducer'
import heatmap from './heatmap-reducer'
import modules from './modules-reducer'
import messages from './messages-reducer'
import system from './system-reducer'
import tenants from './tenant-reducer'
import rules from './rules-reducer'
import interactions from './interactions-reducer'
import dashboards from './dashboards-reducer'
import dashboardStellantis from './dashboardStellantis-reducer'
import notificationProfiles from './notificationProfiles-reducer'
import measures from './measures-reducer'
import tables from './tables-reducer'
import locationHist from './locationHist-reducer'
import dataLabelings from './dataLabelings-reducer'
import activities from './activities-reducer'
import mensurations from './mensurations-reducer'
import reports from './reports-reducer'
import profiles from './profiles-reducer'
import changeLogs from './changeLogs-reducer'
import betaDashboards from './betaDashboards-reducer'

const reducers = combineReducers({
  activities,
  assets,
  betaDashboards,
  cards,
  changeLogs,
  dashboards,
  dashboardStellantis,
  dataLabelings,
  devices,
  filters,
  heatmap,
  history,
  indoor,
  interactions,
  locationHist,
  login,
  measures,
  mensurations,
  messages,
  models,
  modules,
  notification,
  notificationProfiles,
  people,
  profiles,
  projects,
  reftypes,
  reports,
  rules,
  signals,
  system,
  tables,
  tenants,
  toggle,
})

export default reducers
