import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'

const useEntitySelector = <
  TEntity extends keyof RootStore,
  TField extends keyof (RootStore[TEntity]['wip'] & RootStore[TEntity]['current'])
>(
  entity: TEntity,
  field: TField
): RootStore[TEntity]['wip'][TField] => {
  return useSelector((state: RootStore) => state[entity]?.wip?.[field] ?? state[entity]?.current?.[field])
}

export default useEntitySelector
