import React from 'react'
import { useHistory } from 'react-router-dom'
import { Header, Segment, Message, Button } from 'semantic-ui-react'

const DemoExpired = () => {
  const history = useHistory()

  const handleLogout = () => {
    history.push('/login')
  }
  return (
    <Segment basic style={{ padding: '3em 0' }} textAlign="center">
      <Header as="h2" color="red" style={{ marginBottom: '0.5em' }}>
        Período de demostração expirado!
      </Header>
      <Message negative style={{ padding: '1.5em' }}>
        <Message.Header style={{ fontSize: '1.2em' }}>
          Seu período de demostração terminou, para continuar usando o serviço entre em contato com a equipe de vendas.
        </Message.Header>
        <Button onClick={handleLogout} color="red" style={{ marginTop: '1em' }}>
          Logout
        </Button>
      </Message>
    </Segment>
  )
}

export default DemoExpired
