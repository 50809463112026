import axios from 'axios'

export const changeTenant = async (tenant) => {
  var options = {
    url: 'https://phygitall.auth0.com/api/v2/users/',
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('access_token'),
      'content-type': 'application/json',
    },
  }
  await axios.patch(
    options.url + localStorage.getItem('user_id'),
    {
      user_metadata: { empresa: tenant },
    },
    { headers: options.headers }
  )
}
