import React, { useState, useMemo } from 'react'
import { Icon, Card, Statistic, Label, Grid, List } from 'semantic-ui-react'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { useLeaflet } from 'react-leaflet'
import { setTrue } from 'Store/actions/toggle-action'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const GridColumn = styled(Grid.Column)`
  font-weight: bold;
`

const StretchesCard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { map } = useLeaflet()
  const histHolesLines = useObjSelector((state) => state.history?.holesLines)
  const histHolesCount = useMemo(() => {
    return histHolesLines.reduce((previousValue, currentValue, currentIndex, array) => {
      return previousValue + parseInt(currentValue.properties.count)
    }, 0)
  }, [histHolesLines])
  const histProps = useMemo(() => {
    return histHolesLines.reduce((previousValue, currentValue, currentIndex, array) => {
      const { properties } = currentValue
      Object.entries(properties).forEach((e) => {
        const key = e[0]
        const value = e[1]
        previousValue[key] = {
          ...previousValue[key],
          [value]: previousValue[key]?.[value] ? previousValue[key]?.[value] + 1 : 1,
        }
      })
      return previousValue
    }, {})
  }, [histHolesLines])
  console.log('histProps:', histProps)
  const holesCountPercentile = useObjSelector((state) => state.history?.holesCountPercentile)

  const histHolesLen = useMemo(() => histHolesLines.length, [histHolesLines])

  const [details, setDetails] = useState(true)

  const [segProps, setSegProps] = useState(histHolesLines?.[0] || {})
  console.log('segProps:', segProps)

  const segSize = useSelector((state) => state.tables.byId.historySteps.segSize)

  return (
    <Card
      color="blue"
      style={{
        minWidth: '35vw',
        top: '45px',
        zIndex: '900',
        paddingBottom: '20px',
        backgroundColor: 'rgb(255,255,255,0.6)',
        minHeight: details ? '82vh' : '2vh',
      }}
    >
      <Card.Content>
        <Statistic size="mini" floated="right" color={'black'}>
          <Statistic.Value textAlign="right">
            <Icon name={'warning sign'} />
            {histHolesCount}
          </Statistic.Value>
          <Statistic.Label>Ocorrências</Statistic.Label>
        </Statistic>
        <Card.Header>
          <Icon name={'filter'} style={{ cursor: 'pointer' }} onClick={() => dispatch(setTrue('showHistorySteps'))} />
          <Icon name={'road'} /> {`${histHolesLen} Trechos - ${segSize} metros`}
          {histHolesLen > 0 && (
            <Icon
              title={details ? 'Clique para ocultar detalhes' : 'Clique para mostrar mais detalhes'}
              onClick={() => {
                setDetails(!details)
              }}
              style={{ cursor: 'pointer' }}
              name={details ? 'caret up' : 'caret down'}
              color="black"
            />
          )}
        </Card.Header>
        {details && histHolesLen > 0 && (
          <Card.Description style={{ display: 'flex', width: '100%' }}>
            <Grid style={{ width: '100%', paddingLeft: '7px', margin: '0' }}>
              <Grid.Row style={{ padding: '0' }}>
                <Grid.Column floated="left" width={16} textAlign="Left">
                  <List horizontal>
                    <List.Item>
                      <h3 style={{ paddingLeft: '7px' }}>{'Legenda'}</h3>
                    </List.Item>
                    <br />
                    <List.Item>
                      <Label color="grey">Sem dados</Label>
                    </List.Item>
                    <List.Item>
                      <Label circular color="green">
                        {`<${holesCountPercentile[0] + 1}`}
                      </Label>
                    </List.Item>
                    <List.Item>
                      <Label circular color="yellow">
                        {`${holesCountPercentile[0] + 1}-${holesCountPercentile[1]}`}
                      </Label>
                    </List.Item>
                    <List.Item>
                      <Label circular color="orange">
                        {`${holesCountPercentile[1] + 1}-${holesCountPercentile[2]}`}
                      </Label>
                    </List.Item>
                    <List.Item>
                      <Label circular color="red">
                        {`>${holesCountPercentile[2]}`}
                      </Label>
                    </List.Item>
                  </List>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ padding: '0', paddingLeft: '7px', marginTop: '13px' }}>
                <GridColumn floated="left" width={16} textAlign="Left">
                  <h3>{`${segProps.properties?.label} - Trecho ${segProps.properties.id?.slice(-2)}`}</h3>
                </GridColumn>
              </Grid.Row>
              <Grid.Row style={{ padding: '0', marginTop: '8px', marginLeft: '21px' }}>
                <GridColumn floated="left" width={4} textAlign="Center">
                  {/*<Label color="blue">Velocidade Média no Trecho</Label>*/}
                  Vel. Max
                </GridColumn>
                <GridColumn floated="left" width={4} textAlign="Center">
                  Rodovia
                </GridColumn>
                <GridColumn floated="left" width={4} textAlign="Center">
                  {/*<Label color="blue">Temperatura Média</Label>*/}
                  Vias
                </GridColumn>
                <GridColumn floated="left" width={4} textAlign="Center">
                  {/*<Label color="blue">Intervenções Realizadas</Label>*/}
                  Mão Única
                </GridColumn>
              </Grid.Row>
              <Grid.Row style={{ padding: '0', marginLeft: '21px' }}>
                <GridColumn floated="left" width={4} textAlign="Left">
                  {segProps.properties?.maxspeed || '-'}
                </GridColumn>
                <GridColumn floated="left" width={4} textAlign="Left">
                  {segProps.properties?.highway || '-'}
                </GridColumn>
                <GridColumn floated="left" width={4} textAlign="Left">
                  {/*<Label color="cyan">30 °C</Label>*/}
                  {segProps.properties?.lanes || '-'}
                </GridColumn>
                <GridColumn floated="left" width={4} textAlign="Left">
                  {t(segProps.properties?.oneway) || '-'}
                </GridColumn>
              </Grid.Row>
              <Grid.Row style={{ padding: '0', paddingLeft: '7px', marginTop: '13px', marginBottom: '5px' }}>
                <GridColumn floated="left" width={16} textAlign="Left">
                  <h3>Ranking de Trechos</h3>
                </GridColumn>
              </Grid.Row>
              <Grid.Row style={{ padding: '0' }}>
                <GridColumn floated="left" width={4} textAlign="center">
                  {/*<Label color="blue">Id do Trecho</Label>*/}
                  Id do Trecho
                </GridColumn>
                <GridColumn width={8} textAlign="center">
                  {/*<Label color="blue">Via</Label>*/}
                  Via
                </GridColumn>
                <GridColumn floated="left" width={4} textAlign="center">
                  {/*<Label color="blue">Detecções</Label>*/}
                  Detecções
                </GridColumn>
              </Grid.Row>

              {histHolesLines
                .sort((a, b) => b.properties.count - a.properties.count)
                .map((hole, i) => {
                  if (i < 10) {
                    const coords = hole.geometry.coordinates.map((e) => ({ lat: e[1], lng: e[0] }))
                    return (
                      <Grid.Row
                        style={{ padding: '0', cursor: 'pointer', marginTop: '5px' }}
                        onClick={() => {
                          map.getZoom() > 15 ? map.flyToBounds(coords, 20) : map.fitBounds(coords, 20)
                          setSegProps(hole)
                        }}
                      >
                        <GridColumn floated="left" width={4} textAlign="center">
                          {hole.properties.id?.slice(-11)}
                        </GridColumn>
                        <GridColumn width={8} textAlign="center">
                          {hole.properties.label}
                        </GridColumn>
                        <GridColumn floated="left" width={4} textAlign="center">
                          {
                            <Label circular color={hole.properties.color}>
                              {hole.properties.count}
                            </Label>
                          }
                        </GridColumn>
                      </Grid.Row>
                    )
                  } else return null
                })}
            </Grid>
          </Card.Description>
        )}
      </Card.Content>
    </Card>
  )
}

export default StretchesCard
