import React from 'react'
import { Text } from '@react-pdf/renderer'
// import Separator from './Separator'

const ReportFooter = ({ pageNumber, totalPages }) => {
  return (
    <>
      {/* <Separator style={{ paddingTop: 20 }} /> */}
      <Text style={{ textAlign: 'right', paddingTop: 10 }}>
        Página {pageNumber} - {totalPages}
      </Text>
    </>
  )
}

export default ReportFooter
