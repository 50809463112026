import { FormStepItems, StoreForm } from 'interfaces/formStepItems'
import { DataLabeling as DataLabelingSchema } from 'API'

export interface Label {
  phaseIndex: number
  startTime: Date
  endTime: Date
  vehicle: string
  videoUrlKey: string
}

export interface DataLabeling extends DataLabelingSchema {}

export interface WipDataLabeling extends Partial<DataLabeling> {}

export enum HexColors {
  BLUE = 'blue',
  GREEN = 'green',
  GREY = 'grey',
  RED = 'red',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  PURPLE = 'purple',
  PINK = 'pink',
}

export interface MotionDataPoint {
  assetId: string
  assetName: string
  category: string
  aXMean: number
  aYMean: number
  aZMean: number
  aXSpread: number
  aYSpread: number
  aZSpread: number
  time: string
  timestamp: number
}

interface SelectedMeasure {
  name: string
  color: string
}

interface Item {
  labelings: unknown[]
  phases: unknown[]
  startTime: any
}

interface IAPIData {
  items: Item[]
  uniqueShifts: unknown[]
  uniqueVehicles: string[]
  uniquePhases: unknown[]
  rechartsYamazumi: unknown[]
  uniqueCycles: unknown[]
  mensurationData: unknown[]
  vehicleParam: string
  shiftParam: string
  loading?: boolean
}

export interface DataLabelings {
  motionData: MotionDataPoint[]
  allIds: string[]
  byId: Record<string, DataLabeling>
  current: DataLabeling | null
  currentLabelingsIndex: Number
  wip: WipDataLabeling
  form: StoreForm
  showLabelingContent: boolean
  openSideBar: boolean
  selectedMeasures: SelectedMeasure[][]
  selectedDataLabelingsIds: string[]
  loading: boolean
  motionDataDomainMin?: number
  motionDataDomainMax?: number
  startIndex?: number
  endIndex?: number
  openSWKModal?: boolean
  activitiesOptions: Record<string, string>[]
  selectedActivity: Record<string, string>
  selectedVehicle: string
  lastActivityDataLabeling: DataLabeling | null
  driver: any
  labelsTableSetSortBy: Function | null
  stepsByPhases: unknown[]
  currentVideoTime?: string
  cronology: IAPIData
}

export const dataLabelingsItems: FormStepItems[] = [
  {
    id: 'identification',
    icon: 'info',
    title: 'Identificação',
    description: 'Identifique a Rotulagem',
    showFeedback: false,
    disabled: false,
  },
  {
    id: 'videoUpload',
    icon: 'video',
    title: 'Vídeo',
    description: 'Selecione o Vídeo',
    showFeedback: false,
    disabled: true,
  },
  {
    id: 'time',
    icon: 'calendar',
    title: 'Intervalo',
    description: 'Selecione o Intervalo de Tempo',
    showFeedback: false,
    disabled: true,
  },
  {
    id: 'assets',
    icon: 'band aid',
    title: 'Dispositivos',
    description: 'Selecione os Dispositivos',
    showFeedback: false,
    disabled: true,
  },
  {
    id: 'activity',
    icon: 'tasks',
    title: 'Atividade',
    description: 'Indique a Atividade e suas Fases',
    showFeedback: false,
    disabled: true,
  },
]

export const dataLabelings: DataLabelings = {
  allIds: [],
  byId: {},
  current: null,
  currentLabelingsIndex: 0,
  wip: {},
  form: {
    steps: {
      current: 0,
      items: dataLabelingsItems,
    },
    open: true,
    loading: false,
  },
  motionData: [],
  openSideBar: true,
  showLabelingContent: false,
  selectedMeasures: [[{ name: 'aZMean', color: '#4bb258' }]],
  selectedDataLabelingsIds: [],
  loading: false,
  activitiesOptions: [],
  selectedActivity: {},
  selectedVehicle: 'all',
  lastActivityDataLabeling: null,
  driver: [],
  labelsTableSetSortBy: null,
  stepsByPhases: [],
  currentVideoTime: '',
  cronology: {
    items: [],
    uniqueShifts: [],
    uniqueVehicles: [],
    uniquePhases: [],
    rechartsYamazumi: [],
    uniqueCycles: [],
    mensurationData: [],
    vehicleParam: '',
    shiftParam: '',
  },
}
