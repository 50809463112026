import React from 'react'
import m from 'moment'

export const twoColumns = (t, inInfo = false, canEdit) => {
  return [
    {
      Header: t ? t('createdBy') : 'Criado por',
      accessor: inInfo ? 'info.createdBy' : 'createdBy',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${t('emails')}`,
    },
    {
      Header: t ? t('createdAt') : 'Criado em',
      accessor: inInfo ? 'info.createdAt' : 'createdAt',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'horários'}`,
      Cell: ({ value }) => <span title={m(value).format('lll')}>{m(value).fromNow()}</span>,
    },
  ]
}
